import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import Icon from "../components/common/Icon";
import LinkC from "../components/common/LinkC";
import {getQueryParams} from "../tools/url-helper";

export default class Page404 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            back: false,
            description: ''
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        goBack: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    goBack = (event) => {
        event.preventDefault();
        this.context.goBack();
    };

    componentWillMount() {
        if (this.props.history && this.props.history.location) {
            if (/\/svoi-tariff-\S*\.pdf/.test(this.props.history.location.pathname)) {
                let params = getQueryParams(this.props.history.location.search);
                if (params.description) {
                    this.setState({description: params.description});
                    this.props.history.replace(this.props.history.location.pathname);
                }
            }
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined' && window.history.length > 0) {
            this.setState({back: true})
        }
    }

    render() {
        const { staticContext = {} } = this.props;
        staticContext.status = 404;

        return (
            <main className="page__main-holder" role="main">
                <Helmet title={"Оператор сотовой связи МОТИВ | Страница не найдена"} />
                <div className="page__container">
                    <div className="not-found-page__wrapper">
                        <div className="not-found-page__container">
                            <div className="not-found-page__icon">
                                <Icon name={"icon-not-found-page"}/>
                            </div>
                            <h1 className="not-found-page__title">Страница не найдена :(</h1>
                            {this.props.type === 'vacancy'
                                ? <p className="not-found-page__text">К сожалению, данной вакансии больше не существует </p>
                                : ( this.state.description ? <p className="not-found-page__text">{this.state.description}</p> :
                                    <p className="not-found-page__text">К сожалению, неправильно введен адрес или страница больше не существует</p>)
                            }
                            <div className="not-found-page__buttons">
                                {this.state.description ?
                                    <LinkC className="u-btn u-btn_default u-btn_adapt_mobile-sm" to={"/tariffs"}>Вернуться назад</LinkC> :
                                    <button className="u-btn u-btn_default u-btn_adapt_mobile-sm" onClick={this.goBack}>Вернуться назад</button>}
                                <LinkC to={"/"} className={"u-btn u-btn_white u-btn_adapt_mobile-sm"}>
                                    На главную
                                </LinkC>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}