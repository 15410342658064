import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    toggleSearchInputAction,
    searchHintAction,
    clearSearchHintAction,
    stateSearchInputAction,
    clearSearchPageAction,
    clearSearchResultPaginationAction,
} from '../../actions/searchAction';
import { setQueryParam, findTwoSimbols } from '../../tools/tools';

class SearchInput extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            dropDown: false,
        }

    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        locationPath: PropTypes.string,
        isB2b: PropTypes.bool,
        pushHistory: PropTypes.func,
    };

    onChange = (e) => {
        this.props.stateSearchInputAction(e);

        if ((e && findTwoSimbols(e)) || (e.length > 2)) {
            this.setState({ dropDown: true });

            const website = !this.context.isB2b ? 'b2c' : 'b2b';
            this.props.searchHintAction(website, e, this.context.selectedRegion.id);
        }

        if ((e && findTwoSimbols(e)) ? e.length < 2 : e.length < 3) {
            this.setState({ dropDown: false });

            this.props.clearSearchHintAction();
        }
        
    }

    // по клику на иконку поиска
    onSearch = () => {

        // очистка результатов
        this.props.clearSearchPageAction();
        this.props.clearSearchResultPaginationAction();
        this.props.clearSearchHintAction();

        this.props.toggleSearchInputAction(false);
        this.context.pushHistory(`/${this.context.selectedRegion.alias}/search`);

        setQueryParam('q', this.props.stateSearchInput);

        this.setState({ dropDown: false });
    }

    // по клику на крестик
    onCancel = () => {
        this.props.toggleSearchInputAction(false);
        this.props.stateSearchInputAction('');
        this.props.clearSearchHintAction();

        this.setState({ dropDown: false });
    }

    // по клику на найденные элементы
    onClickItem = (e) => {
        if ((e && findTwoSimbols(e)) || (e && e.length > 2)) {
            this.props.stateSearchInputAction(e);

            // очистка результатов
            this.props.clearSearchPageAction();
            this.props.clearSearchResultPaginationAction();
            this.props.clearSearchHintAction();

            this.props.toggleSearchInputAction(false);
            this.context.pushHistory(`/${this.context.selectedRegion.alias}/search`);

            setQueryParam('q', e);
        }
    }


    render() {
        let {
            stateSearchHint,
            stateSearchInput,
        } = this.props;

        let {
            dropDown,
        } = this.state;

        return (
            <div className='search_input'>
                <div className='search_input__container'>
                    <input 
                        className='search_input__input'
                        placeholder='Поиск по сайту'
                        value={stateSearchInput}
                         // первая регулярка на запрет пробела первым символом, вторая на запрет двух и более подряд пробелов
                        onChange={(e) => this.onChange(e.target.value.replace(/^\s/g, '').replace(/\s+/g, ' '))}
                        maxLength={50}
                        onKeyDown={(event) => {
                            // по клику на Enter
                            if (event.which === 13) {
                                this.onSearch();
                            }
                        }}
                        ref={this.props.refSearch}
                    />
                    <button
                        onClick={this.onSearch}
                        className="search_input__icon_search"
                    >
                        <img src={`${require('../../../images/icons/search-gray.svg')}`} alt="Поиск" />
                    </button>
                    {
                        stateSearchInput &&
                          <div
                            onClick={this.onCancel}
                            className='search_input__icon_close'
                          >
                              <img src={`${require('../../../images/icons/close-gray.svg')}`} alt="Закрыть" />
                          </div>
                    }

                    {
                        stateSearchInput &&
                          <div className={`search_input__drop ${dropDown ? 'active' : ''}`}>
                              {stateSearchHint && stateSearchHint.map((i, index) => (
                                <div
                                  className='search_input__drop_item'
                                  key={index}
                                  onClick={(e) => this.onClickItem(e.target.textContent)}
                                >
                                    {i.title}
                                </div>
                              ))}

                              {stateSearchHint && stateSearchHint.length < 1
                                && (
                                  <div>
                                      По вашему запросу ничего не найдено.
                                  </div>
                                )}
                          </div>
                    }
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        stateToggleSearchInput: state.searchReducer.stateToggleSearchInput,
        stateSearchHint: state.searchReducer.stateSearchHint,
        stateSearchInput: state.searchReducer.stateSearchInput,
    }),
    dispatch => {
        return {
            toggleSearchInputAction: (data) => dispatch(toggleSearchInputAction(data)),
            searchHintAction: (businessLine, term, regionId) => dispatch(searchHintAction(businessLine, term, regionId)),
            clearSearchHintAction: () => dispatch(clearSearchHintAction()),
            clearSearchPageAction: () => dispatch(clearSearchPageAction()),
            clearSearchResultPaginationAction: () => dispatch(clearSearchResultPaginationAction()),
            stateSearchInputAction: (data) => dispatch(stateSearchInputAction(data)),
        }
    }
)(SearchInput);
