import React, { Component } from 'react';
import {getMeasure} from "../../tools/tools"

class SidebarHeadInfo extends Component {

    render() {

        let item = this.props.item;
        let type = this.props.type;
        let color = this.props.color;

        let result = <div />;
        let period = item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 1) : '';
        if (period === 'подключение' || item.activation_price > 0 && item.subscription_fee === 0 || item.activation_price === 0 && item.subscription_fee === 0) {
            period = 'подкл.';
        }
        let slugSvoi = item && item.slug.indexOf('svoi');

        if (item.priceBlockInfo === null) {

            if (type === 'service') {

                if (item.is_package === true) {

                    result = <div className="b-price-details__price-list b-price-details__price-list_pp_si-pack">
                        <div className="b-price-list b-price-list_theme_dotted b-price-list b-price-list_adapt_dotted-price-details">
                            <div className="b-price-list__wrapper">
                                <div className="b-price-list__list">
                                    {item.packageServices.map((item, index) =>
                                        <div className="b-price-list__item" key={index}>
                                            <div className="b-price-list__text">{item.name}</div>
                                            <div className="b-price-list__dotter"></div>
                                            {item.subscription_fee === 0 && item.activation_price > 0 ?
                                                <div className="b-price-list__price">{item.activation_price}
                                                    <span className="b-price-list__valute">&nbsp;
                                                        <span className="u-rub">₽/подкл.</span>
                                                    </span>
                                                </div> :
                                                <div className="b-price-list__price">{item.subscription_fee}
                                                    <span className="b-price-list__valute">&nbsp;
                                                        <span className="u-rub">₽</span>
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>;

                }

                if (!item.is_package) {
                    if (item.subscription_fee > 0 && item.activation_price > 0) {
                        result = <React.Fragment>
                            <div className="b-price-details__price">
                                <div className="b-price-details__price-value">{item.subscription_fee}
                                    <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                                </div>
                                <div className="b-price-details__price-period">
                                    /{item.writeOffPeriod ? period : ''}
                                </div>
                            </div>
                            <div className="b-price-details__two-col-options b-price-details__two-col-options_few b-price-details__two-col-options_adapt_col-view">
                                <div className="b-price-details__option-item">
                                    <div className="b-price-details__option">Стоимость подключения услуги</div>
                                    <div className="b-price-details__option--mobile">Стоимость подключения</div>
                                    <div className="b-price-details__price">{item.activation_price}<span className="price-rub">₽</span></div>
                                </div>
                            </div>
                        </React.Fragment>
                    } else if (item.subscription_fee > 0 && item.activation_price === null || item.subscription_fee > 0 && item.activation_price === 0) {
                        result = <React.Fragment>
                            <div className="b-price-details__price">
                                <div className="b-price-details__price-value">{item.subscription_fee}
                                    <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                                </div>
                                <div className="b-price-details__price-period">
                                    /{item.writeOffPeriod ? period : ''}
                                </div>
                            </div>
                        </React.Fragment>
                    } else if (item.subscription_fee === 0 && item.activation_price > 0) {
                        result = <React.Fragment>
                            <div className="b-price-details__price">
                                <div className="b-price-details__price-value">{item.activation_price}
                                    <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                                </div>
                                <div className="b-price-details__price-period">
                                    /{item.writeOffPeriod ? period : ''}
                                </div>
                            </div>
                        </React.Fragment>
                    } else if (item.subscription_fee === 0 && item.activation_price === null || item.subscription_fee === 0 && item.activation_price === 0) {
                        result = <React.Fragment>
                            <div className="b-price-details__price">
                                <div className="b-price-details__price-value">{item.subscription_fee}
                                    <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                                </div>
                                <div className="b-price-details__price-period">
                                    /{item.subscription_fee === 0 && item.activation_price === 0 ? 'подкл.' : item.writeOffPeriod ? period : ''}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                }

            } else {

                if (item.subscription_fee >= 0) {
                    result =
                        <React.Fragment>
                            <div className="b-price-details__price">
                                <div className="b-price-details__price-value">
                                    {   slugSvoi !== -1 && item.svoi.abon_discount > item.svoi.abon && !!item.svoi.abon ?
                                            item.svoi.abon :
                                            item.subscription_fee
                                    }
                                    <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                                </div>
                                <div className="b-price-details__price-period">
                                    /{item.writeOffPeriod ? period : ''}
                                </div>
                            </div>
                            <div className="b-price-details__two-col-options b-price-details__two-col-options_few b-price-details__two-col-options_adapt_col-view">
                                {item.internet_gb_count
                                    ?
                                    <div className="b-price-details__option-item">
                                        <div className="b-price-details__option">Интернет</div>
                                        <div className="b-price-details__price">{item.internet_gb_count} Гб</div>
                                    </div>
                                    : ''
                                }
                                {item.minutes_count
                                    ?
                                    <div className="b-price-details__option-item">
                                        <div className="b-price-details__option">Минуты</div>
                                        <div className="b-price-details__price">{item.minutes_count} Мин</div>
                                    </div>
                                    : ''
                                }
                                {item.sms_count
                                    ?
                                    <div className="b-price-details__option-item">
                                        <div className="b-price-details__option">СМС</div>
                                        <div className="b-price-details__price">{item.sms_count} Смс</div>
                                    </div>
                                    : ''
                                }
                            </div>
                        </React.Fragment>
                }

                if (item.subscription_fee === 0) {
                    result =
                        <React.Fragment>
                        <div className="b-price-details__price">
                                <div className="b-price-details__price-value">{item.subscription_fee}
                                    <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                                </div>
                                <div className="b-price-details__price-period">
                                    /{item.writeOffPeriod ? period : ''}
                                </div>
                            </div>
                            <div className="b-price-details__two-col-options b-price-details__two-col-options_few b-price-details__two-col-options_adapt_col-view">
                                {item.internet_mb_cost
                                    ?
                                    <div className="b-price-details__option-item">
                                        <div className="b-price-details__option">Интернет</div>
                                        <div className="b-price-details__price">{item.internet_mb_cost}<span className="price-rub"> ₽</span>/Мб</div>
                                    </div>
                                    : ''
                                }
                                {item.minute_cost
                                    ?
                                    <div className="b-price-details__option-item">
                                        <div className="b-price-details__option">Минуты</div>
                                        <div className="b-price-details__price">{item.minute_cost}<span className="price-rub"> ₽</span>/Мин</div>
                                    </div>
                                    : ''
                                }
                                {item.sms_cost
                                    ?
                                    <div className="b-price-details__option-item">
                                        <div className="b-price-details__option">СМС</div>
                                        <div className="b-price-details__price">{item.sms_cost}<span className="price-rub"> ₽</span>/Смс</div>
                                    </div>
                                    : ''
                                }
                            </div>
                        </React.Fragment>
                }
            }
        } else {
            if (item.price_block_info_is_text) {
                result =
                    <React.Fragment>
                        <div className="b-price-details__price">
                            <div className="b-price-details__price-value">{item.subscription_fee}
                                <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                            </div>
                            <div className="b-price-details__price-period">
                                /{item.writeOffPeriod ? period : ''}
                            </div>
                        </div>
                        <div className="b-price-details__two-col-options b-price-details__two-col-options_few b-price-details__two-col-options_adapt_col-view"
                             dangerouslySetInnerHTML={{__html: item.price_block_info}}/>
                    </React.Fragment>
            } else {
                result =
                    <React.Fragment>
                        <div className="b-price-details__price">
                            <div className="b-price-details__price-value">
                                {   slugSvoi !== -1 && item.svoi.abon_discount > item.svoi.abon && !!item.svoi.abon ?
                                        item.svoi.abon :
                                        item.subscription_fee
                                }
                                <sup className="b-price-details__valute">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}</sup>
                            </div>
                            <div className="b-price-details__price-period">
                                /{item.writeOffPeriod ? period : ''}
                            </div>
                        </div>
                        <div className="b-price-details__two-col-options b-price-details__two-col-options_few b-price-details__two-col-options_adapt_col-view">
                            {item.priceBlockInfo && item.priceBlockInfo.map((item, index) =>
                                <div className="b-price-details__option-item" key={index}>
                                    <div className="b-price-details__option" dangerouslySetInnerHTML={{__html: item.title}}/>
                                    <div className="b-price-details__price" dangerouslySetInnerHTML={{__html: item.value}}/>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
            }
        }

        return (

            <div className="b-inner-intro__price-details">
                <div className="b-price-details b-price-details_adapt_default">
                    <div className="b-price-details__wrapper" style={color ? {color: color} : {}}>
                        <div className="b-price-details__inner">
                            {type === 'service' || item.is_package === true ? <div className="b-price-details__header b-price-details__header_show">
                                Стоимость услуги
                            </div> : type === 'tariff' ? <div className="b-price-details__header b-price-details__header_show">Стоимость тарифа</div> : ""}

                            <div className="b-price-details__body">
                                {result}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SidebarHeadInfo;
