import React from 'react'
import LinkC from '../common/LinkC'
import {constuctorUrl} from "../../tools/config";

function renderRemain(remain) {
    var title = "";
    switch (remain.type) {
        case "DATA":
            title = "Интернет";
            break;
        case "VOICE":
            title = "Звонки";
            break;
        case "SMS":
            title = "Сообщения";
            break;
    }

    return remain.total_amount > 0 || remain.total_amount === "Безлимит" ? <li className="rate-item__prop" key={remain.type}>
        <div className="text_sm text_light">{title}</div>
        <div className="rate-item__text-medium">{remain.total_amount === "Безлимит" ? "Безлимит" : remain.total_amount+" "+remain.unit}</div>
    </li> : null
}

export default function CurrentTariffBlock({id, title, slug, regionSlug, fee, measure = '', status_id, remains, details, onClick, scrollTo = ()=> {}}) {


    let detailUrl = (regionSlug ? "/" + regionSlug : "")+"/tariffs";

    if (slug) {
        switch (status_id) {
            case 2:
                detailUrl += "/archive/";
                break;
            case 3:
                detailUrl += "/individual/";
                break;
            default:
                detailUrl += "/";
        }
        detailUrl += slug;
    }

    const renderRemains = () => {
        let preparedRemains = [
            {
                type: 'DATA',
                rest_amount: 0,
                unit: 'Гб'
            },
            {
                type: 'VOICE',
                rest_amount: 0,
                unit: 'Мин'
            },
            {
                type: 'SMS',
                rest_amount: 0,
                unit: 'Смс'
            }
        ];
        let content = [];
        let hasData = false;

        if (remains) {
            remains.map((remain) => {
                switch (remain.type) {
                    case 'DATA':
                        hasData = true;
                        preparedRemains[0] = {
                            ...remain,
                            wifi: remain.rest_amount >= 1
                        }
                        break;
                    case 'VOICE':
                        preparedRemains[1] = remain
                        break;
                    case 'SMS':
                        preparedRemains[2] = remain
                        break;
                }
                return remain;
            })
        }

        if (details && details.is_data_unlim && !hasData) {
            content.push(<li className="rate-item__prop" key={'data'}>
                <div className="text_sm">Интернет</div>
                <div className="rate-item__text-medium">
                    <span className="img-icon icon-infinity-black"/>
                    {details.router ? <span className="img-icon icon-wifi icon-wifi-black"/> : null}
                </div>
            </li>)
        } else {
            content.push(renderRemain(preparedRemains[0]))
        }
        content.push(renderRemain(preparedRemains[1]))
        content.push(renderRemain(preparedRemains[2]))
        return content;
    }


    return <div className="current__rate" >
        {title ? <div className="rate-item__name-price">
            <div className="text_sm text_light">Ваш текущий тариф</div>
            <div className="rate-item__name">«{title}»</div>
        </div> : null}
        {/*{details ? <ul className="rate-item__props">*/}
            {/*{details.traffic > 0 ? <li className="rate-item__prop">*/}
                    {/*<div className="text_sm">Интернет</div>*/}
                    {/*<div className="rate-item__text-medium">{details.traffic} Гб</div>*/}
                {/*</li> : null}*/}
            {/*{details.minutes > 0 ? <li className="rate-item__prop">*/}
                {/*<div className="text_sm">Звонки</div>*/}
                {/*<div className="rate-item__text-medium">{details.minutes} мин</div>*/}
            {/*</li> : null}*/}
            {/*{details.sms > 0 ? <li className="rate-item__prop">*/}
                {/*<div className="text_sm">Сообщения</div>*/}
                {/*<div className="rate-item__text-medium">{details.sms} sms</div>*/}
            {/*</li> : null}*/}
        {/*</ul> : null}*/}
        {(remains || (details && details.is_data_unlim) ? <ul className="rate-item__props">
            {renderRemains()}
        </ul> : null)}
        <div className="rate-item__name-price">
            {fee || details ? <div className="rate-item__price">
                {details && details.fee && details.fee_with_discount && details.fee != details.fee_with_discount ? <div className="fee-without-discount">{details.fee} ₽/месяц</div> : null}
                {details ? <div className="rate-item__price-num">
                    {details.fee && details.fee_with_discount && details.fee != details.fee_with_discount ? details.fee_with_discount : details.fee}
                </div> : <div className="rate-item__price-num">{fee}</div>}
                <div className="rate-item__measure">
                    {/*<div className="rate-item__measure-cur">₽</div>*/}
                    <div className="rate-item__period">₽{measure.substr(measure.indexOf('/'))}</div>
                </div>
            </div> : null}
        </div>
        <div className="rate-item__actions">
            {regionSlug ? <a className="u-btn u-btn_default u-btn_adapt_mobile-sm" href={detailUrl}>О тарифе</a> :
                <LinkC className="u-btn u-btn_default u-btn_adapt_mobile-sm" to={detailUrl}>О тарифе</LinkC>}
            {details ? <div className="rate-item__actions-link" onClick={scrollTo}>Изменить тариф</div> :
                <a className="rate-item__actions-link" href="#" onClick={onClick}>Изменить тариф</a>}
            <LinkC className="rate-item__actions-link" to="/services/all">Настроить услуги</LinkC>
        </div>
    </div>
}