import React, {Component} from 'react';
import PropTypes from "prop-types";

class RateCooperation extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    renderElem = (section, elem, key) => {

        let selectedRegion = this.context.selectedRegion;
        let fetchBefore = this.context.fetchBefore;

        let link = "/" + selectedRegion.alias + "/" + section + "/";
        switch (elem.status_id) {
            case 2:
                link += 'archive/';
                break;
            case 3:
                link += 'individual/';
                break;
        }
        link += elem.slug;
        switch (section) {
            case "tariffs":
                return <a href={link}
                          key={key}
                          className="b-tags-list__list-item"
                          onClick={fetchBefore(link)} >
                    {elem.name}
                </a>;
            case "services":
                return <a href={link}
                          key={key}
                          className="b-tags-list__list-item"
                          onClick={fetchBefore(link, elem.slug, null)} >
                    {elem.name}
                </a>;
            default:
                return null;
        }
    };

    render() {

        let items = this.props.items;
        let section = this.props.section;

        return (
            <React.Fragment>
                {items.map((item, idx) =>
                    <div className="b-service-inner__tags-list" key={idx}>
                        <div className="b-tags-list">
                            <div className="b-tags-list__wrapper">
                                <div className="b-tags-list__title">{item.title}</div>
                                <div className="b-tags-list__list">
                                    {item.items.map((elem, key) => this.renderElem(section, elem, key))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default RateCooperation;
