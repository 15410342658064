import PropTypes from "prop-types";
import React, { Component, Fragment } from 'react';
import { mainUrl } from "../../tools/config";
import Icon from '../common/Icon';
import LinkC from "../common/LinkC";

class HeaderNav extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        locationPath: PropTypes.string,
        isLk: PropTypes.bool,
        isB2b: PropTypes.bool,
    };

    isActive(href, path) {
        let sectionPath = path.split('/')[2];
        let sectionNavItem = href.split('/')[1];

        if (!sectionNavItem || href.indexOf('http') > -1) {
            return false;
        }

        if (sectionPath === sectionNavItem) {
            return true;
        }

        return false;
    }

    render() {
        let path = this.context.locationPath;
        const navMenu = this.props.mainMenu;
        const curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 1];

        return (
            <Fragment onClick={this.closeHighlight}>
                {!this.props.isB2b && (
                    this.props.showNewMenuHighlight && (
                        <div className="overlay-visitor overlay-active" onClick={this.closeHighlight}></div>
                    )
                )}

                <div className={`b-header__bottom-line ${(this.props.showNewMenuHighlight && !this.context.isB2b) ? 'highlight-menu-button' : ''}`}>
                    <div className="b-header__short-menu">
                        <div className="page__container">
                            <div className="b-header__menu-holder">
                                <div className="b-short-menu b-short-menu_adapt_default">
                                    <div className="b-short-menu__holder">
                                        <ul className="b-short-menu__level">

                                            {!this.props.isB2b && (
                                                <li className="b-short-menu__level-label-menu" onClick={this.props.openMenu}>
                                                    <div className="b-header__burger-btn flex items-center gap-2 ">
                                                        <p className="b-header__burger-btn-label m-0">
                                                            <Icon name={'icon_menu-burger'} className={'b-header__burger u-icon u-icon_menu-burger'} />
                                                            <Icon name={'icon_cross'} className={'b-header__close-icon u-icon u-icon_cross'} />
                                                            Меню
                                                        </p>

                                                        {this.props.showNewMenuHighlight && (
                                                            <div className="highlight-menu-message">
                                                                Посмотрите, у нас новое меню!
                                                            </div>
                                                        )}
                                                    </div>
                                                </li>
                                            )}

                                            {navMenu.map((item, index) =>
                                                <li className="b-short-menu__item" key={index}>
                                                    <LinkC to={(this.context.isLk && item.link.indexOf('http') !== 0 ? mainUrl + this.context.selectedRegion.alias : "") + item.link}
                                                        func={item.func ? item.func : null}
                                                        className={"b-short-menu__link" + (this.isActive(item.link, path) ? " b-short-menu__link-active" : "")}
                                                        targetBlank={item.new_tab === "1"}>
                                                        {item.title}
                                                    </LinkC>
                                                </li>
                                            )}

                                            {curentPath !== 'search'
                                                && (

                                                    <button
                                                        className="wrap_icon_search_panel"
                                                        onClick={this.props.onOpenSearch}
                                                    >
                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path
                                                                fillRule="nonzero"
                                                                className='search_global'
                                                                d="M19.677 18.199l-3.665-3.665a8.93 8.93 0 0 0 1.94-5.542C17.952 4.034 13.918 0 8.961 0 4.003 0 0 4.065 0 9.022c0 4.958 4.034 8.992 8.992 8.992a8.867 8.867 0 0 0 5.542-1.94l3.665 3.664a1 1 0 0 0 .739.308 1 1 0 0 0 .739-.308c.43-.462.43-1.108 0-1.54zm-3.88-9.146c0 3.757-3.049 6.836-6.805 6.836-3.757 0-6.836-3.08-6.836-6.836 0-3.757 3.048-6.836 6.836-6.836 3.787 0 6.805 3.049 6.805 6.836z"
                                                            />
                                                        </svg>
                                                    </button>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default HeaderNav;