import React, { Component } from 'react';

class Collapse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened
        }
    }

    collapse() {
        this.setState((prevState) => {
            return {opened: !prevState.opened}
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.opened !== this.props.opened && nextProps.opened !== this.state.opened) {
            this.setState({opened: nextProps.opened})
        }
    }

    componentDidMount(){
        if (this.props.openedDefault) {
            this.setState({opened: true})
        }
    }

    render() {
        let {
            title,
            children
        } = this.props;

        return (
            <div className={"b-list-collapser__item" + (this.state.opened ? " b-list-collapser__item_collapsed" : "")}>
                <div className="b-list-collapser__item-header" onClick={this.collapse.bind(this)}>
                    <div className="b-list-collapser__item-title">{title}</div>
                </div>
                <div className={"u-collapser" + (this.state.opened ? " u-collapser_open" : "")}>
                    <div className="u-collapser__container">
                        <div className="b-list-collapser__collapsed-area ">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Collapse;