import React, { Component } from 'react';
import SecurityModal from "./SecurityModal";
import SuccessModal from "../modals/SuccessModal";
import { connect } from "react-redux";
import {clearSmsData} from "../../actions/sms";
import {clearSupportData} from "../../actions/support";

class SecurityMessage extends Component {
    constructor(props) {
        super(props);

        let forms = {};
        props.forms.map((form) => {
            forms[form.alias] = form.settings;
        });

        this.state = {
            modal: null,
            forms
        }
    }

    componentWillReceiveProps(nextProps){
        if (this.props.forms != nextProps.forms) {
            let forms = {};
            nextProps.forms.map((form) => {
                forms[form.alias] = form.settings;
            });
            this.setState({forms});
        }
    }

    renderModal = () => {

        let {
            modal,
            forms
        } = this.state;

        if (typeof document !== 'undefined') {
            if (modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        switch (modal) {
            case "security-modal": return <SecurityModal close={this.closeModal}
                                                         openSuccess={this.openSuccess}
                                                         settings={forms.security_form}
                                                         errors={this.props.supportErrors}
            />;
            case "success" : return <SuccessModal title={forms.security_form_success.security_success_title}
                                                      text={forms.security_form_success.security_success_text}
                                                      btnText="Ок!"
                                                      close={this.closeModal}
            />;
            default:
                return "";
        }
    };

    openSuccess = () => {
        this.setState({modal: "success"});
    };

    openModal = () => {
        this.setState(() => {
            return {
                modal: "security-modal"
            }
        }, () => {
            this.props.clearData();
            this.props.clearSupportData();
        });
    };

    closeModal = () => {
        this.setState({modal: null})
    };

    render() {

        let {forms} = this.state;

        return  (
            <div className="b-support__message-action">
                {forms.security_form_block ? <div className="b-message-action b-message-action_adapt_default">
                    <div className="b-message-action__wrapper">
                        <div className="b-message-action__inner">
                            <div className="b-message-action__title">{forms.security_form_block.security_form_block_title}</div>
                            <div className="b-message-action__text">
                                {forms.security_form_block.security_form_block_text}
                            </div>
                        </div>
                        <div className="b-message-action__action-block">
                            <div className="b-message-action__btn">
                                <div onClick={this.openModal} className="u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive">
                                    Сообщить
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        sms: state.sms,
    }),
    (dispatch) => {
        return {
            clearData: () => dispatch(clearSmsData()),
            clearSupportData: () => dispatch(clearSupportData()),
        }
    }
)(SecurityMessage);