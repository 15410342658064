import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TariffConstructorNew from "../../components/TariffConstructor/TariffConstructorNew";
import ReadyTariffs from "../../components/TariffConstructor/ReadyTariffs";
import TabsSwitcherNew from "../../components/Rates/TabsSwitcherNew";

import { InfoModal, SuccessInfoModalNew, InfoModalNew } from "../../components/modals/constructor/InfoModal";
import TariffConnectionModalNew from "../../components/modals/constructor/TariffConnectionModalNew";
import TariffConnectionCheckBalanceModal from "../../components/modals/constructor/TariffConnectionCheckBalanceModal";
import { axisValues, settings, price, getTariffConstructor, postTariffConstructor, setTariffParams } from "../../actions/constructor";
import InfoBlock from '../../components/common/InfoBlock';
import { tariffsList, tariffFilterSettings, tariffSelectionSettings } from "../../actions/tariffs";
import { tariffInfo, exchangeMinsSend, exchangeMins, services } from "../../actions/client";
import { infoBlocksList } from "../../actions/infoBlocks";
import CurrentTariffBlock from "../../components/Lk/CurrentTariffBlock";
import { getNelementsArray, isEmptyObj, checkAllUrlParams, setCookie } from '../../tools/tools';
import {
    checkWord,
    clearWord,
    clearActivationData,
    checkAvailableConstructor,
    checkOperator,
    clearOperatorData,
    activateTariffConstructor, checkBalanceLack
} from "../../actions/activation";
import { cookieDomain } from "../../tools/config";

import { auth, recovery, clearState } from "../../actions/client";
import SuccessModal from "../../components/modals/SuccessModal";
import AuthorizationModal from "../../components/modals/AuthorizationModal";
import RecoveryModal from "../../components/modals/RecoveryModal";
import { userPhoneValueAction, timerValueAction, tariffConnectionToggleAction, tariffConectionTypeAction } from '../../actions/common';
import TariffLessPriseModal from "../../components/modals/TariffLessPriceModal";
import SuccessForm from "../../components/Forms/SuccessForm";
import {Modal} from "../../components/common/Modal";
import {formsList} from "../../actions/forms";

class RatesMainNew extends Component {

    constructor(props, context) {
        super(props, context);

        let selectionSettings = this.props.selectionSettings;

        this.tabUrls = [
            `/${context.selectedRegion.alias}/tariffs`,
            // `/${context.selectedRegion.alias}/svoi`,
            `/${context.selectedRegion.alias}/tariffs/ready-tariffs`,
            // `/${context.selectedRegion.alias}/svoi/ready-tariffs`,
            `/${context.selectedRegion.alias}/tariffs/dlya-umnyh-veshchey`,
            // `/${context.selectedRegion.alias}/svoi/dlya-umnyh-veshchey`,
            `/${context.selectedRegion.alias}/tariffs/vse`,
            // `/${context.selectedRegion.alias}/svoi/vse`,
        ]

        this.state = {
            isModalNoPrice: false,
            activeTab: this.tabUrls.indexOf(props.location.pathname),

            modal: '',

            hasItemModal: false,
            filterData: {
                internet_traffic: selectionSettings.default_internet_traffic,
                minutes: selectionSettings.tariffMonthCallsDefault,
                sms: selectionSettings.tariffMonthSmsDefault,
                internet_social_unlimit: selectionSettings.tariffSocialUnlimitedDefault,
                internet_music_unlimit: selectionSettings.tariffMusicUnlimitedDefault,
                internet_messenger_unlimit: selectionSettings.tariffMessengerUnlimitedDefault,
                cheap_intercity: selectionSettings.tariffCheapIntercityDefault,
                onlyInternet: selectionSettings.tariffOnlyInternetDefault,
                varianceTraffic: selectionSettings.tariffMonthInternetTrafficVariance,
                varianceMinutes: selectionSettings.tariffMonthCallsVariance,
                varianceSms: selectionSettings.tariffMonthSmsVariance,
                max_traffic: selectionSettings.max_internet_traffic
            },
            selectedRegion: null,
            constructorParams: {},
            readyTariffs: [],
            name: '',
            toggleInputValueTariff: true,
            idTariff: '',
            testWorld: false,
            smsCount: 0,
            optionNames: [],
            dataForUrl: {},
            loadText: 'Пожалуйста, подождите, идет загрузка',
            changeTariffSmsCode: '',
            changeTariffData: {}
        };

        this.priorityFieldsMain = [
            /*{
                field: 'cheap_intercity',
                priority: selectionSettings.tariffCheapIntercityOrder,
                unlimited: false
            },
            {
                field: 'internet_social_unlimit',
                priority: selectionSettings.tariffInternetSocialUnlimitOrder,
                unlimited: false
            },
            {
                field: 'internet_music_unlimit',
                priority: selectionSettings.tariffInternetMusicUnlimitOrder,
                unlimited: false
            },
            {
                field: 'internet_messenger_unlimit',
                priority: selectionSettings.tariffInternetMessengerUnlimitOrder,
                unlimited: false
            },*/
            {
                field: 'internet_gb_count',
                filter: 'byTraffic',
                priority: selectionSettings.tariffTrafficOrder,
                unlimited: true
            },
            {
                field: 'minutes_count',
                filter: 'byCalls',
                priority: selectionSettings.tariffCallsOrder,
                unlimited: true
            },
            {
                field: 'sms_count',
                filter: 'bySms',
                priority: selectionSettings.tariffSmsOrder,
                unlimited: true
            }
        ];

        this.priorityFieldsMain = this.sortSelectionPropsByPriority(this.priorityFieldsMain);

        this.toggleModal = this.toggleModal.bind(this);
        // this.renderModal = this.renderModal.bind(this);

        if (props.currentTariff) {
            props.getInfoBlocksWithTariffs(context.selectedRegion.id, 'tariffs', props.currentTariff.id);
        }

        this.inputTariffNameRef = React.createRef();
    }

    static contextTypes = {
        isGuest: PropTypes.bool,
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func
    };

    static childContextTypes = {
        setHasItemModal: PropTypes.func,
        constructorParams: PropTypes.object,
    };

    static getDerivedStateFromProps(props, state) {
        // if (props.client.exchangeMinsSuccess) {
        //     return {...state, modal: "exchange-success"}
        // }
        // if (props.client.exchangeMinsError) {
        //     return {...state, modal: "exchange-error"}
        // }

        if (!props.isGuest && state.modal === "AuthorizationModal") {
            return { ...state, modal: null }
        }

        if (props.client.fatalError && ["AuthorizationModal", "RecoveryModal"].indexOf(state.modal) > -1) {
            return { ...state, modal: "ErrorModal" }
        }

        if (props.client.successMessageTitle && "RecoveryModal" === state.modal) {
            return { ...state, modal: "SuccessModal" }
        }
        return state;
    }

    componentWillMount() {
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (this.props.tariffs.selectionSettings.length === 0) {
            this.props.getTariffSelectionSettings(this.context.selectedRegion.id);
        }
        // if (this.props.tariffs.filterSettings.length === 0) {
        //     // this.props.getTariffFilterSettings();
        // }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'tariffs');
        }
        if (this.props.currentTariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'tariffs', this.props.currentTariff.id);
        }
        if (!this.props.isGuest && (!this.props.currentTariff || Object.keys(this.props.currentTariff).length < 3)) {
            this.props.getCurrentTariff()
        }
        if (!this.props.isGuest && (!this.props.exchange || !Object.keys(this.props.exchange).length)) {
            this.props.getExchangeMins(this.context.selectedRegion.id)
        }
        if (!this.props.isGuest && !this.props.client.services) {
            this.props.getMyServices()
        }

        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }
        // if (isEmptyObj(this.props.tariffConstructor.defaultData)) {
        //     this.props.getTariffConstructor(this.context.selectedRegion.id);
        // }

        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }
    }

    componentDidMount() {
        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }

        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (this.props.tariffs.selectionSettings.length === 0) {
            this.props.getTariffSelectionSettings(this.context.selectedRegion.id);
        }
        // if (this.props.tariffs.filterSettings.length === 0) {
        //     // this.props.getTariffFilterSettings();
        // }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'tariffs');
        }
        if (this.props.currentTariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'tariffs', this.props.currentTariff.id);
        }
        if (!this.props.isGuest && (!this.props.currentTariff || Object.keys(this.props.currentTariff).length < 3)) {
            this.props.getCurrentTariff()
        }
        if (!this.props.isGuest && (!this.props.exchange || !Object.keys(this.props.exchange).length)) {
            this.props.getExchangeMins(this.context.selectedRegion.id)
        }
        if (!this.props.isGuest && !this.props.client.services) {
            this.props.getMyServices()
        }

        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }

        if (this.state.readyTariffs.length === 0) this.readyTariffsSet();

        if (this.props.location.hash !== '' && this.props.location.hash === '#constructor') {
            const timeout = window.setTimeout(() => {
                window.history.replaceState(null, null, window.location.pathname);
                this.scrollTo();
                window.clearTimeout(timeout)
            }, 1000)
        }

        if (isEmptyObj(this.props.tariffConstructor.defaultData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }

        if (!!this.props.tariffConstructor.defaultData && this.state.optionNames.length === 0 && this.props.tariffConstructor.defaultData && this.props.tariffConstructor.defaultData.options && this.props.tariffConstructor.defaultData.options.length > 0) {
            this.setState({ optionNames: this.props.tariffConstructor.defaultData.options })
        }

        // очищаем ошибки и убираем connectionType="svoi"
        if (this.state.activeTab !== 0) {
            this.props.tariffConectionTypeAction('');
            this.props.clearActivationData();
        }

        this.props.getFormsList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextContext.selectedRegion != this.context.selectedRegion) {
            this.props.getTariffSelectionSettings(nextContext.selectedRegion.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tariffConstructor.recalcData.constructor !== this.props.tariffConstructor.recalcData.constructor && !!this.props.tariffConstructor.recalcData.constructor.sms_count) {
            if (this.state.smsCount !== this.props.tariffConstructor.recalcData.constructor.sms_count) {
                this.setState({ smsCount: +this.props.tariffConstructor.recalcData.constructor.sms_count });
            }
        }

        if (!prevProps.currentTariff && this.props.currentTariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'tariffs', this.props.currentTariff.id);
        }
        if (prevProps.isGuest && !this.props.isGuest) {
            if (!this.props.exchange || !Object.keys(this.props.exchange).length) {
                this.props.getExchangeMins(this.context.selectedRegion.id)
            }
            if (!this.props.client.services) {
                this.props.getMyServices()
            }
        }

        let word = this.props.activation.word;
        if (word !== null && this.state.testWorld) {
            if (word.badWord) {
                this.setState({ modal: 'wrong-name', name: '', testWorld: false });
            } else {
                this.setState({ modal: 'tariff-connection', testWorld: false });
            }
            this.props.clearWord();
        }

        if (this.props.forms && this.props.forms.isSubmitOk != prevProps.forms.isSubmitOk && this.props.forms.isSubmitOk && this.props.connectionType === 'svoi') {
            if(this.props.forms.isSubmitOk === 'order_sent_failed') {
                this.openSuccess("repeat-mnp-modal")
            } else {
                this.openSuccess("success")
            }
        }

        // if (prevState.modal !== this.state.modal && this.state.modal === 'tariff-connection') {
        //     if (typeof document !== "undefined") {
        //         if (this.props.isGuest) {
        //             const el = document.querySelector('.phone_focus');
        //             if (el) el.focus();
        //         } else {
        //             const el = document.querySelector('.email_focus');
        //             if (el) el.focus();
        //         }
        //     }
        // }

        if (this.state.readyTariffs.length === 0) {
            this.readyTariffsSet();
        }

        if (!!this.props.tariffConstructor.recalcData && !!this.props.tariffConstructor.recalcData.options) {
            if (JSON.stringify(prevProps.tariffConstructor.recalcData.options) !== JSON.stringify(this.props.tariffConstructor.recalcData.options)) {
                const arr = this.props.tariffConstructor.recalcData.options;
                const copyOptionNames = [...this.state.optionNames];
                let optionNames = [];

                if (copyOptionNames.length < 7) {
                    for (let i = 0; i < arr.length; i++) {
                        const item = copyOptionNames.find(x => x.type === arr[i].type);

                        if (!item) {
                            optionNames.push(arr[i]);
                        }

                    }

                    this.setState({ optionNames: [...copyOptionNames, ...optionNames] })
                }
            }
        }


        let userphone = this.props.user && this.props.user.username
        // обновляем запрос после входа в лк (для отображения скидки)
        // возникает баг с дублированием опций
        //         if (userphone !== prevProps.user.username && !this.props.isGuest && !isEmptyObj(this.props.tariffConstructor.recalcData)) {
        //             let recalcData = this.props.tariffConstructor && this.props.tariffConstructor.recalcData.constructor;
        //             let min = this.state.constructorParams && this.state.constructorParams.min;
        //             let options = recalcData.options ? recalcData.options : [];

        //             let {
        //                 sms,
        //                 gb,
        //                 social_unlim,
        //                 data_unlim,
        //                 motiv_voice_unlim,
        //                 router,
        //                 youtube_unlim,
        //                 ivi,

        //                 // currentSmsPack,
        //                 // cost_type,
        //                 // user_cost,
        //             } = this.state.constructorParams

        //             let data = +gb === 0 ? 1 : gb;
        //             // currentSmsPack === 'SMS_PACK_0' ? 'SMS_PACK_0' : options.push(currentSmsPack);
        //             social_unlim === 1 ? options.push('SOCIAL_UNLIM') : null;
        //             data_unlim === 1 ? options.push('DATA_UNLIM') : null;
        //             motiv_voice_unlim === 1 ? options.push('MVOICE_UNLIM') : null;
        //             router === 1 ? options.push('ROUTER') : null;
        //             youtube_unlim === 1 ? options.push('YOUTUBE_UNLIM') : null;
        //             ivi === 1 ? options.push('IVI') : null;

        // // console.log('currentSmsPack',currentSmsPack);
        // // console.log('cost_type',cost_type);
        // // console.log('options',options);

        //             const tariff = {
        //                 min,
        //                 data,
        //                 options,
        //                 // cost_type,
        //                 // user_cost
        //             }

        //             this.passingParams(tariff, this.context.selectedRegion.id);
        //         }

        if (prevState.modal && !this.state.modal) {
            this.props.clear();
        }

        // выводим ошибку если axis не пришел
        if (!this.props.tariffConstructor.axis) {
            setTimeout(() => {
                this.setState({
                    loadText: 'Возникла техническая ошибка, пожалуйста, попробуйте позже.'
                });
            }, 5000);
        }
    }

    // записывает состояние выбранной радиокнопки СМС
    setSmsCount = (count) => {
        this.setState({ smsCount: +count });
    }
    // записывает состояние dataForUrl
    getDataForUrl = (data) => {
        this.setState({ dataForUrl: data });
    }

    readyTariffsSet = () => {
        let tariffs = [];
        let count = 0;
        if (this.props.tariffs && this.props.tariffs.tariffsLists && this.props.tariffs.tariffsLists.length > 0 && this.state.readyTariffs.length === 0) {
            const allTariffsLists = [...this.props.tariffs.tariffsLists];
            let tarifsListSvoi = [];

            allTariffsLists.forEach(el => {
                if (el.slug.indexOf('svoi') !== -1) {
                    tarifsListSvoi.push(el);
                }
            })

            let amountElem = tarifsListSvoi && tarifsListSvoi.length >= 4 ? 4 : tarifsListSvoi.length;
            tariffs = getNelementsArray(tarifsListSvoi, amountElem);
            this.setState({
                readyTariffs: tariffs
            })
        }
    }

    getChildContext() {
        return {
            constructorParams: this.state.constructorParams,
            setHasItemModal: (val) => {
                this.setState({ hasItemModal: val })
            }
        }
    }

    toggleModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState((prevState) => {
                return {
                    modal
                }
            })
        }
    }

    // 
    hideScroll = () => {
        // let scrollTop = 0;
        // if (typeof document != "undefined") {
        //     document.body.classList.add("no-scroll");
        //     scrollTop = window.pageYOffset;
        //     document.body.style.position = "fixed";
        //     document.body.style.top = -scrollTop + 'px';
        // }
        if (typeof document != "undefined") {
            const el = document.querySelector('.page');
            if (el) el.classList.add('no-scroll');
        }
    };

    showScroll = () => {
        // if (typeof document != "undefined") {
        //     document.body.classList.remove('no-scroll');
        //     document.body.style.position = '';
        //     document.body.style.top = '';
        //     window.scroll(0, this.scrollTop);
        // }
        if (typeof document != "undefined") {
            const el = document.querySelector('.page');
            if (el) el.classList.remove('no-scroll');
        }
    };

    scrollTo = () => {
        this.smoothScroll('.tariffs_tabs');
        // this.smoothScroll('.b-internet-ipad__tarifs-list');
    }

    onForgotPasswordClickHandler = () => {
        this.setState(() => {
            return {
                modal: null
            }
        }, () => {
            this.openModal('AuthorizationModal');
        });
    };
    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        }, () => {
            this.props.clearData ? this.props.clearData() : "";
            this.props.clearSupportData ? this.props.clearSupportData() : "";
        });
        this.hideScroll();
    };
    closeModal = (e) => {
        if (e) e.preventDefault();
        this.props.clearActivationData();
        this.props.userPhoneValueAction('');
        this.setState({ modal: '' });
        this.showScroll();
    };

    checkBalance = (code, tariffData) => {
        this.setState({changeTariffSmsCode: code, changeTariffData: {...tariffData}})
        let recObj = this.props.tariffConstructor && this.props.tariffConstructor.recalcData;
        let defObj = this.props.tariffConstructor && this.props.tariffConstructor.defaultData;
        let abonPrice = isEmptyObj(recObj)
            ? defObj.abon_discount ? defObj.abon_discount : defObj.abon
            : recObj.abon_discount ? recObj.abon_discount : recObj.abon

        this.props.userPhoneValueAction(this.props.userPhoneValue);
        this.props.checkBalanceLack({
            abnum: this.props.userPhoneValue,
            abon: abonPrice,
            queryId: this.props.activation.available.query_id,
            code
        });
    }

    activateTariff = () => {
        this.props.activateTariff({...this.state.changeTariffData}, this.context.selectedRegion.id);
    }

    renderModal() {
        let {
            tariffConstructor,
            userPhoneValue,
            settings,
            activation,
            isGuest,
            tariffConnectionValue,
            user,
            connectionType
        } = this.props;

        let selectedRegion = this.context.selectedRegion;

        let constructor = this.state.constructorParams;

        let tariffName = this.state.name ? this.state.name : "Свой_тариф";
        let recObj = tariffConstructor && tariffConstructor.recalcData;
        let tariffSum = recObj && recObj.abon;

        let data_unlim = constructor.data_unlim;
        let data = constructor.gb;
        let router = constructor.router;
        let min = constructor.min;
        let social_unlim = constructor.social_unlim;
        let motiv_voice_unlim = constructor.motiv_voice_unlim;
        let youtube_unlim = constructor.youtube_unlim;
        let ivi = constructor.ivi;
        let home_region_checked = constructor.home_region_checked
        let smsCount = this.state.smsCount;

        const objOptionNames = {};
        if (tariffConstructor.recalcData.options) {
            tariffConstructor.recalcData.options.forEach(el => { objOptionNames[el.type] = el.name })
        } else if (tariffConstructor.defaultData.options) {
            tariffConstructor.defaultData.options.forEach(el => { objOptionNames[el.type] = el.name })
        }

        let {
            authFormError,
            formsSettings,
            successMessageTitle,
            successMessage,
            recoveryFormError,
            fatalErrorTitle,
            fatalError
        } = this.props.client

        if (typeof document !== 'undefined') {
            if (this.state.modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        switch (this.state.modal) {
            case "phone-check":
                return <InfoModalNew
                    close={this.closeModal}
                    tariffName={tariffName}
                    tariffSum={tariffSum}
                    userPhone={userPhoneValue}
                    user={user}
                    connectionType={connectionType}
                    isGuest={isGuest}
                    activation={activation}
                    selectedRegion={selectedRegion}
                    checkAvailableConstructor={this.props.checkAvailableConstructor}
                    timerValueAction={this.props.timerValueAction}
                    tariffConnectionToggleAction={this.props.tariffConnectionToggleAction}
                    checkOperator={this.props.checkOperator}
                    tariffConectionTypeAction={this.props.tariffConectionTypeAction}
                    onClickConnect={this.openTariffConnectionModal}
                    settings={this.props.settings}
                />;

            case "wrong-name":
                return <InfoModal
                    close={this.closeModal}
                />;

            case "tariff-connection":
                return <TariffConnectionModalNew
                    close={this.closeModal}
                    tariffName={tariffName}
                    recObj={recObj}
                    user={user}
                    tariffConnectionValue={tariffConnectionValue}
                    userPhone={userPhoneValue}
                    openSuccess={this.openSuccess}
                    settings={this.props.settings}
                    smsCount={smsCount}
                    constructor={constructor}
                    checkBalance={isGuest ? (code, tariffData) => {this.checkBalance(code, tariffData)} : null}
                    isTariffConnection={isGuest}
                />;
            case "tariff-connection-check":
                return <TariffConnectionCheckBalanceModal
                    close={this.closeModal}
                    tariffName={tariffName}
                    recObj={recObj}
                    user={user}
                    tariffConnectionValue={tariffConnectionValue}
                    userPhone={userPhoneValue}
                    openSuccess={this.openSuccess}
                    settings={this.props.settings}
                    smsCount={smsCount}
                    constructor={constructor}
                    checkBalance={isGuest ? (code, tariffData) => {this.checkBalance(code, tariffData)} : null}
                />;
            case "tariff-balance-lack":
                return <TariffLessPriseModal
                    title={'На вашем счёте недостаточно денег для списания абонентской платы по новому тарифу'}
                    text={'На вашем счёте недостаточно денег для списания абонентской платы по новому тарифу.<br/><br/> <b>Рекомендуемый баланс 96.0 руб.</b><br/><br/> В случае перехода на тариф сейчас, тариф не активируется до пополнения баланса, а звонки и смс будут предоставляться платно.<br/><br/> <b>Вы уверены, что хотите перейти?</b>'}
                    icon={true}
                    close={this.closeModal}
                    region={this.context.selectedRegion.alias}
                    className="u-modal u-modal-tariff-less"
                    wrapperClassName="u-modal-wrapper tariff-less-modal"
                    openSuccess={this.openSuccess}
                    acceptChangeTariff={() => {
                        if(isGuest) {
                            this.activateTariff()
                        } else {
                            this.openTariffConnectionModal('tariff-connection')
                        }
                    }}
                />;
            case "success":
                return <SuccessInfoModalNew
                    close={this.closeModal}
                    title="Ваша заявка принята!"
                    text={settings.submitting_request_mnp_description}
                    btnText="Отлично"
                />;
            case "success-tariff-connection":
                return <SuccessInfoModalNew
                    close={this.closeModal}
                    title={`«${tariffName}» <br/> успешно подключен`}
                    btnText="Готово"

                    constructor={constructor}
                    dataForUrl={this.state.dataForUrl}
                    apiOptionNames={objOptionNames}
                    settings={settings}
                    smsCount={smsCount}

                    gb={data}
                    min={min}
                    data_unlim={data_unlim}
                    router={router}
                    social_unlim={social_unlim}
                    motiv_voice_unlim={motiv_voice_unlim}
                    youtube_unlim={youtube_unlim}
                    ivi={ivi}
                    home_region_checked={home_region_checked}
                />;

            case "AuthorizationModal":
                return <AuthorizationModal
                    login={this.props.auth}
                    onForgetLinkClick={
                        (e) => {
                            e.preventDefault();
                            this.openModal("RecoveryModal")
                        }
                    }
                    error={authFormError}
                    close={this.closeModal}
                    subtitle={formsSettings && formsSettings.auth_form.auth_form_description}
                    authFormSettings={formsSettings ? formsSettings.auth_form : {}}
                    qrText={this.props.qrText}
                />;
            case "RecoveryModal":
                return <RecoveryModal
                    close={this.closeModal}
                    recover={this.props.recover}
                    error={recoveryFormError}
                    modal={this.state.modal}
                    recoveryFormSettings={formsSettings ? formsSettings.recovery_form : {}}
                />;

            case "ErrorModal":
                return <SuccessModal
                    title={fatalErrorTitle}
                    text={fatalError}
                    icon={false}
                    btnText="ОК"
                    close={this.closeModal}
                />;
            case "SuccessModal":
                return <SuccessModal
                    title={successMessageTitle}
                    text={successMessage}
                    icon={true}
                    btnText="ОК"
                    close={this.onForgotPasswordClickHandler}
                />;
            case "repeat-mnp-modal":
                const form = this.props.forms.list.filter((item) => {return item.alias === "order_sent_failed"});
                const title = form[0].settings.hasOwnProperty('mnp_form_registry_title') ? form[0].settings['mnp_form_registry_title'] : "Ваша заявка принята";
                const text = form[0].settings.hasOwnProperty('mnp_form_registry_description') ? form[0].settings['mnp_form_registry_description'].replace("{{registry_time}}", form[0].settings['mnp_form_registry_time']) : "";

                return <Modal close={this.closeModal} isFromSuccessModal={true}>
                    <SuccessForm title={title} text={text} icon={false} />
                </Modal>;

            default:
                return null;
        }
    }
    // переключение категорий
    toggleCat = (data) => {
        this.context.pushHistory(this.tabUrls[data])

        this.setState({
            activeTab: +data
        });
    };

    toogleTabDel = (modal) => {
        this.setState({
            modal: modal
        });
    }

    // меняем название тарифа
    onTariffNameChange = (e) => {
        this.setState({ name: e.target.value.replace(/[^А-яа-я\-_]/gim, '').substr(0, 12) }, () => {
            this.setName(this.state.name);
        });
    };
    setName = (name) => {
        let constructorParams = { ...this.state.constructorParams };
        constructorParams.name = name;
        this.setState({ constructorParams })
    };

    // кнопка разблокирования для написания имени тарифа
    toggleTariffBtn = () => {
        this.setState({
            toggleInputValueTariff: false
        }, () => {
            this.inputTariffNameRef.current.focus();
        });
    }
    onBlurName = (e) => {
        this.setState({ toggleInputValueTariff: true });
        if (e.target.value) {
            this.props.checkWord(e.target.value)
        }
    }

    onMainFilterChange = (filterData) => {
        this.setState({ filterData: filterData });
    };

    filterByRegion(tariffs, region_id) {
        return tariffs.filter(x => x.region_id === region_id);
    }

    needToFilter = (checkBoxName) => {
        let availableFilters = this.props.selectionSettings.filters;
        return availableFilters && availableFilters.indexOf(checkBoxName) !== -1;
    };

    //filters For Main Form Filter
    filterByTraffic = (item) => {
        let sliderTraffic = this.state.filterData.internet_traffic;
        let maxTraffic = this.state.filterData.max_traffic;
        let gb = item.internet_gb_count;
        let variance = this.state.filterData.varianceTraffic * maxTraffic / 100;

        if (sliderTraffic === 0) {
            if (gb === null) {
                return true;
            }
        } else {
            if (gb === null) {
                return false;
            }
        }

        if ((maxTraffic === sliderTraffic || maxTraffic < sliderTraffic) && gb === "Безлимит") {
            return true;
        }

        if (gb >= sliderTraffic && gb <= sliderTraffic + variance) {
            return true;
        }

        return false;
    };

    filterByCalls = (item) => {
        let mCheckbox = this.state.filterData.minutes;
        let separator = this.props.selectionSettings.minutes_separator;
        let minutes = item.minutes_count;
        let variance = this.state.filterData.varianceMinutes * separator / 100;

        if (mCheckbox === 'often') {
            if (minutes === "Безлимит" || minutes >= separator) return true;
        }
        if (mCheckbox === "rare") {
            if (minutes === null || minutes < separator + variance) return true;
        }
        return false;
    };

    filterBySms = (item) => {
        let mCheckbox = this.state.filterData.sms;
        let separator = this.props.selectionSettings.sms_separator;
        let sms = item.sms_count;

        let variance = this.state.filterData.varianceSms * separator / 100;

        if (mCheckbox === 'many') {
            if (sms === "Безлимит" || sms >= separator) return true;
        }
        if (mCheckbox === 'few') {
            if (sms === null || sms < separator + variance) return true;
        }
        return false;
    };

    sortByPriority = (tariffs, fields) => {

        let data = this.state.filterData;
        let unlim = 'Безлимит';
        let maxInt = Number.MAX_SAFE_INTEGER;

        tariffs.sort(function (t1, t2) {

            let result = 0;

            for (let i = 0, len = fields.length; i < len; i++) {

                let item = fields[i];

                if (t2[item.field] === t1[item.field]) {
                    continue;
                }
                if (data.onlyInternet && (item.field === 'minutes_count' || item.field === 'sms_count' || item.field === 'cheap_intercity')) {
                    continue;
                }

                if (item.unlimited) {
                    if (item.field === 'internet_gb_count') {
                        if (t2[item.field] === unlim) {
                            result = t1[item.field] - maxInt;
                            break;
                        }
                        if (t1[item.field] === unlim) {
                            result = maxInt - t2[item.field];
                            break;
                        }
                        result = t1[item.field] - t2[item.field];
                        break;
                    } else {
                        if (t2[item.field] === unlim) {
                            result = maxInt - t1[item.field];
                            break;
                        }
                        if (t1[item.field] === unlim) {
                            result = t2[item.field] - maxInt;
                            break;
                        }
                    }
                }
                result = t2[item.field] - t1[item.field];
                break;
            }
            return result;
        });

        return tariffs;
    };

    excludeSame(fromArr, arr) {
        let result = [];

        fromArr.filter((item) => {
            if (arr.filter((elem) => {
                return item.id === elem.id
            }).length === 0) {
                result.push(item);
            }
        });

        return result;
    };

    sortSuitable = (tariffs, fields, count) => {

        let data = this.state.filterData;
        let unlim = 'Безлимит';
        let maxInt = this.state.filterData;
        let selectionSettings = this.props.selectionSettings;

        tariffs.sort(function (t1, t2) {

            let result = 0;

            for (let i = 0, len = fields.length; i < len; i++) {

                let item = fields[i];

                if (t2[item.field] === t1[item.field]) {
                    continue;
                }
                if (data.onlyInternet && (item.field === 'minutes_count' || item.field === 'sms_count' || item.field === 'cheap_intercity')) {
                    continue;
                }

                if (item.unlimited) {
                    if (item.field === 'internet_gb_count') {

                        if (t2[item.field] === unlim) {
                            result = maxInt - t1[item.field];
                            break;
                        }
                        if (t1[item.field] === unlim) {
                            result = t2[item.field] - maxInt;
                            break;
                        }
                        result = t2[item.field] - t1[item.field];
                        break;
                    } else {
                        if (item.field === 'minutes_count') {
                            result = Math.abs(selectionSettings.minutes_separator - t2[item.field]) - Math.abs(selectionSettings.minutes_separator - t1[item.field]);
                        }
                        if (item.field === 'sms_count') {
                            result = Math.abs(selectionSettings.sms_separator - t2[item.field]) - Math.abs(selectionSettings.sms_separator - t1[item.field]);
                        }
                    }
                }
                result = t2[item.field] - t1[item.field];
                break;
            }
            return result;
        });

        return tariffs.slice(0, count);
    };

    openSuccess = (modal) => {
        if (!this.props.isGuest && modal === 'success-tariff-connection') {
            setCookie('has_connection', new Date().getTime() + 300000, { expires: 300, path: '/', domain: cookieDomain })
        }
        this.setState({ modal: modal });
    };

    sortSelectionPropsByPriority(props) {
        props = props.sort(function (f1, f2) {
            return f2.priority - f1.priority;
        });

        for (let i = 0, len = props.length; i < len; i++) {
            if (i + 1 !== len) {
                if (props[i].priority === props[i + 1].priority) {
                    props[i].sortByPrice = true;
                }
            }
        }

        return props;
    }

    // открывает модальное окно нехватки баланса для смены тарифа
    openBalanceLackModal = (modal) => {
        this.setState({modal})
    }

    // открывает начальное модальное окно если название тарифа прошло проверку
    openTariffConnectionModal = (modal, idTariff) => {
        if (!!idTariff && this.state.idTariff === '') {
            this.setState({
                idTariff
            })
        }

        if (modal === 'tariff-connection' || modal === 'tariff-connection-check') {
            if (this.state.name !== "") {
                this.props.checkWord(this.state.name);
                this.setState({ testWorld: true });
            } else {
                this.setState({ modal });
            }
        } else {
            this.setState({ modal });
        }

        this.hideScroll();
    };

    onTrafficChange = (traffic) => {
        this.setState({ traffic })
    };

    sortByField = (tariffs, field) => {

        let maxInt = Number.MAX_SAFE_INTEGER;

        return tariffs.sort(function (t1, t2) {
            if (t2[field] === 'Безлимит') return maxInt - t1[field];
            if (t1[field] === 'Безлимит') return t2[field] - maxInt;

            return t2[field] - t1[field];
        });
    };

    getPrice = (min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router = 0, youtube_unlim = 0, ivi = 0, currentSmsPack, cost_type, user_cost, home_region_checked, first_in = 0) => {
        this.setState({
            constructorParams: {
                min,
                gb,
                sms,
                data_unlim,
                router,
                social_unlim,
                motiv_voice_unlim,
                youtube_unlim,
                ivi,
                home_region_checked,

                currentSmsPack,
                cost_type,
                user_cost
            }
        });

        let options = [];
        let data = +gb === 0 ? 1 : gb;

        options.push(currentSmsPack === '' ? 'SMS_PACK_0' : currentSmsPack);
        social_unlim === 1 ? options.push('SOCIAL_UNLIM') : null;
        data_unlim === 1 ? options.push('DATA_UNLIM') : null;
        motiv_voice_unlim === 1 ? options.push('MVOICE_UNLIM') : null;
        router === 1 ? options.push('ROUTER') : null;
        youtube_unlim === 1 ? options.push('YOUTUBE_UNLIM') : null;
        ivi === 1 ? options.push('IVI') : null;
        home_region_checked === 1 ? options.push('HOME_REGION_CHECKED') : null;

        const tariff = {
            min,
            data,
            cost_type,
            user_cost,
            options,

            first_in
        }

        // this.props.postTariffConstructor(tariff, this.context.selectedRegion.id);
        this.passingParams(tariff, this.context.selectedRegion.id);
    };

    async passingParams(tariff, regionId) {
        await this.props.setTariffParams(tariff, regionId);
        await this.props.postTariffConstructor(tariff, regionId);
    }

    sortBySubscribtion = (tariffs) => {
        return tariffs.sort(function (t1, t2) {
            return t1.subscription_fee - t2.subscription_fee;
        });
    };

    currentYPosition = () => {
        if (typeof document !== "undefined") {
            if (self.pageYOffset) return self.pageYOffset;
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        }
    }

    elmYPosition = (eID) => {
        if (typeof document !== "undefined") {
            let elm = document.querySelector(eID);
            let y = elm.offsetTop;
            let node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        }
    }

    smoothScroll = (eID) => {
        let startY = this.currentYPosition();
        let stopY = this.elmYPosition(eID);
        let distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
            scrollTo(0, stopY); return;
        }
        let speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        let step = Math.round(distance / 25);
        let leapY = stopY > startY ? startY + step : startY - step;
        let timer = 0;
        if (stopY > startY) {
            for (let i = startY; i < stopY; i += step) {
                setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                leapY += step; if (leapY > stopY) leapY = stopY; timer++;
            } return;
        }
        for (let i = startY; i > stopY; i -= step) {
            setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
            leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }
    }

    filterByMainFilter = (tariffs, excludedFilters = [], includedFilters = ['byTraffic', 'byCalls', 'bySms']) => {
        let data = this.state.filterData;
        let result = tariffs;
        let selectionSettings = this.props.selectionSettings;

        if (!selectionSettings.hasOwnProperty('filters')) {
            return result;
        }

        if (excludedFilters.indexOf('byTraffic') === -1 && includedFilters.indexOf('byTraffic') !== -1) {
            result = result.filter(this.filterByTraffic);
        }

        if (!data.onlyInternet) {
            if (excludedFilters.indexOf('byCalls') === -1 && includedFilters.indexOf('byCalls') !== -1) {
                result = result.filter(this.filterByCalls);
            }
            if (excludedFilters.indexOf('bySms') === -1 && includedFilters.indexOf('bySms') !== -1) {
                result = result.filter(this.filterBySms);
            }
            if (this.needToFilter('cheap_intercity') && data.cheap_intercity) {
                result = result.filter(x => x.cheap_intercity === true);
            }
        }
        if (this.needToFilter('internet_social_unlimit') && data.internet_social_unlimit) {
            result = result.filter(x => x.internet_social_unlimit === true);
        }

        if (this.needToFilter('internet_messenger_unlimit') && data.internet_messenger_unlimit) {
            result = result.filter(x => x.internet_messenger_unlimit === true);
        }

        if (this.needToFilter('internet_music_unlimit') && data.internet_music_unlimit) {
            result = result.filter(x => x.internet_music_unlimit === true);
        }
        return result;
    };

    render() {
        let {
            sliderParams,
            currentTariff,
            regions,
            location,
            tariffConstructor,
            settings,
            price,
        } = this.props;

        let {
            filterData,
            constructorParams,
            readyTariffs,
            name,
            smsCount,
            activeTab,
            loadText,
            isModalNoPrice
        } = this.state;
        let selectedRegion = this.context.selectedRegion;
        const axis = tariffConstructor && tariffConstructor.axis;

        let tariffs = this.filterByRegion(this.props.tariffs.tariffsLists, selectedRegion.id);
        let selectionSettings = this.props.selectionSettings;
        let filterFunc = this.filterByMainFilter;
        let excludeSame = this.excludeSame;
        let sortBySub = this.sortBySubscribtion;

        let filteredTariffs = filterFunc(tariffs);
        let priorityFieldsMain = this.priorityFieldsMain;
        let placeholder = 'Свой_тариф';
        let defaultParams = true;

        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            if (!defaultParams) defaultParams = true;
        } else {
            if (!!defaultParams) defaultParams = false;
        }

        if (defaultParams && (!!tariffConstructor.defaultData.constructor && !!tariffConstructor.defaultData.constructor.alias)) {
            placeholder = tariffConstructor.defaultData.constructor.alias;
        }
        if (!defaultParams && tariffConstructor.recalcData.constructor && tariffConstructor.recalcData.constructor.alias) {
            placeholder = tariffConstructor.recalcData.constructor.alias;
        }

        let canRecommend = false;
        if (filteredTariffs.length > 0) {
            canRecommend = true;
        }

        if (filteredTariffs.length > 4) {
            //добавить сортировку по стоимости
            filteredTariffs = sortBySub(filteredTariffs.slice(0, 4));
        } else {
            let tatiffsWithoutFiltered = excludeSame(tariffs, filteredTariffs);
            let additionalTariffs = [];

            //ни одного чекбокса
            if ((!this.needToFilter('cheap_intercity') || !filterData.cheap_intercity) &&
                (!this.needToFilter('internet_social_unlimit') || !filterData.internet_social_unlimit) &&
                (!this.needToFilter('internet_messenger_unlimit') || !filterData.internet_messenger_unlimit) &&
                (!this.needToFilter('internet_music_unlimit') || !filterData.internet_music_unlimit)
            ) {

                priorityFieldsMain.forEach(function (item, idx) {
                    if (filteredTariffs.length < 4 && idx < 2) {
                        if (idx === 0) {
                            additionalTariffs = filterFunc(tatiffsWithoutFiltered, [priorityFieldsMain[2].filter]);
                        }
                        if (idx === 1) {
                            additionalTariffs = filterFunc(tatiffsWithoutFiltered, [priorityFieldsMain[2].filter, priorityFieldsMain[1].filter]);
                        }
                        if (additionalTariffs.length > 0) {
                            filteredTariffs = filteredTariffs.concat(sortBySub(additionalTariffs));
                            tatiffsWithoutFiltered = excludeSame(tariffs, filteredTariffs);
                        }
                    }
                });
            }

            if (filteredTariffs.length < 4) {
                additionalTariffs = filterFunc(tatiffsWithoutFiltered, ['byTraffic', 'byCalls', 'bySms'], []);
                additionalTariffs = this.sortByField(additionalTariffs, priorityFieldsMain[0].field);
                filteredTariffs = filteredTariffs.concat(sortBySub(additionalTariffs)).slice(0, 4);
            }
        }

        let infoBlocks = this.props.infoBlocks.list;

        if (this.props.currentTariff && !this.state.hasItemModal) {
            tariffs = tariffs.filter((item) => item.id !== this.props.currentTariff.id)
        }

        let modifiedUrl = this.props.location && this.props.location.pathname;
        let urlStr = modifiedUrl.substr(1).split('-');
        // let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        // let routeSeo = activeTab === 0 ? routes.list.find(x => x.sys_name === 'tariffsRoute')
        //     : activeTab === 1 ? routes.list.find(x => x.sys_name === 'tariffsDlyaUmnyhVeshcheyRoute')
        //         : activeTab === 2 ? routes.list.find(x => x.sys_name === 'tariffsVseRoute')
        //             : null;
        // const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder page__main-holder__tariffs page__new_tariffs" role="main">
                {/* <Helmet
                    htmlAttributes={{ "class": "pp-mod_services-inner" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        { "name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : "" }
                    ]}>
                    <link rel="canonical" href={pageLink} />
                </Helmet> */}

                {/*{*/}
                {/*    isModalNoPrice &&*/}
                {/*    <div>*/}
                {/*        <TariffLessPriseModal*/}
                {/*            title={'На вашем счёте недостаточно денег для списания абонентской платы по новому тарифу'}*/}
                {/*            text={'Рекомендуемый баланс: 80 руб. <br /> <br />В случае перехода на тариф сейчас, неиспользованные остатки (при наличии) сгорят, а звонки и смс будут предоставляться платно. <br />Вы уверены, что хотите перейти?'}*/}
                {/*            icon={true}*/}
                {/*            btnText="Ок"*/}
                {/*            close={() => {this.setState({isModalNoPrice: false})}}*/}
                {/*            className="u-modal u-modal-tariff-less"*/}
                {/*            wrapperClassName="u-modal-wrapper tariff-less-modal"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*}*/}
                <div className="page__container">
                    <h1 className="page__title">Тарифы</h1>

                    {this.props.isGuest
                        ? null
                        : <CurrentTariffBlock
                            {...this.props.currentTariff}
                            regionSlug={this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug}
                            remains={this.props.remains}
                            onClick={() => this.onToggleTab(1)}
                            scrollTo={this.scrollTo}
                        />
                    }
                    {this.props.isGuest
                        ? null :
                        <InfoBlock
                            type="advert_service_block"
                            section="advert_service_block"
                            data={infoBlocks}
                            additionalProps={{
                                exchangeId: this.props.lkSettings && this.props.lkSettings.exchange_service_id,
                                showModal: this.toggleModal,
                                canCollapse: true,
                                canExchange: this.props.exchange && this.props.exchange.is_available && this.props.exchange.max_minutes && this.props.exchange.allowForTariff,
                                myServices: this.props.client.services ? this.props.client.services.map((item) => item.id_billing) : []
                            }}
                        />
                    }

                    <div className="tariffs_tabs">
                        <TabsSwitcherNew
                            toggleCat={this.toggleCat}
                            activeTab={activeTab}
                        />
                    </div>

                    <ul className="tabs__content" ref="content">
                        <li className={`tabs-content__item ${activeTab === 0 ? " tabs-content__item--active" : ""}`}>
                            {!!axis
                                ? <TariffConstructorNew
                                    selectedRegion={selectedRegion}
                                    name={name}
                                    inputTariffNameRef={this.inputTariffNameRef}
                                    onTariffNameChange={this.onTariffNameChange}
                                    onBlurName={this.onBlurName}
                                    toggleInputValueTariff={this.state.toggleInputValueTariff}
                                    toggleTariffBtn={this.toggleTariffBtn}
                                    price={price}
                                    settings={settings}
                                    axis={axis}
                                    sliderParams={sliderParams}
                                    regions={this.props.regions}
                                    location={this.props.location}
                                    modifiedUrl={modifiedUrl}
                                    urlStr={urlStr}
                                    getPrice={this.getPrice}
                                    getPriceCost={this.getPriceCost}
                                    getSettings={this.props.getSettings}
                                    constructorParams={constructorParams}
                                    onClickConnect={this.openTariffConnectionModal}
                                    currentTariff={currentTariff}
                                    placeholder={placeholder}
                                    defaultParams={defaultParams}
                                    smsCount={smsCount}
                                    setSmsCount={this.setSmsCount}
                                    getDataForUrl={this.getDataForUrl}
                                    openModal={this.openModal}
                                    activateTariff={this.activateTariff}
                                />
                                : <div className="tabs__error">{loadText}</div>
                            }
                        </li>
                        <ReadyTariffs location={this.props.location} activeTab={activeTab} tariffsRT={tariffs} toggleCat={this.toggleCat} />
                    </ul>
                </div>
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        filterSettings: state.tariffs.filterSettings,
        selectionSettings: state.tariffs.selectionSettings,

        client: state.client,
        isGuest: state.client.isGuest,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
        currentTariff: state.client.clientInfo.tariff,
        remains: state.client.clientInfo.remains,
        exchange: state.client.clientInfo.exchange,
        formsSettings: state.client.formsSettings,
        lkSettings: state.client.settings,
        user: state.client.clientInfo,

        sliderParams: state.constructor.params,
        settings: state.constructor.settings,
        price: state.constructor.price,

        infoBlocks: state.infoBlocks,
        regions: state.regions,
        activation: state.activation,
        forms: state.forms,
        tariffConstructor: state.tariffConstructor,
        routes: state.routes,
        qrText: state.settings.qr_code_description,

        userPhoneValue: state.common.userPhoneValue,
        connectionType: state.common.connectionType,
        tariffConnectionValue: state.common.tariffConnectionValue,
    }),
    (dispatch) => {
        return {
            getTariffs: (regionId) => dispatch(tariffsList(regionId)),
            getTariffFilterSettings: () => dispatch(tariffFilterSettings()),
            getTariffSelectionSettings: (regionId) => dispatch(tariffSelectionSettings(regionId)),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getInfoBlocksWithTariffs: (regionId, group, tariff) => dispatch(infoBlocksList(regionId, group, tariff)),
            getCurrentTariff: () => dispatch(tariffInfo()),
            getExchangeMins: (regionId) => dispatch(exchangeMins(regionId)),
            exchangeMinsSend: (data) => dispatch(exchangeMinsSend(data)),
            getMyServices: () => dispatch(services()),
            getSettings: (regionId) => dispatch(settings(regionId)),
            // getPrice: (regionId, min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi, currentSmsPack, cost_type, user_cost) => dispatch(price(regionId, min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi, currentSmsPack, cost_type, user_cost)),
            checkWord: (word) => dispatch(checkWord(word)),
            clearWord: () => dispatch(clearWord()),
            clearActivationData: () => dispatch(clearActivationData()),
            getTariffConstructor: (regionId) => dispatch(getTariffConstructor(regionId)),
            postTariffConstructor: (tariff, regionId) => dispatch(postTariffConstructor(tariff, regionId)),
            setTariffParams: (tariff, regionId) => dispatch(setTariffParams(tariff, regionId)),

            auth: (data, rememberMe) => dispatch(auth(data, rememberMe)),
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),

            checkAvailableConstructor: (phone, regionId, checkData) => dispatch(checkAvailableConstructor(phone, regionId, checkData)),
            checkBalanceLack: (checkData) => dispatch(checkBalanceLack(checkData)),
            timerValueAction: (data) => dispatch(timerValueAction(data)),
            tariffConnectionToggleAction: (data) => dispatch(tariffConnectionToggleAction(data)),
            checkOperator: (formData, params) => dispatch(checkOperator(formData, params)),
            userPhoneValueAction: (phone) => dispatch(userPhoneValueAction(phone)),
            tariffConectionTypeAction: (data) => dispatch(tariffConectionTypeAction(data)),
            activateTariff: (tariff, regionId) => dispatch(activateTariffConstructor(tariff, regionId)),
            getFormsList: () => dispatch(formsList("mnp")),
        }
    }
)(RatesMainNew);