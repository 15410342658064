import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {exchangeMinsSend, exchangeMins} from "../../actions/client";
import {Helmet} from "react-helmet";
import {servicesView} from "../../actions/services";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import LinkC from "../../components/common/LinkC";
import {Redirect} from "react-router-dom";
import ExchangeForm from "../../components/Lk/ExchangeForm";
import LKModals from "../LK/LKModals";

class ServicesService extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = ServicesService.prepareState(props)
    }

    static prepareState = (props) => {
        let service = null;
        const {services, client} = props;
        if (!client.isGuest && services) {
            service = services.find((item) => item.id_billing == client.settings.exchange_service_id)
        }
        const region = props.regions.selected.alias;
        return {service, breadcrumbs: service ? [
                {
                    url: `/${region}/services`,
                    title: 'Услуги'
                },
                {
                    url: `/${region}/services/services`,
                    title: 'Сервисы'
                },
                {
                    title: service.name
                }
            ] : null}
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    componentDidMount() {
        if (!this.props.client.clientInfo.exchange || typeof this.props.client.clientInfo.exchange.rate === "undefined") {
            this.props.getExchangeMins(this.context.selectedRegion.id);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.service && props.services.length) {
            return {...state, ...ServicesService.prepareState(props)}
        }
        if (props.client.exchangeMinsSuccess) {
            return {...state, modal: "exchange-success"}
        }
        if (props.client.exchangeMinsError) {
            return {...state, modal: "exchange-error"}
        }
    }

    render() {
        const {service, breadcrumbs, modal} = this.state;

        const {clientInfo, formsSettings, isGuest} = this.props.client;

        let canExchange;
        if (clientInfo.exchange && clientInfo.exchange.is_available && clientInfo.exchange.max_minutes && clientInfo.exchange.allowForTariff) {
            canExchange = true;
        }
        if (clientInfo.exchange && (clientInfo.exchange.is_available === false || clientInfo.exchange.max_minutes === 0 || clientInfo.exchange.allowForTariff === false)) {
            canExchange = false;
        }

        if (isGuest || canExchange === false) {
            return <Redirect to={`/${this.context.selectedRegion.alias}/services`}/>
        }

        if (!service || !clientInfo.tariff) {
            return <main className="page__main-holder" role="main"/>;
        }

        return (
            <main className="page__main-holder page__main-holder_service" role="main">
                <Helmet
                    title={this.state.headTitle}
                    meta={[
                        {"name": "description", "content": this.state.metaDesc},
                        {"name": "keywords", "content": this.state.metaKeywords}
                    ]}>
                </Helmet>
                <div className="b-service-inner b-service-inner_adapt_default">
                    <div className="b-service-inner__service-header b-service-inner__service-header--mob">
                        <div className="b-inner-intro b-inner-intro_adapt_default">
                            <div className="b-inner-intro__wrapper">
                                <div className="b-inner-intro__inner page__container">
                                    <div className="b-inner-intro__header" />
                                    <div className={`b-inner-intro__main-area dop-size`} >
                                        <div className="b-inner-intro__main-area-wrapper">
                                            <RatesBreadcrumbs items={breadcrumbs} />
                                            <h1 className="b-inner-intro__title">Услуга «{service.name}»</h1>
                                            {service.services_short_description ? <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: service.services_short_description}}/> : null}
                                            <div className="b-inner-intro__btn">
                                                <LinkC to={'/services/'+service.slug}>Подробнее об услуге</LinkC>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page__container">
                        <div className={'b-service-inner__content-area_top'}>
                            {clientInfo.exchange ? <ExchangeForm  max={clientInfo.exchange.max_minutes}
                                           notice={formsSettings.exchange_form ? formsSettings.exchange_form.exchange_form_description.replace('{{rate}}', clientInfo.exchange.rate) : ""}
                                           rate={clientInfo.exchange.rate}
                                           title={false}
                                           isSuccess={this.props.client.exchangeMinsSuccess}
                                           onSubmit={this.props.exchangeMinsSend}
                            /> : null}
                        </div>
                    </div>
                </div>
                <LKModals modal={modal} close={() => {this.setState({modal: null})}}/>
            </main>
        )
    }
}

export default connect(
    (state) => ({
        services: state.services.servicesLists,
        client: state.client,
        exchange: state.client.clientInfo.exchange,
        regions: state.regions,
    }),
    (dispatch) => {
        return {
            getService : (slug, regionId) => dispatch(servicesView(slug, regionId)),
            getExchangeMins: (regionId) => dispatch(exchangeMins(regionId)),
            exchangeMinsSend: (data) => dispatch(exchangeMinsSend(data)),
        }
    }
)(ServicesService);