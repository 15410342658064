import React, {Component} from 'react'
import PropsTypes from 'prop-types'
import {connect} from 'react-redux'
import TopBlock from "../../components/Lk/TopBlock";
import ChangePasswordForm from "../../components/Lk/ChangePasswordForm";
import {changePassword, banServices, services} from "../../actions/client"
import RateServiceListItem from "../../components/Rates/RateServiceListItem";
import {prepareDescription} from '../../tools/tools'

import {Helmet} from "react-helmet";

class Security extends Component {

    componentDidMount(){
        if (!this.props.client.banServices) {
            this.props.getBanServices();
        }
        if (!this.props.client.services) {
            this.props.getMyServices();
        }
    }

    static contextTypes = {
        selectedRegion: PropsTypes.object
    };

    render(){

        let {
            changePasswordErrors,
            formsSettings = {},
            banServices,
            settings,
        } = this.props.client;

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'securityLKRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <div>
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <TopBlock title="Безопасность и вход"/>
            <div className="page__title-3 page__title-3_first-elem page__title-3_mobile-less">Вход в аккаунт</div>
            {/*<div className="b-form-simple b-form-simple_adapt_default">*/}
            {/*    <div className="b-form-simple__wrapper">*/}
            {/*        <div className="b-form-simple__header">*/}
            {/*            <div className="b-form-simple__title">Анкетные данные</div>*/}
            {/*            {formsSettings.personal_form ? <div className="b-form-simple__text"*/}
            {/*                                                dangerouslySetInnerHTML={{__html: prepareDescription(formsSettings.personal_form.personal_block_description, this.context.selectedRegion.alias)}}/> : null}*/}
            {/*            {formsSettings.personal_form && formsSettings.personal_form.personal_block_doc ? <div className="b-form-simple__text b-download-doc">*/}
            {/*                <div className="b-download-doc__wrapper">*/}
            {/*                    <a className="b-download-doc__item b-download-doc__item_pdf"*/}
            {/*                       href={formsSettings.personal_form.personal_block_doc}*/}
            {/*                       target="_blank">{formsSettings.personal_form.personal_block_link_text}</a>*/}
            {/*                </div>*/}
            {/*            </div> : null}*/}
            {/*        </div>*/}
            {/*        <div className="b-form-simple__btns-area">*/}
            {/*            <div className="b-form-simple__btn-item">*/}
            {/*                <button type="button" onClick={this.props.showModal('info-change-request')} className="b-form-simple__btn u-btn u-btn u-btn_default u-btn u-btn_responsive u-btn u-btn_adapt_mobile-sm">Изменить данные</button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="page__hr page__hr_security_simple-forms"/>*/}
            <ChangePasswordForm title="Сменить пароль"
                                notice={formsSettings.change_password_form && formsSettings.change_password_form.change_password_description}
                                errors={changePasswordErrors}
                                onSubmit={this.props.changePassword}
                                clear={this.props.clear}
                                formSettings={formsSettings.auth_form}
            />
            <div>
                <div className="page__hr page__hr_security_simple-forms"/>
                <div className="b-form-simple__header">
                    <div className="b-form-simple__title">Дополнительные настройки</div>
                    {
                        (settings && settings.security_settings_description) &&
                        <div className="b-form-simple__text">{settings.security_settings_description}</div>
                    }
                </div>
                {
                    (settings && settings.security_settings_link) &&
                    <div className="b-form-simple__btns-area">
                        <div className="b-form-simple__btn-item">
                            <a href={settings.security_settings_link} target="_blank" className="b-form-simple__btn u-btn u-btn u-btn_default u-btn u-btn_responsive u-btn u-btn_adapt_mobile-sm">Перейти</a>
                        </div>
                    </div>
                }
            </div>

            {banServices && banServices.length ? <div>
                <div className="page__hr page__hr_security_simple-forms"/>
                <div className="page__title-3 page__title-3_mobile-less page__title-3_blocks-list">Установка запретов на
                    услуги
                </div>
                {formsSettings.services_ban_form ? <div className="page__subtitle page__subtitle_blocks-list">{formsSettings.services_ban_form.services_ban_description}</div> : null}
                <div className="b-options-table_theme_no-side page__blocks-list">
                    {banServices.map((service) => <RateServiceListItem key={service.id} item={service} hideZeroPrice={true}/>)}
                </div>
            </div> : null}
        </div>
    }
}

export default connect(
    state => ({
        client: state.client,

        routes: state.routes,
    }),
    dispatch => () => {
        return {
            changePassword: (data) => dispatch(changePassword(data)),
            getBanServices: () => dispatch(banServices()),
            getMyServices: () => dispatch(services())
        }
    }
)(Security)