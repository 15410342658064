import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {RenderField, onChange} from "./PopupHelpers";

import  {  YMaps,  Map,  ObjectManager, GeolocationControl, ZoomControl  }  from 'react-yandex-maps';

import objectMap from "../../../images/content/icon-map-pin.svg";

export default class Ordering extends Component{

    constructor(props){
        super(props);
        var fields = {
            fio: {
                type: "text",
                label: "ФИО",
                placeholder: "Иванов Иван Иванович",
                value: ""
            },
            phone: {
                type: "text",
                label: "Телефон",
                placeholder: "Номер для связи",
                value: "",
                className: "col_sm_2"
            },
            email: {
                type: "text",
                label: "E-mail",
                placeholder: "user@mail.ru",
                value: "",
                className: "col_sm_2"
            },
            whereGet: {
                type: "radio",
                value: 1,
                items: [
                    {
                        label: "Заказать доставку",
                        value: 1
                    },
                    {
                        label: "Получить в салоне связи",
                        value: 2
                    }
                ]
            },
            sale: {
                type: "checkbox",
                value: 0,
                label: "Скидка 50% на абонентскую плату"
            },
            silverNumber: {
                type: "checkbox",
                value: 1,
                label: "Серебряный номер при подключении"
            },
            city: {
                type: "text",
                label: "Город",
                placeholder: "",
                value: ""
            },
            tube: {
                type: "text",
                label: "Станция метро",
                placeholder: "",
                value: ""
            },
            street: {
                type: "text",
                label: "Улица",
                placeholder: "",
                value: ""
            },
            house: {
                type: "text",
                label: "Дом",
                placeholder: "",
                value: ""
            },
            pavilion: {
                type: "text",
                label: "Корпус",
                placeholder: "",
                value: ""
            },
            flat: {
                type: "text",
                label: "Квартира",
                placeholder: "",
                value: ""
            },
            additionalInfo: {
                type: "textarea",
                label: "Дополнительная информация",
                placeholder: "",
                value: ""
            }
        };

        this.state = {fields};

        this.onChange = onChange.bind(this);
    }

    renderDelivery(){
        return <div className="mnp-popup__delivery mnp-popup__wrapper">
            <div className="inputs-group">
                {['city','tube'].map((item) => <RenderField field={{...this.state.fields[item], name: item}} onChange={this.onChange} />)}
            </div>
            <div className="mnp-delivery__notification">Если вы не нашли свой город в списке, то вы можете оформить переход в МОТИВ в <a href="#">салоне</a></div>
            <div className="inputs-group">
                <RenderField field={{...this.state.fields['street'], name: 'street'}} onChange={this.onChange} />
                <div className="inputs-group">
                    {['house','pavilion', 'flat'].map((item) => <RenderField field={{...this.state.fields[item], name: item}} onChange={this.onChange} />)}
                </div>
            </div>
            <RenderField field={{...this.state.fields['additionalInfo'], name: 'additionalInfo'}} onChange={this.onChange} />
            <hr/>
        </div>
    }


    render() {

        let whereGet = this.state.fields.whereGet.value;

        return <form className="swiper-container" onSubmit={this.props.onSubmit}>
            <div className="mnp-popup__wrapper">
                <h2 className="mnp-popup__title">Оформление заказа</h2>
                <div className="mnp-popup__group">
                    <div className="inputs-group">
                        <div className="input__wrap">
                            <div className="input__label">Мой тариф</div>
                            <div className="mnp-popup__tariff">
                                <h3>«Вместо за 300»</h3><span className="underline"/><h3 className="cost">300 ₽</h3>
                            </div>
                            <Link to="/tariffs/1">Изменить</Link>
                        </div>
                        <div className="input__wrap">
                            <div className="input__label">Мой номер</div>
                            <div className="mnp-popup__tariff">
                                <h3>+7 977 896-74-41</h3><span className="flag flag_silver" /><span className="underline"/><h3 className="cost">0 ₽</h3>
                            </div>
                            <Link to="/tariffs/number">Изменить</Link>
                        </div>
                    </div>
                </div>
                <div className="mnp-popup__group">
                    <div className="input__wrap">
                        <div className="input__label">Акции тарифа:</div>
                        <div className="inputs-group">
                            {['sale', 'silverNumber'].map((item) => <RenderField field={{...this.state.fields[item], name: item}} onChange={this.onChange} />)}
                        </div>
                    </div>
                </div>
                <div className="mnp-popup__group mnp-contacts-info__inputs">
                    <h3 className="inputs-group-title">Контактные данные</h3>
                    <div className="inputs-group inputs-group_contacts">
                        {['fio', 'phone', 'email'].map((item) => <RenderField field={{...this.state.fields[item], name: item}} onChange={this.onChange} />)}
                    </div>
                </div>
            </div>
            <div className="mnp-popup__group">
                <div className=" mnp-popup__wrapper">
                    <h3 className="inputs-group-title">Выберите способ доставки</h3>
                    <RenderField field={{...this.state.fields['whereGet'], name: 'whereGet'}} onChange={this.onChange} />
                </div>
                <div className={whereGet == 1 ? "" : "hidden"}>{this.renderDelivery()}</div>
                <div className={whereGet == 2 ? "" : "hidden"}><OrderingOffice /></div>
            </div>
            <div className="mnp-popup__wrapper">
                <div className="mnp-popup__group">
                    <div className="mnp-popup__submit">
                        <h3 className="inputs-group-title">Общая сумма заказа: 300 ₽</h3>
                        <button type="submit" className="btn btn--mnp-next ">Подтвердить заказ</button>
                    </div>
                </div>
                <div className="mnp-popup__bottom">Стоимость тарифа будет зачислена на счет приобретаемого номера, и вы сможете потратить её на услуги связи.</div>
            </div>
        </form>
    }
}

class OrderingOffice extends Component {

    constructor(props){
        super(props);

        this.offices = [
            {
                id: 1,
                tube: 'Уралмарш',
                address: "Байкалово, Революции, 17",
                work_time: "Ежедневно: 09:00–22:00"
            },
            {
                id: 2,
                tube: 'Уралмарш',
                address: "Екатеринбург, улица Куйбышева, 124/1",
                work_time: "Ежедневно: 09:00–22:00"
            },
            {
                id: 3,
                tube: 'Уралмарш',
                address: "Екатеринбург, улица Ленина, 124/1",
                work_time: "Ежедневно: 09:00–22:00"
            }
        ]

        this.state = {
            offices: this.offices,
            activeSearchMode: 'list'
        }
    }

    setSearchMode(mode){
        return () => {
            this.setState({activeSearchMode: mode})
        }
    }

    search(){

    }

    renderSearch(){
        return <div className="sp-map-widget-search">
            <input className="input" type="text" placeholder="Найти салон по адресу или метро" onChange={this.search}/>
            <div>
                <span className={"sp-office sp-office-map"+(this.state.activeSearchMode == 'map' ? " active" : "")} onClick={this.setSearchMode('map')}>На карте</span>
                <span className={"sp-office sp-office-list"+(this.state.activeSearchMode == 'list' ? " active" : "")} onClick={this.setSearchMode('list')}>Списком</span>
            </div>
        </div>
    }

    renderList(){
        return <ul className="sp-map-widget__list">
            {this.state.offices.map((item) => <li className="sp-map-widget__item " key={item.id}>
                <div>
                    <div className="sp-map-widget__item-addr">Байкалово, Революции, 17</div>
                    <div className="sp-map-widget__item-tube text_light">Уралмарш</div>
                </div>
                <div className="sp-map-widget__item-time">Ежедневно: 09:00–22:00</div>
                <button className="btn btn--s btn--transparent-b-o">Выбрать</button>
            </li>)}
        </ul>
    }

    render(){
        return <div className="mnp-popup__sp">
            {this.renderSearch()}
            <div className={this.state.activeSearchMode == "list" ? "" : "hidden"}>{this.renderList()}</div>
            <div className={this.state.activeSearchMode == "map" ? "" : "hidden"}><OfficeMap /></div>
        </div>
    }
}

class OfficeMap extends Component{
    constructor(props){
        super(props);

        this.mapState = {
            center: [57.397572, 63.761043],
            zoom: 16,
            controls: []
        };

        this.features = [
            {
                type: 'Feature',
                id: 1,
                geometry: {
                    type: 'Point',
                    coordinates: [57.397572, 63.771043]
                },
                properties: {
                    // hintContent: 'Текст всплывающей подсказки',
                    balloonContent: '<div class="sp-map-widget__balloon-item">'+
                        '<div>'+
                            '<div class="sp-map-widget__item-addr">Байкалово, Революции, 17</div>'+
                            '<div class="sp-map-widget__item-tube text_light">Уралмарш</div>'+
                        '</div>'+
                        '<div class="sp-map-widget__item-time">Ежедневно: 09:00–22:00</div>'+
                        '<button class="btn btn--s btn--transparent-b-o">Выбрать</button>'+
                    '</div>'
                },
                options: {
                    iconLayout: 'default#image',
                    iconImageHref: objectMap,
                    iconImageSize: [33, 40],
                    iconImageOffset: [-16, -20],
                    balloonOffset: [-60, -20]
                }
            },
            {
                type: 'Feature',
                id: 2,
                geometry: {
                    type: 'Point',
                    coordinates: [57.396859, 63.758089]
                },
                properties: {
                    // hintContent: 'Текст всплывающей подсказки',
                    balloonContent: '<div class="sp-map-widget__balloon-item">'+
                    '<div>'+
                    '<div class="sp-map-widget__item-addr">Байкалово, Революции, 18</div>'+
                    '<div class="sp-map-widget__item-tube text_light">Уралмарш</div>'+
                    '</div>'+
                    '<div class="sp-map-widget__item-time">Ежедневно: 09:00–22:00</div>'+
                    '<button class="btn btn--s btn--transparent-b-o">Выбрать</button>'+
                    '</div>'
                },
                options: {
                    iconLayout: 'default#image',
                    iconImageHref: objectMap,
                    iconImageSize: [33, 40],
                    iconImageOffset: [-16, -20],
                    balloonOffset: [-60, -20]
                }
            }
        ]
    }

    render() {

        return  <div className="page__map-block">
                    <div className="page__map">
                        <div className="b-map b-map_adapt_default js-init">
                            <div className="b-map__wrapper">
                                <div className="b-map__map">
                                    <div className="b-map__map-self" data-center="59.955939,30.329567" data-zoom="16">
                                        <YMaps>
                                            <Map
                                                state={this.mapState}
                                                width={"100%"}
                                                height={"100%"}
                                                options={{ restrictMapArea: [[82.366481, -35.039568],[-62.425996, -133.828630]]}}
                                                ref={(map) => {this.map = map}}>
                                                {/*<ZoomControl options={{layout: 'round#zoomLayout'}}/>*/}
                                                {/*<GeolocationControl options={{layout: 'round#buttonLayout'}}/>*/}
                                                <ZoomControl />
                                                <GeolocationControl />
                                                <ObjectManager
                                                    options={{
                                                        clusterize: true,
                                                        gridSize: 32
                                                    }}
                                                    clusters={{
                                                        preset: 'islands#orangeClusterIcons'
                                                    }}
                                                    features={this.features}
                                                />
                                            </Map>
                                        </YMaps>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
}

export function OrderingOk({onClick}) {
    return <div className="mnp-popup-ok swiper-container">
        <div className="mnp-popup__title">Заказ оформлен!</div>
        <div className="mnp-popup-ok__block">
            <div className="mpn-popup-ok__title">№ 23487526842-11443237</div>
            <div className="mpn-popup-ok__text">В ближайшее время с вами свяжется наш специалист чтобы подтвердить заказ и уточнить условия доставки</div>
        </div>
        <div className="mnp-popup-ok__col">
            <div className="mnp-popup-ok__block">
                <div className="mpn-popup-ok__title">Ваш заказ</div>
                <div className="mpn-popup-ok__text">
                    <div>Тарифный план <b>«Вместо за 300»</b></div>
                    <div>Серебряный номер <b>+7 977 896-74-41</b></div>
                </div>
            </div>
            <div className="mnp-popup-ok__block">
                <div className="mpn-popup-ok__title">Адрес доставки</div>
                <div className="mpn-popup-ok__text">г.Екатеринбург, ул. Шимякина, д.1, корп 2, кв 26</div>
            </div>
            <div className="mnp-popup-ok__block">
                <div className="mpn-popup-ok__title">Контактные данные</div>
                <div className="mpn-popup-ok__text">
                    <div>Иванов Иван Иванович</div>
                    <div>+7 925 435 32 56</div>
                    <div>info@mail.ru</div>
                </div>
            </div>
        </div>
        <div className="mnp-popup-ok__col">
            <div className="mnp-popup-ok__block">
                <div className="mpn-popup-ok__title">Подключенные акции</div>
                <div className="mpn-popup-ok__text">
                    <div>Серебряный номер при подключении</div>
                    <div>Скидка 50% на абонентскую плату</div>
                </div>
            </div>
        </div>
        <hr/>
        <h3 className="inputs-group-title">Общая сумма заказа: 300 ₽</h3>
        <div className="text-center"><button type="button" className="btn btn--l btn--mnp-next" onClick={onClick}>OK</button></div>
    </div>
}