import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalNew from "../../common/ModalNew";
import Icon from "../../common/Icon";
import PdfLink from "../../Constructor/PdfLink";
import { CopyToClipboard } from 'react-copy-to-clipboard';

class AboutModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    componentDidUpdate(prevState) {
        if (this.state.show !== prevState.show && !!this.state.show) {
            setTimeout(() => {
                this.setState({ show: false });
            }, 2000);
        }
    }

    render() {
        let {
            tariffConstructor,
            constructorParams,
            dataForUrl,
            settings
        } = this.props;

        let shareUrl = '';
        if (typeof window !== "undefined") {
            let basicUrl = window.location.origin + window.location.pathname;
            shareUrl = basicUrl + '?svoi=' + dataForUrl.min + '-' + (dataForUrl.data === 1 && dataForUrl.router === 0 ? 'u' : dataForUrl.router === 1 ? 'r' : dataForUrl.gb) + '-' + (dataForUrl.sms_pack_count ? dataForUrl.sms_pack_count : 0) + '-' + dataForUrl.social + '-' + dataForUrl.voice + '-' + dataForUrl.youtube_unlim + '-' + dataForUrl.ivi + '-' + dataForUrl.home_region_checked;
        }

        let aboutPageUrl = "/" + this.context.selectedRegion.alias + "/" + settings.route;
        let recalc = tariffConstructor && tariffConstructor.recalcData
        let pdfFile = recalc.pdf_file
        let details = recalc.details_message

        return <ModalNew className="u-modal u-modal_active u-modal-top modal_about" close={this.props.close}>
            <div className="content">
                <div className="btns_wrapper">
                    <PdfLink className={"pdf_link"} constructor={constructorParams} linkFile={pdfFile}>
                        Скачать информацию о тарифе <span className="tariff_info__word_pdf_link">(pdf)</span>
                    </PdfLink>

                    <div className="wrap-tooltip">
                        {this.state.show ? <span className="wrap-btn-copy__tooltip about_tooltop">Ссылка&nbsp;скопирована</span> : null}
                        <CopyToClipboard text={shareUrl} onCopy={() => this.setState({ show: true })}>
                            <div className="link_copy">
                                Скопировать ссылку на тариф
                            </div>
                        </CopyToClipboard>
                    </div>

                    <a className={"link_copy"} href={aboutPageUrl} target="_blank">Особенности подключения</a>
                </div>

                <div className="tariff_info">
                    {details && details.map((item) =>
                        <div key={item.type} className="tariff_info__text">
                            <div className="key" dangerouslySetInnerHTML={{ __html: item.param }} />
                            <div className="mean" dangerouslySetInnerHTML={{ __html: item.value }} />
                        </div>
                    )}
                </div>
            </div>
        </ModalNew>
    }
}

export default connect(
    state => ({
        tariffConstructor: state.tariffConstructor,
        settings: state.constructor.settings
    }),
    (dispatch) => {
        return {

        }
    }
)(AboutModal);