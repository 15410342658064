import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import LinkC from "../../components/common/LinkC";
import LKMain from "./LKMain";
import Abonent from "./Abonent";
import Security from "./Security";
import LKModals from "./LKModals";
import Services from "./Services";
import Payments from "./Payments";
import Details from "./Details";
import {Helmet} from "react-helmet";

import {lkUrl, mainUrl} from "../../tools/config";
import PropTypes from 'prop-types';
import MyAppeals from "./MyAppeals";

// боковое меню и центральный контент
class LKPage extends Component {

    constructor(props) {
        super(props);

        let url = props.history.location.pathname.split('/'), title = "";

        if (url.length) {
            let menuItem = props.client.lkMenu.find((item) => item.link === '/' + url[url.length - 1]);
            title = menuItem.seoTitle
        }
        this.state = {
            modal: null,
            mobileMenuOpened: false,
            title
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isLk: PropTypes.bool,
    };

    componentDidMount() {
        if(this.props.client && this.props.client.isGuest && this.props.client.lkMenu) {
            window.location.replace(mainUrl);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.client.changePasswordSuccess) {
            return {...state, modal: "password-change-success"}
        }
        if (props.client.changePasswordError) {
            return {...state, modal: "password-change-error"}
        }
        if (props.client.detalizationSuccess) {
            return {...state, modal: "order-details-success"}
        }
        if (props.client.detalizationError) {
            return {...state, modal: "order-details-error"}
        }
        if (props.client.changeInfoSuccess) {
            return {...state, modal: "info-change-success"}
        }
        if (props.client.changeInfoError) {
            return {...state, modal: "info-change-error"}
        }
        if (props.client.clientInfo && props.client.clientInfo.status && (props.client.clientInfo.status.id !== 1 && state.modal == 'block-number')) {
            return {...state, modal: "block-number-success"}
        }
        if (props.client.exchangeMinsSuccess) {
            return {...state, modal: "exchange-success"}
        }
        if (props.client.exchangeMinsError) {
            return {...state, modal: "exchange-error"}
        }
        if (props.client.clientInfo && props.client.clientInfo.status && (props.client.clientInfo.status.id == 1 && state.modal == 'unblock-number')) {
            return {...state, modal: "unblock-number-success"}
        }
        if (props.client.toggleServiceError) {
            return {...state, modal: "info-details-error"}
        }
        if (props.client.toggleServiceMessage) {
            return {...state, modal: "info-details-ok"}
        }
        if (props.lkMenu && !state.title) {
            var url = props.history.location.pathname.split('/');

            if (url.length) {
                let menuItem = props.client.lkMenu.find((item) => item.link === '/' + url[url.length - 1]);

                return {...state, title: menuItem.seoTitle};
            }
        }
        return state;
    }

    showModal = (modal, data = null) => {
        return (e) => {
            if (e) e.preventDefault();

            this.setState({modal, modalData: data})
        }
    };

    hideModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: null, modalData: null})
    };

    toggleMobileMenu = () => {
        this.setState((prevState) => {
            return {
                mobileMenuOpened: !prevState.mobileMenuOpened
            }
        })
    };

    renderContent = () => {
        var url = this.props.history.location.pathname.split('/');

        if (url.length) {
            let menuItem = this.props.client.lkMenu.find((item) => item.link === '/'+url[url.length - 1]);

            if(menuItem && menuItem.alias){
                switch(menuItem.alias){
                    case "info":
                        return <Abonent showModal={this.showModal}/>;
                    case "security":
                        return <Security clear={this.state.modal} showModal={this.showModal}/>;
                    case "services":
                        return <Services/>;
                    case "payments":
                        return <Payments/>;
                    case "details":
                        return <Details showModal={this.showModal}/>
                    case "appeals":
                        return <MyAppeals />
                }
            } else {
                return <LKMain />
            }
        }

        return null;
    };

    render() {
        const { regions, location } = this.props;
        let {
            isGuest,
            lkMenu
        } = this.props.client;

        if(this.props.client && this.props.client.isGuest && this.props.client.lkMenu) {
            window.location.replace(mainUrl);
        }
        // if (isGuest) {
        //     return <Redirect to="/"/>
        // }

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        let region = this.context.selectedRegion.alias;
        let regionSlug = this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug;
        let detailUrl = (regionSlug ? "/" + regionSlug : "") + "/tariffs";
        let detailUrlLk = (regionSlug ? regionSlug : region) + "/tariffs";
        const slug = this.props.client.clientInfo.tariff ? this.props.client.clientInfo.tariff.slug : ''
        const status_id = this.props.client.clientInfo.tariff ? this.props.client.clientInfo.tariff.status_id : ''
        if (slug) {
            switch (status_id) {
                case 2:
                    detailUrl += "/archive/";
                    detailUrlLk += "/archive/";
                    break;
                case 3:
                    detailUrl += "/individual/";
                    detailUrlLk += "/individual/";
                    break;
                default:
                    detailUrl += "/";
                    detailUrlLk += "/";
            }
            detailUrl += slug;
            detailUrlLk += slug;
        }
        // ссылка на нужный контент
        const linkNewMenuFields = this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl;
        let newMenuFields = [{
            alias: "currentTariff",
            description: "",
            // iconName: "icon_new_slices",
            iconName: "icon_slices",
            link: linkNewMenuFields,
            seoTitle: "",
            title: "Текущий тариф",
            sort: 2
        }]
        // создаем новый массив меню, заливая в него новый объект
        const newLkMenu = lkMenu.concat(newMenuFields)
        // сортируем по порядку поля sort
        newLkMenu.sort(function (a, b) {
           return a.sort - b.sort
        });

        return <main className="page__main-holder" role="main">
            <Helmet title={this.state.title}>
                <link rel="canonical" href={pageLink} />
            </Helmet>
            <div className="b-header_adapt_default b-header_adapt_default__mobile-nav">
                <div className="b-header__aside-menu b-header__aside-menu_active">
                    <div className={"b-aside-menu b-aside-menu_theme_header" + (this.state.mobileMenuOpened ? " b-aside-menu_dd-active" : "")}>
                        <div className="b-aside-menu__dropdown-header-area" onClick={this.toggleMobileMenu}>
                            <div className="b-aside-menu__dropdown-elem">Настройки аккаунта</div>
                        </div>
                        <div className="b-aside-menu__dd-wrap">
                            {newLkMenu.map((item, key) => 
                                <LinkC 
                                    key={key}
                                    to={item.link}
                                    className={"b-aside-menu__group"+(this.props.history.location.pathname.indexOf(item.link) == -1 ? "" : " b-aside-menu__group_open")}>
                                        <div className="b-aside-menu__link">{item.title}</div>
                                </LinkC>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page__container">
                <div className="page__cols">
                    <div className="page__main-col">
                        {this.renderContent()}
                    </div>
                    <div className="page__right-aside">
                        <div className="page__lk-aside-item">
                            <div className="page__aside-item page__aside-item_menu">
                                <div className="b-aside-menu b-aside-menu_adapt_default">
                                    {newLkMenu.map((item, key) => 
                                        <LinkC 
                                            key={key} 
                                            to={item.link} 
                                            className={"b-aside-menu__group b-aside-menu__group"+(this.props.history.location.pathname.indexOf(item.link) == -1 ? "" : " b-aside-menu__group_open")}>
                                                <div className="b-aside-menu__link">{item.title}</div>
                                        </LinkC>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LKModals modal={this.state.modal} close={this.hideModal} {...this.state.modalData}/>
        </main>
    }
}

export default connect(
    state => ({
        regions: state.regions,

        client: state.client,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
    }),
    (dispatch) => {
        return {

        }
    }
)(LKPage);

export const LkPageRequestsAbonent = [
    {
        func: 'getInfo',
        args: [],
        object: 'client.clientInfo',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    },
    {
        func: 'getExchange',
        args: [],
        object: 'client.clientInfo.exchange',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['lk_abonent'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "advert_service_block"}
        ]
    }
];

export const LkPageRequestsPayments = [
    {
        func: 'getInfo',
        args: [],
        object: 'client.clientInfo',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];
export const LkPageRequestsServices = [
    {
        func: 'getMyServices',
        args: [],
        object: 'client.services',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];
export const LkPageRequestsMyAppeals = [
    {
        func: 'getAllMyAppeals',
        args: [],
        object: 'client.allAppeals',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];
export const LkPageRequestsSecurity = [
    {
        func: 'getInfo',
        args: [],
        object: 'client.clientInfo',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    },
    {
        func: 'getMyServices',
        args: [],
        object: 'client.services',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    },
    {
        func: 'getBanServices',
        args: [],
        object: 'client.banServices',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];
export const LkPageRequestsDetails = [
    {
        func: 'getInfo',
        args: [],
        object: 'client.clientInfo',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];