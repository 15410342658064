import React, { Component } from 'react';
import {Redirect, Route} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList, routesVacanciesList} from "../../actions/routes";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import VacancyInfo from "../../components/Vacancies/VacancyInfo";
import {SuccessVacancyModalForm, VacancyModalForm} from "../../components/modals/VacancyModal";
import {vacanciesList} from "../../actions/vacancies";
import {infoBlocksList} from "../../actions/infoBlocks";
import InfoBlock from "../../components/common/InfoBlock";
import Page404 from "../Page404";
import {clearVacancyRespondFormData} from "../../actions/forms";
import Icon from "../../components/common/Icon";

class Vacancy extends Component {

    constructor(props) {

        super(props);

        // let route = props.routes.list.find(x => x.sys_name === 'добавить_роут_вакансии');

        this.state = {
            modal: null,
            idVacancies: this.props.match.params.id ? +this.props.match.params.id : null,
            finalVacancy: null,
            idVac: [],
            redirect: false,
            linkBreadcrumbsVakansii: null
        };
    }

    componentWillMount() {
        let regionId = this.context && this.context.selectedRegion.id;
        if ( this.props.routes.list.length === 0 ) {
            this.props.getRoutes();
        }

        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'about_company');
        }

        const idVacancies = this.state.idVacancies;
        const vacancyAll = this.props.vacanciesList.vacancies.vacancies;
        if (!!idVacancies){
            this.props.getVacanciesList();
            this.searchVacancies( idVacancies, vacancyAll );
        }

        if ( this.props.routesVacancies.list.length === 0 ){
            this.props.getRoutesVacancies();
        }
    }

    componentWillReceiveProps(nextProps) {
        const idVacancies = this.state.idVacancies;
        const vacancyAll = nextProps.vacanciesList.vacancies.vacancies;

        if ( this.props.vacanciesList.vacancies.length === 0 && !!nextProps.vacanciesList.isOk) {
            this.searchVacancies( idVacancies, vacancyAll );
        }

        let vacancyId = this.state.finalVacancy.length > 0 ? this.state.finalVacancy[0].vacancyItem[0].id : null;
        let propsVacId = this.props.match.params.id ? +this.props.match.params.id : null;
        if ( vacancyId !== propsVacId){
            this.setState({redirect: true});

        }

        if (this.props.routesVacancies){
            let routeVakansii = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.alias === 'vakansii');
            let linkBreadcrumbsVakansii = routeVakansii && routeVakansii.link;
            this.setState({linkBreadcrumbsVakansii: linkBreadcrumbsVakansii});
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    searchVacancies( idVacancies, vacancyAll ){
        const vacancyObj = [];

        vacancyAll && vacancyAll.forEach(vacancies => {
            const vacancyItem = vacancies.vacancies.filter(vacant => idVacancies === vacant.id);
            if (!vacancyItem || !vacancyItem.length) return;
            vacancyObj.push({vacancyItem});
        });

        this.setState({
            finalVacancy: vacancyObj,
        });
    }

    componentDidUpdate() {
        let modal = this.state.modal;
        if (typeof document !== 'undefined') {
            if (modal === "open" || modal === "success") {
                this.hideScroll();
            } else {
                this.showScroll();
            }
        }

        let vacancyFormOk = this.props.vacancyRespond && this.props.vacancyRespond.vacancyFormOk;
        if ( !!vacancyFormOk && modal === "open" ){
            this.setState({modal: "success"});
            this.props.clearData();
        }
    }

    openModal = (modal) => {

        return (e) => {

            if (e) e.preventDefault();
            this.setState({
                modal: modal
            });
        }
    };

    hideScroll = () =>{
        let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("no-scroll");
            scrollTop = window.pageYOffset;
            document.body.style.position = "fixed";
            document.body.style.top = -scrollTop + 'px';
        }
    };

    closeModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: "close"});
        this.showScroll();
    };

    showScroll = () => {

        if (typeof document != "undefined") {
            document.body.classList.remove('no-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            window.scroll(0, this.scrollTop);
        }
    };

    renderModal = () => {
        let finalVacancy = this.state.finalVacancy.length > 0 ? this.state.finalVacancy[0].vacancyItem[0] : null;
        const vacancyCity_id = finalVacancy && finalVacancy.city_id;
        const vacancyId = finalVacancy && finalVacancy.id;
        const specializationId = finalVacancy && finalVacancy.specialization_id;
        let selectedRegion = this.context ? this.context.selectedRegion : null;
        let infoBlocks = this.props.infoBlocks ? this.props.infoBlocks.list : null;
        let regionsList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.regions : [];
        let vacanciesList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.vacancies : [];
        switch (this.state.modal) {
            case "open":
                return <VacancyModalForm close={ this.closeModal }
                                         regions={regionsList}
                                         vacancyList={vacanciesList}
                                         selectedRegion={selectedRegion}
                                         vacancyCity_id={vacancyCity_id}
                                         vacancyId={vacancyId}
                                         specializationId={specializationId}
                />;
            case "success":
                return <InfoBlock type={'about_modal_success'} data={infoBlocks} section={'about_modal_success'} close={ this.closeModal } />;
            default:
                return "";
        }
    };

    renderBtnWhatsapp =(linkManager, customClass)=> {
        return (
            <div className={customClass}>
                <span className="whatsapp-link__title">Поддержка в мессенджерах</span>
                <a className="about-aside-menu__whatsapp-link"
                    href={`https://wa.me/${linkManager.replace(/\D/gim,'')}`}
                    target="_blank">
                    <Icon name={'icon_whatsapp'} className={'u-icon_whatsapp'}/>
                    <span>Задать вопрос по вакансии</span>
                </a>
            </div>
        )
    }

    render() {
        let {
            route,
            redirect
        } = this.state;

        const { regions, location } = this.props;

        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder vacancies");
        let infoBlocks = this.props.infoBlocks.list;
        let finalVacancy = this.state.finalVacancy.length > 0 ? this.state.finalVacancy[0].vacancyItem[0] : null;
        let selectedRegion = this.context.selectedRegion;
        let breadcrumbsTitleVacancy = finalVacancy && finalVacancy.title;
        let breadcrumbs = [
            {
                url: `/${selectedRegion.alias}${this.state.linkBreadcrumbsVakansii !== null ? this.state.linkBreadcrumbsVakansii : "/vakansii"}`,
                title: "Работа в МОТИВ"
            },
            {
                url: "",
                title: breadcrumbsTitleVacancy
            }
        ];

        // если id вакансии нет выводим 404
        if (redirect) {
            return <Route render={() => <Page404 type="vacancy" />} />
        }

        const linkManager = finalVacancy && finalVacancy.manager && finalVacancy.manager.whatsapp ? finalVacancy.manager.whatsapp : null;

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        return (
            <main className="page__main-holder page-vacancy motiv-vacancies" role="main">
                <Helmet
                    title={finalVacancy && finalVacancy.pageTitle ? finalVacancy && finalVacancy.pageTitle : route  && route.meta_title}
                    meta={[
                        {"name": "description", "content": route && route.meta_description ? route.meta_description : ""},
                        {"name": "keywords", "content": route && route.meta_keywords ? route.meta_keywords : ""}
                    ]}
                    htmlAttributes={{"class": "pp-mod_support_spam"}}>
                        {!redirect ? <link rel="canonical" href={pageLink} /> : null}
                </Helmet>
                <div className="page__container">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </div>
                            </div>
                            <VacancyInfo data={ finalVacancy } />
                            <div className="page__content-block">
                                <InfoBlock type={'about_send_cv'} data={infoBlocks} section={'about_send_cv'} openModal={ this.openModal } />
                            </div>
                            {!!linkManager ?
                                this.renderBtnWhatsapp(linkManager, "whatsapp-link__sm")
                            : null}
                            <div className="page__vacancy-social">
                                <span className="vacancy-social-title">Мы ждем тебя в нашей команде!</span>
                                <div className="page__vacancy-social-block">
                                    <InfoBlock type={'about_social'} data={infoBlocks} section={'about_social'} />
                                </div>
                            </div>
                        </div>
                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <AsideMenu route={ this.props.routesVacancies } />
                            </div>
                            {!!linkManager ?
                                this.renderBtnWhatsapp(linkManager, "whatsapp-link__lg")
                            : null}
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        vacanciesList: state.vacancies || [],
        infoBlocks: state.infoBlocks || [],
        vacancyRespond: state.forms || [],
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getVacanciesList: () => dispatch(vacanciesList()),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            clearData: () => dispatch(clearVacancyRespondFormData()),
        }
    }
)(Vacancy);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const VacancyRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['about_company'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "about_send_cv"},
            {type: "about_social_fb"},
            {type: "about_social_vk"},
            {type: "about_social_ok"},
            {type: "about_modal_success"},
        ]
    },
];