const initState = { isOk:false, cvForm: []};

export function cvForm(state = initState, action) {

    switch (action.type) {
        case 'CV_FORM':
            return {...state, isOk: false};
        case 'CV_FORM_SUCCESS':
            return {...state, cvForm: action.data, isOk: true};
    }
    return state;
}