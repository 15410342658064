import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function routesList() {
    return {
        type: 'ROUTE_LIST',
    }
}

export function* fetchRoutesList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/routes", {
                method: 'GET',
            })
        });
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function routesVacanciesList() {
    return {
        type: 'ROUTE_VACANCIES_LIST',
    }
}

export function* fetchRoutesVacanciesList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings/paths?name=aboutPaths", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function getPage (page, region = null, company = null) {
    return {
        type: 'GET_PAGE',
        page,
        region,
        company
    }
}

export function* fetchGetPage(action) {
    try {
        let params = [];

        if (action.region) {
            params.push('region_id='+action.region);
        }

        if (action.company) {
            params.push('company_id='+action.company);
        }

        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/page/"+action.page+(params.length ? '?'+params.join('&') : ''), {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, status === 200 && data.length ? {page: action.page, ...data[0]} : data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function setRoutes(type, data) {
    return {
        type: 'SET_ROUTES',
        routeType: type,
        data
    }
}