import React, {Component} from "react";
import {connect} from "react-redux";
import {servicesList} from "../../actions/services";
import PropTypes from "prop-types";
import RatesServiceCatList from "../../components/Rates/RatesServiceCatList";
import RatesServicesFilter from "../../components/Rates/RatesServicesFilter";
import {getQueryParams} from "../../tools/url-helper";
import {infoBlocksList} from "../../actions/infoBlocks";
import InfoBlock from "../../components/common/InfoBlock";

import {Helmet} from "react-helmet";

class Services extends Component {

    constructor(props) {
        super(props);

        let categoriesIds = [], servicesByCategories = [];

        props.services.forEach((service) => {
            [service.mainCategory, ...service.additionalCategories].forEach((cat) => {
                if (categoriesIds.indexOf(cat.id) === -1) {
                    categoriesIds.push(cat.id)
                    servicesByCategories[cat.id] = {
                        category: cat,
                        items: [service]
                    }
                } else {
                    servicesByCategories[cat.id].items.push(service)
                }
            })
        })

        servicesByCategories = Object.values(servicesByCategories)
            .sort((a, b) => (a.category.rank_services === b.category.rank_services && a.category.title < b.category.title) || a.category.rank_services < b.category.rank_services ? -1 : 1)
            .map((item) => {
                return {
                    ...item,
                    items: item.items.sort((a, b) => (a.rank === b.rank && a.name < b.name) || a.rank < b.rank ? -1 : 1)
                }
            })

        this.state = {
            servicesByCategories,
            categories: [
                {
                    id: 'all',
                    slug: 'all',
                    title: 'Все'
                },
                ...servicesByCategories.map((item) => item.category)
            ],
            activeChip: 'all',
            fixChips: this.isChipFixed()
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        setHeaderClassName: PropTypes.func,
        appRoutes: PropTypes.array
    };

    componentWillMount() {
        let {services, getServices, infoBlocks, getInfoBlocks} = this.props;
        if (!services.length) {
            getServices(this.context.selectedRegion.id)
        }
        if (!infoBlocks.length) {
            getInfoBlocks(this.context.selectedRegion.id)
        }
    }

    componentDidMount() {
        setTimeout(() => {
            const params = getQueryParams(this.props.location.search)
            if (params.category) {
                window.scrollTo(0, document.getElementById(`services-${params.category}`).offsetTop - 200);
            }
        }, 500)

        window.addEventListener('scroll', this.checkChipFixed)

        // ищем хэш в адресной строке
        const finedHash = window.location.hash.substr(1);
        // если найден хеш, то активируем нужный таб
        if (finedHash) {
            this.setState({activeChip: finedHash})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.checkChipFixed)
    }

    setActiveChip = (slug) => {
        this.setState({activeChip: slug})
        window.scrollTo(0, document.getElementById(`services-${slug}`).offsetTop - (this.state.fixChips ? 180 : 345));
    }

    isChipFixed = () => {
        return typeof document === 'undefined' || !document.getElementById(`services-all`) ? false : document.getElementById(`services-all`).offsetTop < window.scrollY;
    }

    checkChipFixed = () => {
        const shouldFix = this.isChipFixed();
        if (this.state.fixChips !== shouldFix) {
            this.setState({fixChips: shouldFix})
        }
    }

    render() {
        let {infoBlocks} = this.props;

        let {categories, servicesByCategories, activeChip, fixChips} = this.state;

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.b2b.find(x => x.sys_name === 'servicesB2BRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <main className="page__main-holder" role="main">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <div className="page__container">
                <h1 className="page__title">Услуги</h1>
                <InfoBlock type={'big-carousel'} data={infoBlocks} section={'service_banner_b2b'} />
            </div>
            <div id={'services-all'}/>
            
            {console.log('activeChip', activeChip)}
            {/* {console.log('window.location.hash.substr(1)', window.location.hash.substr(1))} */}
            <RatesServicesFilter
                cats={categories}
                slug={activeChip}
                toggleCat={this.setActiveChip}
                 //  className={fixChips ? 'b-services-list__tags-filter__fixed' : ''}
            />
            <div className="page__container">
                <RatesServiceCatList servicesByCats={servicesByCategories} slug={''}/>
            </div>
        </main>
    }
}

export default connect((state) => ({
    services: state.services.servicesLists,
    infoBlocks: state.infoBlocks.list.filter((item) => item.type.alias === 'service_banner_b2b'),

    routes: state.routes,
}), (dispatch) => {
    return {
        getInfoBlocks : (regionId) => dispatch(infoBlocksList(regionId, 'services_b2b')),
        getServices : (regionId) => dispatch(servicesList(regionId, null, null, 'b2b')),
    }
})(Services)

export const ServicesB2bRequests = [
    {
        func: 'getServices',
        args: [null, null, 'b2b'],
        object: 'services.servicesLists',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['services_b2b'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "service_banner_b2b"}
        ]
    },
];