import React, { Component } from 'react';

class InputSearchResult extends Component {

    render() {

        let {
            items,
            setSearchVal,
            closeFunc,
            activeItemIndex
        } = this.props;

        return (
            <div className="input-search-result__wrapper">
                <ul className="input-search-result__list">
                    {items.map((item, key) =>
                        <li onClick={setSearchVal(item.value)} className={activeItemIndex === key ? "input-search-result__item active" : "input-search-result__item "} key={key}>
                            <span dangerouslySetInnerHTML={{__html: item.title}}/>
                        </li>
                    )}
                </ul>
                <div className="input-search-result__close-area" onClick={closeFunc} />
            </div>
        )
    }
}

export default InputSearchResult;