import React, {Component} from 'react';
import Collapse from "../common/Collapse";
import PropTypes from "prop-types";

class RatesCollapsing extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        fetchBefore: PropTypes.func,
        selectedRegion:  PropTypes.object,
    };

    render () {

        let region = this.context.selectedRegion.alias;
        let fetchBefore = this.context.fetchBefore;

        let {
            info_dropdown,
            className,
            tariffServices
        } = this.props;

        if ((typeof tariffServices === 'undefined' || tariffServices.length === 0) && (info_dropdown === null || info_dropdown === "")) return null;

        return (
            <div className={"b-service-inner__list-collapser-block" + (className ? " " + className : "")}>
                <div className="page__title-3 page__title-3_mobile-less">{info_dropdown.title}</div>
                <div className="b-service-inner__list-collapser">
                    <div className="b-list-collapser b-list-collapser_adapt_default">
                        <div className="b-list-collapser__list">
                            {
                                tariffServices && tariffServices.length > 0
                                    ?   <Collapse title={"Услуги тарифа"}>
                                            {tariffServices.map((item, key) =>
                                                <React.Fragment key={key}>
                                                    <a className={"b-list-collapser__item__link"}
                                                       href={"/" + region + "/services/" + (item.status_id === 2 ? "archive/" : '') + item.slug}
                                                       onClick={fetchBefore("/" + region + "/services/" + (item.status_id === 2 ? "archive/" : '') + item.slug, item.slug, null)}
                                                    >{item.title}</a>
                                                    {key === tariffServices.length - 1 ? "" : ", "}
                                                </React.Fragment>
                                            )}
                                        </Collapse>
                                    :   ''
                            }
                            {
                                info_dropdown.hasOwnProperty('items')
                                    ?   <React.Fragment>
                                            { info_dropdown.items.map((item, key) =>
                                                <Collapse title={item.title} key={key}>
                                                    <div dangerouslySetInnerHTML={{__html: item.text}} />
                                                </Collapse>
                                            )}
                                        </React.Fragment>

                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesCollapsing;