import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthForm from "../../components/Forms/AuthForm";
import {auth, recovery, clearState} from '../../actions/client'
import RecoveryModal from "../../components/modals/RecoveryModal";
import SuccessModal from "../../components/modals/SuccessModal";
import {mainUrl} from "../../tools/config";
import {hasQueryParam} from "../../tools/tools";

class LKAuth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        }
    }

    static getDerivedStateFromProps(props, state){

        let {
            isGuest,
            fatalError,
            successMessageTitle
        } = props.client;


        if (fatalError) {
            return {...state, modal: "ErrorModal"}
        }
        if (successMessageTitle) {
            return {...state, modal: "SuccessModal"}
        }
        return state;
    }

    componentDidMount() {
        if(this.props.client && this.props.client.isGuest && this.props.client.lkMenu && !hasQueryParam('code') && !hasQueryParam('access_token')) {
            window.location.replace(mainUrl);
        }
    }

    closeModal = () => {
        this.setState({modal: null});
        this.props.clear()
    };

    renderModal = () => {

        let {
            modal
        } = this.state;

        if (typeof document !== 'undefined') {
            if (modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        let {
            recoveryFormError,
            fatalErrorTitle,
            fatalError,
            successMessageTitle,
            successMessage,
            formsSettings
        } = this.props.client;

        switch (modal) {
            case "RecoveryModal":
                return <RecoveryModal close={this.closeModal}
                                      recover={this.props.recover}
                                      error={recoveryFormError}
                                      recoveryFormSettings={formsSettings ? formsSettings.recovery_form : {}}
                />;

            case "ErrorModal":
                return <SuccessModal title={fatalErrorTitle}
                                     text={fatalError}
                                     icon={false}
                                     btnText="ОК"
                                     close={this.closeModal}
                />;
            case "SuccessModal":
                return <SuccessModal title={successMessageTitle}
                                     text={successMessage}
                                     icon={true}
                                     btnText="ОК"
                                     close={this.closeModal}
                />;
            default:
                return "";
        }
    };

    render() {
        let {
            login,
            client,
            qrText
        } = this.props;

        let {
            authFormError,
            formsSettings
        } = client;
        console.log(123123123)
        // if(this.props.client && this.props.client.isGuest && this.props.client.lkMenu) {
        //     window.location.replace("https://motivtelecom.ru/");
        // }

        if(this.props.client && this.props.client.isGuest && this.props.client.lkMenu) return null;
        return <main className="page__main-holder" role="main">
            <div className="page__lk-index-wrap">
                <div className="page__container">
                    <AuthForm showTitle={true}
                              login={login}
                              error={authFormError}
                              authFormSettings={formsSettings && formsSettings.auth_form || {}}
                              onForgetLinkClick={
                                  (e) => {
                                      e.preventDefault();
                                      this.setState({modal: "RecoveryModal"})
                                  }
                              }
                              qrText={qrText}
                    />
                </div>
            </div>
            {this.renderModal()}
        </main>
    }
}

export default connect(
    state => ({
        client: state.client,
        qrText: state.settings.qr_code_description
    }),
    (dispatch) => {
        return {
            login: (data, remember) => dispatch(auth(data, remember)),
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState())
        }
    }
)(LKAuth);