const initState = {
    isOk:false,
    currentPolicies: [],
    currentLicenses: [],
    list: [],
    currentRequisites: null,
    allLicenses: null,
    flagIsB2b: false
};

export function company(state = initState, action) {
    switch (action.type) {
        case 'CONTACTS_ABOUT':
            return {...state, contactsAbout: false};
        case 'CONTACTS_ABOUT_SUCCESS':
            return {...state, contactsAbout: action.data, isOk: true};
        case 'DOCS_ABOUT':
            return {...state, docsAbout: false};
        case 'DOCS_ABOUT_SUCCESS':
            return {...state, docsAbout: action.data, isOk: true};
        case 'COMPANY_POLICY':
            return {...state, currentPolicies: false};
        case 'COMPANY_POLICY_SUCCESS':
            return {...state, currentPolicies: action.data, isOk: true};
        case 'COMPANY_LICENSES':
            return {...state, currentLicenses: false};
        case 'COMPANY_LICENSES_SUCCESS':
            return {...state, currentLicenses: action.data, isOk: true};
        case 'COMPANY_REQUISITES':
            return {...state, currentRequisites: null};
        case 'COMPANY_REQUISITES_SUCCESS':
            return {...state, currentRequisites: action.data, isOk: true};
        case 'COMPANY_ALL_LICENSES':
            return {...state, allLicenses: null};
        case 'COMPANY_ALL_LICENSES_SUCCESS':
            return {...state, allLicenses: action.data, isOk: true};
        case 'COMPANIES_LIST_SUCCESS':
            return {...state, list: action.data.map((item) => ({label: item.title, value: item.id}))};

        case 'FLAG_ISB2B':
            return {...state, flagIsB2b: true};
    }
    return state;
}