import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import LinkC from "../../../js/components/common/LinkC";
import Img from 'react-lazilyload-img';
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import { routesList, routesVacanciesList } from "../../actions/routes";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";
import {contactsList} from "../../actions/contacts";
import { renderActiveField } from "../../tools/tools";

class ForPress extends Component {
    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount() {
        if (this.props.contactsList && this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("press");
        }
    }

    render() {

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "/" + selectedRegion.alias + '/contacts',
                title: "Контакты"
            },
            {
                url: "",
                title: 'Для прессы'
            }
        ];

        const { contactsList } = this.props;
        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'forPressRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="info-page-container__title">Для прессы</h1>

                        <div className="for-press-page__content">
                            {contactsList && contactsList.contacts && contactsList.contacts.length > 0 ?
                                contactsList.contacts.map((el, key)=> {
                                    return (
                                        <div key={key} className="for-press-page__content-item">
                                            {el.title ? <div className="for-press-page__content-item-title"><b>{el.title}</b></div> : null}
                                            {el.fio ? <div className="for-press-page__content-item-phone" dangerouslySetInnerHTML={{__html: el.fio}} /> : null}
                                            {el.phone ? renderActiveField(el.phone, 'phone', 'for-press-page__content-item-phone') : null}
                                            {el.email ? renderActiveField(el.email, 'email', 'for-press-page__content-item-mail') : null}
                                        </div>
                                    )
                                })
                            : null}
                        </div>
                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <AsideMenu 
                                flag={"contactsRoute"} 
                                flagSubMenu={"forPressRoute"} 
                                route={this.props.routesVacancies} 
                                customTitleMenu={'Контакты'} 
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        regions: state.regions,
        contactsList: state.contacts || []
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getContactsList: (name) => dispatch(contactsList(name))
        }
    }
)(ForPress);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const ForPressRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getContactsList',
        args: ['press'],
        object: 'contactsList.contacts',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    }
];
