import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { connect } from "react-redux";
import { userPhoneValueAction } from '../../actions/common';

import Swipe from 'react-easy-swipe';

export class ModalNew extends Component {

    onClick = (e) => {
        e.stopPropagation();
    };

    modalClose = () => {
        // обнуляем глобальное состояние
        this.props.userPhoneValueAction('');
        this.props.close();
    }

    constructor(props) {
        super(props);

        this._modal = null

        this.state = {
            touchOn: false,
        }
    }

    componentDidMount() {
        disablePageScroll(this._modal);
    }

    componentWillUnmount() {
        enablePageScroll(this._modal);
    }

    // onSwipeStart(event) {
    //     console.log('1', event);
    // }

    onSwipeMove = (position, event) => {
        let posY = 0;;
        if (posY < position.y) {
            // console.log('down');
            setTimeout(() => {
                this.modalClose();
            }, 500);

            this.setState({
                touchOn: true,
            });
        }
    }

    // onSwipeEnd(event) {
    //     console.log('3', event);
    // }

    render() {
        
        if (typeof document !== 'undefined') {
            let isSm = false;
            if (window.innerWidth < 768) { isSm = true; }

            let {
                className = "u-modal u-modal_active",
                wrapperClassName = "u-modal-wrapper",
                innerClassName = "u-modal-inner",
                closeBtnClassName = "mnp-popup__close",
                children,
                typeWidth
            } = this.props;

            return ReactDOM.createPortal(
                <div
                    className={className}
                    onClick={(e) => {
                        this.modalClose();
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    ref={ref => this._modal = ref}
                >
                    <div className={`${wrapperClassName} ${typeWidth === 'md' ? 'u-modal-wrapper__md' : ""}`}>
                        {isSm
                            ? <div
                                className={`${innerClassName} ${this.state.touchOn ? "modal_touch" : ''}`}
                                onClick={this.onClick}
                                // onSwipeStart={this.onSwipeStart}
                                // onSwipeEnd={this.onSwipeEnd}
                                // onSwipeMove={this.onSwipeMove}
                            >
                                <Swipe 
                                    className="modal_swiper" 
                                    onSwipeMove={this.onSwipeMove}
                                >
                                    <div className="modal_swiper__block"></div>
                                </Swipe>
                                {children}
                            </div>
                            : <div
                                className={`${innerClassName} ${this.state.touchOn ? "modal_touch" : ''}`}
                                onClick={this.onClick}
                                onTouchMove={this.onTouchMove}
                            >
                                <span onClick={this.modalClose} className={closeBtnClassName} />
                                {children}
                            </div>
                        }

                    </div>
                </div>, document.getElementById('modal'));
        }

        return "";
    }
}

export default connect(
    state => ({
        userPhoneValue: state.common.userPhoneValue,
    }),
    (dispatch) => {
        return {
            userPhoneValueAction: (data) => dispatch(userPhoneValueAction(data)),
        }
    }
)(ModalNew);