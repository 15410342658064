const initState = { 
    isOk:false, 
    isNotFound: false, 
    service: [], 
    archiveServicesList: [], 
    servicesLists: [], 
    termsOfService: [] 
};

export function services(state = initState, action) {

    switch (action.type) {
        case 'SERVICE_LIST_ARCHIVE':
            return {...state, isOk: false};
        case 'SERVICE_LIST_ARCHIVE_SUCCESS':
            return {...state, archiveServicesList: action.data, isOk: true};
        case 'TERMS_OF_SERVICE':
            return {...state, isOk: false};
        case 'TERMS_OF_SERVICE_SUCCESS':
            return {...state, termsOfService: action.data, isOk: true};
        case 'SERVICE_LIST':
            return {...state, isOk: false};
        case 'SERVICE_LIST_SUCCESS':
            return {...state, servicesLists: action.data.map((item) => {
                    return {
                        ...item,
                        isOn: state.onIds && state.onIds.indexOf(item.id_billing) !== -1
                    }
                }), isOk: true};
        // тут повторяющийся тайп
        case 'MY_SERVICES_SUCCESS':
            var switchIds = action.data.filter((item) => item.can_disable).map((item) => item.id);
            var ids = action.data.map((item) => item.id);
            return {
                ...state,
                onIds: ids, //id подключенных услуг,
                servicesLists: state.servicesLists.map((item) => {
                    let isOn = ids.indexOf(item.id_billing) !== -1;
                    let inSwitch = switchIds.indexOf(item.id_billing) !== -1;
                    return {
                        ...item,
                        isOn,
                        canDisable: !isOn || inSwitch,
                    }
                }),
                service: state.service.map((item) => {
                    let isOn = ids.indexOf(item.id_billing) !== -1;
                    let inSwitch = switchIds.indexOf(item.id_billing) !== -1;
                    return {
                        ...item,
                        isOn,
                        canDisable: !isOn || inSwitch,
                        randomServices: item.randomServices.map((randomService) => {
                           let isOn = ids.indexOf(randomService.id_billing) !== -1;
                           let inSwitch = switchIds.indexOf(randomService.id_billing) !== -1;
                           return {
                               ...randomService,
                               isOn,
                               canDisable: !isOn || inSwitch,
                           }
                        })
                    }
                })
            };

        case 'TOGGLE_SERVICES_EMPTY_BODY':
            var ids = state.onIds;
            if (action.data.on) {
                ids.push(action.data.service.id_billing);
            } else {
                ids = ids.filter(val => val !== action.data.service.id_billing);
            }

            return {
                ...state,
                onIds: ids, //id подключенных услуг
                servicesLists: state.servicesLists.map((item) => {
                    return {
                        ...item,
                        isOn: ids.indexOf(item.id_billing) !== -1
                    }
                }),
                service: state.service.map((item) => {
                    return {
                        ...item,
                        isOn: ids.indexOf(item.id_billing) !== -1,
                        randomServices: item.randomServices.map((randomService) => {
                            return {
                                ...randomService,
                                isOn: ids.indexOf(randomService.id_billing) !== -1
                            }
                        })
                    }
                })
            };

        case 'SERVICE_VIEW':
            return {...state, isOk: false, isNotFound: false};
        case 'SERVICE_VIEW_SUCCESS':
            let service = [...state.service];
            if (service.filter((item) => {
                return item.id === action.data.id
            }).length === 0) {
                service.push({...action.data, isOn: state.onIds && state.onIds.indexOf(action.data.id_billing) !== -1});
            }
            return {...state, service, isOk: true, isNotFound: false};

        case 'SERVICE_VIEW_NOT_FOUND':
            return {...state, isNotFound: true};
        case 'LOGOUT_EMPTY_BODY':
            return {
                ...state,
                onIds: [],
                servicesLists: state.servicesLists.map((item) => {
                    return {
                        ...item,
                        isOn: false
                    }
                }),
                service: state.service.map((item) => {
                    return {
                        ...item,
                        isOn: false,
                        randomServices: item.randomServices.map((randomService) => {
                            return {
                                ...randomService,
                                isOn: false
                            }
                        })
                    }
                })
            };
    }
    return state;
}