import React, {Component} from "react";
import {connect} from "react-redux";
import LinkC from "../common/LinkC";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import {numberMask, phoneMask} from "../../tools/mask";
import {
    emailValidator,
    onBlur,
    onFocus,
    onFieldChange,
    renderField,
    phoneValidator,
    innValidator, emptyValidator
} from "../../tools/form-helper";
import CheckBox from "../common/CheckBox";
import {formsList} from "../../actions/forms";
import {sendB2bRequest} from "../../actions/activation";
import success from "../../../images/content/constructor/success.svg";
import PropsTypes from "prop-types";

class B2bTariffConnectionModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                fio: {
                    label: "ФИО",
                    value: '',
                    validators: [emptyValidator],
                    hasError: false,
                    required: true,
                    placeholder: 'ФИО'
                },
                abnum: {
                    label: "Телефон",
                    value: '',
                    mask: phoneMask,
                    validators: [emptyValidator, phoneValidator],
                    hasError: false,
                    required: true,
                    placeholder: '+7',
                    textError: "Укажите корректный номер телефона",
                },
                email: {
                    label: "E–mail",
                    value: "",
                    validators: [emailValidator],
                    hasError: null,
                    textError: "Укажите корректный email",
                    placeholder: 'info@mail.ru'
                },
                company_name: {
                    label: "Название компании",
                    value: '',
                    validators: [emptyValidator],
                    hasError: false,
                    required: true
                },
                inn: {
                    label: "ИНН",
                    value: "",
                    validators: [innValidator],
                    mask: numberMask(12),
                    hasError: null,
                    textError: "Укажите корректный ИНН"
                },
            },
            agreement: 0,
            sending: false,
            formAlias: props.tariff ? 'tariff_request_form_b2b' : 'become_client_form_b2b'
        }

        this.onBlur = onBlur.bind(this)
        this.onFocus = onFocus.bind(this)
        this.onChange = onFieldChange.bind(this)
        this.renderField = renderField.bind(this)

        const googleCaptchaSetting = JSON.parse(props.settings.googleCaptcha)
        this.siteKey = googleCaptchaSetting.publicKey;
    }

    static contextTypes = {
        b2bModalToggle: PropsTypes.func
    }

    onFieldChange = (name) => {
        return (e) => {
            this.props.clearForm()
            this.onChange(name)(e)
        }
    }

    componentDidMount() {
        loadReCaptcha(this.siteKey)
        const {forms, getForms} = this.props;

        if (!forms.length) {
            getForms();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.formResult.result && this.props.formResult.result) {
            this.setState({sending: false})
        }
    }

    componentWillUnmount() {
        this.props.clearForm();
    }

    toggleAgreement = () => {
        this.setState((prevState) => ({agreement: prevState.agreement === 0 ? 1 : 0}))
    }

    validate = () => {
        const fields = {...this.state.fields}

        for (let attr in fields) {
            if (fields[attr].hasError || (fields[attr].required && !fields[attr].value)) {
                return false;
            }

            if (fields[attr].value && fields[attr].validators) {
                let valid = true;
                fields[attr].validators.forEach(function (func) {
                    if (!func.call(this, fields[attr].value)) {
                        valid = false;
                    }
                });
                if (!valid) {
                    return false;
                }
            }
        }

        return this.state.agreement;
    }

    verifyCallback = (token) => {
        if (this.state.sending) {
            const {agreement, fields} = {...this.state};

            let data = new FormData();
            for (let attr in fields) {
                if (fields[attr].value) {
                    data.append(attr, attr === 'abnum' ? fields[attr].value.replace(/\D/g, '').substr(1) : fields[attr].value)
                }
            }
            data.append('is_agree', agreement)
            data.append('captcha', token)
            this.props.submitForm(data, this.props.tariff ? this.props.tariff.id : null);
        }
    }

    submit = (e) => {
        e.preventDefault();

        if (this.validate()) {
            this.recaptcha.execute();
            this.setState({sending: true})
        }
    }

    render() {

        const {tariff, forms, formResult, closeModal} = this.props;
        const {result, errors, message} = formResult;
        const formAlias = this.state.formAlias;
        const form = forms.find((item) => item.alias === formAlias);

        const disableBtn = !this.validate() || this.state.sending;

        return result === 'success' ? <div className="modal-success-info">
            <div className="content">
                <img alt="success" src={success}/>
                <h2 className="modal-title">{form.settings[`${formAlias}_header`]}</h2>
                {form.settings[`${formAlias}_content`] ? <div className="text">{form.settings[`${formAlias}_content`]}</div> : null}
                <button className="btn btn-accent u-btn u-btn_default" onClick={tariff ? closeModal : this.context.b2bModalToggle}>ОК</button>
            </div>
        </div> : <div className="tariff-connection-modal tariff-b2b-modal">
            {tariff ? <React.Fragment>
                <div className="tariff-title">
                    <h2 className="modal-title">Подключение тарифа «{tariff.name}»</h2>
                    <div className="price">
                        <div>Абонентская плата:</div>
                        <div className="cost">
                            {tariff.subscription_fee}
                            <span className="unit"
                                  dangerouslySetInnerHTML={{__html: tariff.writeOffPeriod.measure.replace('₽', '<span>₽</span>')}}/>
                        </div>
                    </div>
                </div>
                <LinkC to={'/tariffs/' + tariff.slug}>Подробнее о тарифе</LinkC>
            </React.Fragment> : <div className="tariff-title">
                <h2 className="modal-title">Стать клиентом</h2>
            </div>}
            <form className="b-step-form__block_inputs" onSubmit={this.submit}>
                <div className="b-step-form__row">
                    <div className="b-step-form__item b-step-form__item_size_full">
                        <div className="b-step-form__input">
                            <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                {this.renderField("fio")}
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__item b-step-form__item_size_half">
                        <div className="b-step-form__input">
                            <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                {this.renderField("abnum")}
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__item b-step-form__item_size_half">
                        <div className="b-step-form__input">
                            <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                {this.renderField("email")}
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__item b-step-form__item_size_half">
                        <div className="b-step-form__input">
                            <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                {this.renderField("company_name")}
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__item b-step-form__item_size_half">
                        <div className="b-step-form__input">
                            <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                {this.renderField("inn")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tariff-b2b-modal__agreement-row">
                    <CheckBox
                        className="tariff-b2b-modal__agreement"
                        name={'agreement'}
                        label={form ? form.settings[`${formAlias}_agreement`] : ''}
                        value={this.state.agreement}
                        onChange={this.toggleAgreement}
                    />
                    <div className="description--dot">
                        <span className="orange">*</span>
                        <span className="form-complain-description-txt">– поля обязательные для заполнения</span>
                    </div>
                </div>
                <div className="tariff-b2b-modal__submit-row">
                    <span className={"complain-form-captcha-text"}>
                        Этот сайт защищен reCAPTCHA и применяются <a href="https://policies.google.com/privacy" target="_blank" >Политика конфиденциальности</a> и <a href="https://policies.google.com/terms" target="_blank" >Условия обслуживания</a> Google
                    </span>
                    {this.siteKey ? <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={this.siteKey}
                        action='become_client'
                        verifyCallback={this.verifyCallback}
                    /> : null}
                    <button
                        type="submit"
                        className={`u-btn u-btn_adapt_mobile-sm u-btn_default${disableBtn ? ' disabled' : ''}`}
                        {...disableBtn ? {disabled: true} : {}}
                    >Отправить</button>
                </div>
            </form>
            {result === 'error' ?
                message ? <div className='disabled-msg'>{message}</div> :
                    errors && errors.captcha ? <div className='disabled-msg'>{errors.captcha}</div> : null
                : null}
        </div>
    }

}

export default connect(
    state => ({
        forms: state.forms.list,
        formResult: state.activation.b2bForm,
        settings: state.settings
    }),
    (dispatch) => {
        return {
            getForms: () => dispatch(formsList('b2b')),
            submitForm: (data, tariffId) => dispatch(sendB2bRequest(data, tariffId)),
            clearForm: () => dispatch({type: 'SEND_B2B_REQUEST_CLEAR'})
        }
    }
)(B2bTariffConnectionModal)