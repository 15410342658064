const initState = {
    isOk: false,
    contacts: [],
    stateDataForSubscribers: [],
    stateDataContacts: [],
};

export function contacts(state = initState, action) {

    switch (action.type) {
        case 'CONTACTS_LIST':
            return {...state, isOk: false};
        case 'CONTACTS_LIST_SUCCESS':
            return {...state, contacts: action.data, isOk: true};
        case 'CONTACTS_BUSINESS':
            return {...state, isOk: false};
        case 'CONTACTS_BUSINESS_SUCCESS':
            return {...state, contactsBusiness: action.data, isOk: true};
        case 'CONTACTS_PARTNERS':
            return {...state, isOk: false};
        case 'CONTACTS_PARTNERS_SUCCESS':
            return {...state, contactsPartners: action.data, isOk: true};
        case 'DATA_FOR_SUBSCRIBERS_SUCCESS':
            return {
                ...state,
                stateDataForSubscribers: action.data
            };
        case 'DATA_CONTACTS_SUCCESS':
            return {
                ...state,
                stateDataContacts: action.data
            };
    }
    return state;
}