import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList} from "../../actions/routes";
import OfficeMap from "../../components/common/OfficeMap";
import { addressAutoComplete } from "../../actions/forms";
import { shopsList, closestShopsList } from "../../actions/shops";
import AutoComplete from "./../../components/common/AutoComplete";
import {normalizeForSelect} from "../../tools/tools";
import ListOffices from "../../components/common/ListOffices";
import {coverImagesList} from "../../actions/coverImages";

class ListOffice extends Component {

    constructor(props) {

        super(props);

        //let route = props.routes.list.find(x => x.sys_name === 'listOfficeRoute');

        this.state = {
            route: null,
            showTabs: true,
            typeDisplay: 'map',
            typeMap: 'sim'
        };
    }

    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if ( this.props.shops.list.length === 0 ) {
            this.props.getShopsList(regionId);
        }

        this.props.getRoutes();

        this.props.getCoverImages();
    }
    
    typeDisplayHandler = (val) => {
        if (val) {
            this.setState({
                typeDisplay: val
            })
        }
    };

    chooseAddress = (value) => {
        if (value) {
            this.props.addressAutoComplete('addressFull', value, {
                count: 1,
                regionId: this.context.selectedRegion.id,
                allRegions: 1
            });
            //this.setState({getClosest: true});
        } else {
            this.props.getShopsList(this.context.selectedRegion.id);
        }
    };

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    render() {

        let selectedRegion = this.context.selectedRegion;

        let {
            forms,
            shops,
            addressAutoComplete,
            getClosestShopsList,
            getShopsList
        } = this.props;

        let {
            route,
            showTabs,
            typeDisplay,
            typeMap
        } = this.state;

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={route ? route.meta_title : ""}
                    meta={[
                        {"name": "description", "content": route && route.meta_description ? route.meta_description : ""},
                        {"name": "keywords", "content": route && route.meta_keywords ? route.meta_keywords : ""}
                    ]}
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                />
                <div className="page__container">
                    <div className="page__options options-select">
                        <AutoComplete
                            className="options-select__search-input search-input-block"
                            inputOptions={{
                                className: "search-input-block__input",
                                placeholder: "Найти салон по адресу или метро"
                            }}
                            options={normalizeForSelect(forms.addressFull || [])}
                            getOptions={(val) =>
                                {
                                    if (val.length >= 3 && typeMap === 'mnp') {
                                        addressAutoComplete('addressFull',
                                            val,
                                            {
                                                region: selectedRegion.name.substr(0, selectedRegion.name.indexOf(" ")),
                                                regionId: selectedRegion.id
                                            });
                                    }
                                    else if (val.length >= 3 && typeMap !== 'mnp') {
                                        addressAutoComplete('addressFull',
                                            val, {
                                                regionId: selectedRegion.id,
                                                allRegions: 1
                                            });
                                    }
                                }
                            }
                            onChange={this.chooseAddress}
                        />
                        <ul className="options-select__type-display-list type-display-list">
                           <li className={ `type-display-list__item type-display-item ${ typeDisplay === 'map' ? 'type-display-item_active' : null }`} onClick={() => this.typeDisplayHandler('map')}>
                               <svg width='16' height='19' viewBox='0 0 16 19' xmlns='http://www.w3.org/2000/svg' className="type-display-item__icon">
                                   <g id='map' fill='none' fillRule='evenodd'>
                                       <g id='Group-6' transform='translate(1 1)'>
                                           <path d='M1.7024671,11.596449 C0.641665999,10.3672216 0,8.76492918 0,7.0125 C0,3.13960319 3.13400675,0 7,0 C10.8659932,0 14,3.13960319 14,7.0125 C14,8.76492918 13.358334,10.3672216 12.2975329,11.596449 C11.5903322,12.415934 9.82448786,14.2171176 7,17 C4.17551214,14.2171176 2.40966784,12.415934 1.7024671,11.596449 Z'
                                                 id='Path' stroke='#f37021' strokeWidth='2' />
                                           <circle id='Oval-Copy' fill='#F37021' cx='7' cy='7' r='2' />
                                       </g>
                                   </g>
                               </svg>
                               На карте
                           </li>
                           <li className={`type-display-list__item type-display-item ${ typeDisplay === 'list' ? 'type-display-item_active' : null }`} onClick={() => this.typeDisplayHandler('list')}>
                               <svg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' className="type-display-item__icon">
                                   <path className="type-display-item__icon_active-fill" d='M0 0h2v2H0zM0 5h2v2H0zM5 0h11v2H5zM5 5h11v2H5zM0 10h2v2H0zM5 10h11v2H5z'
                                         fillRule='evenodd' />
                               </svg>
                               Cписком
                           </li>
                        </ul>
                    </div>
                    {
                        typeDisplay === "list" ?
                            <ListOffices items={shops.list}
                                         getClosestShopsList={getClosestShopsList}
                                         address={forms.addressFull || []}
                            />
                            : null
                    }

                    {
                        typeDisplay === "map" ?
                            <OfficeMap  items={shops.list}
                                        address={forms.addressFull || []}
                                        addressAutoComplete={addressAutoComplete}
                                        getClosestShopsList={getClosestShopsList}
                                        getShopsList={getShopsList}
                                        inProgress={forms.inProgress}
                                        showTabs={showTabs}
                                        typeMap={typeMap}
                                        coverCoords={this.props.coverImages.list}
                            />
                            : null
                    }
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        shops: state.shops,
        forms: state.forms,
        coverImages: state.coverImages
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            addressAutoComplete: (type, query, params) => dispatch(addressAutoComplete(type, query, params)),
            getClosestShopsList: (regionId, lat, lon, region, city, street) => dispatch(closestShopsList(regionId, lat, lon, region, city, street)),
            getShopsList: (regionId) => dispatch(shopsList(regionId)),
            getCoverImages: () => dispatch(coverImagesList())
        }
    }
)(ListOffice);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const listOfficeRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
];