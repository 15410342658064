import React, { Component } from 'react';

import CheckboxNew from "../Constructor/CheckboxNew";
import CheckboxPseudo from "../Constructor/CheckboxPseudo";

class SwitcherInputNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let {
            checkboxClick,
            nameData,
            valueData,
            onChangeData,
            nameRouter,
            valueRouter,
            onChangeRouter,
            nameSocial,
            valueSocial,
            onChangeSocial,
            nameVoice,
            valueVoice,
            onChangeVoice,
            nameYoutube,
            valueYoutube,
            onChangeYoutube,
            nameIvi,
            valueIvi,
            onChangeIvi,
            nameHome,
            valueHome,
            onChangeHome,
            currentSmsPack,
            optionSmsArr,
            chOn,
            disabledRqs,
            type,
            forced,
            maxFixedPrice
        } = this.props;

        let smsType =  optionSmsArr && optionSmsArr.find((i) => i.type === type);
        return (
            <React.Fragment>
                {!!chOn.dataChOn && type === "DATA_UNLIM" &&
                    (<CheckboxNew 
                        checkboxClick={checkboxClick}
                        name={nameData}
                        value={valueData}
                        onChange={onChangeData}
                        // typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        forced={forced}
                        disabled={maxFixedPrice}
                    />)
                }
                {!!valueData && !!chOn.routerChOn && type === "ROUTER" &&
                    (<CheckboxNew 
                        name={nameRouter}
                        value={valueRouter}
                        onChange={onChangeRouter}
                        // typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        forced={forced}
                    />)
                }
                {!!chOn.socialChOn && type === "SOCIAL_UNLIM" &&
                    (<CheckboxNew 
                        name={nameSocial}
                        value={valueSocial}
                        onChange={onChangeSocial}
                        // typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        forced={forced}
                    />)
                }
                {!!chOn.ytbChOn && type === "YOUTUBE_UNLIM" &&
                    (<CheckboxNew 
                        name={nameYoutube}
                        value={valueYoutube}
                        onChange={onChangeYoutube}
                        // typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        forced={forced}
                    />)
                }
                {!!chOn.iviChOn && type === "IVI" &&
                    (<CheckboxNew 
                        name={nameIvi}
                        value={valueIvi}
                        onChange={onChangeIvi}
                        // typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        forced={forced}
                    />)
                }
                {!!chOn.voiceChOn && type === "MVOICE_UNLIM" &&
                    (<CheckboxNew 
                        name={nameVoice}
                        value={valueVoice}
                        onChange={onChangeVoice}
                        // typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        forced={forced}
                    />)
                }
                {!!chOn.homeChOn && type === "HOME_REGION_CHECKED" &&
                    (<CheckboxNew 
                        name={nameHome}
                        value={valueHome}
                        onChange={onChangeHome}
                        disabledRqs={disabledRqs}
                        forced={forced}
                    />)
                }
                {type === (smsType && smsType.type) &&
                    (<CheckboxPseudo
                        name={type}
                        toggleSmsPack={this.props.toggleSmsPack}
                        currentSmsPack={currentSmsPack}
                        type={type}
                    />)
                }
            </React.Fragment>
        )
    }
}


export default SwitcherInputNew;