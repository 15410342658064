const initState = {
    stateSearchInput: '',
    stateToggleSearchInput: false,
    stateSearchHint: [],
    stateSearchPage: [],
    stateSearchPagePagination: {},
    statePopularQueries: [],
    stateSearchResultPagination: 2,
    stateListFilters: [],
    stateCurrentFilter: '',
};

export function searchReducer(state = initState, action) {

    switch (action.type) {
        case 'TOGGLE_SEARCH_INPUT':
            return {
                ...state,
                stateToggleSearchInput: action.data,
            };

        case 'STATE_SEARCH_INPUT':
            return {
                ...state,
                stateSearchInput: action.data,
            };
        
        case 'SEARCH_HINT_SUCCESS':
            return {
                ...state,
                stateSearchHint: action.data,
            };

        case 'CLEAR_SEARCH_HINT':
            return {
                ...state,
                stateSearchHint: [],
            };

        case 'SEARCH_PAGE_SUCCESS':
            return {
                ...state,
                stateSearchPage: [...state.stateSearchPage, ...action.data],
            };
        
        case 'CLEAR_SEARCH_PAGE':
            return {
                ...state,
                stateSearchPage: [],
            };

        case 'SEARCH_PAGE_PAGINATION':
            return {
                ...state,
                stateSearchPagePagination: action.data,
            };

        case 'POPULAR_QUERIES_SUCCESS':
            return {
                ...state,
                statePopularQueries: action.data,
            };
        
        case 'CLEAR_SEARCH_PAGE_PAGINATION':
            return {
                ...state,
                stateSearchPagePagination: {},
            };

        case 'SEARCH_PRESULT_PAGINATION':
            return {
                ...state,
                stateSearchResultPagination: state.stateSearchResultPagination + 1,
            };

        case 'CLEAR_SEARCH_PRESULT_PAGINATION':
            return {
                ...state,
                stateSearchResultPagination: 2,
            };

        case 'LIST_FILTERS_SUCCESS':
            return {
                ...state,
                stateListFilters: action.data,
            };

        case 'CLEAR_LIST_FILTERS':
            return {
                ...state,
                stateListFilters: [],
            };

        case 'CURRENT_FILTER':
            return {
                ...state,
                stateCurrentFilter: action.data,
            };
    }
    return state;
}