import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Helmet } from "react-helmet";
import InfoBlock from "../../components/common/InfoBlock";
import {internetItemsList} from "../../actions/internet";
import RatesList from "../../components/Rates/RatesList";
import RatesServiceList from "../../components/Rates/RatesServiceList";
import {infoBlocksList} from "../../actions/infoBlocks";
import {faqList} from "../../actions/questions";
import RatesFaq from "../../components/Rates/RatesFaq";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import {tariffsList} from "../../actions/tariffs";
import {servicesList} from "../../actions/services";
import {services} from "../../actions/client";

class RatesInternet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };
        // для ssr
    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if (typeof this.props.internet.internetItemsList === 'undefined') {
            this.props.getInternetItemsList(regionId);
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'mobile_internet');
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(regionId, 2); //мобильный интернет
        }
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(regionId, 'rank_router;rank_tab');
        }
        if (this.props.services.servicesLists.length === 0) {
            this.props.getServices(regionId, 'rank_router;rank_tab');
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
    }

    componentDidMount() {
        let regionId = this.context.selectedRegion.id;

        if (typeof this.props.internet.internetItemsList === 'undefined') {
            this.props.getInternetItemsList(regionId);
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'mobile_internet');
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(regionId, 2); //мобильный интернет
        }
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(regionId, 'rank_router;rank_tab');
        }
        if (this.props.services.servicesLists.length === 0) {
            this.props.getServices(regionId, 'rank_router;rank_tab');
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
    }

    filterByRegionNRank = (items, rankField) => {

        let regionId = this.context.selectedRegion.id;

        return items.filter(function (item) {
            if (item.region_id !== regionId) {
                return false;
            }
            if (item.mainCategory[rankField] !== null) {
                return true;
            }
            let addCats = item.additionalCategories.filter(function (cat) {
                return cat.hasOwnProperty(rankField) && cat[rankField] !== null
            });

            return addCats.length > 0;
        });
    };

    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: val
                }
            })
        }
    };

    render() {
        const { regions, location } = this.props;

        if (typeof this.props.internet.internetItemsList === 'undefined') {
            return null;
        }

        let tariffs = this.props.tariffs.tariffsLists;
        let services = this.props.services.servicesLists;

        let selectedRegion = this.context.selectedRegion;
        let activeTab = this.state.activeTab;

        let {
            packagesTab,
            packagesRouter,
        } = this.props.internet.internetItemsList;

        let infoBlocks = this.props.infoBlocks.list;

        let tariffsTab = this.filterByRegionNRank(tariffs, 'rank_tab');
        let tariffsRouter = this.filterByRegionNRank(tariffs, 'rank_router');
        let servicesTab = this.filterByRegionNRank(services, 'rank_tab');
        let servicesRouter = this.filterByRegionNRank(services, 'rank_router');

        let faqList = this.props.faq.list.filter(x => x.category_id === 2);

        let currentPage = {
            url: "/" + selectedRegion.alias + "/internet",
            title: "Интернет",
        };

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'internetRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container">
                    <h1 className="page__title">Интернет</h1>
                    <div className="b-internet-ipad b-internet-ipad_adapt_default">
                        <div className="b-internet-ipad__promo-invite">
                            <div className="b-promo-invite b-promo-invite b-promo-invite_adapt_default">
                                <div className="b-promo-invite__wrapper">
                                    <div className="b-promo-invite__wrapper">
                                        <InfoBlock type={'three-quarters-banner'} data={infoBlocks} section={'mobile_internet_banner'} />
                                        <InfoBlock type={'one-quarter-banner'} data={infoBlocks} section={'mobile_internet_orange_block'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-internet-ipad__simple-advantages">
                            <div className="b-internet-ipad__sa-title">Почему 4G интернет Мотив</div>
                            <div className="b-simple-advantages b-simple-advantages_adapt_default">
                                <div className="b-simple-advantages__body">
                                    <InfoBlock type={'internet-text-banner'} data={infoBlocks} section={'internet_description_main'} />
                                    <InfoBlock type={'internet-small-banner'} data={infoBlocks} section={'internet_description_additional'} group={'mobile_internet'} />
                                </div>
                            </div>
                        </div>
                        <div className="b-internet-ipad__tarifs-list">
                            <div className="b-internet-ipad__tabs-header">
                                <TabsSwitcher items={[{title: "Планшет"}, {title: "Роутер"}]} onClickFunc={this.onToggleTab} activeTab={activeTab}/>
                            </div>
                        </div>
                        <ul className="tabs__content" ref="content">
                            <li className={"tabs-content__item" + ((activeTab === 0) ? " tabs-content__item--active" : "")}>
                                <div className="b-internet-ipad__tariffs-table">
                                    <div className="b-tariffs-table b-tariffs-table_adapt_default">
                                        {   tariffsTab.length > 0
                                            ?   <div className="b-internet-ipad__option-table">
                                                    <div className="b-tariffs-table__title">Тарифы</div>
                                                    <RatesList currentPage={currentPage} rates={tariffsTab} byCat={false} selectedRegion={selectedRegion} />
                                                </div>
                                            :   ''
                                        }
                                        {   packagesTab.length > 0
                                            ?   <div className="b-internet-ipad__option-table">
                                                    <div className="page__title-3">Пакеты</div>
                                                    <RatesServiceList currentPage={currentPage} items={packagesTab} opened={true}/>
                                                </div>
                                            :   ''
                                        }
                                        {   servicesTab.length > 0
                                            ?   <div className="b-internet-ipad__option-table">
                                                    <div className="page__title-3">Услуги</div>
                                                    <RatesServiceList currentPage={currentPage} items={servicesTab} opened={true}/>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                </div>
                            </li>
                            <li className={"tabs-content__item" + ((activeTab === 1) ? " tabs-content__item--active" : "")}>
                                <div className="b-internet-ipad__tariffs-table">
                                    <div className="b-tariffs-table b-tariffs-table_adapt_default">
                                        {   tariffsRouter.length > 0
                                            ?   <div className="b-internet-ipad__option-table">
                                                    <div className="b-tariffs-table__title">Тарифы</div>
                                                    <RatesList currentPage={currentPage} rates={tariffsRouter} byCat={false} selectedRegion={selectedRegion} />
                                                </div>
                                            :   ''
                                        }
                                        {   packagesRouter.length > 0
                                            ?   <div className="b-internet-ipad__option-table">
                                                    <div className="page__title-3">Пакеты</div>
                                                    <RatesServiceList currentPage={currentPage} items={packagesRouter} opened={true}/>
                                                </div>
                                            :   ''
                                        }
                                        {   servicesRouter.length > 0
                                            ?   <div className="b-internet-ipad__option-table">
                                                    <div className="page__title-3">Услуги</div>
                                                    <RatesServiceList currentPage={currentPage} items={servicesRouter} opened={true}/>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <InfoBlock type={'internet-big-banner'} data={infoBlocks} section={'internet_promo_banner'} />
                        {
                            faqList
                                ?   <RatesFaq items={faqList} title={"Дополнительная информация"} />
                                :   ''
                        }
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        internet: state.internet,
        infoBlocks: state.infoBlocks,
        faq: state.faq,
        tariffs: state.tariffs,
        services: state.services,
        client: state.client,
        regions: state.regions,

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getInternetItemsList : (regionId) => dispatch(internetItemsList(regionId)),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getFaq : (regionId, categoryId) => dispatch(faqList(regionId, categoryId)),
            getTariffs : (regionId, categoryRankField, tariffRankField) => dispatch (tariffsList(regionId, categoryRankField, tariffRankField)),
            getServices : (regionId, categoryRankField) => dispatch(servicesList(regionId, categoryRankField)),
            getMyServices: () => {dispatch(services())}
        }
    }
)(RatesInternet);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */

export const RatesInternetRequests = [
    {
        func: 'getInternetItemsList',
        args: [],
        object: 'internet.internetItemsList',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false,
        checkRegionArr: {
            tariffsTab: 'tariffsTab',
            tariffsRouter: 'tariffsRouter',
            servicesTab: 'servicesTab',
            servicesRouter: 'servicesRouter',
            packagesTab: 'packagesTab',
            packagesRouter: 'packagesRouter'
        }
    },
    {
        func: 'getTariffs',
        args: ['rank_router;rank_tab'],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getServices',
        args: ['rank_router;rank_tab'],
        object: 'services.servicesLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['mobile_internet'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "mobile_internet_banner"},
            {type: "mobile_internet_orange_block"},
            {type: "internet_description_main"},
            {type: "internet_description_additional"},
            {type: "internet_promo_banner"},
        ]
    },
    {
        func: 'getFaq',
        args: [2],
        object: 'faq.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
];



export const RatesInternetLkRequests = [
    {
        func: 'getMyServices',
        args: [],
        object: 'client.services',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];