import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { tariffsList } from "../../actions/tariffs";
import PropTypes from "prop-types";
import { infoBlocksList } from "../../actions/infoBlocks";
import { faqList } from "../../actions/questions";
import {
    formsList,
    submitMnpForm,
    addressAutoComplete,
    clearAddress,
    clearCity,
    clearStreet,
    clearHouse,
    mnpFormEsiaAction,
    cleareMnpFormEsiaAction
} from "../../actions/forms";
import InfoBlock from "../../components/common/InfoBlock";
import MnpForm from "../../components/Rates/MnpForm";
import RatesFaq from "../../components/Rates/RatesFaq";
import OfficeMap from "../../components/common/OfficeMap";
import { shopsList, closestShopsList } from "../../actions/shops";
import { checkOperator, clearActivationData, checkRadioValue } from "../../actions/activation";
import { setCurrentStep } from '../../actions/stepActions';
import { deleteCookie, deleteQueryParam, getQueryParam, hasQueryParam, smoothScroll } from '../../tools/tools';
import Icon from "../../components/common/Icon";
import RequiredText from "../../components/common/form/RequiredText";
import CommonCallbackForm from "../../components/Forms/CommonCallbackForm";
import { scroller } from "react-scroll";

class RatesMnp extends Component {

    constructor(props) {
        super(props);

        let activation = props.activation;
        this.state = {
            step: props.location.state && props.activation.isOk ? props.location.state.step : 0,
            fields: {
                operator: activation.isOk && activation.mnp.hasOwnProperty('operatorTitle') && activation.mnp.operatorTitle ? activation.mnp.operatorTitle : "Не определен",
                region: activation.isOk && activation.mnp.hasOwnProperty('regionTitle') && activation.mnp.regionTitle ? activation.mnp.regionTitle : "Не определен",
                phone: props.location.state && activation.isOk ? props.location.state.phone : "",
                titleRegion: activation.isOk && activation.mnp.hasOwnProperty('regionTitle') && activation.mnp.regionTitle ? activation.mnp.regionTitle : "Не определен"
            },
            isFinalStepComplete: null,
            scrollToElem: null,
            constructor: null,
            target: "MNP",
            validPhoneNumber: false,

            toggleForm: 0
        };

        if (activation.mnpConstructor) {
            let mnpData = activation.mnpConstructor;
            this.state.step = 1;
            this.state.fields = {
                operator: mnpData.checkOperatorObj.operatorTitle,
                region: mnpData.checkOperatorObj.regionTitle,
                titleRegion: mnpData.checkOperatorObj.regionTitle,
                phone: mnpData.phone.replace(/\D/gim, ''),
            };
            this.state.constructor = mnpData;
            this.state.target = "Конструктор_тарифа"
        }
    }



    static contextTypes = {
        selectedRegion: PropTypes.object,
        regions: PropTypes.array,
        setHeaderClassName: PropTypes.func
    };

    static childContextTypes = {
        goToStep: PropTypes.func,
        step: PropTypes.number,
        fields: PropTypes.object
    };

    getChildContext() {
        return {
            goToStep: this.goToStep,
            step: this.state.step,
            fields: this.state.fields
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forms.isSubmitOk !== this.props.forms.isSubmitOk && nextProps.forms.isSubmitOk === this.state.alias) {
            if (["personal_info", "ordering_final", "operator_not_defined", "final_form_v2"].indexOf(nextProps.forms.isSubmitOk) === -1) {
                if ("delivery_address".indexOf(nextProps.forms.isSubmitOk) === -1) {
                    this.goToStep(this.state.step + 1);
                } else {
                    this.goToStep(this.state.step + 2);
                }
            } else {
                this.setState({ isFinalStepComplete: nextProps.forms.isSubmitOk });
            }
        }

        if (nextProps.activation.isOk !== this.props.activation.isOk && Object.keys(nextProps.activation.mnp).length !== 0) {
            let fields = { ...this.state.fields };
            fields.operator = nextProps.activation.mnp.operatorTitle || "Не определен";
            fields.titleRegion = nextProps.activation.mnp.regionTitle || "Не определен";
            this.setState({ fields });
        }

        if (typeof this.props.activation.mnp.statusCode !== 'undefined' && this.props.activation.mnp.statusCode === 1001) {
            // if (this.state.step !== 1){
            //     this.goToStep(this.state.step + 1);
            // }

            if (this.state.step !== 1 && this.state.toggleForm) {
                this.goToStep(this.state.step + 1);
            }
        }
    }

    componentWillMount() {
        let regionId = this.context.selectedRegion.id;
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(regionId);
        }
        if (this.props.infoBlocks.length === 0) {
            this.props.getInfoBlocks(regionId, 'mnp_page');
        }
        if (this.props.faq.length === 0) {
            this.props.getFaq(regionId, 1); //mnp
        }
        if (this.props.forms.list.length === 0) {
            this.props.getForms("mnp");
        }
        if (this.props.shops.list.length === 0) {
            this.props.getShopsList(regionId);
        }

        if (typeof document !== 'undefined') {
            if (this.state.step === 0) {
                this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_white");
                this.props.setCurrentStep(0)
            } else {
                if (this.state.isFinalStepComplete === null) {
                    this.context.setHeaderClassName("page__header-holder");
                    this.props.setCurrentStep(1)
                    document.querySelector('header').classList.remove('page__header-holder_float')
                    document.querySelector('header').classList.remove('page__header-holder_white')
                }
            }
        }
    }

    componentDidUpdate() {
        let scrollTo = this.state.scrollToElem;
        if (scrollTo !== null) {
            smoothScroll(scrollTo);
        }
    }

    componentDidMount() {
        let regionId = this.context.selectedRegion.id;
        this.props.getInfoBlocks(regionId, 'mnp_page');


        setTimeout(() => {
            if (window.location.hash) {
                this.handleScroll(window.location.hash.replace('#', ''))
            }
        }, 500);
    }



    // якорь к нужному месtу
    handleScroll = (target) => {
        scroller.scrollTo(target, {
            duration: 500,
            delay: 1000,
            offset: -80,
            smooth: 'easeInOutQuart'
        });
    };

    onToggleForm = (data) => {
        this.setState({
            toggleForm: +data
        });
    }

    goToStep = (step) => {
        if (step === 0) {
            this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_white");

            this.props.clearData();
            this.setState({
                step: 0,
                fields: {
                    operator: "",
                    region: "",
                    phone: "",
                    titleRegion: ""
                },
                isFinalStepComplete: null,
                scrollToElem: null,
                constructor: null
            });
        } else if (step === 5) {
            if (this.state.constructor) {
                if (this.state.step === 6) {
                    step = 4;
                } else {
                    step = 6;
                }
            }

            this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_black");
        } else {
            this.context.setHeaderClassName("page__header-holder page__header-holder_black");
            this.props.setCurrentStep(1)
            document.querySelector('header').classList.remove('page__header-holder_float')
            document.querySelector('header').classList.remove('page__header-holder_white')

        }
        this.setState({ step }, () => {
            window.scrollTo(0, 0)
        });
    };

    clearCity = () => {
        this.props.clearAutoCompleteCity();
    };

    clearAddress = () => {
        this.props.clearAutoCompleteAddress();
    };

    clearStreet = () => {
        this.props.clearAutoCompleteStreet();
    };

    clearHouse = () => {
        this.props.clearAutoCompleteHouse();
    };

    beutifyPhoneNumber(p) {
        if (p === "") {
            return "";
        }
        return ' ' + p[0] + ' (' + p[1] + p[2] + p[3] + ') ' + p[4] + p[5] + p[6] + ' - ' + p[7] + p[8] + ' - ' + p[9] + p[10];
    }

    renderBreadcrumbs = () => {
        return <div className="page__breadcrumbs">
            <div className="b-breadcrumbs b-breadcrumbs_theme_grey">
                <ul className="b-breadcrumbs__list">
                    <li className="b-breadcrumbs__item">
                        <a className="b-breadcrumbs__link" href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                this.goToStep(0);
                            }}
                        >Перейти в МОТИВ со своим номером</a>
                    </li>
                </ul>
            </div>
        </div>
    };

    renderNumberInfo = () => {

        let fields = this.state.fields;

        return <div className="page__b-num-transfer-info">
            <div className="b-num-transfer-info b-num-transfer-info_adapt-default">
                <div className="b-num-transfer-info__line">
                    <div className="b-num-transfer-info__text">Номер для переноса в МОТИВ:</div>
                    <div
                        className="b-num-transfer-info__tel"> + {this.beutifyPhoneNumber(fields.phone)} ({fields.operator + ' ' + fields.titleRegion})
                    </div>
                    {this.state.constructor === null
                        ? <div onClick={this.goBackToForm} title='сменить номер для переноса'>
                            <Icon name={"icon_edit"} className={"b-num-transfer-info__icon u-icon u-icon_edit"} />
                        </div>
                        : ''
                    }
                </div>
                {this.renderTariffConstructorInfo()}
            </div>
        </div>
    };

    renderTariffConstructorInfo = () => {

        let constructor = this.state.constructor;

        if (constructor === null) {
            return '';
        }

        return <div className="b-num-transfer-info__text b-num-transfer-info__constructor-info">"Свой
            тариф": {constructor.userTariffName ? '"' + constructor.userTariffName + '", ' : ''}{constructor.traffic} ГБ, {constructor.min} мин{constructor.social_network_unlim ? ', безлимит на соцсети' : ''}{constructor.sms ? ', ' + constructor.sms + ' sms' : ''}</div>

    };

    renderShopInfo = () => {

        let fields = this.state.fields;

        return <div className="page__b-num-transfer-info">
            <div className="b-num-transfer-info b-num-transfer-info_adapt-default">
                <div className="b-num-transfer-info__line">
                    <div className="b-num-transfer-info__text"><strong>Пункт самовывоза:</strong></div>
                    <div className="b-num-transfer-info__tel">Салон связи
                        "Мотив". {fields.shop_city}. {fields.shop_address}</div>
                    <div onClick={() => {
                        this.goToStep(3)
                    }}>
                        <Icon name={"icon_edit"} className={"b-num-transfer-info__icon u-icon u-icon_edit"} />
                    </div>
                </div>
                {this.renderTariffConstructorInfo()}
            </div>
        </div>
    };

    // это сабмит который отправляется при нажатии на кнопку во второй форме
    onSubmitForm = (data, alias) => {
        this.state.fields.region = this.state.fields.titleRegion;
        this.setState({ fields: { ...this.state.fields, ...data }, alias }, () => {
            let formData = new FormData();
            for (let attr in this.state.fields) {
                formData.append(attr, this.state.fields[attr]);
            }
            this.props.submitForm(formData, alias, this.state.target);
        });
        this.props.cleareMnpFormEsiaAction();
        this.props.clearData();
        this.setState({
            toggleForm: 0
        });
    };

    onCheckPhone = (data) => {
        this.setState({
            fields: { ...this.state.fields, ...data },
            scrollToElem: null
        }, () => {
            this.props.checkOperator(data);
        })
    };

    // записываем в стор значение выбранной рпадиокнопки в форме StartMoveChange
    onRadioChange = (value) => {
        this.props.checkRadioValue(value)
    }

    goBackToForm = (e) => {
        e.preventDefault();
        this.context.setHeaderClassName("page__header-holder page__header-holder_float page__header-holder_white");
        this.props.clearData();
        this.props.cleareMnpFormEsiaAction();
        this.setState({
            step: 0,
            fields: {
                operator: "",
                region: "",
                phone: ""
            },
            toggleForm: 0,
            isFinalStepComplete: null,
            scrollToElem: 'start-move-form'
        });
    };

    render() {
        let {
            infoBlocks,
            faq,
            forms,
            submitForm,
            addressAutoComplete,
            tariffs,
            shops,
            getShopsList,
            getClosestShopsList,
            activation,
            regions,
            location,
        } = this.props;

        if (typeof document !== 'undefined') {
            deleteCookie('mnpData');
        }

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'mnpRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        switch (this.state.step) {
            case 1:
                this.context.setHeaderClassName("page__header-holder");
                //не определен оператор

                //уже абонент мотив
                if (typeof this.props.activation.mnp.operatorCode !== 'undefined' && this.props.activation.mnp.operatorCode === this.props.activation.mnp.ownOperatorCode) {
                    return <main className="page__main-holder">
                        <Helmet
                            title={currentMetaTags ? currentMetaTags.meta_title : ""}
                            meta={[
                                {
                                    "name": "description",
                                    "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                                },
                                {
                                    "name": "keywords",
                                    "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                                }
                            ]}>
                            <link rel="canonical" href={pageLink} />
                        </Helmet>
                        {/* <Helmet title="Смена оператора с сохранением номера | МОТИВ">
                            <link rel="canonical" href={pageLink} />
                        </Helmet> */}
                        <div className="page__container">
                            <MnpForm alias="already_in_motive"
                                forms={forms}
                                isGuest={this.props.isGuest}
                            />
                        </div>
                    </main>
                }

                //ошибка при проверке региона
                let regionsIds = this.context.regions.map((item) => item.id);
                if (typeof this.props.activation.mnp.regionId !== 'undefined' && regionsIds.indexOf(this.props.activation.mnp.regionId) == -1) {
                    return <main className="page__main-holder">
                        <Helmet
                            title={currentMetaTags ? currentMetaTags.meta_title : ""}
                            meta={[
                                {
                                    "name": "description",
                                    "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                                },
                                {
                                    "name": "keywords",
                                    "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                                }
                            ]}>
                            <link rel="canonical" href={pageLink} />
                        </Helmet>
                        {/* <Helmet title="Смена оператора с сохранением номера | МОТИВ">
                            <link rel="canonical" href={pageLink} />
                        </Helmet> */}
                        <div className="page__container">
                            <MnpForm alias="wrong_region"
                                forms={forms}
                                isGuest={this.props.isGuest}
                            />
                        </div>
                    </main>
                }

                return <main className="page__main-holder">
                    <Helmet
                        title={currentMetaTags ? currentMetaTags.meta_title : ""}
                        meta={[
                            {
                                "name": "description",
                                "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                            },
                            {
                                "name": "keywords",
                                "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                            }
                        ]}>
                        <link rel="canonical" href={pageLink} />
                    </Helmet>
                    {/* <Helmet title="Смена оператора с сохранением номера | МОТИВ">
                        <link rel="canonical" href={pageLink} />
                    </Helmet> */}
                    <div className="page__container">
                        {this.renderBreadcrumbs()}
                        <MnpForm
                            alias="final_form_v2"
                            forms={forms}
                            onSubmit={this.onSubmitForm}
                            goBack={this.goBackToForm}
                            additionalProps={{
                                phone: this.state.fields.phone,
                                regionId: this.props.activation.mnp.regionId,
                                addresses: forms.addresses,
                                addressAutoComplete
                            }}
                            isFinalStepComplete={this.state.isFinalStepComplete}
                            clearCity={this.clearCity}
                            clearAddress={this.clearAddress}
                            clearStreet={this.clearStreet}
                            clearHouse={this.clearHouse}
                            radioValue={this.props.activation.raduoVal}
                            isGuest={this.props.isGuest}
                        >
                            {this.renderNumberInfo()}
                        </MnpForm>
                    </div>
                </main>;
            case 2:
                this.context.setHeaderClassName("page__header-holder");
                return <main className="page__main-holder page_mnp_step-form" role="main">
                    <div className="page__container">
                        {this.renderBreadcrumbs()}
                        <MnpForm alias="number_agreements"
                            forms={forms}
                            onSubmit={this.onSubmitForm}
                            activation={activation}
                            isGuest={this.props.isGuest}
                        >
                            {this.renderNumberInfo()}
                        </MnpForm>
                    </div>
                </main>;
            case 3:
                this.context.setHeaderClassName("page__header-holder");
                return this.state.fields.getting_type_alias === "delivery"
                    ? <main className="page__main-holder page_mnp_step-form" role="main">
                        <div className="page__container">
                            {this.renderBreadcrumbs()}
                            <div className="page__step-title">Оформление доставки</div>
                            {this.renderTariffConstructorInfo()}
                            <RequiredText />
                            <MnpForm alias="delivery_address"
                                forms={forms}
                                onSubmit={this.onSubmitForm}
                                additionalProps={{ addresses: forms.addresses, addressAutoComplete }}
                                clearCity={this.clearCity}
                                clearAddress={this.clearAddress}
                                clearStreet={this.clearStreet}
                                clearHouse={this.clearHouse}
                                isGuest={this.props.isGuest}
                            />
                        </div>
                    </main>
                    : <main className="page__main-holder">
                        <Helmet
                            htmlAttributes={{ "class": "page_mnp-map" }}
                            title={currentMetaTags ? currentMetaTags.meta_title : ""}
                            meta={[
                                {
                                    "name": "description",
                                    "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                                },
                                {
                                    "name": "keywords",
                                    "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                                }
                            ]}>
                            <link rel="canonical" href={pageLink} />
                        </Helmet>
                        {/* <Helmet htmlAttributes={{"class": "page_mnp-map"}}>
                            <link rel="canonical" href={pageLink} />
                        </Helmet> */}
                        <div className="page__container">
                            <div className="page_sim-get-options">
                                <div className="page__about-title">
                                    {this.renderBreadcrumbs()}
                                    <div className="page__title">
                                        Выберите салон связи
                                        <div className="page__map-back" onClick={() => {
                                            this.goToStep(2);
                                        }}>
                                            <Icon name={"icon_left-arrow"}
                                                className={"page__map-back-icon u-icon u-icon_left-arrow"} />
                                            Вернуться назад</div>
                                        {this.renderTariffConstructorInfo()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OfficeMap items={shops.list}
                            onSubmit={this.onSubmitForm}
                            address={forms.addressFull || []}
                            addressAutoComplete={addressAutoComplete}
                            getClosestShopsList={getClosestShopsList}
                            getShopsList={getShopsList}
                            inProgress={forms.inProgress}
                            typeMap="mnp"
                        />
                    </main>;
            case 4:
                this.context.setHeaderClassName("page__header-holder");
                return <main className="page__main-holder page_mnp-get-sim" role="main">
                    <div className="page__container">
                        {this.renderBreadcrumbs()}
                        <div className="page__title">Оформление заказа</div>
                        {this.renderShopInfo()}
                        <MnpForm alias="continue_way"
                            forms={forms}
                            onSubmit={this.onSubmitForm}
                            isFinalStepComplete={this.state.isFinalStepComplete}
                            isGuest={this.props.isGuest}
                        />
                    </div>
                </main>;
            case 5:
                this.context.setHeaderClassName("page__header-holder page__header-holder_float");
                return <main className="page__main-holder page_mnp_tariff" role="main">
                    <MnpForm alias="tariff_selection"
                        forms={forms}
                        onSubmit={this.onSubmitForm}
                        additionalProps={{ tariffs: tariffs.tariffsLists }}
                        typeDelivery={this.state.fields.getting_type_alias}
                        isFinalStepComplete={this.state.isFinalStepComplete}
                        isGuest={this.props.isGuest}
                    />
                </main>;
            case 6:
                this.context.setHeaderClassName("page__header-holder");
                return <main className="page__main-holder page_mnp_tariff" role="main">
                    <div className="page__container">
                        {this.renderBreadcrumbs()}
                        {this.renderTariffConstructorInfo()}
                        <RequiredText />
                        <MnpForm alias="personal_info"
                            clearCity={this.clearCity}
                            clearAddress={this.clearAddress}
                            clearStreet={this.clearStreet}
                            clearHouse={this.clearHouse}
                            forms={forms}
                            onSubmit={this.onSubmitForm}
                            additionalProps={{ addresses: forms.addresses, addressAutoComplete }}
                            isFinalStepComplete={this.state.isFinalStepComplete}
                            fields={this.state.fields}
                            isGuest={this.props.isGuest}
                        />
                    </div>
                </main>;
            default:
                return (
                    <main className="page__main-holder page_mnp-index" role="main">
                        <Helmet
                            title={currentMetaTags ? currentMetaTags.meta_title : ""}
                            meta={[
                                {
                                    "name": "description",
                                    "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                                },
                                {
                                    "name": "keywords",
                                    "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                                }
                            ]}>
                            <link rel="canonical" href={pageLink} />
                        </Helmet>

                        <div>
                            <InfoBlock section="mnp_main_banner" subsection="mnp_main_banner_icon_text"
                                type="mnp_main_banner" data={infoBlocks} isGuest={this.props.isGuest} />
                        </div>
                        <div className="page__floating-btn-container js-floating-btn-container">
                            <div className="page__container">
                                {faq ? <RatesFaq items={faq.filter(el => el.category && el.category.id === 1)}
                                    title={"Популярные вопросы"} /> : null}
                                <MnpForm
                                    alias="start_move_form"
                                    forms={forms}
                                    activation={activation}
                                    onSubmit={this.onCheckPhone}
                                    onToggleForm={this.onToggleForm}
                                    validPhoneNumber={this.state.validPhoneNumber}
                                    onRadioChange={this.onRadioChange}
                                    isGuest={this.props.isGuest}
                                    mnpFormEsiaAction={this.props.mnpFormEsiaAction}
                                />
                                {/*<div className="page__h3 page__advantages-number">Переход в МОТИВ со своим номером – это*/}
                                {/*    просто!*/}
                                {/*</div>*/}
                                {/*<InfoBlock type="text_with_icon" section="text_with_icon" data={infoBlocks}/>*/}
                                {/*<MnpForm alias={"callback_form"}*/}
                                {/*forms={forms}*/}
                                {/*onSubmit={submitForm}*/}
                                {/*validPhoneNumber={this.state.validPhoneNumber}*/}
                                {/*/>*/}
                                <CommonCallbackForm target="MNP" />
                                {forms.isSubmitOk === "callback_form"
                                    ? <MnpForm alias={"order_sent_success"}
                                        forms={forms}
                                        onSubmit={submitForm}
                                        isGuest={this.props.isGuest}
                                    />
                                    : ""
                                }
                            </div>
                        </div>
                    </main>
                );
        }
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        infoBlocks: state.infoBlocks ? state.infoBlocks.list : [],
        faq: state.faq && state.faq.list ? state.faq.list : [],
        forms: state.forms,
        shops: state.shops,
        activation: state.activation,
        isGuest: state.client.isGuest,
        regions: state.regions,

        routes: state.routes,
        currentStep: state.step.currentStep
    }),
    (dispatch) => {
        return {
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getTariffs: (regionId, categoryRankField, tariffRankField) => dispatch(tariffsList(regionId, categoryRankField, tariffRankField)),
            getFaq: (regionId, categoryId) => dispatch(faqList(regionId, categoryId)),
            getForms: (group) => dispatch(formsList(group)),
            getShopsList: (regionId) => dispatch(shopsList(regionId)),
            getClosestShopsList: (regionId, lat, lon, region, city, street) => dispatch(closestShopsList(regionId, lat, lon, region, city, street)),
            submitForm: (formData, alias, target) => dispatch(submitMnpForm(formData, alias, target)),
            checkOperator: (formData) => dispatch(checkOperator(formData)),
            checkRadioValue: (value) => dispatch(checkRadioValue(value)),
            addressAutoComplete: (type, query, params) => dispatch(addressAutoComplete(type, query, params)),
            clearData: () => dispatch(clearActivationData()),
            clearAutoCompleteCity: () => dispatch(clearCity()),
            clearAutoCompleteAddress: () => dispatch(clearAddress()),
            clearAutoCompleteStreet: () => dispatch(clearStreet()),
            clearAutoCompleteHouse: () => dispatch(clearHouse()),

            mnpFormEsiaAction: (data) => dispatch(mnpFormEsiaAction(data)),
            cleareMnpFormEsiaAction: () => dispatch(cleareMnpFormEsiaAction()),
            setCurrentStep: (step) => dispatch(setCurrentStep(step))
        }
    }
)(RatesMnp);

export const RatesMnpRequests = [
    {
        func: 'getInfoBlocks',
        args: ['mnp_page'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            { type: "mnp_main_banner" }
        ]
    },
    {
        func: 'getForms',
        args: ["mnp"],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getFaq',
        args: [1],
        object: 'faq.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
    {
        func: 'getTariffs',
        args: [],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getShopsList',
        args: [],
        object: 'shops.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },

];