import {Component} from "react";

import React from "react";

class IconInst extends Component {

    render() {
        return (
            <div className="icon_inst_tariff"></div>
        )
    }
}
export default IconInst;