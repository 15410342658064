import React from "react";
import LinkC from "../common/LinkC";
import Icon from "../common/Icon";
import GoToMotiveForm from "../Rates/GoToMotiveForm";

import {mediaUrl} from "../../tools/config";
import InfoblockLink from "./InfoblockLink";
import AdvertisingLabeling from "../AdvertisingLabeling/AdvertisingLabeling";

export default function InfoBlockGoToMotive({ blocks, onSubmit, onCheckboxChange, onPhoneChange, onRadioChange, phone, phoneError, agreement, agreementError, activation, formSettings = {}, operatorCheck, mnpBonuses, inProgress}){

    let block = blocks.length ? blocks[0] : null;

    let ind = block.title.indexOf('<br>');
    
    return block ? <div className="b-come-offer__wrapper" id="go-to-motive">
        <AdvertisingLabeling
            token={block && block.token}
            advertiser={block && block.advertiser}
        />
        <div className="b-come-offer__content" style={{backgroundImage: 'url('+mediaUrl+block.imagePath+')'}}>
            <div className="b-come-offer__container page__container">
                <div className="b-come-offer__text-section">
                    <div className="b-come-offer__text-main">
                        <div className="b-come-offer__title">{ind !== -1 ? block.title.substr(0,block.title.indexOf('<br>')) : block.title}</div>
                        <div className="b-come-offer__subtitle">{ind !== -1 ? block.title.substr(block.title.indexOf('<br>') + 4) : null}</div>
                    </div>
                    <InfoblockLink className={"b-come-offer__learn-more"} item={block}>
                        {block.link_title}
                        <span className="b-come-offer__link-icon-area">
                            <Icon name="icon_arrow-left-tr" className="b-come-offer__link-icon u-icon" />
                        </span>
                    </InfoblockLink>
                </div>
            </div>
        </div>
        <div className="b-come-offer__form-wraper">
            <div className="b-come-offer__inner-container page__container">
                <div className="b-come-offer__form-section">
                    <div className="b-come-offer__form-container">
                        <div className="b-form-transfer b-form-transfer_adapt_default">
                            <div className="b-form-transfer__wrapper">
                                <div className="b-form-transfer__subtitle">{formSettings.start_move_form_title}</div>
                                <GoToMotiveForm 
                                    operatorCheck={operatorCheck} 
                                    activation={activation} 
                                    onSubmit={onSubmit} 
                                    onCheckboxChange={onCheckboxChange} 
                                    onPhoneChange={onPhoneChange} 
                                    onRadioChange={onRadioChange} 
                                    phone={phone} 
                                    phoneError={phoneError} 
                                    agreement={agreement} 
                                    agreementError={agreementError} 
                                    agreementText={formSettings.start_move_form_agreement} 
                                    commentText={formSettings.start_move_form_comment}
                                    formSettings={formSettings}
                                    mnpBonuses={mnpBonuses}
                                    inProgress={inProgress}
                                >
                                    <div className="b-form-transfer__link-block">
                                        <InfoblockLink className={"b-form-transfer__link"} item={block}/>
                                    </div>
                                </GoToMotiveForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : null;
}
