import React, {Component} from 'react';
import Select from "../common/Select";

class RatesServicesFilter extends Component {

    render() {

        let items = this.props.cats;
        let slug = this.props.slug;
        let className = 'b-services-list__tags-filter promotions__tags_filter'+(this.props.className ? ' '+this.props.className : '')

        let selectItems = [];
        if (items.length > 0) {
            selectItems = items.map((item) => {
                return {
                    label: item.title,
                    value: item.slug
                }
            });
        }

        return (
            <div className={className}>
                <div className="page__container js-arrow-switcher b-tags-filter b-tags-filter_adapt_service-list b-tags-filter_theme_service-list promotions__tags_filter_adapt">
                    <div className="b-tags-filter__wrapper">
                        <div className="b-tags-filter__list" >
                            {items.map((item, index) =>
                                <a
                                    key={index}
                                    href="#"
                                    onClick={(e) => {e.preventDefault(); this.props.toggleCat(item.slug)}}
                                    className={"b-tags-filter__item" + (item.slug === slug ? " b-tags-filter__item_active" : "")}>{item.title}</a>
                            )}
                        </div>
                        {selectItems.length > 1 &&
                            <Select 
                                className={"b-tags-filter__selection"} 
                                hasError={false} 
                                options={selectItems} 
                                attribute={"tariff"} 
                                onToggle={this.props.toggleCat} 
                                value={slug} 
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesServicesFilter;