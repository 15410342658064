import React, { Component } from 'react';
import { mediaUrl } from '../../tools/config';
import RatesSlider from '../../components/Rates/RatesSlider';
import BigCarousel from "../Rates/BigCarousel";
import RatesThreeQuartersBanner from "../Rates/RatesThreeQuartersBanner";
import RatesOneQuarterBanner from "../Rates/RatesOneQuarterBanner";
import RatesInternetTextBanner from "../Rates/RatesInternetTextBanner";
import RatesBigBanner from "../Rates/RatesBigBanner";
import InfoBlockTitleLink from "../InfoBlocks/InfoBlockTitleLink";
import InfoBlockProductCard from "../InfoBlocks/InfoBlockProductCard";
import InfoBlockRoamingBigBanner from "../InfoBlocks/InfoBlockRoamingBigBanner";
import InfoBlockText from "../InfoBlocks/InfoBlockText";
import InfoBlockFullScreenCarousel from "../InfoBlocks/InfoBlockFullScreenCarousel";
import InfoBlockLinkWithIcon from "../InfoBlocks/InfoBlockLinkWithIcon";
import InfoBlockGoToMotive from "../InfoBlocks/InfoBlockGoToMotive";
import InfoBlockShop from "../InfoBlocks/InfoBlockShop";
import InfoBlockAccentBanner from "../InfoBlocks/InfoBlockAccentBanner";
import InfoBlockHomeBigBanner from "../InfoBlocks/InfoBlockHomeBigBanner";
import InfoBlockHomeBigBannerWithMedia from "../InfoBlocks/InfoBlockHomeBigBannerWithMedia";
import InfoBlockFullScreenBanner from "../InfoBlocks/InfoBlockFullScreenBanner";
import InfoBlockAdvantagesPlates from "../InfoBlocks/InfoBlockAdvantagesPlates";
import InfoBlockBanner from "../InfoBlocks/InfoBlockBanner";
import PropTypes from "prop-types";
import InfoblockLink from "../InfoBlocks/InfoblockLink";
import {
    BottomSupportInfoBlock,
    RightSupportInfoBlock,
    RightSupportSlidesInfoBlock,
    RightSupportMessengerInfoBlock
} from "../InfoBlocks/InfoBlockSupport";
import InfoBlockAdvertService from "../InfoBlocks/InfoBlockAdvertService";
import InfoBlockCareerVideo from "../InfoBlocks/InfoBlockCareerVideo";
import SendResumeBlock from "../Vacancies/SendResumeBlock";
import { SuccessVacancyModalForm } from "../../components/modals/VacancyModal";
import DescriptionBlock from "../../components/Vacancies/DescriptionBlock";
import VacancySocial from "../../components/Vacancies/VacancySocial";
import CarouselAboutCompany from "../PressCenter/CarouselAboutCompany";
import CarouselAboutCompanyVideo from "../PressCenter/CarouselAboutCompanyVideo";
import MediaMaterialsLink from "../PressCenter/MediaMaterialsLink";
import PressCenterSocial from "../PressCenter/PressCenterSocial";
import CarouselAbout from '../../../js/components/InfoPages/CarouselAbout';
import LightBannerAbout from '../../../js/components/InfoPages/LightBannerAbout';
import VideoFrameAbout from '../../../js/components/InfoPages/VideoFrameAbout';
import InfoBlockBecomeClient from "../InfoBlocks/InfoBlockBecomeClient";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

import ListOfServicesMainInfoblock from "../PaymentsTransfers/ListOfServicesMainInfoblock";
import ListOfServicesGroupInfoblocks from "../PaymentsTransfers/ListOfServicesGroupInfoblocks";
import CarouselPromotions from '../Promotions/CarouselPromotions';

import LinkC from "../../../js/components/common/LinkC";

export default class InfoBlock extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
        b2bModalToggle: PropTypes.func,
        regions: PropTypes.array
    };

    render() {
        let dataLength = this.props.data && this.props.data.length;
        if (dataLength === 0) {
            return (<div className="b-support-no-answer__list" />);
        }

        let blocks = this.props.data && this.props.data.length ? this.props.data.filter(x => {
            let result = x.type.alias === this.props.section || x.alias === this.props.section && x.regions.filter(region => region.id === this.context.selectedRegion.id).length;

            if (this.props.group) {
                result = result && x.groups.filter(group => group.alias === this.props.group).length;
            }
            return result;
        }) : [];

        let subblocks = this.props.subsection && this.props.data && this.props.data.length ? this.props.data.filter(x => x.type.alias === this.props.subsection) : [];

        let children = this.props.children;

        if (blocks.length === 0 && this.props.type !== "about_vacancy_list_banner"
            && this.props.type !== "about_career_video" && this.props.type !== "about_send_cv"
            && this.props.type !== "about_modal_success" && this.props.type !== "about_career_text_block" && this.props.type !== "about_social_fb"
            && this.props.type !== "about_social_vk" && this.props.type !== "about_social_ok" && this.props.type !== "about_social") {
            return (<div className="b-support-no-answer__list" />);
        }

        if (blocks.length > 1) {
            blocks.sort(function (a, b) {
                if (a.weight > b.weight) {
                    return -1;
                }
                if (a.weight < b.weight) {
                    return 1;
                }
                return 0;
            });
        }
        switch (this.props.type) {
            case 'banner': return (
                <div className="b-support-no-answer__list">
                    {blocks.map((item, index) =>
                        <div className="b-support-no-answer__col flex-col-4" key={index}>
                            <InfoblockLink className={"b-support-no-answer__item"} item={item}>
                                <span className="b-support-no-answer__text">{item.title}</span>
                                <img className="b-support-no-answer__icon u-icon u-icon_no-answer" src={mediaUrl + item.imagePath} alt={item.title} />
                            </InfoblockLink>
                        </div>
                    )}
                </div>
            );
            case 'before-slider': return (
                <div className="rates-slider__title-area">
                    <h3 className="rates-slider__title">{blocks[0].title}</h3>
                    <InfoblockLink className={"rates-slider__title-link"} item={blocks[0]} />
                </div>
            );

            case 'slider': return (
                <RatesSlider blocks={blocks} />
            );
            case 'big-carousel': return (
                <BigCarousel blocks={blocks} />
            );
            case 'three-quarters-banner': return (
                <RatesThreeQuartersBanner blocks={blocks} />
            );
            case 'one-quarter-banner': return (
                <RatesOneQuarterBanner blocks={blocks} />
            );
            case 'internet-text-banner': return (
                <RatesInternetTextBanner blocks={blocks} />
            );
            case 'internet-small-banner': return (
                <div className="b-simple-advantages__adv-block">
                    <div className="b-simple-advantages__adv-inner">
                        {blocks.map((block, index) =>
                            <div className="b-simple-advantages__adv-item" key={index}>
                                <img src={mediaUrl + block.imagePath} alt="img" />
                                <div className="b-simple-advantages__adv-text">
                                    {block.title}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
            case 'internet-big-banner': return (
                <RatesBigBanner blocks={blocks} />
            );
            case 'title-link': return (
                <InfoBlockTitleLink blocks={blocks} />
            );
            case 'product-card': return (
                <InfoBlockProductCard blocks={blocks} />
            );
            case 'roaming-big-banner': return (
                <InfoBlockRoamingBigBanner blocks={blocks} />
            );
            case 'roaming-text-block-before-footer':
                return (
                    <InfoBlockText blocks={blocks} />
                );
            case 'full_screen_carousel':
                return (
                    <InfoBlockFullScreenCarousel blocks={blocks} />
                );
            case 'link_with_icon':
                return (
                    <InfoBlockLinkWithIcon blocks={blocks} />
                );
            case 'full_screen_go_to_motive':
                return (
                    <InfoBlockGoToMotive
                        blocks={blocks}
                        onSubmit={this.props.onSubmit}
                        onCheckboxChange={this.props.onCheckboxChange}
                        onPhoneChange={this.props.onPhoneChange}
                        onRadioChange={this.props.onRadioChange}
                        phone={this.props.phone}
                        phoneError={this.props.phoneError}
                        agreement={this.props.agreement}
                        agreementError={this.props.agreementError}
                        activation={this.props.activation}
                        formSettings={this.props.formSettings}
                        operatorCheck={this.props.operatorCheck}
                        mnpBonuses={this.props.mnpBonuses}
                        inProgress={this.props.inProgress}
                    />
                );
            case 'online_shop':
                return (
                    <InfoBlockShop blocks={blocks} />
                );
            case 'accent_banner':
                return (
                    <div className="infoblock_accent_banner__wrap">
                        <div className="infoblock_accent_banner__wrap_advertising_labeling">
                            <AdvertisingLabeling
                                token={blocks[0] && blocks[0].token}
                                advertiser={blocks[0] && blocks[0].advertiser}
                            />
                        </div>
                        <InfoBlockAccentBanner blocks={blocks} />
                    </div>
                );
            case 'home_page_banner':
                return (
                    <InfoBlockHomeBigBanner blocks={blocks} />
                );
            case 'home_page_media_banner':
                return (
                    <InfoBlockHomeBigBannerWithMedia blocks={blocks} />
                );

            case 'mnp_main_banner':
                return (
                    <InfoBlockFullScreenBanner blocks={blocks} subblocks={subblocks} isGuest={this.props.isGuest} />
                );
            case 'text_with_icon':
                return (
                    <InfoBlockAdvantagesPlates blocks={blocks} />
                );
            case 'text':
                return (
                    <React.Fragment>
                        {blocks[0].text}
                    </React.Fragment>
                );
            case 'support_bottom_banners':
                return (
                    <BottomSupportInfoBlock blocks={blocks} />
                );
            case 'support_right_banners':
                return (
                    <RightSupportInfoBlock blocks={blocks} />
                );
            case 'support_right_messenger':
                return (
                    <RightSupportMessengerInfoBlock blocks={blocks} subblocks={subblocks} {...this.props.additionalProps} />
                );
            case 'advert_service_block':
                return <InfoBlockAdvertService blocks={blocks} {...this.props.additionalProps} myRegion={this.context.selectedRegion.id} />;
            case 'about_vacancy_list_banner':
                return <InfoBlockBanner dataBanner={blocks} />;
            case 'about_send_cv':
                return <SendResumeBlock dataSend={blocks} openModal={this.props.openModal} />;
            case 'about_modal_success':
                return <SuccessVacancyModalForm dataSuccesModal={blocks} close={this.props.close} />;
            case 'about_career_video':
                return <InfoBlockCareerVideo dataCareerVideo={blocks} />;
            case 'about_career_text_block':
                return <DescriptionBlock dataDescription={blocks} />;
            case 'about_social_fb':
                return <VacancySocial dataVacancySocial={blocks} bgColor={this.props.bgColor} />;
            case 'about_social_vk':
                return <VacancySocial dataVacancySocial={blocks} bgColor={this.props.bgColor} />;
            case 'about_social_ok':
                return <VacancySocial dataVacancySocial={blocks} bgColor={this.props.bgColor} />;
            case 'about_social':
                return <VacancySocial dataVacancySocial={blocks} bgColor={this.props.bgColor} />;
            case 'about_press_center_carousel':
                return <CarouselAboutCompany blocks={blocks} title={this.props.title} />;
            case 'about_press_center_carousel_video':
                return <CarouselAboutCompanyVideo blocks={blocks} linkMediaBank={this.props.linkMediaBank} />;
            case 'media_materials_link':
                return <MediaMaterialsLink blocks={blocks} />;
            case 'press-center-social':
                return <PressCenterSocial data={blocks} />;
            case 'company_slider':
                return <CarouselAbout blocks={blocks} />;
            case 'company_image':
                return <LightBannerAbout data={blocks} />;
            case 'company_video':
                return <VideoFrameAbout data={blocks} />;
            case 'become_client_b2b':
                return <InfoBlockBecomeClient data={blocks} onClick={this.context.b2bModalToggle} />;

            case 'list_of_services_main_img':
                return (
                    <React.Fragment>
                        {blocks.map(item =>
                            <div className="list-of-services__img-wrapper">
                                <AdvertisingLabeling
                                    token={item && item.token}
                                    advertiser={item && item.advertiser}
                                />
                                <img src={mediaUrl + item.imagePath} alt="" />
                            </div>
                        )}
                    </React.Fragment>
                )
            case 'list_of_services_main':
                return (
                    <React.Fragment>
                        {blocks.map(item =>
                            <div className="list-of-services__infoblocks">
                                <h3>{item.title}</h3>
                                <span>{item.text}</span>
                                <InfoblockLink className={"list-of-services__all-sevices"} item={item}>
                                    {item.link_title}
                                </InfoblockLink>
                            </div>
                        )}
                    </React.Fragment>
                )
            // return <ListOfServicesMainInfoblock data={blocks}/>;
            case 'list_of_services_group':
                return <ListOfServicesGroupInfoblocks data={blocks} />;

            case 'motiv_pay_banner':
                return (
                    <React.Fragment>
                        {blocks.map(item =>
                            <div className="motiv-pay__img-wrapper">
                                <AdvertisingLabeling
                                    token={item && item.token}
                                    advertiser={item && item.advertiser}
                                />
                                <img src={mediaUrl + item.imagePath} alt="" />
                            </div>
                        )}
                    </React.Fragment>
                );

            case 'payments-page_banner':
                return (
                    <React.Fragment>
                        {blocks.map(item =>
                            <div className="payments-page__banner_wrapper">
                                <AdvertisingLabeling
                                    token={item && item.token}
                                    advertiser={item && item.advertiser}
                                />
                                <img src={mediaUrl + item.imagePath} alt="" />

                                <div className="banner_wrapper_text">
                                    <div>
                                        <h2 className="banner_wrapper_text_title">{item.title}</h2>
                                        <div className="banner_wrapper_text_subtitle">{item.text}</div>
                                    </div>
                                    <LinkC to={item.link} className="banner_wrapper_text_btn">{item.link_title}</LinkC>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                );

            case 'promotions_slider':
                return <CarouselPromotions blocks={blocks} />;
            case 'b2b_lk':
                return (
                    <a href="https://lk-business.motivtelecom.ru/" className="right-side-b2b-lk">
                        <span>Личный кабинет</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.28217 7.53217C8.57506 7.23928 9.04994 7.23928 9.34283 7.53217L13.2803 11.4697C13.5732 11.7626 13.5732 12.2374 13.2803 12.5303L9.34283 16.4678C9.04994 16.7607 8.57506 16.7607 8.28217 16.4678C7.98928 16.1749 7.98928 15.7001 8.28217 15.4072L11.6893 12L8.28217 8.59283C7.98928 8.29994 7.98928 7.82506 8.28217 7.53217Z"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd" d="M1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H12.75C13.1642 11.25 13.5 11.5858 13.5 12C13.5 12.4142 13.1642 12.75 12.75 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12Z"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 3.75C12 3.33579 12.3358 3 12.75 3H18C18.3978 3 18.7794 3.15804 19.0607 3.43934C19.342 3.72065 19.5 4.10218 19.5 4.5V19.5C19.5 19.8978 19.342 20.2794 19.0607 20.5607C18.7794 20.842 18.3978 21 18 21H12.75C12.3358 21 12 20.6642 12 20.25C12 19.8358 12.3358 19.5 12.75 19.5H18V4.5L12.75 4.5C12.3358 4.5 12 4.16421 12 3.75Z"
                            />
                        </svg>
                    </a>
                );

            default: console.warn('Wrong type of banner');
        }
    }
}
