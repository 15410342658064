import React, {Component} from "react";

export default class Checkbox extends Component{

    constructor(props){
        super(props);

        this.state = {
            checked: props.value || false
        }
    }

    componentWillReceiveProps(nextProps){
        if (this.state.checked !== nextProps.value) {
            this.setState({checked: nextProps.value});
        }
    }

    onChange = () => {
        this.setState((prevState) => {
            return {
                checked: !prevState.checked
            }
        }, () => {
            this.props.onChange(this.props.name, this.state.checked);
        });
    };

    render(){

        let {
            name,
            label,
            children,
            checkboxClick = ()=>{},
            disabled,
            disabledRqs = false
        } = this.props;

        let checked = this.state.checked;

        return <div className={"checkbox-block"+(checked ? " checked" : "")+(disabled ? " disabled" : "")}>
            <label htmlFor={name} onClick={checkboxClick}>
                <input
                    id={name}
                    className="checkbox-input"
                    type="checkbox"
                    {...checked ? {checked: "checked"} : {}}
                    onChange={this.onChange}
                    disabled={disabled || disabledRqs}
                />
                {label}
                {children}
            </label>
        </div>
    }
}
