import {parseErrors} from "../tools/api-helper";

let initState = {
    promotions: [],
    promotionsArchive: [],
    detailPage: {},
    detailPageArchive: {},
    category: [],
    isNotFound: false,
};

export function promotions(state = initState, action) {
    switch (action.type) {
        case "PROMOTIONS_PAGE_SUCCESS":
            return {...state, promotions: action.data};
        case "PROMOTIONS_PAGE_NEW_SUCCESS":
            return {...state, promotions: [...state.promotions, ...action.data]};
        case "PROMOTIONS_PAGE_PAGINATION":
            return {...state, pagination: action.pagination};

        case "PROMOTIONS_CLEAR_PAGE":
            return {...state, promotions: [], promotionsArchive: []};

        case "PROMOTIONS_ARCHIVE_PAGE_SUCCESS":
            return {...state, promotionsArchive: action.data};
        case "PROMOTIONS_ARCHIVE_PAGE_NEW_SUCCESS":
            return {...state, promotionsArchive: [...state.promotionsArchive, ...action.data]};
        case "PROMOTIONS_ARCHIVE_PAGE_PAGINATION":
            return {...state, paginationArchive: action.pagination};

        case "PROMOTIONS_DETAIL_PAGE":
            return {...state, detailPage: {}, isNotFound: false};
        case "PROMOTIONS_DETAIL_PAGE_SUCCESS":
            return {...state, detailPage: action.data};
        case 'PROMOTIONS_DETAIL_PAGE_NOT_FOUND':
            return {...state, isNotFound: true};

        case "PROMOTIONS_DETAIL_ARCHIVE_PAGE":
            return {...state, detailPageArchive: {}, isNotFound: false};
        case "PROMOTIONS_DETAIL_ARCHIVE_PAGE_SUCCESS":
            return {...state, detailPageArchive: action.data};
        case "PROMOTIONS_DETAIL_ARCHIVE_PAGE_NOT_FOUND":
            return {...state, isNotFound: true};

        case "PROMOTIONS_CATEGORY_SUCCESS":
            return {...state, category: action.data};
    }
    return state;
}