import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

export function settingsList(names) {
    return {
        type: 'SETTINGS_LIST',
        names
    }
}

export function* fetchSettingsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/settings?names="+action.names, {
                method: 'GET'
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}