import React, { Component } from "react";
import Tooltip from "../common/Tooltip";
import Icon from "../common/Icon";
import Input from "../Constructor/Input";
import InputIcon from "../common/InputIcon";
import Radio from "../common/Radio";

import { esiaUrl, cookieDomain } from "../../tools/config";
import Modal from "../common/Modal";
import { v1 as uuidv } from 'uuid';
import { getCookie, setCookie, deleteCookie } from "../../tools/tools";

export default class GoToMotiveForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            phoneNotCorrect: false,
            notCorrectTxt: "Возможно, Ваш номер зарегистрирован в регионе, где нет присутствия оператора МОТИВ",
            arr: {
                name: "mainForm",
                type: "radio",
                value: "value_0",
                items: [],
            },
            valueBonus: "value_0",
            modal: '',
        }
        this.renderModal = this.renderModal.bind(this);
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            deleteCookie('checkboxValueCookie');
            deleteCookie('numberMnpCookie');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let mnp = this.props.activation && this.props.activation.mnp && this.props.activation.mnp;
        let prevMnp = prevProps.activation && prevProps.activation.mnp && prevProps.activation.mnp;

        if (mnp !== prevMnp && mnp.statusCode === 1001) {
            this.openModal('choose_an_option');
        }

        if (mnp !== prevMnp && mnp.StatusCode === 3003) {
            setTimeout(() => {
                this.setState({ phoneNotCorrect: true })
            }, 1500);
        }
        if (this.props.mnpBonuses !== prevProps.mnpBonuses) {
            this.setState({ arr: { ...this.state.arr, items: this.props.mnpBonuses.map((el, index) => { return { label: el.title, value: 'value_' + index, tooltip: el.tooltip } }) } })
        }
    }

    onChangeRadio = (name, value) => {
        this.setState({
            valueBonus: value,
            arr: {
                ...this.state.arr,
                value
            }
        });

        setCookie('checkboxValueCookie', value, {
            path: '/',
            expires: 60,
            domain: cookieDomain
        });
    };

    onPhoneChange = (e) => {
        if (!this.state.focus) {
            this.setState({ focus: true })
        }
        this.props.onPhoneChange(e);
        this.setState({ phoneNotCorrect: false })
    };

    onSubmit = (e) => {
        let findItem = this.state.arr.items.find((i) => i.value === this.state.valueBonus);

        this.setState({ focus: false });
        this.props.onSubmit(e);
        this.props.onRadioChange(findItem ? findItem.label : '');
    };

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.activation.isOk !== this.props.activation.isOk) {
    //         if (typeof nextProps.activation.mnp.StatusCode !== 'undefined' && nextProps.activation.mnp.StatusCode === 3003) {
    //             setTimeout(() => {
    //                 this.setState({phoneNotCorrect: true})
    //             }, 1500);
    //         }
    //     }
    // }

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        });
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({
            modal: '',
        });
    };

    renderModal() {
        let locationHref;
        if (typeof window !== 'undefined') {
            // locationHref = `${window.location.origin}${window.location.pathname === '/' ? '/sverdlovsk_oblast/' : window.location.pathname}${window.location.search !== '' ? window.location.search : ''}`;
            locationHref = `${window.location.origin}${window.location.pathname === '/' ? '/sverdlovsk_oblast/' : window.location.pathname}`;
        }

        let path = `${esiaUrl}blitz/bridge/entrance`;

        let uuidNum = uuidv();
        let esiaUrlLink = `${path}?redirect_url=${locationHref}mnp&state=${uuidNum}`

        switch (this.state.modal) {
            case "choose_an_option":
                return <Modal className="u-modal u-modal_active choose_modal" close={this.closeModal}>
                    <div className="content">
                        <h2 className="modal_title">Для продолжения необходимо предоставить паспортные данные</h2>
                        <div className="modal_btns">
                            <a
                                href={esiaUrlLink}
                                className="modal_btns_links modal_btns__portal"
                            >
                                <img src={`${require('../../../images/icons/icon_portal.svg')}`} alt="" />
                                <div className="modal_btns_text">
                                    Через госуслуги
                                </div>
                            </a>
                            <button
                                className="modal_btns_links modal_btns__manually"
                                type="submit"
                                onClick={(e) => {
                                    this.onSubmit(e);
                                }}
                            >
                                <img src={`${require('../../../images/icons/icon_manually.svg')}`} alt="" />
                                <div className="modal_btns_text">
                                    Ввести вручную <br />
                                    <span>Заполнить данные самостоятельно</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal>
        }
    }

    render() {

        let {
            phone,
            phoneError,
            agreement,
            agreementError,
            agreementText,
            commentText,
            inProgress,
        } = this.props;

        let { notCorrectTxt, phoneNotCorrect } = this.state;
        // var isDisabled =  this.props.activation.inProgress || this.props.activation.isOk;
        var isDisabled = false;

        return (
            // <form onSubmit={this.onSubmit}>
            <form>
                <div className="b-form-transfer__form">
                    <div className="b-form-transfer__inputs-sector">
                        <div className="b-form-transfer__input-area">
                            <div className="b-input-text">
                                <div className="b-input-text__wrapper">
                                    <Radio
                                        className={"b-come-offer__main_form mnp-bonuses-radios"}
                                        name={this.state.arr.name}
                                        items={this.state.arr.items}
                                        value={this.state.arr.value}
                                        onChange={this.onChangeRadio}
                                        withTooltips={true}
                                    />

                                    <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                                        <div className="b-input-text__label-text">Введите номер для переноса</div>
                                    </div>
                                    <div className="b-input-text__input-area">
                                        <input className={"b-input-text__input" + (phoneError && !this.state.focus ? " has-error" : "")}
                                            type="text" placeholder="+7"
                                            value={phone}
                                            onChange={this.onPhoneChange}
                                            onBlur={(e) => { this.setState({ focus: false }); this.props.onPhoneChange(e) }}
                                            onFocus={() => { this.setState({ focus: true }) }}
                                            disabled={isDisabled}
                                        />
                                        {commentText ? <Tooltip id={"start_move_form_comment"} showOnClick={true} className={"b-input-text__tooltip " + (phoneNotCorrect ? " b-input-text__tooltip--correct" : "")} label={<Icon name="icon_notification" className="u-icon u-icon_notification" />}>
                                            <p>{commentText}</p>
                                        </Tooltip> : null}
                                        {phoneError && !this.state.focus ? <div className="b-input-text__input-error b-input__change-rate">{phoneError}</div> :
                                            phoneNotCorrect ? <div className="b-input-text__input-error b-input__change-rate b-input__change-phoneNotCorrect">{notCorrectTxt}</div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-form-transfer__checkbox-area">
                            <div className="b-input-checkbox b-input-checkbox_adapt_small-other b-input-checkbox_color_grey">
                                <label className="b-input-checkbox__wrap">
                                    <input className="b-input-checkbox__input" type="checkbox" onChange={this.props.onCheckboxChange} disabled={isDisabled} />
                                    <span className={"b-input-checkbox__icon" + (agreementError ? " has-error" : "")} />
                                    <span className="b-input-checkbox__text">{agreementText}</span>
                                </label>
                                {agreementError ? <div className="b-input-text__input-error b-input__change-rate">{agreementError}</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="b-form-transfer__btns-sector">
                        <button
                            id={"mnp-main-page-check-number-button"}
                            className={"mnp-btn-container b-form-transfer__submit u-btn u-btn u-btn_default u-btn u-btn_lg-responsive" + (!agreement || phone.replace(/\D/gim, '').length != 11 || isDisabled || inProgress ? " disabled" : "")}
                            type="button"
                            onClick={() => this.props.operatorCheck()}
                        // type="submit"
                        >
                            {
                                inProgress ?
                                    <span className='loader' /> :
                                    <span>Отправить</span>
                            }
                        </button>
                        {/*{this.props.children}*/}
                    </div>
                </div>
                {this.renderModal()}
            </form>
        )
    }
}
