import React, { Component } from 'react';
import PropTypes from "prop-types";
import PdfLink from "../Constructor/PdfLink";
import {setIdButton, isEmptyObj} from "../../tools/tools";
import SocialLinks from "../../components/Rates/SocialLinks";
import { connect } from "react-redux";

import {createBrowserHistory, createMemoryHistory} from "history";

class TariffCalculator extends Component {

    constructor(props) {
        super(props);
        let flag = this.props.flag;
        this.state = {
            flag,
            numbers: 0,
            oldnumbers: 0,
            masOld: [0],
            masNew: [0],
            activeIndexes: [0],
            isSm: typeof window === "undefined" ? true : window.innerWidth < 768,
            classesNums: {
                lineNew: [
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    }
                ],
                lineOld: [
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    }
                ]
            },
            isMd: typeof window === "undefined" ? 320 : window.innerWidth
        }
        this.costContainer = React.createRef();
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object
    };

    componentDidMount(){
        if (typeof window !== "undefined") {
            window.addEventListener('resize', this.onResize);
        }

        // вылавливаем нужный query параметр и запускам модалку
        const history = typeof document === 'undefined' ? createMemoryHistory() : createBrowserHistory();
        const queryParamsForm = new URLSearchParams(history.location.search).get('form');
        if(queryParamsForm && queryParamsForm === 'svoi'){
            this.props.onClickConnect();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    };

    componentWillMount() {
        if (!!this.props.tariffConstructor && !isEmptyObj(this.props.tariffConstructor.recalcData)) {
            if (!!this.props.tariffConstructor.recalcData.abon && !this.props.tariffConstructor.recalcData.abon_discount) {
                this.priceToState(this.props.tariffConstructor.recalcData.abon);
            }
            if (!!this.props.tariffConstructor.recalcData.abon_discount && !!this.props.tariffConstructor.recalcData.abon && +this.props.tariffConstructor.recalcData.abon >= +this.props.tariffConstructor.recalcData.abon_discount) {
                this.priceToState(this.props.tariffConstructor.recalcData.abon);
            }

            // при переходе по вкладкам оставляет скидку
            if (!!this.props.tariffConstructor.recalcData.abon_discount && +this.props.tariffConstructor.recalcData.abon_discount < +this.props.tariffConstructor.recalcData.abon) {
                this.priceToState(this.props.tariffConstructor.recalcData.abon_discount);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        // установка цены при заходе на страницу
        if (this.props.tariffConstructor.recalcData.abon !== nextProps.tariffConstructor.recalcData.abon) {
            if ( !nextProps.tariffConstructor.recalcData.abon_discount ||
                !!nextProps.tariffConstructor.recalcData.abon_discount && !!nextProps.tariffConstructor.recalcData.abon && +nextProps.tariffConstructor.recalcData.abon >= +nextProps.tariffConstructor.recalcData.abon_discount) {
                this.priceToState(nextProps.tariffConstructor.recalcData.abon);
            }
        }

        if (this.props.tariffConstructor.recalcData.abon_discount !== nextProps.tariffConstructor.recalcData.abon_discount) {
            // при входе в лк меняет сумму на со скидкой
            if ( !!nextProps.tariffConstructor.recalcData.abon_discount && +nextProps.tariffConstructor.recalcData.abon_discount < +nextProps.tariffConstructor.recalcData.abon ) {
                this.priceToState(nextProps.tariffConstructor.recalcData.abon_discount);
            }

            // при выходе из лк меняет сумму на без скидки
            if (this.props.tariffConstructor.recalcData.abon && this.props.isGuest) {
                this.priceToState(nextProps.tariffConstructor.recalcData.abon);
            }
        }
    }

    onResize = () => {
        const ww = window.innerWidth;
        if ( ww != this.state.isMd) {
            this.setState({isMd: ww});
        }
    };

    priceToState = (num) => {

        let oldN = this.state.numbers;
        let newN = num;

        let masOld = String(oldN).split("");
        let masNew = String(newN).split("");

        let n = Math.max(masOld.length, masNew.length);

        for (let i = 0; i < n; i++) {
            if (masOld.length < i+1){
                masOld.unshift(" ");
            }
            if (masNew.length < i+1){
                masNew.unshift(" ");
            }
        }

        let activeIndexes = [];

        for (let i = 0; i < n; i++) {
            if (masOld[i] != masNew[i]){
                activeIndexes[i] = 1;
            }
        }

        this.setState({
            oldnumbers: this.state.numbers,
            numbers:  num,
            flag: 1,
            masOld: masOld,
            masNew: masNew,
            activeIndexes: activeIndexes,
            classesNums: {
                lineNew: [
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    }
                ],
                lineOld: [
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    },
                    {
                        classNum: ""
                    }
                ]
            }
        },()=> {

            if (this.state.firstLoad2 === true){
                this.setState( {
                    firstLoad2: false,
                    classesNums: {
                        lineNew: [
                            {
                                classNum: " active"
                            },
                            {
                                classNum: " active"
                            },
                            {
                                classNum: " active"
                            },
                            {
                                classNum: " active"
                            }
                        ],
                        lineOld: [
                            {
                                classNum: " active"
                            },
                            {
                                classNum: " active"
                            },
                            {
                                classNum: " active"
                            },
                            {
                                classNum: " active"
                            }
                        ]
                    }
                })
            } else {
                this.setClass(0, masNew.length);
            }
        } )
    };

    setClass = (i, n) => {
        this.setState( (prevState)=> {

                let lineNew = [...prevState.classesNums.lineNew];
                let lineOld = [...prevState.classesNums.lineOld];

                // let str1 = this.state.masNew.join(',');
                // let str2 = this.state.masOld.join(',');


            //    if  (str1 === str2){
            //        lineNew[i].classNum = " active";
            //        lineOld[i].classNum = " active";
            //    } else{

            //         if (prevState.activeIndexes[i] === 1 ) {
            //             lineNew[i].classNum = " active";
            //             lineOld[i].classNum = " active";
            //          } else{
            //              lineNew[i].classNum = " no-animation";
            //              lineOld[i].classNum = " no-animation";
            //         }
            //    }
                lineNew[i].classNum = " active";
                lineOld[i].classNum = " active";

                return {classesNums: {lineNew: lineNew, lineOld: lineOld}};

            },()=>{

            if (i+1 < n) {
                setTimeout(this.setClass, 100, i+1, n);
            }
        });
    };

    render() {
        let {
            flag,
            masOld,
            masNew,
            isSm,
            classesNums,
            isMd
        } = this.state;

         let {
             price,
             constructorParams,
             onClickConnect = () => {},
             settings,
             dataForUrl
        } =this.props;

        let recalcData = this.props.tariffConstructor.recalcData;
        let aboutPageUrl = "/" + this.context.selectedRegion.alias + "/" + settings.route;
        let saleFlag = false;

        if (!!recalcData.abon_discount && recalcData.abon_discount !== recalcData.abon) saleFlag = true;

        let idButton = setIdButton(this.context.selectedRegion.id, 'selfmade', 'st', 'main', 'button');

        return (
            <div className="tariffs-constructor__subscription-fee-wrap calculator">
                <div className={ saleFlag === true ? "price sale_on" : "price"}>
                    {saleFlag === true ?
                        <div className="sale">
                            <span className="old-cost">{recalcData && recalcData.abon ? recalcData.abon : 'undef'}</span>
                            <span className="percent">-{recalcData && recalcData.discount ? recalcData.discount : 'undef'}%</span>
                            {recalcData && recalcData.discount_period ? <span className={"comment-sale-desktop"}>
                                {'Первые ' + recalcData.discount_period + ' месяца'}
                            </span> : null}
                        </div> : null}

                        <div className="cost">
                                <div className="numbers_container" ref={this.costContainer}>
                                    <div className={ (flag===1)? "lineNums lineRelative" : "lineNums"}>
                                        {masNew.map( (item, key) =>
                                        classesNums.lineNew[key] ? <div className={ (flag === 1) ? "newNum" + classesNums.lineNew[key].classNum : "newNum"}>
                                                {item}
                                            </div> : null
                                        )}
                                    </div>
                                    <div className={ (flag===1)? "lineNums lineAbsolute" : "lineNums"}>
                                        {masOld.map( (item, key) =>
                                            <div className={ (flag === 1) ? "oldNum" + classesNums.lineOld[key].classNum : "oldNum"}>
                                                {(flag === 0) ? masNew[key] : item}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            <span className="unit">
                                <span className="rub">₽</span>
                                /месяц
                            </span>
                        </div>
                    {/* {saleFlag 
                        ? <span className={"comment-sale-mobile"}>
                            {recalcData && recalcData.discount_period ? ('Первые ' + recalcData.discount_period + ' месяца') : 'undef'}
                        </span> 
                        : ""
                    } */}
                    <div className="tariffs-constructor__tariff-info">
                        <PdfLink className={"pdf-link"} constructor={constructorParams} linkFile={recalcData && recalcData.pdf_file}>
                            Информация о тарифе
                        </PdfLink>
                        <a className={"about-link dd"}  href={aboutPageUrl} target="_blank">Особенности подключения к тарифу</a>
                    </div>
                    {isMd >= 1024 ?
                        <SocialLinks title={'Поделись «своим тарифом»'} dataForUrl={dataForUrl} />
                    : null}
                </div>
                <div className="st-main-button-wrap">
                    <a id={!!idButton ? idButton : 'st-main'} onClick={onClickConnect} className = {"btn btn-lg btn-gradient btn-chevron-right st-main-button"}>{ saleFlag ? "Подключить со скидкой" : "Подключить"}</a>
                    {isMd < 1024 ?
                        <SocialLinks title={'Поделись «своим тарифом»'} dataForUrl={dataForUrl} />
                    : null}
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        tariffConstructor: state.tariffConstructor,
        isGuest: state.client.isGuest
    })
)(TariffCalculator);