import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
    activateMyTariff,
    activateTariffConstructor,
    checkAvailableConstructor,
    clearTariffPopupError
} from "../../actions/activation";
import { codeValidatorText, emailValidatorText, phoneValidatorText } from "../../tools/form-helper";
import { phoneMask } from "../../tools/mask";
import { addBrackets, checkUtmParams, getCookie, isEmptyObj, setIdButton } from "../../tools/tools";
import Timer from "../common/Timer";
import FormField from "./FormField";
import PdfLink from "./PdfLink";

class QuickConnectionForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                phone_number: {
                    type: "text",
                    name: "phone_number",
                    label: "Введите номер для подключения",
                    value: "",
                    placeholder: "+7",
                    error: "",
                    mask: phoneMask,
                    validate: phoneValidatorText,
                    typeInput: "phone"
                },
                email: {
                    type: "text",
                    name: "email",
                    label: "Ваш e-mail",
                    value: "",
                    placeholder: "info@mail.ru",
                    hint: props.settings.quick_email_hint || '',
                    error: "",
                    validate: emailValidatorText,
                    typeInput: "email"
                },
                code: {
                    type: "text",
                    name: "code",
                    label: "Код подтверждения",
                    value: "",
                    placeholder: "",
                    error: "",
                    validate: codeValidatorText
                },
                i_agree: {
                    type: "checkbox",
                    name: "i_agree",
                    label: <span dangerouslySetInnerHTML={{ __html: props.settings.rules_checkbox_text }} />,
                    value: false,
                    error: ""
                },
                tariff_conditions: {
                    type: "checkbox",
                    name: "tariff_conditions",
                    label: <span>С <PdfLink constructor={props.constructor} tariffParams={props.tariffParams} className="link-accent" linkFile={props.linkFile}>условиями тарифа</PdfLink> ознакомлен, условия понятны, претензий к Оператору не имею.</span>,
                    value: false,
                    error: ""
                }
            },
            step: 1,
            formValid: false,
            errorMessage: '',
            timer: true,
            formSent: false,
            disableBtnTimer: !!getCookie('has_connection') && getCookie('has_connection') > 1
        }
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
        selectedRegion: PropTypes.object
    };

    componentDidMount() {
        if (this.props.isGuest) {
            document.querySelector('.quick-connection').id = 'svoi_form'
        }
    }
    componentWillUnmount() {
        this.props.clearTariffPopupError();
    }

    componentWillReceiveProps(nextProps) {

        let available = nextProps.activation.available;
        let activation = nextProps.activation.activated;

        if (available.hasOwnProperty('query_id')) {
            if (nextProps.activation.activated === true) {
                this.props.openSuccess('success-tariff-connection');
            } else {
                this.setState({ step: 2, formValid: false });
            }
        }

        if (this.props.activation.activatedMyTariff !== nextProps.activation.activatedMyTariff && nextProps.activation.activatedMyTariff === true) {
            this.props.openSuccess('success-tariff-connection');
            if (this.state.formSent) this.setState({ formSent: false });
        }

        if (nextProps.activation.error && available.hasOwnProperty('message')) {
            this.setState({ errorMessage: available.message })
        }

        if (nextProps.activation.error && nextProps.activation.activated.hasOwnProperty('message')) {
            this.setState({ errorMessage: nextProps.activation.activated.message })
        }

        if (nextProps.activation.error && nextProps.activation.activatedMyTariff.hasOwnProperty('message')) {
            this.setState({ errorMessage: nextProps.activation.activatedMyTariff.message })
        }
    }

    onChange = (name, value) => {
        let fields = { ...this.state.fields };

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        fields[name].error = fields[name].validate ? fields[name].validate(fields[name].value) : "";

        this.setState({ fields });

        this.validateForm();
    };

    validateForm = () => {
        let fields = { ...this.state.fields };

        let formValid = true;

        if (this.state.step === 1) {

            if ((!this.props.isGuest && this.props.typeConnectionModal !== 'svoi' || this.props.isGuest && this.props.typeConnectionModal !== 'svoi') ||
                (this.props.isGuest && this.props.typeConnectionModal === 'svoi')) {
                if (!fields['phone_number'].value || fields['phone_number'].validate(fields['phone_number'].value) !== "") {
                    formValid = false;
                }

                if (fields['i_agree'].value === false || fields['tariff_conditions'].value === false) {
                    formValid = false;
                }
            } else {
                if (fields['tariff_conditions'].value === false) {
                    formValid = false;
                }
            }

            if (fields['email'].value !== "" && fields['email'].validate(fields['email'].value) !== "") {
                formValid = false;
            }

            this.setState({ formValid: formValid });
        }

        if (this.state.step === 2) {
            if (fields['code'].validate(fields['code'].value) !== "") {
                formValid = false;
            }

            this.setState({ formValid: formValid });
        }
    };

    renderField = (name, className = false) => {
        let disabled = this.state.fields[name].type === "checkbox" && (this.props.activation.inProgress || this.state.formSent);
        return <FormField {...this.state.fields[name]} disabled={disabled} onChange={this.onChange} className={className} />;
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.checkAvailableConstructor(this.state.fields.phone_number.value, this.context.selectedRegion.id);
        this.setState({ timer: true })
    };

    timerEnd = () => {
        this.setState({
            timer: false
        });
    };

    disableBtnTimerEnd = () => {
        this.setState({
            disableBtnTimer: false
        });
    };

    checkTypeVar = typeVar => {
        let result;
        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            if (typeVar === 'abon') {
                return result = this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.subs_fee;
            }
            if (typeVar === 'discount') {
                if (this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.discount === 0) {
                    return result = this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.subs_fee;
                } else {
                    return result = this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.subs_fee_discount;
                }
            }
        } else {
            if (typeVar === 'abon') {
                return result = this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.subs_fee;
            }
            if (typeVar === 'discount') {
                if (this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.discount === 0) {
                    return result = this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.subs_fee;
                } else {
                    return result = this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.subs_fee_discount;
                }
            }
        }
    }

    // сбор аналитики Svoi
    collectionOfAnalyticsSvoi() {
        try {
            ym(5462218, 'reachGoal', 'svoi_form_send');
            // ga('create', 'UA-120539241-1', 'auto');
            // ga('send', 'event', 'forms_sent', 'ga_svoi_form_send');
            VK.Goal('submit_application');
            var _tmr = window._tmr || (window._tmr = []); _tmr.push({ "type": "reachGoal", "id": 2767574, "goal": "svoy_tarif" });
            ttq.track('CompleteRegistration');


            let tariffParams = this.props.tariffParams;
            let tariff_id = false;

            if (!!tariffParams) {
                tariff_id = tariffParams.svoi && tariffParams.svoi.tariff_id
            } else {
                tariff_id = false
            }

            const data = !!tariffParams && (tariffParams.svoi.data === 'безлимит' || tariffParams.svoi.data === 'Безлимит' ? 0 : parseInt(tariffParams.svoi.data));
            const min = !!tariffParams && parseInt(tariffParams.svoi.min);
            const sms = !!tariffParams && (!!tariffParams.svoi.sms && tariffParams.svoi.sms !== '' ? parseInt(tariffParams.svoi.sms) : 0);
            const youtube_unlim = !!tariffParams && (!!tariffParams.svoi.youtube_unlim ? 1 : 0);
            const motiv_voice_unlim = !!tariffParams && (!!tariffParams.svoi.motiv_voice_unlim ? 1 : 0);
            const socialNetworkUnlim = !!tariffParams && (!!tariffParams.svoi.social_network_unlim ? 1 : 0);
            const ivi = !!tariffParams && (!!tariffParams.svoi.ivi ? 1 : 0);
            const data_unlim = !!tariffParams && (!!tariffParams.svoi.data_unlim ? 1 : 0);
            const router = !!tariffParams && (!!tariffParams.svoi.router ? 1 : 0);
            const abon = !!tariffParams && parseInt(tariffParams.svoi.abon);
            const abon_discount = !!tariffParams && parseInt(tariffParams.svoi.abon_discount);

            const paramsOldFormsTarifs = {
                name: `Svoi Констр ${data}Гб ${min}минут ${+sms} ${youtube_unlim} ${motiv_voice_unlim} ${socialNetworkUnlim} ${ivi} ${data_unlim} ${router}`,
                price: abon_discount || abon,
                id: tariff_id,
            };

            // для GTM
            setDataLayer('oldFormsTarifs', paramsOldFormsTarifs);
        } catch (e) {
            console.log(e);
        }
    };

    activateTariff = (e) => {
        e.preventDefault();
        console.log('second')

        this.collectionOfAnalyticsSvoi();

        let tariffParams = this.props.tariffParams;
        let alias = '', tariff_id;
        if (this.props.userTariffName) {
            alias = this.props.userTariffName;
        }
        // if (this.props.userTariffName.replace(/[^А-яа-я\-_]/gim, '') === this.props.userTariffName) {
        //     alias = this.props.userTariffName;
        // }

        if (!!tariffParams) {
            tariff_id = tariffParams.svoi && tariffParams.svoi.tariff_id
        } else {
            tariff_id = false
        }

        this.props.activateTariff({
            // region_id: this.context.selectedRegion.id,
            region_id: tariff_id,
            abnum: this.state.fields.phone_number.value,

            email: this.state.fields.email.value,
            query_id: this.props.activation.available.query_id,
            verify_code: this.state.fields.code.value,
            socialNetworkUnlim: !!tariffParams && (!!tariffParams.svoi.social_network_unlim ? 1 : 0),
            motiv_voice_unlim: !!tariffParams && (!!tariffParams.svoi.motiv_voice_unlim ? 1 : 0),
            data_unlim: !!tariffParams && (!!tariffParams.svoi.data_unlim ? 1 : 0),
            router: !!tariffParams && (!!tariffParams.svoi.router ? 1 : 0),
            youtube_unlim: !!tariffParams && (!!tariffParams.svoi.youtube_unlim ? 1 : 0),
            ivi: !!tariffParams && (!!tariffParams.svoi.ivi ? 1 : 0),
            home_region_checked: !!tariffParams && (!!tariffParams.svoi.home_region_checked ? 1 : 0),

            alias: alias,
            abon: !!tariffParams && parseInt(tariffParams.svoi.abon),
            abon_discount: !!tariffParams && parseInt(tariffParams.svoi.abon_discount),
            min: !!tariffParams && parseInt(tariffParams.svoi.min),
            sms: !!tariffParams && (!!tariffParams.svoi.sms && tariffParams.svoi.sms !== '' ? parseInt(tariffParams.svoi.sms) : 0),
            data: !!tariffParams && (tariffParams.svoi.data === 'безлимит' || tariffParams.svoi.data === 'Безлимит' ? 0 : parseInt(tariffParams.svoi.data)),

            tariff_id,
            is_site: 0,
            // smsCount
        }, this.context.selectedRegion.id);
    };

    activateMyTariff = (e) => {
        e.preventDefault();
        console.log('first')
        this.collectionOfAnalyticsSvoi();
        let tariffParams = this.props.tariffParams;

        let alias = '', tariff_id = '';
        let options = [];
        if (tariffParams) {
            tariffParams.svoi.sms ? options.push(`SMS_PACK_${tariffParams.svoi.sms}`) : null;
            tariffParams.svoi.social_network_unlim ? options.push('SOCIAL_UNLIM') : null;
            tariffParams.svoi.data_unlim ? options.push('DATA_UNLIM') : null;
            tariffParams.svoi.motiv_voice_unlim ? options.push('MVOICE_UNLIM') : null;
            tariffParams.svoi.data_unlim && tariffParams.svoi.router ? options.push('ROUTER') : null;
            tariffParams.svoi.youtube_unlim ? options.push('YOUTUBE_UNLIM') : null;
            tariffParams.svoi.ivi ? options.push('IVI') : null;
            tariffParams.svoi.home_region_checked ? options.push('HOME_REGION_CHECKED') : null;
        }

        let utm_start = getCookie("utm_start");

        const copy_start_params = getCookie("copy_start_params");
        let utm_finish = checkUtmParams();

        if (typeof utm_start === 'undefined' && !!copy_start_params) {
            utm_start = copy_start_params;
        }

        if (!!tariffParams) {
            alias = tariffParams.name ? tariffParams.name : 'Свой_тариф';
            // alias = tariffParams.name.replace(/[^А-яа-я\-_]/gim, '') === tariffParams.name ? tariffParams.name : 'Свой_тариф';
        }

        let body = {
            region_id: this.context.selectedRegion.id,
            msisdn: this.props.clientInfo.username,

            email: this.state.fields.email.value,
            alias: alias,
            min: tariffParams && parseInt(tariffParams.svoi.min),
            data: tariffParams && (tariffParams.svoi.data === 'Безлимит' ? 0 : parseInt(tariffParams.svoi.data)),
            abon: tariffParams && parseInt(tariffParams.svoi.abon),
            abon_discount: tariffParams && parseInt(tariffParams.svoi.abon_discount),
            sms_count: !!tariffParams && (!!tariffParams.svoi.sms && tariffParams.svoi.sms !== '' ? parseInt(tariffParams.svoi.sms) : 0),
            options,

            // utm_start,
            // utm_finish,
        }

        if (!!tariffParams) {
            body['tariff_id'] = tariffParams.svoi && tariffParams.svoi.tariff_id
        }

        this.props.activateMyTariff(body, this.context.selectedRegion.billing_id);
        this.setState({
            formSent: true
        })
    }

    renderContent = () => {
        let { isGuest, typeConnectionModal, settings } = this.props;
        let activation = this.props.activation;
        let available = this.props.activation.available;
        let tariffParams = this.props.tariffParams;
        let idButton = 'st-connection-finish-button';

        if (tariffParams) {
            if (isGuest) idButton = setIdButton(this.context.selectedRegion.id, 'premade', (tariffParams.slug + '-' + 'st'), 'connection', 'button');
        } else {
            if (isGuest) idButton = setIdButton(this.context.selectedRegion.id, 'selfmade', 'st', 'connection', 'button');
        }
        switch (this.state.step) {
            case 2:
                const ind = settings.sms_description.indexOf('{{sms_description_msisdn}}');
                const formatTextSms = (ind !== -1) ? `${settings.sms_description.substr(0, ind)}<span>${addBrackets(this.state.fields.phone_number.value)}</span>&ensp;${settings.sms_description.substr(ind + '{{sms_description_msisdn}}'.length)}` : settings.sms_description;

                return <div className="quick-connection">
                    {
                        this.state.errorMessage
                            ? <p> {this.state.errorMessage}</p>
                            : <React.Fragment>
                                <div className="text-lg" dangerouslySetInnerHTML={{ __html: available.message ? available.message : formatTextSms }}></div>
                                {/* <div className="text-description">
                                                Смена тарифа осуществляется бесплатно.
                                                <br />
                                                Обращаем Ваше внимание!<br/>
                                                При смене тарифа или изменении объема выбранных пакетов неиспользованные минуты, ГБ, SMS сгорают. Ранее подключенные услуги, если вы не планируете их использовать, нужно отключить самостоятельно.</div> */}
                                <form>
                                    <div className="code-field">
                                        {this.renderField("code")}
                                        {/*<a href="#" className="link-accent">Выслать еще раз</a>*/}
                                    </div>
                                    {this.state.timer
                                        ? <div className={"orange code-form"}>Заказать код повторно можно через <Timer callbackFunc={this.timerEnd} /> секунд</div>
                                        : <div className={"link-accent pointer code-form"} onClick={this.onSubmit}>Выслать еще раз</div>}

                                    {
                                        activation.is500Error ?
                                            <span className='b-error-text'>К сожалению, мы не можем подключить интересующий вас тариф. Повторите попытку позже или позвоните по номеру 8 800 240 0000 (звонок бесплатный).</span>
                                            : (
                                                <button
                                                    id={"st-connection-finish-button"}
                                                    onClick={this.activateTariff}
                                                    type="submit"
                                                    className={"quick-connection-submit btn btn-accent btn-chevron-right" + (this.state.formValid ? "" : " disabled")}
                                                >
                                                    Подключить
                                                </button>
                                            )
                                    }
                                </form>
                            </React.Fragment>
                    }
                </div>;

            default:
                let warning = this.props.settings.subscription_fee_warning || "";
                return <div className="quick-connection">
                    {
                        this.state.errorMessage
                            ? <p> {this.state.errorMessage}</p>
                            : <React.Fragment>
                                {typeConnectionModal === 'svoi' && this.props.settings.quick_description ?
                                    <div className="text-lg">
                                        {this.props.settings.quick_description}
                                    </div>
                                    : null}
                                {typeConnectionModal === 'svoi' && warning ?
                                    <div className="b-error-text">
                                        {warning.replace('{{subscription_fee}}', tariffParams ? parseInt(tariffParams.svoi.abon_discount) : this.checkTypeVar('discount'))}
                                    </div>
                                    : null}
                                <form
                                    onKeyDown={(event) => {
                                        // по клику на Enter предотвращаем любые всплытия
                                        if (event.which === 13 || event.keyCode == 13 || event.key === "Enter") {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            event.nativeEvent.stopImmediatePropagation();
                                        }
                                    }}
                                >
                                    <div className="form-row">
                                        {!isGuest && typeConnectionModal === 'svoi' ? null : this.renderField("phone_number", "phone_focus")}
                                        {this.renderField("email", "email_focus")}
                                    </div>
                                    <div className="form-row">
                                        <div>
                                            {!isGuest && typeConnectionModal === 'svoi' ? null : this.renderField("i_agree")}
                                            {this.renderField("tariff_conditions")}
                                        </div>
                                        {!isGuest && typeConnectionModal === 'svoi' ?
                                            (
                                                <Fragment>
                                                    {
                                                        activation.is500Error ? (
                                                            <span className='b-error-text quick-connection-submit-error quick-connection-submit-error-desktop'>К сожалению, мы не можем подключить интересующий вас тариф. Повторите попытку позже или позвоните по номеру 8 800 240 0000 (звонок бесплатный).</span>
                                                        ) : (
                                                            <button
                                                                id={idButton}
                                                                onClick={this.activateMyTariff}
                                                                type="submit"
                                                                className={"st-connection-finish-button quick-connection-submit btn btn-accent btn-chevron-right" + (this.state.formValid && !this.state.formSent && !this.state.disableBtnTimer ? "" : " disabled")}
                                                            >
                                                                {this.state.disableBtnTimer ? <React.Fragment>Осталось&nbsp;<Timer callbackFunc={this.disableBtnTimerEnd} withMinutes seconds={Math.round((getCookie('has_connection') - new Date().getTime()) / 1000)} /></React.Fragment> : 'Подключить'}
                                                            </button>
                                                        )
                                                    }
                                                </Fragment>
                                            ) :
                                            <button
                                                id={idButton}
                                                onClick={this.onSubmit}
                                                type="submit"
                                                className={"st-connection-button btn btn-accent btn-chevron-right" + (this.state.formValid && !this.props.activation.inProgress ? "" : " disabled")}
                                            >
                                                Подключить
                                            </button>
                                        }
                                    </div>
                                    {
                                        activation.is500Error &&
                                        <span className='b-error-text quick-connection-submit-error quick-connection-submit-error-mobile'>К сожалению, мы не можем подключить интересующий вас тариф. Повторите попытку позже или позвоните по номеру 8 800 240 0000 (звонок бесплатный).</span>
                                    }
                                </form>
                            </React.Fragment>
                    }
                </div>;
        }
    };

    render() {
        return this.renderContent();
    }
}

export default connect(
    state => ({
        activation: state.activation,
        price: state.constructor.price,
        tariffConstructor: state.tariffConstructor,
        settings: state.constructor.settings,
        clientInfo: state.client.clientInfo,
    }),
    (dispatch) => {
        return {
            checkAvailableConstructor: (phone, regionId) => dispatch(checkAvailableConstructor(phone, regionId)),
            activateTariff: (tariff, regionId) => dispatch(activateTariffConstructor(tariff, regionId)),
            activateMyTariff: (tariff, regionId) => dispatch(activateMyTariff(tariff, regionId)),
            clearTariffPopupError: () => dispatch(clearTariffPopupError()),
        }
    }
)(QuickConnectionForm);
