import React, { Component } from 'react';

import Slider from 'react-slick';

class TabsFilterCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dots: []
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.slickTo = this.slickTo.bind(this);
    }

    componentWillMount() {
        let dots = [];

        this.props.blocks.forEach(function (el, i) {
            dots.push({
                active: i === 0,
            });
        });

        this.state.dots = dots;
    }
    
    componentDidMount(){
        this.slider.slickGoTo(this.props.slug);
    }

    componentDidUpdate(prevProps, prevState) {
        this.slider.slickGoTo(this.props.slug);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    slickTo(e) {
        let idx = e.target.getAttribute('data-index');
        this.slider.slickGoTo(idx);
    }

    changeDot(idx) {
        this.setState(() => {
            this.state.dots.forEach(function (el) {
                el.active = false;
            });
            this.state.dots[idx].active = true;
            return { dots: this.state.dots }
        });
    }

    render() {
        let blocks = this.props.blocks;
        let slug = this.props.slug;
        // let typeSettings = blocks.length ? blocks[0].type : {};

        const settings = {
            infinite: true,
            variableWidth: true,
            autoplay: false,
            autoplaySpeed: 100,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '20px',
            swipeToSlide: true,
            arrows: false,
            dots: false,
            draggable: false,
            initialSlide: slug
            // beforeChange: (current, next) => this.changeDot(next),
            // responsive: [ 
            //     {
            //         breakpoint: 320,
            //         settings: {
            //             slidesToShow: 3,
            //             // centerMode: true,
            //             // centerPadding: '20px',
            //         }
            //     },
            // ]
        };

        return (
            <div className="tabs_container__slider">
                <div className="">
                    <div className="tabs_container__slider_wrapper">
                        <Slider ref={c => (this.slider = c)} {...settings}>
                            {blocks.map((block, index) =>
                                <a
                                    key={index}
                                    href="#"
                                    onClick={(e) => { e.preventDefault(); this.props.toggleCat(index) }}
                                    // onClick={(e) => { e.preventDefault(); this.props.toggleCat(block.slug) }}
                                    className={`tabs_container__list__item ${index === slug ? 'tabs_container__list__item_active' : ''}`}
                                >
                                    {block.name}
                                </a>
                            )}
                        </Slider>
                    </div>

                    {/* {blocks && blocks.length > 1 
                            ?   <div className="b-service-slider__controls promotion_page__slider_controls">
                                    <div className="b-service-slider__arrows">
                                        <div onClick={this.previous} className="b-service-slider__arrow b-service-slider__arrow_left" />
                                        <div onClick={this.next} className="b-service-slider__arrow b-service-slider__arrow_right" />
                                    </div>
                                    <div className="b-service-slider__dots">
                                        <ul className="slick-dots" role="tablist">
                                            {this.state.dots.map((dot, index) =>
                                                <li className={dot.active ? "slick-active" : ""} role="presentation" key={index}>
                                                    <button type="button" onClick={this.slickTo} data-index={index}/>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        className="b-main-slider__circle"
                                                        version="1.1"
                                                        viewBox="0 0 60 60">
                                                        <circle className="circle__background" cx="30" cy="30" r="25" stroke="hsla(0,0%,100%,.5)" strokeWidth="3" fill="rgba(0,0,0,0)"/>
                                                        <circle className="b-main-slider__path" style={{animationDuration: (7*typeSettings.auto_slick_time)+"s"}} cx="30" cy="30" r="25" stroke="#fff" strokeWidth="3" fill="rgba(0,0,0,0)"/>
                                                    </svg>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            :   null
                        } */}
                </div>
            </div>
        )
    }
}

export default TabsFilterCarousel;