import React from "react";
import {mediaUrl} from "../../tools/config";
import AdvertisingLabeling from "../AdvertisingLabeling/AdvertisingLabeling";

const InfoBlockBecomeClient = ({data, onClick}) => {

    const block = data.length ? data[0] : null;

    return block ? <div className='info-block_become-client b-banner-offer_adapt_default' {...block.imagePath ? {style: {backgroundImage: `url('${mediaUrl + block.imagePath}')`}} : {}}>
        <AdvertisingLabeling
            token={block && block.token}
            advertiser={block && block.advertiser}
        />
        <div className="page__container">
            <div className='info-block_become-client__text'>
                <div className='b-banner-offer__title'>{block.title}</div>
                <div className='b-banner-offer__text'>{block.text}</div>
                <button
                    onClick={onClick}
                    className='u-btn u-btn_responsive u-btn_adapt_mobile-sm u-btn_default'
                >
                    Оставить заявку
                </button>
            </div>
        </div>
    </div> : null;
}

export default InfoBlockBecomeClient;