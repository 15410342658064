import React, {Component} from 'react';
import PropsTypes from 'prop-types';
import {connect} from "react-redux";
import Icon from "../../components/common/Icon";
import {
    clearState,
    changeStatus,
    orderDetails,
    changeInfo,
    exchangeMinsSend,
    toggleServices,
    checkReqAction
} from "../../actions/client";
import Modal from "../../components/common/Modal";
import BlockNumberForm from "../../components/Lk/BlockNumberForm";
import UnblockNumberForm from "../../components/Lk/UnblockNumberForm";
import OrderDetailsForm from "../../components/Lk/OrderDetailsForm";
import ChangeInfoForm from "../../components/Lk/ChangeInfoForm";
import ExchangeForm from "../../components/Lk/ExchangeForm";
import LinkC from "../../components/common/LinkC";
import {mainUrl} from "../../tools/config";
import ChangeRateConfirmation from "../../components/modals/ChangeRateConfirmation";

class LKModals extends Component {

    // когда открывается попап (Ваш запрос успешно отправлен), 
    // возвращает кнопке ОТПРАВИТЬ активность в попапе (Заявка на изменение данных)
    componentDidUpdate(prevProps) {
        if (prevProps.modal !== this.props.modal) {
            if (this.props.modal === "info-change-success") {
                this.props.checkReqAction(true);
            }
        }
    }

    static contextTypes = {
        selectedRegion: PropsTypes.object
    };

    infoContent = (message, description = "", isErr = false) => {
        if (message.indexOf("{{name}}") > -1) {
            message = message.replace("{{name}}", this.props.service? this.props.service.name : "")
        }
        return <div className="mnp-popup-ok swiper-container mnp-popup__wrapper">
            <div className="text-center">
                {isErr ? null : <Icon name={'icon-form-ok'} className={"mnp-popup-ok__icon"}/>}
                <div className="b-plug-auth__title">{message}</div>
                {description ? <div className="b-plug-auth__subtitle">{description}</div> : null}
                <button type="button" className="u-btn u-btn_default u-btn_adapt_mobile-sm" onClick={this.close}>OK</button>
            </div>
        </div>
    };

    onService = (id, service) => {
        this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), service, true);
    };

    offService = (id, service) => {
        this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), service, false);
    };

    renderContent = () => {

        let {
            modal,
            service,
            blockNumber,
            unblockNumber,
            orderDetails,
            changeInfo,
            sendExchange,
            routes
        } = this.props;

        let {
            changePasswordError,
            changePasswordSuccess,
            changeStatusError,
            clientInfo,
            detalizationError,
            changeInfoError,
            exchangeMinsSuccess,
            exchangeMinsError,
            toggleServiceError,
            toggleServiceMessage,
            formsSettings
        } = this.props.client;

        let serviceDetail = typeof service !== 'undefined' ? service.slug : '';
        let motivOnMapRoute = routes ? routes.list.find(x => x.sys_name === 'motivOnMapRoute') : null;

        

        switch (modal) {

            case 'password-change-success':
                return this.infoContent(formsSettings.change_password_form.change_password_success_title, formsSettings.change_password_form.change_password_success_description);
            case 'block-number-success':
                return this.infoContent(formsSettings.block_form.block_success_title, formsSettings.block_form.block_success_description);
            case 'unblock-number-success':
                return this.infoContent(formsSettings.unblock_form.unblock_success_title, formsSettings.unblock_form.unblock_success_description);

            case 'password-change-error':
                return this.infoContent(changePasswordError, "", true);

            case 'block-number':
                return <BlockNumberForm number={clientInfo.username} error={changeStatusError} onSubmit={blockNumber} formSettings={formsSettings && formsSettings.block_form}/>;
            case 'unblock-number':
                return <UnblockNumberForm number={clientInfo.username} error={changeStatusError} onSubmit={unblockNumber} formSettings={formsSettings && formsSettings.unblock_form}/>;

            case 'order-details':
                return <OrderDetailsForm title={service.name}
                                         link={mainUrl+this.context.selectedRegion.alias+"/services/"+service.slug}
                                         onSubmit={orderDetails}
                                         goBack={this.close}
                                         notice={formsSettings.details_form && formsSettings.details_form.details_form_description}
                                         dayPrice={formsSettings.details_form && formsSettings.details_form.details_form_day_price}
                />;
            case 'order-details-success':
                return this.infoContent(formsSettings.details_form && formsSettings.details_form.details_form_success_title, formsSettings.details_form && formsSettings.details_form.details_form_success_description);
            case 'order-details-error':
                return this.infoContent(detalizationError,"", true);
            case 'info-details':
                return service.canSwitch ? <ChangeRateConfirmation type={"service"}
                                                                   item={{
                                                                       name: service.name,
                                                                       isOn: service.isOn,
                                                                       info: [{
                                                                           items: service.isOn ? [
                                                                               {
                                                                                   title: "Дата отключения",
                                                                                   value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                                               }
                                                                           ] : [
                                                                               {
                                                                                   title: "Абонентская плата",
                                                                                   value: service.subscription_fee || 0,
                                                                                   measure: "₽"
                                                                               },
                                                                               {
                                                                                   title: "Стоимость подключения",
                                                                                   value: service.activation_price || 0,
                                                                                   measure: "₽"
                                                                               },
                                                                               {
                                                                                   title: "Дата подключения",
                                                                                   value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                                               }
                                                                           ]
                                                                       }]
                                                                   }}
                                                                   notice={service.isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_form_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_form_description}
                                                                   onClick={() => {service.isOn ? this.offService(service.id_billing, service) : this.onService(service.id_billing, service)}}
                                                                   onCancel={this.close}
                /> : <div className="b-lk-block b-lk-block_adapt-default order-details-form">
                    <div className="b-lk-block__title">Запрос на предоставление услуги «<LinkC to={mainUrl+this.context.selectedRegion.alias+"/services/"+service.slug}>{service.name}</LinkC>»</div>
                    <div className="b-lk-block__sub-title">Для {service.isOn ? "от" : "под"}ключения услуги обратитесь в <LinkC to={mainUrl+this.context.selectedRegion.alias + motivOnMapRoute.route}>офис продаж</LinkC></div>
                    <div className="b-lk-block__form">
                        <div className="buttons-group text-right">
                            <button className="u-btn u-btn_transparent-grey u-btn_adapt_default u-btn_mobile-responsive" type="reset" onClick={this.close}>Назад</button>
                            <button className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive disabled">{service.isOn ? "От" : "Под"}ключить</button>
                        </div>
                    </div>
                </div>;
            case 'info-details-ok':
                return service ? this.infoContent(service.isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_title : formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_title,
                    service.isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_description) : null;
            case 'info-details-error':
                return service ? this.infoContent(toggleServiceError === "error" ? (service.isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_title : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_title) : toggleServiceError,
                    toggleServiceError === "error" ? (service.isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_description) : "",
                    true) : null;

            case 'info-change-request':
                return <ChangeInfoForm title="Заявка на изменение данных"
                                       description={formsSettings.personal_form ? (formsSettings.personal_form.personal_form_description || formsSettings.personal_form.personal_block_description) : null}
                                       onSubmit={changeInfo}
                                       number={clientInfo.username}
                                       checkReq={this.props.checkReq}
                />;
            case 'info-change-success':
                return this.infoContent(formsSettings.personal_form && formsSettings.personal_form.personal_form_success_title, formsSettings.personal_form && formsSettings.personal_form.personal_form_success_description);
            case 'info-change-error':
                return this.infoContent(changeInfoError, "", true);
            case 'exchange':
                return clientInfo.exchange ? <ExchangeForm max={clientInfo.exchange.max_minutes}
                                                           notice={formsSettings.exchange_form ? formsSettings.exchange_form.exchange_form_description.replace('{{rate}}', clientInfo.exchange.rate) : ""}
                                                           rate={clientInfo.exchange.rate}
                                                           onSubmit={sendExchange}
                /> : null;
            case 'exchange-success':
                return this.infoContent(exchangeMinsSuccess);
            case 'exchange-error':
                return this.infoContent(exchangeMinsError, "", true);
            case 'order-expenses':
                return <OrderDetailsForm title={"Заказать детализацию"}
                                         link={mainUrl+this.context.selectedRegion.alias+"/services/" + serviceDetail}
                                         onSubmit={orderDetails}
                                         goBack={this.close}
                                         notice={formsSettings.details_form && formsSettings.details_form.details_form_description}
                                         dayPrice={formsSettings.details_form && formsSettings.details_form.details_form_day_price}
                />;
            default:
                return null;
        }
    };

    close = () => {
        this.props.close();
        this.props.clear();
    };

    render() {

        let {
            modal
        } = this.props;

        let content = this.renderContent();

        return modal && content ? <Modal close={this.close}>{content}</Modal> : null
    }
}

export default connect(
    state => ({
        client: state.client,
        routes: state.routes,
        checkReq: state.client.checkReq
    }),
    (dispatch) => {
        return {
            clear: () => dispatch(clearState()),
            blockNumber: (data) => dispatch(changeStatus(data, true)),
            unblockNumber: (data) => dispatch(changeStatus(data, false)),
            orderDetails: (data) => dispatch(orderDetails(data)),
            changeInfo: (data) => dispatch(changeInfo(data)),
            sendExchange: (data) => dispatch(exchangeMinsSend(data)),
            checkReqAction: (data) => dispatch(checkReqAction(data)),
            toggleService: (data, service, on) => dispatch(toggleServices(data, service, on))
        }
    }
)(LKModals);