import React, { Component } from 'react';
import {setCookie} from "../tools/tools";
import {cookieDomain} from "../tools/config";

class LocalTokenBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localAccessToken: '',
      localRefreshToken: '',
    }
  }

    setCookies = () => {
      setCookie("access_token", this.state.localAccessToken,{
        path: "/",
        expires: 30 * 3600 * 24,
        domain: cookieDomain
      })
      setCookie("refresh_token", this.state.localRefreshToken,{
        path: "/",
        expires: 30 * 3600 * 24,
        domain: cookieDomain
      })
      setCookie('token_type', 'bearer',{
        path: "/",
        expires: 30 * 3600 * 24,
        domain: cookieDomain
      })
    }

    render() {
        return (
          <div className="local_auth_container">
              <div className="local_auth_container__input__container">
                  <input
                    className='search_input__input local_auth_container__input'
                    placeholder='Access token'
                    value={this.state.localAccessToken}
                    onChange={(e) => {this.setState({localAccessToken: e.target.value})}}
                  />
              </div>
              <div className="local_auth_container__input__container">
                  <input
                    className='search_input__input local_auth_container__input'
                    placeholder='Refresh token'
                    value={this.state.localRefreshToken}
                    onChange={(e) => {this.setState({localRefreshToken: e.target.value})}}
                  />
              </div>
              <div>
                  <button
                    onClick={this.setCookies}
                    className="local_auth_container__btn"
                  >
                      Применить
                  </button>
              </div>
          </div>
        )
    }
}

export default LocalTokenBlock;