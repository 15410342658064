import React, {Component} from "react";
import LinkC from "../common/LinkC";

import sim from "../../../images/content/home/term-sim.png";
import Icon from "../common/Icon";
import {mediaUrl} from "../../tools/config";
import InfoblockLink from "./InfoblockLink";

export default class InfoBlockAccentBanner extends Component{

    constructor(props){
        super(props);
        this.state = {
            animate: false
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.onScroll);
        this.onScroll();
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        let el = document.getElementById("page__buy-offer");
        if (el.offsetTop - window.pageYOffset < window.innerHeight - 100) {
            this.setState({
                animate: true
            })
        }
    };

    render(){
        let blocks = this.props.blocks;

        return <div id="page__buy-offer" className="page__buy-offer">
            {blocks.map((block) => <div className="b-buy-offer b-buy-offer_adapt_default" key={block.alias}>
                <div className="b-buy-offer__wrapper">
                    <div className="b-buy-offer__text-section">
                        <div className="b-buy-offer__title">{block.title}</div>
                        <div className="b-buy-offer__text">{block.text}</div>
                    </div>
                    <div className="b-buy-offer__btn-section">
                        <InfoblockLink className={"b-buy-offer__btn u-btn u-btn u-btn_transparent-white u-btn u-btn_sm"} item={block} />
                    </div>
                    <div className="b-buy-offer__img-section">
                        <div className={"b-buy-offer__term b-buy-offer__term_sim"+(this.state.animate ? " animate" : "")}>
                            <img className="b-buy-offer__term-img" src={sim} alt="img" role="presentation"/>
                        </div>
                        <div className="b-buy-offer__plus">
                            <Icon className="b-buy-offer__plus-icon u-icon u-icon_plus-tr" name="icon_plus-tr"/>
                        </div>
                        <div className={"b-buy-offer__term b-buy-offer__term_phone"+(this.state.animate ? " animate" : "")}>
                            <img className="b-buy-offer__term-img" src={mediaUrl+block.imagePath} alt="img" role="presentation"/>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    }
}
