import React, {Component} from 'react';
import PropTypes from "prop-types";
import {imgUrl} from '../../tools/config';
import LinkC from "../../../js/components/common/LinkC";
import Icon from '../common/Icon';

class PaymentMethodCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    isOpen = () => {
        this.setState({            
            opened: !this.state.opened,
        });
    }

    render() {
        let {
            opened
        } = this.state

        let {
            data
        } = this.props

        let selectedRegion = this.context.selectedRegion.alias;

        return (
            <div>
                <div className={`payment-methods-card__content ${opened ? ' card__content-active' : ''}` }>
                    <div className="payment-methods-card__content-img">
                        <img src={imgUrl + data.img.file_path} alt=""/>
                    </div>

                    {data && data.description && (
                        <React.Fragment>
                            <button
                                className="content__description-btn"
                                onClick={() => this.isOpen()}
                            >
                                <div className="content__description-btn-text">
                                    {opened ? 'Свернуть описание' : 'Показать описание'}
                                </div>
                                <Icon 
                                    name='icon_arrow-down' 
                                    className={`content__description-btn-img ${opened ? ' active-img' : ''}`} 
                                />
                            </button>

                            <div className={`content__all-description ${opened ? ' content__all-description-active' : ''}`}>
                                {data && (data.description || []).map(cat =>
                                    <div
                                        className="content__description"
                                        key={cat.type}
                                    >
                                        <div className="content__description-left">{cat.type}</div>
                                        <div className="content__description-right">{cat.value}</div>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )}

                    <LinkC 
                        to={(this.context.isLk && data.link.indexOf('http') !== 0 ? mainUrl + selectedRegion : "") + data.link}
                        className={"payment-methods-card__content-link"}
                    >
                        Перейти
                    </LinkC>
                </div>             
            </div>
        )
    }
}

export default PaymentMethodCard;