import React, {Component} from 'react';
import RatesCompleteSetListItem from "./RatesCompleteSetListItem";

class RatesCompleteSetList extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let items = this.props.items;

        return (
            <div className="page__packs-table">
                <div className="b-packs-table b-packs-table_adapt_default">
                    <div className="b-packs-table__wrapper">
                        {items.map((item, key) =>
                            <RatesCompleteSetListItem item={item} key={key} />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesCompleteSetList;