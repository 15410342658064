import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

export function putData(action) {
    return {
        type: 'PUT_DATA',
        payload: action.data
    }
}