import React, { Component } from 'react';
import Icon from './Icon';
import LinkC from "./LinkC";

class ButtonDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        }
    }

    dropButton = (value) => {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState(() => {
                return {opened: value}
            })
        }
    };

    buttonToggle = () => {
        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        })
    };

    locate (e) {
        if (e.target.target === '_blank') {
            window.open(e.target.href);
        } else {
            location.href = e.target.href;
        }
    }


    render() {
        let {idName = false} = this.props;

        return (
            <div className={"button-dropdown" + (this.state.opened ? " active" : "")} onClick={this.buttonToggle} onMouseLeave={this.dropButton(false)}>
                <div id={!!idName ? idName : ''} className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive button-dropdown__btn">
                    {this.props.name}
                    <Icon name={'icon_region-arrow'} className={'b-region-selector__arrow u-icon u-icon_region-arrow'}/>
                </div>
                <ul className="button-dropdown__select-list">
                    <li className="button-dropdown__select-item">
                        <a className="button-dropdown__select-item__link" onClick={this.locate} href={this.props.shopUrl} target="_blank" rel="nofollow">Купить SIM-карту</a>
                    </li>
                    <li className="button-dropdown__select-item">
                        <LinkC to={"/mnp"} className={"button-dropdown__select-item__link"}>
                            Перейти со своим номером
                        </LinkC>
                    </li>
                </ul>
            </div>

        )
    }
}

export default ButtonDropdown;