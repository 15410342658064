import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Helmet } from "react-helmet";
import InfoBlock from "../../components/common/InfoBlock";
import Icon from "../../components/common/Icon";
import RatesBigHeaderBanner from "../../components/Rates/RatesBigHeaderBanner";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import {completeSetsList} from "../../actions/completeSets";
import {infoBlocksList} from "../../actions/infoBlocks";
import RatesCompleteSetList from "../../components/Rates/RatesCompleteSetList";
import {faqList} from "../../actions/questions";
import {smoothScroll}  from '../../tools/tools';
import RatesFaq from "../../components/Rates/RatesFaq";
import LinkC from "../../components/common/LinkC";
import {tariffsList} from "../../actions/tariffs";
import Modal from "../../components/common/Modal";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import MnpForm from "../../components/Rates/MnpForm";
import {formsList, submitMnpForm} from "../../actions/forms";
import RatesByCategories from "../../components/Rates/RatesByCategories";
import AdvertisingLabeling from '../../components/AdvertisingLabeling/AdvertisingLabeling';
import BalansBlock from '../../components/BalansBlock/BalansBlock';

import CommonCallbackForm from "../../components/Forms/CommonCallbackForm";

class RatesHomeInternet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            modal: null,
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func
    };

    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if (this.props.completeSets.completeSetsList.length === 0) {
            this.props.getCompleteSetsList(regionId);
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'home_internet');
        }
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(regionId, 'rank_internet');
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(regionId, 3); //домашний интернет
        }
        if (this.props.forms.list.length === 0) {
            this.props.getForms("mnp");
        }
    }

    showModal() {
        return (e) => {
            e.preventDefault();
            this.setState({modal: 'feedback-order'});
        }
    }

    renderModal() {

        let content = "";

        if (this.state.modal) {
            content = <CommonCallbackForm closeModal={this.hideModal}/>
        } else {
            return;
        }

        return  <Modal innerClassName="u-modal-inner no-padding"  close={this.hideModal}>
                    {content}
                </Modal>
    }

    hideModal() {
        this.setState({modal: null});
    }

    onToggleTab = (value) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: value
                }
            })
        }
    };

    scrollBanner() {
        smoothScroll('header__banner', 'bottom');
    }

    scrollDevices = () =>  {
        smoothScroll('devices');
        this.setState({activeTab: 0});
    };

    filterForHomeInternet = (tariffs, region_id) => {

        tariffs = tariffs.filter(function (tariff) {

            if (tariff.region_id !== region_id) {
                return false;
            }

            if (tariff.mainCategory.rank_tab !== null) {
                return true;
            }

            if (tariff.mainCategory.rank_router !== null) {
                return true;
            }

            tariff.additionalCategories.forEach(function (cat) {
                if (cat.rank_tab !== null) {
                    return true;
                }
                if (cat.rank_router !== null) {
                    return true;
                }
            });
            return false;
        });

        return tariffs;
    };

    render() {

        let selectedRegion = this.context.selectedRegion;
        let activeTab = this.state.activeTab;
        let infoBlocks = this.props.infoBlocks.list;

        let headerBanner = infoBlocks.find(x => x.alias === 'home_internet_main_banner');

        let completeSets = this.props.completeSets.completeSetsList;
        let tariffs = this.props.tariffs.tariffsLists;
        tariffs = this.filterForHomeInternet(tariffs, selectedRegion.id);

        let faqList = this.props.faq.list.filter(x => x.category_id === 3);

        let currentPage = {
            url: "/" + selectedRegion.alias + "/internet/4g",
            title: "Домашний интернет по воздуху",
        };

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/internet",
                title: "Интернет"
            },
            {
                url: "",
                title: "Мобильный интернет"
            }
        ];

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'internet4gRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <React.Fragment>
                <main className="page__main-holder" role="main">
                    <Helmet
                        htmlAttributes={{"class": "pp-mod_internet"}}
                        title={currentMetaTags ? currentMetaTags.meta_title : ""}
                        meta={[
                            {
                                "name": "description",
                                "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                            },
                            {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                        ]}>
                        {/* <link rel="canonical" href={pageLink}/> */}
                    </Helmet>
                    
                    <div className="page__promo-intro">
                        <div className="b-promo-intro b-promo-intro_adapt_default">
                            {headerBanner ?
                                <RatesBigHeaderBanner imagePath={headerBanner.imagePath}>
                                    <AdvertisingLabeling
                                        token={headerBanner && headerBanner.token}
                                        advertiser={headerBanner && headerBanner.advertiser}
                                        type="mainType"
                                    />
                                    <div className="b-promo-intro__page-header"/>
                                    <div className="page__container">
                                        <div className="b-promo-intro__inner">
                                            <RatesBreadcrumbs items={breadcrumbs}/>
                                            <div className="b-promo-intro__title" dangerouslySetInnerHTML={{__html: headerBanner.title}}/>
                                            <div className="b-promo-intro__sub-title">{headerBanner.text}</div>
                                            <div className="b-promo-intro__btn-area">
                                                <LinkC
                                                    to={headerBanner.link}
                                                    noFollow={headerBanner.prevent_link_indexing}
                                                    className={"b-promo-intro__btn b-promo-intro__btn_choose u-btn u-btn_default"}
                                                    func={this.scrollDevices}
                                                >
                                                    {headerBanner.link_title}
                                                </LinkC>
                                                <a href="#" onClick={this.showModal()} className="b-promo-intro__btn b-promo-intro__btn_call u-btn u-btn_transparent-white">Заказать звонок</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="b-promo-intro__arrow-holder" onClick={this.scrollBanner}>
                                        <div className="page__container page__container_relative">
                                            <div className="b-promo-intro__arrow">
                                                <div className="b-promo-intro__arrow-text">Вниз</div>
                                                <Icon name={'icon_scroll'} className={'b-promo-intro__arrow-icon u-icon u-icon_scroll'}/>
                                            </div>
                                        </div>
                                    </div>
                                </RatesBigHeaderBanner>
                                : ''
                            }
                            <div className="wrap_balans_user">
                                <BalansBlock {...this.props.client} />
                            </div>
                        </div>
                    </div>
                    <div className="page__container">
                        <div className="page__h3">Почему 4G интернет Мотив</div>
                        <div className="page__simple-advantages">
                            <div className="b-simple-advantages__body">
                                <InfoBlock type={'internet-text-banner'} data={infoBlocks} section={'internet_description_main'} />
                                <InfoBlock type={'internet-small-banner'} data={infoBlocks} section={'internet_description_additional'} group={"home_internet"} />
                            </div>
                        </div>
                        <div id="devices" className="page__tarifs-list">
                            <div className="page__h4">Тарифы</div>
                        </div>
                        <div className="b-internet-ipad__tarifs-list">
                            <div className="b-internet-ipad__tabs-header">
                                <TabsSwitcher items={[{title: "Готовые комплекты"}, {title: "Подобрать тариф"}]} onClickFunc={this.onToggleTab} activeTab={activeTab} />
                            </div>
                        </div>
                        <ul className="tabs__content" ref="content">
                            <li className={"tabs-content__item" + ((activeTab === 0) ? " tabs-content__item--active" : "")}>
                                <div className="b-internet-ipad__tariffs-table">
                                    <div className="b-tariffs-table b-tariffs-table_adapt_default">
                                        {<RatesCompleteSetList items={completeSets} />}
                                    </div>
                                </div>
                            </li>
                            <li className={"tabs-content__item" + ((activeTab === 1) ? " tabs-content__item--active" : "")}>
                                <div className="b-internet-ipad__tariffs-table">
                                    <div className="b-tariffs-table b-tariffs-table_adapt_default">
                                        {
                                            tariffs
                                                ?   <RatesByCategories currentPage={currentPage} tariffs={tariffs} rankField={'rank_router'} />
                                                :   ''
                                        }
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="page__promo-block">
                            <InfoBlock type={'internet-big-banner'} data={infoBlocks} section={'internet_promo_banner'} />
                        </div>
                        <div className="page__offers-plates">
                            <div className="b-offers-plates b-offers-plates b-offers-plates_adapt_default">
                                <InfoBlock type={'title-link'} data={infoBlocks} section={'home_internet_device_selection'} />
                                <InfoBlock type={'product-card'} data={infoBlocks} section={'home_internet_device'} />
                            </div>
                        </div>
                        {
                            faqList
                                ?   <RatesFaq items={faqList} title={"Дополнительная информация"} />
                                :   ''
                        }
                    </div>
                </main>
                {this.renderModal()}
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        completeSets: state.completeSets,
        infoBlocks: state.infoBlocks,
        tariffs: state.tariffs,
        faq: state.faq,
        forms: state.forms,
        client: state.client,

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getCompleteSetsList : (regionId) => dispatch(completeSetsList(regionId)),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getTariffs : (regionId, categoryRankField) => dispatch(tariffsList(regionId, categoryRankField)),
            getFaq : (regionId, categoryId) => dispatch(faqList(regionId, categoryId)),
            submitForm: (formData, alias, target) => dispatch(submitMnpForm(formData, alias, target)),
            getForms: (group) => dispatch(formsList(group)),
        }
    }
)(RatesHomeInternet);


/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */

export const RatesHomeInternetRequests = [
    {
        func: 'getCompleteSetsList',
        args: [],
        object: 'completeSets.completeSetsList',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['home_internet'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "internet_description_main"},
            {type: "internet_description_additional"},
            {type: "home_internet_main_banner"},
            {type: "internet_promo_banner"},
            {type: "home_internet_device_selection"},
            {type: "home_internet_device"},
        ]
    },
    {
        func: 'getForms',
        args: [],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getTariffs',
        args: ['rank_internet'],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getFaq',
        args: [3],
        object: 'faq.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
];