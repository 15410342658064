import PropTypes from "prop-types";
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { infoBlockImage, infoBlocksImagesList } from '../../actions/infoBlocks';
import {stateSearchInputAction, toggleSearchInputAction} from '../../actions/searchAction';
import { b2bUrl, lkUrl, mainUrl, mediaUrl } from "../../tools/config";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';
import Icon from '../common/Icon';
import LinkC from "../common/LinkC";
import SearchInput from '../common/SearchInput';


class HeaderMegaMenu extends Component {
    static contextTypes = {
        selectedRegion: PropTypes.object,
        locationPath: PropTypes.string,
        fetchBefore: PropTypes.func,
        isLk: PropTypes.bool,
        isB2b: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            activeBlock: null,

            activeInfoblockId: null,
            activeBlockTitle: null,
            isHovered: null,
        };
    }

    componentDidMount() {
        this.props.infoBlocksImagesList();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeInfoblockId !== this.state.activeInfoblockId) {
            this.props.infoBlockImage(this.state.activeInfoblockId);
        }
    }

    handleMouseEnter = (infoblock) => {
        this.setState({
            activeInfoblockId: infoblock.infoblock_id,
            activeBlockTitle: infoblock.title,
            isHovered: true,
        });
    };

    handleMouseLeave = () => {
        this.setState({
            isHovered: false,
        });
    };

    render() {
        let megaMenuObj = this.props.megaMenu.megaMenuList.filter(x => x.regionId === this.context.selectedRegion.id);
        let regionAlias = this.context.selectedRegion.alias;
        let {
            toggleCollapse,
            activeIdx,
            isGuest,
            lkMenu,
            mainMenu
        } = this.props;

        const isB2b = this.context.isB2b;

        if (!isB2b && megaMenuObj.length === 0) {
            return (
                <div className="b-header__burger-holder u-fixpos">
                </div>
            )
        }

        // для ссылки О тарифе
        let region = this.context.selectedRegion.alias;
        let regionSlug = this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug;
        let detailUrl = (regionSlug ? "/" + regionSlug : "") + "/tariffs";
        let detailUrlLk = (regionSlug ? regionSlug : region) + "/tariffs";
        const slug = this.props.client.clientInfo.tariff ? this.props.client.clientInfo.tariff.slug : ''
        const status_id = this.props.client.clientInfo.tariff ? this.props.client.clientInfo.tariff.status_id : ''

        if (slug) {
            switch (status_id) {
                case 2:
                    detailUrl += "/archive/";
                    detailUrlLk += "/archive/";
                    break;
                case 3:
                    detailUrl += "/individual/";
                    detailUrlLk += "/individual/";
                    break;
                default:
                    detailUrl += "/";
                    detailUrlLk += "/";
            }
            detailUrl += slug;
            detailUrlLk += slug;
        }
        // ссылка на нужный контент
        const linkNewMenuFields = this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl;
        let newMenuFields = [{
            alias: "currentTariff",
            description: "",
            // iconName: "icon_new_slices",
            iconName: "icon_slices",
            link: linkNewMenuFields,
            seoTitle: "",
            title: "Текущий тариф",
            sort: 2
        }]
        // создаем новый массив меню, заливая в него новый объект
        const newLkMenu = lkMenu.concat(newMenuFields)
        // сортируем по порядку поля sort
        newLkMenu.sort(function (a, b) {
            return a.sort - b.sort
        });

        const leftSideOpennedMenu = () => {
            const { activeBlockTitle } = this.state;

            return (
                <div className="b-full-menu__titles__wrapper">
                    <ul className="b-full-menu__titles flex flex-col list-none p-0 m-0">
                        {megaMenuObj[0].data.flatMap(section => section).map((infoblock, idx) => (
                          <li
                            onMouseEnter={() => {
                                this.setState({
                                    activeBlock: infoblock.categories,
                                    activeBlockTitle: infoblock.title,
                                    activeInfoblockId: infoblock.infoblock_id
                                });
                                this.handleMouseEnter(infoblock)
                            }}
                            onMouseLeave={this.handleMouseLeave}
                            className={`b-full-menu__item_new ${activeBlockTitle === infoblock.title ? 'b-full-menu__item_new--active' : ''
                            }`}
                            key={idx}
                          >
                              <span className="b-full-menu__text">{infoblock.title}</span>
                          </li>
                        ))}
                    </ul>
                </div>
            );
        };
        const rightSideOpennedMenu = () => {
            const { activeBlock, activeBlockTitle } = this.state;
            const imageInfo = this.props.imageData[0];


            if (!activeBlock) {
                return (
                    <ul className="b-full-menu__images">
                        {this.props.listImages && this.props.listImages.map((item, index) => {
                            return (
                                <li className="b-full-menu__image-item" key={index}>
                                    <a href={item.link}>
                                        {item.token && (
                                            <AdvertisingLabeling
                                                token={item.token}
                                                advertiser={item.advertiser}
                                            />
                                        )}
                                        <img src={mediaUrl + item.imagePath} alt="Description" className="b-full-menu__image" />
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                );
            }

            const numberOfCategories = activeBlock ? activeBlock.length : 0;
            const holderClass = numberOfCategories > 1 ? "b-full-menu__second-holder" : "b-full-menu__second-holder";

            return (
                <div className={holderClass}>
                    <div className="b-full-menu__text-content">
                        <h2 className="b-full-menu__block-title mb-8">{activeBlockTitle}</h2>
                        <div className="b-full-menu__block-grid">
                            <div className={`b-full-menu__block-category b-full-menu__scrollable_container`}>
                                {activeBlock.map((category, categoryIdx) => {
                                    return (
                                        <div className="b-full-menu__category" key={categoryIdx}>
                                            <LinkC
                                                className="b-full-menu__category-title"
                                                to={category.link}
                                            >
                                                {category.title}
                                            </LinkC>
                                            <ul className="b-full-menu__second-level">
                                                {category.items.map((item, itemIdx) => {
                                                    return (
                                                        <li className="b-full-menu__second-item" key={itemIdx}>
                                                            <LinkC
                                                                className="b-full-menu__second-link"
                                                                to={(this.context.isLk && item.link.indexOf('http') !== 0 ? mainUrl + this.context.selectedRegion.alias : "") + item.link}
                                                                targetBlank={item.new_tab}
                                                            >
                                                                {item.title}
                                                            </LinkC>
                                                        </li>
                                                    )
                                                }
                                                )}
                                            </ul>
                                        </div>
                                    )
                                })}
                            </div>

                            {imageInfo && imageInfo.imagePath && (
                                <div className={`b-full-menu__image-holder ${numberOfCategories > 1 ? 'b-full-menu__image-holder-hidden-image' : ''}`}>
                                    <a href={imageInfo.link}>
                                        {imageInfo.token && (
                                            <AdvertisingLabeling
                                                token={imageInfo.token}
                                                advertiser={imageInfo.advertiser}
                                            />
                                        )}
                                        <img
                                            src={mediaUrl + imageInfo.imagePath}
                                            alt={activeBlockTitle}
                                            className="b-full-menu__second-level-image"
                                        />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        };

        const mainBlock = () => {
            return (
                <div>
                    <div className="b-header__full-menu-mobile b-header__full-menu">
                        <div className="b-full-menu b-full-menu_adapt_default">
                            <div className="b-full-menu__holder">
                                <ul className="b-full-menu__level">
                                    {megaMenuObj[0].data.flatMap(section => section).map((infoblock, idx) => {
                                        return (
                                            <div className={"b-full-menu__col"} key={idx}>
                                                <li
                                                    onClick={() => toggleCollapse(infoblock.categories.length > 0 ? { col: idx + 1, block: infoblock.infoblock_id } : { col: -1, block: -1 })}
                                                    className={"b-full-menu__item" + (infoblock.categories ? " b-full-menu__item_contain" : "") + (activeIdx.block === infoblock.infoblock_id && activeIdx.col === idx + 1 ? " b-full-menu__item_open" : "")}
                                                    key={idx}
                                                >
                                                    <div className="b-full-menu__link-holder">
                                                        {(
                                                            <LinkC
                                                                className={"b-full-menu__link"}
                                                                to={(this.context.isLk && infoblock.categories[0].items[0].link.indexOf('http') !== 0 ? mainUrl + this.context.selectedRegion.alias : "") + (infoblock && infoblock.categories && infoblock.categories[0] && infoblock.categories[0].items[0] ? infoblock.categories[0].items[0].link : '')}
                                                                targetBlank={infoblock.categories ? infoblock.categories[0].items[0].new_tab : false}
                                                            >
                                                                <span className="b-full-menu__text">{infoblock.title}</span>
                                                            </LinkC>
                                                        )}
                                                    </div>

                                                    {infoblock.categories && (
                                                        <div className="b-full-menu__second-holder">
                                                            <ul className="b-full-menu__second-level">
                                                                {infoblock.categories.map((item, idx1) => {
                                                                    return (
                                                                        item.items.map((itemElement, itemElementIdx) => {
                                                                            return (
                                                                                // itemElementIdx > 0 && (
                                                                                    <li className="b-full-menu__second-item" key={itemElementIdx}>
                                                                                        <div className="b-full-menu__second-link-holder">
                                                                                            <LinkC
                                                                                                className={"b-full-menu__second-link"}
                                                                                                to={(this.context.isLk && itemElement.link.indexOf('http') !== 0 ? mainUrl + this.context.selectedRegion.alias : "") + itemElement.link}
                                                                                                targetBlank={itemElement.new_tab}
                                                                                            >
                                                                                                <span className="b-full-menu__second-text">{itemElement.title}</span>
                                                                                            </LinkC>
                                                                                        </div>
                                                                                    </li>
                                                                                // )
                                                                            )
                                                                        })
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            </div>
                                        )
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="b-header__full-menu-desktop b-full-menu__holder">
                        {leftSideOpennedMenu()}
                        {rightSideOpennedMenu()}
                    </div>
                </div>
            )
        }
        const servicesBlock = () => {
            return (
                <div className="b-header__services">
                    {megaMenuObj[0].bottomMenu.map((item, idx) => (
                        <div className="b-header__service" key={idx}>
                            {item.link.indexOf('http') > -1
                                ? (
                                    <a
                                        className="b-header__service-link"
                                        href={item.link}
                                        target={item.new_tab && "_blank"}
                                    >
                                        <img src={mediaUrl + item.img} className="b-header__service-icon u-icon u-icon_chat" alt="chat pic" />
                                        <span className="b-header__service-text">{item.title}</span>
                                    </a>
                                ) : (
                                    <a
                                        className="b-header__service-link" href={(this.context.isLk ? mainUrl : "/") + regionAlias + item.link}
                                        target={item.new_tab ? "_blank" : ""}
                                    >
                                        <img src={mediaUrl + item.img} className="b-header__service-icon u-icon u-icon_chat" alt="chat pic" />
                                        <span className="b-header__service-text">{item.title}</span>
                                    </a>
                                )
                            }
                        </div>
                    ))}
                </div>
            )
        }
        const searchBlock = () => {
            return (
                <ul className="b-additional-menu gap-16">
                    <li
                        className="b-short-menu__level-label-menu"
                        onClick={() => {
                            this.props.openMenu();
                            this.setState({
                                activeBlock: null,
                                activeBlockTitle: '',
                                activeInfoblockId: null
                            });
                        }}
                    >
                        <div className="b-header__burger-btn flex items-center gap-2">
                            <p className="b-header__burger-btn-label m-0">
                                <Icon name={'icon_menu-burger'} className={'b-header__burger u-icon u-icon_menu-burger'} />
                                <Icon name={'icon_cross'} className={'b-header__close-icon u-icon u-icon_cross'} />
                                Меню
                            </p>
                        </div>
                    </li>

                    <li className='page__header__b2b__wrap_search_input w-full'>
                        <SearchInput refSearch={this.refSearch} />
                    </li>
                </ul>
            )
        }
        const b2bMenu = () => {
            return (
                <div className="b-header__account-settings">
                    <div className="b-settings-menu b-settings-menu_adapt_default js-init">
                        <div className="b-settings-menu__holder">
                            <div className="b-settings-menu__items">
                                {mainMenu.map((item) => (
                                    <div key={item.link} className="b-settings-menu__item">
                                        <LinkC
                                            className="b-settings-menu__link"
                                            to={item.link}
                                            func={item.func ? item.func : null}
                                            targetBlank={item.new_tab === "1"}
                                        >{item.title}</LinkC>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const b2cMenu = () => {
            return (
                <div className="b-header__account-settings b-header__btc-account-settings">
                    <div className="b-settings-menu b-settings-menu_adapt_default js-init">
                        <div className="b-settings-menu__holder">
                            <div className={"b-settings-menu__title" + (activeIdx.col === 0 ? " b-settings-menu__title_open" : "")}>
                                <div className="b-settings-menu__title-text" onClick={() => toggleCollapse({ col: 0, block: 0 })}>Настройки&nbsp;
                                    <span className="b-settings-menu__mobile-hide">моего</span> аккаунта
                                </div>
                            </div>
                            <div className="b-settings-menu__items">
                                {!regionSlug && (
                                    newLkMenu.map((item) =>
                                        <div className="b-settings-menu__item">
                                            <LinkC
                                                className="b-settings-menu__link"
                                                key={item.link}
                                                to={(!this.context.isLk && item.link.indexOf('http') !== 0 ?
                                                    (item.alias === 'currentTariff' ? '' : lkUrl + regionAlias) : "") + item.link}>
                                                {item.title}
                                            </LinkC>
                                        </div>
                                    )
                                )}
                                {regionSlug && (
                                    newLkMenu.map((item) =>
                                        <div className="b-settings-menu__item">
                                            <a
                                                className="b-settings-menu__link"
                                                key={item.link}
                                                href={(!this.context.isLk && item.link.indexOf('http') !== 0 ?
                                                    (item.alias === 'currentTariff' ? '' : lkUrl + regionAlias) : "") + item.link}>
                                                {item.title}
                                            </a>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div >
                </div >
            )
        }
        const guestMenu = () => {
            console.log('null');
            
            return null
        }
        return (
            <Fragment>
                <div className={`b-header__burger-holder u-fixpos ${isB2b ? ' b-header__burger-holder__b2b' : ''} ${!isGuest ? 'is_auth_container' : ''}`}>
                    <div className="page__container">
                        <div className={"b-header__main-menu-wrap"}>
                            <div className={"b-header__main-menu"}>
                                <LinkC to={isB2b ? mainUrl : "/"} className="b-header__main-menu-item" targetBlank={isB2b}>
                                    <span className={"b-header-mega-menu__item-text" + (!isB2b ? " active" : "")}>Частным клиентам</span>
                                </LinkC>
                                <LinkC to={isB2b ? "/" : b2bUrl} className="b-header__main-menu-item" targetBlank={!isB2b}>
                                    <span className={"b-header-mega-menu__item-text" + (isB2b ? " active" : "")}>Бизнесу</span>
                                </LinkC>
                                <a href="https://shop.motivtelecom.ru/" target="_blank" className={"b-header__main-menu-item"}>
                                    <span className={"b-header-mega-menu__item-text"}>Интернет-магазин</span>
                                </a>
                            </div>
                        </div>

                        <div className='menu__wrap_search_input'>
                            <SearchInput
                                toggleSearchInputAction={this.props.toggleSearchInputAction}
                            />
                        </div>

                        {isB2b ? (
                            b2bMenu()
                        ) : (
                            <Fragment>
                                <div className="b-header__full-menu montserrat-font">
                                    {searchBlock()}
                                    {isGuest ? guestMenu() : b2cMenu()}
                                    <div className="b-full-mega-menu">
                                        {servicesBlock()}
                                        {mainBlock()}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default connect(
    state => ({
        megaMenu: state.megaMenu,
        lkMenu: state.client.lkMenu,
        client: state.client,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || '',
        listImages: state.infoBlocks.listImages,
        imageData: state.infoBlocks.imageData
    }),
    dispatch => {
        return {
            toggleSearchInputAction: (data) => dispatch(toggleSearchInputAction(data)),
            infoBlocksImagesList: () => dispatch(infoBlocksImagesList()),
            infoBlockImage: (id) => dispatch(infoBlockImage(id)),
        }
    }
)(HeaderMegaMenu);