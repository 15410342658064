import React, { Component } from 'react';

import Slider from 'react-slick';
import {mediaUrl} from "../../tools/config";
import Icon from '../common/Icon';
import InfoblockLink from "../InfoBlocks/InfoblockLink";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="rates-slider-arrow slider-fullpage-arrow slider-fullpage-arrow--next"
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="rates-slider-arrow slider-fullpage-arrow slider-fullpage-arrow--prev"
      onClick={onClick}
    />
  );
}


class RatesSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.props.blocks.length > 1 ? 2 : 1,
            slidesToScroll: this.props.blocks.length > 1 ? 2 : 1,
            swipeToSlide: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            draggable: false,
            responsive: [
              {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
              }
            ]
        };

        let blocks = this.props.blocks;

        return (
            <Slider ref={c => (this.slider = c)} {...settings}>
                {blocks.map((action, index) =>
                    <div className={this.props.blocks.length > 1 ? "rates-slider-slide" : "rates-slider-slide rates-slider-slide--one"} key={index}>
                      <AdvertisingLabeling
                        token={action && action.token}
                        advertiser={action && action.advertiser}
                      />
                        <Icon name={'icon_banner'} className={'rates-slider__icon-banner'}/>
                        <img className="rates-slider__img" src={mediaUrl + action.imagePath} alt={action.title} />
                        <div className="rates-slider-slide__content">
                            <h3 className="rates-slider-slide__title">{action.title}</h3>
                            <p className="rates-slider-slide__text">{action.text}</p>
                            <InfoblockLink className={"rates-slider-slide__link"} item={action}>
                                Подробнее
                                <Icon name={'icon_slider-arrow'} className={'u-icon rates-slider-slide__arrow'}/>
                            </InfoblockLink>
                        </div>
                    </div>
                )}
            </Slider>
        )
      //   return (
      //     <div>
      //         {blocks.map((action, index) =>
      //           <div>
      //             <img src={mediaUrl + action.imagePath} alt={action.title} />
      //           </div>
      //         )}
      //     </div>
      // )
    }
}


export default RatesSlider;