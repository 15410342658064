import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import LinkC from "../common/LinkC";
import InfoblockLink from "../InfoBlocks/InfoblockLink";

class RatesOneQuarterBanner extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    render() {

        let selectedRegion = this.context.selectedRegion;
        let block = this.props.blocks[0];

        return (
            <div className="b-promo-invite__spec-offer-block">
                <div
                    className="b-invite-offer b-invite-offer b-invite-offer_inherit-height b-invite-offer b-invite-offer_adapt_default">
                    <div className="b-invite-offer__wrapper">
                        <div className="b-invite-offer__inner">
                            <div className="b-invite-offer__title" dangerouslySetInnerHTML={{__html: block.title}} />
                            <div className="b-invite-offer__text">{block.text}</div>
                            <LinkC className="b-invite-offer__arrow-link" to={block.link}/>
                        </div>
                        <div className="b-invite-offer__link-block">
                            <InfoblockLink className={"b-invite-offer__link"} item={block}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesOneQuarterBanner;


