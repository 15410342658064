import React, { Component } from "react";

export default class Checkbox extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {

        let {
            currentSmsPack,
            type
        } = this.props;

        return (
            <div
                className={`switcher-wrap ${currentSmsPack === type ? "checked" : ''}`}
                onClick={() => {
                    if (currentSmsPack === type) {
                        this.props.toggleSmsPack('SMS_PACK_0')
                    } else {
                        this.props.toggleSmsPack(type)
                    }
                }}
            >
                <div className="switcher-input">
                    <div className="checkbox-input"></div>
                    <label className="switcher-input__label" htmlFor={type} />
                </div>
            </div>
        )
    }
}
