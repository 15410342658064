let initState = {inProgress: false, data: {}, error: false, verified: false};

export function sms (state = initState, action) {
    switch (action.type) {
        case "SMS":
            return {...state, inProgress: true};
        case "SMS_SUCCESS":
            return {...state, inProgress: false, data: action.data};
        case 'SMS_BAD_REQUEST':
        case 'SMS_LOGOUT':
        case 'SMS_FORBIDDEN':
        case 'SMS_NOT_FOUND':
        case 'SMS_ERROR':
            return {...state, inProgress: false, data: action.data, error: true};
        case "VERIFY_SMS":
            return {...state, inProgress: true};
        case "VERIFY_SMS_SUCCESS":
            return {...state, inProgress: false, data: action.data, verified: true};
        case 'VERIFY_SMS_BAD_REQUEST':
        case 'VERIFY_SMS_LOGOUT':
        case 'VERIFY_SMS_FORBIDDEN':
        case 'VERIFY_SMS_NOT_FOUND':
        case 'VERIFY_SMS_ERROR':
            return {...state, inProgress: false, data: action.data, error: true};
        case 'CLEAR_SMS_DATA':
            return {...state, isOk: false, data: {}, verified: false, error: false};
    }
    return state;
}