import React, { Component } from 'react';
import {connect} from "react-redux";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { routesList } from "../../actions/routes";
import {
    stateSearchInputAction,
    searchPageAction,
    clearSearchPageAction,
    popularQueriesAction,
    clearSearchPagePaginationAction,
    searchResultPaginationAction,
    clearSearchResultPaginationAction,
    listFiltersAction,
    currentFilterAction,
    searchHintAction,
    clearSearchHintAction,
    clearListFiltersAction,
} from '../../actions/searchAction';
import BigSearchInput from '../../components/common/BigSearchInput';
import SearchResult from '../../components/common/SearchResult';
import {
    deleteQueryParam,
    getQueryParam,
    setQueryParam,
    findTwoSimbols,
} from '../../tools/tools';

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            dropDown: false,
            secondDropDown: false,
        };

        this.refDropDown = React.createRef();
        this.refBigSearch = React.createRef();
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isB2b: PropTypes.bool
    };

    // для ssr
    componentWillMount() {
        this.firstLoadPage();
    }

    componentDidMount(){
        this.firstLoadPage();

        const currentWebsite = this.state.activeTab === 0 ? 'b2c' : 'b2b';
        const param = getQueryParam('q');

        // загружаем результат поиска при первой загрузке страницы при условии,
        // что в адресной строке есть исключение из списка или квери параметр
        if ((param && findTwoSimbols(param)) || (param && param.length > 2)) {
            this.props.stateSearchInputAction(param);
            this.props.searchPageAction(currentWebsite, param, this.context.selectedRegion.id);
            this.props.listFiltersAction(currentWebsite, param, this.context.selectedRegion.id);
            this.props.searchHintAction(currentWebsite, param, this.context.selectedRegion.id);
        }

        // вешаем обработчик события на body для handleOutsideClick
        document.body.addEventListener('click', this.handleOutsideClick);
    }

    componentDidUpdate(prevProps) {

        const currentWebsite = this.state.activeTab === 0 ? 'b2c' : 'b2b';
        const param = getQueryParam('q');

        // когда пользователь авториовался или разавторизовался запрашиваем запросы на поис еще раз
        if (this.props.client.isGuest !== prevProps.client.isGuest) {
            this.firstLoadPage();

            this.props.searchPageAction(currentWebsite, param, this.context.selectedRegion.id);
            this.props.searchHintAction(currentWebsite, param, this.context.selectedRegion.id);
        }
    }


    // выполняется при первой загрузке страницы
    firstLoadPage = () => {
        // очистка результатов
        this.props.clearSearchPageAction();
        this.props.clearSearchPagePaginationAction();
        this.props.clearSearchResultPaginationAction();
        this.props.clearListFiltersAction();

        // если фильров больше одного, то выбираем первый (ВСЕ)
        if (this.props.stateListFilters && this.props.stateListFilters.length > 1) {
            this.props.currentFilterAction('');
        }

        // подгружаем популярные запросы
        const website = !this.context.isB2b ? 'b2c' : 'b2b';
        this.props.popularQueriesAction(website, this.context.selectedRegion.id);

        // в зависимости от того где находимся(на b2c или b2b), активируем нужный таб
        const activeWebsite = !this.context.isB2b ? 0 : 1;
        this.setState({ activeTab: activeWebsite })
    }

    // переключение табов
    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: val
                }
            })

            const {
                stateSearchInput,
            } = this.props;

            // очистка результатов
            this.props.clearSearchPageAction();
            this.props.clearSearchResultPaginationAction();

            // запускаем поиск когда введено три и более символа
            const website = val === 0 ? 'b2c' : 'b2b';
            if ((stateSearchInput && findTwoSimbols(stateSearchInput)) || (stateSearchInput && stateSearchInput.length > 2)) {
                this.props.searchPageAction(website, stateSearchInput, this.context.selectedRegion.id, 1);
                this.props.listFiltersAction(website, stateSearchInput, this.context.selectedRegion.id);
            }

        }
    };

    // по клику на на оранжевое слово с точками под ним
    onClickItem = (e) => {
        // очистка результатов
        this.props.clearSearchPageAction();
        this.props.clearSearchResultPaginationAction();
        this.props.clearSearchHintAction();

        // записываем значение в инпут
        this.props.stateSearchInputAction(e);

        // записываем в адресную строку квери параметр
        setQueryParam('q', e);

        // осуществляем поиск
        const website = this.state.activeTab === 0 ? 'b2c' : 'b2b';
        this.props.searchPageAction(website, e, this.context.selectedRegion.id);
        this.props.listFiltersAction(website, e, this.context.selectedRegion.id);   

        this.setState({ secondDropDown: false });
    }

    // изменение состояние большого инпута поиска
    onChangeBigInput = (e) => {
        this.props.stateSearchInputAction(e);

        if ((e && findTwoSimbols(e)) || (e.length > 2)) {
            const website = !this.context.isB2b ? 'b2c' : 'b2b';
            this.props.searchHintAction(website, e, this.context.selectedRegion.id);

            this.setState({ dropDown: true });
            this.setState({ secondDropDown: true });
        }

        if ((e && findTwoSimbols(e)) ? e.length < 2 : e.length < 3) {
            this.props.clearSearchHintAction();

            this.setState({ dropDown: false });

            this.props.clearSearchPagePaginationAction();
            this.props.clearListFiltersAction();
            this.props.clearSearchPageAction();
        }
    }

    // по клику на иконку поиска
    onSearchBigInput = () => {
        const {
            stateSearchInput,
            stateCurrentFilter,
        } = this.props;

        // очистка результатов
        this.props.clearSearchPageAction();
        this.props.clearSearchResultPaginationAction();
        
        const website = this.state.activeTab === 0 ? 'b2c' : 'b2b';
        if ((stateSearchInput && findTwoSimbols(stateSearchInput)) || (stateSearchInput && stateSearchInput.length > 2)) {
            this.props.searchPageAction(website, stateSearchInput, this.context.selectedRegion.id, '', stateCurrentFilter);
            this.props.listFiltersAction(website, stateSearchInput, this.context.selectedRegion.id);

            // записываем в адресную строку квери параметр
            setQueryParam('q', stateSearchInput);
        }

        this.setState({ secondDropDown: false });
    }

    // по клику на крестик
    onCancelBigInput = () => {
        this.props.stateSearchInputAction('');
        this.props.clearSearchPageAction();
        this.props.clearSearchPagePaginationAction();
        this.props.clearSearchResultPaginationAction();
        this.props.clearListFiltersAction();
        deleteQueryParam('q');

        // устанавливаем курсор в поле поиска
        setTimeout(() => {
            this.refBigSearch.current.focus();
        }, 200);
    }

    // клик по инпуту поиска
    сlickToInput = () => {
        const {
            stateSearchInput,
        } = this.props;

        if ((stateSearchInput && findTwoSimbols(stateSearchInput)) || (stateSearchInput && stateSearchInput.length > 2)) {
            this.setState({ secondDropDown: true });
            this.setState({ dropDown: true });
    
            const website = this.state.activeTab === 0 ? 'b2c' : 'b2b';
            this.props.searchHintAction(website, stateSearchInput, this.context.selectedRegion.id);
        }
    };

    // закрывает всплывашку поиска когда кликаем в не ее зоны
    handleOutsideClick = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        // includes --- ищет указанный элемет в массиве, возвращает true или false
        if (!path.includes(this.refDropDown)) {
            this.setState({ secondDropDown: false });
        }
    };


    render() {
        let {
            activeTab,
            dropDown,
            secondDropDown,
        } = this.state;

        let {
            client,
            stateSearchPage,
            stateSearchPagePagination,
            statePopularQueries,
            stateSearchInput,
            stateSearchResultPagination,
            searchPageAction,
            clearSearchPageAction,
            clearSearchResultPaginationAction,
            stateListFilters,
            listFiltersAction,
            currentFilterAction,
            stateCurrentFilter,
            stateSearchHint,
        } = this.props;


        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'legalInformationRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);


        return (
            <div className="page__container info-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="search__content">
                    <div className="search__wrap_big_search">
                        <BigSearchInput
                            stateSearchInput={stateSearchInput}
                            onChangeBigInput={this.onChangeBigInput}
                            onSearchBigInput={this.onSearchBigInput}
                            onCancelBigInput={this.onCancelBigInput}
                            сlickToInput={this.сlickToInput}
                            stateSearchHint={stateSearchHint}
                            onClickItem={this.onClickItem}
                            dropDown={dropDown}
                            secondDropDown={secondDropDown}
                            refDropDown={this.refDropDown}
                            refBigSearch={this.refBigSearch}
                        />
                    </div>

                    {statePopularQueries && statePopularQueries.length > 0
                        && (
                            <div className="search__wrap_popular_queries">
                                <div className="search__popular_queries">
                                    {statePopularQueries && statePopularQueries.map((item, index) => (
                                        <div
                                            className="search__item_popular_queries"
                                            key={index}
                                            onClick={(e) => this.onClickItem(e.target.textContent)}
                                        >
                                            {item.title}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    
                    <div className="search__wrap_tabs">
                        <div className="search__wrap_tabs_switcher">
                            <TabsSwitcher
                                items={[
                                    {title: "Частным клиентам"},
                                    {title: "Бизнесу"},
                                ]}
                                onClickFunc={this.onToggleTab}
                                activeTab={activeTab}
                            />
                        </div>
                    </div>

                    <ul className="tabs__content search__wrap_tabs_content" ref="content">
                        <div className="search__wrap_search_result">
                            <SearchResult
                                stateSearchPage={stateSearchPage}
                                stateSearchPagePagination={stateSearchPagePagination}
                                stateSearchInput={stateSearchInput}
                                searchResultPaginationAction={this.props.searchResultPaginationAction}
                                activeTab={activeTab}
                                searchPageAction={searchPageAction}
                                stateSearchResultPagination={stateSearchResultPagination}
                                clearSearchPageAction={clearSearchPageAction}
                                clearSearchResultPaginationAction={clearSearchResultPaginationAction}
                                stateListFilters={stateListFilters}
                                listFiltersAction={listFiltersAction}
                                currentFilterAction={currentFilterAction}
                                stateCurrentFilter={stateCurrentFilter}
                            />
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        regions: state.regions,
        client: state.client,
        stateSearchInput: state.searchReducer.stateSearchInput,
        stateSearchPage: state.searchReducer.stateSearchPage,
        stateSearchPagePagination: state.searchReducer.stateSearchPagePagination,
        statePopularQueries: state.searchReducer.statePopularQueries,
        stateSearchResultPagination: state.searchReducer.stateSearchResultPagination,
        stateListFilters: state.searchReducer.stateListFilters,
        stateCurrentFilter: state.searchReducer.stateCurrentFilter,
        stateSearchHint: state.searchReducer.stateSearchHint,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            stateSearchInputAction: (data) => dispatch(stateSearchInputAction(data)),
            searchPageAction: (businessLine, term, regionId, pageNum, entity) => dispatch(searchPageAction(businessLine, term, regionId, pageNum, entity)),
            clearSearchPageAction: () => dispatch(clearSearchPageAction()),
            clearSearchPagePaginationAction: () => dispatch(clearSearchPagePaginationAction()),
            popularQueriesAction: (businessLine, regionId) => dispatch(popularQueriesAction(businessLine, regionId)),
            searchResultPaginationAction: () => dispatch(searchResultPaginationAction()),
            clearSearchResultPaginationAction: () => dispatch(clearSearchResultPaginationAction()),
            currentFilterAction: (data) => dispatch(currentFilterAction(data)),
            listFiltersAction: (businessLine, term, regionId) => dispatch(listFiltersAction(businessLine, term, regionId)),
            searchHintAction: (businessLine, term, regionId) => dispatch(searchHintAction(businessLine, term, regionId)),
            clearSearchHintAction: () => dispatch(clearSearchHintAction()),
            clearListFiltersAction: () => dispatch(clearListFiltersAction()),
        }
    }
)(Search);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const SearchRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    // {
    //     func: 'getDocsAbout',
    //     args: ['b2c'],
    //     object: 'company.docsAbout',
    //     checkField: null,
    //     main: false,
    //     withRegion: true,
    //     objectReceived: false
    // }
];
