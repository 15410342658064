import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import DayPicker from 'react-day-picker';

export default class DataPickerDay extends Component {

    constructor(props) {
        super(props);
    };

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    render() {

        const {
            handleDayClick,
            from,
            to,
            showCalendar,
            getApply,
            filterReset
        } = this.props;

        const modifiers = {
            start: from,
            end: to,
            highlighted: new Date(),
        };

        const MONTHS = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ];

        const WEEKDAYS_LONG = [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
        ];

        const WEEKDAYS_SHORT = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

        return ( !!showCalendar ?
            <Fragment>
                <div className="day-picker__wrap">
                    <DayPicker
                        className="media-date-picker Selectable"
                        showOutsideDays
                        selectedDays={ [ from, { from, to }] }
                        modifiers={ modifiers }
                        onDayClick={ handleDayClick }
                        months={ MONTHS }
                        weekdaysLong={ WEEKDAYS_LONG }
                        weekdaysShort={ WEEKDAYS_SHORT }
                        firstDayOfWeek={ 1 }
                        initialMonth={from}
                    />
                    <div className="media-date-picker__triangle"/>
                    <div className="media-date-picker-btn" onClick={ getApply }>Применить</div>
                    <div className="media-date-picker-btn filter-reset-btn" onClick={ filterReset }>Сбросить фильтр</div>
                </div>
            <div className="lock-news-page"/> </Fragment>: null
        )
    }
}
