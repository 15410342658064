import React, { Component } from 'react';
import PropTypes from "prop-types";

class RatesInternetTextBanner extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    render() {

        let block = this.props.blocks[0];

        return (
            <div className="b-simple-advantages__text-block">
                <div className="b-simple-advantages__paragraph">{block.title}</div>
                <div className="b-simple-advantages__paragraph">{block.text}</div>
            </div>
        )
    }
}

export default RatesInternetTextBanner;

