import React, { Component } from 'react';
import Icon from "../common/Icon";
import RatesPriceList from "../Rates/RatesPriceList";

export default class ChangeRateConfirmation extends Component {

    constructor(props){
        super(props);
        this.state = {
            disabledBtn: false
        }
    }

    componentWillMount() {
        if ( this.state.disabledBtn === true ) {
            this.setState({disabledBtn: false})
        }
    };

    disabledBtn =()=> {
        if (!this.state.disabledBtn) {
            this.setState({disabledBtn: true})
        }
    };

    render() {

        let {
            onClick = () => {},
            onCancel = () => {},
            item,
            notice,
            type
        } = this.props;

        let { disabledBtn } = this.state;

        let title = "";

        if (type === 'tariff') {
            title = "Перейти на тариф «" + item.name + "»";
        }

        if (type === 'service') {
            title = (item.isOn ? "Отключение" : "Подключение")+" услуги «" + item.name + "»";
        }

        return (
            <div className="swiper-container mnp-popup__wrapper">
                <div>
                    <div className="b-plug-auth__title">{title}</div>
                    {notice ? <div className="modal-notice">
                        {type === 'tariff' ? <Icon name="icon_i-orange"/> : null}
                        {notice}
                    </div> : null}
                    <RatesPriceList blocks={item.info} />
                    <div className="modal-buttons-right">
                        <button type="button" className={"u-btn u-btn_transparent"+(type === 'service' ? "-grey" : "")+" u-btn_adapt_mobile-sm"} onClick={onCancel}>Отмена</button>
                        <button type="button" className={"u-btn u-btn_default u-btn_adapt_mobile-sm"+(disabledBtn === false ? "" : " disabled")} onClick={() => {onClick(); this.disabledBtn();}}>{item && item.isOn ? "Отключить" : "Подключить"}</button>
                    </div>
                </div>
            </div>
        )
    }
}