import {Component} from "react";
import React from "react";
import TabsSwitcher from "./TabsSwitcher";
import Icon from "./Icon";

export default class OfficeCard extends Component{

    constructor(props){
        super(props);

        this.state = {
            activeTab: 0,
        };
    }

    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: val
                }
            })
        }
    };

    onSubmit = (e) => {
        if(this.props.isMnpOffices) {
            e.preventDefault();
            this.props.onSubmit(this.props.item)
        } else {
            if (!this.props.inProgress) {
                e.preventDefault();
                this.props.onSubmit({
                    shop_address: this.props.item.address,
                    shop_city: this.props.item.city.name,
                    form_name: 'Шаг№4.1“Офис продаж”'
                }, 'choose_shop');
            }
        }
    };

    handlerRouteBtn = () => {
        if (!!this.props.userCoordinates.length) {
            window.open(
                `https://yandex.ru/maps/?rtext=${this.props.userCoordinates}~${this.props.item.coordinates}&rtt=auto`,
                '_blank'
            );
        } else {
            window.open(
                `https://yandex.ru/maps/?rtext=~${this.props.item.coordinates}&rtt=auto`,
                '_blank'
            );
        }
    };

    render(){

        let {
            closeCardFunc,
            item,
            inProgress,
            isMnpOffices,
        } = this.props;

        return (
            <div className="b-map__info-block">
                <div className="b-map__salon-info">
                    <div className="b-salon-info b-salon-info_adapt_default">
                        <div className="b-salon-info__wrapper">
                            <div className="b-salon-info__header">
                                <a onClick={closeCardFunc} className="b-salon-info__close-btn u-cross u-cross u-cross_color_white" href="#" />
                                <div className="b-salon-info__address">{item.address}</div>
                                <div className="b-salon-info__tabs-header">
                                    <TabsSwitcher
                                        items={[{title: "Описание"}, {title: "Услуги"}]}
                                        onClickFunc={this.onToggleTab}
                                        activeTab={this.state.activeTab}
                                        itemHasWrap={true}
                                        className={"b-tabs-header_theme_salon-info"}
                                    />
                                </div>
                            </div>
                            <div className="b-salon-info__body">
                                <div className="b-salon-info__scroll-holder js-custom-scrolls">
                                    {
                                        this.state.activeTab === 0
                                            ?   <React.Fragment>
                                                    <div className="b-salon-info__info-block">
                                                        <div className="b-salon-info__info-item">
                                                            <div className="b-salon-info__info-term">Время работы:</div>
                                                            <div className="b-salon-info__info-definition" dangerouslySetInnerHTML={{__html: item.working_time}} />
                                                        </div>
                                                        <div className="b-salon-info__info-item b-salon-info__info-item_phone">
                                                            <div className="b-salon-info__info-term">Телефон:</div>
                                                            <div className="b-salon-info__info-definition" dangerouslySetInnerHTML={{__html: item.phone}} />
                                                        </div>
                                                    </div>
                                                    {
                                                        isMnpOffices && (
                                                            <a className="b-salon-info__route-btn mnp__map__office-choose__btn" href="#" onClick={this.onSubmit}>Выбрать офис</a>
                                                        )
                                                    }
                                                    { (!isMnpOffices && this.props.onSubmit)
                                                        ? <a id={"mnp-delivery-office-choice-button"} className={"b-salon-info__choose-btn" + (inProgress ? " disabled" : "")} href="#" onClick={this.onSubmit}>Выбрать салон</a>
                                                        : null
                                                    }
                                                    <a className={"b-salon-info__route-btn u-btn u-btn_transparent" + (inProgress ? " disabled" : "")}
                                                       onClick={this.handlerRouteBtn}
                                                    >
                                                        <svg className="b-salon-info__route-btn-icon" id='_1' data-name='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.83 21.67'>
                                                            <path fill-rule='evenodd' fill='#5f6164' d='M21.76,12l-4.17,4.85L13.42,12l1.51-1.31,1.75,2v-7a2.91,2.91,0,1,0-5.81,0v10a4.87,4.87,0,1,1-9.74,0V11.88h2v3.87a2.87,2.87,0,1,0,5.74,0v-10a4.91,4.91,0,1,1,9.81,0v6.78l1.56-1.82ZM3.22,8.5H1v2.22H3.22Z'
                                                            />
                                                        </svg>
                                                        Построить маршрут
                                                    </a>

                                                </React.Fragment>
                                            :  null //<div />
                                    }

                                    {
                                        this.state.activeTab === 1
                                            ?
                                            <ul className="b-salon-info__list-services">
                                                {
                                                    item.shopServices.map((service, key) =>
                                                        <li key={key} className="b-salon-info__list-services-item">
                                                            { service.title }
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}