import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {axisValues, banner, price, settings, getTariffConstructor} from "../../actions/constructor";
import LinkC from "./../../components/common/LinkC";
import {faqList} from "../../actions/questions";
import {isEmptyObj} from "../../tools/tools";

class TariffConstructorAbout extends Component {

    // код не задействуется, добавил getTariffConstructor в import
    componentWillMount() {
        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }         
        if (isEmptyObj(this.props.tariffConstructor.defaultData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }
        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(6);
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        pushHistory: PropTypes.func,
    };

    render() {

        let selectedRegion = this.context.selectedRegion;
        let setting = this.props.settings;

        return (
            <div className="about-page-wrapper">
                <div className={"about-page"}>
                    <LinkC className="about-link_back" to={"/tariffs#constructor"}>
                        <span className={"about-link_arrow"}> </span>ОФОРМЛЕНИЕ
                    </LinkC>
                    <h2 className="about-title">{setting && setting.page_title}</h2>
                    <div className="about-content">
                        <div dangerouslySetInnerHTML={{__html: setting && setting.text}} />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        settings: state.constructor.settings,
        regions: state.regions,
        axis: state.constructor.axis,
        faq: state.faq,
        tariffConstructor: state.tariffConstructor
    }),
    (dispatch) => {
        return {
            getSettings: (regionId) => dispatch(settings(regionId)),
            getFaq : (categoryId) => dispatch(faqList(categoryId)),
            getTariffConstructor: (regionId) => dispatch(getTariffConstructor(regionId)),
        }
    }
)(TariffConstructorAbout);