import {
    call,
    put,
    take,
    select
} from 'redux-saga/effects';

import {
    fetchClientLkApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {
    setCookie,
    getCookie,
    deleteCookie, deleteQueryParam
} from '../tools/tools';
import {cookieDomain, mainUrl} from "../tools/config";

import {servicesView, servicesList} from './services'
const servicesListsState = state => state.services.servicesLists;
const myServicesListsState = state => state.client.services;
const regionsState = state => state.regions.list;
const formsSettingsState = state => state.client.formsSettings;
const settingsState = state => state.client.settings;

export function checkToken() {
    return {
        type: "CHECK_TOKEN"
    }
}

export function* fetchCheckToken(action) {
    let refresh = getCookie("refresh_token"), expires_in = getCookie("expires_in");

    if ((new Date(expires_in)).getTime() + 10000 < (new Date()).getTime()) {
        // const {status, data, error} = yield call(() => {
        //     return fetchClientLkApi("/v1/login/refresh", {
        //         method: 'POST',
        //         body: JSON.stringify({refresh_token: refresh})
        //     })
        // });
        let formData = new FormData();
        formData.append('refresh_token', refresh);
        const {status, data, error} = yield call(() => {
            return fetchClientApi("/blitz/refresh", {
                method: 'POST',
                body: formData,
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            if (status === 200) {
                for (var key in data) {
                    let options = action.remember ? {} : {expires: 30*24*3600, path: "/"};
                    if (cookieDomain) {
                        options.domain = cookieDomain
                    }
                    setCookie(key, data[key], options);
                }
            }

            yield put(checkErrors(action.type, data, status));
        }
    }

    yield put({type: "TOKEN_CHECKED"})
}

export function userType(username) {
    return {
        type: 'USER_TYPE',
        username
    }
}

export function* fetchUserType(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/user/type?username="+action.username.slice(1), {
                method: 'GET'
            })
        });

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function auth(data, remember) {
    return {
        type: 'AUTH',
        data,
        remember
    }
}

export function* fetchAuth(action) {
    try {
        var username = JSON.parse(action.data).username;

        yield put(userType(username));
        let res = yield take(["USER_TYPE_SUCCESS", "USER_TYPE_BAD_REQUEST", "USER_TYPE_ERROR"]);

        switch (res.type) {
            case "USER_TYPE_SUCCESS":

                switch (res.data.id) {
                    case 1:
                        const {status, data, error} = yield call(() => {
                            return fetchClientLkApi("/v1/login", {
                                method: 'POST',
                                body: action.data
                            })
                        });

                        if (error) {
                            console.log(error);
                        } else {

                            if (status === 200) {
                                for (var key in data) {

                                    let options = {path: "/"};
                                    if (action.remember) {
                                        options.expires = 30*24*3600
                                    }
                                    if (cookieDomain) {
                                        options.domain = cookieDomain
                                    }

                                    setCookie(key, data[key], options);
                                }
                                yield put(info(username));

                                // выполняет переход на детальный тариф при авторизации на мобильных устройствах
                                // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                                //     let tariffAction = yield take(["TARIFF_INFO_SUCCESS", "TARIFF_INFO_ERROR"]);
                                //     let infoAction = yield take(["CLIENT_INFO_SUCCESS", "CLIENT_INFO_ERROR"]);
                                //     if (tariffAction.type === 'TARIFF_INFO_SUCCESS' && infoAction.type === 'CLIENT_INFO_SUCCESS') {
                                //         let tariffUrl = mainUrl + infoAction.data.region.alias + '/tariffs';
                                //         switch (tariffAction.data.status_id) {
                                //             case 2:
                                //                 tariffUrl += '/archive/';
                                //                 break;
                                //             case 3:
                                //                 tariffUrl += '/individual/';
                                //                 break;
                                //             default:
                                //                 tariffUrl += '/';
                                //         }
                                //         tariffUrl += tariffAction.data.slug;
                                //         window.open(tariffUrl, '_self')
                                //     }
                                // }

                            }
                            var formsSettings = yield select(formsSettingsState);

                            if (status === 400) {
                                data.message = formsSettings.auth_form.auth_form_error;
                            }
                            yield put(checkErrors(action.type, data, status));
                        }
                        break;
                    case 3:
                        window.open("https://lisa.motivtelecom.ru/?abnum="+username, '_self');
                        break;
                    default:
                        var formsSettings = yield select(formsSettingsState);
                        yield put(checkErrors(action.type, {title: formsSettings.auth_form.fixed_connection_title, description: formsSettings.auth_form.fixed_connection_description}, 403));

                }
                break;
            case "USER_TYPE_BAD_REQUEST":
                // let formsSettings = yield select(formsSettingsState);
                // yield put(checkErrors(action.type, {...res.data, message: formsSettings.auth_form.auth_form_error}, 400));
                yield put(checkErrors(action.type, {...res.data, message: res.data.message}, 400));

                break;
            case "USER_TYPE_ERROR":
                yield put(checkErrors(action.type, res.data, 500));
                break;
        }

    } catch (e) {
        console.log(e);
    }
}

export function callLogin() {
    return {
        type: 'CALL_LOGIN'
    }
}
export function* fetchCallLogin(action) {
    try {
        const {status, data, error} = yield call(() => {
            return fetchClientApi("/blitz/auth", {
                method: 'GET',
            })
        });

        if (error) {
            console.log(error);
        } else {
            if(data.link) window.location.replace(data.link)
            else yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function checkLogin(code, isCssToken = false) {
    return {
        type: 'CHECK_LOGIN',
        code,
        isCssToken
    }
}
export function* fetchCheckLogin(action) {
    try {
        const {status, data, error} = yield call(() => {
            return fetchClientApi(`/blitz/check?${action.isCssToken ? 'token=' : 'code='}${action.code}`, {
                method: 'POST',
            })
        });

        if (error) {
            console.log(error);
        } else {
            if (status === 200) {
                for (var key in data) {
                    let options = {path: "/"};
                    if (action.remember) {
                        options.expires = 30*24*3600
                    }
                    if (cookieDomain) {
                        options.domain = cookieDomain
                    }

                    setCookie(key, data[key], options);
                }
                yield put(info());
                deleteQueryParam('code');
                deleteQueryParam('access_token');
            } else if(status === 400) {
                deleteQueryParam('code');
                deleteQueryParam('access_token');
                deleteQueryParam('state');
            }
            // var formsSettings = yield select(formsSettingsState);
            //
            // if (status === 400) {
            //     data.message = formsSettings.auth_form.auth_form_error;
            // }
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function logout() {
    return {
        type: 'LOGOUT'
    }
}

export function* fetchLogout(action) {
    try {


        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/logout", {
                method: 'POST',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            if(status >= 200 && status < 300) {
                let options = {
                    'max-age': -1,
                    path: '/'
                };
                if (cookieDomain) {
                    options.domain = cookieDomain;
                }
                setCookie("access_token", "", options);
                setCookie("refresh_token", "",options);
                setCookie("expires_in", "",options);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function callLogout() {
    return {
        type: 'CALL_LOGOUT'
    }
}
export function* fetchCallLogout(action) {
    try {
        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/blitz/logout", {
                method: 'POST',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            if(status === 200) {

                yield put(logout());
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function info() {
    return {
        type: 'CLIENT_INFO'
    }
}

export function* fetchInfo(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        yield put(remainsInfo());
        yield put(remainsTotal());
        yield put(tariffInfo());

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }

        if (status == 500) {
            yield put(logout())
        }

    } catch (e) {
        console.log(e);
    }
}

// изменяет булево значение
export function checkReqAction(data) {
    return {
        type: 'CHECK_REQ',
        data
    }
}

export function changeInfo(data) {
    return {
        type: 'CHANGE_CLIENT_INFO',
        data
    }
}

// отправляет форму в личном кабинете (Заявка на изменение данных)
export function* fetchChangeInfo(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me", {
                method: 'POST',
                token: getCookie("access_token"),
                headers: {},
                body: action.data
            })
        });

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function remainsInfo() {
    return {
        type: 'CLIENT_REMAINS_INFO'
    }
}

export function* fetchRemainsInfo(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/remains", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function remainsTotal() {
    return {
        type: 'CLIENT_REMAINS_TOTAL'
    }
}

export function* fetchRemainsTotal(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/remains/total", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function remainsHistory() {
    return {
        type: 'CLIENT_REMAINS_HISTORY'
    }
}

export function* fetchRemainsHistory(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/remains/history", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function recovery(data) {
    return {
        type: 'RECOVERY_PASSWORD',
        data
    }
}

export function* fetchRecovery(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/password", {
                method: 'POST',
                body: action.data
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function changePassword(data) {
    return {
        type: 'CHANGE_PASSWORD',
        data
    }
}

export function* fetchChangePassword(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        let formData = new FormData();
        formData.append('old_password', action.data.old_password);
        formData.append('new_password', action.data.new_password);

        const {status, data, error} = yield call(() => {
            // return fetchClientLkApi("/v1/password", {
            return fetchClientApi(
              "/blitz/password ",
              {
                    method: 'PUT',
                    // body: action.data,
                    body: JSON.stringify(action.data),
                    token: getCookie("access_token")
                },
              {'Accept': 'application/json', 'Content-Type': 'application/json'})
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}
export function tariffInfo() {
    return {
        type: 'TARIFF_INFO'
    }
}

export function* fetchTariffInfo(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/tariff", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}


export function clearState() {
    return {
        type: "CLEAR_STATE"
    }
}

export function changeTariff(data) {
    return {
        type: 'CHANGE_TARIFF',
        data
    }
}

export function* fetchChangeTariff(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/tariff", {
                method: 'PUT',
                body: action.data,
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            if (status < 300) {
                yield put(tariffInfo());
                yield put(remainsInfo());
                yield put(remainsTotal());
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function changeStatus(data, isBlock = true) {
    return {
        type: 'CHANGE_STATUS',
        data,
        isBlock
    }
}

export function* fetchChangeStatus(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/status", {
                method: action.isBlock ? 'PATCH' : 'PUT',
                body: action.data,
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type+(action.isBlock ? '_BLOCK' : '_UNBLOCK'), data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function services() {
    return {
        type: 'MY_SERVICES'
    }
}

// список услуг
export function* fetchServices(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        yield put(availableServices());

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/service", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function toggleServices(data, service, on = true, clearAfter = false) {
    return {
        type: 'TOGGLE_SERVICES',
        data,
        on,
        clearAfter,
        service
    }
}

export function* fetchToggleServices(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/service", {
                method: action.on ? 'POST' : 'DELETE',
                body: action.data,
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            if (status < 300) {
                // var servicesList = yield select(servicesListsState);
                // var id = JSON.parse(action.data).id;
                // var service = servicesList.find(item => item.id_billing === id);
                // if (!service && !action.on) {
                //     servicesList = yield select(myServicesListsState);
                //     service = servicesList.find(item => item.id_billing === id);
                // }

                yield put(checkErrors(action.type, {on: action.on, clearAfter: action.clearAfter, service: action.service}, status));
            } else {
                yield put(checkErrors(action.type, data, status));
            }

        }

    } catch (e) {
        console.log(e);
    }
}

export function orderDetails(data) {
    return {
        type: 'ORDER_DETAILS',
        data
    }
}

export function* fetchOrderDetails(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/detalization", {
                method: 'POST',
                body: action.data,
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function details() {
    return {
        type: 'MY_DETAILS'
    }
}

export function* fetchDetails(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        let date = new Date(Date.now() - 30*24*3600000);
        let year = date.getFullYear(), month = date.getMonth() + 1, day = date.getDate();
        if (month < 10) month = '0'+month;
        if (day < 10) day = '0'+day;
        let dateFrom = year+"-"+month+"-"+day+"T00:00:00Z";

        date = new Date();
        year = date.getFullYear(), month = date.getMonth() + 1, day = date.getDate();
        if (month < 10) month = '0'+month;
        if (day < 10) day = '0'+day;
        let dateTo = year+"-"+month+"-"+day+"T00:00:00Z";

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/detalization?date_from="+dateFrom+"&date_to="+dateTo, {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function payments() {
    return {
        type: 'MY_PAYMENTS'
    }
}

export function* fetchPayments(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        let date = new Date(Date.now() - 90*24*3600000);
        let year = date.getFullYear(), month = date.getMonth() + 1, day = date.getDate();
        if (month < 10) month = '0'+month;
        if (day < 10) day = '0'+day;
        let dateFrom = year+"-"+month+"-"+day+"T00:00:00Z";

        date = new Date();
        year = date.getFullYear(), month = date.getMonth() + 1, day = date.getDate();
        if (month < 10) month = '0'+month;
        if (day < 10) day = '0'+day;
        let dateTo = year+"-"+month+"-"+day+"T00:00:00Z";

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/payments?date_from="+dateFrom+"&date_to="+dateTo, {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function exchangeMins(regionId) {

    return {
        type: 'EXCHANGE_MINS',
        regionId
    }
}

export function* fetchExchangeMins(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        let services = yield select(servicesListsState);
        let settings = yield select(settingsState);

        if (!services.length) {
            yield put(servicesList(action.regionId, 'rank_filter'));
            let act = yield take('SERVICE_LIST_SUCCESS');
            services = act.data;
        }

        if (!settings) {
            yield put(lkSettings());
            let actSettings = yield take('LK_SETTINGS_SUCCESS');
            settings = actSettings.data;
        }

        let obmen = services.find(item => item.id_billing == settings.exchange_service_id);

        if (obmen) {
            let slug = obmen.slug, region = obmen.region_id;
            yield put(servicesView(slug, region));
        }


        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/exchange", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function exchangeMinsSend(data) {
    return {
        type: 'EXCHANGE_MINS_SEND',
        data
    }
}

export function* fetchExchangeMinsSend(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/exchange", {
                method: 'POST',
                body: action.data,
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            if (status < 300) {
                yield put(remainsInfo());
                yield put(remainsTotal());
                yield put(remainsHistory())
            }
            yield put(checkErrors(action.type, status < 300 ? JSON.parse(action.data) : data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function banServices() {
    return {
        type: "BAN_SERVICES"
    }
}

export function* fetchBanServices(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/me/ban-services", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function formsSettings() {
    return {
        type: "LK_FORMS_SETTINGS"
    }
}

export function* fetchFormsSettings(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/forms/lk", {
                method: 'GET'
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}
export function lkMenu() {
    return {
        type: "LK_MENU"
    }
}

export function* fetchLkMenu(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/lk-menu", {
                method: 'GET'
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function lkSettings() {
    return {
        type: "LK_SETTINGS"
    }
}

export function* fetchLkSettings(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/settings?names=exchange_service_id;remain_comment;credit_balance_comment;details_services_type_slug;details_service_id;account_settings_page_description;security_settings_description;security_settings_link;gs_redirect_url", {
                method: 'GET'
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function costsServices(regionId) {
    return {
        type: "COSTS_SERVICES",
        regionId
    }
}

export function* fetchCostsServices(action) {
    try {

        let myServices = yield select(myServicesListsState);

        if (!myServices) {
            yield put(services())
        }

        let settings = yield select(settingsState);

        if (!settings) {
            yield put(lkSettings());
            let actSettings = yield take('LK_SETTINGS_SUCCESS');
            settings = actSettings.data;
        }

        if (!settings.details_services_type_slug) {
            yield put(checkErrors(action.type, [], 200));
        } else {
            const {status, data, error} = yield call(() => {
                return fetchClientApi("/services?regionId="+action.regionId+"&main_category_id="+settings.details_services_type_slug, {
                    method: 'GET'
                })
            });

            if (error) {
                console.log(error);
            } else {

                yield put(checkErrors(action.type, data, status));
            }
        }



    } catch (e) {
        console.log(e);
    }
}

export function availableServices() {
    return {
        type: "AVAILABLE_SERVICES"
    }
}

export function* fetchAvailableServices(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/service/available", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }


    } catch (e) {
        console.log(e);
    }
}
//
export function promisedInfoAction() {
    return {
        type: "CLIENT_PROMISED_INFO"
    }
}

export function* fetchPromisedInfoSaga(action) {
    try {
        yield put(checkToken());
        yield take("TOKEN_CHECKED")

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/payments/promised/info", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }
    } catch (e) {
        console.log(e);
    }
}
//
export function clientPromisedAction(data) {
    return {
        type: 'CLIENT_PROMISED',
        data
    }
}

export function* fetchClientPromisedSaga(action) {
    try {

        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/payments/promised", {
                method: 'PUT',
                body: action.data.paySum ? JSON.stringify({ pay_sum: action.data.paySum }) : '',
                token: getCookie("access_token")
            })
        });

        if(status === 200){
            yield put(promisedInfoAction());
        }

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}


export function serviceCashback() {
    return {
        type: "SERVICE_CASHBACK"
    }
}

export function* fetchServiceCashback(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientLkApi("/v1/me/cashback", {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) {
            console.log(error);
        } else {
            yield put(checkErrors(action.type, data, status));
        }


    } catch (e) {
        console.log(e);
    }
}

export function getAllMyAppeals(phone) {
    return {
        type: "ALL_MY_APPEALS",
        phone,
    }
}

export function* fetchGetAllMyAppeals(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/calls-info?phone=" + action.phone + '&blank=all', {
                method: 'GET'
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function getFilteredMyAppeals(phone, blank) {
    return {
        type: "FILTERED_MY_APPEALS",
        phone,
        blank
    }
}

export function* fetchGetFilteredMyAppeals(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/calls-info?phone=" + action.phone + '&blank=' + action.blank, {
                method: 'GET'
            })
        });

        if (error) {
            console.log(error);
        } else {

            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}