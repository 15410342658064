import

    React, {Component} from "react";
import PropsTypes from "prop-types"

import CallbackForm from "../Forms/CallbackForm";
import SuccessForm from "../Forms/SuccessForm";
import StartMoveForm from "../Forms/StartMoveForm";
import GettingType from "../Forms/GettingType";
import InfoConfirm from "../Forms/InfoConfirm";
import DeliveryAddress from "../Forms/DeliveryAddress";
import ContinueWay from "../Forms/ContinueWay";
import Modal from "../common/Modal";
import ChooseTariff from "../Forms/ChooseTariff";
import PersonalInfoForm from "../Forms/PersonalInfoForm";
import MnpErrorForm from "../Forms/MnpErrorForm";
import AlreadyInMotiv from "../Forms/AlreadyInMotiv";
import FinalMnpForm from "../Forms/FinalMnpForm";
import {setUtmCookie, checkUtmParams, getCookie } from "../../tools/tools";
import {connect} from "react-redux";
import {routesList} from "../../actions/routes";
import {addressAutoComplete} from "../../actions/forms";
import {closestShopsList, shopsList} from "../../actions/shops";
import {coverImagesList} from "../../actions/coverImages";
import PropTypes from "prop-types";
import OfficeMap from "../common/OfficeMap";
import {getMnpBonuses} from "../../actions/infoBlocks";

class MnpForm extends Component{

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        goToStep: PropsTypes.func,
        fields: PropsTypes.object,
        step: PropsTypes.number
    };

    static childContextTypes = {
        showModal: PropsTypes.func
    };

    getChildContext(){
        return {
            showModal: this.showModal
        }
    }

    constructor(props){
        super(props);

        this.state = {
            modal: null,
            fields: {
                name: ""
            },
            officeData: {},
        }
        this._regions = [];
    }
    loadRegion = () => {
        if (this._regions.length === 0) {
            return;
        }
        this.props.getCoverImages(this._regions.shift());
    }
    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if (this._regions.length === 0) {
            this._regions = this.props.regions.list.map(item => item.id).filter(item => item !== regionId);
            this._regions.unshift(regionId)
        }

        if (typeof document !== "undefined") {
            this.loadRegion();
        }
    }
    componentWillUnmount() {
        // document.querySelector('html').classList.remove('notScroll');
    }
    componentDidMount() {
        this.props.getMnpBonuses();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isFinalStepComplete !== this.props.isFinalStepComplete && nextProps.isFinalStepComplete) {
            this.showModal("success")();
        }
        if (nextProps.forms.isSubmitOk !== this.props.forms.isSubmitOk &&  nextProps.forms.isSubmitOk === 'callback_form') {
            this.showModal("success")();
        }
        if (nextProps.forms.isSubmitOk !== this.props.forms.isSubmitOk &&  nextProps.forms.isSubmitOk === 'operator_not_defined') {
            this.showModal("success")();
        }
        if (nextProps.forms.isSubmitOk !== this.props.forms.isSubmitOk &&  nextProps.forms.isSubmitOk === 'final_form_v2') {
            this.showModal("success")();
        }
        if (nextProps.forms.isSubmitOk !== this.props.forms.isSubmitOk &&  nextProps.forms.isSubmitOk === 'order_sent_failed') {
            this.showModal("order_sent_failed")();
        }
        if (nextProps.forms.submitError !== this.props.forms.submitError &&  nextProps.forms.submitError) {
            this.showModal("error")();
        }



        if (!!this.props.validPhoneNumber){
            this.showModal("success")();
        }
    }

    showModal = (modal) => {
        return () => {
            this.setState({modal}, () => {
                // document.querySelector('html').classList.add('notScroll');
            })
        }
    };

    hideModal = () => {
        // document.querySelector('html').classList.remove('notScroll');
        if (this.state.modal === "success") this.context.goToStep(0);
        this.setState({modal: null});
    };

    onFinalCallSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit({
            name: this.state.fields.name,
            form_name: this.context.fields.getting_type_alias === "delivery" ? "Шаг"+this.context.step+".2" : "Шаг"+this.context.step+".1"
        }, "ordering_final");
    };

    renderModal = () => {

        let {
            forms,
            shops,
            addressAutoComplete,
            getClosestShopsList,
            getShopsList,
        } = this.props;

        let {
            modal,
            fields,
            showTabs,
        } = this.state;

        switch (modal) {
            case "call":

                let submitButtonId = this.getCallModalSubmitButtonId();

                var form = this.props.forms.list.filter((item) => {return item.alias === "continue_by_phone_modal"});
                var {
                    continue_by_phone_description
                } = form[0].settings;

                return   <Modal close={this.hideModal}>
                            <div className="b-callback-form b-callback-form_mnp">
                                <div className="b-callback-form__title b-callback-form__title_mnp">Заказать звонок</div>
                                <div className="b-callback-form__body">
                                    <div className="b-callback-form__text">{continue_by_phone_description}</div>
                                    <form className="b-callback-form__form-holder b-callback-form__form-holder_mnp" onSubmit={this.onFinalCallSubmit}>
                                        <div className="b-callback-form__form-item b-callback-form__form-item_mnp">
                                            <div className="b-input-text b-input-text_adapt_modal">
                                                <div className="b-input-text__wrapper">
                                                    <div className="b-input-text__input-area">
                                                        <input className="b-input-text__input"
                                                           type="text"
                                                           placeholder="Как вас зовут"
                                                           value={fields.name}
                                                           onChange={(e) => {this.setState({fields: {name: e.target.value}})}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="b-callback-form__form-item b-callback-form__form-item_submit">
                                            <button id={submitButtonId} className="b-callback-form__submit u-btn u-btn_default u-btn_adapt_default" type="submit">Отправить</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>;
            case "success":

                if (typeof getCookie("utm_start") !== "undefined") {
                    setUtmCookie(2, "utm_finish", checkUtmParams());
                }

                let form = [];

                let alias = this.props.alias;

                if (alias === "continue_way" || alias === "operator_not_defined" || alias === "final_form_v2") {
                    alias = "order_sent_success";
                }

                form = this.props.forms.list.filter((item) => {return item.alias === alias});
                let title = form[0].settings.hasOwnProperty(alias+"_title") ? form[0].settings[alias+"_title"] : "Ваша заявка принята";
                let text = form[0].settings.hasOwnProperty(alias+"_description") ? form[0].settings[alias+"_description"] : "";

                if (alias === "personal_info") {
                    form = this.props.forms.list.filter((item) => {return item.alias === "ordering_final"});
                    text = form[0].settings.ordering_final_text;

                    if (this.context.fields.getting_type_alias === "delivery") {
                        text = form[0].settings.ordering_final_text_delivery;
                        text = text.replace('[[address]]', this.props.fields.city + ', ' + this.props.fields.address + ', ' + this.props.fields.house + (this.props.fields.block ? ', ' + this.props.fields.block : ''))
                    } else {
                        text = text.replace('[[address]]', this.props.fields.shop_city + ', ' + this.props.fields.shop_address)
                    }
                }

                let hideModal = this.props.hideModal ? this.props.hideModal : this.hideModal;

                return  <Modal close={hideModal}>
                            <SuccessForm title={title} text={text} />
                        </Modal>;
            case "order_sent_failed":
                form = [];

                alias = this.props.alias;

                form = this.props.forms.list.filter((item) => {return item.alias === "order_sent_failed"});
                title = form[0].settings.hasOwnProperty('mnp_form_registry_title') ? form[0].settings['mnp_form_registry_title'] : "Ваша заявка принята";
                text = form[0].settings.hasOwnProperty('mnp_form_registry_description') ? form[0].settings['mnp_form_registry_description'].replace("{{registry_time}}", form[0].settings['mnp_form_registry_time']) : "";

                hideModal = this.props.hideModal ? this.props.hideModal : this.hideModal;

                return  <Modal close={hideModal}>
                    <SuccessForm title={title} text={text} icon={false} />
                </Modal>;
            case "error":

                hideModal = this.props.hideModal ? this.props.hideModal : this.hideModal;

                return  <Modal close={hideModal}>
                            <SuccessForm title={this.props.forms.submitError} icon={false} />
                        </Modal>;

            case "choose_office":
                hideModal = this.props.hideModal ? this.props.hideModal : this.hideModal;

                return  <Modal
                  close={hideModal}
                  wrapperClassName="u-modal-wrapper u-modal-wrapper-full-page"
                  innerClassName="u-modal-inner u-modal-inner-full-page"
                  closeBtnClassName="mnp-popup__close mnp__map-offices__close"
                >
                    <div className="mnp__office-map">
                        <div className="page__breadcrumbs mnp__office-map__subtitle">
                            <div className="b-breadcrumbs b-breadcrumbs_theme_grey">
                                <ul className="b-breadcrumbs__list">
                                    <li className="b-breadcrumbs__item">
                                        <span className="b-breadcrumbs__link">Перейти в МОТИВ со своим номером</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="mnp__office-map__controlls">
                            <div className="page_mnp_step-form">
                                <div className="page__step-title mnp__office-map__title">Выберите салон связи</div>
                            </div>
                            <div className="mnp__office-map__back" onClick={hideModal}>Вернуться назад</div>
                        </div>
                    </div>
                    <OfficeMap
                        items={shops.list}
                        address={forms.addressFull || []}
                        addressAutoComplete={addressAutoComplete}
                        getClosestShopsList={getClosestShopsList}
                        getShopsList={getShopsList}
                        inProgress={forms.inProgress}
                        showTabs={showTabs}
                        coverCoords={this.props.coverImages.list}
                        loadMore={() => this.loadRegion()}
                        duplicates={this.props.coverImages.duplicates || []}
                        isMnpOffices={true}
                        onSubmit={this.setMnpOffice}
                        mnpPhoneData={this.props.mnpPhoneData}
                    />
                </Modal>;
            default:
                return null;
        }
    };

    getCallModalSubmitButtonId = () => {
        switch (this.context.step) {
            case 4: return "mnp-pickup-type-info-by-call-submit-button";
            case 5: return "mnp-tariff-info-by-call-submit-button";
            default: return ""
        }
    };

    setMnpOffice = (data) => {
        this.setState({officeData: {...data}})
        this.hideModal();
    }


    render() {
        let {alias, forms, additionalProps, onSubmit, children, activation, className} = this.props;

        let {
            officeData,
        } = this.state

        let inProgress = forms.inProgress;

        forms = forms.list;
        let searchAlias = alias;

        if (["wrong_region", "already_in_motive", "operator_not_defined"].indexOf(alias) !== -1) {
            searchAlias = "start_move_form"
        }

        let form = forms.filter((item) => {return item.alias === searchAlias});
        form = form.length ? form[0] : null;

        if (!form) return null;

        switch (alias) {
            case "callback_form":
                return <CallbackForm inProgress={inProgress} target={this.props.target} className={className} {...form.settings} onSubmit={(data) => {onSubmit(data, alias, this.props.target)}}>
                           {this.renderModal()}
                       </CallbackForm>;
            case "order_sent_success":
                return <div></div>;
                //return <SuccessForm text={form.settings[alias+"_description"]} title={form.settings[alias+"_title"]} />;
//    
            case "start_move_form":
                return <StartMoveForm
                      activation={activation}
                      {...form.settings}
                      onSubmit={(data) => { onSubmit(data, alias) }}
                      modal={this.state.modal}
                      validPhoneNumber={this.props.validPhoneNumber}
                      onToggleForm={this.props.onToggleForm}
                      onRadioChange={this.props.onRadioChange}
                      mnpFormEsiaAction={this.props.mnpFormEsiaAction}
                      officeClick={this.showModal('choose_office')}
                      mnpBonuses={this.props.mnpBonuses}
                    >
                    {this.renderModal()}
                </StartMoveForm>;
//
            case "getting_type":
                return <GettingType inProgress={inProgress} {...form.settings} onSubmit={(data) => {onSubmit(data, alias)}}/>;
            case "number_agreements":
                return <InfoConfirm inProgress={inProgress} activation={activation} forms={forms} {...form.settings} onSubmit={(data) => {onSubmit(data, alias)}} children={children} />;
            case "delivery_address":
                return <DeliveryAddress
                    forms={this.props.forms}
                    {...form.settings}
                    {...additionalProps}
                    onSubmit={(data) => {onSubmit(data, alias)}}
                    clearCity={this.props.clearCity}
                    clearAddress={this.props.clearAddress}
                    clearStreet={this.props.clearStreet}
                    clearHouse={this.props.clearHouse}
                    inProgress={inProgress}
                />;
            case "continue_way":
                return <ContinueWay {...form.settings} onSubmit={(data) => {onSubmit(data, alias)}}>
                           {this.renderModal()}
                       </ContinueWay>;
            case "tariff_selection":
                return <ChooseTariff {...form.settings} typeDelivery={this.props.typeDelivery} {...additionalProps} onSubmit={(data) => {onSubmit(data, alias)}}>
                           {this.renderModal()}
                       </ChooseTariff>;
            case "personal_info":
                return <PersonalInfoForm
                    forms={this.props.forms}
                    {...form.settings}
                    {...additionalProps}
                    onSubmit={(data) => {onSubmit(data, alias)}}
                    clearCity={this.props.clearCity}
                    clearAddress={this.props.clearAddress}
                    clearStreet={this.props.clearStreet}
                    clearHouse={this.props.clearHouse}
                    inProgress={inProgress}
                >
                           {this.renderModal()}
                       </PersonalInfoForm>;
            case "operator_not_defined":
                return <MnpErrorForm {...form.settings} onSubmit={(data) => {onSubmit(data, alias)}} goBack={this.props.goBack}>
                            {this.renderModal()}
                       </MnpErrorForm>;
            case "already_in_motive":
                return <AlreadyInMotiv text={form.settings[alias+"_description"]} title={form.settings[alias+"_title"]}/>;
            case "wrong_region":
                return <AlreadyInMotiv text={form.settings["wrong_region_description"]} title={form.settings["wrong_region_title"]}/>;
            case "final_form_v2":
                return <FinalMnpForm
                    forms={this.props.forms}
                    {...form.settings}
                    {...additionalProps}
                    onSubmit={(data) => {onSubmit(data, alias)}}
                    clearCity={this.props.clearCity}
                    clearAddress={this.props.clearAddress}
                    clearStreet={this.props.clearStreet}
                    clearHouse={this.props.clearHouse}
                    inProgress={inProgress}
                    phoneInfo={children}
                    radioValue={this.props.radioValue}
                    isGuest={this.props.isGuest}
                    officeData={officeData}
                    officeClick={this.showModal('choose_office')}
                >
                    {this.renderModal()}
                </FinalMnpForm>;
            default:
                return null;
        }
    }

}
export default connect(
  state => ({
      routes: state.routes,
      shops: state.shops,
      forms: state.forms,
      settings: state.settings,
      coverImages: state.coverImages,
      regions: state.regions,
      mnpPhoneData: state.activation.mnp,
      mnpBonuses: state.infoBlocks.mnpBonuses,
  }),
  (dispatch) => {
      return {
          getRoutes: () => dispatch(routesList()),
          addressAutoComplete: (type, query, params) => dispatch(addressAutoComplete(type, query, params)),
          getClosestShopsList: (regionId, lat, lon, region, city, street) => dispatch(closestShopsList(regionId, lat, lon, region, city, street)),
          getShopsList: (regionId) => dispatch(shopsList(regionId)),
          getCoverImages: (regionId) => dispatch(coverImagesList(regionId)),
          getMnpBonuses: () => dispatch(getMnpBonuses()),
      }
  }
)(MnpForm);
