import React, {Component} from 'react';
import RatesCollapsing from "./RatesCollapsing";
import Select from "../common/Select";

class RatesRoamingTabs extends Component {

    constructor(props) {

        super(props);

        this.state = {
            activeTab: 0,
            operators: []
        }
    }

    onToggleTab = (value) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: value
                }
            })
        }
    };

    onSelectOperator = (value) => {
        this.setState({
            activeTab: value
        })
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.tabs !== nextProps.tabs) {
            this.setState({
                activeTab: 0
            })
        }
    }

    componentDidMount() {
        this.handlerGetOperators(this.props.tabs);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.tabs != nextProps.tabs) {

            this.handlerGetOperators(nextProps.tabs);

            return true;
        }

        if (this.state.operators != nextState.operators) {
            return true;
        }

        if (this.state.activeTab != nextState.activeTab) {
            return true;
        }

        return false;
    }

    handlerGetOperators = (tabs) => {

        let operatorsList = [];

        tabs.map((tab, index) => {
            operatorsList.push({
                value: index,
                label: tab.operator,
            })
        });

        this.setState({
            operators: operatorsList
        });
    };

    render () {
        let {
            activeTab
        } = this.state
        let {
            tabs,
            isOk
        } = this.props;

        return (
            <React.Fragment>
                {   tabs.length > 0
                    ?   <div className="b-with-aside-area b-with-aside-area_adapt_default">
                        <div className="b-with-aside-area__column">
                            <h3 className="page__title-3">Тарифы на услуги связи {tabs[0].region}</h3>
                            <div className="b-roaming__tags-filter">
                                <div className="js-arrow-switcher b-tags-filter b-tags-filter b-tags-filter_adapt_default">
                                    <div className="b-tags-filter__wrapper">
                                        {tabs.map((item, key) =>
                                            <React.Fragment>
                                                {item.servce_link && activeTab === key
                                                && (
                                                    <div className="b-tags-filter__link"
                                                        dangerouslySetInnerHTML={{ __html: item.servce_link }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                        <div className="b-tags-filter__list">
                                            <div className="b-tags-filter__item-btn b-tags-filter__item-btn_prev" data-prev="data-prev"/>
                                            {tabs.map((item, key) =>
                                                <React.Fragment>
                                                    <a 
                                                        onClick={this.onToggleTab(key)} 
                                                        className={"b-tags-filter__item" + (activeTab === key ? " b-tags-filter__item_active" : "")} 
                                                        href="#" 
                                                        key={key}
                                                    >
                                                        {item.operator}
                                                    </a>
                                                </React.Fragment>
                                            )}
                                            <div className="b-tags-filter__item-btn b-tags-filter__item-btn_next" data-next="data-next"/>
                                        </div>
                                        {this.state.operators 
                                            && (
                                                <Select 
                                                    value={ this.state.activeTab } 
                                                    className={'b-tags-filter__select'} 
                                                    onToggle={ this.onSelectOperator } 
                                                    options={ this.state.operators }
                                                />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {tabs.map((item, key) =>
                                <div className={"b-roaming__price-list" + (this.state.activeTab === key ? " b-roaming__price-list_active" : "")} key={key}>
                                    <div className="b-price-list b-price-list_adapt_default">
                                        <div className="b-price-list__wrapper">
                                            {item.international
                                                ?   <div className="b-price-list__list">
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Входящие соединения, 1 минута</div>
                                                        <div className="b-price-list__price">{item.incoming_minute_price}</div>
                                                        {!isNaN(item.incoming_minute_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    {/* <div className="b-price-list__item">
                                                                <div className="b-price-list__text">Входящие соединения, при подключенной услуге “Роуминг НОН-СТОП”, 1 минута</div>
                                                                <div className="b-price-list__price">{item.incoming_minute_nonstop_price}</div>
                                                        {!isNaN(item.incoming_minute_nonstop_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие соединения в Россию, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_to_russia_price}</div>
                                                        {!isNaN(item.outgoing_minute_to_russia_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие соединения местные, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_local_price}</div>
                                                        {!isNaN(item.outgoing_minute_local_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие соединения в страны СНГ, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_to_cis_price}</div>
                                                        {!isNaN(item.outgoing_minute_to_cis_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие соединения в другие страны, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_to_another_country_price}</div>
                                                        {!isNaN(item.outgoing_minute_to_another_country_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие SMS, 1 SMS</div>
                                                        <div className="b-price-list__price">{item.outgoing_sms_price}</div>
                                                        {!isNaN(item.outgoing_sms_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Мобильный интернет, 1 Мб</div>
                                                        <div className="b-price-list__price">{item.gprs_price_100kb}</div>
                                                        {!isNaN(item.gprs_price_100kb)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                </div>
                                                :   <div className="b-price-list__list">
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Входящие вызовы, 1 минута</div>
                                                        <div className="b-price-list__price">{item.incoming_minute_price}</div>
                                                        {!isNaN(item.incoming_minute_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    {/*<div className="b-price-list__item">
                                                                <div className="b-price-list__text">Входящие вызовы, 1 минута, при подключении услуг “Роуминг 2.0”, “Роуминг “НОН-СТОП”, “Роуминг для разговоров”, “Роуминг для всего”</div>
                                                                <div className="b-price-list__price">{item.incoming_minute_nonstop_price}</div>
                                                        {!isNaN(item.incoming_minute_nonstop_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие на номера России, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_to_russia_price}</div>
                                                        {!isNaN(item.outgoing_minute_to_russia_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    {/*<div className="b-price-list__item">
                                                                <div className="b-price-list__text">Исходящие на номера России, при подключении услуг “Роуминг для разговоров” и “Роуминг для всего”, 1 минута</div>
                                                                <div className="b-price-list__price">{item.outgoing_minute_to_russia_for_all_price}</div>
                                                                {!isNaN(item.outgoing_minute_to_russia_for_all_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                    {/*<div className="b-price-list__item">
                                                                <div className="b-price-list__text">Исходящие на номера России, при подключении услуги “Роуминг 2.0”, 1 минута</div>
                                                                <div className="b-price-list__price">{item.outgoing_minute_to_russia_2_0_price}</div>
                                                                {!isNaN(item.outgoing_minute_to_russia_2_0_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие на номера стран СНГ, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_to_cis_price}</div>
                                                        {!isNaN(item.outgoing_minute_to_cis_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие на номера остальных стран, 1 минута</div>
                                                        <div className="b-price-list__price">{item.outgoing_minute_to_another_country_price}</div>
                                                        {!isNaN(item.outgoing_minute_to_another_country_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Исходящие SMS, 1 SMS</div>
                                                        <div className="b-price-list__price">{item.outgoing_sms_price}</div>
                                                        {!isNaN(item.outgoing_sms_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    {/*<div className="b-price-list__item">
                                                                <div className="b-price-list__text">Исходящие SMS, при подключении услуги “Роуминг 2.0”, 1 SMS</div>
                                                                <div className="b-price-list__price">{item.outgoing_sms_2_0_price}</div>
                                                                {!isNaN(item.outgoing_sms_2_0_price)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                    <div className="b-price-list__item">
                                                        <div className="b-price-list__text">Мобильный интернет, 1 Мб</div>
                                                        <div className="b-price-list__price">{item.gprs_price_100kb}</div>
                                                        {!isNaN(item.gprs_price_100kb)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                    </div>
                                                    {/*<div className="b-price-list__item">
                                                                <div className="b-price-list__text">Мобильный интернет, при подключении услуги “Роуминг для всего”, 1 МБ</div>
                                                                <div className="b-price-list__price">{item.internet_for_all_price_1mb}</div>
                                                                {!isNaN(item.internet_for_all_price_1mb)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                    {/*<div className="b-price-list__item">
                                                                <div className="b-price-list__text">Мобильный интернет, при подключении услуги “Роуминг 2.0”, 1 МБ</div>
                                                                <div className="b-price-list__price">{item.internet_2_0_price_1mb}</div>
                                                                {!isNaN(item.internet_2_0_price_1mb)?<div className="b-price-list__valute">&nbsp;₽</div>:''}
                                                            </div>*/}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                            { tabs[0].service
                                ?
                                <React.Fragment>
                                    <RatesCollapsing className={"b-roaming__list-collapser"} info_dropdown={tabs[0].service.info_dropdown}/>
                                </React.Fragment>
                                :   ''
                            }
                        </div></div>
                    :   isOk
                        ?    <p>По вашему запросу ничего не найдено</p>
                        :    ''
                }
            </React.Fragment>
        )
    }
}

export default RatesRoamingTabs;