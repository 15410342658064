import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Ordering, {OrderingOk} from "../Rates/Ordering";
import Modal from "../common/Modal";
import ChangeRate from "../Rates/ChangeRate";
import PropTypes from "prop-types";
import ButtonDropdown from "../common/ButtonDropdown";
import TariffDescription from "../common/TariffDescription";
import ChangeRateOk from "../modals/ChangeRateOk";
import {getMeasure, setIdButton, getCookie, setUtmCookie, checkUtmParams}  from '../../tools/tools';
import ChangeRateConfirmation from "../modals/ChangeRateConfirmation";
import RecoveryForm from "../../components/Forms/RecoveryForm";
import {clearState, recovery, changeTariff} from "../../actions/client";

class RatesListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: null
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    static getDerivedStateFromProps(props, state){
        if (props.successMessageTitle && "recovery" === state.modal) {
            return {...state, modal: "recovery-ok"}
        }
        if (props.changeTariffMessage && state.modal === "change-rate") {
            return {...state, modal: "change-rate-ok"}
        }
        if (props.changeTariffError && state.modal === "change-rate") {
            return {...state, modal: "change-rate-err"}
        }
        return state;
    }

    showModal(type){
        return (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({modal: type});
            this.context.setHasItemModal(true);
        }
    }

    showSuccess = () => {
        this.setState({modal: 'change-rate-ok'})
    };

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        pushHistory: PropTypes.func,
        setHasItemModal: PropTypes.func
    };

    hideModal() {
        this.setState({modal: null});
        this.props.clear();
        this.context.setHasItemModal(false);
    }

    locateToTariff = (e) => {
        e.stopPropagation();
        this.context.pushHistory(
            "/" + this.context.selectedRegion.alias + "/tariffs/" + this.props.data.slug,
            {state: this.props.currentPage ? this.props.currentPage : null}
        );
    };

    onClickConnect =(e, id)=> {
        e.stopPropagation();
        this.props.onClickConnect('tariff-connection', id);
    }

    renderModal(){
        let content = "";
        let formsSettings = this.props.formsSettings;
        let utm_start = '';
        let utm_finish = '';
        if (typeof document !== "undefined") {
            utm_start = getCookie("utm_start");
            utm_finish = checkUtmParams();
        }

        switch (this.state.modal) {
            case 'ordering':
                content = <Ordering onSubmit={this.showModal('ordering-ok')}/>;
                break;
            case 'ordering-ok':
                content = <OrderingOk onClick={this.hideModal}/>;
                break;
            case 'change-rate':
                content = this.props.isGuest ? <ChangeRate
                    onSuccess={this.showSuccess}
                    forgetLinkClick={this.showModal('recovery')}
                    item={this.props.data}
                    hideModal={this.hideModal}
                    title={"Перейти на тариф «" + this.props.data.name + "»"}
                    type={"tariff"}
                /> : <ChangeRateConfirmation type={"tariff"}
                                             item={{
                                                 name: this.props.data.name,
                                                 info: [{
                                                     items: [
                                                         {
                                                             title: "Абонентская плата",
                                                             value: this.props.data.subscription_fee,
                                                             measure: "₽"
                                                         },
                                                         {
                                                             title: "Дата перехода",
                                                             value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                         }
                                                     ]
                                                 }]
                                             }}
                                              notice={formsSettings.tariff_form && formsSettings.tariff_form.tariff_form_description}
                                              onClick={() => {this.props.changeTariff(JSON.stringify({id: this.props.data.id, utm_start: utm_start, utm_finish: utm_finish}))}}
                                              onCancel={this.hideModal}

                />;
                break;
            case 'change-rate-ok':
                content = <ChangeRateOk title={formsSettings.tariff_form && formsSettings.tariff_form.tariff_change_title} description={formsSettings.tariff_form && formsSettings.tariff_form.tariff_change_description} item={this.props.data} type={"tariff"} onClick={this.hideModal}/>;
                break;
            case 'change-rate-err':
                content = <ChangeRateOk title={this.props.changeTariffError === "error" ? formsSettings.tariff_form && formsSettings.tariff_form.tariff_unavailable_title : this.props.changeTariffError} description={this.props.changeTariffError === "error" ? formsSettings.tariff_form && formsSettings.tariff_form.tariff_unavailable_description : ""} item={this.props.data} type={"error"} onClick={this.hideModal}/>;
                break;
            case 'recovery':
                content = <RecoveryForm recover={this.props.recover} error={this.props.recoveryError}/>;
                break;
            case 'recovery-ok':
                content = <ChangeRateOk type={"recovery"} onClick={this.hideModal}/>;
                break;
            default:
                return null;
        }
        return <Modal close={this.hideModal}>{content}</Modal>
    }

    render() {
        let {mainCategory, name, subscription_fee, slug, id, online_shop_link, writeOffPeriod} = this.props.data;
        let {canRecommend, index, isGuest, onClickConnect = ()=> {}, data} = this.props;

        let selectedRegion = this.context.selectedRegion;
        let slugSvoi = slug.indexOf('svoi');
        let idButtonSvoiIsGuest = '', idButton = '', idButtonSvoiNoGuest = '';

        if (slugSvoi !== -1) {
            idButtonSvoiIsGuest = setIdButton(this.context.selectedRegion.id, 'premade', data.slug, 'buy', 'tariff');
        } else {
            idButton = setIdButton(this.context.selectedRegion.id, 'premade', data.slug, 'buy', 'tariff');
        }

        return (
            <React.Fragment>
                <div onClick={this.locateToTariff} className="b-page__tariff-item" itemID={id}>
                    <div className="b-tariffs-table b-tariffs-table_theme_ecom b-tariffs-table_adapt_ecom">
                        <div className="b-tariffs-table__item">
                            <div className="b-tariffs-table__row">
                                <div className="b-tariffs-table__col-name">
                                    {
                                        canRecommend && index === 0
                                            ?   <div className="b-tariffs-table__recommended">
                                                    Рекомендуемый Вам тариф
                                                </div>
                                            :   !this.props.byCat
                                                    ?   <div className="b-tariffs-table__type">{mainCategory.title}</div>
                                                    :   ''
                                    }
                                    <div className="b-tariffs-table__name">Тариф&nbsp;
                                        <span className="b-tariffs-table__name-inner">«{name}»</span>
                                    </div>
                                    <div className="b-tariffs-table__price">
                                        <div className="b-tariffs-table__price-num">
                                            {slugSvoi !== -1 && data.svoi.abon_discount > data.svoi.abon && !!data.svoi.abon ?
                                                data.svoi.abon :
                                                subscription_fee
                                            }
                                        </div>
                                        <div>
                                            <div className="b-tariffs-table__price-top">
                                                <span className="u-rub">{writeOffPeriod !== null ? getMeasure(writeOffPeriod.measure, 0) : ''}</span>
                                            </div>
                                            <div className="b-tariffs-table__price-bottom">{writeOffPeriod !== null ? '/ ' + getMeasure(writeOffPeriod.measure, 1) : ''}</div>
                                        </div>
                                    </div>
                                </div>
                                <TariffDescription tariff={this.props.data} slugSvoi={slugSvoi} title={this.props.byCat}/>
                                <div className="b-tariffs-table__col-options">
                                    {/*<div className="b-tariffs-table__options-title b-tariffs-table__options-title_undefined">
                                        Акции тарифа
                                    </div>
                                    <div className="b-tariffs-table__options-block">
                                        <div className="b-tariffs-table__options-group">
                                            <label className="b-tariffs-table__label">
                                                <input className="b-tariffs-table__checkbox" type="checkbox"/>
                                                <span className="b-tariffs-table__checkbox-inner">Скидка 50% на абонентскую плату</span>
                                                <span className="b-tariffs-table__checkbox-mo">
                                                    <span className="b-input-switcher b-input-switcher_size_small">
                                                        <span className="b-input-switcher__wrapper">
                                                            <label className="b-input-switcher__area">
                                                                <input className="b-input-switcher__input-hidden" type="checkbox"/>
                                                                <span className="b-input-switcher__switcher">
                                                                    <span className="b-input-switcher__switcher-walker"></span>
                                                                </span>
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="b-tariffs-table__options-group">
                                            <label className="b-tariffs-table__label">
                                                <input className="b-tariffs-table__checkbox" type="checkbox"/>
                                                <span className="b-tariffs-table__checkbox-inner">Серебряный номер при подключении</span>
                                                <span className="b-tariffs-table__checkbox-mo">
                                                    <span className="b-input-switcher b-input-switcher_size_small">
                                                        <span className="b-input-switcher__wrapper">
                                                            <label className="b-input-switcher__area">
                                                                <input className="b-input-switcher__input-hidden" type="checkbox"/>
                                                                <span className="b-input-switcher__switcher">
                                                                    <span className="b-input-switcher__switcher-walker"></span>
                                                                </span>
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>*/}
                                </div>
                                <div className="b-tariffs-table__col-activate">
                                    {isGuest && slugSvoi === -1 ?
                                        <ButtonDropdown idName={idButton} name={"Купить тариф"} shopUrl={online_shop_link} /> : isGuest && slugSvoi !== -1 ?
                                            <button id={idButtonSvoiIsGuest} className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive button-dropdown__btn" onClick={(e) => this.onClickConnect(e, id)}>Купить тариф</button> :
                                        !isGuest && slugSvoi !== -1 ?
                                        <button className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive" onClick={(e)=> this.onClickConnect(e, id)} >Сменить тариф</button> :
                                        <button className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive" onClick={this.showModal('change-rate')}>Сменить тариф</button>}
                                    <div className="b-tariffs-table__links">
                                        { isGuest && slugSvoi !== -1 ?
                                        null :
                                            isGuest ?
                                            <a className="b-tariffs-table__link" href="#" onClick={this.showModal('change-rate')}>Сменить тариф</a> :
                                            null
                                        }
                                    </div>
                                    <div className="b-tariffs-table__links">
                                        <div className="b-tariffs-table__link" >Подробнее о тарифе</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className={"tariff_info_for_test"}>
                        <p><span>
                        internet_gb_count: <span className={"orange"}> {tarifft.internet_gb_count}</span>;<br/>
                        minutes_count: <span className={"orange"}>{tarifft.minutes_count}</span>;<br/>
                        sms_count: <span className={"orange"}>{tarifft.sms_count}</span>;<br/>
                        internet_social_unlimit: <span className={"orange"}>{(tarifft.internet_social_unlimit ? "true" : "false")}</span>;<br/>
                        internet_music_unlimit: <span className={"orange"}>{(tarifft.internet_music_unlimit ? "true" : "false")}</span>;<br/>
                        internet_messenger_unlimit: <span className={"orange"}>{(tarifft.internet_messenger_unlimit ? "true" : "false")}</span>;<br/>
                        cheap_intercity: <span className={"orange"}>{(tarifft.cheap_intercity ? "true" : "false")}</span>;<br/>
                    </span></p>
                        <p>
                            internet_mb_cost: <span className={"orange"}>{tarifft.internet_mb_cost}</span>;<br/>
                            minute_cost: <span className={"orange"}>{tarifft.minute_cost}</span>;<br/>
                            sms_cost: <span className={"orange"}>{tarifft.sms_cost}</span>;<br/>
                        </p>
                    </div>*/}
                </div>
                {this.renderModal()}
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({
        isGuest: state.client.isGuest,
        recoveryError: state.client.recoveryFormError || state.client.fatalError,
        successMessageTitle: state.client.successMessageTitle,
        changeTariffMessage: state.client.changeTariffMessage,
        changeTariffError: state.client.changeTariffError,
        formsSettings: state.client.formsSettings
    }),
    (dispatch) => {
        return {
            recover: (data) => dispatch(recovery(data)),
            changeTariff: (data) => dispatch(changeTariff(data)),
            clear: () => dispatch(clearState())
        }
    }
)(RatesListItem);
