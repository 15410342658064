import React, {Component} from 'react';
import RateAdditionalService from "./RateAdditionalService";
import PropTypes from "prop-types";
import B2bServiceCard from "../Services/B2bServiceCard";

class RatesAdditionalServices extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        isB2b: PropTypes.bool
    }

    render() {

        let title = this.props.title;
        let items = this.props.items;

        return (
            <div className={`b-services-list__options-table-plates${this.context.isB2b ? ' b-services-list__options-table-plates__b2b' : ''}`}>
                <div className="b-services-list__services-with">
                    <div className="b-options-table b-options-table_adapt_plates-services b-options-table_theme_plates">
                        <div className="b-options-table__wrapper">
                            <div className="b-options-table__header">
                                <div className="b-options-table__header-title">{title}</div>
                            </div>
                            <div className="b-options-table__items-area">
                                {items.map((item, idx) =>
                                    this.context.isB2b ? <B2bServiceCard item={item} key={item.id}/> : <RateAdditionalService key={idx} item={item}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesAdditionalServices;
