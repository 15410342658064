import React, { Component, Fragment } from 'react';

import PropTypes from "prop-types";
import { lkUrl, mainUrl } from "../../tools/config";
import { phoneMask } from "../../tools/mask";
import BalansBlock from "../BalansBlock/BalansBlock";
import Icon from '../common/Icon';
import LinkC from "../common/LinkC";
import Tooltip from "../common/Tooltip";
import AuthorizationModal from "../modals/AuthorizationModal";
import RecoveryModal from "../modals/RecoveryModal";
import SuccessModal from "../modals/SuccessModal";
import {callLogout} from "../../actions/client";
import TooltipCustom from "../common/TooltipQuestion/TooltipCustom";

class HeaderMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            mobileMenu: false,
            remainsTotal: this.prepareRemains(),
            startAnimation: false,
            finishAnimation: false,
            mousePointed: false,
        }
    }

    static contextTypes = {
        fetchBefore: PropTypes.func,
        selectedRegion: PropTypes.object,
        isLk: PropTypes.bool,
        locationPath: PropTypes.string
    };

    componentDidMount() {
        if (typeof document !== 'undefined') {
            const selector = ".logo-wrap";
            const el = document.querySelector(selector);
            el && el.addEventListener("mouseover", this.mouseoverEvent);
            el && el.addEventListener("mouseout", this.mouseoutEvent);
        }
    }

    componentWillUnmount() {
        const selector = ".logo-wrap";
        const el = document.querySelector(selector);
        el && el.removeEventListener("mouseover", this.mouseoverEvent);
        el && el.removeEventListener("mouseout", this.mouseoutEvent);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.modal && !this.state.modal) {
            this.props.clear();
        }

        if (prevProps.clientInfo.packageRemains !== this.props.clientInfo.packageRemains && this.props.clientInfo.packageRemains) {
            this.setState({ remainsTotal: this.prepareRemains() })
        }
        if (prevProps.clientInfo.remainsTotal !== this.props.clientInfo.remainsTotal || prevProps.clientInfo.tariff !== this.props.clientInfo.tariff) {
            this.setState({ remainsTotal: this.prepareRemains() });
        }

        const el = document.querySelector('.logo-wrap');

        if (this.state.mousePointed !== prevState.mousePointed && !this.state.mousePointed) {
            setTimeout(() => {
                this.setState({ finishAnimation: true });
                el.classList.add('finish-animation');
            }, 490);
        }

        if (this.state.finishAnimation !== prevState.finishAnimation && !!this.state.finishAnimation && !!this.state.startAnimation) {
            setTimeout(() => {
                this.setState({
                    startAnimation: false,
                    finishAnimation: false
                });
                el.classList.remove('hover-logo');
                el.classList.remove('start-animation');
                el.classList.remove('finish-animation');
            }, 550);
        }
    }

    mouseoverEvent = () => {
        const selector = '.logo-wrap';
        const el = document.querySelector(selector);

        el.classList.add('hover-logo');
        el.classList.add('start-animation');
        if (!this.state.mousePointed && !this.state.startAnimation) {
            this.setState({
                startAnimation: true,
                mousePointed: true
            });
        }
    }

    mouseoutEvent = () => {
        if (this.state.mousePointed) {
            this.setState({
                mousePointed: false
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.isGuest && state.modal === "AuthorizationModal") {
            return { ...state, modal: null }
        }
        if (props.fatalError && ["AuthorizationModal", "RecoveryModal"].indexOf(state.modal) > -1) {
            return { ...state, modal: "ErrorModal" }
        }
        if (props.successMessageTitle && "RecoveryModal" === state.modal) {
            return { ...state, modal: "SuccessModal" }
        }
        return state;
    }

    totalForm = (props) => {
        let { tariff } = this.props.clientInfo;

        let hasData = false;
        let preparedRemains = [
            {
                type: 'DATA',
                rest_amount: 0,
                unit: 'Гб'
            },
            {
                type: 'VOICE',
                rest_amount: 0,
                unit: 'Мин'
            },
            {
                type: 'SMS',
                rest_amount: 0,
                unit: 'Смс'
            }
        ]

        props && props.map((remain) => {
            switch (remain.type) {
                case 'DATA':
                    hasData = true;
                    preparedRemains[0] = {
                        ...remain,
                        wifi: remain.rest_amount >= 1
                    }
                    break;
                case 'VOICE':
                    preparedRemains[1] = remain
                    break;
                case 'SMS':
                    preparedRemains[2] = remain
                    break;
            }
            return remain;
        });

        if (!hasData && tariff && tariff.details && tariff.details.is_data_unlim) {
            preparedRemains[0] = {
                total_amount: "Безлимит",
                type: 'DATA',
                wifi: tariff.details.router,
                roaming: tariff && tariff.roaming_type === "N" ? true : false,
            }
            // if (tariff && tariff.roaming_type === "N") {
            //     preparedRemains[0] = {
            //         roaming_type: true,
            //         type: 'DATA',
            //         wifi: tariff.details.router,
            //     }
            // } else {
            //     preparedRemains[0] = {
            //         total_amount: "Безлимит",
            //         type: 'DATA',
            //         wifi: tariff.details.router,
            //     }
            // }
        }

        return preparedRemains;
    }

    prepareRemains = () => {
        let {
            remainsTotal,
            packageRemains
        } = this.props.clientInfo;

        let isHomeRegion = this.props.clientInfo && this.props.clientInfo.tariff && this.props.clientInfo.tariff.options && this.props.clientInfo.tariff.options.some((el) => el.id === 1994);

        let totalHome = [];
        packageRemains && packageRemains.forEach(element => {
            if (element.service.id === 1994) {
                totalHome.push(element)
            }
        });

        // всегда показывать тотал
        // if (isHomeRegion) {
        //     return this.totalForm(totalHome);
        // } else if (remainsTotal) {
            return this.totalForm(remainsTotal);
        // }
    }

    renderModal = () => {
        let {
            modal
        } = this.state;

        if (typeof document !== 'undefined') {
            if (modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        switch (modal) {
            case "AuthorizationModal":
                return <AuthorizationModal login={this.props.login}
                    onForgetLinkClick={
                        (e) => {
                            e.preventDefault();
                            this.openModal("RecoveryModal")
                        }
                    }
                    error={this.props.authFormError}
                    close={this.closeModal}
                    subtitle={this.props.formsSettings && this.props.formsSettings.auth_form.auth_form_description}
                    authFormSettings={this.props.formsSettings ? this.props.formsSettings.auth_form : {}}
                    qrText={this.props.qrText}
                />;
            case "RecoveryModal":
                return <RecoveryModal close={this.closeModal}
                    recover={this.props.recover}
                    error={this.props.recoveryFormError}
                    modal={this.state.modal}
                    recoveryFormSettings={this.props.formsSettings ? this.props.formsSettings.recovery_form : {}}
                />;

            case "ErrorModal":
                return <SuccessModal title={this.props.fatalErrorTitle}
                    text={this.props.fatalError}
                    icon={false}
                    btnText="ОК"
                    close={this.closeModal}
                />;
            case "SuccessModal":
                return <SuccessModal title={this.props.successMessageTitle}
                    text={this.props.successMessage}
                    icon={true}
                    btnText="ОК"
                    close={this.onForgotPasswordClickHandler}
                />;
            default:
                return "";
        }
    };

    onForgotPasswordClickHandler = () => {
        this.setState(() => {
            return {
                modal: null
            }
        }, () => {
            this.openModal('AuthorizationModal');
        });
    };

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        }, () => {
            this.props.clearData ? this.props.clearData() : "";
            this.props.clearSupportData ? this.props.clearSupportData() : "";
        });
    };

    closeModal = () => {
        this.setState({ modal: null })
    };

    login = () => {
        this.openModal("AuthorizationModal");
    };

    logout = () => {
        this.props.logout && this.props.logout()
    };

    checkAuth = () => {
        return this.props.isGuest;
    };

    renderRemain = (remain) => {
        let icon = null;

        switch (remain.type) {
            case "SMS":
                icon = <Icon name="icon_sms-header" className="u-icon u-icon_sms-header" />;
                break;
            case "VOICE":
                icon = <Icon name="icon_mobile" className="u-icon u-icon_mobile" />;
                break;
            default:
                icon = <Icon name="icon_internet-header" className="u-icon u-icon_internet-header" />;
        }

        return <div className="b-tariff-review__value" key={remain.type}>
            {remain.wifi ?
                <TooltipCustom
                    text={'На Вашем тарифе доступна раздача интернета на другие устройства'}
                    label='<span class="img-icon icon-wifi icon-wifi-header" />'
                    customClasses='wifi-tooltip'
                /> : null}
            <div className="b-tariff-review__value-icon">
                {icon}
            </div>
            <div className="b-tariff-review__value-text b-tariff-review__value-text-center">
                {!!remain.roaming 
                    ? "0 Гб"
                    : remain.total_amount === "Безлимит"
                        ? <span className="img-icon icon-infinity-black" />
                        : (remain.cost
                            ? remain.cost + " " + "₽/" + (remain.type === "DATA"
                                ? "Мб"
                                : remain.unit)
                            : remain.rest_amount + " " + remain.unit)
                }
                {/* {remain.total_amount === "Безлимит"
                    ? <span className="img-icon icon-infinity-black" />
                    : (remain.cost
                        ? remain.cost + " " + "₽/" + (remain.type === "DATA"
                            ? "Мб"
                            : remain.unit)
                        : remain.rest_amount + " " + remain.unit)
                } */}
                {/* {remain.total_amount === "Безлимит" ? <span className="img-icon icon-infinity-black"/> : remain.rest_amount+" "+remain.unit} */}
            </div>
        </div>
    };

    toggleMobileMenu = () => {
        this.setState((prevState) => {
            return {
                mobileMenu: !prevState.mobileMenu
            }
        })
    };

    render() {
        let region = this.context.selectedRegion.alias;
        let fetchBefore = this.context.fetchBefore;

        let {
            isGuest,
            isB2b,
            clientInfo,
            settings,
            regionSlug,
            slug,
            status_id,
            openMenu,
            callLogin,
            callLogout,
            allAppeals,
        } = this.props;

        let currentRegion = this.context.selectedRegion.alias;
        let remainsTotal = this.state.remainsTotal;
        const curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 1];

        let detailUrl = (regionSlug ? "/" + regionSlug : "") + "/tariffs";
        let detailUrlLk = (regionSlug ? regionSlug : region) + "/tariffs";

        if (slug) {
            switch (status_id) {
                case 2:
                    detailUrl += "/archive/";
                    detailUrlLk += "/archive/";
                    break;
                case 3:
                    detailUrl += "/individual/";
                    detailUrlLk += "/individual/";
                    break;
                default:
                    detailUrl += "/";
                    detailUrlLk += "/";
            }
            detailUrl += slug;
            detailUrlLk += slug;
        }
        let isHomeRegion = clientInfo && clientInfo.tariff && clientInfo.tariff.options && clientInfo.tariff.options.some((el) => el.id === 1994);

        return (
            <Fragment onClick={this.closeHighlight}>
                {!this.props.isB2b && (
                    this.props.showNewMenuHighlight && (
                        <div className="overlay-visitor overlay-active" onClick={this.closeHighlight}></div>
                    )
                )}

                <div className="b-header__middle-line">
                    <div className="page__container">
                        <div className="b-header__middle-items">
                            <div className="b-header__middle-item b-header__middle-item_logo">
                                <LinkC to={this.context.isLk ? mainUrl : "/"} className="b-header__logo-link" targetBlank={false}>
                                    <div className="logo-wrap">
                                        <Icon name={'icon_logo_mediator-arrows'} className={'mediator-arrows'} />
                                        <Icon name={'icon_logo_mediator-triangle'} className={'mediator-triangle'} />
                                        {isB2b
                                            ? <React.Fragment>
                                                <Icon name={'icon_logo_base_b2b'} className={'icon_logo_base_b2b u-icon_logo-base'} />
                                                <Icon name={'icon_logo_b2b'} className={'icon_logo_b2b u-icon_logo-base'} />
                                            </React.Fragment>
                                            : <Icon name={'icon_logo_base'} className={'u-icon_logo-base'} />
                                        }
                                    </div>
                                </LinkC>
                            </div>

                            {isGuest || isB2b ? null
                                : (
                                    <div className="b-header__middle-item b-header__middle-item_tariff">
                                        <div className="b-tariff-review b-tariff-review_adapt_default">
                                            <div className="b-tariff-review__items">
                                                {clientInfo.tariff ? <div className="b-tariff-review__item">
                                                    <div className="b-tariff-review__label">Ваш тариф</div>
                                                    <div className="b-tariff-review__values">
                                                        <div className="b-tariff-review__value">
                                                            <div className="b-tariff-review__value-text">«{clientInfo.tariff.title}»</div>
                                                        </div>
                                                    </div>
                                                </div> : null}

                                                {clientInfo.balance ? <div className="b-tariff-review__item">
                                                    <div className="b-tariff-review__label">Баланс</div>
                                                    <div className="b-tariff-review__values">
                                                        <div className="b-tariff-review__value">
                                                            <div className="b-tariff-review__value-text">
                                                                {clientInfo.balance.value}
                                                                <span className="u-rub">&nbsp;₽</span>
                                                                {/* credit balance condition */}
                                                                {false && settings && settings.credit_balance_comment ? <Tooltip
                                                                    id={"tooltip-balance"}
                                                                    label={<span className="img-icon icon-info" />}
                                                                    openedLabel={<span className="img-icon icon-info-select" />}
                                                                    className="numbers-tooltip"
                                                                    position="left"
                                                                >{settings.credit_balance_comment}</Tooltip> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null}

                                                {remainsTotal && remainsTotal.length
                                                    ? <div className="b-tariff-review__item">
                                                        <div className="b-tariff-review__label">
                                                            {this.props.clientInfo && this.props.clientInfo.tariff && this.props.clientInfo.tariff.roaming_type === 'N' ? 'Остатки в поездках по России' : 'На вашем тарифе'}
                                                        </div>
                                                        <div className="b-tariff-review__values">
                                                            {remainsTotal.map((remain) => this.renderRemain(remain))}
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {isB2b ?
                              <Fragment>
                                  <div className="b-header__middle-item b-header__middle-item_my_number">
                                      <div className="b-profile-line">
                                          <div className="b-profile-line__unauth-state">
                                              <a href="https://lk-business.motivtelecom.ru/" className="b-profile-line__auth-button" onClick={() => {}}>
                                                  <span className="b-profile-line__auth-text">{'Личный кабинет'}</span>
                                                  <span className="b-profile-line__icon-holder">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                          fill-rule="evenodd"
                                                          clip-rule="evenodd"
                                                          d="M8.28217 7.53217C8.57506 7.23928 9.04994 7.23928 9.34283 7.53217L13.2803 11.4697C13.5732 11.7626 13.5732 12.2374 13.2803 12.5303L9.34283 16.4678C9.04994 16.7607 8.57506 16.7607 8.28217 16.4678C7.98928 16.1749 7.98928 15.7001 8.28217 15.4072L11.6893 12L8.28217 8.59283C7.98928 8.29994 7.98928 7.82506 8.28217 7.53217Z"
                                                        />
                                                        <path
                                                          fill-rule="evenodd"
                                                          clip-rule="evenodd" d="M1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H12.75C13.1642 11.25 13.5 11.5858 13.5 12C13.5 12.4142 13.1642 12.75 12.75 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12Z"
                                                        />
                                                        <path
                                                          fill-rule="evenodd"
                                                          clip-rule="evenodd"
                                                          d="M12 3.75C12 3.33579 12.3358 3 12.75 3H18C18.3978 3 18.7794 3.15804 19.0607 3.43934C19.342 3.72065 19.5 4.10218 19.5 4.5V19.5C19.5 19.8978 19.342 20.2794 19.0607 20.5607C18.7794 20.842 18.3978 21 18 21H12.75C12.3358 21 12 20.6642 12 20.25C12 19.8358 12.3358 19.5 12.75 19.5H18V4.5L12.75 4.5C12.3358 4.5 12 4.16421 12 3.75Z"
                                                        />
                                                    </svg>
                                                </span>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="b-header__mobile-profile">
                                      <div className={"b-profile-line b-profile-line_adapt_default" + (isGuest ? "" : " b-profile-line_authorized")}>

                                          <div className="b-profile-line__unauth-state">
                                              <a href="https://lk-business.motivtelecom.ru/" className="b-profile-line__auth-link">
                                                  <span className="b-profile-line__auth-text">Личный кабинет</span>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </Fragment>
                                : (
                                    <Fragment>
                                        <div className="b-header__middle-item b-header__middle-item_my_number">
                                            <div className="b-profile-line">
                                                {isGuest
                                                    ? (this.context.isLk
                                                        ? null
                                                        : <div className="b-profile-line__unauth-state">
                                                            <div className="b-profile-line__auth-button" onClick={() => {callLogin()}}>
                                                                <span className="b-profile-line__auth-text">{'Войти'}</span>
                                                                <span className="b-profile-line__icon-holder">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M8.28217 7.53217C8.57506 7.23928 9.04994 7.23928 9.34283 7.53217L13.2803 11.4697C13.5732 11.7626 13.5732 12.2374 13.2803 12.5303L9.34283 16.4678C9.04994 16.7607 8.57506 16.7607 8.28217 16.4678C7.98928 16.1749 7.98928 15.7001 8.28217 15.4072L11.6893 12L8.28217 8.59283C7.98928 8.29994 7.98928 7.82506 8.28217 7.53217Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd" d="M1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H12.75C13.1642 11.25 13.5 11.5858 13.5 12C13.5 12.4142 13.1642 12.75 12.75 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12Z"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M12 3.75C12 3.33579 12.3358 3 12.75 3H18C18.3978 3 18.7794 3.15804 19.0607 3.43934C19.342 3.72065 19.5 4.10218 19.5 4.5V19.5C19.5 19.8978 19.342 20.2794 19.0607 20.5607C18.7794 20.842 18.3978 21 18 21H12.75C12.3358 21 12 20.6642 12 20.25C12 19.8358 12.3358 19.5 12.75 19.5H18V4.5L12.75 4.5C12.3358 4.5 12 4.16421 12 3.75Z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="b-profile-line__auth-state">
                                                            <div className="b-profile-line__current">
                                                                {
                                                                    clientInfo.username
                                                                        ? <div className="b-profile-line__number b-profile-line__number_current">
                                                                            <div className="b-profile-line__number-label">Мой номер</div>
                                                                            <div className="b-profile-line__number-value">{phoneMask(clientInfo.username)}</div>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                            <div className="b-profile-line__dropdown">
                                                                <div className="b-profile-line__section">
                                                                    <div className="b-profile-line__ui">
                                                                        <div className="b-profile-line__ui-item">
                                                                            {regionSlug && (
                                                                                <a className="b-profile-line__ui-link" href={this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl}>
                                                                                    <span className="b-profile-line__icon-holder">
                                                                                        <Icon name="icon_simka" className="b-profile-line__ui-icon u-icon u-icon_simka" />
                                                                                    </span>
                                                                                    <span className="b-profile-line__ui-text">О тарифе</span>
                                                                                </a>
                                                                            )}
                                                                            {!regionSlug && (
                                                                                <LinkC className="b-profile-line__ui-link" to={this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl}>
                                                                                    <span className="b-profile-line__icon-holder">
                                                                                        <Icon name="icon_simka" className="b-profile-line__ui-icon u-icon u-icon_simka" />
                                                                                    </span>
                                                                                    <span className="b-profile-line__ui-text">О тарифе</span>
                                                                                </LinkC>
                                                                            )}
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <LinkC className="b-profile-line__ui-link" to={lkUrl + region + "/services"}>
                                                                                <span className="b-profile-line__icon-holder">
                                                                                    <Icon name="icon_switchers" className="b-profile-line__ui-icon u-icon u-icon_switchers" />
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">Подключенные услуги</span>
                                                                            </LinkC>
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <LinkC className="b-profile-line__ui-link" to={lkUrl + region + "/appeals"}>
                                                                                <span className="b-profile-line__icon-holder u-icon_say_clouds">
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path d="M6.04688 15.7734L3.52344 17.8828C3.43238 17.9582 3.32183 18.0061 3.20461 18.0212C3.08738 18.0363 2.96829 18.0178 2.86115 17.9679C2.75401 17.918 2.66321 17.8387 2.59929 17.7393C2.53536 17.6399 2.50094 17.5244 2.5 17.4063V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H16.875C17.0408 4.375 17.1997 4.44085 17.3169 4.55806C17.4342 4.67527 17.5 4.83424 17.5 5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H6.44531C6.29899 15.625 6.15756 15.6777 6.04688 15.7734V15.7734Z" stroke="#FA6600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        <path d="M10 10.9375C10.5178 10.9375 10.9375 10.5178 10.9375 10C10.9375 9.48223 10.5178 9.0625 10 9.0625C9.48223 9.0625 9.0625 9.48223 9.0625 10C9.0625 10.5178 9.48223 10.9375 10 10.9375Z" fill="#FA6600"/>
                                                                                        <path d="M6.25 10.9375C6.76777 10.9375 7.1875 10.5178 7.1875 10C7.1875 9.48223 6.76777 9.0625 6.25 9.0625C5.73223 9.0625 5.3125 9.48223 5.3125 10C5.3125 10.5178 5.73223 10.9375 6.25 10.9375Z" fill="#FA6600"/>
                                                                                        <path d="M13.75 10.9375C14.2678 10.9375 14.6875 10.5178 14.6875 10C14.6875 9.48223 14.2678 9.0625 13.75 9.0625C13.2322 9.0625 12.8125 9.48223 12.8125 10C12.8125 10.5178 13.2322 10.9375 13.75 10.9375Z" fill="#FA6600"/>
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">
                                                                                    Мои обращения {allAppeals.length > 0 && <span className="b-profile-line__ui_appeals_counter">{allAppeals.length}</span>}
                                                                                </span>
                                                                            </LinkC>
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <LinkC className="b-profile-line__ui-link" to={this.context.isLk ? '/' : lkUrl + region}>
                                                                                <span className="b-profile-line__icon-holder">
                                                                                    <Icon className="b-profile-line__ui-icon u-icon u-icon_gear" name="icon_gear" />
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">Настройка аккаунта</span>
                                                                            </LinkC>
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <a className="b-profile-line__ui-link" href="#" onClick={(e) => { e.preventDefault(); callLogout(); }}>
                                                                                <span className="b-profile-line__icon-holder">
                                                                                    <Icon className="b-profile-line__ui-icon u-icon u-icon_out" name="icon_out" />
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">Выход</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="b-header__mobile-profile">
                                            <div className={"b-profile-line b-profile-line_adapt_default" + (isGuest ? "" : " b-profile-line_authorized")}>
                                                {
                                                    isGuest ? (this.context.isLk
                                                        ? null
                                                        : (
                                                            <div className="b-profile-line__unauth-state">
                                                                <a className="b-profile-line__auth-link" onClick={() => {callLogin()}}>
                                                                    <span className="b-profile-line__auth-text">Войти</span>
                                                                </a>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className={"b-profile-line__auth-state" + (this.state.mobileMenu ? " b-profile-line__auth-state_open" : "")}>
                                                            <div className="b-profile-line__current" onClick={this.toggleMobileMenu}>
                                                                {clientInfo.username ? <div key={"b-profile-line__auth-state-" + (this.state.mobileMenu ? "open" : "close")} className="b-profile-line__number b-profile-line__number_current">
                                                                    <div className="b-profile-line__number-label">Ваш номер</div>
                                                                    <div className="b-profile-line__number-value">{phoneMask(clientInfo.username)}</div>
                                                                </div> : null}
                                                            </div>
                                                            <div className="b-profile-line__dropdown">
                                                                <div className="b-profile-line__section">
                                                                    <div className="b-profile-line__ui">
                                                                        <div className="b-profile-line__ui-item">
                                                                            {regionSlug && (
                                                                                <a className="b-profile-line__ui-link" href={this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl}>
                                                                                    <span className="b-profile-line__icon-holder">
                                                                                        <Icon name="icon_simka" className="b-profile-line__ui-icon u-icon u-icon_simka" />
                                                                                    </span>
                                                                                    <span className="b-profile-line__ui-text">О тарифе</span>
                                                                                </a>
                                                                            )}
                                                                            {!regionSlug && (
                                                                                <LinkC className="b-profile-line__ui-link" to={this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl}>
                                                                                    <span className="b-profile-line__icon-holder">
                                                                                        <Icon name="icon_simka" className="b-profile-line__ui-icon u-icon u-icon_simka" />
                                                                                    </span>
                                                                                    <span className="b-profile-line__ui-text">О тарифе</span>
                                                                                </LinkC>
                                                                            )}
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <LinkC className="b-profile-line__ui-link" to={lkUrl + region + "/services"}>
                                                                                <span className="b-profile-line__icon-holder">
                                                                                    <Icon name="icon_switchers" className="b-profile-line__ui-icon u-icon u-icon_switchers" />
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">Подключенные услуги</span>
                                                                            </LinkC>
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <LinkC className="b-profile-line__ui-link" to={lkUrl + region + "/appeals"}>
                                                                                <span className="b-profile-line__icon-holder u-icon_say_clouds">
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M6.04688 15.7734L3.52344 17.8828C3.43238 17.9582 3.32183 18.0061 3.20461 18.0212C3.08738 18.0363 2.96829 18.0178 2.86115 17.9679C2.75401 17.918 2.66321 17.8387 2.59929 17.7393C2.53536 17.6399 2.50094 17.5244 2.5 17.4063V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H16.875C17.0408 4.375 17.1997 4.44085 17.3169 4.55806C17.4342 4.67527 17.5 4.83424 17.5 5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H6.44531C6.29899 15.625 6.15756 15.6777 6.04688 15.7734V15.7734Z" stroke="#FA6600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        <path d="M10 10.9375C10.5178 10.9375 10.9375 10.5178 10.9375 10C10.9375 9.48223 10.5178 9.0625 10 9.0625C9.48223 9.0625 9.0625 9.48223 9.0625 10C9.0625 10.5178 9.48223 10.9375 10 10.9375Z" fill="#FA6600"/>
                                                                                        <path d="M6.25 10.9375C6.76777 10.9375 7.1875 10.5178 7.1875 10C7.1875 9.48223 6.76777 9.0625 6.25 9.0625C5.73223 9.0625 5.3125 9.48223 5.3125 10C5.3125 10.5178 5.73223 10.9375 6.25 10.9375Z" fill="#FA6600"/>
                                                                                        <path d="M13.75 10.9375C14.2678 10.9375 14.6875 10.5178 14.6875 10C14.6875 9.48223 14.2678 9.0625 13.75 9.0625C13.2322 9.0625 12.8125 9.48223 12.8125 10C12.8125 10.5178 13.2322 10.9375 13.75 10.9375Z" fill="#FA6600"/>
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">
                                                                                    Мои обращения {allAppeals.length > 0 && <span className="b-profile-line__ui_appeals_counter">{allAppeals.length}</span>}
                                                                                </span>
                                                                            </LinkC>
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <LinkC className="b-profile-line__ui-link" to={this.context.isLk ? '/' : lkUrl + region}>
                                                                                <span className="b-profile-line__icon-holder">
                                                                                    <Icon name="icon_gear" className="b-profile-line__ui-icon u-icon u-icon_gear" />
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">Настройка аккаунта</span>
                                                                            </LinkC>
                                                                        </div>
                                                                        <div className="b-profile-line__ui-item">
                                                                            <a className="b-profile-line__ui-link" href="#" onClick={(e) => { e.preventDefault(); callLogout(); }}>
                                                                                <span className="b-profile-line__icon-holder">
                                                                                    <Icon name="icon_out" className="b-profile-line__ui-icon u-icon u-icon_out" />
                                                                                </span>
                                                                                <span className="b-profile-line__ui-text">Выход</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }

                            <div
                                className={`b-header__middle-item b-header__middle-item_burger ${isB2b ? 'b-header__burger-holder__b2b' : ''} ${this.props.showNewMenuHighlight ? 'burger-highlight' : ''}`}
                                onClick={this.props.openMenu}
                            >
                                <span className="b-header__burger-btn">
                                    <Icon name={'icon_menu-burger'} className={'b-header__burger u-icon u-icon_menu-burger'} />
                                    <Icon name={'icon_cross'} className={'b-header__close-icon u-icon u-icon_cross'} />
                                </span>

                                {this.props.showNewMenuHighlight && (
                                    <div className="highlight-menu-message">
                                        Посмотрите, у нас новое меню!
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>
                    {/*{(curentPath !== '' && curentPath !== '4g' && curentPath !== currentRegion) &&*/}
                    {/*    <div className="wrap_balans_user">*/}
                    {/*        <BalansBlock {...this.props} />*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {this.renderModal()}
                </div>
            </Fragment>
        )
    }
}

export default HeaderMain;