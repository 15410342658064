import React, {Component} from 'react'
import PropsTypes from 'prop-types'
import Icon from "../common/Icon"
import InputError from "../common/InputError"
import Date from "../common/Date"
import {fileValidator} from "../../tools/form-helper"
import {prepareDescription} from '../../tools/tools'

export default class ChangeInfoForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            values: {
                fullname: "",
                birthday: "",
                file: []
            },
            errors: {
                fullname: "",
                birthday: "",
                file: ""
            },
            agreement: false
        };

        this.allowedTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
        this.maxSize = 5*1024*1024;
        this.maxFiles = 5;
        this.fileError = "Один или несколько файлов не соответствуют требованиям: *.pdf, *.jpg, *.jpeg, *.png, не более 5 МБ";
    }


    static contextTypes = {
        selectedRegion: PropsTypes.object
    };

    static getDerivedStateFromProps(props, state){
        if (JSON.stringify(props.errors) != JSON.stringify(state.propsErrors)  && JSON.stringify(state.errors) != JSON.stringify(props.errors)) {
            return {...state, errors: props.errors, propsErrors: props.errors}
        }
        if (props.clear) {
            return {
                values: {
                    fullname: "",
                    birthday: "",
                    file: null
                },
                errors: {
                    fullname: "",
                    birthday: "",
                    file: ""
                }
            }
        }
        return state;
    }

    onChange = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                values: {
                    ...prevState.values,
                    [target.name]: target.value
                }
            }
        })
    };

    onDateChange = (name) => {
        return (value) => {

            this.setState((prevState) => {
                return {
                    ...prevState,
                    values: {
                        ...prevState.values,
                        [name]: value ? value.split('.').reverse().join('-') : ""
                    }
                }
            })
        }
    };

    onFileChange = (e) => {
        if (e.target.files.length) {

            var file = Array.from(e.target.files).slice(0, this.maxFiles);

            if (!fileValidator(file, this.allowedTypes, this.maxSize)) {
                this.setState((prevState) => {
                    return {
                        errors: {
                            ...prevState.errors,
                            file: this.fileError
                        }
                    }
                });
            } else {
                this.setState((prevState) => {
                    return {
                        errors: {
                            ...prevState.errors,
                            file: ""
                        }
                    }
                });
            }

           this.setState((prevState) => {
               return {
                   values: {
                       ...prevState.values,
                       file: [...prevState.values.file, ...file]
                   }
               }
           });

        }
    };

    unsetFile = (key) => {
       this.setState((prevState) => {

           var file = [...prevState.values.file];
           file.splice(key, 1);

           return {
               values: {
                   ...prevState.values,
                   file
               },
               errors: {
                   ...prevState.errors,
                   file: fileValidator(file, this.allowedTypes, this.maxSize) ? "" : this.fileError
               }
           }
       });
    };

    onBlur = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [target.name]: this.checkError(target.value, true)
                }
            }
        })
    };

    onFocus = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [target.name]: ""
                }
            }
        })
    };

    checkError = (value, required, validator) => {
        if ((!value || !value.length) && required) {
            return "Обязательно для заполнения";
        }
        if (validator && !validator(value, this.allowedTypes, this.maxSize)) {
            return this.fileError
        }
        return "";
    };

    onSubmit = (e) => {
        e.preventDefault();

        let {
            values
        } = this.state;

        this.setState({
            errors: {
                fullname: this.checkError(values.fullname, true),
                birthday: this.checkError(values.birthday, true),
                file: this.checkError(values.file, true, fileValidator)
            }
        }, () => {
            let {
                errors
            } = this.state;

            if (values.fullname && values.birthday && values.file && !errors.fullname && !errors.birthday && !errors.file) {
                var data = new FormData();
                data.append('fullname', values.fullname);
                data.append('birthday', values.birthday);
                values.file.map((file, key) => {
                    data.append('file'+(key > 0 ? key + 1 : ''), file);
                });
                data.append('phone', this.props.number);
                this.props.onSubmit(data)
            }
        });
    };

    inputProps = (name) => {

        let {
            values,
            errors
        } = this.state;

        return {
            name,
            value: values[name],
            error: errors[name],
            onChange: name === "birthday" ? this.onDateChange(name) : this.onChange,
            [name === 'fullname' ? 'onBlur' : 'onblur']: this.onBlur,
            [name === 'fullname' ? 'onFocus' : 'onfocus']: this.onFocus
        }
    };

    toggleAgreement = () => {
        this.setState((prevState) => {
            return {
                agreement: !prevState.agreement
            }
        })
    };

    render(){

        let {
            title,
            description,
            checkReq
        } = this.props;

        let {
            values,
            errors,
            agreement
        } = this.state;

        return <div className="b-form-change-data b-form-change-data_adapt_default order-details-form">
            <div className="b-form-change-data__wrapper">
                <div className="b-form-change-data__title">{title}</div>
                {description ? <div className="b-form-change-data__subtitle" dangerouslySetInnerHTML={{__html: prepareDescription(description, this.context.selectedRegion.alias)}}/> : null}
                <form className="b-form-change-data__form-area b-form-change-request" onSubmit={this.onSubmit}>
                    <div className="b-form-change-data__row">
                        <div className="b-form-change-data__item b-form-change-data__item_fullname">
                            <div className="form-group">
                                <label className="b-input-text__label b-input-text__label b-input-text__label_upper form-group-label">ФИО:</label>
                                <input className={"b-input-text__input"+(errors.fullname ? " has-error" : "")}
                                       {...this.inputProps('fullname')}
                                       placeholder="Фамилия Имя Отчество"
                                />
                                <InputError hasError={!!errors.fullname} errorText={errors.fullname}/>
                            </div>
                        </div>
                        <div className="b-form-change-data__item b-form-change-data__item_date">
                            <div className="form-group date-icon-outer">
                                <label className="b-input-text__label b-input-text__label b-input-text__label_upper form-group-label">Дата рождения:</label>
                                <Date {...this.inputProps('birthday')} hasError={!!errors.birthday} allowYearDropdown={true}/>
                                <InputError hasError={!!errors.birthday} errorText={errors.birthday}/>
                            </div>
                        </div>
                        <div className="form-group b-form-change-data__item b-form-change-data__item_btn change-request-btn">
                            <div className="b-form-change-data__btn-area">
                                <button type="submit" className={"u-btn u-btn_default u-btn_responsive u-btn_adapt_mobile-sm"+(
                                    values.fullname && values.birthday && values.file && !errors.fullname && !errors.birthday && !errors.file && !!agreement && checkReq ? "" : " disabled"
                                )}>Отправить</button>
                            </div>
                        </div>
                    </div>
                    <div className="b-form-change-data__row checkbox-consent-row">
                        <div className="b-step-form__block b-step-form__block_checkbox-agree">
                            <div className="b-step-form__checkbox-item">
                                <div className="b-input-checkbox b-input-checkbox_adapt_mobile-small">
                                    <label className="b-input-checkbox__wrap" htmlFor="checkbox_agree">
                                        <input className="b-input-checkbox__input"
                                               type="checkbox"
                                               id="checkbox_agree"
                                               onChange={this.toggleAgreement}
                                               checked={agreement ? "checked" : false}
                                        />
                                        <span className="b-input-checkbox__icon"/>
                                        <span className="b-input-checkbox__text">Я даю согласие на обработку моих персональных данных</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {values.file.map((file, key) => <div key={key} className="attached-files">
                        {file.name}
                        <span className="b-form-change-data__attach-text close" onClick={() => {this.unsetFile(key)}}>&times;</span>
                    </div>)}
                    {errors.file ? <div className="b-error-text">{errors.file}</div> : null}
                    {values.file.length < this.maxFiles ? <div className="b-form-change-data__attach-area">
                        <label htmlFor="file-input" className="b-form-change-data__attach-inner">
                            <input id="file-input" multiple={true} className="b-form-change-data__attach-input" type="file" onChange={this.onFileChange}/>
                            <Icon name="icon_clip" className="b-form-change-data__attach-icon u-icon u-icon_clip"/>
                            <span className="b-form-change-data__attach-text">Прикрепить файл (*.pdf, * .jpg,* .jpeg, *.png, не более 5 МБ)</span>
                        </label>
                    </div> : null}
                    <div className="form-group b-form-change-data__item b-form-change-data__item_btn change-request-btn--mobile">
                        <div className="b-form-change-data__btn-area">
                            <button type="submit" className={"u-btn u-btn_default u-btn_responsive u-btn_adapt_mobile-sm"+(
                                values.fullname && values.birthday && values.file && !errors.fullname && !errors.birthday && !errors.file && !!agreement ? "" : " disabled"
                            )}>Отправить</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    }
}