import React, {Component} from 'react';
import {mediaUrl} from "../../tools/config";
import Slider from 'react-slick';
import LinkC from "../../../js/components/common/LinkC";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

class CarouselPromotions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dots: []
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.slickTo = this.slickTo.bind(this);
    }

    componentWillMount () {
        let dots = [];

        this.props.blocks.forEach(function (el, i) {
            dots.push({
                active: i === 0,
            });
        });

        this.state.dots = dots;
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    slickTo (e) {
        let idx = e.target.getAttribute('data-index');
        this.slider.slickGoTo(idx);
    }

    changeDot (idx) {
        this.setState(() => {
            this.state.dots.forEach(function (el) {
                el.active = false;
            });
            this.state.dots[idx].active = true;
            return {dots : this.state.dots}
        });
    }

    render() {
        let blocks = this.props.blocks;
        let typeSettings = blocks.length ? blocks[0].type : {};

        const settings = {
            dots: false,
            infinite: true,
            autoplay: typeSettings.allow_auto_slick,
            speed: 1000,
            autoplaySpeed: typeSettings.auto_slick_time*1100,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            draggable: false,
            beforeChange: (current, next) => this.changeDot(next)
        };

        return (
            <div className="b-services-list__services-slider b-services-listpromotion_page_slider">
                <div className="b-service-slider b-service-slider_adapt_default js-init">
                    <div className="b-service-slider__wrapper">
                        <div className="b-service-slider__items-list">
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {blocks.map((block, index) =>
                                    <div className="about-page__slider-item promotion_page__slider_wrapper" key={index}>
                                        <AdvertisingLabeling
                                            token={block && block.token}
                                            advertiser={block && block.advertiser}
                                        />
                                        <img src={mediaUrl + block.imagePath} alt={block.title} />
                                        <div className="about-page__slider-item-content promotion_page__slider">
                                            <h3 className="about-page__slider-item-content-title promotion_page__slider_title" dangerouslySetInnerHTML={{ __html: block.title }} />

                                            <div className="promotion_page__slider_text" dangerouslySetInnerHTML={{ __html: block.text }} />
                                            <LinkC
                                                to={block.link}
                                                className="banner_wrapper_text_btn promotion_page__slider_btn"
                                                targetBlank={block.target_blank}
                                            >{block.link_title}</LinkC>
                                        </div>
                                    </div>
                                )}
                            </Slider>
                        </div>

                        {blocks && blocks.length > 1 
                            ?   <div className="b-service-slider__controls promotion_page__slider_controls">
                                    <div className="b-service-slider__arrows">
                                        <div onClick={this.previous} className="b-service-slider__arrow b-service-slider__arrow_left" />
                                        <div onClick={this.next} className="b-service-slider__arrow b-service-slider__arrow_right" />
                                    </div>
                                    <div className="b-service-slider__dots">
                                        <ul className="slick-dots" role="tablist">
                                            {this.state.dots.map((dot, index) =>
                                                <li className={dot.active ? "slick-active" : ""} role="presentation" key={index}>
                                                    <button type="button" onClick={this.slickTo} data-index={index}/>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        className="b-main-slider__circle"
                                                        version="1.1"
                                                        viewBox="0 0 60 60">
                                                        <circle className="circle__background" cx="30" cy="30" r="25" stroke="hsla(0,0%,100%,.5)" strokeWidth="3" fill="rgba(0,0,0,0)"/>
                                                        <circle className="b-main-slider__path" style={{animationDuration: (7*typeSettings.auto_slick_time)+"s"}} cx="30" cy="30" r="25" stroke="#fff" strokeWidth="3" fill="rgba(0,0,0,0)"/>
                                                    </svg>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            :   null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CarouselPromotions;