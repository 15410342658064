import React, {Component} from "react";
import FormField from "./FormField";
import {phoneMask} from "../../tools/mask";
import {emailValidatorText, getFormData, phoneValidatorText} from "../../tools/form-helper";
import { connect } from "react-redux";
import {submitMnpForm} from "../../actions/forms";
import PdfLink from "./PdfLink";
import PropTypes from "prop-types";

class CallbackConnectionForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {
                form_name: {
                    value: "Заказать_звонок"
                },
                username: {
                    type: "text",
                    name: "username",
                    label: "Как вас зовут?",
                    value: "",
                    placeholder: "Иван",
                    error: ""
                },
                phone: {
                    type: "text",
                    name: "phone",
                    label: "Введите контактный номер телефона",
                    value: "",
                    placeholder: "+7",
                    error: "",
                    mask: phoneMask,
                    validate: phoneValidatorText,
                    typeInput: "phone"
                },
                email: {
                    type: "text",
                    name: "email",
                    label: "Ваш e-mail",
                    value: "",
                    placeholder: "info@mail.ru",
                    hint: "На указанный электронный адрес мы вышлем письмо с подробным описанием подключаемого тарифа.",
                    error: "",
                    validate: emailValidatorText,
                    typeInput: "email"
                },
                i_agree: {
                    type: "checkbox",
                    name: "i_agree",
                    label: <span dangerouslySetInnerHTML={{__html: props.settings.rules_checkbox_text}}/>,
                    value: false,
                    error: ""
                },
                tariff_conditions: {
                    type: "checkbox",
                    name: "tariff_conditions",
                    label: <span>С <PdfLink constructor={props.constructor} className="link-accent">условиями тарифа</PdfLink> ознакомлен, условия понятны, претензий к Оператору не имею.</span>,
                    value: false,
                    error: ""
                }
            },
            formValid: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        constructorParams: PropTypes.object,
    };

    onChange = (name, value) => {
        let fields = {...this.state.fields};

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        fields[name].error = fields[name].validate ? fields[name].validate(fields[name].value) : "";

        this.setState({fields});

        this.validateForm();
    };

    componentWillReceiveProps (nextProps) {
        if (nextProps.forms.hasOwnProperty('isSubmitOk')) {
            this.props.openSuccess('success');
        }
    }

    validateForm = () => {
        let fields = {...this.state.fields};

        let formValid = true;

        if (fields['phone'].validate(fields['phone'].value) !== "") {
            formValid = false;
        }
        /*if (fields['email'].validate(fields['email'].value) !== "") {
            formValid = false;
        }*/

        /*if (fields['tariff_conditions'].value === false) {
            formValid = false;
        }*/

        this.setState({formValid: formValid});
    };

    renderField = (name) => {
        return <FormField {...this.state.fields[name]} onChange={this.onChange}/>;
    };

    onSubmit = (e) => {
        e.preventDefault();
        let fields = Object.assign({}, this.state.fields);
        let constructor = this.context.constructorParams;
        let settings = this.props.settings;
        let urlStr = this.props.urlStr;

        let data = new FormData();

        fields.phone.value = fields.phone.value.replace(/\D/gim, '');

        data.append('form_name', fields.form_name.value);
        data.append('username', fields.username.value);
        data.append('phone', fields.phone.value);
        data.append('email', fields.email.value);
        data.append('region', this.context.selectedRegion.name);
        data.append('constructor_info[id]', this.props.settings.constructor_tariff_id);
        data.append('constructor_info[name]', this.props.userTariffName);
        data.append('constructor_info[min]', constructor.min);
        data.append('constructor_info[traffic]', constructor.gb);
        data.append('constructor_info[sms]', constructor.sms ? settings.sms_pack_count : 0);
        data.append('constructor_info[abon]', this.props.price.fee_with_sms);
        data.append('constructor_info[abon_discount]', this.props.price.fee_with_sms_promo);
        data.append('constructor_info[social_network_unlim]', constructor.social_unlim);
        data.append('constructor_info[data_unlim]', constructor.data_unlim);
        data.append('constructor_info[motiv_voice_unlim]', constructor.motiv_voice_unlim);

        this.props.submitForm(data, 'callback_form');
    };

    render(){

        return <div className="callback-connection">
            <div className="text-lg">Оставьте свой номер, и наши специалисты помогут вам с оформлением заявки</div>
            <form>
                <div className="form-row">
                    {this.renderField("username")}
                    {this.renderField("phone")}
                    {/* <div className="form-group" />*/}
                </div>
                <div className="light-info">Если у вас остались вопросы, задайте их по телефону: <b>8 (800) 240 00 00</b></div>
                <div className="form-row row-bottom-form">
                    <div>

                    </div>
                    <button id="st-call-button" onClick={this.onSubmit} type="submit" className={"btn btn-accent btn-chevron-right"+(this.state.formValid ? "" : " disabled")}>Отправить</button>
                </div>
            </form>
        </div>
    }
}

export default connect(
    state => ({
        forms: state.forms,
        settings: state.constructor.settings,
        price: state.constructor.price,
    }),
    (dispatch) => {
        return {
            submitForm: (formData, alias) => dispatch(submitMnpForm(formData, alias, 'Конструктор_тарифа')),
        }
    }
)(CallbackConnectionForm);
