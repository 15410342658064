import React, {Component} from "react";
import PropTypes from "prop-types";
import Icon from "../common/Icon";
import Select from "../common/Select";
import DatePicker from "../common/Date";

import {numberMask, documentNumberMask, unitCodeMask, onlyCyrillicChars} from "../../tools/mask";
import {getObjectData, emptyValidator, usernameValidator, docNumberValidator, unitCodeValidator} from "../../tools/form-helper";
import Tooltip from "../common/Tooltip";
import {normalizeForSelect} from "../../tools/tools";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";

export default class PersonalInfoForm extends Component{
    constructor(props, context){
        super(props, context);

        let documents = [];
        let defaultDoc = '';

        if (props.personal_info_documents.length > 0) {

            documents = props.personal_info_documents.map((item) => {
                return {
                    label: item.title,
                    value: item.title
                }
            });

            let defaultDocs = props.personal_info_documents.filter(x => x.default === 1);

            defaultDoc = defaultDocs.length > 0 ? defaultDocs[0].title : '';
        }

        var maxBirthday = new Date();
        maxBirthday.setYear(maxBirthday.getFullYear() - 14);

        this.state = {
            fields: {
                fio: {
                    type: "text",
                    label: "ФИО",
                    value: "",
                    required: true,
                    mask: onlyCyrillicChars,
                    validators: [usernameValidator],
                    hasError: null,
                    textError: "Укажите ФИО"
                },
                birth_date: {
                    type: "date",
                    label: "Дата рождения",
                    maxDate: maxBirthday,
                    value: "",
                    required: true,
                    allowYearDropdown: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите дату рождения"
                },
                birth_place: {
                    type: "text",
                    label: "Место рождения",
                    value: "",
                    required: true,
                    validators: [usernameValidator],
                    hasError: null,
                    textError: "Укажите место рождения"
                },
                document: {
                    type: "select",
                    label: "Документ",
                    value: defaultDoc,
                    required: true,
                    options: documents
                },
                document_number: {
                    type: "text",
                    label: "Серия и номер",
                    value: "",
                    required: true,
                    mask: documentNumberMask,
                    validators: [docNumberValidator],
                    hasError: null,
                    textError: "Укажите серию и номер паспорта"
                },
                who_issued: {
                    type: "text",
                    label: "Кем выдан",
                    value: "",
                    required: true,
                    validators: [usernameValidator],
                    hasError: null,
                    textError: "Необходимо указать, кем выдан документ"
                },
                issue_date: {
                    type: "date",
                    label: "Дата выдачи",
                    value: "",
                    required: true,
                    allowYearDropdown: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите дату выдачи"
                },
                unit_code: {
                    type: "text",
                    label: "Код подразделения",
                    value: "",
                    mask: unitCodeMask,
                    hasError: null,
                    textError: ""
                },
                reg_region: {
                    type: "region",
                    label: "Область",
                    value: "",
                    options: [],
                    trigger: false,
                    required: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите регион регистрации"
                },
                reg_city: {
                    type: "city",
                    label: "Город",
                    value: "",
                    options: [],
                    trigger: false,
                    required: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите город регистрации"
                },
                reg_address: {
                    type: "address",
                    label: "Улица",
                    value: "",
                    required: true,
                    options: [],
                    trigger: false,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите улицу"
                },
                reg_house: {
                    type: 'house',
                    label: "Дом",
                    value: "",
                    required: true,
                    trigger: false,
                    options: [],
                    validators: [emptyValidator],
                    hasError: null
                },
                reg_block: {
                    type: 'block',
                    label: "Корпус",
                    value: "",
                    required: false,
                    trigger: false,
                    options: []
                },
                reg_flat: {
                    type: "text",
                    label: "Квартира",
                    value: "",
                    required: false,
                    hasError: null,
                    textError: ""
                },
                reg_postcode: {
                    type: "text",
                    label: "Индекс",
                    value: "",
                    mask: numberMask(6),
                    hasError: null,
                    textError: ""
                }
            }
        }
    }

    onFieldChange = (name) => {
        return (e) => {
            let fields = {...this.state.fields};
            fields[name].value = fields[name].mask ? fields[name].mask(e.target.value) : e.target.value;
            this.setState({fields});
        }
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    renderField = (name) => {
        let field = {...this.state.fields[name]};
        let fields = this.state.fields;
        let input = null;

        switch (field.type) {
            case "select":
                input = <Select options={field.options || []}
                                value={field.value}
                                className="b-choose-plates__select"
                                onChange={(attr, value) => {
                                    let fields = {...this.state.fields};
                                    fields[name].value = value;
                                    fields[name].hasError = false;
                                    this.setState({fields});
                                }}
                                hasError={field.hasError}
                                onblur={this.onBlur(name)}
                                onfocus={this.onFocus(name)}
                                errorText={field.textError}
                />;
                break;
            case "address":
            case "city":
            case "region":
            case "house":
            case "block":

                let additionalParams = {};
                let options = [];

                let {
                    reg_city,
                    reg_region
                } = this.state.fields;

                if (field.type === "region") {
                    if (this.props.forms.hasOwnProperty('region') && this.props.forms.region.length > 0) {
                        options = normalizeForSelect(this.props.forms.region);
                    }
                }

                if (field.type === "address") {
                    if (this.props.forms.hasOwnProperty('address') && this.props.forms.address.length > 0) {
                        options = normalizeForSelect(this.props.forms.address);
                    }
                    additionalParams = {city: reg_city.value ? reg_city.value.replace(/(г\s)/gim, '') : null, region: reg_region.value ? reg_region.value.replace(/\s?(обл|респ|АО)\s?/gim, '') : ""};
                }

                if (field.type === "house") {
                    if (this.props.forms.hasOwnProperty('house') && this.props.forms.house.length > 0) {
                        options = normalizeForSelect(this.props.forms.house);
                    }
                    additionalParams = {city: reg_city.value ? reg_city.value.replace(/(г\s)/gim, '') : null, region: reg_region.value ? reg_region.value.replace(/\s?(обл|респ|АО)\s?/gim, '') : ""};
                }

                if (field.type === "block") {
                    if (this.props.forms.hasOwnProperty('block') && this.props.forms.block.length > 0) {
                        options = normalizeForSelect(this.props.forms.block);
                    }
                    additionalParams = {city: reg_city.value ? reg_city.value.replace(/(г\s)/gim, '') : null, region: reg_region.value ? reg_region.value.replace(/\s?(обл|респ|АО)\s?/gim, '') : ""};
                }

                if (field.type === "city") {
                    if (this.props.forms.hasOwnProperty('city') && this.props.forms.city.length > 0) {
                        options = normalizeForSelect(this.props.forms.city);
                    }
                    additionalParams = {region: reg_region.value ? reg_region.value.replace(/\s?(обл|респ|АО)\s?/gim, '') : ""};
                }

                input = <Select options={options}
                                value={field.value}
                                className="b-choose-plates__select"
                                onChange={(attr, value) => {
                                    let fields = {...this.state.fields};
                                    fields[name].value = value;
                                    fields[name].hasError = false;
                                    if (field.type === "address") {
                                        this.props.clearStreet();
                                        fields.reg_house.value = '';
                                        fields.reg_block.value = '';
                                        fields.reg_flat.value = '';
                                        fields.reg_postcode.value = '';
                                        let postcode = this.props.forms.address.filter((item) => {return item.value === value;})[0].postcode;
                                        if (postcode !== null) {
                                            fields.reg_postcode.value = postcode;
                                        }
                                    }

                                    if (field.type === "house") {
                                        this.props.clearHouse();
                                        fields.reg_block.value = '';
                                        fields.reg_flat.value = '';
                                        fields.reg_postcode.value = '';
                                        let postcode = this.props.forms.house.filter((item) => {return item.value === value;})[0].postcode;
                                        if (postcode !== null) {
                                            fields.reg_postcode.value = postcode;
                                        }
                                    }

                                    if (field.type === "block") {
                                        let postcode = this.props.forms.block.filter((item) => {return item.value === value;})[0].postcode;
                                        if (postcode !== null) {
                                            fields.reg_postcode.value = postcode;
                                        }
                                    }

                                    if (name === 'reg_region') {
                                        this.props.clearCity();
                                        var option = this.props.forms.hasOwnProperty('region') ? this.props.forms.region.filter((item) => {
                                            return value === item.value;
                                        }) : [];
                                        if (option.length && option[0].city) {
                                            fields.reg_city.value = option[0].city;
                                            fields.reg_city.readOnly = true;
                                        } else {
                                            fields.reg_city.value = '';
                                            fields.reg_city.readOnly = false;
                                        }
                                        fields.reg_house.value = '';
                                        fields.reg_block.value = '';
                                        fields.reg_address.value = '';
                                        fields.reg_flat.value = '';
                                        fields.reg_postcode.value = '';
                                    }
                                    if (name === 'reg_city') {
                                        this.props.clearAddress();
                                        fields.reg_address.value = '';
                                        fields.reg_house.value = '';
                                        fields.reg_block.value = '';
                                        fields.reg_flat.value = '';
                                        fields.reg_postcode.value = '';
                                    }

                                    this.setState({fields});
                                }}
                                onChangeValue={(attr, value) => {
                                    let fields = {...this.state.fields};
                                    fields[name].value = value;
                                    fields[name].hasError = false;
                                    this.setState({fields});
                                }}
                                trigger={field.trigger}
                                onFilter={(val) => {
                                        if (val.length >= 3) this.props.addressAutoComplete(field.type, val, additionalParams);

                                        if (field.type === "address" && val.length >= 3) {
                                            this.props.addressAutoComplete(field.type, val, additionalParams)
                                        }
                                        if (field.type === "house" && fields.reg_address.value.length >= 3) {
                                            this.props.addressAutoComplete(field.type, fields.reg_address.value + ' ' + val, additionalParams)
                                        }
                                        if (field.type === "block" && fields.reg_house.value && fields.reg_address.value.length >= 3) {
                                            this.props.addressAutoComplete(field.type, fields.reg_address.value + ' ' + fields.reg_house.value + ' ' + val, additionalParams)
                                        }
                                    }
                                }
                                allowSearch={true}
                                disabled={field.readOnly}
                                hasError={field.hasError}
                                onblur={this.onBlur(name)}
                                onfocus={this.onFocus(name)}
                                errorText={field.textError}
                />;
                break;
            case "date":
                input = <DatePicker onChange={(val) => {
                        let fields = {...this.state.fields};
                        fields[name].value = val ? val : "";
                        this.setState({fields});
                    }}
                    {...field}
                    hasError={field.hasError}
                    onblur={this.onBlur(name)}
                    onfocus={this.onFocus(name)}

                />;
                break;
            case "textarea":
                input = <div className="b-input-textarea__textarea-wrap">
                    <textarea className="b-input-textarea__textarea"
                              value={field.value}
                              onChange={this.onFieldChange(name)}
                    />
                </div>;
                break;
            default:
                input = <div className="b-input-text__input-area">
                    <InputIcon error={field.hasError}/>
                    <input className={"b-input-text__input" + (field.hasError ? " has-error" : "")}
                           type="text"
                           value={field.value}
                           onChange={this.onFieldChange(name)}
                           readOnly={field.readOnly}
                           onBlur={this.onBlur(name)}
                           onFocus={this.onFocus(name)}
                    />
                    <InputError hasError={field.hasError} errorText={field.textError}/>
                </div>;
        }

        return <div className="b-input-text__wrapper b-input-textarea_adapt_mnp-step-form">
            <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                <div className="b-input-text__label-text">{field.label}{field.required ? <span className={"orange"}>*</span> : ''}</div>
            </div>
            {input}
        </div>
    };

    componentDidMount() {
        this.setState({location: window.location});
    }


    static contextTypes = {
        goToStep: PropTypes.func,
        step: PropTypes.number,
        fields: PropTypes.object,
        showModal: PropTypes.func
    };

    goBack = () => {
        this.context.goToStep(this.context.step - 1);
    };

    onSubmit = (e) => {
        if (e) e.preventDefault();
        if (this.isValid() && !this.props.inProgress) {
            this.props.onSubmit({...getObjectData({...this.state.fields}), form_name: this.context.fields.getting_type_alias === "delivery" ? "Шаг6.2" : "Шаг6.1"});
        }
    };

    isValid = () => {
        let fields = {...this.state.fields};

        for (let attr in fields) {
            if (fields[attr].error || (fields[attr].required && !fields[attr].value)) {
                return false;
            }
        }
        if (fields.fio.value.length < 3) {
            return false;
        }
        if (fields.birth_place.value.length < 3) {
            return false;
        }
        if (fields.document_number.value.replace(/\D/gim, '').length !== 10) {
            return false;
        }
        if (fields.who_issued.value.length < 3) {
            return false;
        }

        return true;
    };
    
    render(){
        let {
            personal_info_birthday_comment,
            personal_info_button_hint,
            personal_info_button_text,
            personal_info_button_visible,
            children
        } = this.props;

        return <div className="page__step-form">
            <form className="b-step-form b-step-form_adapt_default" onSubmit={this.onSubmit}>
                <div className="b-step-form__wrapper">
                    <div className="b-step-form__block">
                        <div className="b-step-form__row">
                            <div className="b-step-form__item b-step-form__item_size_full">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("fio")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-date b-input-date_adapt_mnp-questionare">
                                        {this.renderField("birth_date")}
                                    </div>
                                    {this.state.fields.birth_date.hasError
                                        ? <InputError hasError={true} errorText={this.state.fields.birth_date.textError}/>
                                        : <div className="b-input-date__bottom-text b-input-date__bottom-text b-input-date__bottom-text_grey">{personal_info_birthday_comment}</div>
                                    }
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("birth_place")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__block b-step-form__block_labeled">
                        <div className="b-step-form__block-label">Документ, удостоверяющий личность</div>
                        <div className="b-step-form__row">
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    {this.renderField("document")}
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("document_number")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_full">
                                <div className="b-step-form__input">
                                    {this.renderField("who_issued")}
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input b-step-form__input_textarea">
                                    <div className="b-input-date b-input-date_adapt_mnp-questionare">
                                        {this.renderField("issue_date")}
                                        {this.state.fields.issue_date.hasError
                                            ? <InputError hasError={true} errorText={this.state.fields.issue_date.textError}/>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("unit_code")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__block b-step-form__block_labeled">
                        <div className="b-step-form__block-label">Адрес регистрации</div>
                        <div className="b-step-form__row">
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    {this.renderField("reg_region")}
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    {this.renderField("reg_city")}
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_third">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("reg_address")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("reg_house")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("reg_block")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("reg_flat")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("reg_postcode")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__btns-sector b-step-form__btns-sector_questionare">
                        <button type="button" className="b-step-form__btn-item b-step-form__btn-item_questionare u-btn u-btn_lg-responsive u-btn_transparent" onClick={this.goBack}>Назад</button>
                        <button id={"mnp-client-info-button"} type="submit" className={"b-step-form__btn-item b-step-form__btn-item_questionare u-btn u-btn_lg-responsive u-btn_default"+(!this.isValid() || this.props.inProgress ? " disabled" : "")}>Отправить заявку</button>

                        {personal_info_button_visible ? <div className="b-step-form__call-block">
                            <a className="b-head-content__call-item" href="#" onClick={this.context.showModal("call")}>
                                <Icon name="icon_support2" className="b-head-content__call-icon u-icon u-icon_support2"/>
                                <span className="b-head-content__call-text">{personal_info_button_text}</span>
                            </a>
                            <Tooltip id={"phone-data"} label={<span className="img-icon icon-info"/>} openedLabel={<span className="img-icon icon-info-select"/>} className="numbers-tooltip">
                                <p>{personal_info_button_hint}</p>
                            </Tooltip>
                        </div> : null}
                    </div>
                </div>
            </form>
            {children}
        </div>
    }
}
