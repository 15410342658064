import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeInput from '../common/RangeInput';
import TariffInfo from "../Constructor/TariffInfo";
import {getRounding, checkAllUrlParams} from '../../tools/tools';
import TariffCalculator from '../TariffConstructor/TariffCalculator';
import TariffCalculatorCheckboxes from '../TariffConstructor/TariffCalculatorCheckboxes';
import {checkWord, clearWord, clearActivationData} from "../../actions/activation";
import {isEmptyObj, chekParams} from "../../tools/tools";

class TariffConstructor extends Component {
    constructor(props){
        super(props);
        let axisYVal = 0;
        let valueSvoiGb = 0;
        let axisXVal = 0;
        let valueMinutes = 0;

        let gb_default = 1, minutes_default = 0, data_unlim_default = 0, sms_pack_default = 0, social_unlim_default = 0, motiv_voice_unlim_default = 0, router_default = 0, sms_pack_count = 0,  youtube_unlim_default = 0, ivi_default = 0;
        const defaultData = this.props.tariffConstructor.defaultData;
        let recalcData = this.props.tariffConstructor.recalcData;
        let defaultParams = this.props.defaultParams;

        if (!!defaultParams) {
            gb_default = defaultData.constructor && +defaultData.constructor.data;
            minutes_default = defaultData.constructor && +defaultData.constructor.min;
            if (defaultData.constructor && defaultData.constructor.options.length > 0) {
                data_unlim_default = chekParams(defaultData.constructor.options, 'DATA_UNLIM');
                sms_pack_default = chekParams(defaultData.constructor.options, 'SMS_PACK');
                social_unlim_default = chekParams(defaultData.constructor.options, 'SOCIAL_UNLIM');
                motiv_voice_unlim_default = chekParams(defaultData.constructor.options, 'MVOICE_UNLIM');
                router_default = chekParams(defaultData.constructor.options, 'ROUTER');
                youtube_unlim_default = chekParams(defaultData.constructor.options, 'YOUTUBE_UNLIM');
                ivi_default = chekParams(defaultData.constructor.options, 'IVI');
            }
        } else {
            gb_default = recalcData.constructor && +recalcData.constructor.data;
            minutes_default = recalcData.constructor && +recalcData.constructor.min;
            if (recalcData.constructor && recalcData.constructor.options.length > 0) {
                data_unlim_default = chekParams(recalcData.constructor.options, 'DATA_UNLIM');
                sms_pack_default = chekParams(recalcData.constructor.options, 'SMS_PACK');
                social_unlim_default = chekParams(recalcData.constructor.options, 'SOCIAL_UNLIM');
                motiv_voice_unlim_default = chekParams(recalcData.constructor.options, 'MVOICE_UNLIM');
                router_default = chekParams(recalcData.constructor.options, 'ROUTER');
                youtube_unlim_default = chekParams(recalcData.constructor.options, 'YOUTUBE_UNLIM');
                ivi_default = chekParams(recalcData.constructor.options, 'IVI');
            }
        }

        const xLength = props.axis && props.axis.x && props.axis.x.length;
        const maxValMinutes = props.axis && props.axis.x[xLength - 1];

        this.state = {
            focus: false,
            inputTitle: 'название',
            tariffInfo: {},
            max_traffic: 81,
            axisYVal,
            valueSvoiGb,
            focusSvoiGb: false,
            svoiGb_step: 1,
            axisXVal,
            valueMinutes,
            focusMinutes: false,
            minutes_step: 10,
            masOld: [0],
            masNew: [0],
            arrayGb: null,
            marksObjectGb: null,
            arrayMinutes: null,
            marksObjectMinutes: null,
            sms: 0,
            social_unlim: 0,
            data_unlim: 0,
            motiv_voice_unlim: 0,
            router: 0,
            selectedRegion: null,
            flag: 0,
            maxValInternet: null,
            maxValMinutes,
            modal: '',
            validAllParamUrl: false,
            data_unlim_default,
            sms_pack_default,
            social_unlim_default,
            motiv_voice_unlim_default,
            router_default,
            gb_default,
            sms_pack_count,
            disabled: false,
            urlItems: [],
            checkboxOptions: [],
            youtube_unlim: 0,
            ivi: 0,
            youtube_unlim_default,
            ivi_default,
            dataChOn: true,
            routerChOn: true,
            socialChOn: true,
            voiceChOn: true,
            smsChOn: true,
            ytbChOn: true,
            iviChOn: true,
            disabledRqs: false,
            smsParamReq: false
        }
    }

    componentWillMount() {
        if (this.state.selectedRegion === null) {
            this.setState({selectedRegion: this.context.selectedRegion});
            this.state.selectedRegion = this.context.selectedRegion
        }

        const axisY = this.props.axis && this.props.axis.y;
        if (!!axisY && !this.state.arrayGb) {
            let gbArr = this.getMarks(axisY, 10, 9);
            let marksObjectGb = this.getObjectMarks('gb', gbArr);

            this.setState({
                arrayGb: gbArr,
                marksObjectGb
            })
        }

        const axisX = this.props.axis && this.props.axis.x;
        if (!!axisX && !this.state.arrayMinutes) {
            let minutesArr = this.getMarks(axisX, 20);
            let marksObjectMinutes = this.getObjectMarks('minutes', minutesArr);

            this.setState({
                arrayMinutes: minutesArr,
                marksObjectMinutes
            })
        }

        let currentTariff = this.props.currentTariff
        let slugSvoi = currentTariff && currentTariff.slug.indexOf('svoi');

        if ((this.props.isGuest || !this.props.isGuest && slugSvoi === -1) && !this.state.validAllParamUrl) {
            if (!!this.props.defaultParams) this.setParamsTariff(this.props.tariffConstructor.defaultData, 'default_setting');
            if (!this.props.defaultParams) this.setParamsTariff(this.props.tariffConstructor.recalcData, 'default_setting');
        }

        if (!!currentTariff && !this.props.isGuest && slugSvoi !== -1 && !this.state.validAllParamUrl) {
            this.setParamsTariff(currentTariff);
        }
    }

    componentDidMount(){
        if (!this.state.maxValInternet) {
            let maxValInternet = this.maxValueObj(this.state.marksObjectGb) + 10;
            this.setState({
                maxValInternet
            })
        }
    }

    componentWillReceiveProps(nextProps){
        if (this.props.tariffConstructor.recalcData.constructor !== nextProps.tariffConstructor.recalcData.constructor) {
            if (typeof window !== "undefined") {
                const urlParams = checkAllUrlParams();
                let smsCount = !!nextProps.tariffConstructor.recalcData.constructor.sms_count ? +nextProps.tariffConstructor.recalcData.constructor.sms_count : 50;

                if (!!urlParams && !this.state.smsParamReq) {
                    if (!this.state.smsParamReq) this.setState({smsParamReq: true});
                    this.setUrlParams(urlParams, smsCount);
                }
            }
        }

        let currentTariff = this.props.currentTariff;

        if ( (JSON.stringify(this.props.tariffConstructor.recalcData) !== JSON.stringify(nextProps.tariffConstructor.recalcData)) || nextProps.tariffConstructor.recalcData && isEmptyObj(this.state.tariffInfo) ) {
            if (!isEmptyObj(nextProps.tariffConstructor.recalcData)) {
                this.setState({tariffInfo: this.combineTariffInfo(nextProps.tariffConstructor.recalcData)});
            }
        }

        if (this.props.isGuest !== nextProps.isGuest && nextProps.isGuest ||
            !this.props.isGuest && currentTariff !== nextProps.currentTariff && nextProps.currentTariff && nextProps.currentTariff.slug.indexOf('svoi') === -1 && !this.state.validAllParamUrl) {
            this.setParamsTariff(this.props.tariffConstructor.defaultData, 'default_setting');
        }

        if (!this.props.isGuest && currentTariff !== nextProps.currentTariff && nextProps.currentTariff && nextProps.currentTariff.slug.indexOf('svoi') !== -1 && !this.state.validAllParamUrl) {
            this.setParamsTariff(nextProps.currentTariff);
        }

        const axis = nextProps.axis;
        let { axisXVal, axisYVal } = this.state;

        if (axis.y[axisYVal] !== this.state.valueSvoiGb) {
            this.setState({
                valueSvoiGb: axis.y[axisYVal],
                valueSvoiGbOld: axis.y[axisYVal],
            })
        }

        if (axis.x[axisXVal] !== this.state.valueMinutes) {
            this.setState({
                valueMinutes: axis.x[axisXVal],
                valueMinutesOld: axis.x[axisXVal]
            })
        }

        if (this.props.tariffConstructor.errors !== nextProps.tariffConstructor.errors && !!nextProps.tariffConstructor.errors.message && !this.state.disabled) {
            this.setState({disabled: true})
        }

        if (this.props.tariffConstructor.errors !== nextProps.tariffConstructor.errors && !nextProps.tariffConstructor.errors) {
            this.setState({disabled: false})
        }

        if (this.state.checkboxOptions.length === 0 && nextProps.tariffConstructor.defaultData.options.length > 0) this.setState({checkboxOptions: nextProps.tariffConstructor.defaultData.options});

        if (this.props.tariffConstructor.recalcData.options !== nextProps.tariffConstructor.recalcData.options && this.state.checkboxOptions.length > 0) {
            let optionsArrState = [...this.state.checkboxOptions];

            if (nextProps.tariffConstructor.recalcData.options.length > 0) {
                if (JSON.stringify(optionsArrState) !== JSON.stringify(nextProps.tariffConstructor.recalcData.options)) {
                    let optionsArrProps = [...nextProps.tariffConstructor.recalcData.options];
                    for (let param in optionsArrProps) {
                        let itemFind = optionsArrState.find(item => item.type === optionsArrProps[param].type);
                        if (!itemFind) {
                            optionsArrState.push(optionsArrProps[param]);
                        }
                    }
                    if (JSON.stringify(optionsArrState) !== JSON.stringify(this.state.checkboxOptions)) {
                        this.setState({checkboxOptions: optionsArrState})
                    }
                }
            }
        }

        if ( typeof this.props.tariffConstructor.number !== "undefined" &&
            this.props.tariffConstructor.number !== nextProps.tariffConstructor.number && !!this.state.disabledRqs ) {
            this.setState({disabledRqs: false})
        }
    }

    componentDidUpdate(prevState) {
        if (prevState.disabledRqs !== this.state.disabledRqs && !!this.state.disabledRqs) {
            setTimeout(()=> {
                if (!!this.state.disabledRqs) this.setState({disabledRqs: false});
            }, 10000)
        }
    }

    componentWillUnmount() {
        if (this.state.smsParamReq) this.setState({smsParamReq: false});
    }

    setUrlParams = (urlParams, smsCount) => {
        let location = this.props.location;
        let pathnameRegion = location.pathname.substr(1).split('/');
        let regions = this.props.regions && this.props.regions.list;
        let regionObj = regions.find(x => x.alias === pathnameRegion[0]);
        let validMin = !!urlParams[0] && +urlParams[0] <= this.props.axis.x[this.props.axis.x.length - 1] ? true : false;
        let validGb = !!urlParams[1] && (urlParams[1] === 'u' || urlParams[1] === 'r') || !!urlParams[1] && urlParams[1] !== '0' && +urlParams[1] <= this.props.axis.y[this.props.axis.y.length - 2] ? true : false;
        let validSms = !!urlParams[2] && +urlParams[2] !== 1 && (+urlParams[2] === smsCount || +urlParams[2] === 0) ? true : false;
        let validUnlimSocial = !!urlParams[3] && +urlParams[3] === 0 || !!urlParams[3] && +urlParams[3] === 1 ? true : false;
        let validUnlimVoice  = !!urlParams[4] && +urlParams[4] === 0 || !!urlParams[4] && +urlParams[4] === 1 ? true : false;
        let validYoutube  = !!urlParams[5] && +urlParams[5] === 0 || !!urlParams[5] && +urlParams[5] === 1 ? true : false;
        let validIvi  = !!urlParams[6] && +urlParams[6] === 0 || !!urlParams[6] && +urlParams[6] === 1 ? true : false;
        let validAllParamUrl = !!regionObj && validMin && validGb && validSms && validUnlimSocial && validUnlimVoice && validYoutube && validIvi;

        this.setState({validAllParamUrl})

        const axisY = this.props.axis && this.props.axis.y;
        const axisX = this.props.axis && this.props.axis.x;
        let gb = (urlParams[1] === 'r' || urlParams[1] === 'u') ? 1 : +urlParams[1];
        let sms  = (+urlParams[2] === smsCount || +urlParams[2] === 1) ? 1 : 0;
        let social_unlim = +urlParams[3] === 1 ? 1 : 0;
        let data_unlim = (urlParams[1] === 'u' || urlParams[1] === 'r') ? 1 : 0;
        let motiv_voice_unlim = +urlParams[4] === 1 ? 1 : 0;
        let is_yotube_unlim = +urlParams[5] === 1 ? 1 : 0;
        let is_ivi = +urlParams[6] === 1 ? 1 : 0;
        let router = urlParams[1] === 'r' ? 1 : 0;
        let axisYVal = axisY.indexOf(gb);
        let axisXVal = axisX.indexOf(+urlParams[0]);

        let data = {
            min: +urlParams[0],
            gb,
            sms,
            social_unlim,
            data_unlim,
            motiv_voice_unlim,
            axisYVal,
            axisXVal,
            router,
            is_yotube_unlim,
            is_ivi
        }

        if (!!validAllParamUrl) this.setParamsTariff(data, 'params_data_url');
    }

    combineTariffInfo = (tariffInfo) => {
        const infoData = tariffInfo && tariffInfo.details_message;
        const labelRegion = infoData.find(x => x.type === 'region');
        const abon = infoData.find(x => x.type === 'abon');
        const discount_period = infoData.find(x => x.type === 'discount_period');
        const discount_subscription_fee = infoData.find(x => x.type === 'discount_subscription_fee');

        let info = [
            {
                id: 1,
                label: "Регион подключения",
                value: labelRegion.value,
                additionalClassName: ""
            }];

        if (!!discount_period) {
            info.push(
                {
                    id: 2,
                    label: discount_period.param,
                    value: discount_period.value,
                    additionalClassName: "attention"
                }
            );
        }

        if (!!discount_subscription_fee) {
            info.push(
                {
                    id: 3,
                    label: discount_subscription_fee.param,
                    value: discount_subscription_fee.value,
                    additionalClassName: "attention"
                }
            );
        }

        if (!abon.including) {
            info.push(
                {
                    id: 4,
                    label: abon.param,
                    value: abon.value,
                    additionalClassName: ""
                }
            );
        }

        if (!!abon.including) {
            info.push(
                {
                    id: 5,
                    label: abon.param,
                    value: abon.value,
                    additionalClassName: "info-without-border"
                }
            );

            info.push(
                {
                    id: 6,
                    label: "В том числе:",
                    additionalClassName: "info-without-border"
                }
            );

            abon.including.map((item, id) => {
                return (
                    info.push({id: 7 + id,
                        label: item.param,
                        value: item.value,
                        additionalClassName: "info-without-border"
                    })
                )
            });
        }

        if (!!infoData) {
            infoData.map((item, id) => {
                if (item.type !== 'region' &&
                    item.type !== 'discount_period' &&
                    item.type !== 'discount_subscription_fee' &&
                    item.type !== 'abon') {
                        if (item.value.indexOf('\n') > -1) {
                            const newItems = item.value.replace(/\n/gim, "<br>");
                            return (
                                info.push({id: 15 + id,
                                    label: item.param,
                                    value: newItems,
                                    additionalClassName: ""
                                })
                            )
                        } else if (item.param !== 'Рекомендованный баланс') {
                            return (
                                info.push({id: 16 + id,
                                    label: item.param,
                                    value: item.value,
                                    additionalClassName: ""
                                })
                            )
                        } else {
                            return (
                                info.push({id: 16 + id,
                                    label: item.param,
                                    value: item.value,
                                    additionalClassName: "info-top-border"
                                })
                            )
                        }

                }
            })
        }

        return info;
    };

    onChange = (param) => {
        if (param === 'axisYVal') {
            return (val) => {
                if (parseInt(val) > this.props.axis.y.length - 1) {
                    this.state[param] = this.props.axis.y.length + 9;
                    this.setState({[param]: this.props.axis.y.length + 9});
                } else {
                    if (val === '' && !!this.state.data_unlim) {
                        this.onCheckboxChange('data_unlim')
                    }
                    if (parseInt(val) < this.props.axis.y[0] && this.state.axisYVal === -1) {
                        this.state[param] = 0;
                        this.setState({[param]: 0});
                    } else {
                        this.state[param] = this.props.axis.y.indexOf(parseInt(val));
                        this.setState({[param]: this.props.axis.y.indexOf(parseInt(val))});
                    }

                }

                if (parseInt(val) <= this.props.axis.y.length - 1 && this.props.axis.y[this.state.axisYVal] !== this.state.valueSvoiGb) {
                    this.setState({
                        valueSvoiGb: this.props.axis.y[this.state.axisYVal]
                    })
                    if (this.state.data_unlim) {
                        this.onCheckboxChange('data_unlim')
                    }
                }
                if (parseInt(val) > this.props.axis.y.length - 1 && parseInt(val) !== this.state.valueSvoiGb) {
                    this.setState({
                        valueSvoiGb: this.state.maxValInternet
                    })
                    if (!this.state.data_unlim) {
                        this.onCheckboxChange('data_unlim')
                    }

                }
            }
        }

        if (param === 'axisXVal') {
            return (val) => {
                this.state[param] = this.props.axis.x.indexOf(parseInt(val));
                this.setState({[param]: this.props.axis.x.indexOf(parseInt(val))});

                if (val !== this.state.valueMinutes) {
                    this.setState({
                        valueMinutes: val
                    })
                }
            }
        }
    };

    onFocus = (name) => {
        return () => {
            if (name === 'svoiGb' && !this.state.focusSvoiGb) {
                this.setState({
                    focusSvoiGb: true
                })
            }
            if (name === 'minutes' && !this.state.focusMinutes) {
                this.setState({
                    focusMinutes: true
                })
            }
        }
    }

    getClosest = (axis, val) => {
        let i = 0;
        while (i < axis.length && axis[i] < val) {
            i++;
        }
        return Math.min(i, axis.length - 1);
    }

    recountSliders = (name) => {
        if (name === 'svoiGb') {
            this.setState({
                focusSvoiGb: false
            });
            if (this.state.valueSvoiGbOld !== this.state.valueSvoiGb) {
                this.setState((prevState) => {
                    return {valueSvoiGbOld: prevState.valueSvoiGb}
                });
                this.recountPrice()
            }

            if (this.state.axisYVal === -1) {
                this.setState({
                    axisYVal: 0,
                    valueSvoiGb: 0
                }, () => {
                    this.recountPrice()
                });
            }
        }
        if (name === 'minutes') {
            this.setState({
                focusMinutes: false
            })
            if (this.state.axisXVal === -1) {
                let axisXVal = this.getClosest(this.props.axis.x, this.state.valueMinutes);
                this.setState({
                    axisXVal,
                    valueMinutes: this.props.axis.x[axisXVal]
                }, () => {
                    if (this.state.valueMinutesOld !== this.state.valueMinutes) {
                        this.setState((prevState) => {
                            return {valueMinutesOld: prevState.valueMinutes}
                        });
                        this.recountPrice()
                    }
                })
            } else if (this.state.valueMinutesOld !== this.state.valueMinutes) {
                this.setState((prevState) => {
                    return {valueMinutesOld: prevState.valueMinutes}
                });
                this.recountPrice()
            }
        }
    }

    onBlur = (name) => {
        return () => {
            this.recountSliders(name);
        }
    }

    onKeyDown = (e, type, maxVal = false) => {
        if (e.keyCode === 13) {
            this.recountSliders(type);
        }
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
        selectedRegion:  PropTypes.object,
        pushHistory: PropTypes.func,
        toggleRegion: PropTypes.func
    };

    getMarks = (items, n, m) => {
        if (!!items) {
            const result = [items[0]];

            for (let i = m || n; i <= 7 * n; i += n){
                result.push(items[i])
            }
            result.push(items[items.length - 1])

            return result
        }
    }

    getObjectMarks = (type, arr) => {
        let marksObject = {};
        let newArr = [];

        if (type === 'gb' && arr && arr.length <= 9 || type === 'minutes' && arr && arr.length <= 7) {
            newArr = [...arr]
        }

        if (type === 'gb' && arr && arr.length > 9) {
            for (let i = 0; i < 7; i++) {
                newArr.push(arr[i])
            }
            newArr.push(arr[arr.length - 2])
            newArr.push(arr[arr.length - 1])
        }

        if (type === 'minutes' && arr && arr.length > 7) {
            for (let i = 0; i < 6; i++) {
                newArr.push(arr[i])
            }
            newArr.push(arr[arr.length - 1])
        }

        for (let i = 0; i < newArr.length; i++) {
            if (type === 'gb') {
                if (newArr[i] !== 0) {
                    marksObject[newArr[i]] = newArr[i]
                } else {
                    let maxVal = this.maxValueObj(marksObject)
                    marksObject[parseInt(maxVal) + 10] = '∞'
                }
            }
            if (type === 'minutes') {
                marksObject[newArr[i]] = newArr[i]
            }
        }

        return marksObject
    }

    maxValueObj = (obj) => {
        return Object.entries(obj).reduce((max, n) => n[1] > max[1] ? n : max)[1]
    }

    onCheckboxChange = (name) => {
        this.setState({
            flag: 0
        }, () => {
            this.onParamChange(name, this.state[name] == 0 ? 1 : 0);
        });

        this.setState({disabledRqs: true});
    };

    offCheckboxChange = (name) => {
        this.setState({
            flag: 0
        }, () => {
            this.onParamChange(name, 0);
        });
    };

    checkboxClick =()=> {
        if (this.state.data_unlim === 1) {
            if (this.props.isGuest  || !this.props.isGuest && this.props.currentTariff && this.props.currentTariff.slug.indexOf('svoi') === -1) {
                if (this.state.axisYVal > this.props.axis.y.length - 1) {
                    this.setState({
                        axisYVal: this.props.axis.y.indexOf(+this.state.gb_default),
                        valueSvoiGb: +this.state.gb_default
                    })
                }
            };

            if (!this.props.isGuest && this.props.currentTariff && this.props.currentTariff.slug.indexOf('svoi') !== -1) {
                if (this.state.axisYVal > this.props.axis.y.length - 1) {
                    if (this.props.currentTariff.details.traffic !== '') {
                        this.setState({
                            axisYVal: this.props.axis.y.indexOf(parseInt(this.props.currentTariff.details.traffic)),
                            valueSvoiGb: parseInt(this.props.currentTariff.details.traffic)
                        })
                    } else {
                        this.setState({
                            axisYVal: this.props.axis.y.indexOf(1),
                            valueSvoiGb: 1
                        })
                    }
                }
            }
        }
    }

    recountPrice = () => {
        let {
            axisXVal,
            axisYVal,
            sms,
            social_unlim,
            data_unlim,
            motiv_voice_unlim,
            router,
            youtube_unlim,
            ivi
        } = this.state;

        const axis = this.props.axis;

        if (axisYVal >= axis.y.length || data_unlim === 1) {
            axisYVal = axis.y.length - 1
        }

        if (axisYVal !== -1) this.props.getPrice(axis.x[axisXVal], axis.y[axisYVal], sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi);
    }

    onParamChange = (name, val) => {
        this.setState((prevState) => ({[name]: val, ...(name === 'data_unlim' && !val) ? {router: 0, axisYVal: prevState.axisYVal !== -1 ? prevState.axisYVal : 0} : {}}), () => {
            this.recountPrice();
        })
    };

    onAfterChangeSlider = (type, objectMarks, items, roundingLow, roundingHigh) => {
        let result = null;
        const axis = this.props.axis && this.props.axis;
        let axisYVal = this.state.axisYVal;

        roundingLow = roundingLow === '' ? 0 : parseInt(roundingLow);
        roundingHigh = roundingHigh === '' ? 0 : parseInt(roundingHigh);

        if (objectMarks && items >= 0) {
            result = this.getRange(objectMarks, items, roundingLow, roundingHigh);
            if (type === 'svoiGb') {
                if (!!result) {
                    this.setState({
                        axisYVal: axis.y.indexOf(result),
                        valueSvoiGb: result
                    })

                    if (axisYVal > this.props.axis.y.length - 1) {
                        this.onParamChange('axisYVal', this.props.axis.y.length - 1);
                    } else {
                        this.onParamChange('axisYVal', axis.y.indexOf(result));
                    }
                }
            }
            if (type === 'minutes') {
                if (result !== null) {
                    this.setState({
                        axisXVal: axis.x.indexOf(result),
                        valueMinutes: result
                    })

                    this.onParamChange('axisXVal', axis.x.indexOf(result));
                }
            }
        }
    }

    getRange = (object, item, roundingLow, roundingHigh) => {
        const keys = Object.keys(object);
        let arrRange = [];
        let range = {};
        let result = null;

        for (let i = 0; i < keys.length - 1; i++){
            arrRange.push({min: object[keys[i]], max: object[keys[i + 1]]})
        }

        for (let j = 0;  j < arrRange.length; j++) {

            if (item >= arrRange[j].min && item <= arrRange[j].max || item >= arrRange[j].min && isNaN(arrRange[j].max)) {
                range = (arrRange[j]);
            }
        }

        if (!!range) {
            let roundLow = getRounding(range.min, !isNaN(range.max) ? range.max : range.min + 10, parseInt(roundingLow));
            let roundHigh = getRounding(range.min, !isNaN(range.max) ? range.max : range.min + 10, parseInt(roundingHigh));

            if (roundingLow === 0 && roundingHigh === 0 || item > roundLow && item < range.max && roundingHigh === 0 || item > roundLow && item < roundHigh){
                result = item
            } else if (item <= roundLow && !isNaN(range.max) || item <= roundLow && isNaN(range.max)) {
                result = range.min
            } else if (item >= roundHigh) {
                result = range.max
            }

            // if (isNaN(range.max) && (item > roundLow && item < roundHigh || item === roundLow || item >= roundHigh)) {
            //     result = range.min + 10
            // }
        }

        return result
    }

    setParamsTariff = (data, type) => {
        if (!!data) {
            const axis = this.props.axis;
            let min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, axisYVal, axisXVal, router, youtube_unlim, ivi;

            if (type === 'default_setting') {
                min = data.constructor.min;
                gb = data.constructor.data;
                sms = this.state.sms_pack_default;
                social_unlim = this.state.social_unlim_default;
                data_unlim = this.state.data_unlim_default;
                motiv_voice_unlim = this.state.motiv_voice_unlim_default;
                router = this.state.router_default;
                axisYVal = axis.y.indexOf(+data.constructor.data);
                axisXVal = axis && axis.x.indexOf(+data.constructor.min);
                youtube_unlim = this.state.youtube_unlim_default,
                ivi = this.state.ivi_default
            } else if (type === 'params_data_url') {
                min = data.min;
                gb = data.gb;
                sms = data.sms;
                social_unlim = data.social_unlim;
                data_unlim = data.data_unlim;
                motiv_voice_unlim = data.motiv_voice_unlim;
                axisYVal = data.axisYVal;
                axisXVal = data.axisXVal;
                router = data.router;
                youtube_unlim = data.is_yotube_unlim,
                ivi = data.is_ivi

            } else {
                min = !!data.details.minutes ? parseInt(data.details.minutes) : 0;
                gb = data.details.is_data_unlim ? axis.y[axis.y.length - 1] : !!data.details.traffic ? parseInt(data.details.traffic) : 0;
                sms = !!data.details.sms ? 1 : 0;
                social_unlim = data.details.is_socnet_unlim ? 1 : 0;
                data_unlim = data.details.is_data_unlim ? 1 : 0;
                motiv_voice_unlim = !!data.details.is_motiv_voice_unlim ? 1 : 0;
                axisYVal = data.details.is_data_unlim ? axis.y.indexOf(axis.y[axis.y.length - 1]) : axis.y.indexOf(gb);
                axisXVal = axis && axis.x.indexOf(min);
                router = data.details.router ? 1 : 0;
                youtube_unlim = data.details.is_yotube_unlim ? 1 : 0;
                ivi = data.details.is_ivi ? 1 : 0;
            }

            this.setState({
                axisYVal,
                axisXVal,
                data_unlim,
                social_unlim,
                motiv_voice_unlim,
                sms,
                router,
                youtube_unlim,
                ivi
            })
            this.props.getPrice(min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi);
        }
    }

    onBlurName = (e) => {
        this.setState({focus: false});
        if (e.target.value) {
            this.props.checkWord(e.target.value)
        }
    }

    onFocusName = (e) => {
        this.setState({focus: true});
    }

    openingConnectionForm = () => {
        this.recountPrice();
        this.props.onClickConnect('tariff-connection');
    }

    setSearchParams = (constrUrl) => {
        if (window.history.replaceState) {
            window.history.replaceState(null, '', constrUrl);
        }
    }

    checkParam =(arr, type, findItem)=> {
        if (arr.length > 0 && arr.find(l => l[type] === findItem)) {
            return true
        }
        return false
    }

    setChekParam = arr => {
        const {
            data_unlim,
            dataChOn,
            router,
            routerChOn,
            social_unlim,
            socialChOn,
            motiv_voice_unlim,
            voiceChOn,
            sms,
            smsChOn,
            youtube_unlim,
            ytbChOn,
            ivi,
            iviChOn
        } = this.state;

        let ytbProps = this.checkParam(arr, 'type', 'YOUTUBE_UNLIM');
        let dataProps = this.checkParam(arr, 'type', 'DATA_UNLIM');
        let routerProps = this.checkParam(arr, 'type', 'ROUTER');
        let socialProps = this.checkParam(arr, 'type', 'SOCIAL_UNLIM');
        let voiceProps = this.checkParam(arr, 'type', 'MVOICE_UNLIM');
        let smsProps = this.checkParam(arr, 'type', 'SMS_PACK');
        let ivivProps = this.checkParam(arr, 'type', 'IVI');

        if (!dataProps && !!dataChOn) {
            this.setState({dataChOn: false});
            this.offCheckboxChange('data_unlim');
        }
        if (!!dataProps && !dataChOn) this.setState({ dataChOn: true });

        if (!routerProps && !!routerChOn) {
            this.setState({routerChOn: false});
            this.offCheckboxChange('router');
        }
        if (!!routerProps && !routerChOn) this.setState({ routerChOn: true });

        if (!socialProps && !!socialChOn) {
            this.setState({socialChOn: false});
            this.offCheckboxChange('social_unlim');
        }
        if (!!socialProps && !socialChOn) this.setState({ socialChOn: true });

        if (!voiceProps && !!voiceChOn) {
            this.setState({voiceChOn: false});
            this.offCheckboxChange('motiv_voice_unlim');
        }
        if (!!voiceProps && !voiceChOn) this.setState({ voiceChOn: true });

        if (!smsProps && !!smsChOn) {
            this.setState({smsChOn: false});
            this.offCheckboxChange('sms');
        }
        if (!!smsProps && !smsChOn) this.setState({ smsChOn: true });

        if (!ytbProps && !!ytbChOn) {
            this.setState({ytbChOn: false});
            this.offCheckboxChange('youtube_unlim');
        }
        if (!!ytbProps && !ytbChOn) this.setState({ ytbChOn: true });

        if (!ivivProps && !!iviChOn) {
            this.setState({iviChOn: false});
            this.offCheckboxChange('ivi');
        }
        if (!!ivivProps && !iviChOn) this.setState({ iviChOn: true })
    }

    render() {
        let { price, settings, url, axis, constructorParams, name, placeholder, tariffConstructor, defaultParams } = this.props;

        let sms_pack_count = this.props.smsCount;

        let {
            inputTitle,
            focus,
            svoiGb_step,
            axisYVal,
            axisXVal,
            minutes_step,
            tariffInfo,
            valueSvoiGb,
            valueMinutes,
            marksObjectGb,
            marksObjectMinutes,
            sms,
            social_unlim,
            data_unlim,
            router,
            motiv_voice_unlim,
            flag,
            maxValInternet,
            maxValMinutes,
            disabled,
            validAllParamUrl,
            checkboxOptions,
            youtube_unlim,
            ivi,
            dataChOn,
            routerChOn,
            socialChOn,
            voiceChOn,
            smsChOn,
            ytbChOn,
            iviChOn,
            disabledRqs
        } = this.state;

        const roundingLowGb = settings && settings.rounding_low_gb;
        const roundingHighGb = settings && settings.rounding_high_gb;
        const roundingLowM = settings && settings.rounding_low_m;
        const roundingHighM = settings && settings.rounding_high_m;
        const yLength = axis && axis.y && axis.y.length;
        const axisY = axis && axis.y;
        const axisX = axis && axis.x;

        let dataForUrl = {
            min: axisX[axisXVal],
            gb: !!axisY[axisYVal] ? axisY[axisYVal] : 'u',
            data: data_unlim,
            router: router,
            social: social_unlim,
            sms: sms,
            voice: motiv_voice_unlim,
            sms_pack_count,
            youtube_unlim,
            ivi
        }

        let errorMsg = tariffConstructor.errors && tariffConstructor.errors.message;
        let constrUrl = '', gbUrl = '', smsUrl = '';

        gbUrl = dataForUrl.data === 1 && dataForUrl.router === 0 ? 'u' : dataForUrl.data === 1 && dataForUrl.router === 1 ? 'r' : dataForUrl.gb;
        smsUrl = dataForUrl.sms === 1 ? (dataForUrl.sms_pack_count ? dataForUrl.sms_pack_count : 0) : 0;
        constrUrl = `?svoi=${dataForUrl.min}-${gbUrl}-${smsUrl}-${dataForUrl.social}-${dataForUrl.voice}-${dataForUrl.youtube_unlim}-${dataForUrl.ivi}`;

        if (typeof window !== 'undefined' && !!validAllParamUrl) {
            if (window.location.search !== '' && window.location.search !== constrUrl) {
                if (window.location.search.indexOf('&') !== -1) {
                    constrUrl += window.location.search.substr(window.location.search.indexOf('&'))
                }
                this.setSearchParams(constrUrl);
            }
        }

        let dataUnlimLabel = '', routerLabel = '', socialUnlimLabel = '', mVoiceUnlimLabel = '', smsPackLabel = '', youtubeUnlimLabel = '', iviLabel = '', arrDisplayedOptions = [];

        if (defaultParams) {
            arrDisplayedOptions = tariffConstructor.defaultData && tariffConstructor.defaultData.options;
        } else {
            arrDisplayedOptions = tariffConstructor.recalcData && tariffConstructor.recalcData.options;
        }

        if (checkboxOptions.length > 0) {
            if (dataUnlimLabel === '') dataUnlimLabel = checkboxOptions.find(l => l.type === 'DATA_UNLIM');
            if (routerLabel === '') routerLabel = checkboxOptions.find(l => l.type === 'ROUTER');
            if (socialUnlimLabel === '') socialUnlimLabel = checkboxOptions.find(l => l.type === 'SOCIAL_UNLIM');
            if (mVoiceUnlimLabel === '') mVoiceUnlimLabel = checkboxOptions.find(l => l.type === 'MVOICE_UNLIM');
            if (smsPackLabel === '') smsPackLabel = checkboxOptions.find(l => l.type === 'SMS_PACK');
            if (youtubeUnlimLabel === '') youtubeUnlimLabel = checkboxOptions.find(l => l.type === 'YOUTUBE_UNLIM');
            if (iviLabel === '') iviLabel = checkboxOptions.find(l => l.type === 'IVI');
        }

        if (typeof window !== "undefined") {

            if (arrDisplayedOptions.length > 0 ) {
                this.setChekParam(arrDisplayedOptions);
            }
        }

        let chOn = {
            dataChOn,
            routerChOn,
            socialChOn,
            voiceChOn,
            smsChOn,
            ytbChOn,
            iviChOn
        };

        return (<div className={"b-page__tariffs-constructor " + (disabled ? "block-disabled" : "") }>
            <div className="b-page__tariffs-constructor-wrap">
                <div className="tariffs-constructor__slider-wrap">
                    <div className="constructor__tariff-name">
                        <span className="constructor__tariff-name--title">{inputTitle}</span>
                        <input
                            className="constructor__tariff-name--input"
                            value={name}
                            placeholder={focus ? '' : placeholder}
                            onChange={this.props.onTariffNameChange}
                            onBlur={this.onBlurName}
                            onFocus={this.onFocusName}
                        />
                    </div>
                    <div className="constructor__tarif-slider-wrap">
                        <div className="tarif-slider-traffic">
                            <div className="filter-rates__label">Интернет, в месяц</div>
                            <RangeInput
                                type={'svoiGb'}
                                min={1}
                                max={maxValInternet}
                                marks={marksObjectGb}
                                stepSlider={svoiGb_step}
                                dots={true}
                                value={data_unlim === 1 ? maxValInternet : axisYVal > yLength - 1 ? axisYVal : axis && axis.y[axisYVal]}
                                valueSvoi={data_unlim === 1 ? maxValInternet : valueSvoiGb}
                                onChange={this.onChange('axisYVal')}
                                onFocus={this.onFocus('svoiGb')}
                                onBlur={this.onBlur('svoiGb')}
                                onKeyDown={(e) => this.onKeyDown(e, 'svoiGb', maxValInternet)}
                                onAfterChange={() => this.onAfterChangeSlider('svoiGb', marksObjectGb, axisY[axisYVal], roundingLowGb, roundingHighGb)}
                                unit={'ГБ'}
                                valueData={data_unlim}
                            />
{/* 
                            {console.log('max', maxValInternet)}
                            {console.log('marks', marksObjectGb)}
                            {console.log('stepSlider', svoiGb_step)}
                            {console.log('marksObjectMinutes', marksObjectMinutes)}
                            {console.log('value', data_unlim === 1 ? maxValInternet : axisYVal > yLength - 1 ? axisYVal : axis && axis.y[axisYVal])}
                            {console.log('valueSvoi', data_unlim === 1 ? maxValInternet : valueSvoiGb)}
                            {console.log('valueData', data_unlim)} */}

                            {console.log('marksObjectGb', marksObjectGb)}
                            {console.log('axisY[axisYVal]', axisY[axisYVal])}
                            {console.log('roundingLowGb', roundingLowGb)}
                            {console.log('roundingHighGb', roundingHighGb)}
                            {console.log('marksObjectMinutes', marksObjectMinutes)}

                        </div>
                        <div className="tarif-slider-minutes">
                            <div className="filter-rates__label">Минуты на номера РФ, в месяц</div>
                            <RangeInput
                                type={'svoiMinutes'}
                                min={0}
                                max={maxValMinutes}
                                marks={marksObjectMinutes}
                                stepSlider={minutes_step}
                                dots={true}
                                value={axisX[axisXVal]}
                                valueSvoi={valueMinutes}
                                onChange={this.onChange('axisXVal')}
                                onFocus={this.onFocus('minutes')}
                                onBlur={this.onBlur('minutes')}
                                onKeyDown={(e) => this.onKeyDown(e, 'minutes')}
                                onAfterChange={() => this.onAfterChangeSlider('minutes', marksObjectMinutes, axis.x[axisXVal], roundingLowM, roundingHighM)}
                                unit={'МИН'}
                            />
                        </div>
                    </div>

                    <TariffCalculatorCheckboxes
                            nameData="data_unlim"
                            valueData={data_unlim}
                            labelData={dataUnlimLabel && dataUnlimLabel.name}
                            onChangeData={() => this.onCheckboxChange('data_unlim')}
                            data_unlim_hint={dataUnlimLabel && dataUnlimLabel.description}
                            router_hint={routerLabel && routerLabel.description}
                            checkboxClick={this.checkboxClick}

                            nameRouter="router"
                            valueRouter={router}
                            labelRouter={routerLabel && routerLabel.name}
                            onChangeRouter={() => this.onCheckboxChange('router')}

                            nameSocial="social_unlim"
                            valueSocial={social_unlim}
                            labelSocial={socialUnlimLabel && socialUnlimLabel.name}
                            onChangeSocial={() => this.onCheckboxChange('social_unlim')}
                            social_unlim_hint={socialUnlimLabel && socialUnlimLabel.description}

                            nameVoice="motiv_voice_unlim"
                            valueVoice={motiv_voice_unlim}
                            labelVoice={mVoiceUnlimLabel && mVoiceUnlimLabel.name}
                            onChangeVoice={() => this.onCheckboxChange('motiv_voice_unlim')}
                            voice_unlim_hint={mVoiceUnlimLabel && mVoiceUnlimLabel.description}

                            nameYoutube="youtube_unlim"
                            valueYoutube={youtube_unlim}
                            labelYoutube={youtubeUnlimLabel && youtubeUnlimLabel.name}
                            onChangeYoutube={() => this.onCheckboxChange('youtube_unlim')}
                            youtube_unlim_hint={youtubeUnlimLabel && youtubeUnlimLabel.description}

                            nameIvi="ivi"
                            valueIvi={ivi}
                            labelIvi={iviLabel && iviLabel.name}
                            onChangeIvi={() => this.onCheckboxChange('ivi')}
                            ivi_hint={iviLabel && iviLabel.description}

                            nameSms="sms"
                            valueSms={sms}
                            labelSms={smsPackLabel && smsPackLabel.name}
                            onChangeSms={() => this.onCheckboxChange('sms')}
                            sms_hint={smsPackLabel && smsPackLabel.description}

                            chOn={chOn}
                            disabledRqs={disabledRqs}
                    />

                </div>

                <TariffCalculator
                    price={price}
                    constructorParams={constructorParams}
                    onClickConnect={this.openingConnectionForm}
                    settings={settings}
                    flag={flag}
                    dataForUrl={dataForUrl}
                />

            </div>

            { !isEmptyObj(tariffInfo) && settings ?
                <TariffInfo
                    type={'inner'}
                    settings={settings}
                    info={tariffInfo}
                    innerTitle={'О тарифе'}
                /> : null
            }
            {disabled ? <span className="disabled-msg">{errorMsg}</span> : null}
        </div>)
    }
}

export default connect(
    state => ({
        activation: state.activation,
        forms: state.forms,
        isGuest: state.client.isGuest,
        tariffConstructor: state.tariffConstructor
    }),
    (dispatch) => {
        return {
            checkWord: (word) => dispatch(checkWord(word)),
            clearWord: () => dispatch(clearWord()),
            clearActivationData: () => dispatch(clearActivationData())
        }
    }
)(TariffConstructor);
