import React, { Component } from 'react';
import PropTypes from "prop-types";
import Img from 'react-lazilyload-img';
import {Link} from "react-router-dom";
import {isIE} from "react-device-detect";
import LinkC from "../../../js/components/common/LinkC";

class OtherNews extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    render() {
        const selectedRegion = this.context.selectedRegion.alias;
        let {
            className,
            title,
            data,
            linkPageNews,
            linkAllnews = false
        } = this.props;

        return (
            data && data.length !== 0 ?
                <div className={ "news-and-events-block " + ( !!className ? className : "" ) } >
                    <div className="news-and-events-top">
                        <div className="news-and-events__title">{ title }</div>
                        {linkAllnews ? <LinkC to={"/news"} className={"about-page__link-all-news"}>Смотреть все</LinkC> : null}
                    </div>
                    <div className={"news-and-events"}>
                        {data.map((el) =>
                            <Link key={el.id} className={"news-and-events-link"} to={'/'+selectedRegion + linkPageNews + '/' + el.id}>
                                {el && (el.miniature || el.photo) ?
                                    <div className={ isIE === true ? "news-and-events-link-img__wrap news-and-events-link-img__wrap--IE" : "news-and-events-link-img__wrap"}>
                                        {isIE === true ?
                                            <svg role="image"
                                                 preserveAspectRatio="xMidYMid slice"
                                                 viewBox="0 0 1024 640" className="news-and-events-link__img--IE">
                                                <image width="100%" height="100%" xlinkHref={el.miniature}/>
                                            </svg>
                                            :
                                            < Img
                                                className = "news-and-events-link__img"
                                                src={el.photo}
                                                alt=""
                                            />
                                        }
                                    </div>
                                    : null}
                                <div className="news-and-events-link__content">
                                    {el && el.date ? <div className="news-and-events-link__publicationDate" dangerouslySetInnerHTML={{ __html: el.date }} /> : null}
                                    {el && el.title ? <div className="news-and-events-link__title" dangerouslySetInnerHTML={{ __html: el.title }} /> : null}
                                    {el && el.short_description ? <div className="news-and-events-link__text" dangerouslySetInnerHTML={{ __html: el.short_description }} /> : null}
                                </div>
                            </Link>
                        )}
                    </div>
                </div> :
            null
        )
    }
}

export default OtherNews;
