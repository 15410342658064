const initState = {
    isLoaded: false
};

export function callback(state = initState, action) {
    switch (action.type) {
        case "CHECK_CALLBACK_FORM":
            return initState;
        case "CHECK_CALLBACK_FORM_SUCCESS":
            return {...action.data, isLoaded: true};
        case "CHECK_SLOTS":
            return {...state, checkSlots: false};
        case "CHECK_SLOTS_SUCCESS":
            return {...state, ...action.data, checkSlots: true};
        case "CLEAR_CALLBACK_FORM":
            return {...state, status: undefined, result: undefined};
        case "SEND_CALLBACK_FORM":
            return {...state, status: "progress", result: undefined};
        case "SEND_CALLBACK_FORM_SUCCESS":
            return {...state, status: "success", result: action.data};
        case "SEND_CALLBACK_FORM_BAD_REQUEST":
        case "SEND_CALLBACK_FORM_ERROR":
            return {...state, status: "error", result: action.data};
    }
    return state;
}