import React, { Component } from 'react';

import Icon from '../../components/common/Icon';

import appStore from '../../../images/content/app-store.svg';
import googlePlay from '../../../images/content/google-play.svg';
// import appgallery from "../../../images/content/app-gallery.svg";
import loadapk from "../../../images/content/load-apk.svg";
import ruStore from "../../../images/content/ru-store.svg";
import PropTypes from "prop-types";
import LinkC from "../../components/common/LinkC";
import {mainUrl} from "../../tools/config";

class Footer extends Component {

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        isLk: PropTypes.bool
    };

    render() {

        let footerObj = this.props.footerMenuList.filter(x => x.regionId === this.context.selectedRegion.id);
        let regionAlias = this.context.selectedRegion.alias;
        let fetchBefore = this.context.fetchBefore;
        let d = new Date();
        let thisYear = d.getFullYear();

        if (footerObj.length === 0) {
            return (
                <footer className="page__footer">
                </footer>
            )
        }

        return (
            <footer className="page__footer">
                <div className="b-footer b-footer_adapt_default">
                    <div className="b-footer__top-section">
                        <div className="page__container">
                            <div className="b-footer__menu-holder">
                                <div className="b-footer-menu">
                                    <div className="b-footer-menu__holder">
                                        <ul className="b-footer-menu__level">
                                            {footerObj[0].data.map((col, idx) =>
                                                <li className="b-footer-menu__item" key={idx}>
                                                    <div className="b-footer-menu__link-holder">
                                                        <LinkC
                                                            className={"b-footer-menu__link"}
                                                            to={(this.context.isLk && col.link.indexOf('http') !== 0 ? mainUrl+this.context.selectedRegion.alias : "")+col.link}
                                                            targetBlank={col.new_tab == "1"}
                                                        >
                                                            <span className="b-footer-menu__text">{col.title}</span>
                                                        </LinkC>
                                                    </div>
                                                    <div className="b-footer-menu__second-holder">
                                                        <ul className="b-footer-menu__second-level">
                                                            {col.items.map((item, key) =>
                                                                <li className="b-footer-menu__second-item" key={key}>
                                                                    <div className="b-footer-menu__second-link-holder">
                                                                        <LinkC className={"b-footer-menu__second-link"} to={(this.context.isLk && item.link.indexOf('http') !== 0 ? mainUrl+this.context.selectedRegion.alias : "")+item.link} targetBlank={item.new_tab === "1"}>
                                                                            <span className="b-footer-menu__second-text">{item.title}</span>
                                                                        </LinkC>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-footer__bottom-section">
                        <div className="page__container">
                            <div className="b-footer__bottom-wrap">
                                <div className="b-footer__copyright-holder">
                                    <div className="b-footer__copyright-text">

                                        <div className="b-footer__copy-text-period">&copy; 2011&ndash;{thisYear}&nbsp;Мотив.
                                        </div>
                                        <div className="b-footer__copy-text-rights">Все права защищены</div>
                                    </div>
                                    <div className="b-footer__copyright-icon">
                                        <Icon name={'icon_18'} className={'b-footer__pg u-icon u-icon_18'} />
                                    </div>
                                </div>

                                <div className={"mobile-apps"}>
                                    <a href="https://motiv.me/appstore" target="_blank">
                                        <img src={ appStore } alt="" role="presentation"/>
                                    </a>
                                    <a href="https://motiv.me/googleplay" target="_blank">
                                        <img src={ googlePlay } alt="" role="presentation"/>
                                    </a>
                                    <a href="https://motiv.me/rustore" target="_blank">
                                        <img src={ ruStore } alt="" role="presentation"/>
                                    </a>
                                    <a href="https://motiv.me/m-app" target="_blank">
                                        <img src={ loadapk } alt="" role="presentation" />
                                    </a>
                                    {/* <a href="https://appgallery.huawei.com/app/C104641419" target="_blank">
                                        <img src={ appgallery } alt="" role="presentation" />
                                    </a> */}
                                </div>
                                <div className="b-footer__developers">
                                    <a href="https://buroburo.ru/" target="_blank" >
                                        <Icon name={'icon_buro'} className={'b-footer__dev-icon u-icon u-icon_buro'} />
                                    </a>
                                </div>
                                <div className="b-footer__socials">
                                    <div className="b-socials b-socials_footer b-socials_adapt_default">
                                        <ul className="b-socials__items">
                                            {/* <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://www.facebook.com/motiv4g" target="_blank">
                                                    <Icon name={'icon_facebook'} className={'b-socials__icon u-icon u-icon_facebook'} />
                                                </a>
                                            </li> */}
                                            <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://vk.com/motiv4g" target="_blank">
                                                    <Icon name={'icon_vk'} className={'b-socials__icon u-icon u-icon_vk'} />
                                                </a>
                                            </li>
                                            <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://www.tiktok.com/@motiv.official" target="_blank">
                                                    <Icon name={'icon_tiktok'} className={'b-socials__icon u-icon u-icon_tiktok'} />
                                                </a>
                                            </li>
                                            {/* <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://www.instagram.com/motiv4g/" target="_blank">
                                                    <Icon name={'icon_instagram'} className={'b-socials__icon u-icon u-icon_instagram'} />
                                                </a>
                                            </li> */}
                                            <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://www.youtube.com/user/TVMotiv" target="_blank">
                                                    <Icon name={'icon_youtube'} className={'b-socials__icon u-icon u-icon_youtube'} />
                                                </a>
                                            </li>
                                            <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://zen.yandex.ru/id/60ae34e9d0e334758e1315bb" target="_blank">
                                                    <Icon name={'icon_yandex_zen'} className={'b-socials__icon u-icon u-icon_youtube'} />
                                                </a>
                                            </li>
                                            <li className="b-socials__item">
                                                <a className="b-socials__item-link" href="https://t.me/motiv_news" target="_blank">
                                                    <Icon name={'icon_telegram_zen'} className={'b-socials__icon u-icon u-icon_telegram'} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;