import React, {Component} from "react";
import PropTypes from "prop-types";

import {phoneMask} from "../../tools/mask";
import Icon from "../common/Icon";
import Tooltip from "../common/Tooltip";
import {phoneValidator, checkboxValidator} from "../../tools/form-helper";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";
import Radio from "../common/Radio";
import {setIdButton} from "../../tools/tools";
import { v1 as uuidv } from 'uuid';
import Modal from "../common/Modal";
import { getCookie, setCookie, deleteCookie } from "../../tools/tools";
import { esiaUrl, cookieDomain } from "../../tools/config";

export default class StartMoveForm extends Component{

    static contextTypes = {
        fields: PropTypes.object,
        selectedRegion: PropTypes.object
    };

    constructor(props, context){
        super(props, context);
        this.state = {
            phone: context.fields ? phoneMask(context.fields.phone) : "",
            phone_err: null,
            agree: context.fields ? !!context.fields.phone : false,
            agree_err: null,
            phoneNotCorrect: false,
            arr: {
                name: "mnpForm",
                type: "radio",
                value: "value_0",
                items: [],
            },
            valueBonus: "value_0",
            modal: '',
            is_esia: 0
        }

        this.renderModal = this.renderModal.bind(this);
    }
    
    componentDidMount() {
        if (typeof window !== "undefined") {
            const searchURL = window.location.search;
            // true result=AUTHORIZED
            const desiredURL = searchURL.includes('result=AUTHORIZED');
            // true utm
            const utmURL = searchURL.includes('utm');
            // если в searchURL result=AUTHORIZED то стираем
            let utmSearch = searchURL.replace(/&result=AUTHORIZED/g, '');
            // находим основной путь http://localhost:3000 + /kurgan_oblast/mnp + utm
            let basicUrl = `${window.location.origin}${window.location.pathname}${utmURL ? utmSearch : ''}`;

            if (desiredURL) {
                // вытягиваем кукку номера телефона
                const number = getCookie('numberMnpCookie');

                // вытягиваем кукку чекбокс бонуса
                const checkbox_value = getCookie('checkboxValueCookie');
                // получаем токен пользователя
                let esiaToken = getCookie('x_bridge_token', true);
                if(esiaToken) {
                    this.props.mnpFormEsiaAction(esiaToken);
                }
                this.setState({
                    phone: number || '',
                    agree: true,
                    valueBonus: checkbox_value || 'value_0',
                    arr: {...this.state.arr, value: checkbox_value || 'value_0'}
                }, () => {
                    setTimeout(() => {
                        this.onSubmit();
                    }, 1000);
                    // удаляем токены
                    setTimeout(() => {
                        // deleteCookie('checkboxValueCookie');
                        // deleteCookie('numberMnpCookie');
                    }, 5000);
                });
                this.props.onToggleForm(1);

                // меняем url и убираем result=AUTHORIZED
                history.replaceState(null, null, basicUrl);
            } else {
                // deleteCookie('checkboxValueCookie');
                // deleteCookie('numberMnpCookie');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let mnp = this.props.activation && this.props.activation.mnp && this.props.activation.mnp;
        let prevMnp = prevProps.activation && prevProps.activation.mnp && prevProps.activation.mnp;

        if (mnp !== prevMnp && mnp.statusCode === 1001) {
            this.openModal('choose_an_option');
            this.validatePhone(); 
            this.validateCheckbox();
        }

        if (mnp !== prevMnp && mnp.StatusCode === 3003) {
            setTimeout(() => {
                this.setState({phoneNotCorrect: true});
            }, 1500);
        }
        if (this.props.mnpBonuses !== prevProps.mnpBonuses) {
            this.setState({arr: {...this.state.arr, items: this.props.mnpBonuses.map((el, index) => {return {label: el.title, value: 'value_' + index, tooltip: el.tooltip}})}})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!!this.props.validPhoneNumber){
            this.setState({
                phone: "",
                agree: false
            })
        }

        // if (nextProps.activation.isOk !== this.props.activation.isOk) {
        //     if (typeof nextProps.activation.mnp.StatusCode !== 'undefined' && nextProps.activation.mnp.StatusCode === 3003) {
        //         setTimeout(() => {
        //             this.setState({phoneNotCorrect: true});
        //         }, 1500);
        //     }
        // }
    }

    onChangeRadio = (name, value) => {
        this.setState({
            valueBonus: value,
            arr: {
                ...this.state.arr,
                value
            }
        });

        setCookie('checkboxValueCookie', value, {
            path: '/',
            expires: 30 * 3600 * 24,
            domain: cookieDomain
        });
    };

    onChange = (e) => {
        this.setState({phone: phoneMask(e.target.value)});
        this.setState({phoneNotCorrect: false});
    };

    onCheckboxChange = () => {
        this.setState((prevState) => {
            return {
                agree: !prevState.agree,
                agree_err: null
            }
        })
    };

    onSubmit = (e) => {
        if (e) e.preventDefault();
        let findItem = this.state.arr.items.find((i) => i.value === this.state.valueBonus);

        this.props.onRadioChange(findItem ? findItem.label : '');

        let data = {
            // phone: this.state.phone && this.state.phone.replace(/\D/gim, ''),
            phone: this.state.phone.replace(/\D/gim, ''),
            form_name: 'Шаг0',
        };

        if (this.isValid() && data.phone && !this.props.activation.inProgress) {
            this.props.onSubmit(data);
        }
    };

    checkPhone = () => {
        let data = {
            phone: this.state.phone.replace(/\D/gim, ''),
        };
        this.props.onSubmit(data);
    }

    isValid = () => {
        if (this.state.phone && this.state.phone.replace(/\D/gim, '').length !== 11) {
        // if (this.state.phone.replace(/\D/gim, '').length !== 11) {
            return false;
        }
        if (this.state.phone === '') return false;
        return this.state.agree;
    };

    validatePhone = () => {
        this.setState({phone_err: !phoneValidator(this.state.phone)});
    };

    validateCheckbox = () => {
        this.setState({agree_err: !checkboxValidator(this.state.agree)});
    };

    onFocus = () => {
        this.setState({phone_err: null});
    };

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        });

        setCookie('numberMnpCookie', this.state.phone, {
            path: '/',
            expires: 30 * 3600 * 24,
            domain: cookieDomain
        });
        // this.hideScroll();
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({
            modal: '',
        });

        // this.showScroll();
    };

    renderModal() {
        let locationHref;
        if (typeof window !== 'undefined') {
            locationHref = `${window.location.origin}${window.location.pathname === '/' ? '/sverdlovsk_oblast/' : window.location.pathname}${window.location.search !== '' ? window.location.search : ''}`;
        }

        let path = `${esiaUrl}blitz/bridge/entrance`;

        let uuidNum = uuidv();
        let esiaUrlLink = `${path}?redirect_url=${locationHref}&state=${uuidNum}`

        switch (this.state.modal) {
            case "choose_an_option":
                return <Modal className="u-modal u-modal_active choose_modal" close={this.closeModal}>
                    <div className="content">
                        <h2 className="modal_title">Для продолжения необходимо предоставить паспортные данные</h2>
                        <div className="modal_btns">
                            <a 
                                href={esiaUrlLink} 
                                className="modal_btns_links modal_btns__portal"
                            >
                                <img src={`${require('../../../images/icons/icon_portal.svg')}`} alt="" />
                                <div className="modal_btns_text">
                                    Через госуслуги
                                </div>
                            </a>
                            <button 
                                className="modal_btns_links modal_btns__manually"
                                type="submit" 
                                onClick={(e) => {
                                    this.onSubmit(e);
                                    this.props.onToggleForm(1);
                                }} 
                            >
                                <img src={`${require('../../../images/icons/icon_manually.svg')}`} alt="" />
                                <div className="modal_btns_text">
                                    Ввести вручную <br/>
                                    <span>Заполнить данные самостоятельно</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal>
        }
    }

    render(){
        let {
            start_move_form_title,
            start_move_form_comment,
            start_move_form_agreement,
            activation,
            officeClick,
            children,
        } = this.props;

        let {phone, agree, phone_err, agree_err, phoneNotCorrect} = this.state;
        let idButton = setIdButton(this.context.selectedRegion.id, 'mnp', 'check', 'number', 'button');

        return <div className="page__change-form" id="start-move-form">
            {children}
            <div className="b-change-form b-change-form_adapt_default">
                <div className="b-change-form__wrapper">
                    <div className="b-change-form__title">{start_move_form_title}</div>
                    <div className="b-change-form__form">
                        <form>
                            <Radio
                                className={"b-come-offer__mnp_form mnp-start-move-form"}
                                name={this.state.arr.name}
                                items={this.state.arr.items}
                                value={this.state.arr.value}
                                onChange={this.onChangeRadio}
                                withTooltips={true}
                            />
                            <div className="b-change-form__row">
                                <div className="b-change-form__input">
                                    <div className="b-input-text b-input-text_adapt_big b-input-text_notification">
                                        <div className="b-input-text__wrapper">
                                            <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                                                <div className="b-input-text__label-text">Введите номер для переноса</div>
                                            </div>
                                            <div className="b-input-text__input-area">
                                                {start_move_form_comment ? <Tooltip id={"start_move_form_comment"} showOnClick={true} className={"b-input-text__tooltip " + (phoneNotCorrect ? " b-input-text__tooltip--correct" : "")} label={<Icon name="icon_notification" className="u-icon u-icon_notification" />}>
                                                    <p>{start_move_form_comment}</p>
                                                </Tooltip> : null}
                                                <input 
                                                    className={"b-input-text__input" + (phone_err ? " has-error" : "")}
                                                    type="text"
                                                    placeholder="+7"
                                                    onChange={this.onChange}
                                                    value={phone}
                                                    onBlur={this.validatePhone}
                                                    onFocus={this.onFocus}
                                                    disabled={activation.inProgress}
                                                />
                                                {phone_err 
                                                    ? <div className="b-input-text__input-error b-input__change-rate">Введите корректный номер телефона</div> 
                                                    : this.isValid() && phoneNotCorrect 
                                                        ? <div className="b-input-text__input-error b-input__change-rate b-input__not-correct">
                                                            Возможно, Ваш номер зарегистрирован в регионе, где нет присутствия оператора МОТИВ
                                                        </div> 
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button 
                                    id={idButton}
                                    onClick={() => {
                                        this.checkPhone();
                                        // this.openModal('choose_an_option');
                                        // this.validatePhone(); 
                                        // this.validateCheckbox();
                                    }}
                                    type="button" 
                                    className={"start-mnp-btn-container b-change-form__btn u-btn u-btn_default u-btn_lg-responsive"+(!this.isValid() || activation.inProgress ? " disabled" : "")}
                                >
                                    {
                                        activation.inProgress ?
                                          <span className='loader' /> :
                                          <span>Начать перенос номера</span>
                                    }
                                </button>

                                <div className="b-change-form__checkbox">
                                    <div className="b-input-checkbox b-input-checkbox_adapt_mnp">
                                        <label className="b-input-checkbox__wrap" htmlFor="start_move_form_agree">
                                            <input 
                                                className="b-input-checkbox__input"
                                                type="checkbox"
                                                onChange={this.onCheckboxChange}
                                                id="start_move_form_agree"
                                                checked={agree ? "checked" : ""}
                                                disabled={activation.inProgress}
                                            />
                                            <span className={"b-input-checkbox__icon" + (agree_err ? " has-error" : "")}/>
                                            <span className="b-input-checkbox__text">
                                                {start_move_form_agreement}
                                            </span>
                                        </label>
                                        {agree_err ? <div className="b-input-text__input-error b-input__change-rate">Поле обязательно для заполнения</div> : null}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {this.renderModal()}
        </div>;
    }
}
