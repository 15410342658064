import React, {Component} from 'react'

export default class Expenses extends  Component {

    render(){

        let {
            items,
            showModal
        } = this.props;

        return <div className="page_lk-details_expenses">
            <div className="details_expenses-title" dangerouslySetInnerHTML={{__html: items.title}}/>
            <div className="details_expenses-subtitle" dangerouslySetInnerHTML={{__html: items.subtitle}}/>
            <div className="b-message-action__action-block">
                <div className="b-message-action__btn">
                    <button onClick={showModal('order-expenses')} className="u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_expenses">Заказать детализацию</button>
                </div>
            </div>
        </div>

    }
}