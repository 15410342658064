import React, { Component } from 'react';
import PropTypes from "prop-types";
import {imgUrl, mainUrl} from "../../tools/config";
import LinkC from "../../../js/components/common/LinkC";

class OtherPaymentMethods extends Component {

    constructor(props) {
        super(props);

        this.state = {
            iconId: null,
            animate: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentDidMount(){
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    // ловим изменения ширины экрана
    onResize = () => {
        if(window.innerWidth > 768){
            this.setState({
                animate: true
            })
        }else{
            this.setState({
                animate: false
            })
        }
    };

    toggleHover = (id) => {
        this.setState({ iconId: id })
    };

    render() {

        let { 
            iconId,
            animate
        } = this.state;

        let {
            otherPaymentMethods
        } = this.props

        let selectedRegion = this.context.selectedRegion.alias;
        let title = otherPaymentMethods && otherPaymentMethods.title;
        let description = otherPaymentMethods && otherPaymentMethods.description;

        const allBanks = otherPaymentMethods && otherPaymentMethods.data;

        return (
            <div className="payment-methods__container">
                <div className="payment-methods__content">
                    <h2 className="payment-methods__title">{title}</h2>
                    <span className="payment-methods__subtitle">{description}</span>
                    <LinkC to={"/payments-methods"} className={"payment-methods__more"}>Подробнее</LinkC>

                    <div className="payment-methods__bank-icons">
                        {allBanks && allBanks.slice(0, 6).map((item) => 
                            <LinkC 
                                key={item.id}
                                to={(this.context.isLk && item.link.indexOf('http') !== 0 ? mainUrl + selectedRegion : "") + item.link}
                                className={"payment-methods__links"}
                                onMouseEnter={() => this.toggleHover(item.id)}
                                onMouseLeave={() => this.toggleHover(null)}
                            >
                                {animate 
                                    ? <img src={item.id === iconId ? imgUrl + item.hoverImg.file_path : imgUrl + item.withoutHoverImg.file_path} />
                                    : <img src={imgUrl + item.hoverImg.file_path} />
                                }
                            </LinkC>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default OtherPaymentMethods;