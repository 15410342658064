import React, {Component} from 'react';
import RatesPriceList from "./RatesPriceList";
import RatesCollapsing from "./RatesCollapsing";
import DownloadDoc from "../common/DownloadDoc";

class RatesExternalCallsResult extends Component {

    constructor(props) {

        super(props);

        this.state = {
            activeTab: 0,
        }
    }

    render () {

        let {
            searchResult,
            inSearch,
        } = this.props;

        return (
            <React.Fragment>
                {searchResult.length > 0
                    ?   <React.Fragment>
                            <h3 className="page__title-3">Тарифы на услуги связи {searchResult[0].whither_region}</h3>
                            <RatesPriceList blocks={[{title: false, items: searchResult[0].items}]} />
                            { searchResult[0].service && searchResult[0].service.info_dropdown
                                ?   <RatesCollapsing info_dropdown={searchResult[0].service.info_dropdown} />
                                :   ''
                            }
                        </React.Fragment>
                    :   inSearch
                           ?   <p>По вашему запросу ничего не найдено</p>
                           :   ''
                }
            </React.Fragment>
        )
    }
}

export default RatesExternalCallsResult;