import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';
import {hideLoading, showLoading} from "react-redux-loading-bar";

export function support(data) {
    return {
        type: 'SUPPORT',
        data
    }
}

export function* fetchSupport(action) {
    try {

        let body = action.data;

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/support", {
                method: 'POST',
                body
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function supportNotices() {
    return {
        type: 'SUPPORT_NOTICES'
    }
}

export function* fetchSupportNotices(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/support-notices", {
                method: 'GET'
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function clearSupportData() {
    return {
        type: 'CLEAR_SUPPORT_DATA'
    }
}


export function fastDecisions(regionId) {
    return {
        type: 'SUPPORT_FAST_DECISIONS',
        regionId
    }
}

export function* fetchFastDecisions(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/quick-decisions" + (action.regionId ? '?regionId='+action.regionId : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function ussd(regionId) {
    return {
        type: 'SUPPORT_USSD',
        regionId
    }
}

export function* fetchUssd(action) {
    try {

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/ussd" + (action.regionId ? '?regionId='+action.regionId : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function complain() {
    return {
        type: 'SUPPORT_COMPLAIN',
    }
}

export function* fetchComplain(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/page/complain", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function feedbackSubjects() {
    return {
        type: 'FEEDBACK_SUBJECTS',
    }
}

export function* fetchFeedbackSubjects(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/feedback/subjects", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function feedbackRequest (data) {
    return {
        type: 'FEEDBACK_REQUEST',
        data
    }
}
export function* fetchFeedbackRequest(action) {
    try {
        let body = action.data;

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/feedback-request", {
                method: 'POST',
                body
            })
        });
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function feedbackForm () {
    return {
        type: 'FEEDBACK_FORM'
    }
}
export function* fetchFeedbackForm(action) {
    try {
        const {status, data, error} = yield call(() => {
            return fetchClientApi("/forms/b2b", {
                method: 'GET',
            })
        });
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}


// подписка на рассылку
export function onSubscription(data) {
    return {
        type: 'ON_SUBSCRIPTION',
        data
    }
}

// подписка на рассылку
export function* fetchOnSubscription(action) {
    try {

        let formData = new FormData();

        formData.append('agreement', action.data.agreement);
        formData.append('captcha', action.data.captcha);
        formData.append('firstname', action.data.firstname);
        formData.append('email', action.data.email);
        formData.append('company', action.data.company);
        if(action.data.subs_id) formData.append('subs_id', action.data.subs_id);

        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/mailing/request", {
                    method: 'POST',
                    body: formData
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

// успешная подписка на рассылку
export function onSubscriptionIn(data) {
    return {
        type: 'ON_SUBSCRIPTION_IN',
        data
    }
}

// успешная подписка на рассылку
export function* fetchOnSubscriptionIn(action) {
    try {

        let formData = new FormData();

        formData.append('email', action.data.email);
        formData.append('token', action.data.token);

        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/mailing/accept", {
                    method: 'POST',
                    body: formData
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

// отказ от подписки на рассылку
export function onSubscriptionOut(data) {
    return {
        type: 'ON_SUBSCRIPTION_OUT',
        data
    }
}

// отказ от подписки на рассылку
export function* fetchOnSubscriptionOut(action) {
    try {

        let formData = new FormData();

        formData.append('email', action.data.email);
        formData.append('token', action.data.token);

        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/mailing/cancel", {
                    method: 'POST',
                    body: formData
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}