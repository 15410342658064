import React from 'react'
import Icon from "../common/Icon";
import LinkC from "../../components/common/LinkC";

export function MySidebarInfo({balance, fee, charge_date, paymentLink, details, color}) {

        return <div className="inner-header__sidebar is-mine">
            <div className="sidebar-head-info">
                {balance ? <div className="sidebar-head-info__price-block" style={color ? {color: color} : {}}>
                    <div className="sidebar-head-info__list-item-block">Баланс на {(new Date()).toLocaleDateString('ru')}</div>
                    <div className="sidebar-head-info__price">
                        <div className="sidebar-head-info__price-num">{balance}</div>
                        <div className="sidebar-head-info__measure">
                            <div className="sidebar-head-info__measure-cur">₽</div>
                        </div>
                    </div>
                </div> : null}
                <div className="sidebar-head-info__desc">
                    <ul className="sidebar-head-info__list">
                        <li className="sidebar-head-info__list-item" style={color ? {color: color} : {}}>
                            <div className="sidebar-head-info__list-item-block">Абонентская плата</div>
                            {details && details.fee && details.fee_with_discount && details.fee != details.fee_with_discount ? <div className="sidebar-head-info__list-item-price-with-discount">{details.fee + " ₽"}</div> : null}
                            <div className="sidebar-head-info__list-item-price">{
                                (details ? (details.fee && details.fee_with_discount && details.fee != details.fee_with_discount ? details.fee_with_discount : details.fee) : fee)}
                                <span className="sidebar-head-info__list-item-price price-rub"> ₽</span>
                            </div>
                        </li>
                        <li className="sidebar-head-info__list-item next-payment">
                            <div className="sidebar-head-info__list-item-block">Следующий платеж</div>
                            <div className="sidebar-head-info__list-item-price">{charge_date ? (new Date(charge_date)).toLocaleDateString('ru') : "-"}</div>
                            {/* <a className="payment-link" href={paymentLink}><Icon name="icon_pay" className="u-icon_pay"/>Пополнить счет</a> */}
                            <LinkC to={"/payments-transfers"} className="payment-link">
                                <Icon name="icon_pay" className="u-icon_pay"/>
                                Пополнить счет
                            </LinkC>
                        </li>
                    </ul>
                </div>
                {/* <a target="_blank" href={paymentLink} className="sidebar-head-info__pay"><Icon name="icon_pay_white" className="u-icon_pay"/>Пополнить счет</a> */}
                <LinkC to={"/payments-transfers"} className="sidebar-head-info__pay">
                    <Icon name="icon_pay_white" className="u-icon_pay"/>
                    Пополнить счет
                </LinkC>
            </div>
        </div>

}