import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { tariffsList } from "../../actions/tariffs";
import { infoBlocksList, getMnpBonuses } from "../../actions/infoBlocks";
import LinkC from "./../../components/common/LinkC";

import HomePageTariff from "../../components/Rates/HomePageTariff";
import InfoBlock from "../../components/common/InfoBlock";
import { checkOperator, clearOperatorData, checkRadioValue } from "../../actions/activation";
import { formsList, submitMnpForm } from "../../actions/forms";
import { phoneMask } from "../../tools/mask";
import { SuccessInfoModal } from "../../components/modals/constructor/InfoModal";
import TariffConnectionModal from "../../components/modals/constructor/TariffConnectionModal";
import { clearActivationData } from "../../actions/activation";
import RatesCarousel from "../../components/Rates/RatesCarousel";
import { mainVideo } from "../../actions/media";
import { deleteQueryParam, setCookie } from "../../tools/tools";
import { cookieDomain } from "../../tools/config";
import InfoBlockLastNews from "../../components/InfoBlocks/InfoBlockLastNews";
import { newsListShort } from "../../actions/news";
import BalansBlock from "../../components/BalansBlock/BalansBlock";
import { onSubscriptionIn, onSubscriptionOut } from "../../actions/support";
import Modal from "../../components/common/Modal";
import { scroller } from "react-scroll";

class RatesMainPage extends Component {

    constructor(props) {
        super(props);

        props.clearOperatorData();

        this.state = {
            phone: "",
            phoneError: "",
            agreement: false,
            agreementError: "",
            modal: "",
            idTariff: "",
            optionNames: [],
            modalEmailSubscribed: false,
            modalEmailUnsubscribed: false,
        }
    }

    componentDidMount() {
        this.props.getMnpBonuses();
        if (!!this.props.tariffConstructor.defaultData && this.state.optionNames.length === 0 && this.props.tariffConstructor.defaultData && this.props.tariffConstructor.defaultData.options && this.props.tariffConstructor.defaultData.options.length > 0) {
            this.setState({ optionNames: this.props.tariffConstructor.defaultData.options })
        }

        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token")
        const email = queryParameters.get("email")
        const action = queryParameters.get("action")
        if (token && email && action) {
            this.props.getForms('mailing')
            if (action === 'accept') {
                this.props.onSubscriptionIn({
                    email,
                    token
                })
                this.setState({ modalEmailSubscribed: true })
            } else {
                this.props.onSubscriptionOut({
                    email,
                    token
                })
                this.setState({ modalEmailUnsubscribed: true })
            }
            deleteQueryParam('token')
            deleteQueryParam('email')
            deleteQueryParam('action')
        }
        if (window.location.hash) {
            this.handleScroll(window.location.hash.replace('#', ''))
        }
    }

    // якорь к нужному месtу
    handleScroll = (target) => {
        scroller.scrollTo(target, {
            duration: 500,
            delay: 0,
            offset: -80,
            smooth: 'easeInOutQuart'
        });
    };

    onCheckboxChange = () => {
        this.setState((prevState) => {
            return {
                agreement: !prevState.agreement,
                agreementError: ""
            }
        })
    };

    onPhoneChange = (e) => {
        this.setState({ phone: phoneMask(e.target.value), phoneError: e.target.value ? (e.target.value.replace(/\D/gim, '').length == 11 ? "" : "Укажите корректный номер телефона") : "Поле обязательно для заполнения" });
    };

    // записываем в стор и локальное состояние значение выбранной рпадиокнопкив форме на главной
    onRadioChange = (value) => {
        this.props.checkRadioValue(value)
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.agreement && this.state.phone.replace(/\D/gim, '').length == 11 && !this.props.activation.inProgress) {
            let data = {
                phone: this.state.phone.replace(/\D/gim, ''),
                form_name: 'Шаг0',
            };

            this.props.checkOperator(data, 'main-page');

        } else {
            this.setState({
                phoneError: this.state.phone ? (this.state.phone.replace(/\D/gim, '').length == 11 ? "" : "Укажите корректный номер телефона") : "Поле обязательно для заполнения",
                agreementError: this.state.agreement ? "" : "Поле обязательно для заполнения"
            })
        }
    };

    operatorCheck = () => {
        let data = {
            phone: this.state.phone.replace(/\D/gim, '')
        };

        this.props.checkOperator(data);
        setCookie('numberMnpCookie', this.state.phone, {
            path: '/',
            expires: 30 * 3600 * 24,
            domain: cookieDomain
        });
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBeforeAfterSubmit: PropTypes.func,
        constructorParams: PropTypes.object,
    };

    // этот компоннет жизненного цикла отвечает за SSR данных, это антипод componentDidMount()
    componentWillMount() {
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'home_page');
        }
        if (!this.props.formSettings || Object.keys(this.props.formSettings).length === 0) {
            this.props.getFormsSettings();
        }
        if (this.props.newsList && this.props.newsList.newsBasic.length === 0) {
            this.props.getNewsListShort("?list_type=basic");
        }
        this.props.getMainVideos();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.activation.isOk !== this.props.activation.isOk) {
            if (typeof nextProps.activation.mnp.statusCode !== 'undefined' && nextProps.activation.mnp.statusCode === 1001 && nextProps.activation.mnp.params === "main-page") {
                this.context.fetchBeforeAfterSubmit("/" + this.context.selectedRegion.alias + "/mnp", null, { phone: this.state.phone.replace(/\D/gim, ''), step: 1 });
            }
        }

        if (this.props.forms && this.props.forms.isSubmitOk != nextProps.forms.isSubmitOk && nextProps.forms.isSubmitOk) {
            this.openSuccess("success");
        }

        // if (nextProps.activation.isOk !== this.props.activation.isOk && Object.keys(nextProps.activation.mnp).length !== 0) {
        //     if (nextProps.activation.mnp && nextProps.activation.mnp.StatusCode !== 3003 && nextProps.activation.mnp.operatorCode && nextProps.activation.mnp.operatorCode !== nextProps.activation.mnp.ownOperatorCode) {
        //         var data = new FormData();
        //         data.append('phone', this.state.phone.replace(/\D/gim, ''));
        //         data.append('form_name', "Переход_в_Мотив");
        //         this.props.submitForm(data, 'callback_form');
        //     }
        // }

        // if (nextProps.activation.isOk !== this.props.activation.isOk && Object.keys(nextProps.activation.mnp).length !== 0) {
        //     if (nextProps.activation.mnp && nextProps.activation.mnp.StatusCode !== 3003 && nextProps.activation.mnp.operatorCode && nextProps.activation.mnp.operatorCode !== nextProps.activation.mnp.ownOperatorCode) {
        //         console.log("success")
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.modal !== this.state.modal && this.state.modal === 'tariff-connection') {
            if (this.props.isGuest) {
                const el = document.querySelector('.phone_focus');
                if (el) el.focus();
            } else {
                const el = document.querySelector('.email_focus');
                if (el) el.focus();
            }
        }

        if (!!this.props.tariffConstructor.recalcData && !!this.props.tariffConstructor.recalcData.options) {
            if (JSON.stringify(prevProps.tariffConstructor.recalcData.options) !== JSON.stringify(this.props.tariffConstructor.recalcData.options)) {
                const arr = this.props.tariffConstructor.recalcData.options;
                const copyOptionNames = [...this.state.optionNames];
                let optionNames = [];

                if (copyOptionNames.length < 7) {
                    for (let i = 0; i < arr.length; i++) {
                        const item = copyOptionNames.find(x => x.type === arr[i].type);

                        if (!item) {
                            optionNames.push(arr[i]);
                        }

                    }

                    this.setState({ optionNames: [...copyOptionNames, ...optionNames] })
                }
            }
        }
    }

    renderModal() {
        let modifiedUrl = this.props.location && this.props.location.pathname;
        let urlStr = modifiedUrl.substr(1).split('-');
        let idTariff = this.state.idTariff;
        let tariff = this.props.tariffs.tariffsLists.filter(x => x.id === idTariff);
        let tariffParams = !!idTariff && tariff && tariff[0];
        let tariffName = tariffParams.name;
        let data_unlim_info = !!idTariff && tariffParams.svoi && tariffParams.svoi.data_unlim ? 1 : 0;
        let smsCount = !!idTariff && tariffParams.sms_count ? parseInt(tariffParams.sms_count) : 0;
        let sms = !!idTariff && tariffParams.svoi && tariffParams.svoi.sms ? 1 : 0;
        let paramGb = !!idTariff && tariffParams.svoi && tariffParams.svoi.data;
        let paramMin = !!idTariff && tariffParams.svoi && parseInt(tariffParams.svoi.min);
        let paramSocialUnlim = !!idTariff && tariffParams.svoi && tariffParams.svoi.social_network_unlim ? 1 : 0;
        let paramMotivVoiceUnlim = !!idTariff && tariffParams.svoi && tariffParams.svoi.motiv_voice_unlim ? 1 : 0;
        let paramYoutubeUnlim = !!idTariff && tariffParams.svoi && tariffParams.svoi.youtube_unlim ? 1 : 0;
        let paramIvi = !!idTariff && tariffParams.svoi && tariffParams.svoi.ivi ? 1 : 0;

        switch (this.state.modal) {
            case "tariff-connection":
                return <TariffConnectionModal tariffName={tariffName}
                    close={this.closeModal}
                    openSuccess={this.openSuccess}
                    settings={this.props.settings}
                    data_unlim_info={data_unlim_info}
                    urlStr={urlStr}
                    typeConnectionModal={'svoi'}
                    tariffParams={tariffParams}
                />;
            case "success":
                return <SuccessInfoModal title="Ваша заявка принята!"
                    text={this.props.settings.submitting_request_mnp_description}
                    btnText="Отлично!"
                    close={this.closeModal}
                />;
            case "success-tariff-connection":
                return <SuccessInfoModal title={'Тариф "' + tariffName + '" успешно подключен'}
                    btnText="Ок!"
                    close={this.closeModal}
                    gb={paramGb === "безлимит" || paramGb === "Безлимит" ? 0 : parseInt(paramGb)}
                    min={paramMin}
                    social_unlim={paramSocialUnlim}
                    sms={sms}
                    yotube_unlim={paramYoutubeUnlim}
                    ivi={paramIvi}
                    motiv_voice_unlim={paramMotivVoiceUnlim}
                    smsCount={smsCount}
                    settings={this.props.settings}
                />;
            default:
                return null;
        }
    }

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.props.clearActivationData();
        this.setState({ modal: '' });
        this.showScroll();

        if (this.state.idTariff !== '') {
            this.setState({
                idTariff: ''
            })
        }
    };

    hideScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.pp-mod_services-inner');
            if (el) el.classList.add('no-scroll');
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.pp-mod_services-inner');
            if (el) el.classList.remove('no-scroll');
        }
    };

    openSuccess = (modal) => {
        if (!this.props.isGuest && modal === 'success-tariff-connection') {
            setCookie('has_connection', new Date().getTime() + 300000, { expires: 300, path: '/', domain: cookieDomain })
        }
        this.setState({ modal: modal });
    };

    openTariffConnectionModal = (modal, idTariff) => {
        if (!!idTariff && this.state.idTariff === '') {
            this.setState({
                idTariff
            })
        }
        this.setState({ modal });
        this.hideScroll();
    };

    render() {
        let { regions, location, forms } = this.props;

        let tariffs = this.props.tariffs.tariffsLists.filter(x => x.region_id === this.context.selectedRegion.id && x.rank_popular);
        tariffs = tariffs.sort(function (t1, t2) {
            return t2.rank_popular - t1.rank_popular;
        });

        let infoBlocks = [], bottomLinksInfoBlocks = [], arrayCards = [];
        const infoBanner = this.props.infoBlocks && this.props.infoBlocks.list.find(x => x.type.alias === 'banner_with_tariffs');

        this.props.infoBlocks.list.map((block) => {
            if (block.alias.indexOf('home_page_bottom_link') === -1) {
                infoBlocks.push(block);
            } else {
                bottomLinksInfoBlocks.push(block);
            }
        });

        if (!!infoBanner) {
            let arrTemp = [];

            arrTemp.push({
                id: infoBanner.id,
                alias: 'banner_with_tariffs',
                imagePath: infoBanner.imagePath,
                link: infoBanner.link,
                link_title: infoBanner.link_title,
                title: infoBanner.title,
                internet_social_unlimit: false,
                internet_messenger_unlimit: false,
                slug: '',
                advertiser: infoBanner.advertiser,
                token: infoBanner.token,
            });

            if (tariffs && tariffs.length <= 2) {
                arrayCards = [...arrTemp, ...tariffs];
            } else {
                arrayCards = [...arrTemp];
            }
        } else {
            if (tariffs && tariffs.length <= 2) {
                arrayCards = [...tariffs];
            }
        }

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'mainB2CRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        const routePressCenter = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.alias === 'press-center');
        const routeAllNews = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.alias === 'news');

        const mailingForm = forms && forms.list.find(x => x.alias === 'mailing_form')

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        { "name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : "" }
                    ]}>
                    <link rel="canonical" href={pageLink} />
                </Helmet>

                <InfoBlock type={'full_screen_carousel'} data={infoBlocks} section={'full_screen_carousel'} />

                <div className="wrap_balans_user">
                    <BalansBlock {...this.props.client} />
                </div>

                <div className="page__container">
                    <div className="page__tariff-plates">
                        <div className="b-tariff-plates b-tariff-plates_adapt_default js-tarif-plates">
                            <div className="b-tariff-plates__wrapper">
                                <div className="b-tariff-plates__head">
                                    <div className="b-tariff-plates__title">Тарифы</div>
                                    {/* {кнопки link_with_icon} */}
                                    {/* <div className="b-tariff-plates__labels-section">
                                        <InfoBlock type="link_with_icon" data={infoBlocks} section="link_with_icon"/>
                                    </div> */}
                                </div>

                                <div className="b-tariff-plates__plates">
                                    {arrayCards && arrayCards.length > 0 ?
                                        arrayCards.map((tariff, id) =>
                                            <HomePageTariff
                                                type={'banner'}
                                                tariff={tariff}
                                                key={id}
                                                selectedRegion={this.context.selectedRegion}
                                                isGuest={this.props.isGuest}
                                                onClickConnect={this.openTariffConnectionModal}
                                                customClass={"with-tariff__wrap" + (arrayCards.length === 3 ? " with-tariff__wrap-average" : "")}
                                            />)
                                        :
                                        null}
                                    {tariffs && tariffs.length >= 3 ?
                                        <div className={"rates-carousel__wrap" + (arrayCards && arrayCards.length === 0 ? " rates-carousel__wrap-fz" : "")}>
                                            <RatesCarousel
                                                type={'tariff'}
                                                blocks={tariffs}
                                                selectedRegion={this.context.selectedRegion}
                                                isGuest={this.props.isGuest}
                                                onClickConnect={this.openTariffConnectionModal}
                                                customClass={"tariff-caurosel__wrap"}
                                                firstBlock={arrayCards && arrayCards.length > 0 ? true : false}
                                            />
                                        </div> :
                                        null}

                                    {/* {tariffs && tariffs.map((tariff, id) =>
                                        <HomePageTariff
                                            tariff={tariff}
                                            key={id}
                                            selectedRegion={this.context.selectedRegion}
                                            isGuest={this.props.isGuest}
                                            onClickConnect={this.openTariffConnectionModal}
                                        />)
                                    } */}
                                </div>
                                <div className="b-tariff-plates__link-section">
                                    <LinkC className="b-tariff-plates__link"
                                        to={"/tariffs"}>
                                        Все тарифы
                                    </LinkC>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page__come-offer">
                    <div className="b-come-offer b-come-offer_adapt_default">
                        <InfoBlock
                            onSubmit={this.onSubmit}
                            onCheckboxChange={this.onCheckboxChange}
                            onPhoneChange={this.onPhoneChange}
                            onRadioChange={this.onRadioChange}
                            phone={this.state.phone}
                            phoneError={this.state.phoneError}
                            agreement={this.state.agreement}
                            agreementError={this.state.agreementError}
                            type="full_screen_go_to_motive"
                            data={infoBlocks}
                            section="full_screen_go_to_motive"
                            activation={this.props.activation}
                            formSettings={this.props.formSettings && this.props.formSettings.settings}
                            operatorCheck={this.operatorCheck}
                            mnpBonuses={this.props.mnpBonuses}
                            inProgress={this.props.activation.inProgress}
                        />
                    </div>
                </div>
                <div className="page__container">
                    {/* блок с новостями на главной */}
                    {routePressCenter && routeAllNews ?
                        <InfoBlockLastNews data={this.props.newsList} linkPageNews={this.props.routesVacancies} /> :
                        null
                    }
                    <InfoBlock type="online_shop" data={infoBlocks} section="online_shop_card" />
                    <InfoBlock type="accent_banner" data={infoBlocks} section="accent_banner" />
                    <InfoBlock type="home_page_banner" data={infoBlocks} section="home_page_banner" />
                    <div className="page__icon-links">
                        <div className="b-icon-links b-icon-links_adapt_default">
                            <div className="b-icon-links__wrapper">
                                <InfoBlock type="link_with_icon" data={bottomLinksInfoBlocks} section="link_with_icon" />
                            </div>
                        </div>
                    </div>
                    <InfoBlock type="home_page_media_banner" data={infoBlocks} section="home_page_media_banner" />
                </div>
                {
                    ((this.state.modalEmailSubscribed || this.state.modalEmailUnsubscribed) && mailingForm) &&
                    <Modal
                        className="u-modal u-modal_active modal-fullscreen modal-email modal-email-success modal-email-subscribed"
                        close={() => {
                            this.setState({ modalEmailSubscribed: false, modalEmailUnsubscribed: false })
                        }}>
                        <div className="content">
                            <img src={`${require('../../../icons/circle-check.svg')}`}
                                className="modal-email-success__check"
                                alt="Успешно" />
                            {
                                this.state.modalEmailSubscribed &&
                                <div className="modal-email-success__title">{mailingForm && mailingForm.settings.mailing_form_subscribe_header ? mailingForm.settings.mailing_form_subscribe_header : 'Подписка на рассылку от Мотив подтверждена'}</div>
                            }
                            {
                                this.state.modalEmailUnsubscribed &&
                                <div className="modal-email-success__title">{mailingForm && mailingForm.settings.mailing_form_unsubscribe_header ? mailingForm.settings.mailing_form_unsubscribe_header : 'Вы успешно удалены из рассылки'}</div>
                            }
                            <div
                                className="vacancy-modal-btn-block support-send-block modal-email-btn modal-email-success-btn">
                                <button
                                    onClick={() => {
                                        this.setState({ modalEmailSubscribed: false, modalEmailUnsubscribed: false })
                                    }}
                                    type="submit"
                                    className={"u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default support-form-send-btn"}>
                                    Хорошо
                                </button>
                            </div>
                        </div>
                    </Modal>
                }
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        infoBlocks: state.infoBlocks,
        activation: state.activation,
        formSettings: state.forms.list ? state.forms.list.find(form => form.alias === "start_move_form") : {},
        isGuest: state.client.isGuest,
        settings: state.constructor.settings,
        forms: state.forms,
        tariffConstructor: state.tariffConstructor,
        regions: state.regions,
        newsList: state.news,
        routesVacancies: state.routesVacancies,
        client: state.client,

        routes: state.routes,
        mnpBonuses: state.infoBlocks.mnpBonuses,
    }),
    (dispatch) => {
        return {
            getTariffs: (regionId, categoryRankField, tariffRankField) => dispatch(tariffsList(regionId, categoryRankField, tariffRankField)),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            checkOperator: (formData, params) => dispatch(checkOperator(formData, params)),
            checkRadioValue: (value) => dispatch(checkRadioValue(value)),
            clearOperatorData: () => dispatch(clearOperatorData()),
            getFormsSettings: () => dispatch(formsList("mnp")),
            submitForm: (formData, alias) => dispatch(submitMnpForm(formData, alias, 'MNP')),
            clearActivationData: () => dispatch(clearActivationData()),
            getMainVideos: () => dispatch(mainVideo()),
            getNewsListShort: (listType) => dispatch(newsListShort(listType)),
            getForms: (group) => dispatch(formsList(group)),
            onSubscriptionIn: (data) => dispatch(onSubscriptionIn(data)),
            onSubscriptionOut: (data) => dispatch(onSubscriptionOut(data)),
            getMnpBonuses: () => dispatch(getMnpBonuses()),
        }
    }
)(RatesMainPage);

export const RatesMainPageRequests = [
    {
        func: 'getTariffs',
        args: [null, 'rank_popular'],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['home_page'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            { type: "full_screen_carousel" }
        ]
    },
    {
        func: 'getForms',
        args: ["mnp"],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getNewsListShort',
        args: ['?list_type=basic'],
        object: 'newsList.newsBasic',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    }
];