import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import RatesServicesFilter from "../../components/Rates/RatesServicesFilter";
import {promotionsPageAction, promotionsCategoryAction} from "../../actions/promotions"
import LinkC from "../../components/common/LinkC";
import {Link} from "react-router-dom";
import Icon from '../../components/common/Icon';
import {infoBlocksList} from "../../actions/infoBlocks";
import InfoBlock from "../../components/common/InfoBlock";
import {sortByProp} from "../../tools/tools";

const categoriesBase = {
    id: 1000,
    slug: "",
    title: 'Все',
    in_filter: true,
    rank_filter: 1000000,
};

class Promotions extends Component {
    constructor(props) {
        super(props);

        let slug = props.match.params.slug;

        this.state = {
            page: 1,
            slug: slug ? slug : "",
            categoriesFilter: [],
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        pushHistory: PropTypes.func,
    };

    componentWillMount(){
        let regionId = this.context && this.context.selectedRegion.id;

        // подтягиваем акции и категории
        if(!this.props.promotions.length){
            this.props.promotionsPageAction(regionId, this.props.match.params.slug);
            this.props.promotionsCategoryAction(regionId);
        }

        this.props.getInfoBlocks(regionId, 'promotions');
    }

    componentDidMount(){
        let regionId = this.context && this.context.selectedRegion.id;

        // подтягиваем акции и категории
        this.props.promotionsPageAction(regionId, this.props.match.params.slug, null, window.innerWidth < 768 ? 1 : null);
        this.props.promotionsCategoryAction(regionId);
        
        // добавляет к полю все остальной массив категорий
        if(this.state.categoriesFilter.length === 0){
            this.setState({
                categoriesFilter: this.categoriesFromList()
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        let regionId = this.context && this.context.selectedRegion.id;

        // пагинация
        if(prevState.page !== this.state.page){
            this.props.promotionsPageAction(regionId, null, this.state.page, window.innerWidth < 768 ? 1 : null);
        }

        // добавляет к полю все остальной массив категорий
        if(prevProps.category !== this.props.category){
            this.setState({
                categoriesFilter: this.categoriesFromList()
            })
        }
    }

    getMore = () => {
        this.setState({
            page: this.state.page + 1
        })
    }

    // добавляем поле все к остальным категориям
    categoriesFromList = () => {
        let cats = [];
        let decisions = this.props.category;

        cats.push(categoriesBase);

        if (decisions.length > 0) {
            decisions.forEach(function(item) {
                cats.push({
                    id: item.id,
                    slug: String(item.id),
                    title: item.title,
                    in_filter: true,
                    rank_filter: item.rank_filter,
                })
            })
        }

        cats = sortByProp(cats, 'rank_filter');

        return cats;
    };

    toggleCat = (data) => {
        let regionId = this.context && this.context.selectedRegion.id;

        this.setState({
            page: 1,
            slug: data
        });

        this.props.promotionsPageAction(regionId, data);

        let url = "";
        if (data === "") {
            url = '/' + this.context.selectedRegion.alias + '/actions';
        }else {
            url = '/' + this.context.selectedRegion.alias + '/actions/category/' + data;
        }
        this.context.pushHistory(url, {doNotScroll: true});
    };

    render() {
        let {
            promotions,
            pagination,
            infoBlocks,
            regions,
            location,
        } = this.props

        let selectedRegion = this.context.selectedRegion;

        let {
            slug
        } = this.state

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'actionsB2CRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                <div className="payments-page-container__content">
                    <div className="payments-page-container__wrapper">
                        <h1 className="payments-page__title">Акции</h1>

                        {(this.state.categoriesFilter.length > 1)
                            && (
                                <RatesServicesFilter 
                                    cats={this.state.categoriesFilter}
                                    slug={slug}
                                    toggleCat={this.toggleCat} 
                                />
                            )}

                        <div className="archived_promotions__all">
                            {promotions && promotions.map((item) => 
                                <Link 
                                    key={item.id}
                                    className="archived_promotions__cart"
                                    to={`/${selectedRegion.alias}/actions/${item.id}`}
                                >
                                    <div className="archived_promotions__cart_img">
                                        <img src={item.photo} alt=""/>
                                        <div className="archived_promotions__category_wrapper">
                                            <div className="promotion__category">{item.categories[0].title}</div>
                                        </div>
                                    </div> 
                                    <div className="archived_promotions__cart_date">{item.date}</div>
                                    <div className="archived_promotions__cart_title">{item.title}</div>
                                    <div className="archived_promotions__cart_info" dangerouslySetInnerHTML={{__html: item.short_description || item.description}} />
                                </Link>
                            )}
                        </div>

                        {(pagination && (pagination.currentPage < pagination.pageCount))
                            && (
                                <div className="news-and-events__link-to-news">
                                    <div 
                                        className="more-news-block" 
                                        onClick={this.getMore}
                                    >
                                        <Icon name={"icon_read-more-news"}/>
                                        <div className="more-news-block__title">Показать еще</div>
                                    </div>
                                </div>
                            )}
                        <LinkC to={"/actions/archive"} className="promotions__btn">
                            <div className="promotions__btn_img">
                                <Icon name={"icon_box"}/>
                            </div>
                            <div className="promotions__btn_text">Архив акций</div>
                        </LinkC>

                        <div className="promotions__slider_wrapper">
                            {/* type = нужный case section = api type.alias */}
                            <InfoBlock type={'promotions_slider'} data={infoBlocks && infoBlocks.list} section={'promotions_page_carousel'} />
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default connect(
    state => ({
        category: state.promotions.category,
        promotions: state.promotions.promotions,
        pagination: state.promotions.pagination,

        infoBlocks: state.infoBlocks || [],
        routes: state.routes,
        regions: state.regions,
        // infoBlocks: state.infoBlocks.list.filter((item) => item.groups.map((group) => group.alias).indexOf('services_payment') !== -1),
    }),
    (dispatch) => {
        return {
            promotionsPageAction: (regionId, data, pageNum, is_mobile) => dispatch(promotionsPageAction(regionId, data, pageNum, is_mobile)),
            promotionsCategoryAction: (regionId, data) => dispatch(promotionsCategoryAction(regionId, data)),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
        }
    }
)(Promotions);