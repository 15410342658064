import React, { Component } from 'react';

class TabsSwitcherNew extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {

        };

        this.scrollDiv = React.createRef();
    }

    componentDidMount() {
        let activeTab = this.props.activeTab;
        let widthWindow = window.innerWidth;

        let el = document.querySelector("#tabs_wrapper");
        let widthAllTabs = el.querySelector(".tabs_wrapper__container").offsetWidth;

        let tabPosition2 = widthAllTabs / 2 - widthWindow / 2 - 146 - ((48 + 24) / 2);   // длинна всего контейнера | длинна экрана | первый элемент | марджины?
        let tabPosition3 = widthAllTabs - widthWindow / 2 - 223 - 230 + 42;              // длинна всего контейнера | длинна экрана | третий элемент | четвертый элемент | марджины?

        if (activeTab === 0) {
            this.scrollDiv.current.scrollIntoView({ block: "nearest", inline: "start" });
        }
        if (activeTab === 1) {
            el.scrollBy(tabPosition2, 0);
            // el.scrollBy(120, 0); //320
            // el.scrollBy(80, 0); //400
        }
        if (activeTab === 2) {
            el.scrollBy(tabPosition3, 0);
            // el.scrollBy(350, 0); // 320
            // el.scrollBy(310, 0); //400
        }
        if (activeTab === 3) {
            this.scrollDiv.current.scrollIntoView({ block: "nearest", inline: "end" });
        }
    }

    render() {
        let {
            activeTab,
            toggleCat = () => { }
        } = this.props

        let tabs = [
            { id: 0, title: 'Настроить тариф', rank_filter: 1 },
            { id: 1, title: 'Готовые сборки', rank_filter: 2 },
            { id: 2, title: 'Тарифы для умных вещей', rank_filter: 3 },
            // { id: 3, title: 'Все тарифы по категориям', rank_filter: 4 },
            (activeTab !== 3 ? { id: 3, title: 'Все тарифы по категориям', rank_filter: 4 } : { id: 3, title: 'Все тарифы по категориям*', rank_filter: 4 }),
        ]

        return (
            <div className="tabs_wrapper" id="tabs_wrapper">
                <div
                    className="tabs_wrapper__container"
                    ref={this.scrollDiv}
                >
                    {tabs.map((block, index) =>
                        <div
                            key={index}
                            // href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleCat(block.id);
                                // this.toScroll(block.id);
                            }}
                            id="tabs_container__list__item"
                            className={`tabs_container__list__item ${block.id === activeTab ? 'tabs_container__list__item_active' : ''}`}
                        >
                            {block.title}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default TabsSwitcherNew;