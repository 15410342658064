import React, {Component} from 'react'
import Slider from 'rc-slider'
import IconArrowDown from "../../../images/content/icon-arrow-down.svg";
import IconCheckWhite from "../../../images/content/icon-check-white.svg";

export default class Appeal extends Component {

    constructor(props){
        super(props);
        this.state = {
            opened: false,
        }
    }

    render(){

        let {
            appeal,
        } = this.props;

        let {
            opened
        } = this.state;

        return (
          <li className={`page_lk-appeals-appeal ${opened ? 'appeal-opened' : ''}`} key={appeal.title}>
              <div className="page_lk-appeals-appeal-title">
                  <img
                    src={IconArrowDown} alt="Раскрыть обращение"
                    className="page_lk-appeals-appeal-title-arrow"
                    onClick={() => {this.setState({opened: !this.state.opened})}}
                  />
                  <div className="page_lk-appeals-appeal-title-num">{appeal.title}</div>
                  <div className="page_lk-appeals-appeal-title-start_date">{appeal.creationDate}</div>
              </div>
              <div className={`page_lk-appeals-appeal-status ${appeal.state === 'Завершен' ? 'page_lk-appeals-appeal-status-done' : ''}`}>{appeal.state}</div>
              <div className="page_lk-appeals-appeal-details">
                  <div
                    className={`page_lk-appeals-appeal-status-start page_lk-appeals-appeal-detail isStatused ${appeal.state === 'В работе' || appeal.state === 'Завершен' ? 'isStatusedLine' : ''}`}>
                      <div className="page_lk-appeals-appeal-status-title">
                          <div className="page_lk-appeals-appeal-status-check">
                              <img src={IconCheckWhite} alt=""/>
                          </div>
                          <div className="page_lk-appeals-appeal-status-title-text">Зарегистрирован</div>
                      </div>
                      <div className="page_lk-appeals-appeal-status-description">
                          <div className="page_lk-appeals-appeal-status-description-text">
                              Ваше обращение зарегистрировано, работа по нему начнется в ближайшее время
                          </div>
                      </div>
                  </div>
                  <div className={`page_lk-appeals-appeal-status-work page_lk-appeals-appeal-detail ${appeal.state === 'В работе' || appeal.state === 'Завершен' ? 'isStatused' : ''}  ${appeal.state === 'Завершен' ? 'isStatusedLine' : ''}`}>
                      <div className="page_lk-appeals-appeal-status-title">
                          <div className="page_lk-appeals-appeal-status-check">
                              {
                                (appeal.state === 'В работе' || appeal.state === 'Завершен') &&
                                <img src={IconCheckWhite} alt=""/>
                              }
                          </div>
                          <div className="page_lk-appeals-appeal-status-title-text">В работе</div>
                      </div>
                      <div className="page_lk-appeals-appeal-status-description">
                          <div className="page_lk-appeals-appeal-status-description-text">
                              Мы работаем над Вашим обращением, ожидайте, пожалуйста, ответ. Максимальный срок рассмотрения - 30 дней с даты регистрации
                          </div>
                      </div>
                  </div>
                  <div className={`page_lk-appeals-appeal-status-end page_lk-appeals-appeal-detail ${appeal.state === 'Завершен' ? 'isStatused' : ''}`}>
                      <div className="page_lk-appeals-appeal-status-title">
                          <div className="page_lk-appeals-appeal-status-check">
                              {
                                (appeal.state === 'Завершен') &&
                                <img src={IconCheckWhite} alt=""/>
                              }
                          </div>
                          <div className="page_lk-appeals-appeal-status-title-text">Завершен</div>
                      </div>
                      <div className="page_lk-appeals-appeal-status-description">
                          {
                              appeal.state === 'Завершен' ? (
                                <div>
                                    <div className="page_lk-appeals-appeal-status-description-date">{appeal.dateDecision}</div>
                                    <div className="page_lk-appeals-appeal-status-description-text" dangerouslySetInnerHTML={{__html: appeal.solution}} />
                                </div>
                              ) : (
                                <div className="page_lk-appeals-appeal-status-description-text">
                                    Тут будет официальный ответ на Ваше обращение
                                </div>
                              )
                          }
                      </div>
                  </div>
              </div>
          </li>
        )
    }
}