import React, { Component } from 'react';

import spriteIcon from '../../../images/sprites/sprite-svg/symbol/icons.svg';

class Icon extends Component {

    render() {
        return (

            <svg className={'icon ' + this.props.name + ' ' + this.props.className}>
                <use xlinkHref={spriteIcon + '#' + this.props.name}></use>
            </svg>
        )
    }
}

export default Icon;