import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import { showLoading, hideLoading } from 'react-redux-loading-bar'

export function newsList(listType, loadMore = false, finding = '') {
    return {
        type: loadMore ? 'NEWS_LIST_MORE' : 'NEWS_LIST',
        listType,
        finding,
    }
}

export function* fetchNewsList(action) {
    const queryString = action.finding !== '' ? '&search=' + action.finding : '';

    try {
        yield put(showLoading());
        const { status, data, error, pagination } = yield call( () => {
            return fetchClientApi(
                "/news" + (action.listType ? action.listType : '') + queryString, {
                    method: 'GET',
                })
        });

        if (error) console.log("error fetchNewsList", error);
        else {
            yield put(checkErrors(action.type, {data, pagination}, status ));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function newsListShort(listType) {
    return {
        type: 'NEWS_LIST_SHORT',
        listType
    }
}

export function* fetchNewsListShort(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/news" + (action.listType ? action.listType : ''), {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status ));
        }

    } catch(e) {
        console.log(e);
    } finally {
        // yield put(hideLoading());
    }
}