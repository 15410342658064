import React, {Component, Fragment} from "react";
import {setQueryParam} from "../../tools/tools";
import CollapseRateTariffs from "../common/CollapseRateTariffs";
import TooltipQuestion from "../common/TooltipQuestion/TooltipQuestion";
import TooltipInfo from "../common/TooltipQuestion/TooltipInfo";
import Paginator from "../common/Paginator";

export default class RateTariffsListContent extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            currentPage: 1
        }
    }

    render() {
        let {
            tariffList,
            isSmsTariffs,
            activeTab,
            setCurrentPage,
            pagination,
            stateSearchInput,
            sibling,
            supportTariffsSmsText,
            categoryList,
            isShownSearch
        } = this.props;

        const categoryDisc = (activeTab > 0 && categoryList.length > 0) ? categoryList.find(el => el.id === activeTab).note : '';

        return (
            <div>
                {
                    tariffList.length > 0 ? (
                        <React.Fragment>

                            {
                                isShownSearch &&
                                <p className="rate-tariffs-list__search-description">Популярные запросы:</p>
                            }

                            <div className="rates-tariffs-list__header">
                                <div className="rates-tariffs-list__header-name">Наименование</div>
                                <div
                                    className="rates-tariffs-list__header-number b-list-collapser__item-number-desktop">Номер
                                </div>
                                <div
                                    className="rates-tariffs-list__header-cost b-list-collapser__item-cost-desktop">
                                    <span>Цена, руб.</span>
                                    <TooltipInfo
                                        text={"Цены указаны с учетом НДС (НДС начисляется, согласно действующему законода-тельству РФ)"}/>
                                </div>
                            </div>
                            <div className="b-support-faq__block">
                                <div className="b-small-collapser b-small-collapser_adapt-defoult">
                                    {
                                        tariffList.map(item =>
                                            <div id={"faq-category-" + item.id} key={"faq-category-" + item.id}>
                                                <CollapseRateTariffs item={item} isSmsTariffs={isSmsTariffs}>
                                                    <div className="rates-tariffs-list__item-content-text">
                                                        <div
                                                            className="rates-tariffs-list__item-content-text__type">
                                                            <div
                                                                className="rates-tariffs-list__item-content-text__type-header">Тип
                                                            </div>
                                                            <div className="rates-tariffs-list__item-content-text__type-content">{item.service_type}</div>
                                                        </div>
                                                        <div
                                                            className="rates-tariffs-list__item-content-text__company">
                                                            <div
                                                                className="rates-tariffs-list__item-content-text__company-header">Компания,
                                                                предоставляющая услугу
                                                            </div>
                                                            <div className="rates-tariffs-list__item-content-text__type-content">{item.provider}</div>
                                                        </div>
                                                        <div
                                                            className="rates-tariffs-list__item-content-text__text rates-tariffs-list__item-content-text__type-content"
                                                            dangerouslySetInnerHTML={{__html: item.description}}
                                                        />
                                                    </div>
                                                </CollapseRateTariffs>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div>
                                {
                                    pagination.pageCount > 1 &&
                                    <Paginator
                                        className="pagination-bar"
                                        currentPage={pagination.currentPage}
                                        totalCount={pagination.totalCount}
                                        pageCount={pagination.pageCount}
                                        pageSize={pagination.perPage}
                                        onPageChange={setCurrentPage}
                                        siblingCount={sibling}
                                    />
                                }
                            </div>
                            <div>
                                {
                                    categoryDisc &&
                                    <div className="rates-tariffs__info-text-star"
                                         dangerouslySetInnerHTML={{__html: categoryDisc}}/>
                                }
                            </div>
                            <div>
                                <div className="rates-tariffs__info-text"
                                     dangerouslySetInnerHTML={{__html: supportTariffsSmsText.content}}/>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div>
                            {
                                this.props.loaded &&
                                <div className="rates-tariffs-list__empty">
                                    <svg width="81" height="80" viewBox="0 0 81 80" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_9757_52151)">
                                            <path
                                                d="M68.1145 0H12.8855C6.05609 0 0.5 5.55609 0.5 12.3855V67.6147C0.5 74.4439 6.05609 80 12.8855 80H68.1147C74.9439 80 80.5 74.4439 80.5 67.6145V12.3855C80.5 5.55609 74.9439 0 68.1145 0ZM75.8125 67.6145C75.8125 71.8592 72.3592 75.3125 68.1145 75.3125H12.8855C8.64078 75.3125 5.1875 71.8592 5.1875 67.6145V24.7708H75.8125V67.6145ZM75.8125 20.0833H5.1875V12.3855C5.1875 8.14078 8.64078 4.6875 12.8855 4.6875H68.1147C72.3592 4.6875 75.8125 8.14078 75.8125 12.3855V20.0833Z"
                                                fill="#FA6600"/>
                                            <path
                                                d="M15.3958 10.042H12.8855C9.78051 10.1565 9.7802 14.6148 12.8855 14.7295H15.3958C18.5008 14.6148 18.5011 10.1567 15.3958 10.042Z"
                                                fill="#FA6600"/>
                                            <path
                                                d="M26.6929 10.042H24.1824C21.0774 10.1565 21.0771 14.6148 24.1824 14.7295H26.6929C29.7977 14.6148 29.7982 10.1567 26.6929 10.042Z"
                                                fill="#FA6600"/>
                                            <path
                                                d="M37.9897 10.042H35.4793C32.3743 10.1565 32.3739 14.6148 35.4793 14.7295H37.9897C41.0946 14.6148 41.095 10.1567 37.9897 10.042Z"
                                                fill="#FA6600"/>
                                            <path
                                                d="M38.3909 49.9668C39.5535 48.8046 41.4457 48.8046 42.6084 49.9668C43.5238 50.882 45.0081 50.8814 45.9229 49.9659C46.8379 49.0504 46.8376 47.5664 45.922 46.6514C42.9321 43.6631 38.0671 43.6631 35.0773 46.6514C32.9624 48.9276 36.1129 52.0809 38.3909 49.9668Z"
                                                fill="#FA6600"/>
                                            <path
                                                d="M58.0732 42.3442C59.3676 42.3442 60.417 41.2948 60.417 40.0005V37.49C60.3025 34.385 55.8442 34.3847 55.7295 37.49V40.0005C55.7295 41.2948 56.7789 42.3442 58.0732 42.3442Z"
                                                fill="#FA6600"/>
                                            <path
                                                d="M22.9268 42.3442C24.2211 42.3442 25.2705 41.2948 25.2705 40.0005V37.49C25.156 34.385 20.6977 34.3847 20.583 37.49V40.0005C20.583 41.2948 21.6324 42.3442 22.9268 42.3442Z"
                                                fill="#FA6600"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_9757_52151">
                                                <rect width="80" height="80" fill="white" transform="translate(0.5)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="rates-tariffs-list__empty-text">По вашему запросу ничего не
                                        найдено.</p>
                                </div>
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}