import React, { Component } from 'react';
import Icon from "../common/Icon";
import Modal from "../common/Modal";

class SuccessModal extends Component {

    render() {

        let {
            close,
            title,
            text,
            icon = true,
            btnText,
            payments = false,
            motivPay = null,

            className = "u-modal u-modal_active u-modal-top tariff-connection-modal",
            wrapperClassName = '',
            typeWidth = ''
        } = this.props;

        return (
            <Modal
                close={close}
                typeWidth={typeWidth}
                wrapperClassName={wrapperClassName}
                className={className}
            >
                <div className={`mnp-popup-ok swiper-container mnp-popup__wrapper ${typeWidth === 'md' ? 'mnp-popup-ok__md' : ""}`}>
                    <div className="text-center">
                        {icon ? <Icon name={'icon-form-ok'} className={"mnp-popup-ok__icon"}/> : null}
                        <div className="b-plug-auth__title">{title}</div>

                        {text ? <div dangerouslySetInnerHTML={{__html: text}} className={`b-success-modal__text ${payments ? "top-up-balance__modal" : ""}`} /> : null}
                        {/* {text ? <div className={`b-success-modal__text ${payments ? "top-up-balance__modal" : ""}`}>{text}</div> : null} */}

                        {payments && motivPay}
                        <button 
                            type="button" 
                            className={`u-btn u-btn_default u-btn_adapt_mobile-sm ${payments ? "top-up-balance__modal-btn" : ""}`} onClick={close}>{btnText}
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default SuccessModal;