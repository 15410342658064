import {parseErrors} from "../tools/api-helper";

const initState = {
    isOk:false, 
    list: [], 
    inProgress: false,
    esiaData: {}
};

export function forms(state = initState, action) {
    switch (action.type) {
        case 'FORMS_LIST':
            return {...state, isOk: false};
        case 'FORMS_LIST_SUCCESS':
            let forms = [...state.list];
            action.data.map((item) => {
                if (forms.filter((elem) => {
                    return item.alias === elem.alias
                }).length === 0) {
                    forms.push(item);
                }
            });
            return {...state, list: action.data, isOk: true};
        case 'MNP_FORM_SUBMIT':
            return {...state, isSubmitOk: false, submitError: "", inProgress: true};
        case 'MNP_FORM_SUBMIT_IS_REPEAT':
            return {...state, isSubmitOk: action.data.alias, submitError: "", inProgress: false};
        case 'MNP_FORM_SUBMIT_SUCCESS':
            return {...state, isSubmitOk: action.data.alias, addresses: [], city: [], address: [], region: [], metro: [], house: [], block: [], inProgress: false};
        case 'VACANCY_RESPOND':
            return {...state, isSubmitOk: false, inProgress: true};
        case 'CLEAR_VACANCY_RESPOND_FORM_DATA':
            return {...state, isSubmitOk: false, vacancyFormOk: false};
        case 'VACANCY_RESPOND_SUCCESS':
            return {...state, isSubmitOk: true, vacancyFormOk: true, inProgress: false};
        case 'VACANCY_RESPOND_BAD_REQUEST':
        case 'VACANCY_RESPOND_FORBIDDEN':
        case 'VACANCY_RESPOND_VALIDATION_ERROR':
            return {...state, inProgress: false};
        case 'COMPLAIN_FORM':
            return {...state, isSubmitOk: false, inProgress: true, submitError: "", errorValidation: false};
        case 'CLEAR_COMPLAIN_FORM_DATA':
            return {...state, isSubmitOk: false, complainFormOk: false, errorValidation: false, complainFormError: false};
        case 'COMPLAIN_FORM_SUCCESS':
            return {...state, isSubmitOk: true, complainFormOk: true};
        case 'COMPLAIN_FORM_BAD_REQUEST':
            return {...state, submitError: action.data.message, inProgress: false, error: action.error, complainFormError: true};
        case 'COMPLAIN_FORM_ERROR':
            return {...state, inProgress: false, formError: true};
        case "COMPLAIN_FORM_VALIDATION_ERROR":
            return {...state, inProgress: false, errors: action.data, errorValidation: true, isOk: false, complainFormError: true};
        case 'MNP_FORM_SUBMIT_BAD_REQUEST':
            return {...state, submitError: action.data.message, inProgress: false};
        case 'ADDRESS_AUTO_COMPLETE_SUCCESS':
            return {...state, [action.data.suggestionType]: action.data.list};
        case 'SHOPS_LIST_SUCCESS':
            return {...state, address: []};
        case 'CLEAR_AUTO_COMPLETE_ADDRESS':
            return {...state, address: [], metro: [], house: [], block: []};
        case 'CLEAR_AUTO_COMPLETE_CITY':
            return {...state, address: [], city: [], metro: [], house: [], block: []};
        case 'CLEAR_AUTO_COMPLETE_STREET':
            return {...state, house: [], block: []};
        case 'CLEAR_AUTO_COMPLETE_HOUSE':
            return {...state, block: []};
        
        case 'MNP_FORM_ESIA':
            return {...state, isOk: false, inProgress: true};
        case 'MNP_FORM_ESIA_SUCCESS':
            return {...state, isOk: true, inProgress: false, esiaData: action.data};
        case 'CLEARE_MNP_FORM_ESIA':
            return {...state, isOk: false, inProgress: true, esiaData: {}};

    }
    return state;
}