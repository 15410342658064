import React, { Component } from 'react';

class Collapse extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {
            title,
            children,
            itemLength,
            fnCollapse,
            flag,
            opened
        } = this.props;
        
        return (
            <div className={"b-list-collapser__item" + (opened ? " b-list-collapser__item_collapsed" : "")}>
                <div className="b-list-collapser__item-header" onClick={() => {fnCollapse(flag)}}>
                    <div className="b-list-collapser__item-title">{title}
                        <span className="b-list-collapser__item-number">{itemLength}</span>
                    </div>
                </div>
                <div className={"u-collapser" + (opened ? " u-collapser_open" : "")}>
                    <div className="u-collapser__container">
                        <div className="b-list-collapser__collapsed-area ">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Collapse;