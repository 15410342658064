import React, {Component} from 'react';
import PropsTypes from 'prop-types'
import {connect} from 'react-redux'
import TopBlock from "../../components/Lk/TopBlock";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import {getFilteredMyAppeals} from "../../actions/client"

import {Helmet} from "react-helmet";
import Appeal from "../../components/Lk/Appeal";

class MyAppeals extends Component {

    constructor(props){
        super(props);

        let tabs = [
            {title: "Все обращения"},
            {title: "В работе"},
            {title: "Завершенные"}
        ];

        this.state = {
            tabs,
            activeTab: 0,
        };
    }

    static contextTypes = {
        selectedRegion: PropsTypes.object
    };

    toggleTab = (i) => {
        return (e) => {
            if (e) e.preventDefault();

            this.setState({activeTab: i}, () => {
                let blank = 'all';
                switch (this.state.activeTab) {
                    case 1:
                        blank = 'in-working';
                        break;
                    case 2:
                        blank = 'finished';
                        break;
                }
                if(this.props.phone) this.props.getFilteredMyAppeals(this.props.phone, blank);
            })
        }
    };

    render(){

        let {tabs, activeTab} = this.state;
        let {filteredAppeals, allAppeals, appealsLoading} = this.props;

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'appeals');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <div className="page_lk-details">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <TopBlock title="Мои обращения"/>
            <div className="page__tabs">
                <TabsSwitcher items={tabs} onClickFunc={this.toggleTab} activeTab={activeTab}/>
            </div>
            <div className='page_lk-appeals-container'>
                {
                    !appealsLoading ?
                        <div>
                            {
                                filteredAppeals.length > 0 ? (
                                    <ul>
                                        {
                                            filteredAppeals.map(appeal => (
                                                <Appeal appeal={appeal} key={appeal.title} />
                                            ))
                                        }
                                    </ul>
                                ) : (
                                    <div className="page_lk-appeals-empty">
                                        <div className="page_lk-appeals-empty-title">
                                            {(allAppeals.length === 0 || activeTab === 0) && 'У вас еще нет обращений или их срок хранения истек'}
                                            {(allAppeals.length !== 0 && activeTab === 1) && 'Все Ваши обращения завершены'}
                                            {(allAppeals.length !== 0 && activeTab === 2) && 'Все текущие обращения находятся в работе, пожалуйста, ожидайте'}
                                        </div>
                                        <div className="page_lk-appeals-empty-description">Обращения хранятся 30 дней после создания</div>
                                    </div>
                                )
                            }
                        </div> :
                        <div className="page_lk-appeals-loader-container">
                            <div className="loader"></div>
                        </div>
                }
            </div>
        </div>
    }
}

export default connect(
    state => ({
        routes: state.routes,

        payments: state.client.payments || [],
        paymentsMessage: state.client.paymentsMessage,
        allAppeals: state.client.allAppeals || [],
        filteredAppeals: state.client.filteredAppeals || [],
        appealsLoading: state.client.appealsLoading,
        phone: state.client.clientInfo.username,
    }),
    dispatch => {
        return {
            getFilteredMyAppeals: (phone, blank) => dispatch(getFilteredMyAppeals(phone, blank)),
        }
    }
)(MyAppeals)
