import React, { Component } from 'react';
import PropTypes from "prop-types";
import {mediaUrl} from "../../tools/config";

class MediaMaterialsLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            linkItems: null,
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    componentWillMount() {
        const items = this.props.blocks;
        const linkItems = this.state.linkItems;

        if ( !!items && linkItems === null ){
            this.setItemsAddHash( items );
        }
    }

    setItemsAddHash = (items) => {
        if ( !!items ){
            items.forEach(el => {
                if ( el.alias === "press_center_bottom_link_1" ){
                    el.hashId = "#videoAll";
                } else {
                    el.hashId = "";
                }
            });
            this.setState({
                linkItems: items
            });
        }
    };

    render() {

        let { linkItems } = this.state;
        let fetchBefore = this.context.fetchBefore;

        return ( <div className="media_materials_link">
            {linkItems && linkItems.map((el) =>
                <a key={ el.id } href={ "/" + this.context.selectedRegion.alias + el.link + el.hashId}
                   className="media_materials_link-block"
                   onClick={ fetchBefore( "/" + this.context.selectedRegion.alias + el.link + el.hashId)}
                   rel="nofollow">
                    <img className="media_materials_link-block_img" src={ mediaUrl + el.imagePath } alt="icon_media" />
                    <span className="media_materials_link-block_title" dangerouslySetInnerHTML={{__html: el.title}} />
                </a>
            )}
        </div>)
    }
}

export default MediaMaterialsLink;
