import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList} from "../../actions/routes";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import SearchVacancy from "../../components/Vacancies/SearchVacancy";
import ContactsHR from "../../components/Vacancies/ContactsHR";
import {VacancyModalForm} from "../../components/modals/VacancyModal";
import backgroundImg from "../../../images/content/mnp/mnp-hero-bg.png";

import InfoBlock from "../../components/common/InfoBlock";
import {smoothScroll} from "../../tools/tools";
import {vacanciesList} from "../../actions/vacancies";
import {contactsList} from "../../actions/contacts";
import {infoBlocksList} from "../../actions/infoBlocks";
import {routesVacanciesList} from "../../actions/routes";
import {clearVacancyRespondFormData} from "../../actions/forms";

class Vacancies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            banner: {
                title: "Присоединяйся</br> к команде профессионалов</br> «МОТИВ»",
                backgroundImg: backgroundImg
            }
        };
    }

    componentWillMount() {
        let regionId = this.context && this.context.selectedRegion.id;

        if ( this.props.routes.list.length === 0 ) {
            this.props.getRoutes();
        }

        if ( this.props.routesVacancies.list.length === 0 ){
            this.props.getRoutesVacancies();
        }

        // для подтягиваня ssr всех вакансий
        if(this.props.vacanciesList){
            this.props.getVacanciesList();
        }

        if (this.props.contactsList && this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("hr");
        }

        let list_banner = this.props.infoBlocks && this.props.infoBlocks.list.find(x => x.alias === 'about_vacancy_list_banner');
        let about_send_cv = this.props.infoBlocks && this.props.infoBlocks.list.find(x => x.alias === 'about_send_cv');
        let about_modal_success = this.props.infoBlocks && this.props.infoBlocks.list.find(x => x.alias === 'about_modal_success');

        if (this.props.infoBlocks.list.length === 0 || !list_banner || !about_send_cv || !about_modal_success) {
            this.props.getInfoBlocks(regionId, 'about_company');
        }
    }

    componentDidUpdate() {
        let modal = this.state.modal;
        if (typeof document !== 'undefined') {
            if (modal === "open" || modal === "success") {
                this.hideScroll();
            } else {
                this.showScroll();
            }
        }

        let vacancyFormOk = this.props.vacancyRespond && this.props.vacancyRespond.vacancyFormOk;
        if ( !!vacancyFormOk && modal === "open" ){
            this.setState({modal: "success"});
            this.props.clearData();
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    openModal = (modal) => {

        return (e) => {

            if (e) e.preventDefault();
            this.setState({
                modal: modal
            });
        }
    };

    hideScroll = () =>{
        let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("no-scroll");
            scrollTop = window.pageYOffset;
            document.body.style.position = "fixed";
            document.body.style.top = -scrollTop + 'px';
        }
    };

    closeModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: 'close'});
        this.showScroll();
    };

    showScroll = () => {

        if (typeof document != "undefined") {
            document.body.classList.remove('no-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            window.scroll(0, this.scrollTop);
        }
    };

    renderModal = () => {
        let selectedRegion = this.context ? this.context.selectedRegion : null;
        let infoBlocks = this.props.infoBlocks ? this.props.infoBlocks.list : null;
        let regionsList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.regions : [];
        let vacanciesList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.vacancies : [];
        switch (this.state.modal) {
            case "open":
                return <VacancyModalForm close={ this.closeModal }
                                         regions={ regionsList }
                                         vacancyList={ vacanciesList }
                                         selectedRegion={ selectedRegion }
                />;
            case "success":
                return <InfoBlock type={'about_modal_success'} data={infoBlocks} section={'about_modal_success'} close={ this.closeModal } />;
            default:
                return "";
        }
    };

    render() {
        const { regions, location } = this.props;
        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder vacancies");
        let selectedRegion = this.context.selectedRegion;
        let regionsList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.regions : [];
        let vacanciesList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.vacancies : [];
        let contactsList = this.props.contactsList ? this.props.contactsList.contacts : [];

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "",
                title: "Работа в МОТИВ"
            }
        ];

        let infoBlocks = this.props.infoBlocks.list;
        let route = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "vakansiiRoute");
        let vacancyLink = route && route.link;
        const filterTitle = infoBlocks.find(x => x.alias === 'about_company_vacancy_title_1');
        const subTitleSearchVacancy = filterTitle && filterTitle.text;

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'vakansiiRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);
        
        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container motiv-vacancies">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </div>
                            </div>
                            <div className="b-intro-simple__title">Работа в МОТИВ</div>
                            <InfoBlock type={'big-carousel'} data={infoBlocks} section={'about_vacancy_list_banner'} />
                            <div className="page__content-block">
                                <InfoBlock type={'about_send_cv'} data={infoBlocks} section={'about_send_cv'} openModal={ this.openModal } />
                            </div>
                            {filterTitle && filterTitle.title ? <div className="page__title-3">{ filterTitle.title }</div> : null}
                            <div className="page__vacancy-search">
                                <SearchVacancy
                                    regions={regionsList}
                                    vacancyList={vacanciesList}
                                    selectedRegion={selectedRegion}
                                    vacancyLink={vacancyLink}
                                    subTitle={subTitleSearchVacancy}
                                />
                            </div>
                        </div>
                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <AsideMenu 
                                    flag={ route.sys_name } 
                                    route={ this.props.routesVacancies } 
                                    customTitleMenu={'Работа в Мотив'} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        vacanciesList: state.vacancies || [],
        contactsList: state.contacts || [],
        infoBlocks: state.infoBlocks || [],
        vacancyRespond: state.forms || [],
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getVacanciesList: () => dispatch(vacanciesList()),
            getContactsList: (name) => dispatch(contactsList(name)),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            clearData: () => dispatch(clearVacancyRespondFormData()),
        }
    }
)(Vacancies);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const VacanciesRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['about_company'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "about_vacancy_list_banner"},
            {type: "about_send_cv"},
            {type: "about_modal_success"},
        ]
    },
    {
        func: 'getContactsList',
        args: ['hr'],
        object: 'contactsList.contacts',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getVacanciesList',
        args: [],
        object: 'vacanciesList.vacancies',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
];
