import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import Icon from "../../components/common/Icon";
import LinkC from "../../components/common/LinkC";
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";

import {lkUrl, mainUrl} from "../../tools/config";

// центральное меню
class LKMain extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isLk: PropTypes.bool,
    };

    render() {
        let {
            isGuest,
            lkMenu,
            settings
        } = this.props.client;

        // для ссылки О тарифе
        let region = this.context.selectedRegion.alias;
        let regionSlug = this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug;
        let detailUrl = (regionSlug ? "/" + regionSlug : "") + "/tariffs";
        let detailUrlLk = (regionSlug ? regionSlug : region) + "/tariffs";
        const slug = this.props.client.clientInfo.tariff ? this.props.client.clientInfo.tariff.slug : ''
        const status_id = this.props.client.clientInfo.tariff ? this.props.client.clientInfo.tariff.status_id : ''
        if (slug) {
            switch (status_id) {
                case 2:
                    detailUrl += "/archive/";
                    detailUrlLk += "/archive/";
                    break;
                case 3:
                    detailUrl += "/individual/";
                    detailUrlLk += "/individual/";
                    break;
                default:
                    detailUrl += "/";
                    detailUrlLk += "/";
            }
            detailUrl += slug;
            detailUrlLk += slug;
        }
        // ссылка на нужный контент
        const linkNewMenuFields = this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl;
        let newMenuFields = [{
            alias: "currentTariff",
            description: "",
            // iconName: "icon_new_slices",
            iconName: "icon_slices",
            link: linkNewMenuFields,
            seoTitle: "",
            title: "Текущий тариф",
            sort: 2
        }]
        // создаем новый массив меню, заливая в него новый объект
        const newLkMenu = lkMenu.concat(newMenuFields)
        // сортируем по порядку поля sort
        newLkMenu.sort(function (a, b) {
           return a.sort - b.sort
        });
        
        const { regions, location } = this.props;

        if (isGuest) {
            return <Redirect to="/"/>
        }

        // let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'mainLKRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <main className="page__main-holder" role="main">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
                {/* <link rel="canonical" href={pageLink} /> */}
            </Helmet>
            
            <div className="page__lk-index-wrap">
                <div className="page__container">
                    <div className="b-lk-index b-lk-index_adapt_default">
                        <div className="b-lk-index__header">
                            <div className="b-lk-index__title">Настройка аккаунта</div>
                            <div className="b-lk-index__subtitle">
                                {settings && settings.account_settings_page_description}
                            </div>
                        </div>
                        <div className="b-lk-index__page-list">
                            {newLkMenu.map((item, key) => <div className="b-lk-index__item" key={key}>
                                <div className="b-lk-index__body">
                                    <LinkC to={item.link} className="b-lk-index__icon-block">
                                        <Icon name={item.iconName} className={"b-lk-index__icon u-icon u-"+item.iconName}/>
                                    </LinkC>
                                    <LinkC to={item.link}>
                                        <div className="b-lk-index__label">{item.title}</div>
                                    </LinkC>
                                    <div className="b-lk-index__text">
                                        {item.description}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    }
}

export default connect(
    state => ({
        regions: state.regions,

        routes: state.routes,
        client: state.client,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
    }),
    (dispatch) => {
        return {

        }
    }
)(LKMain);