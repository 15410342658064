import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {disablePageScroll, enablePageScroll} from 'scroll-lock';
import {connect} from "react-redux";
import { userPhoneValueAction } from '../../actions/common';

export class Modal extends Component {

    onClick = (e) => {
        e.stopPropagation();
    };

    modalClose = () => {
        // обнуляем глобальное состояние
        if(!this.props.isFromSuccessModal && this.props.userPhoneValueAction) {
            this.props.userPhoneValueAction('')
        }
        this.props.close();
    }

    constructor(props) {
        super(props);

        this._modal = null
    }

    componentDidMount() {
        disablePageScroll(this._modal);
    }

    componentDidUpdate(prevProps){
        if (prevProps.isGuest !== this.props.isGuest) {
            this.props.close();
        }
    }

    componentWillUnmount() {
        enablePageScroll(this._modal);
    }

    render() {

        if (typeof document !== 'undefined') {
            let {
                className = "u-modal u-modal_active",
                wrapperClassName = "u-modal-wrapper",
                innerClassName = "u-modal-inner",
                closeBtnClassName = "mnp-popup__close",
                children,
                typeWidth
            } = this.props;

            return ReactDOM.createPortal(
                <div
                    className={className}
                    onClick={() => {
                        this.modalClose();
                    }}
                    ref={ref => this._modal = ref}
                >
                    <div className={`${wrapperClassName} ${typeWidth === 'md' ? 'u-modal-wrapper__md' : ""}`}>
                        <div className={innerClassName} onClick={this.onClick}>
                            <span onClick={this.modalClose} className={closeBtnClassName} />
                            {children}
                        </div>
                    </div>
                </div>, document.getElementById('modal'));
        }

        return "";
    }
}

export default connect(
    state => ({
        userPhoneValue: state.common.userPhoneValue,
        isGuest: state.client.isGuest,
    }),
    (dispatch) => {
        return {
            userPhoneValueAction: (data) => dispatch(userPhoneValueAction(data)),
        }
    }
)(Modal);