const initState = { isOk:false };

export function feedback(state = initState, action) {

    switch (action.type) {
        case 'FEEDBACK_ORDER_REQUEST':
            return {...state, isOk: false};
        case 'FEEDBACK_ORDER_REQUEST_SUCCESS':
            return {...state, isOk: true};
        case 'FEEDBACK_FAQ_REQUEST':
            return {...state, isOk: false};
        case 'FEEDBACK_FAQ_REQUEST_SUCCESS':
            return {...state};
    }
    return state;
}