import React, { Component } from 'react';
import LinkC from "./LinkC";

export default class CookieAgreement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened
        }
    }

    collapse() {
        this.setState((prevState) => {
            return {opened: !prevState.opened}
        });
    }

    accept = () => {
        this.props.accept();
        this.collapse();
    };

    render() {

        // if (this.props.cookieAgreement) {
        //     console.log('q1');
        //     return null
        // }

        let url = this.props.url;

        if (!url) {
            url = "/cookies-agreement";
        }

        return (
            <div className={"cookie-agreement"}>
                <div className={"page__container wrap-lg"}>
                    <div className={"cookie-agreement__content"}>
                        <div className={"cookie-agreement__button-container"}>
                            <LinkC
                                to={"/cookies-agreement"}
                                className="u-btn u-btn_default u-btn_adapt_mobile-sm cookie-agreement__button cookie-agreement__button__more"
                            >Подробнее</LinkC>
                            <button
                                onClick={this.accept}
                                className="u-btn u-btn_default u-btn_adapt_mobile-sm cookie-agreement__button"
                            >Принять</button>
                        </div>
                        <div className={"cookie-agreement__text"}>
                            Для персонализации сервисов сайт ООО «ЕКАТЕРИНБУРГ-2000» использует файлы сookie, применяя метрические и иные системы аналитик. Запретить эти действия можно в настройках браузера, либо отказаться от использования сайта.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}