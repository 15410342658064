import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import DocumentsAbout from "../../components/InfoPages/DocumentsAbout";
import {connect} from "react-redux";
import {getPage} from "../../actions/routes";

import {Helmet} from "react-helmet";

class LegalInformation extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount() {
        let {page, getPage} = this.props;

        if (!page) {
            getPage(this.context.selectedRegion.id);
        }
    }

    render() {
        let {page} = this.props;
        
        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.b2b.find(x => x.sys_name === 'legalInformationB2BRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container">
                <Helmet                    
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <h1 className="info-page-container__title">Правовая информация</h1>
                        {page ? <Fragment>
                            {page.content ?
                                <div className="legal-information-page__description"
                                     dangerouslySetInnerHTML={{__html: page.content}}/>
                                : null}
                            <div className="b-internet-ipad__tariffs-table legal-information-page__wrap-tabs">
                                {page.files && page.files.length && <DocumentsAbout data={page.files} />}
                            </div>
                        </Fragment> : null}
                    </div>
                    <div className="info-page-container__content-right-part"/>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        page: state.support.legalInformationB2BRoute,
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getPage: (regionId) => dispatch(getPage('legalInformationB2BRoute', regionId))
        }
    }
)(LegalInformation);

export const B2bLegalInformationRequests = [
    {
        func: 'getPage',
        args: ['legalInformationB2BRoute'],
        object: 'support.legalInformationB2BRoute',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    }
];