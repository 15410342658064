import React, { Component } from 'react';
import { mediaUrl } from "../../tools/config";
import LinkC from "../../../js/components/common/LinkC";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

class BanerAbout extends Component {
    render() {
        const { companyBaner } = this.props;

        return (
            <div className="about-page__wrap-work-in-motiv"
                style={{
                    backgroundImage: 'url('+mediaUrl + companyBaner.imagePath+')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}>
                    <AdvertisingLabeling
                        token={companyBaner && companyBaner.token}
                        advertiser={companyBaner && companyBaner.advertiser}
                    />
                <div className="page__container info-page-container">
                    <div className="about-page__work-in-motiv-content">
                        {!!companyBaner.title && companyBaner.title !== '' ? <h4 className="about-page__work-in-motiv-title">
                            {companyBaner.title}
                        </h4> : null}
                        {!!companyBaner.text && companyBaner.text !== '' ?
                            <div
                                className="about-page__work-in-motiv-description"
                                dangerouslySetInnerHTML={{__html: companyBaner.text}}
                                />
                        : null}
                        {!!companyBaner.link_title && companyBaner.link_title !== '' ?
                            <LinkC to={companyBaner.link} className="about-page__work-in-motiv-btn">
                                {companyBaner.link_title}
                            </LinkC>
                        : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default BanerAbout;