import React, {Component} from "react";
import {connect} from "react-redux";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3';
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import {phoneMask} from "../../tools/mask";
import {getTimezone, deleteQueryParam, hasQueryParam} from "../../tools/tools";
import {checkCallbackForm, sendCallbackForm, clearCallbackForm, checkSlots} from "../../actions/callback"
import {emptyValidator, checkedValidator, phoneValidator} from "../../tools/form-helper";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";
import PropTypes from "prop-types";

import Select from "../common/Select";
import Checkbox from "../common/CheckBox";

import Modal from "../common/Modal";
import SuccessForm from "../Forms/SuccessForm";
import {scroller} from 'react-scroll';

class CommonCallbackForm extends Component {

    constructor(props) {
        super(props);


        this.state = {
            captchaToken: '',
            siteKey: '',
            sending: false,
            captchaSuccessLoad: false,
            fields: {
                time: {
                    attribute: 'time',
                    placeholder: "",
                    value: "",
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Выберите время для звонка",
                    options: [],
                    getOptions: this.getOptions,
                    allowEmpty: true,
                    opened: false,
                    onBlur: () => {
                        this.setState((prevState) => {
                            return {
                                fields: {
                                    ...prevState.fields,
                                    time: {
                                        ...prevState.fields.time,
                                        opened: false
                                    }
                                }
                            }
                        })
                    }
                },
                phone: {
                    placeholder: "Номер телефона",
                    value: props.clientInfo.username ? phoneMask(props.clientInfo.username) : "",
                    mask: phoneMask,
                    validators: [emptyValidator, phoneValidator],
                    hasError: null,
                    textError: "Введите корректный номер телефона"
                },
                agreementCheckbox: {
                    value: 0,
                    validators: [checkedValidator],
                    name: 'agreementCheckbox',
                    label: '',
                    hasError: null,
                    textError: "Обязательно для заполнения"
                }
            }
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isB2b: PropTypes.bool
    };

    componentWillMount() {
        this.loadForm();
    }

    componentDidMount() {
        let queryUrl = hasQueryParam('anchor');

        // если есть квери параметр, то скролим до нужного блока
        if (queryUrl) {
            this.handleScroll('mnp-callback-form');

            setTimeout(() => {
                deleteQueryParam('anchor');
            }, 3000);
        }
    }

    componentDidUpdate(_, prevState) {
        if (this.state.siteKey && !prevState.siteKey) {
            try {
                this.createReCaptcha(this.state.siteKey);
            } catch (error) {
                console.error('Ошибка в загрузке ReCaptcha:', error);
                this.setState({ siteKey: null });
            }
        }
    }

    loadForm = () => {
        if (typeof location != "undefined") {
            // this.props.loadForm(location.origin + location.pathname);
            this.props.loadForm(location.origin + location.pathname, this.context.isB2b ? 'b2b' : '');
        }
    };

    createReCaptcha = (siteKey) => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
        script.async = true;
        script.defer = true;
        document.body.appendChild(script)
    }

    verifyCallback = (recaptchaToken) => {
        try {
            if (this.state.sending) {
                this.sendForm(recaptchaToken);
            }
            this.setState({captchaToken: recaptchaToken, sending: false});
        } catch (error) {
            console.error('ReCaptcha verify callback failed:', error);
            this.setState({sending: false}); // Сбрасываем флаг отправки
        }
    };



    componentWillReceiveProps(nextProps) {
        if (!this.props.data.isLoaded && nextProps.data.isLoaded) {
            let timeOptions = this.getTimeOptions(nextProps.data);
            this.setState((prevState) => {
                return {
                    ...prevState,
                    siteKey: nextProps.data.recaptchaSiteKey,
                    fields: {
                        ...prevState.fields,
                        phone: {
                            ...prevState.fields.phone,
                            disabled: !nextProps.data.formEnabled
                        },
                        time: {
                            ...prevState.fields.time,
                            placeholder: nextProps.data.immediatePlaceholder,
                            options: timeOptions,
                            disabled: !nextProps.data.formEnabled,
                            value: ''
                        },
                        agreementCheckbox: {
                            ...prevState.fields.agreementCheckbox,
                            label: nextProps.data.agreement
                        }
                    }
                }
            }, () => {

            })
        }
        if (!this.props.clientInfo.username && nextProps.clientInfo.username) {
            this.setState((prevState) => {
                return {
                    fields: {
                        ...prevState.fields,
                        phone: {
                            ...prevState.fields.phone,
                            value: phoneMask(nextProps.clientInfo.username)
                        }
                    }
                }
            })
        }
        if (!this.props.data.checkSlots && nextProps.data.checkSlots) {
            let timeOptions = this.getTimeOptions(nextProps.data);
            this.setState((prevState) => {
                return {
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        phone: {
                            ...prevState.fields.phone,
                            disabled: !nextProps.data.formEnabled
                        },
                        time: {
                            ...prevState.fields.time,
                            options: timeOptions,
                            opened: nextProps.data.formEnabled && nextProps.data.checkSlots,
                            disabled: !nextProps.data.formEnabled
                        }
                    }
                }
            })
        }
    }

    getTimeOptions = (data) => {
        let {
            immediateEnabled,
            immediateText,
            slots = []
        } = data;

        return immediateEnabled ? [{label: immediateText, value: 'now'}, ...slots] : slots;
    };

    getOptions = () => {
        let {
            configId,
            formEnabled
        } = this.props.data;
        if (configId && formEnabled) {
            this.props.checkSlots(configId);
        }
    };

    onFieldChange = (e) => {
        var fields = {...this.state.fields};

        if (fields.hasOwnProperty(e.target.name)) {
            var field = {...fields[e.target.name]};
            field.value = field.mask ? field.mask(e.target.value) : e.target.value;
            field.hasError = false;
            fields[e.target.name] = field;
            if (e.target.name === "time") {
                fields[e.target.name].opened = false;
            }
            this.setState({fields});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({sending: true});
        try {

            window.grecaptcha.execute();
        } catch (error) {
            console.error('ReCaptcha execution failed:', error);
            this.setState({ sending: false });
        }
    };

    isFormValid = (setErrors = false) => {
        let fields = {...this.state.fields};

        let valid = true;
        for (let attr in fields) {
            let field = fields[attr];
            if (field.hasOwnProperty('validators')) {
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                        if (setErrors) {
                            fields[attr].hasError = true;
                        }
                    }
                });
            }
        }

        if (setErrors) {
            this.setState({fields});
        }

        return valid;
    }

    sendForm = (recaptchaToken) => {
        const valid = this.isFormValid(true);

        if (valid) {
            let {
                time,
                phone,
                agreementCheckbox
            } = this.state.fields;

            let {
                configId,
                contextId,
                status
            } = this.props.data;

            if (status !== "progress") {
                let type = 'scheduled';
                if (time.value === 'now') {
                    type = 'immediate';
                }
                let data = new FormData();
                data.append('configId', configId);
                data.append('contextId', contextId);
                data.append('timezone', getTimezone());
                data.append('phone', phone.value.replace(/\D/gim, '').substr(1));
                data.append('captcha', recaptchaToken);

                if (type === 'scheduled') {
                    data.append('from', time.value);
                }
                this.props.sendForm(type, data);
                this.props.showLoading();
            }
        }
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    clearForm = (shouldClear) => {
        if (shouldClear) {
            this.loadForm();
            this.setState((prevState) => {
                return {
                    ...prevState,
                    fields: {
                        phone: {
                            ...prevState.fields.phone,
                            value: ''
                        },
                        time: {
                            ...prevState.fields.time,
                            value: prevState.fields.time.options.length ? prevState.fields.time.options[0].value : ''
                        },
                        agreementCheckbox: {
                            ...prevState.fields.agreementCheckbox,
                            value: 0
                        }
                    }
                }
            })
        }
        this.props.closeModal && this.props.closeModal();
        this.props.clearForm();
    };

    renderModal = () => {

        let {
            status,
            result
        } = this.props.data;

        let {
            time
        } = this.state.fields;

        switch (status) {
            case "success":
            case "error":
                this.props.hideLoading();
                let text = result.description;
                if (status === "success" && time.value !== 'now') {
                    text += "<br/>Ожидаемое время обратного звонка: " + time.options.find((item) => item.value == time.value).label
                }

                return <Modal close={() => {
                    this.clearForm(status === "success")
                }}>
                    <SuccessForm title={result.message} text={text} icon={status === "success"} linkButton={false}
                                 close={() => {
                                     this.clearForm(status === "success")
                                 }}/>
                </Modal>;

            default:
                return null;
        }
    };

    // якорь к нужному месtу
    handleScroll = (target) => {
        scroller.scrollTo(target, {
            duration: 500,
            delay: 1000,
            offset: -80,
            smooth: 'easeInOutQuart'
        });
    };

    render() {

        let {
            time,
            phone,
            agreementCheckbox
        } = this.state.fields;

        let {data, className, target, isGuest, flagIsB2b} = this.props;

        let {
            isLoaded,
            agreement,
            description_left,
            description_right,
            formEnabled,
            phoneNumber,
            phoneNumberB2B,
            recaptcha,
            title,
            status
        } = data;

        return <div id="mnp-callback-form" className={"page__callback-form" + (className ? ' ' + className : '')}>
            {isLoaded ? <div
                className={"b-callback-form b-callback-form_theme_mnp b-callback-form_adapt_mnp b-callback-form_adapt_mnp" + (target == "MNP" ? " primary" : '')}>
                <div className="b-callback-form__title">{title}</div>
                <div className="b-callback-form__body">
                    <div className="b-callback-form__text-holder">
                        <div className="b-callback-form__intro-text">{description_left}</div>
                        <div className="b-callback-form__phone-holder">
                            <div className="b-callback-form__phone-text">{description_right}</div>
                            {phoneNumber && phoneNumberB2B ? <div className="b-callback-form__phone">
                                <a className="b-callback-form__phone-link"
                                   href={`tel: ${!flagIsB2b ? phoneNumber.replace(/\D/gim, '') : phoneNumberB2B.replace(/\D/gim, '')}`}>
                                    {!flagIsB2b ? phoneNumber : phoneNumberB2B}
                                </a>
                            </div> : null}
                        </div>
                    </div>
                    <form onSubmit={this.onSubmit}>
                        <div className="b-callback-form__form-holder">
                            <div className="b-callback-form__form-item">
                                <div className="b-input-text b-input-text_adapt_mobile-small b-input-text_theme_mnp">
                                    <div className="b-input-text__wrapper">
                                        <div className="b-input-text__input-area">
                                            {phone.disabled ? null : <InputIcon error={phone.hasError}
                                                                                className={target === "MNP" ? 'b-input-text__input-icon-mpn' : ""}/>}
                                            <input
                                                className={"b-input-text__input" + (phone.disabled || status == "progress" ? " disabled" : "") + (target === "MNP" ? ' b-input-text__input-mpn' : "") + (phone.hasError ? ' has-error' : "")}
                                                type="text"
                                                placeholder={phone.placeholder}
                                                value={phone.value}
                                                name="phone"
                                                onChange={this.onFieldChange}
                                                onBlur={this.onBlur("phone")}
                                                onFocus={this.onFocus("phone")}
                                                autoComplete="off"
                                            />
                                            <InputError hasError={phone.hasError} errorText={phone.textError}
                                                        className={target === "MNP" ? 'b-input-text__input-error-mpn' : ""}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-callback-form__form-item">
                                <div className="b-input-text b-input-text_adapt_mobile-small b-input-text_theme_mnp">
                                    <div className="b-input-text__wrapper">
                                        <div className="b-input-text__input-area">
                                            <Select
                                                className={"b-input-text__input callback-form-select" + (time.hasError ? " has-error" : "")}
                                                {...time}
                                                disabled={time.disabled || status == "progress"}
                                                onChange={(name, value) => {
                                                    this.onFieldChange({target: {name, value}})
                                                }}
                                            />
                                            <InputError hasError={time.hasError} errorText={time.textError}
                                                        className={target === "MNP" ? 'b-input-text__input-error-mpn' : ""}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-callback-form__form-item b-callback-form__form-item_submit">
                                <button className={"u-btn u-btn_default u-btn u-btn_adapt_mobile-sm" +
                                    (formEnabled && status != "progress" && this.isFormValid() ? "" : " disabled")}
                                        type="submit"
                                >
                                    Отправить
                                </button>
                            </div>
                        </div>
                        {agreement ? <div className="agreement-block b-input-text__input-area">
                            <Checkbox {...agreementCheckbox} disabled={status == "progress"} onChange={(name, value) => {this.onFieldChange({target: {name, value}})}}/>
                            <InputError hasError={agreementCheckbox.hasError} errorText={agreementCheckbox.textError} className={target === "MNP" ? 'b-input-text__input-error-mpn' : ""}/>
                        </div> : null}
                        {recaptcha ? <div className="recaptcha-block">
                            {isGuest ? <div dangerouslySetInnerHTML={{__html: recaptcha}}/> : null}

                        </div> : ''}
                    </form>
                </div>
            </div> : ''}
            {this.renderModal()}
        </div>
    }

}

export default connect(
    state => ({
        data: state.callbackForm,
        clientInfo: state.client.clientInfo,
        isGuest: state.client.isGuest,
        flagIsB2b: state.company.flagIsB2b
    }),
    (dispatch) => {
        return {
            loadForm: (url, businessLine) => dispatch(checkCallbackForm(url, businessLine)),
            checkSlots: (configId) => dispatch(checkSlots(configId)),
            sendForm: (type, data) => dispatch(sendCallbackForm(type, data)),
            clearForm: () => dispatch(clearCallbackForm()),
            showLoading: () => dispatch(showLoading()),
            hideLoading: () => dispatch(hideLoading())
        }
    }
)(CommonCallbackForm)