import React, { Component } from "react";
import Modal from "../../common/Modal";

export default class InfoModalQR extends Component {

    render() {
        let {
            close = () => {},
            imageBase64 = '',
        } = this.props;

        return (
            <Modal
                className="u-modal u-modal_active u-modal-sm modal-info"
                close={close}
                innerClassName={'u-modal-inner info_modal_qr'}
            >
                <div className="info_modal_qr__content">
                    <div className="info_modal_qr__left_part">
                        <div className="info_modal_qr__wrap_qr">
                            <img src={`data:image/png;base64, ${imageBase64}`} />
                        </div>
                    </div>

                    <div className="info_modal_qr__right_part">
                        <div className="info_modal_qr__title">
                            Как платить через QR-код?
                        </div>

                        <div className="info_modal_qr__all_item_text">
                            <div className="info_modal_qr__tem_text">
                                1. Отсканируйте QR-код в мобильном приложении банка. Это можно сделать, нажав кнопку «Оплатить по QR-коду» или иконку QR-кода. 
                            </div>
                            <div className="info_modal_qr__tem_text">
                                2. Проверьте, верно ли указан номер мобильного телефона. Затем нажмите «Продолжить» и подтвердите платёж.
                            </div>
                            <div className="info_modal_qr__tem_text">
                                3. Дождитесь Смс-подтверждения успешного платежа. Обычно оно приходит сразу после оплаты, но в исключительных случаях может прийти в течение 24 часов.
                            </div>
                        </div>

                        <div className="info_modal_qr__wrap_btn">
                            <button
                                className="row-balance-btn"
                                onClick={close}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
