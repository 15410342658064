import React from "react";
import LinkC from "../common/LinkC";

export default function AlreadyInMotiv({title, text}){

    return  <div className="b-no-operator-form b-no-operator-form_adapt-default">
                <div className="b-no-operator-form__title">{title}</div>
                <div className="b-no-operator-form__text">{text}</div>
                <div className="b-no-operator-form__text-center">
                    <LinkC to="/" className="b-success-modal__btn">Вернуться на главную</LinkC>
                </div>
            </div>
}
