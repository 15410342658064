import React, {Component} from "react";

import {phoneMask} from "../../tools/mask";
import {getObjectData, emailValidator, phoneValidator, emptyValidator} from "../../tools/form-helper";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";
import RequiredText from "../common/form/RequiredText";

export default class MnpErrorForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            fields: {
                form_name: {
                    value: "error-form"
                },
                name: {
                    value: "",
                    required: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите Ваше имя"
                },
                callback_phone: {
                    value: "",
                    mask: phoneMask,
                    required: true,
                    validators: [phoneValidator],
                    hasError: null,
                    textError: "Укажите корректный номер телефона"
                },
                email: {
                    value: "",
                    required: true,
                    validators: [emailValidator],
                    hasError: null,
                    textError: "Укажите корректный email"
                }
            }
        }
    }

    onFieldChange = (e) => {
        let fields = {...this.state.fields};

        if (fields.hasOwnProperty(e.target.name)) {
            let field = {...fields[e.target.name]};
            field.value = field.mask ? field.mask(e.target.value) : e.target.value;
            fields[e.target.name] = field;
            this.setState({fields});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (this.isValid()) {
            let fields = Object.assign({}, this.state.fields);
            fields.callback_phone.value = fields.callback_phone.value.replace(/\D/gim, '');
            this.props.onSubmit(getObjectData({...fields}));
        }
    };

    isValid = () => {
        let fields = {...this.state.fields};

        for (let attr in fields) {
            if (fields[attr].error || (fields[attr].required && !fields[attr].value)) {
                return false;
            }
        }

        if (fields.callback_phone.value.replace(/\D/gim, '').length !== 11) {
            return false;
        }

        if (!emailValidator(fields.email.value)) {
            return false;
        }

        return true;
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                })
            }
        }
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    render(){

        let {
            operator_not_defined_description,
            operator_not_defined_title,
            children
        } = this.props;

        let {
            callback_phone,
            name,
            email
        } = this.state.fields;

        return <div className="page_no-operator">
            <div className="b-no-operator-form b-no-operator-form_adapt-default">
                <div className="b-no-operator-form__title">{operator_not_defined_title}</div>
                <div className="b-no-operator-form__text">{operator_not_defined_description}
                    <a onClick={this.props.goBack} href='#' className='b-no-operator-form__link'> введите другой номер</a>
                </div>
                <div className="b-no-operator-form__form">
                    <RequiredText />
                    <form onSubmit={this.onSubmit}>
                        <div className="b-no-operator-form__input">
                            <div className="b-input-text b-input-text_adapt_modal">
                                <div className="b-input-text__wrapper">
                                    <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                                        <div className="b-input-text__label-text">Как вас зовут{name.required ? <span className={"orange"}>*</span> : ''}</div>
                                    </div>
                                    <div className="b-input-text__input-area">
                                        <InputIcon error={name.hasError}/>
                                        <input className={"b-input-text__input" + (name.hasError ? ' has-error' : "")}
                                               type="text"
                                               value={name.value}
                                               name="name"
                                               onChange={this.onFieldChange}
                                               autoComplete="off"
                                               onBlur={this.onBlur('name')}
                                               onFocus={this.onFocus('name')}
                                        />
                                        <InputError hasError={name.hasError} errorText={name.textError}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-no-operator-form__row">
                            <div className="b-no-operator-form__col">
                                <div className="b-no-operator-form__input">
                                    <div className="b-input-text b-input-text_adapt_modal">
                                        <div className="b-input-text__wrapper">
                                            <div
                                                className="b-input-text__label b-input-text__label b-input-text__label_upper">
                                                <div className="b-input-text__label-text">Номер телефона{callback_phone.required ? <span className={"orange"}>*</span> : ''}</div>
                                            </div>
                                            <div className="b-input-text__input-area">
                                                <InputIcon error={callback_phone.hasError}/>
                                                <input className={"b-input-text__input" + (callback_phone.hasError ? ' has-error' : "")}
                                                       type="text"
                                                       value={callback_phone.value}
                                                       name="callback_phone"
                                                       placeholder="+7"
                                                       onChange={this.onFieldChange}
                                                       onBlur={this.onBlur('callback_phone')}
                                                       onFocus={this.onFocus('callback_phone')}
                                                />
                                                <InputError hasError={callback_phone.hasError} errorText={callback_phone.textError}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-no-operator-form__col">
                                <div className="b-no-operator-form__input">
                                    <div className="b-input-text b-input-text_adapt_modal">
                                        <div className="b-input-text__wrapper">
                                            <div
                                                className="b-input-text__label b-input-text__label b-input-text__label_upper">
                                                <div className="b-input-text__label-text">Ваш e–mail{email.required ? <span className={"orange"}>*</span> : ''}</div>
                                            </div>
                                            <div className="b-input-text__input-area">
                                                <InputIcon error={email.hasError}/>
                                                <input className={"b-input-text__input" + (email.hasError ? ' has-error' : "")}
                                                       type="text"
                                                       value={email.value}
                                                       name="email"
                                                       onChange={this.onFieldChange}
                                                       onBlur={this.onBlur('email')}
                                                       onFocus={this.onFocus('email')}
                                                />
                                                <InputError hasError={email.hasError} errorText={email.textError}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-no-operator-form__text-center">
                            <button className={"b-no-operator-form__btn" + (this.isValid() ? "" : " disabled")} type="submit">Отправить</button>
                        </div>
                    </form>
                </div>
            </div>
            {children}
        </div>;
    }
}