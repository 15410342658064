import React, { Component } from 'react';
import {mediaUrl} from "../../tools/config";

class RatesBigHeaderBanner extends Component {

    render() {

        let imagePath = this.props.imagePath;
        let children = this.props.children;

        return (
            <div id="header__banner" className="b-promo-intro__wrapper"
                 style={{backgroundImage : 'url('+mediaUrl + imagePath +')'}}>
                {children}
            </div>
        )
    }
}

export default RatesBigHeaderBanner;
