import React, {Component} from "react";

export default class Timer extends Component{

    constructor(props) {
        super(props);

        this.state = {
            seconds: props.seconds ? props.seconds : 60,
            isOn: false
        }
    }

    startTimer = () => {
        this.timer = setInterval(() => this.setState((prevState) => ({
            seconds: prevState.seconds - 1
        })), 1000);
        this.setState({isOn: true});
    };

    stopTimer = () => {
        if (typeof this.props.callbackFunc === 'function') {
            this.props.callbackFunc();
        }
        clearInterval(this.timer);
    };

    secondsToMinutesAndSeconds = (time) => {
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    render() {

        let {
            className = "",
            withMinutes = false
        } = this.props;

        let {
            seconds,
            isOn
        } = this.state;

        if (seconds === 0) {
            this.stopTimer();
        } else {
            if (!isOn) {
                this.startTimer();
            }
        }

        return <span className={(className ? className : '')}>{withMinutes ? this.secondsToMinutesAndSeconds(seconds) : seconds}</span>
    }
}