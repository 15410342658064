import {
    call,
    put
} from 'redux-saga/effects';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function faqList(regionId, categoryId, rankField = null, businessLine) {
    return {
        type: 'FAQ_LIST',
        regionId,
        categoryId,
        rankField,
        businessLine
    }
}

export function* fetchFaqList(action) {
    try {

        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/faq?regionId=" + action.regionId + (action.categoryId ? '&categoriesIds=' + action.categoryId : '')
                + (action.rankField ? '&categoryRankField=' + action.rankField : '')
                + (action.businessLine === 'b2b' ? '&is_b2b=1' : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}