import React, {Component} from "react";
import PropTypes from 'prop-types'
import smmIcons from '../../../images/content/rates/smm-icons.png';
import musicIcons from '../../../images/content/rates/music-services-icons.png';
import messengerIcons from '../../../images/content/rates/services-icons-sprite.png';
import {connect} from "react-redux";
import {remainsHistory, exchangeMins, exchangeMinsSend, remainsInfo} from "../../actions/client";
import Icon from "../common/Icon";
import LKModals from "../../containers/LK/LKModals";
import Tooltip from "../common/Tooltip";
import {mediaUrl} from '../../tools/config'


function prepareRemains(propsRemains, tariff, type = 'common', exchange = null, isTransferred = false){
    if (propsRemains || tariff.options || tariff.details) {
        var remains = type === 'common' ? [
            {
                title: 'интернет',
                type: 'DATA',
                rest_amount: 0,
                unit: 'Гб'
            },
            {
                title: 'звонки',
                type: 'VOICE',
                rest_amount: 0,
                unit: 'Мин'
            },
            {
                title: 'сообщения',
                type: 'SMS',
                rest_amount: 0,
                unit: 'Смс'
            }
        ] : [];

        if (propsRemains) {
            switch (type) {
                case 'data':
                case 'voice':
                case 'sms':
                    remains = [...propsRemains].filter((remain) => remain.type.toLowerCase() == type);
                    break;

                default:
                    propsRemains.map((remain) => {
                        switch (remain.type) {
                            case 'DATA':
                                remains[0] = {
                                    ...remain,
                                    wifi: remain.rest_amount >= 1
                                }
                                break;

                            case 'VOICE':
                                remains[1] = remain
                                break;
                                
                            case 'SMS':
                                remains[2] = remain
                                break;
                        }
                        return remain;
                    })
            }
        }

        let details = null;

        if (["data", "common"].indexOf(type) !== -1) {
            // if (tariff.internet_gb_count === "Безлимит") {
            //     remains.push({
            //         title: "Интернет",
            //         total_amount: "Безлимит",
            //         type: "DATA"
            //     })
            // }
            if (tariff.details && tariff.details.is_data_unlim) {
                details = {
                    title: "Интернет"+(type === 'common' && tariff.details.router ? " (+ режим роутера)" : ""),
                    total_amount: "Безлимит",
                    type: "DATA",
                    order: 1,
                    wifi: type === 'common' && tariff.details.router
                }
            }

            if (tariff.internet_mb_cost) {
                details = {
                    title: "Интернет",
                    rest_amount: tariff && tariff.internet_mb_cost,
                    type: "DATA",
                    unit: "₽",
                    period: "Мб",
                    order: 1
                }
            }

            if (details) {
                if (type === 'common') {
                    remains[0] = details;
                } else {
                    remains.push(details)
                }
            }
        }
        if (["voice", "common"].indexOf(type) !== -1) {
            // if (tariff.minutes_count === "Безлимит") {
            //     remains.push({
            //         title: "Звонки",
            //         total_amount: "Безлимит",
            //         type: "VOICE"
            //     })
            // }

            if (tariff.minute_cost) {
                details = {
                    title: "Звонки",
                    rest_amount: tariff.minute_cost,
                    type: "VOICE",
                    unit: "₽",
                    period: "мин",
                    order: 2
                }

                if (type === 'common') {
                    remains[1] = details;
                } else {
                    remains.push(details)
                }
            }
        }
        if (["sms", "common"].indexOf(type) !== -1) {
            // if (tariff.sms_count === "Безлимит") {
            //     remains.push({
            //         title: "Сообщения",
            //         total_amount: "Безлимит",
            //         type: "SMS"
            //     })
            // }

            if (tariff.sms_cost) {
                details = {
                    title: "Сообщения",
                    rest_amount: tariff.sms_cost,
                    type: "SMS",
                    unit: "₽",
                    period: "sms",
                    order: 3
                }

                if (type === 'common') {
                    remains[2] = details;
                } else {
                    remains.push(details)
                }
            }
        }
        //
        // if (type === "common") {
        //     remains.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);})
        // }

        if (tariff.options && tariff.options.length) {
            tariff.options.map((option) => {
                if (option.type.toLowerCase() === type && option.title !== 'Интернет') {
                    remains.push({
                        ...option,
                        type: option.id,
                        ...(option.moved_amount ? option : {total_amount: "Безлимит"})
                    })
                }
            })
        }

        // if (type == "data") {
        //     if (tariff.internet_social_unlimit) {
        //         remains.push({
        //             title: "Соцсети",
        //             total_amount: "Безлимит",
        //             type: "social"
        //         })
        //     }
        //     if (tariff.internet_music_unlimit) {
        //         remains.push({
        //             title: "Музыка",
        //             total_amount: "Безлимит",
        //             type: "music"
        //         })
        //     }
        //     if (tariff.internet_messenger_unlimit) {
        //         remains.push({
        //             title: "Мессенджеры",
        //             total_amount: "Безлимит",
        //             type: "messenger"
        //         })
        //     }
        //
        //
        //     if (exchange && exchange.is_available && exchange.max_minutes > 0) {
        //         remains.push({
        //             title: "Обменные минуты на гигабайты",
        //             subtitle: "действуют до",
        //             rest_amount: exchange.max_minutes,
        //             total_amount: exchange.max_minutes,
        //             type: "exchange",
        //             unit: "мин"
        //         })
        //     }
        // }

        return remains;
    }

    return [];
}

class MyTariffReminder extends Component{

    constructor(props, context){
        super(props, context);
        this.state = {
            modal: null,
            collapsed: false,
            remains: prepareRemains(props.remains, props.tariff),
            dataRemains: prepareRemains(props.remains, props.tariff, "data", props.exchange),
            voiceRemains: prepareRemains(props.remains, props.tariff, "voice"),
            smsRemains: prepareRemains(props.remains, props.tariff, "sms"),
            dataTransferredRemains: prepareRemains(props.remainsHistory, {}, "data", null,true),
            voiceTransferredRemains: prepareRemains(props.remainsHistory, {}, "voice", null,true),
            smsTransferredRemains: prepareRemains(props.remainsHistory, {}, "sms", null,true),
        };

        if (!props.remainsHistory || !props.remainsHistory.length) {
            props.getRemainsHistory()
        }

        if (!props.exchange) {
            props.getExchangeMins(context.selectedRegion.id)
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    static getDerivedStateFromProps(props, state){
        if (props.client.exchangeMinsSuccess) {
            return {...state, modal: "exchange-success"}
        }
        if (props.client.exchangeMinsError) {
            return {...state, modal: "exchange-error"}
        }

        let remains = prepareRemains(props.remains, props.tariff);
        if (JSON.stringify(state.remains) !== JSON.stringify(remains)) {
            return {
                ...state,
                remains,
                dataRemains: prepareRemains(props.remains, props.tariff, "data"),
                voiceRemains: prepareRemains(props.remains, props.tariff, "voice"),
                smsRemains: prepareRemains(props.remains, props.tariff, "sms")
            }
        }

        let remainsHistory = prepareRemains(props.remainsHistory, props.tariff);
        if (JSON.stringify(state.remainsHistory) !== JSON.stringify(remainsHistory)) {
            return {
                ...state,
                remainsHistory,
                dataRemains: prepareRemains(props.remains, props.tariff, "data"),
                dataTransferredRemains: prepareRemains(props.remainsHistory, {}, "data", null,true),
                voiceTransferredRemains: prepareRemains(props.remainsHistory, {}, "voice", null,true),
                smsTransferredRemains: prepareRemains(props.remainsHistory, {}, "sms", null,true)
            }
        }
        return state;
    }

    toggleCollapsed = () => {
        this.setState({collapsed: !this.state.collapsed});
    };

    renderRemain = (remain) => {
        let countTitle = "";
        let icon;

        switch (remain.type) {
            case "DATA":
                countTitle = "Плата за Мб";
                break;

            case "VOICE":
                countTitle = "Поминутная тарификация";
                if (this.props.exchange && this.props.exchange.is_available && this.props.exchange.allowForTariff) {
                    icon =  <span onClick={this.showModal('exchange')}>
                                <Icon className="img-icon u-icon_spir" name="icon_spir"/>
                            </span>;
                }
                break;

            case "SMS":
                countTitle = "Поштучная тарификация";
                break;
        }
        
        return <div className="remainder-item row" key={remain.type}>
            <div className="remainder-item-elem">
                <div className="remainder-name">{remain.title}</div>
                {remain.total_amount === "Безлимит"
                ?   <div className="remainder__price">
                        <div className="remainder__price-num">
                            <span className="img-icon icon-infinity-black"/>
                            {remain.wifi ? <span className="img-icon icon-wifi icon-wifi-black"/> : null}
                        </div>
                    </div> 
                :   <div className="remainder__price">
                        <div className="remainder__price-num">{remain.rest_amount}</div>
                        <div className="remainder__measure">
                            <div className="remainder__measure-cur">
                                {remain.unit}
                                {remain.wifi ? <span className="img-icon icon-wifi icon-wifi-black"/> : null}
                            </div>
                            {remain.period ? <div className="remainder__period"> /{remain.period}</div> : null}
                        </div>
                    </div>
                }
            </div>
            {icon ? <div className="remainder-item-elem">{icon}</div> : null}
            <div className="remainder-item-elem">
                {remain.period 
                ?   <p>{countTitle}</p> 
                :   (remain.total_amount ? <div className="remainder-semicircle-element">
                        <Semicircle stroke="#f37021"
                                percent={remain.total_amount === "Безлимит" ? 100 : Math.min(remain.rest_amount*100/remain.total_amount, 100)}
                                content={remain.total_amount === "Безлимит" ? "Безлимит" : <span>из&nbsp;{remain.total_amount}<br/>{remain.unit}</span>}
                        />
                        <Circle stroke="#f37021"
                                percent={remain.total_amount === "Безлимит" ? 100 : Math.min(remain.rest_amount*100/remain.total_amount, 100)}
                                radius={20}
                        />
                    </div> : null)
                }
            </div>
        </div>
    };

    renderLineRemain = (remain, color = "#f37021") => {
        let icon = null;

        // убираем нужную услугу из видимости
        if (remain.id !== 1994) {
            if (remain.icon_path) {
                icon = <img src={mediaUrl+remain.icon_path} className="line-reminder-icon filter-rates__img" />;
            }

            let tooltip = null;

            if (remain.tooltip) {
                tooltip = <Tooltip  id={"tooltip-" + remain.id}
                                    label={<span className="img-icon icon-info"/>}
                                    openedLabel={<span className="img-icon icon-info-select"/>}
                                    className="numbers-tooltip"
                >
                    {remain.tooltip}
                </Tooltip>
            }

            if (this.props.tooltip && remain.total_amount !== "Безлимит" && remain.rest_amount > remain.total_amount) {
                tooltip = <Tooltip  id={"tooltip-" + remain.id}
                                    label={<span className="img-icon icon-info"/>}
                                    openedLabel={<span className="img-icon icon-info-select"/>}
                                    className="numbers-tooltip"
                >
                    {this.props.tooltip}
                </Tooltip>
            }

            return <Line 
                stroke={color}
                infoOnly={remain.total_amount === undefined}
                key={remain.type}
                date={remain.date}
                moved={remain.moved_amount}
                title={remain.title}
                subtitle={remain.subtitle}
                icon={icon}
                tooltip={tooltip}
                percent={!remain.total_amount || remain.total_amount === "Безлимит" ? 100 : remain.rest_amount*100/remain.total_amount}
                reminder={remain.total_amount === "Безлимит" ? "Безлимит" : <span>{(remain.rest_amount ? remain.rest_amount : remain.moved_amount)}&nbsp;{remain.unit}</span>}
                total={remain.total_amount === "Безлимит" ? <span className="img-icon icon-infinity"/> : (remain.total_amount ? <span>из&nbsp;{remain.total_amount}&nbsp;{remain.unit}</span> : "")}
            />
        }

        return null
    };

    renderPackageRemain = (remain) => {
        return <div className="reminder__block__row">
            <div className="reminder__block__row__title">
                {remain.service ? remain.service.title : null}
            </div>
            {/*<div>*/}
                {/*<div className="text_sm text_light">Срок действия</div>*/}
                {/*<div>-</div>*/}
            {/*</div>*/}
            <div>
                <div className="text_sm text_light">Дата окончания</div>
                <div>{(new Date(remain.date)).toLocaleDateString('ru')}</div>
            </div>
            <div>
                <div className="text_sm text_light">Осталось</div>
                <div>{remain.rest_amount} {remain.unit}</div>
            </div>
            <Circle stroke="#f37021" percent={remain.rest_amount*100/remain.total_amount} />
        </div>
    };

    showModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();

            this.setState({modal})
        }
    };

    hideModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: null})
    };

    render(){

        let collapsed = this.state.collapsed;

        let {
            remains,
            dataRemains,
            voiceRemains,
            smsRemains,
            dataTransferredRemains,
            voiceTransferredRemains,
            smsTransferredRemains
        } = this.state;

        let {
            packageRemains = [],
            tariff
        } = this.props;

        return <div>
            <div className="remainder row">
                {remains ? remains.map((remain) => this.renderRemain(remain)) : null}
            </div>
            {collapsed ? (tariff.options && tariff.options.length || dataTransferredRemains.length > 0 || voiceTransferredRemains.length > 0 || smsTransferredRemains.length > 0 || packageRemains.length > 0 ? <div className="remainder-item-btn row">
                <button className="btn btn--transparent-b-o u-btn u-btn_transparent u-btn_adapt_default u-btn_mobile-responsive" onClick={this.toggleCollapsed}>
                    Все остатки по тарифу
                </button>
            </div> : null) : <div>
                {tariff.options && tariff.options.length ? <div className="reminder__block">
                    <div className="reminder__block__title text_xl">Доступно по условиям тарифа</div>
                    <div className="reminder__block__body">
                        <div className="reminder__block__col">
                            {dataRemains.map((remain) => this.renderLineRemain(remain))}
                        </div>
                        <div className="reminder__block__col">
                            {voiceRemains.map((remain) => this.renderLineRemain(remain))}
                        </div>
                        <div className="reminder__block__col">
                            {smsRemains.map((remain) => this.renderLineRemain(remain))}
                        </div>
                    </div>
                </div> : null}

                {dataTransferredRemains.length > 0 || voiceTransferredRemains.length > 0|| smsTransferredRemains.length > 0 ? <div className="reminder__block">
                    <div className="reminder__block__title text_xl">Перенесенные остатки</div>
                    <div className="reminder__block__body">
                        <div className="reminder__block__col">
                            {dataTransferredRemains.map((remain) => this.renderLineRemain(remain, "#449dd1"))}
                        </div>
                        <div className="reminder__block__col">
                            {voiceTransferredRemains.map((remain) => this.renderLineRemain(remain, "#449dd1"))}
                        </div>
                        <div className="reminder__block__col">
                            {smsTransferredRemains.map((remain) => this.renderLineRemain(remain, "#449dd1"))}
                        </div>
                    </div>
                </div> : null}

                {packageRemains.length 
                ?   packageRemains.map((remain, key) => 
                        <div className="reminder__block" key={key}>
                            {key === 0 ? <div className="reminder__block__title text_xl">Остатки по пакетным услугам</div> : null}
                            {this.renderPackageRemain(remain)}
                        </div>) 
                :   null}

                {!this.props.tariff.internet_mb_cost && !this.props.tariff.minute_cost && !this.props.tariff.sms_cost &&
                    (<div className="reminder__block__close" onClick={this.toggleCollapsed}>Скрыть все параметры<span>&times;</span></div>)
                }
            </div>}
            <LKModals modal={this.state.modal} close={this.hideModal}/>
        </div>
    }
}

export default connect(
    state => ({
        client: state.client,
        remains: state.client.clientInfo.remains,
        exchange: state.client.clientInfo.exchange,
        remainsHistory: state.client.clientInfo.remainsHistory,
        packageRemains: state.client.clientInfo.packageRemains,
        tooltip: state.client.settings && state.client.settings.remain_comment
    }),
    dispatch => {
        return {
            getRemainsHistory: () => dispatch(remainsHistory()),
            getExchangeMins: (regionId) => dispatch(exchangeMins(regionId)),
            getRemainsInfo: () => dispatch(remainsInfo())
        }
    }
)(MyTariffReminder);

export function Semicircle({stroke = "#000", percent, content}){
    return <div className="semicircle">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle className="back-circle" r="46" cy="50" cx="50" fill="none" stroke="#C8C8C8" strokeWidth="4"/>
            <circle className="front-circle" r="46" cy="50" cx="50" stroke={stroke} fill="none" strokeWidth="4"
                    strokeLinecap="round" strokeDashoffset={-1.45*percent} strokeDasharray="145"
                    style={{transform: "rotate(-90deg);", transformOrigin: "center;"}}/>
        </svg>
        <div className="content">{content}</div>
    </div>
}

export function Circle({stroke = "#000", percent, radius = 20, total}){
    let l = 2*3.14*(radius - 4);
    return <div className="circle" style={{width: 2*radius+"px", height: 2*radius+"px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 "+2*radius+" "+2*radius}>
            <circle className="back-circle" r={radius - 4} cy={radius} cx={radius} fill="none" stroke="#C8C8C8" strokeWidth="4" strokeDasharray={l}/>
            <circle className="front-circle" r={radius - 4} cy={radius} cx={radius} stroke={stroke} fill="none" strokeWidth="4"
                    strokeLinecap="round" strokeDashoffset={(100 - percent)*l/100} strokeDasharray={l}
                    style={{transform: "rotate(-90deg);", transformOrigin: "center;"}}/>
        </svg>
        <div className="total" style={{marginTop: "-"+(radius+2)+"px"}}>{total}</div>
    </div>
}

export function Line({stroke = "#000", percent, title, subtitle = null, icon = null, reminder, total, infoOnly = false, tooltip, date, moved}){

    let finalDate = (new Date(date)).toLocaleDateString('ru')

    return <div className={"line-reminder"+(infoOnly ? " no-line" : "")+(subtitle ? " with-subtitle" : "")}>
        <div className="title">
            <div className="flex-between">
                <span className="flex-between">
                    {title}
                    {tooltip}
                </span>
                {icon}
            </div>
            {subtitle ? <div className="text_light">{subtitle}</div> : null}
        </div>
        {infoOnly ? null : <div className="line"><div className="accent" style={{borderColor: stroke, width: percent+"%"}}/></div>}

        <div className={`flex-between ${moved > 0 && !subtitle ? "flex-between-second" : ""}`}>
            {reminder}
            <span className="text_light">{total}</span>
        </div>

        {moved > 0 && !subtitle &&
            (<div className="text_light end">действуют до {finalDate}</div>)
        }
    </div>
}