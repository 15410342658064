import React, { Component } from 'react';
import {Link} from "react-router-dom";


import PropTypes from "prop-types";

class RatesLinks extends Component {

    static contextTypes = {
        fetchBefore: PropTypes.func,
    };

    render() {

        let fetchBefore = this.context.fetchBefore;
        let url = this.props.url;
        let title = this.props.title;

        return (
            <React.Fragment>
                <div className="text-center rates-area-links flexCol">
                    <Link to={url} onClick={fetchBefore(url)} className="b-tariffs-list__archive-link">{title}</Link>
                </div>
            </React.Fragment>
        )
    }
}

export default RatesLinks;