import React, {Component} from "react";
import PropTypes from "prop-types";

import Icon from "../common/Icon";
import HomePageTariff from "../Rates/HomePageTariff";
import RatesByCategories from "../Rates/RatesByCategories";
import Tooltip from "../common/Tooltip";

export default class ChooseTariff extends Component{

    constructor(props){
        super(props);
    }

    static contextTypes = {
        fields: PropTypes.object,
        showModal: PropTypes.func,
        step: PropTypes.number,
        goToStep: PropTypes.func,
        selectedRegion:  PropTypes.object,
    };

    static childContextTypes = {
        chooseTariff: PropTypes.func
    };

    chooseTariff = (tariff) => {
        this.props.onSubmit({tariff, form_name: this.context.fields.getting_type_alias == "delivery" ? "Шаг 5.2" : "Шаг 5.1"});
    };

    getChildContext(){
        return {
            chooseTariff: this.chooseTariff
        }
    }
    
    render(){

        let {
            tariffs,
            tariff_selection_button_hint,
            tariff_selection_button_text,
            tariff_selection_button_visible,
            children,
            typeDelivery
        } = this.props;

        let step = 4;

        if (typeDelivery === 'delivery') {
            step = 3;
        }

        let popularTariffs = tariffs.filter(x => x.region_id === this.context.selectedRegion.id && x.rank_popular !== null).slice(0, 4);
        popularTariffs = popularTariffs.sort(function (t1, t2) {
            return t2.rank_popular - t1.rank_popular;
        });

        return <div>
            <div className="page__head-content">
                <div className="page__container">
                    <div className="page__head-block">
                        <div className="b-head-content b-head-content_adapt_default">
                            <div className="b-head-content__wrapper">
                                <div className="b-head-content__left-side">
                                    <div className="b-head-content__breadcrumbs">
                                        <div className="b-breadcrumbs b-breadcrumbs_theme_grey">
                                            <ul className="b-breadcrumbs__list">
                                                <li className="b-breadcrumbs__item">
                                                    <a className="b-breadcrumbs__link" href="#" onClick={(e) => {e.preventDefault(); this.context.goToStep(0)}}>Перейти в МОТИВ со своим номером</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="b-head-content__title">Выберите тариф</div>
                                </div>
                                {  tariff_selection_button_visible
                                    ? <div className="b-head-content__right-side">
                                        <div className="b-head-content__call-block">
                                            <a id={"mnp-tariff-by-call-button"} className="b-head-content__call-item" href="#" onClick={this.context.showModal("call")}>
                                                <Icon name="icon_support2" className="b-head-content__call-icon u-icon u-icon_support2"/>
                                                <span className="b-head-content__call-text">{tariff_selection_button_text}</span>
                                            </a>
                                            <Tooltip id={"phone-data"}
                                                     label={<span className="img-icon icon-info"/>}
                                                     openedLabel={<span className="img-icon icon-info-select"/>}
                                                     className="numbers-tooltip">
                                                <p>{tariff_selection_button_hint}</p>
                                            </Tooltip>
                                        </div>
                                      </div>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    {popularTariffs.length ? <div className="page__tariffs-label">Популярные тарифы</div> : null}
                    {popularTariffs.length ? <div className="page__tariff-plates">
                        <div className="b-tariff-plates b-tariff-plates_adapt_mnp b-tariff-plates_theme_mnp js-tarif-plates">
                            <div className="b-tariff-plates__wrapper">
                                <div className="b-tariff-plates__plates">
                                    {popularTariffs.map(
                                        (tariff) => <HomePageTariff tariff={tariff}
                                                                    key={"home-page-tariff-"+tariff.id}
                                                                    buttonText="Выбрать тариф"
                                                                    onClick={(e) => {e.preventDefault(); this.chooseTariff(tariff.name)}}
                                                                    selectedRegion={this.context.selectedRegion}
                                        />)
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
            </div>
            <div className="page__container">
                <RatesByCategories tariffs={tariffs} isChooseItem={true}/>
                <a className="page__back-btn u-btn u-btn u-btn_lg-responsive u-btn u-btn_transparent" href="#" onClick={(e) => {e.preventDefault(); this.context.goToStep(step)}}>Назад</a>
            </div>
            {children}
        </div>
    }
}
