import React, { Component } from 'react';
import TabSwitchItem from "./TabSwitchItem";

class TabsSwitcher extends Component {
    getNext (activeTab, items) {
        
        if (activeTab === (items.length - 1)) {
            return 0;
        }
        return activeTab + 1;
    };

    getPrev (activeTab, items) {
        
        if (activeTab === 0) {
            return items.length - 1;
        }
        return activeTab - 1;
    };

    render() {

        let {
            activeTab,
            onClickFunc,
            items,
            children,
            itemHasWrap,
            className,
            closeCard
        } = this.props;

        let next = this.getNext(activeTab, items);
        let prev = this.getPrev(activeTab, items);

        return (
            <div className={"b-tabs-header b-tabs-header_adapt_default" + (className ? " " + className : "")}>
                <div className="b-tabs-header__wrapper">
                    <div className="b-tabs-header__items-list" onClick={closeCard}>
                        <div className="b-tabs-header__nav-btn b-tabs-header__nav-btn_prev" onClick={onClickFunc(prev)} />
                        {items.map((item, key) =>
                            <TabSwitchItem key={key} idx={item.id ? item.id : key} item={item} activeTab={activeTab} onClickFunc={onClickFunc} hasWrap={itemHasWrap} />
                        )}
                        {children}
                        <div className="b-tabs-header__nav-btn b-tabs-header__nav-btn_next" onClick={onClickFunc(next)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default TabsSwitcher;