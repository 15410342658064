import React, { Component } from "react";
import Icon from "../Icon";
import {
    VKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class ShareForPayments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            showTooltip: false,
        };

        this.advertisingRef = React.createRef();
    }

    componentDidMount() {
        // для закрытия выпадашки по клику в не ее области
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    
    componentDidUpdate(prevState) {
        // скрываем тултип (КОПИРОВАТЬ В БЕФЕР ОБМЕНА) через 2 сек после его появления
        if (this.state.showTooltip !== prevState.showTooltip && !!this.state.showTooltip) {
            setTimeout(() => {
                this.setState({ showTooltip: false });
            }, 2000);
        }
    }

    // убираем выпадашку по клику вне его области "пицеман"
    handleClickOutside = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        // includes --- ищет указанный элемет в массиве, возвращает true или false
        if (!path.includes(this.advertisingRef.current)) {
            this.setState({
                toggle: false,
            });
        }
    };

    // открывает/закрывает выпадашку
    toggleDrop = () => {
        let { onOpenShare, disable = false  } = this.props;
        if (disable) return null;

        this.setState({ toggle: true });
        onOpenShare();
    }

    render() {
        let { toggle } = this.state;
        let { 
            data,
            disable = false,
            phone = '',
            sum = '',
        } = this.props;
        const isDisable = disable ? 'disable' : '';

        let shareUrl = typeof window === 'undefined' ? "#" : data && data.form_url;
        const textForShare = `Привет! Пополни, пожалуйста, баланс моего номера ${phone} на ${sum} руб. по ссылке:`;

        return (
            <div
                className="share_for_payments"
                ref={this.advertisingRef}
            >
                <div
                    className={`share_for_payments__btn ${isDisable}`}
                    onClick={this.toggleDrop}
                >
                    <div className={`share_for_payments__btn_text ${isDisable}`}>Поделиться</div>
                    <div className="share_for_payments__btn_icon">
                        <Icon
                            name="icon_semicircular_arrow"
                            className={`u-icon_semicircular_arrow ${isDisable}`}
                        />
                    </div>
                </div>

                <div className={`share_for_payments__drop ${(toggle && (data && data.form_url)) ? ' active' : ''}`}>
                    <div className="share_for_payments__drop_item">
                        <VKShareButton url={shareUrl} title={textForShare} className="share_for_payments__drop_item_btn">
                            <Icon
                                name="icon_share_vk"
                                className={`u-icon_share_for_payments`}
                            />
                        </VKShareButton>
                    </div>
                    <div className="share_for_payments__drop_item">
                        <TelegramShareButton url={shareUrl} title={textForShare} className="share_for_payments__drop_item_btn">
                            <Icon
                                name="icon_share_tg"
                                className={`u-icon_share_for_payments`}
                            />
                        </TelegramShareButton>
                    </div>
                    <div className="share_for_payments__drop_item">
                        <WhatsappShareButton url={shareUrl} title={textForShare} className="share_for_payments__drop_item_btn">
                            <Icon
                                name="icon_share_wa"
                                className={`u-icon_share_for_payments`}
                            />
                        </WhatsappShareButton>
                    </div>
                    <div className="share_for_payments__drop_item">
                        {this.state.showTooltip && <span className="share_for_payments__tooltip">Ссылка&nbsp;скопирована</span>}
                        <CopyToClipboard
                            text={data && data.form_url}
                            onCopy={() => this.setState({ showTooltip: true })}
                        >
                            <div>
                                <Icon
                                    name="icon_share_cory"
                                    className={`u-icon_share_for_payments`}
                                />
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        )
    }
}