import {
    call,
    put
} from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function tariffsList(regionId, categoryRankField = null, tariffRankField = null, businessLine = 'b2c') {
    return {
        type: 'TARIFF_LIST',
        regionId,
        categoryRankField,
        tariffRankField,
        businessLine
    }
}

export function* fetchTariffsList(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariffs?regionId=" + action.regionId
                + (action.categoryRankField ? '&categoryRankField=' + action.categoryRankField : '')
                + (action.tariffRankField ? '&tariffRankField=' + action.tariffRankField : '')
                + (action.businessLine ? '&businessLine=' + action.businessLine : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function tariffArchiveList() {
    return {
        type: 'TARIFF_LIST_ARCHIVE',
    }
}

export function* fetchTariffArchiveList(action) {
    try {

        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariffs-archive", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function tariffIndividualList() {
    return {
        type: 'TARIFF_LIST_INDIVIDUAL',
    }
}

export function* fetchTariffIndividualList(action) {
    try {

        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariffs-individual", {
                method: 'GET',
            })
        });
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function tariffFilterSettings() {
    return {
        type: 'TARIFF_FILTER_SETTINGS'
    }
}

export function* fetchTariffFilterSettings(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariffs/filter-settings", {
                method: 'GET',
            })
        });

        if (error) alert(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function tariffSelectionSettings(regionId) {
    return {
        type: 'TARIFF_SELECTION_SETTINGS',
        regionId
    }
}

export function* fetchTariffSelectionSettings(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariffs/selection-settings?regionId="+action.regionId, {
                method: 'GET',
            })
        });

        if (error) alert(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function getTariffsSupportList(tariffType, term = '', page = 1, category_id, per_page = 20) {
    return {
        type: 'GET_TARIFFS_SUPPORTS_LIST',
        tariffType,
        term,
        page,
        category_id,
        per_page
    }
}

export function* fetchGetTariffsSupportList(action) {
    try {

        let queryParams = '?page=' + action.page + '&term=' + action.term + (action.category_id ? ('&categoryId=' + action.category_id) : '') + '&perPage=' + action.per_page;

        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi(`/support-tariffs/list/${action.tariffType}${queryParams}`, {
                method: 'GET',
            })
        });

        if (error) alert(error);
        else {
            yield put(checkErrors(action.type, {data, pagination}, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function getServiceCategories() {
    return {
        type: 'GET_SERVICE_CATEGORIES',
    }
}

export function* fetchGetServiceCategories(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/support-tariffs/service-categories`, {
                method: 'GET',
            })
        });

        if (error) alert(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}