export function getRegionFromLocation (location) {
    let result = location.split('/');

    return result[1];
}

export function getPathWithoutRegion (location) {
    let result = location.split('/');
    result.splice(0, 2);

    return result.join('/');
}

export function getQueryParams (search) {
    let searchArr = search.substr(1).split('&');
    let params = {};
    for (var i = 0; i < searchArr.length; i++) {
        let paramArr = searchArr[i].split('=');
        if (paramArr.length > 1) {
            params[paramArr[0]] = decodeURIComponent(paramArr[1]);
        } else if (paramArr.length > 0) {
            params[paramArr[0]] = true;
        }
    }
    return params;
}

// подсвечивает пункты меню в зависимости от того на каком урле находимся
export const highlightUrl = (curentPath) => {
    const url = (
        curentPath === 'about' ||
        curentPath === 'values' ||
        curentPath === 'leadership' ||
        curentPath === 'legal-information' ||
        curentPath === 'support' ||
        curentPath === 'fast-decisions' ||
        curentPath === 'bezopasnost' ||
        curentPath === 'motiv-on-map' ||
        curentPath === 'complain' ||
        curentPath === 'terms-of-service' ||
        curentPath === 'vakansii' ||
        curentPath === 'career-in-motiv' ||
        curentPath === 'press-center' ||
        curentPath === 'news' ||
        curentPath === 'media-bank' ||
        curentPath === 'form-style' ||
        curentPath === 'contacts' ||
        curentPath === 'for-subscribers' ||
        curentPath === 'for-press' ||
        curentPath === 'for-partners' ||
        curentPath === 'for-buisness');
    return url;
}
