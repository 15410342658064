import React, {Component} from 'react';
import PropTypes from "prop-types";

class RatesBreadcrumbs extends Component {

    constructor(props) {

        super(props);
    }

    static contextTypes = {
        fetchBefore: PropTypes.func,
    };

    render () {

        let items = this.props.items;
        let fetchBefore = this.context.fetchBefore;

        return (
            <div className="b-inner-intro__breadcrumbs">
                <div className="b-breadcrumbs b-breadcrumbs b-breadcrumbs_theme_grey">
                    <ul className="b-breadcrumbs__list" style={this.props.color ? {color: this.props.color} : {}}>
                        {items.map((item ,key) =>
                            <li className="b-breadcrumbs__item" key={key} data-color={this.props.color ? this.props.color : '#707478'}>
                                {   item.url
                                    ?   item.state
                                        ? <a href={item.url} className="b-breadcrumbs__link" onClick={fetchBefore(item.url, null, item.state)}>{item.title}</a>
                                        : <a href={item.url} className="b-breadcrumbs__link" onClick={fetchBefore(item.url)}>{item.title}</a>
                                    :   item.title
                                }
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }

}

export default RatesBreadcrumbs;