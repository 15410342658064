import React, { Component } from 'react';
import Icon from "../common/Icon";
import PropTypes from "prop-types";

class   AsideMenuItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    componentWillMount() {
        let flag = this.props.flag;
        const sysName = this.props.item.sys_name;

        if ( flag === sysName ){
            this.setState({
                show: true
            });
        }
    }

    // по клику на стрелку возможно открыть одновременно несколько категорий
    handlerMenuItem = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            return {
                show: !prevState.show
            }
        })
    };

    isActive = (href, path) => {
        if(path && href) {
            let sectionPath = path.split('/').slice(-1)[0];
            let sectionNavItem = href.split('/').slice(-1)[0];

            if (sectionPath === sectionNavItem) {
                return true;
            }
        }

        return false;
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        locationPath: PropTypes.string
    };

    render () {
        let path = this.context.locationPath;

        const { item, flagSubMenu, isActive } = this.props;
        let menu = item.menu ? item.menu.filter((tmp) => !!tmp.link) : []
        const { show } = this.state;
        const selectedRegion = this.context.selectedRegion.alias;
        let fetchBefore = this.context.fetchBefore;
        return (
            <div className={`b-aside-menu__group ${ menu && menu.length > 0 ? "b-aside-menu__group_with-children" : "" } ${ show && menu && menu.length > 0 ? "b-aside-menu__group_open" : "" } ${this.isActive(item.link, path) ? 'b-aside-menu__group-active' : ''}`}>
                {!!item.link ?
                    <div 
                        className="b-aside-menu-item"
                        onClick={this.handlerMenuItem}
                    >
                        <a 
                            href={'/'+selectedRegion + item.link} 
                            className="b-aside-menu__link"
                            onClick={fetchBefore('/' + selectedRegion + item.link)}
                        >
                            {item.name}
                        </a>

                        {(menu && menu.length > 0 && !!item.link)
                            ?
                            <Icon name="icon_caret"
                                className={`b-aside-menu__caret b-aside-menu__caret-mobile ${ show ? "b-aside-menu__caret_active" : "" } u-icon u-icon_caret`}
                            />
                            : null
                        }
                    </div>
                : null}

                {(menu && menu.length > 0 && show)
                    ?
                    <div className="b-aside-menu__dropdown">
                        {menu.map((itemMenu, index) =>
                            itemMenu.link ?
                                <a key={index} href={'/'+selectedRegion + itemMenu.link}
                                    className={"b-aside-menu__dropdown-link" + (itemMenu.sys_name === flagSubMenu ? " b-aside-menu__dropdown-link-active" : "")}
                                    onClick={fetchBefore('/'+selectedRegion + itemMenu.link)}>{itemMenu.name}
                                </a>
                                : null
                        )}
                    </div>
                    : null
                }

            </div>
        )
    }
}

export default AsideMenuItem;