import React, { Component } from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import TabsSwitcher from "../../components/common/TabsSwitcher";
import Select from "../../components/common/Select";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import { routesList, getPage } from "../../actions/routes";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";
import { docsAbout, getCompanyParams } from "../../actions/company";
import DocumentsAbout from "../../components/InfoPages/DocumentsAbout";
import RequisitesAbout from "../../components/InfoPages/RequisitesAbout";

class LegalInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            activeFields: null,
            fields: {
                organizations: {
                    label: "",
                    value: "",
                    options: [],
                    placeholder: "",
                    hasError: false
                },
                organizationsPolicy: {
                    label: "",
                    value: "",
                    options: [],
                    placeholder: "",
                    hasError: false
                },
                organizationsLicenses: {
                    label: "",
                    value: "",
                    options: [],
                    placeholder: "",
                    hasError: false
                },
            },
            licenses: [],
            policies: [],
            requisites: null
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isB2b: PropTypes.bool
    };

    componentWillMount() {
        const { company } = this.props;
        const { organizations, organizationsPolicy, organizationsLicenses } = this.state.fields;
        const { licenses, policies, requisites, activeTab, activeFields } = this.state;
        
        if (!company.docsAbout) {
            this.props.getDocsAbout(this.context.selectedRegion.id, this.context.isB2b ? 'b2b' : 'b2c');
        }

        if ( !!company && !!company.docsAbout ) {
            if ( !!company.docsAbout.companies && company.docsAbout.companies.length > 0 ) {
                if ( organizations.options.length === 0) this.setOrganizationData(company.docsAbout.companies, 'options' );
            }

            if ( !!company.docsAbout.requisites && organizations.value === '' ) {
                const { id } = company.docsAbout.requisites;
                if (id) {
                    setTimeout(()=> {
                        this.setOrganizationData(id, 'value');
                    }, 0)
                }
            }

            let newState = {activeFields, activeTab};

            if ( licenses.length === 0 && !!company.docsAbout.licenses && company.docsAbout.licenses.length > 0 ) {
                newState.licenses = company.docsAbout.licenses;
                if (!newState.activeTab && !newState.activeFields) {
                    newState.activeTab = 0
                    newState.activeFields = 'licenses'
                }
            }

            if ( policies.length === 0 && !!company.docsAbout.policy && company.docsAbout.policy.length > 0 ) {
                newState.policies = company.docsAbout.policy;
                if (!newState.activeTab && !newState.activeFields) {
                    newState.activeTab = 1
                    newState.activeFields = 'policy'
                }
            }
            
            if ( requisites === null && !!company.docsAbout.requisites ) {
                newState.requisites = company.docsAbout.requisites;
                if (!newState.activeTab && !newState.activeFields) {
                    newState.activeTab = 2
                    newState.activeFields = 'requisites'
                }
            }
            if (newState.licenses || newState.policies || newState.requisites) {
                this.setState(newState)
            }
        }

        if ( typeof company.allLicenses === "undefined" || company.allLicenses === null ) {
            this.props.getCompanyParams(`/docs/licenses?is_b2${this.context.isB2b ? 'b' : 'c'}=1`, 'allLicenses');
        }

        if (!this.props.support.legalInformationRoute) {
            this.props.getPage('legalInformationRoute', this.context.selectedRegion.id)
        }
    }

    componentWillReceiveProps(nextProps){
        const { organizations } = this.state.fields;
        const { licenses, policies, requisites, activeTab, activeFields } = this.state;
        let { company } = this.props;

        if ( typeof company.docsAbout !== "undefined" && company.docsAbout !== nextProps.company.docsAbout ) {
            if ( nextProps.company.docsAbout.companies.length > 0 && organizations.options.length === 0 ) {
                this.setOrganizationData(nextProps.company.docsAbout.companies, 'options');
            }
            if ( !!nextProps.company.docsAbout.requisites && organizations.value === '' ) {
                const { id } = nextProps.company.docsAbout.requisites;
                if ( id ) {
                    setTimeout(()=> {
                        this.setOrganizationData(id, 'value');
                    }, 0)
                }
            }

            let newState = {activeFields, activeTab};
            
            if ( licenses.length === 0 && company.docsAbout !== nextProps.company.docsAbout ) {
                if ( !!nextProps.company.docsAbout.licenses && nextProps.company.docsAbout.licenses.length > 0 ) {
                    newState.licenses = nextProps.company.docsAbout.licenses;
                    if (!newState.activeTab && !newState.activeFields) {
                        newState.activeTab = 0
                        newState.activeFields = 'licenses'
                    }
                }
            }
            if ( policies.length === 0 && company.docsAbout !== nextProps.company.docsAbout ) {
                if ( !!nextProps.company.docsAbout.policy && nextProps.company.docsAbout.policy.length > 0 ) {
                    newState.policies = nextProps.company.docsAbout.policy;
                    if (!newState.activeTab && !newState.activeFields) {
                        newState.activeTab = 1
                        newState.activeFields = 'policy'
                    }
                }
            }
            if ( requisites === null && company.docsAbout !== nextProps.company.docsAbout ) {
                if ( !!nextProps.company.docsAbout.requisites ) {
                    newState.requisites = nextProps.company.docsAbout.requisites
                    if (!newState.activeTab && !newState.activeFields) {
                        newState.activeTab = 2
                        newState.activeFields = 'requisites'
                    }
                }
            }
            if (newState.licenses || newState.policies || newState.requisites) {
                this.setState(newState)
            }
        }

        if ( typeof company.currentLicenses !== "undefined" ) {
            if ( company.currentLicenses !== nextProps.company.currentLicenses && nextProps.company.currentLicenses !== false) {
                this.setState({licenses: nextProps.company.currentLicenses})
            }
        }

        if ( typeof company.currentPolicies !== "undefined" ) {
            if ( company.currentPolicies !== nextProps.company.currentPolicies &&
                (nextProps.company.currentPolicies.length > 0 || nextProps.company.currentPolicies.length === 0 ) ) {
                this.setState({policies: nextProps.company.currentPolicies})
            }
        }

        if ( typeof company.currentRequisites !== "undefined" ) {
            if ( company.currentRequisites !== nextProps.company.currentRequisites && !!nextProps.company.currentRequisites ) {
                this.setState({requisites: nextProps.company.currentRequisites})
            }
        }
    }

    setOrganizationData =(data, type, field = 'organizations')=> {
        let result = null;

        if ( type === 'options' && data.length > 0 ) {
            let options = [];

            data.forEach(el => {
                options.push({label: el.title, value: el.id, has_policy: el.has_policy, has_licenses: el.has_licenses})
            });
            result = options;
            let resultPolicy = options.filter((item) => item.has_policy)
            let resultLicenses = options.filter((item) => item.has_licenses)

            this.setState((prevState) => ({
                fields: {
                    organizations: {
                        ...prevState.fields.organizations,
                        [type]: result,
                        value: result.length ? result[0].value : ''
                    },
                    organizationsPolicy: {
                        ...prevState.fields.organizationsPolicy,
                        [type]: resultPolicy,
                        value: resultPolicy.length ? resultPolicy[0].value : ''
                    },
                    organizationsLicenses: {
                        ...prevState.fields.organizationsLicenses,
                        [type]: resultLicenses,
                        value: resultLicenses.length ? resultLicenses[0].value : '',
                    },
                }
            }))

        } else {
            result = data;

            this.setState((prevState) => ({
                fields: {
                    ...prevState.fields,
                    [field]: {
                        ...prevState.fields[field],
                        [type]: result
                    }
                }
            }))
        }
    }

    // при клике меняем индекс и строку с нужным именем
    onToggleTab = (val, lav) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: val,
                    activeFields: lav
                }
            })
        }
    };

    onSelected(attr, val) {
        let fields = { ...this.state.fields };
        fields[attr].value = val;
        fields[attr].hasError = false;
        this.setState({ fields });
        this.getParamsCurentCompany(val);
    }

    async getParamsCurentCompany(id) {
        const businessLineParams = `is_b2${this.context.isB2b ? 'b' : 'c'}=1`
        switch (this.state.activeTab) {
        // switch (this.state.activeFields) {
            case 0:
            // case 'licenses':
                await this.props.getCompanyParams(`/docs/licenses?company_id=${id}&${businessLineParams}`, 'licenses');
                break;
            case 1:
            // case 'policy':
                await this.props.getCompanyParams(`/docs/policy?company_id=${id}&${businessLineParams}`, 'policy');
                break;
            case 2:
            // case 'requisites':
                await this.props.getCompanyParams(`/companies/${id}`);
                break;
        }
    }

    renderSelect = (attr) => {
        let fields = {...this.state.fields};
        return <div className="legal-information-page__wrap-select">
            <Select
                hasError={fields[attr].hasError}
                options={fields[attr].options}
                attribute={attr}
                onChange={(attr, value) => this.onSelected(attr, value)}
                value={fields[attr].value}
                customClassName={'select-company'}
                enabledSelect={fields[attr].options.length > 1}
                trigger={fields[attr].options.length > 1}
            />
        </div>
    }

    render() {
        let activeTab = this.state.activeTab;
        let activeFields = this.state.activeFields;
        let selectedRegion = this.context.selectedRegion;
        const { licenses, policies, requisites } = this.state;
        const allLicenses = this.props.company && this.props.company.allLicenses;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "",
                title: 'Правовая информация'
            }
        ];

        let tabs = [], tabsContent = [];

        if (licenses && licenses.length) {
            tabs.push({ title: "Лицензии", tab: 'licenses' })

            tabsContent.push(<li key={"licenses"} className={"tabs-content__item" + ((activeTab === 0) ? " tabs-content__item--active" : "")}>
            {/* tabsContent.push(<li key={"licenses"} className={"tabs-content__item" + ((activeFields === 'licenses') ? " tabs-content__item--active" : "")}> */}
                {this.renderSelect('organizationsLicenses')}
                <div className="b-internet-ipad__tariffs-table">
                    {licenses !== false &&
                    ( licenses && licenses.length !== 0) && (licenses && licenses.length > 0 || allLicenses && allLicenses.length > 0 ) ?
                        <DocumentsAbout data={licenses} />
                        : <div>Документы не найдены</div>}
                </div>
            </li>)
        }

        if (policies && policies.length) {
            tabs.push({ title: "Политика обработки данных", tab: 'policy' })

            tabsContent.push(<li key={"policies"} className={"tabs-content__item" + ((activeTab === 1) ? " tabs-content__item--active" : "")}>
            {/* tabsContent.push(<li key={"policies"} className={"tabs-content__item" + ((activeFields === 'policy') ? " tabs-content__item--active" : "")}> */}
                {this.renderSelect('organizationsPolicy')}
                <div className="b-internet-ipad__tariffs-table">
                    {policies && policies.length > 0 ?
                        <DocumentsAbout data={policies} />
                        : <div>Документы не найдены</div>}
                </div>
            </li>)
        }

        if (requisites) {
            tabs.push({ title: "Реквизиты", tab: 'requisites' })

            tabsContent.push(<li key={"requisites"} className={"tabs-content__item" + ((activeTab === 2) ? " tabs-content__item--active" : "")}>
            {/* tabsContent.push(<li key={"requisites"} className={"tabs-content__item" + ((activeFields === 'requisites') ? " tabs-content__item--active" : "")}> */}
                {this.renderSelect('organizations')}
                {!!requisites ?
                    <RequisitesAbout requisites={requisites} />
                    : null}
            </li>)
        }

        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        const content = this.props.support && this.props.support.legalInformationRoute && this.props.support.legalInformationRoute.content

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'legalInformationRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="info-page-container__title">Правовая информация</h1>
                        <div 
                            className="legal-information-page__description"
                            dangerouslySetInnerHTML={{__html: content}}
                        />
                        {/* <div className="legal-information-page__description">
                            «МОТИВ» — ведущий инфраструктурный оператор связи в Уральском федеральном округе.<br/><br/>
                            Абонентам Мотив доступны все виды телекоммуникационных услуг. Лицензионная территория компании включает Свердловскую и Курганскую области, Ханты-Мансийский и Ямало-Ненецкий автономные округа.
                        </div> */}

                        <div className="b-internet-ipad__tabs-header legal-information-page__wrap-panel-tabs">
                            <TabsSwitcher
                                items={tabs}
                                onClickFunc={this.onToggleTab}
                                activeTab={activeTab}
                            />
                        </div>

                        <ul className="tabs__content" ref="content">
                            {tabsContent}
                        </ul>
                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <AsideMenu 
                                flag={"aboutRoute"} 
                                flagSubMenu={"legalInformationRoute"} 
                                route={this.props.routesVacancies} 
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        regions: state.regions,
        company: state.company,
        support: state.support,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getDocsAbout: (region, businessLine = 'b2c') => dispatch(docsAbout(region, businessLine)),
            getCompanyParams: (params, type)=> dispatch(getCompanyParams(params, type)),
            getPage: (page, region) => dispatch(getPage(page, region))
        }
    }
)(LegalInformation);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const LegalInformationRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getDocsAbout',
        args: ['b2c'],
        object: 'company.docsAbout',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    }
];
