import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {infoBlocksList} from "../../actions/infoBlocks";
import {Helmet} from "react-helmet";
import FaqSearch from "../../components/Support/FaqSearch";
import {faqList} from "../../actions/questions";
import {supportNotices, ussd} from "../../actions/support";
import {formsList, submitMnpForm} from "../../actions/forms";
import InfoBlock from "../../components/common/InfoBlock";
import AlertBlock from "../../components/Support/AlertBlock";
import {routesList} from "../../actions/routes";
import {RightSupportSlidesInfoBlock} from "../../components/InfoBlocks/InfoBlockSupport";
import MobileNav from "../../components/Support/MobileNav";
import RightNav from "../../components/Support/RightNav";

import CommonCallbackForm from "../../components/Forms/CommonCallbackForm";

class Support extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if (this.props.infoBlocks && this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'support');
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(regionId, null, 'rank_support');
        }
        this.props.getSupportNotices();
        if (this.props.forms.list.length === 0) {
            this.props.getForms("support");
        }

        let form = this.props.forms ? this.props.forms.list.find(x => x.alias === 'security_form_block') : null;
        if (!form) {
            this.props.getForms("security");
        }

        this.props.getRoutes();
        // this.props.getUssd(regionId);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    render() {
        let {
            faq,
            notices,
            forms,
            submitForm,
            ussd,
            routes,
            regions,
            location
        } = this.props;

        let infoBlocks = this.props.infoBlocks.list;

        notices = notices.filter(x => x.region_id === this.context.selectedRegion.id || x.region_id === null);

        let fastDecisionsRoute = routes ? routes.list.find(x => x.sys_name === 'fastDecisionsRoute') : null;

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");
        
        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'supportRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder  main-media-bank" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <h1 className="page__title">Поддержка</h1>
                            <div className="b-support-faq b-support-faq_adapt-defoult">
                                <FaqSearch forms={forms} withSearch={true} rankField={"rank_support"} list={faq.list}
                                           history={this.props.history}>
                                    {notices.map((item) => <AlertBlock key={item.id} text={item.text}/>)}
                                </FaqSearch>
                            </div>
                        </div>
                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <RightNav/>
                            </div>
                            <div className="right-nav-wrap--support page__aside-cols page__aside-cols_support">
                                <InfoBlock section="support_right_banners" type="support_right_banners"
                                           data={infoBlocks}/>
                                {fastDecisionsRoute ? <RightSupportSlidesInfoBlock blocks={ussd}
                                                             fastDecisionsRoute={fastDecisionsRoute.route}/> : null}
                                <InfoBlock section="support_right_messenger"
                                           subsection="support_right_messengers_banners"
                                           type="support_right_messenger"
                                           data={infoBlocks}
                                           additionalProps={{
                                               forms,
                                               submitForm
                                           }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='page_adapt_support-faq'>
                        <div className="page__right-aside hidden-lg">
                            <div className="page__aside-cols page__aside-cols_support">
                                <InfoBlock section="support_right_banners" type="support_right_banners"
                                           data={infoBlocks}/>
                                {fastDecisionsRoute ? <RightSupportSlidesInfoBlock blocks={ussd}
                                                             fastDecisionsRoute={fastDecisionsRoute.route}/> : null}
                                <InfoBlock section="support_right_messenger"
                                           subsection="support_right_messengers_banners"
                                           type="support_right_messenger"
                                           data={infoBlocks}
                                           additionalProps={{
                                               forms,
                                               submitForm
                                           }}
                                />
                            </div>
                        </div>

                        <div className="page__no-answer">
                            <div className="b-support-no-answer b-support-no-answer_adapt-defoult">
                                <div className="b-support-no-answer__title">Не нашли ответ? Свяжитесь с нами</div>
                                <InfoBlock section="support_bottom_banners" type="support_bottom_banners"
                                           data={infoBlocks}/>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        infoBlocks: state.infoBlocks,
        faq: state.faq,
        notices: state.support.notices || [],
        forms: state.forms,
        routes: state.routes,
        ussd: state.support.ussd,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getFaq : (regionId, categoryId, categoryRankField) => dispatch(faqList(regionId, categoryId, categoryRankField)),
            getSupportNotices: () => dispatch(supportNotices()),
            submitForm: (formData, alias, target) => dispatch(submitMnpForm(formData, alias, target)),
            getForms: (group) => dispatch(formsList(group)),
            getRoutes: () => dispatch(routesList()),
            getUssd: (regionId) => dispatch(ussd(regionId))
        }
    }
)(Support);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const supportRequests = [
    {
        func: 'getInfoBlocks',
        args: ['support'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "support_right_banners"},
            {type: "support_right_slides"},
            {type: "support_right_messenger"},
        ]
    },
    {
        func: 'getForms',
        args: ["support"],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getFaq',
        args: [null, 'rank_support'],
        object: 'faq.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getUssd',
        args: [],
        object: 'support.ussd',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
];