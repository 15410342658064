import React, { Component } from 'react';
import { mediaUrl } from "../../tools/config";

class LightBannerAbout extends Component {

    render() {
        const { data } = this.props;
        return (<React.Fragment>
            {data.length > 0 ? <div className="values-page__wrap-big-img"
                style={{
                    backgroundImage: 'url('+mediaUrl + data[0].imagePath+')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}/> : null}
        </React.Fragment>)
    }
}

export default LightBannerAbout;