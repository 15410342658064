import React, {Component} from 'react'
import {datePretty} from "../../tools/mask";

export default class Replenishments extends  Component {

    constructor(props){
        super(props);

        this.state = {
            items: [],
            sort: 'desc'
        }
    }

    componentWillMount() {
        if (!!this.props.items) {
            let sortItems = this.props.items.sort(function (a, b) {
            let dateA = new Date(a.date).getTime();
            let dateB = new Date(b.date).getTime();
                if (dateA < dateB){
                    return 1;
                }
                if (dateA > dateB) {
                    return -1;
                }
                return 0;
            });
            this.setState({ items: sortItems });
        }
    }

    sort = () => {

        this.setState((prevState) => {
            return {
                sort: prevState.sort === 'desc' ? 'asc' : 'desc',
                items: prevState.items.reverse()
            };

        });
    };

    render(){

        let {message} = this.props;
        let {items} = this.state;

        return <div>
            {message ? <div className="page__title-3 page__title-3_first-elem page__title-3_mobile-less">
                {message}
            </div> : <div className="page__table page__table_repl">
                <div className="b-table-default b-table-default_adapt_replenishment">
                    <div className="b-table-default__wrapper">
                        <div className="b-table-default__thead">
                            <div className="b-table-default__th">
                                <div className={"b-table-default__sort-btn b-table-default__sort-btn_"+(this.state.sort === "desc" ? "down" : "up")} onClick={this.sort}>
                                    Дата пополнения
                                </div>
                            </div>
                            <div className="b-table-default__th">Описание</div>
                            <div className="b-table-default__th">Сумма</div>
                        </div>
                        <div className="b-table-default__tbody">
                            {items.map((item, key) => <div className="b-table-default__trow" key={key}>
                                <div className="b-table-default__td">{item.date ? datePretty(item.date, true) : "-"}</div>
                                <div className="b-table-default__td">
                                    <div className="b-table-default__text-two-str">{item.gateway}</div>
                                    <div className="b-table-default__hidden-price">{item.amount}<span className="price-rub"> ₽</span></div>
                                </div>
                                <div className="b-table-default__td">{item.amount}<span className="price-rub"> ₽</span></div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>}
            {/*<div className="page__repl-way">*/}
                {/*<a className="page__repl-way-btn" href="#">Способы пополнения</a>*/}
            {/*</div>*/}
        </div>
    }
}