import {
    call,
    put
} from 'redux-saga/effects';

import {
    checkErrors,
    fetchClientApi
} from '../tools/api-helper';

export function megaMenuList() {
    return {
        type: 'MEGA_MENU_ITEMS_LIST',
    }
}

export function* fetchMegaMenuList(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi("/mega-menu", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}