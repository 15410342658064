import React, { Component } from 'react';
import Slider from 'react-slick';
import Icon from '../common/Icon';
import {connect} from "react-redux";
import {download} from "../../actions/media";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="rates-slider-arrow slider-fullpage-arrow slider-fullpage-arrow--next"
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="rates-slider-arrow slider-fullpage-arrow slider-fullpage-arrow--prev"
            onClick={onClick}
        />
    );
}

class MediaSlider extends Component {

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            fade: true,
            swipeToSlide: true,
            initialSlide: this.props.sliderItem,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            draggable: false,
            responsive: [ {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        };

        let { items } = this.props;

        return (
            <Slider {...settings}>
                {items && items.content.map(( el ) =>
                    <React.Fragment>
                        <div className="media-slider-slide" key={ el.id }>
                            <div 
                                className="media-slider__img"
                                style={{ 
                                    // backgroundImage: "url(" + el.miniaturePath + ")",
                                    backgroundImage: "url(" + el.path + ")",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }}
                            />
                        </div>
                    <div className="media-slider-description-wrap">
                        <Icon name="icon_media-download" className="media-slider-description--icon"/>
                        <a className="media-slider-description--text" href="#" onClick={(e) => {e.preventDefault(); this.props.download(el.path)}}>Скачать ({ el.size })</a>
                    </div>
                    </React.Fragment>
                )}
            </Slider>
        )
    }
}

export default connect(
    state => ({

    }),
    (dispatch) => {
        return {
            download: (path) => dispatch(download(path))
        }
    }
)(MediaSlider);