import React, {Component} from 'react';
import RatesCollapseFag from "./RatesCollapseFag";

class RatesFaq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            activeId: null
        }
    }

    toggleList = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        });
    };

    onToggleCollapse = (val) => {
        this.setState({activeId: val === this.state.activeId ? null : val});
    };

    componentDidMount(){
        let {items} = this.props

        // находим hash (то что находится после #)
        const hash = window.location.hash.replace('#', '')
        // разделяем на 2 части до нужного знака (utm)
        const splits = hash.split('?');
        // находим нужый alias
        const aliasFind = items.find((item) => item.alias === splits[0]);

        items.forEach(element => {
            if(aliasFind && aliasFind.id === element.id){
                this.onToggleCollapse(element.id)
                this.scrollToAnchorName(splits[0])
            }
        });
    }
    // якорь к нужному вопросу
    scrollToAnchorName(hash) {
        // находим нужный элемент по id
        let target = document.getElementById(hash);

        setTimeout(() => {
            if (target && target.getAttribute('name') === hash) {
                var rect = target.getBoundingClientRect();
                window.scrollTo((document.documentElement.scrollLeft || 0),
                    rect.top + (document.documentElement.scrollTop || 0)
                    - (document.documentElement.clientTop || 0));
            }
        }, 1000);
    }   

    render () {

        let {items, title} = this.props

        let firstShownItems = 5;        
        
        return (
            <React.Fragment>
                {
                    items.length
                        ?   <div className="b-service-inner__list-collapser-block">
                                <div className="page__title-3 page__title-3_mobile-less">{title}</div>
                                <div className="b-service-inner__list-collapser">
                                    <div className="b-list-collapser b-list-collapser_adapt_default">
                                        <div className="b-list-collapser__list">
                                            {items.map((item, key) =>
                                                key < firstShownItems || this.state.opened
                                                    ?   <RatesCollapseFag title={item.question} key={key} item={item} activeId={this.state.activeId} toggleCollapse={this.onToggleCollapse} />
                                                    :   ''
                                            )}
                                        </div>
                                        {!this.state.opened && items.length > firstShownItems
                                            ?   <div className="b-list-collapser__btns">
                                                    <div onClick={this.toggleList} className="b-list-collapser__more-btn u-link u-link_dotted">Показать еще</div>
                                                </div>
                                            :   this.state.opened && items.length > firstShownItems
                                                ?   <div className="b-list-collapser__btns">
                                                        <div onClick={this.toggleList} className="b-list-collapser__more-btn u-link u-link_dotted">Скрыть</div>
                                                    </div>
                                                :   ''
                                        }
                                    </div>
                                </div>
                            </div>
                        :   ''
                }
            </React.Fragment>
        )
    }

}

export default RatesFaq;