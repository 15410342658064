import React, {Component} from "react";
import {isMobile} from "react-device-detect";
import Icon from "../common/Icon";

class MobileAppAdvert extends Component {

    constructor(props) {
        super(props)

        this.state = {
            visible: typeof document !== "undefined" && isMobile && sessionStorage.getItem('mobile-app-advert') !== 'closed'
        };
    }

    close = () => {
        sessionStorage.setItem('mobile-app-advert', 'closed');
        this.setState({visible: false});
    }

    render() {
        return <div key={'mobile-app-advert'}>
            {this.state.visible ? <div className={'mobile-app__wrapper'}>
                <span className={'mnp-popup__close'} onClick={this.close}/>
                <div className={'mobile-app__text'}>
                    <Icon name={'icon_logo_mediator-arrows'}/>
                    {this.props.text ? <span dangerouslySetInnerHTML={{__html: this.props.text}}/> :
                        <span>Открой в&nbsp;мобильном приложении</span>}
                </div>
                <a href={'https://motiv.me/download-mp'} target={'_blank'}
                   className={'u-btn u-btn_sm u-btn_default'}>Открыть</a>
            </div> : null}
        </div>
    }
}

export default MobileAppAdvert;