import React, {Component} from "react";
import AlertBlock from "./AlertBlock";
import {categoriesFromList, sortByProp} from "../../tools/tools";
import {mediaUrl} from "../../tools/config";
import RatesServiceList from "../Rates/RatesServiceList";
import RatesAdditionalServices from "../Rates/RatesAdditionalServices";
import Collapse from "../common/Collapse";
import SmallCollapse from "./SmallCollapse";
import FaqCollapseForm from "../common/FaqCollapseForm";

export default class FaqSearch extends Component{

    constructor(props){
        super(props);

        let form = props.forms ? props.forms.list.find(x => x.alias === 'support_search_form') : null;

        this.state = {
            fields: {
                search: {
                    placeholder: "Поиск",
                    value: "",
                },
            },
            openedCat: false,
            openedQuestion: null,
            form: form,
        }
    }

    componentDidMount() {
        let {
            list,
        } = this.props;

        // ищем хэш в адресной строке
        const finedHash = window.location.hash.substr(1);
        const target = list.find((item) => item.id === +finedHash);
        // если находим, то найденный вопрос записываем в поле поиска, и нам открывается нужный ответ
        if (target) {
            let fields = {...this.state.fields};
            fields.search.value = target.question;
            this.setState({fields});
        }
    }

    onFieldChange = (e) => {
        let fields = {...this.state.fields};
        fields.search.value = e.target.value;
        this.setState({fields});
    };

    onSubmit = (e) => {
        e.preventDefault();
    };

    faqByCats (cats, items) {

        let result = [];

        cats.forEach (function (cat, i) {

            let obj = {'category': {}, 'items' : []};

            obj.category = cat;
            result[i] = obj;

            items.forEach(function (elem) {
                if (cat.id === elem.category.id) {
                    result[i].items.push(elem);
                }
            });

            //sortByProp(result[i].items, 'rank');
        });

        return result;
    }

    filterFaq = (list) => {
        return list.filter(this.filterBySearch);
    };

    filterBySearch = (item) => {
        let search = this.state.fields.search.value;

        if (item.question.search(new RegExp(search,'ig')) !== -1) {
            return true;
        }

        if (item.search_keywords && item.search_keywords.search(new RegExp(search,'ig')) !== -1) {
            return true;
        }

        if (item.answer.search(new RegExp(search,'ig')) !== -1) {
            return true;
        }

        return false;
    };

    render() {

        let {
            list,
            rankField,
            withSearch,
            children
        } = this.props;

        let {
            form
        } = this.state;

        let search = this.state.fields.search;




        let filteredList = [];

        list.forEach(function (item) {
            filteredList.push(Object.assign({}, item));
        });

        if (search.value.length > 2) {
            filteredList = this.filterFaq(filteredList);
            filteredList.forEach(function (item) {
                item.question = item.question.replace(new RegExp(search.value,'ig'), '<span class="searched">' + search.value + '</span>');
                item.answer = item.answer.replace(new RegExp(search.value,'ig'), '<span class="searched">' + search.value + '</span>');
            });
        }

        let categories = sortByProp(categoriesFromList(filteredList, 'category', rankField), rankField);
        let faq = this.faqByCats(categories, filteredList);

        return  <React.Fragment>
                    {
                        withSearch
                            ?
                            <React.Fragment>
                                <div className="b-support-faq__search">
                                    <form onSubmit={this.onSubmit}>
                                        <div className="b-input-text">
                                            <div className="b-input-text__wrapper">
                                                <div className="b-input-text__input-area">
                                                    <input className="b-input-text__input" type="text"
                                                           placeholder={search.placeholder}
                                                           value={search.value}
                                                           autoComplete="off"
                                                           onChange={this.onFieldChange}
                                                           name="search"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <a onClick={this.onSubmit} className="b-country-search__search-btn" href="#" />
                                    </form>
                                </div>
                            </React.Fragment>
                            :   ''
                    }
                    <div key="alerts">
                        {children}
                    </div>
                    <div className="b-support-faq__title">Вопросы по категориям</div>
                    <div className="b-support-faq__block">
                        <div className="b-small-collapser b-small-collapser_adapt-defoult">
                            {
                                faq.map((cat, index) =>
                                    <div id={"faq-category-"+cat.category.id} key={"faq-category-"+cat.category.id}>
                                        <Collapse
                                            key={"faq-category-"+cat.category.id}
                                            opened={search.value.length > 2}
                                            openedDefault={this.props.history && this.props.history.location && this.props.history.location.hash == "#faq-category-"+cat.category.id}
                                            title={cat.category.name}
                                        >
                                            {
                                                cat.items.map((item, key) => {
                                                    return (
                                                        <SmallCollapse
                                                            opened={search.value.length > 2}
                                                            key={key}
                                                            title={item.question}
                                                        >
                                                            <div dangerouslySetInnerHTML={{__html: item.answer}} />
                                                            <FaqCollapseForm id={item.id} />
                                                        </SmallCollapse>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                    </div>
                                )
                            }
                        </div>
                    {
                        withSearch && faq.length === 0
                        ?  <p>{form ? form.settings.text_notification_no_result : ""}</p>
                        :  ''
                    }
                    </div>
                </React.Fragment>
    }
}

