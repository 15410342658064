import React, { Component } from 'react';
import Modal from "../common/Modal";
import RecoveryForm from "../Forms/RecoveryForm";

export default class RecoveryModal extends Component {

    render() {

        let {
            close,
            recover = () => {},
            error,
            recoveryFormSettings = {},
            modal
        } = this.props;

        return (
            <Modal className="u-modal u-modal_active u-modal-top" close={close}>
               <RecoveryForm recover={recover} error={error} recoveryFormSettings={recoveryFormSettings} modal={modal} />
            </Modal>
        )
    }
}