const initState = { isOk:false, news: [], newsBasic: []};

export function news(state = initState, action) {
    switch (action.type) {
        case 'NEWS_LIST':
            return {...state, isOk: false};
        case 'NEWS_LIST_MORE':
            return {...state, isOk: false};
        case 'NEWS_LIST_SHORT':
            return {...state, isOk: false};
        case 'NEWS_LIST_SUCCESS':
            return {...state, news: action.data, isOk: true};
        case 'NEWS_LIST_SHORT_SUCCESS':
            return {...state, newsBasic: action.data, isOk: true};
        case 'NEWS_LIST_MORE_SUCCESS':
            return {...state, news: {data: [...state.news.data, ...action.data.data], pagination: action.data.pagination}, isOk: true};
    }
    return state;
}