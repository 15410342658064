import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function regionsList() {
    return {
        type: 'REGION_LIST',
    }
}

export function* fetchRegionsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/regions", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function setRegions(data) {
    return {
        type: 'REGION_LIST_SUCCESS',
        data
    }
}

export function regionsListB2B(){
    return{
        type: 'REGION_LIST_B2B'
    }
}

export function* fetchRegionsListB2B(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/regions/b2b", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}