import React, { Component } from 'react';

import Icon from './Icon';

class SwitcherInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: !!props.value
        }
    }

    onChange = () => {
        if (this.props.withoutRecieveProps) {
            this.setState((prevState) => {
                return {
                    checked: !prevState.checked
                }
            }, () => {this.props.onChange(this.state.checked)})
        }
    };

    componentWillReceiveProps(nextProps) {
        if (!!nextProps.value !== this.state.checked) {
            this.setState({checked: !!nextProps.value})
        }
    }

    render() {

        return (
            <div className={"switcher-wrap" + (this.props.disabled ? " disabled" : "") + (this.props.readOnly? " read-only" : "")}>
                <div className="switcher-input">
                    <Icon name={this.props.readOnly ? 'icon-ok-orange-small' : 'icon-ok-small'} className={''} />
                    <input type="checkbox" id={this.props.id} checked={this.state.checked} onChange={this.onChange}/>
                    <label className="switcher-input__label" htmlFor={this.props.id} />
                </div>
                {this.props.name ?
                    <div className="switcher-wrap__text" onClick={this.onChange}>{this.props.name}</div>
                    : ''
                }
            </div>
        )
    }
}


export default SwitcherInput;