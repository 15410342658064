import React, { Component } from 'react';
import PaymentMethodCard from "../../components/PaymentsTransfers/PaymentMethodCard";
import CarouselPaymentMethod from './CarouselPaymentMethod';

class PaymentMethodCategories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    render() {

        let {
            item,
            onClick,
            active
        } = this.props

        return (
            <React.Fragment>
                <div className={`payment-methods-categories__block ${active ? "payment-methods-categories__block-collapsed" : ""}`}>
                    <div 
                        className="payment-methods-categories__wrapper" 
                        onClick={onClick}
                    >
                        <div className="payment-methods-categories__wrapper-title">{item.category.title}</div>
                    </div>

                    <div className={`u-collapser ${active ? "u-collapser_open" : ""}`}>
                        <div className="u-collapser__container">
                            <div className="payment-methods-categories__info-block" dangerouslySetInnerHTML={{__html: item.category.description}}>
                            </div>

                            <div className="payment-methods-card__container">
                                {item && item.types.map(i =>
                                    <React.Fragment key={i.id}>
                                        <PaymentMethodCard data={i} />
                                    </React.Fragment>
                                )}
                            </div>

                            {(item && item.types.length > 0) 
                                && (
                                    <div className="payment-methods-card__container-slider">
                                        <CarouselPaymentMethod data={item && item.types} />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentMethodCategories;