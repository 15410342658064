import React, { Component } from 'react';
import PropTypes from "prop-types";
import Slider from 'react-slick';
import {Link} from "react-router-dom";
import LinkC from "../../components/common/LinkC";

class CarouselRatePromotion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dots: []
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.slickTo = this.slickTo.bind(this);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount () {
        let dots = [];

        this.props.data.forEach(function (el, i) {
            dots.push({
                active: i === 0,
            });
        });

        this.state.dots = dots;
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    slickTo (e) {
        let idx = e.target.getAttribute('data-index');
        this.slider.slickGoTo(idx);
    }

    changeDot (idx) {
        this.setState(() => {
            this.state.dots.forEach(function (el) {
                el.active = false;
            });

            this.state.dots[idx].active = true;
            return {dots : this.state.dots}
        });
    }

    // closeContent
    render() {
        let {
            data
        } = this.props;

        const settings = {
            dots: false,
            infinite: data.length > 2, // убираем бесконечную прокрутку если слайдов меньше чем нужно тем самым отключаем КЛОНЫ СЛАЙДЫ,
            // autoplay: 4900,
            // speed: 1000,
            // autoplaySpeed: 4900,
            slidesToShow: 2,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            draggable: true,
            
            centerMode: true,
            centerPadding: '15px',
            beforeChange: (current, next) => {
                this.changeDot(next)
            },
            responsive: [ 
            {
                breakpoint: 560,
                settings: {
                    infinite: data.length > 2,
                    slidesToShow: 2,
                    centerMode: false,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    infinite: data.length > 1,
                    slidesToShow: 1,
                    centerPadding: '0px',
                },
            }
        ]
        };
        
        let selectedRegion = this.context.selectedRegion;

        return (
            <div className="about-page__slider-wrap-content">
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {data.map((i) =>
                        <Link 
                            key={i.id} 
                            className="archived_promotions__cart promotion__other_carousel_cart"
                            to={`/${selectedRegion.alias}/actions/${i.id}`}
                        >
                            <div className="archived_promotions__cart_img">
                                <img src={i.photo} alt=""/>
                                <div className="archived_promotions__category_wrapper">
                                    <div className="promotion__category">{i.categories[0].title}</div>
                                </div>
                            </div> 
                            <div className="archived_promotions__cart_date">{i.date}</div>
                            <div className="archived_promotions__cart_title">{i.title}</div>
                            <div className="archived_promotions__cart_info" dangerouslySetInnerHTML={{__html: i.short_description || i.description}} />
                        </Link> 
                    )}
                </Slider>
                <div className="payment-methods__slider-controls">
                    {/* <div className="about-page__slider-controls-wrap-arrow">
                        <div onClick={this.previous} className="about-page__slider-controls-arrow_many about-page__slider-controls-arrow_left" />
                        <div onClick={this.next} className="about-page__slider-controls-arrow_many about-page__slider-controls-arrow_right" />
                    </div> */}
                    <div className="payment-methods__slider-controls-wrap-dots">
                        <ul className="slick-dots" role="tablist">
                            {this.state.dots.map((dot, index) =>
                                <li className={dot.active ? "slick-active" : ""} role="presentation" key={index}>
                                    <button type="button" onClick={this.slickTo} data-index={index}/>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default CarouselRatePromotion;