import React, {Component} from "react";


export default class AutoComplete extends Component{

    constructor(props){
        super(props);
        this.state = {
            value: "",
            opened: false
        };

        this._inputEl = null;
    }

    onSelect = (item) => {
        return () => {
            this.setState({
                value: item.label,
                opened: false
            })

            this.props.onChange(item.label);
        }
    }

    onFocus = () => {
        this.setState({opened: true});
    }

    /*onBlur = (e) => {
        this.props.onChange(e.target.value);
    }*/

    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
        this.props.getOptions(e.target.value);
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13 && this.props.options.length > 0) {
            this._inputEl.blur();
            let item = this.props.options[0];
            this.setState({
                value: item.label,
                opened: false
            }, () => {
                this.props.onChange(item.label);
            })
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({opened: false});
    };

    clearInput = () => {
        this.setState({value: ""});
        this.props.onChange();
    };

    render(){

        var {
            className = "autocomplete",
            inputOptions = {},
            options = [],
            showClearIcon
        } = this.props;

        var {
            opened,
            value
        } = this.state;

        return <div className={className} onClick={(e) => {e.stopPropagation(); e.nativeEvent.stopImmediatePropagation()}}>
            <input {...inputOptions}
                   type="text"
                   value={value}
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onChange={this.onChange}
                   onKeyDown={this.onKeyDown}
                   ref={(ref) => this._inputEl = ref}
            />
            {opened && options.length ?
                <div className="jq-selectbox__dropdown">
                    <ul>
                        {options.map((item, key) =>
                            <li key={key} onClick={this.onSelect(item)}>
                                {item.label}
                            </li>
                        )}
                    </ul>
                </div> : null}

            {showClearIcon && value
            ?
            <svg
                className="b-input-text__clear-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                onClick={this.clearInput}
            >
                <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="2">
                    <path d="M15.364 2.636L2.636 15.364M15.364 15.364L2.636 2.636"/>
                </g>
            </svg>
            : null
            }

        </div>
    }
}
