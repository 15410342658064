import React, { Component } from 'react';

import Slider from 'react-slick';
import HomePageTariff from "../../components/Rates/HomePageTariff";
import B2bServiceCard from "../Services/B2bServiceCard";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="rates-carousel-arrow rates-carousel-arrow--next"
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="rates-carousel-arrow rates-carousel-arrow--prev"
      onClick={onClick}
    />
  );
}

class RatesCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSm: false,
            settings: null
        }
    }

    componentDidMount(){
        if (typeof window !== "undefined") {

            window.addEventListener('resize', this.onResize);
            if (window.innerWidth <= 767 && !this.state.isSm) this.setState({isSm: true});
        }

        if (!this.state.settings) {
            let settings = this.setSetting();
            this.setState({settings})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        let {isSm} = this.state;
        if (typeof window !== 'undefined') {
            if (window.innerWidth <= 767) {
                if (!isSm) this.setState({isSm: true});
            }

            if (window.innerWidth >= 768) {
                if (!!isSm) this.setState({isSm: false});
            }

            if (window.innerWidth <= 1023 || window.innerWidth >= 1024 || window.innerWidth >= 768 ) {
                this.setSetting();
            }
        }
    };

    setSetting =()=> {
        let sliderToShowDef = 4;
        let slidesToShow1023Def = 2;

        if (!this.props.firstBlock) {
            if (this.props.blocks.length >= 4) {
                sliderToShowDef = 4;
            } else {
                sliderToShowDef = this.props.blocks.length;
            }

            if (this.props.blocks.length >= 3) {
                slidesToShow1023Def = 2;
            }
        }

        if (!!this.props.firstBlock) {
            if (this.props.blocks.length >= 3) {
                sliderToShowDef = 3;
            } else {
                sliderToShowDef = this.props.blocks.length;
            }
        }

        return {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: sliderToShowDef,
            slidesToScroll: 1,
            swipeToSlide: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            draggable: false,
            responsive: [{
                breakpoint: 1023,
                settings: {
                    // slidesToShow: slidesToShow1023Def,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        }
    }

    renderContent = () => {
        let {
            type = 'b2c',
            blocks,
            selectedRegion,
            isGuest,
            onClickConnect,
            customClass
        } = this.props;

        return type === 'b2b-service' ? blocks.map((service) => <B2bServiceCard item={service} key={service.id}/>) :
            blocks.map((tariff) =>
                <HomePageTariff
                    type={type}
                    tariff={tariff}
                    key={tariff.id}
                    selectedRegion={selectedRegion}
                    isGuest={isGuest}
                    onClickConnect={onClickConnect}
                    customClass={customClass}
                    {...type === 'b2b' ? {buttonTextGuest: "Подключить тариф"} : {}}
                />
            );
    }

    render() {

        let {isSm, settings} = this.state;
        const content = this.renderContent()

        return !isSm && !!settings ?
                    <Slider {...settings}>
                        {content}
                    </Slider> : <div className={'slider-content'}>{content}</div>
    }
}


export default RatesCarousel;