import React, {Component} from "react";
import objectMap from "../../../images/content/icon-map-pin.svg";
import objectMapSelected from "../../../images/content/icon-map-pin-active.svg";
import {GeolocationControl, Map, ObjectManager, YMaps, ZoomControl, Placemark, Clusterer, Rectangle, Button, Polygon} from "react-yandex-maps";
import OfficeCard from "./OfficeCard";
import OfficeCardMap from "./OfficeCardMap";
import PropTypes from "prop-types";
import RateChooseItem from "../Rates/RateChooseItem";
import RateListItem from "../Rates/RateListItem";
import OfficeData from "./OfficeData";
import {getObjectData} from "../../tools/form-helper";
import {mediaUrl} from "../../tools/config";
import AutoComplete from "./../../components/common/AutoComplete";

import mapMinus from "../../../images/icons/map-minus.svg";
import mapPlus from "../../../images/icons/map-plus.svg";
import {getBoundsByCity, normalizeForSelect} from "../../tools/tools";

export default class OfficeMap extends Component {

    constructor(props){
        super(props);

        this.state = {
            card: {id: 0},
            zone: {
                lte: true,
                gsm: true
            },
            tab: props.isMotivCoverMap ? "zone" : '',
            items: [],
            itemsShow: [],
            services: [],
            quantitySelectServices: 1,
            // quantitySelectServices: 0,
            userCoordinates: {},
            activeKey: null
        };
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        setHeaderClassName: PropTypes.func
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.state.services != nextState.services) {
            this.selectOfficesByFilter(nextState.services, nextState.items);
        }

        if (this.props.items != nextProps.items) {
            this.setState({
                typeUpdate: 'update'
            })
        }

    }

    componentWillMount() {
        // Received list of office push to state
        // and refresh service list
        // its need for filter

        if (this.props.items.length > 0) {
            this.setState({
                items: this.props.items,
                typeUpdate: 'load'
            });

            this.formationServicesList(this.props.items);
        } 
    }

    componentDidMount() {
        this.selectOfficesByFilter(this.state.services, this.state.items);       
    }

    componentWillReceiveProps(nextProps){
        if (
            // this.state.getClosest &&
            this.props.address != nextProps.address && nextProps.address.length == 1) {
            nextProps.getClosestShopsList(this.context.selectedRegion.id, nextProps.address[0].lat, nextProps.address[0].lon, nextProps.address[0].region, nextProps.address[0].city, nextProps.address[0].street);
            //this.setState({getClosest: false});
        }

        if (this.props.items != nextProps.items) {
            // Changed list of office push to state
            // and refresh service list
            // its need for filter

            this.setState({
                card: {id: 0},
                items: nextProps.items
            });

            // when at start we get a zero array

            if (this.state.items.length > 0)
                this.updateServicesList(nextProps.items);
            else
                this.formationServicesList(nextProps.items);
        }

        if (this.props.zone != nextProps.zone) {
            this.setState({
                zone: nextProps.zone
            })
        }
    }

    showZonesHandler = (val) => {
        if (val) {
            let zone = {...this.state.zone};
            zone[val] = !zone[val];
            this.setState({zone});
        }
    };

    setOfficeCardData = (data, key) => {
        this.setState({
            card: {id: 0},
            activeKey: null
        });
        this.setState({
            card: data,
            activeKey: key
        });
    };

    closeCard = (e) => {
        e.preventDefault();
        this.setState({
            card: {id: 0},
            activeKey: null
        });
    };

    filterShops = (shops) => {
        return shops.filter(x => x.region_id === this.context.selectedRegion.id)
    };

    chooseAddress = (value) => {
        if (value) {
            this.props.addressAutoComplete('addressFull', value, {
                count: 1,
                regionId: this.context.selectedRegion.id,
                allRegions: this.props.typeMap === 'sim' ? 0 : 1
            });
            //this.setState({getClosest: true});
        } else {
            this.props.getShopsList(this.context.selectedRegion.id);
        }
    };

    handlerCloseCard = () => {
        // for type map - "Get sim"
        // when zooming
        // close salon card

        if (this.props.typeMap === 'sim') {
            this.setState({card: {id: 0}});
        }
    };

    handlerChangeTab = (val) => {
        // Check open tab

        if (val === 0) {
            history.pushState(null, null, '/' + this.context.selectedRegion.alias + '/motiv-on-map');
            this.setState({
                tab: "office"
            });
        }

        if (val === 1) {
            history.pushState(null, null, '/' + this.context.selectedRegion.alias + '/motiv-cover-map');
            this.setState({
                tab: "zone"
            })
        }
    };

    // select offices by services filter

    selectOfficesByFilter = (services, items) => {
        
        let listOffice = [];

        if (items) {
            listOffice = [...items];
        } else {
            listOffice = [...this.state.items];
        }

        // filter only select services

        const selectServices = services.filter(service => service.checked === true);

        const selectOffices = listOffice.filter(office => {

            // counter matches

            let numberOfMatches = 0;

            // match select services with office services

            selectServices.map(service => {
                let compareServiceWithOfficeServices = office.shopServices.findIndex(serviceOffice => serviceOffice.id === service.id);

                if (compareServiceWithOfficeServices !== -1) {
                    numberOfMatches++;
                }
            });

            return selectServices.length === numberOfMatches;
        });

        this.setState({
            itemsShow: selectOffices,
            typeUpdate: 'filter'
        })
    };

    // get list services from office list

    getListServicesFromOfficeList = (items) => {
        const services = [];

        if (items) {
            items.map(salon => {
                salon.shopServices.map(shopService => {
                    const serviceSearch = services.findIndex(service => service.id === shopService.id);

                    if (serviceSearch === -1) {
                        if (shopService.title === 'Подключение' && !this.props.isMnpOffices) {
                            services.push({
                                id: shopService.id,
                                title: shopService.title,
                                checked: true,
                                active: true
                            });
                        } else if(shopService.title === 'Переход в Мотив' && this.props.isMnpOffices) {
                            services.push({
                                id: shopService.id,
                                title: shopService.title,
                                checked: true,
                                active: true
                            });
                        } else {
                            services.push({
                                id: shopService.id,
                                title: shopService.title,
                                checked: false,
                                active: true
                            });
                        }
                    }
                    // if (serviceSearch === -1) {
                    //     services.push({
                    //         id: shopService.id,
                    //         title: shopService.title,
                    //         checked: false,
                    //         active: true
                    //     });
                    // }
                })
            })
        }

        return services;
    };

    // get services list from offices list when component mount

    formationServicesList = (items) => {
        const servicesList = this.getListServicesFromOfficeList(items);

        this.setState({
            services: servicesList
        })
    };

    // update services list when component update

    updateServicesList = (items) => {
        const servicesList = [...this.state.services];
        const servicesOfOffices = this.getListServicesFromOfficeList(items);

        servicesList.map((service, index) => {
            const serviceSearch = servicesOfOffices.findIndex(serviceOfOffice => serviceOfOffice.id === service.id);

            if (serviceSearch === -1) {
                servicesList[index].active = false;
                servicesList[index].checked = false;
            }
        });

        this.setState({
            services: servicesList
        });
    };

    // handler of checkbox form

    handlerCheckbox = (event) => {
        const selectServices = [...this.state.services];

        const target = event.target;
        const checked = target.checked;
        const value = target.value;

        const serviceIndex = selectServices.findIndex(service => service.id === value);

        if (serviceIndex !== -1) {
            selectServices[serviceIndex].checked = checked;
        }

        if (checked) {
            this.setState(prevState => {
                return {
                    services: selectServices,
                    quantitySelectServices: prevState.quantitySelectServices + 1
                }
            });
        } else {
            this.setState(prevState => {
                return {
                    services: selectServices,
                    quantitySelectServices: prevState.quantitySelectServices - 1
                }
            });
        }
    };

    // set user position when ymaps init

    setUserCoordinates = (coordinates) => {
        const coordinatesStr = `${coordinates[0]},${coordinates[1]}`;

        this.setState({
            userCoordinates: coordinatesStr
        })
    };

    // clear filter

    handlerClearFilter = (event) => {
        event.preventDefault();
        
        const services = [...this.state.services];

        services.map((service, index) => {
            services[index].checked = false
        });

        this.setState({
            services: services,
            quantitySelectServices: 0
        });
    }

    render() {
        let {
            onSubmit,
            address,
            addressAutoComplete,
            inProgress,
            showTabs,
            typeMap,
            isMnpOffices,
            mnpPhoneData,
        } = this.props;

        let selectedRegion = this.context.selectedRegion;

        return  <div className={`page__map-block ${isMnpOffices ? 'page__map-block-full-page' : ''}`}>
                    <div className="page__map">
                        <div className="b-map b-map_adapt_default js-init">
                            <div className="b-map__wrapper">
                                <YMap
                                    items={this.state.itemsShow.length > 0 ? this.state.itemsShow : this.state.items}
                                    card={this.state.card}
                                    setOfficeCardData={this.setOfficeCardData}
                                    handlerCloseCard={this.handlerCloseCard}
                                    zone={this.state.zone}
                                    typeMap={typeMap}
                                    tab={this.state.tab}
                                    coverCoords={this.props.coverCoords}
                                    selectedRegion={this.context.selectedRegion}
                                    setUserCoordinates={this.setUserCoordinates}
                                    typeUpdate={this.state.typeUpdate}
                                    loadMore={this.props.loadMore}
                                    duplicates={this.props.duplicates}
                                    isMnpOffices={isMnpOffices}
                                    mnpPhoneData={mnpPhoneData}
                                />

                                {
                                    // for type map - "Zone coverage"
                                    // for type map - "Get sim"
                                    // hide

                                    typeMap !== 'zone' && typeMap !== 'sim'
                                        ?
                                            <React.Fragment>
                                                {
                                                    this.state.card.id !== 0
                                                    ?
                                                    <OfficeCard inProgress={inProgress}
                                                                onSubmit={onSubmit}
                                                                closeCardFunc={this.closeCard}
                                                                item={this.state.card}
                                                                userCoordinates={this.state.userCoordinates}
                                                                isMnpOffices={isMnpOffices}
                                                    />
                                                    : ''
                                                }
                                                <OfficeData items={this.state.itemsShow.length ? this.state.itemsShow : this.state.items}
                                                            onClick={this.setOfficeCardData}
                                                            address={address}
                                                            chooseAddress={this.chooseAddress}
                                                            addressAutoComplete={addressAutoComplete}
                                                            zone={this.state.zone}
                                                            showZonesHandler={this.showZonesHandler}
                                                            showTabs={showTabs}
                                                            handlerChangeTab={this.handlerChangeTab}
                                                            handlerCheckbox={this.handlerCheckbox}
                                                            selectOfficesByFilter={this.selectOfficesByFilter}
                                                            services={this.state.services}
                                                            typeMap={typeMap}
                                                            quantitySelectServices={this.state.quantitySelectServices}
                                                            handlerClearFilter={this.handlerClearFilter}
                                                            activeKey={this.state.activeKey}
                                                            closeCard={this.closeCard}
                                                            isMnpOffices={isMnpOffices}
                                                            isMotivCoverMap={!!this.props.isMotivCoverMap}
                                                />
                                            </React.Fragment>
                                        :   null
                                }

                                {
                                    // for type map - "Zone coverage"

                                    typeMap === 'zone'
                                        ?
                                        <div className="b-map__search-block">
                                            <AutoComplete
                                                className="b-map__search-input"
                                                inputOptions={{
                                                    placeholder: "Поиск города или адреса"
                                                }}
                                                options={normalizeForSelect(address)}
                                                getOptions={(val) =>
                                                {
                                                    if (val.length >= 3 && typeMap === 'mnp') {
                                                        addressAutoComplete('addressFull',
                                                            val,
                                                            {
                                                                region: selectedRegion.name.substr(0, selectedRegion.name.indexOf(" ")),
                                                                regionId: selectedRegion.id
                                                            });
                                                    }
                                                    else if (val.length >= 3 && typeMap !== 'mnp') {
                                                        addressAutoComplete('addressFull', val,
                                                        {
                                                            regionId: selectedRegion.id,
                                                            allRegions: 1
                                                        });
                                                    }
                                                }
                                                }
                                                onChange={this.chooseAddress}
                                            />
                                        </div>
                                        : null
                                }

                                {
                                    // for type map - "Get sim"

                                    typeMap === 'sim'
                                        ?
                                        <React.Fragment>
                                            {
                                                this.state.card.id !== 0
                                                    ?
                                                    <OfficeCardMap
                                                        item={this.state.card}
                                                        closeCard={this.closeCard}
                                                    />
                                                    : null
                                            }
                                        </React.Fragment>
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
    }
}

class YMap extends Component{

    constructor(props){
        super(props);

        //let coverGsm = props.coverCoords ? props.coverCoords.filter(x => x.type === 'gsm') : [];
        //let coverLte = props.coverCoords ? props.coverCoords.filter(x => x.type === 'lte') : [];

        let coords = [];

        if ( props.coverCoords ) {
            coords = props.coverCoords;
        }

        let items = props.items.filter(x => x.coordinates !== '');
        this.state = {
            bounds: props.isMnpOffices ? this.getBounds(items, true) : this.getBounds(items),
            controls: [],
            coverCoords: {
                gsm: coords,
                lte: []
            }
        };

        this._mapElement = null;
        this._yMap = null;
        this._multiRoute = null;
        this._GSMCollection = null;
        this._LTECollection = null;
        this._polygonIds = [];
        this._geoObjectsAdded = false;
        this._showed = false;
    }

    getCoords = (coordStr) => {
        let coords =  coordStr.split(',');
        return [Number(coords[0].trim()), Number(coords[1].trim())];
    };

    getBounds = (items, isInitMnpMap = false) => {
        var minLon = 1000000, minLat = 1000000, maxLon = 0, maxLat = 0;

        // filter shops by region
        let filteredItems = isInitMnpMap ? items.filter(x => x.region_id == this.props.mnpPhoneData.regionId) : items.filter(x => x.region_id === this.props.selectedRegion.id);

        if (filteredItems.length === 0) {
            filteredItems = items;
        }

        filteredItems.map((item) => {
            let coords = this.getCoords(item.coordinates);
            if (coords[0] < minLon) minLon = coords[0];
            if (coords[0] > maxLon) maxLon = coords[0];
            if (coords[1] < minLat) minLat = coords[1];
            if (coords[1] > maxLat) maxLat = coords[1];
        });
        var delta = 0.02;
        return [[minLon - delta, minLat - delta], [maxLon + delta, maxLat + delta]];
    };

    getPointBounds = (coords) => {
        let delta = 0.005,
            coordinates;

        if (this.props.typeMap === 'sim') {
            coordinates = [[coords[0] - delta, coords[1] - delta], [coords[0] + delta, coords[1] + delta]];
        } else {
            coordinates = [[coords[0] - delta, coords[1] - delta], [coords[0] + delta, coords[1] + 8*delta]];
        }

        return coordinates;
    };

    componentWillReceiveProps(nextProps){
        if (this.props.items != nextProps.items && nextProps.typeUpdate != 'filter') {
            let items = nextProps.items.filter(x => x.coordinates !== '');
            this.setState({
                bounds: this.getBounds(items)
            });
        }
        if (this.props.card != nextProps.card) {
            if (nextProps.card.id && nextProps.card.coordinates) {
                this.setState({bounds: this.getPointBounds(this.getCoords(nextProps.card.coordinates))});

                // get coordinates user

                this._yMap.geolocation.get()
                    .then(result => {
                        this.props.setUserCoordinates(result.geoObjects.position);
                    })
            }
        }
        if (this.props.coverCoords !== nextProps.coverCoords || this.props.tab != nextProps.tab || this.props.typeMap != nextProps.typeMap || this.props.zone != nextProps.zone) {
            if (nextProps.tab === 'zone' || nextProps.typeMap === 'zone') {
                if (nextProps.zone) {
                    this.addAndRemoveZone(nextProps.zone);
                }
                else {
                    this.addAndRemoveZone(this.props.zone);
                }
            } else {
                this.removeAllZone();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if (this.props.coverCoords !== nextProps.coverCoords) return true;
        if (this.state != nextState) return true;
        if (this.props.card.id !== nextProps.card.id) return true;
        if (this.props.zone !== nextProps.zone) return true;
        if (this.props.tab != nextProps.tab) return true;
        if (this.props.typeMap !== nextProps.typeMap) return true;
        if (this.props.items.length != nextProps.items.length) return true;
        if (nextProps.items.length && this.props.items[0].id != nextProps.items[0].id) return true;
        return false;
    }

    handlerZoomOut = () => {
        let zoom = this._mapElement.getZoom();

        if (zoom > 1)
            this._mapElement.setZoom(zoom - 1);

    };

    handlerZoomIn = () => {
        let zoom = this._mapElement.getZoom();

        if (zoom < 10)
            this._mapElement.setZoom(zoom + 1);
    };

    handleApiAvaliable = ymaps => {
        this._yMap = ymaps;
        this._GSMCollection = new this._yMap.GeoObjectCollection();
        this._LTECollection = new this._yMap.GeoObjectCollection();
    };
    
    handlerInitMap = () => {
        if (this.props.typeMap === 'zone') {
            this.addAndRemoveZone(this.props.zone);
        }
    };

    addAndRemoveZone = (zone) => {
        if (this._yMap && this._mapElement) {
            if (!this._geoObjectsAdded) {
                this._mapElement.geoObjects.add(this._GSMCollection);
                this._mapElement.geoObjects.add(this._LTECollection);
                this._geoObjectsAdded = true;
            }
            this.props.coverCoords.forEach(element => {
                this.updatePolygons(element.region_id, element.type, element.coords);
            });

            if (this.props.loadMore) {
                this.props.loadMore();
            }

            this.setCoverVisible(false, zone);
        }
    };

    setCoverVisible = (forceHide, zone) => {
        if (this._GSMCollection) {
            this._GSMCollection.options.set({ 'visible': forceHide ? false : zone.gsm });
        }
        if (this._LTECollection) {
            this._LTECollection.options.set({ 'visible': forceHide ? false : zone.lte })
        }
    }

    checkDuplicates = (regionId, type, allIds, duplicates) => {
        if (duplicates.indexOf(regionId) === -1) {
            return false;
        }
        for (var i = 0; i < duplicates.length; i++) {
            if (regionId != duplicates[i] && allIds.indexOf(type + duplicates[i]) > -1) {
                return true;
            }
        }
        return false;
    }

    updatePolygons = (regionId, type, coords) => {
        const id = type + regionId;
        if (this._polygonIds.indexOf(id) === -1 && !this.checkDuplicates(regionId, type, this._polygonIds, this.props.duplicates)) {
            const color = type === 'gsm' ? '#F37021' : '#5897FB';
            const zIndex = type === 'gsm' ? 0 : 1;
            const newPolygon = this.createPolygons(color, coords, zIndex);
            this._polygonIds.push(id);
            this._showed = true;
            if (type === 'gsm') {
                this._GSMCollection.add(newPolygon);
            } else {
                this._LTECollection.add(newPolygon);
            }
        }
    }

    createPolygons = (color, coords, zIndex) => {
        return new this._yMap.Polygon(
            coords,
            {},
            {
                fillColor: color,
                strokeWidth: 0,
                opacity: 0.5,
                zIndex: zIndex
            }
        );
    }


    removeAllZone = () => {
        this.setCoverVisible(true, null);
    };

    render(){
        let items = this.props.items.filter(x => x.coordinates !== '');
        let {
            card,
            setOfficeCardData,
            handlerCloseCard,
            typeMap,
            tab
        } = this.props;

        return <div className="b-map__map">
            <div className="b-map__map-self">
                <YMaps
                    onApiAvaliable={ymaps => this.handleApiAvaliable(ymaps)}
                >
                    <Map state={
                            this.state
                         }
                         width={"100%"}
                         height={"100%"}
                         instanceRef={ref => {this._mapElement = ref; this.handlerInitMap();}}
                         options={{ restrictMapArea: [[82.366481, -35.039568],[-62.425996, -133.828630]]}}
                         onWheel={handlerCloseCard}
                    >

                        <GeolocationControl
                            options={{
                                position: {
                                    left: '20px',
                                    top: 'calc(50vh - 60px)'
                                }
                            }}
                        />

                        <Button
                            data={{
                                image: mapMinus
                            }}

                            options={{
                                position: {
                                    left: '20px',
                                    top: 'calc(50vh + 60px)'
                                },
                                selectOnClick: false
                            }}

                            onClick={this.handlerZoomOut}
                        />

                        <Button
                            data={{
                                image: mapPlus
                            }}

                            options={{
                                position: {
                                    left: '20px',
                                    top: '50vh'
                                },
                                selectOnClick: false
                            }}

                            onClick={this.handlerZoomIn}
                        />



                        {
                            // for type map - "Zone coverage"
                            // for tab - "Zone coverage"
                            // for show route
                            // hide PlaceMarks

                            typeMap !== 'zone' && tab !== 'zone' ?
                                <Clusterer
                                    options={{
                                        clusterize: true,
                                        gridSize: 64,
                                        preset: 'islands#orangeClusterIcons',
                                        groupByCoordinates: false,
                                        hasBalloon: false
                                    }}
                                >
                                    {items.map((point) =>
                                        <Placemark
                                            key={point.id}
                                            geometry={{
                                                type: 'Point',
                                                coordinates: this.getCoords(point.coordinates)
                                            }}
                                            options={{
                                                iconLayout: 'default#image',
                                                iconImageHref: point.id === card.id ? objectMapSelected : objectMap,
                                                iconImageSize: point.id === card.id ? [49, 60] : [33, 40],
                                                iconImageOffset: [-16, -20],
                                                hasBalloon: false
                                            }}
                                            onClick={() => setOfficeCardData(point)}
                                        />
                                    )}
                                </Clusterer> : null
                        }

                    </Map>
                </YMaps>
            </div>
        </div>
    }
}