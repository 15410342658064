import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

export function sms(phone) {
    return {
        type: 'SMS',
        phone
    }
}

export function* fetchSms(action) {

    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/sms?abnum=" + action.phone.replace(/\D/gim, '').slice(1), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function verifySms(verify_code, query_id) {
    return {
        type: 'VERIFY_SMS',
        verify_code,
        query_id
    }
}

export function* fetchVerifySms(action) {
    try {
        let body = new FormData();

        body.append('verify_code', action.verify_code);
        body.append('query_id', action.query_id);

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/verify-code", {
                method: 'POST',
                body
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function clearSmsData() {
    return {
        type: 'CLEAR_SMS_DATA'
    }
}