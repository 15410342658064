import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {otherPaymentMethods, paymentMethods, paymentsMethodPageAction} from "../../actions/paymentsTransfers"
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import PaymentMethodCategories from "../../components/PaymentsTransfers/PaymentMethodCategories";
import SmallMenu from "../../../js/components/PaymentsTransfers/SmallMenu";

class PaymentsMethods extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clicked: 0,
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentDidMount(){
        if (!this.props.paymentsTransfers.length){
            this.props.paymentMethods()
        }

        if (!this.props.paymentMethod.length){
            this.props.paymentsMethodPageAction();
        }
    }

    componentWillMount(){
        if (!this.props.paymentMethod.length){
            this.props.paymentsMethodPageAction();
        }
    }

    isOpen(index) {
        if (this.state.clicked === index){
            this.setState({            
                clicked: null
            });
        }else{
            this.setState({            
                clicked: index
            });
        }
    }

    render() {

        let {
            clicked
        } = this.state

        let {
            paymentsTransfers,
            routes,
            paymentMethod
        } = this.props

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/payments-transfers',
                title: "Платежи"
            },
            {
                url: "",
                title: 'Способы оплаты'
            }
        ];

        let data = paymentsTransfers && paymentsTransfers.paymentMethods

        //получаем нужный нам SEO {}
        let routeSeo = routes && routes.list.find(x => x.sys_name === 'paymentsMethodsRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        {paymentMethod && paymentMethod.map((item) => 
                            <React.Fragment key={item.id}>
                                <h1 className="payments-page__title">{item.title}</h1>                                
                            </React.Fragment>
                        )}

                        <div className="payments-methods__block">
                            {data && data.map((item, index) =>
                                <React.Fragment key={item.category.id}>
                                    <PaymentMethodCategories 
                                        item={item}
                                        index={index}
                                        onClick={() => this.isOpen(index)}
                                        active={clicked === index}
                                    />
                                </React.Fragment>
                            )}
                        </div>


                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <SmallMenu 
                                // при назначении держит открытое меню
                                flag={'payments-methods'}
                                // при назначении ищет и подчеркивает ссылку
                                flagSubMenu={'payments-methods'}
                                // title в мобильном меню
                                customTitleMenu={'Способы оплаты'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        paymentsTransfers: state.paymentsTransfers,
        paymentMethod: state.paymentsTransfers.paymentMethod,
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            otherPaymentMethods: () => dispatch(otherPaymentMethods()),
            paymentMethods: () => dispatch(paymentMethods()),
            paymentsMethodPageAction: () => dispatch(paymentsMethodPageAction()),
        }
    }
)(PaymentsMethods);