import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import InfoBlock from "../../components/common/InfoBlock";
import {connect} from "react-redux";
import RatesRoamingSearch from "../../components/Rates/RatesRoamingSearch";
import RatesRoamingTabs from "../../components/Rates/RatesRoamingTabs";
import RateServiceListItem from "../../components/Rates/RateServiceListItem";
import RatesFaq from "../../components/Rates/RatesFaq";
import {infoBlocksList} from "../../actions/infoBlocks";
import {faqList} from "../../actions/questions";
import {servicesList} from "../../actions/services";
import PropTypes from "prop-types";
import {
    callingSearch,
    roamingSearch,
    roamingSearchPlaces,
    callingSearchPlaces,
    roamingClear, getRoamingPlaceByAlias, getRoamingMemos
} from "../../actions/roaming";
import RatesExternalCallsResult from "../../components/Rates/RatesExternalCallsResult";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import RatesAdditionalServices from "../../components/Rates/RatesAdditionalServices";
import {filterServices} from "../../tools/tools";
import {tariffsList} from "../../actions/tariffs";
import {services} from "../../actions/client";
import {settingsList} from "../../actions/settings";
import {getQueryParams} from "../../tools/url-helper";
import {withRouter} from "react-router-dom";

const faqCatIGo = 4;
const faqCatIGoRussia = 50;
const faqCatICall = 5;
const faqCats = faqCatIGo + ',' + faqCatICall + ',' + faqCatIGoRussia;

class RatesRoaming extends Component {

    constructor(props) {
        super(props);

        let url = this.props.match.path.split('/');
        let activeTab = 0;
        switch (url[url.length - 1]) {
            case 'roaming':
                activeTab = 1;
                break;
        }

        this.state = {
            activeTab,
            inSearchIGo: false,
            inSearchICall: false,
        };
    }

    setInSearchIGo = () => {
        this.setState({inSearchIGo: true});
    }

    setInSearchICall = () => {
        this.setState({inSearchICall: true});
    }

    onToggleTab = (value) => {
        return (e) => {
            e.preventDefault();
            this.props.roamingClear();
            let url = '/' + this.context.selectedRegion.alias + '/roaming-i-mezhdugorodnyaya-svyaz';
            switch (value) {
                case 1:
                    url += '/roaming';
                    break;
            }
            this.context.pushHistory(url, {doNotScroll: true});
            setTimeout(() => {
                let tabsOffsetTop = document.getElementById("roaming-tabs").offsetTop;
                if (window.scrollY > tabsOffsetTop) {
                    window.scrollTo(0, document.getElementById("roaming-tabs").offsetTop - 40)
                }
            }, 100)
        }
    };

    fetchRoamingSearch = (region, regionId, international) => {
        this.props.getRoamingSearchResult(region, regionId, international);
    };

    fetchRoamingSearchPlaces = (search, international) => {
        this.props.getRoamingSearchPlaces(search, international)
    }

    static contextTypes = {
        isGuest: PropTypes.bool,
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    // для ssr
    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'roaming');
        }
        if (this.props.services.servicesLists.length === 0) {
            this.props.getServices(regionId, 'rank_filter');
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(regionId, faqCats); //роуминг
        }
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(regionId);
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
        if (!this.props.settings || !this.props.settings.roamingMainText) {
            this.props.getRoamingSettings();
        }

        if (typeof window !== 'undefined') {
            const params = getQueryParams(window.location.search);
            if (params.region) {
                this.props.getRoamingPlace(params.region)
                setTimeout(() => window.scrollTo(0, document.getElementById("roaming-tabs").offsetTop - 40), 1000)
            }
        }
    }

    componentDidMount() {
        let regionId = this.context.selectedRegion.id;

        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'roaming');
        }
        if (this.props.services.servicesLists.length === 0) {
            this.props.getServices(regionId, 'rank_filter');
        }
        if (this.props.faq.list.length === 0) {
            this.props.getFaq(regionId, faqCats); //роуминг
        }
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(regionId);
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
        this.props.getRoamingMemos(this.state.activeTab)
    }

    render() {
        const {regions, location, roaming} = this.props;
        let activeTab = this.state.activeTab;
        let servicesIGo = filterServices(this.props.services.servicesLists, 'rank_roaming');
        let servicesIGoRussia = filterServices(this.props.services.servicesLists, 'rank_roaming_russia');
        let faqListIGo = this.props.faq.list.filter(x => x.category_id === faqCatIGo);
        let faqListIGoRussia = this.props.faq.list.filter(x => x.category_id === faqCatIGoRussia);
        let infoBlocks = this.props.infoBlocks.list;
        let roamingSearchResult = this.props.roaming.roamingSearchList;
        let region = this.context.selectedRegion.alias;
        let {
            roamingMainText,
            roaming_i_go_russia_example_regions,
            roaming_i_go_example_regions,
            roaming_i_call_example_regions,
            roamingFooterTextIGoRussia,
            roamingFooterTextIGo,
            roamingFooterTextICall
        } = this.props.settings;

        let currentPageRoaming = {
            url: "/" + region + "/roaming-i-mezhdugorodnyaya-svyaz/roaming",
            title: "Роуминг и международная связь",
        };

        let currentPageCalls = {
            url: "/" + region + "/roaming-i-mezhdugorodnyaya-svyaz",
            title: "Роуминг и международная связь",
        };

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = !activeTab ?
            this.props.routes.list.find(x => x.sys_name === 'roamingIMezhdugorodnyayaSvyazRoute') :
            this.props.routes.list.find(x => x.sys_name === 'roamingIMezhdugorodnyayaSvyazRoamingRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_roaming pp-mod_roaming-search"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {
                            "name": "keywords",
                            "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                        }
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container">
                    <h1 className="info-page-container__title">Роуминг</h1>
                    <div className="page__roaming">
                        {roamingMainText ? <div className="legal-information-page__description"
                                                dangerouslySetInnerHTML={{__html: roamingMainText}}/> : null}
                        <div className="b-roaming b-roaming_adapt_default">
                            <InfoBlock type={'big-carousel'} data={infoBlocks} section={'roaming_main_banner'}/>
                            <div className="b-internet-ipad__roaming-list" id="roaming-tabs">
                                <div className="b-internet-ipad__tabs-header">
                                    <TabsSwitcher items={[{title: "Поездки по России"}, {title: "Поездки по миру"}]}
                                                  onClickFunc={this.onToggleTab} activeTab={activeTab}/>
                                </div>
                            </div>
                            <ul className="tabs__content" ref="content">
                                <li className={"tabs-content__item tabs-content__item--active"}>
                                    <div className="b-internet-ipad__tariffs-table">
                                        <div className="b-tariffs-table b-tariffs-table_adapt_default">
                                            <h2 className="page__title-2">Звонки во время путешествий
                                                по {activeTab === 0 ? 'России' : 'миру'}</h2>
                                            <div className="page__subtitle">Напишите
                                                название {activeTab === 0 ? 'города' : 'страны'}, в
                                                которо{activeTab === 0 ? 'м' : 'й'} вы находитесь, и рассчитайте
                                                стоимость звонка домой.
                                            </div>
                                            <RatesRoamingSearch
                                                placeholder={"Укажите " + (activeTab === 0 ? 'город' : 'страну')}
                                                defaultValue={this.props.roaming.placeFromUrl}
                                                setInSearch={this.setInSearchIGo}
                                                searchItemsMethod={(search) => this.fetchRoamingSearchPlaces(search, activeTab === 0 ? 0 : 1)}
                                                searchMethod={(region, regionId) => this.fetchRoamingSearch(region, regionId, activeTab === 0 ? 0 : 1)}
                                                searchItems={this.props.roaming.roamingSearchPlacesList}
                                                exampleRegions={activeTab === 0 ? roaming_i_go_russia_example_regions : roaming_i_go_example_regions}
                                            />
                                            {
                                                roamingSearchResult.length > 0 || this.state.inSearchIGo
                                                    ? <RatesRoamingTabs inSearch={this.state.inSearchIGo}
                                                                        tabs={roamingSearchResult}
                                                                        isOk={this.props.roaming.roamingFirstRequest}/>
                                                    : ''
                                            }
                                            {
                                                roamingSearchResult.length > 0 && ((activeTab === 0 && servicesIGoRussia.length) || (activeTab === 1 && servicesIGo.length))
                                                    ? <RatesAdditionalServices title={"Дополнительные услуги"}
                                                                               items={(activeTab === 0 ? servicesIGoRussia : servicesIGo).slice(0, 3)}/>
                                                    : ''
                                            }
                                            {
                                                roamingSearchResult.length === 0 && ((activeTab === 0 && servicesIGoRussia.length) || (activeTab === 1 && servicesIGo.length))
                                                    ? <div className="b-roaming__options-table">
                                                        <div className="page__title-3">Услуги для роуминга</div>
                                                        <div className="b-options-table b-options-table_adapt_default">
                                                            <div className="b-options-table__wrapper">
                                                                <div className="b-options-table__items-area">
                                                                    {(activeTab === 0 ? servicesIGoRussia : servicesIGo).map((item, key) =>
                                                                        <RateServiceListItem
                                                                            currentPage={currentPageRoaming} item={item}
                                                                            key={key}/>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <div className="page__hr"></div>
                                            }
                                            <div>
                                                {
                                                    (roaming.roamingMemos && roaming.roamingMemos.length > 0) &&
                                                    <div className="b-roaming__options-table">
                                                        {
                                                            this.state.activeTab === 0 ?
                                                                <div className="page__title-3">Памятка для абонентов при
                                                                    поездке
                                                                    по России</div>
                                                                :
                                                                <div className="page__title-3">Памятка для абонентов при
                                                                    поездке
                                                                    за границу</div>
                                                        }
                                                        <div className="roaming-memos-list">
                                                            {
                                                                roaming.roamingMemos && roaming.roamingMemos.map(file => (
                                                                    <a className="roaming-memos" href={file.path}
                                                                       target="_blank">
                                                                        {
                                                                            (file.extension === 'pdf') ? (
                                                                                    <span
                                                                                        className="roaming-memos-icon roaming-memos-icon--family"/>
                                                                                )
                                                                                : (
                                                                                    <svg
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="131 77 32 44"
                                                                                        width="28" height="40"
                                                                                        className="roaming-memos-icon__doc">
                                                                                        <path
                                                                                            d="M148.01 79.16L160.94 92.09L160.94 118.93L132.11 118.93L132.11 79.37L148.01 79.16Z"
                                                                                            stroke="#000000" strokeWidth="2"></path>
                                                                                        <path
                                                                                            d="M160.94 92.09L148.01 92.09L148.01 79.16"
                                                                                            stroke="#000000" strokeWidth="2"></path>
                                                                                    </svg>
                                                                                )
                                                                        }
                                                                        <span className="roaming-memos-desc">
                                                                    <span
                                                                        className="roaming-memos-desc_title">{file.filename}</span>
                                                                    <span
                                                                        className="roaming-memos-desc_size">(размер: {file.size})</span>
                                                                </span>
                                                                    </a>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="b-roaming__list-collapser">
                                                {
                                                    faqListIGoRussia && activeTab === 0
                                                        ? <RatesFaq items={faqListIGoRussia} title={"Полезные советы"}/>
                                                        : ''
                                                }
                                                {
                                                    faqListIGo && activeTab === 1
                                                        ? <RatesFaq items={faqListIGo} title={"Полезные советы"}/>
                                                        : ''
                                                }
                                            </div>
                                            {activeTab === 0 && roamingFooterTextIGoRussia ?
                                                <div className="b-roaming__add-info"
                                                     dangerouslySetInnerHTML={{__html: roamingFooterTextIGoRussia}}/> : null}
                                            {activeTab === 1 && roamingFooterTextIGo ?
                                                <div className="b-roaming__add-info"
                                                     dangerouslySetInnerHTML={{__html: roamingFooterTextIGo}}/> : null}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default withRouter(connect(
    state => ({
        services: state.services,
        infoBlocks: state.infoBlocks,
        faq: state.faq,
        roaming: state.roaming,
        tariffs: state.tariffs,
        settings: state.settings,
        client: state.client,
        regions: state.regions,

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getTariffs: (regionId) => dispatch(tariffsList(regionId)),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getFaq: (regionId, categoryId) => dispatch(faqList(regionId, categoryId)),
            getServices: (regionId, categoryRankField) => dispatch(servicesList(regionId, categoryRankField)),
            getRoamingSearchResult: (region, regionId, international) => dispatch(roamingSearch(region, regionId, international)),
            getExternalCallsSearchResult: (fromRegionId, tariffId, whitherRegion) => dispatch(callingSearch(fromRegionId, tariffId, whitherRegion)),
            getRoamingSearchPlaces: (search, international) => dispatch(roamingSearchPlaces(search, international)),
            roamingClear: () => dispatch(roamingClear()),
            getExternalCallsSearchPlace: (fromRegionId, tariffId, search) => dispatch(callingSearchPlaces(fromRegionId, tariffId, search)),
            getRoamingSettings: () => dispatch(settingsList("roamingMainText;roaming_i_go_russia_example_regions;roaming_i_go_example_regions;roaming_i_call_example_regions;roamingFooterTextIGoRussia;roamingFooterTextIGo;roamingFooterTextICall")),
            getMyServices: () => {
                dispatch(services())
            },
            getRoamingPlace: (alias) => dispatch(getRoamingPlaceByAlias(alias)),
            getRoamingMemos: (tabId) => dispatch(getRoamingMemos(tabId))
        }
    }
)(RatesRoaming));

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const RatesRoamingRequests = [
    {
        func: 'getServices',
        args: ['rank_filter'],
        object: 'services.servicesLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['roaming'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "roaming_main_banner"},
            {type: "roaming_text_in_search_under_tariff_select"}
        ]
    },
    {
        func: 'getFaq',
        args: [faqCats],
        object: 'faq.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getTariffs',
        args: [],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getRoamingSettings',
        args: [],
        object: 'settings.roamingMainText',
        checkField: null,
        main: true,
        withRegion: false,
        objectReceived: false
    },
];

export const RatesRoamingLkRequests = [
    {
        func: 'getMyServices',
        args: [],
        object: 'client.services',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];