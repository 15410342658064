import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {complain} from "../../actions/support";
import {formsList} from "../../actions/forms";
import {getPage, routesList} from "../../actions/routes";
import MobileNav from "../../components/Support/MobileNav";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import ComplainContent from "../../components/Rates/ComplainContent";
import RightNav from "../../components/Support/RightNav";
import ComplainForm from "../../components/Forms/ComplainForm";
import RateTariffsListContent from "../../components/Rates/RateTariffsListContent";
import {getTariffsSupportList} from "../../actions/tariffs";

class SmsTariffs extends Component {

    constructor(props) {
        super(props);
        this.timer;

        this.state = {
            stateSearchInput: "",
            winWidth: 0,
            tariffList: [],
            pagination: {
                currentPage: 1,
                pageCount: 1,
                totalCount: 1,
                perPage: 20
            },
            isShownSearchDesc: true,
            loaded: false,
        }
    }

    componentWillMount() {
        if (!this.props.support.supportTariffsSms) {
            this.props.getPage('supportTariffsSms', this.context.selectedRegion.id)
        }

        // if (typeof this.props.support.complain === "undefined") {
        //     this.props.getComplain();
        // }

        this.props.getRoutes();

        let form = this.props.forms ? this.props.forms.list.find(x => x.alias === 'appeal_form') : null;

        if (this.props.forms.list.length === 0 || !form) {
            this.props.getForms("support");
        }
    }

    componentDidMount() {
        this.setState({
                winWidth: window.innerWidth,
                pagination: {...this.state.pagination, perPage: window.innerWidth > 767 ? 20 : 5}
            },
            () => {
                this.props.getTariffsSupportList('sms', '', 1, null, this.state.pagination.perPage)
                this.setState({loaded: true})
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.stateSearchInput !== this.state.stateSearchInput && this.state.stateSearchInput.length === 0) {

            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.onSearch('')
                this.setState({isShownSearchDesc: true})
            }, 1000)
        }
        if (prevState.stateSearchInput !== this.state.stateSearchInput && this.state.stateSearchInput.length > 0 && this.state.stateSearchInput.length < 3) {

            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.setState({
                    isShownSearchDesc: false,
                    tariffList: [],
                    pagination: {
                        currentPage: 1,
                        pageCount: 1,
                        totalCount: 1,
                        perPage: 20
                    }
                })
            }, 1000)
        }
        if (prevState.stateSearchInput !== this.state.stateSearchInput && this.state.stateSearchInput.length >= 3) {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.onSearch(this.state.stateSearchInput)
                this.setState({isShownSearchDesc: false})
            }, 1000)
        }
        if (prevProps.tariffs.smsTariffs !== this.props.tariffs.smsTariffs) {
            this.setState({
                tariffList: this.props.tariffs.smsTariffs.data,
                pagination: {
                    currentPage: parseInt(this.props.tariffs.smsTariffs.pagination.currentPage),
                    pageCount: parseInt(this.props.tariffs.smsTariffs.pagination.pageCount),
                    totalCount: parseInt(this.props.tariffs.smsTariffs.pagination.totalCount),
                    perPage: parseInt(this.props.tariffs.smsTariffs.pagination.perPage)
                }
            })
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    // по клику на иконку поиска
    onSearch = (search) => {
        this.props.getTariffsSupportList('sms', search, 1, null, this.state.pagination.perPage)
    }

    setCurrentPage = (currentPage) => {
        this.setState({pagination: {...this.state.pagination, currentPage: currentPage}}, () => {
            this.props.getTariffsSupportList('sms', this.state.stateSearchInput, currentPage, null, this.state.pagination.perPage)
        })
    }

    render() {

        let {
            forms,
            support,
            isGuest,
            clientInfo,
            routes,
            regions,
            location,
            tariffs
        } = this.props;

        let {stateSearchInput, winWidth, tariffList = [], pagination} = this.state;

        let supportTariffsSms = support && support.supportTariffsSms;
        let selectedRegion = this.context.selectedRegion;
        // let complainItem = support && support.complain && support.complain.length ? support.complain[0] : {};
        let form = forms ? forms.list.find(x => x.alias === 'appeal_form') : null;

        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder header-complain");
        let supportRoute = routes ? routes.list.find(x => x.sys_name === 'supportRoute') : null;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + supportRoute.route,
                title: "Поддержка"
            },
            {
                url: "",
                title: "Тарифы на Смс-сообщения"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'supportTariffsSms');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder complain-page  main-media-bank" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {
                            "name": "keywords",
                            "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                        }
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container  motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <RatesBreadcrumbs items={breadcrumbs}/>
                                </div>
                            </div>
                            <div className="complain-txt sms-tariffs-txt">
                                {supportTariffsSms && supportTariffsSms.title ?
                                    <h1 className="complain-txt__title sms-tariffs-txt__title">{supportTariffsSms.title}</h1> : null
                                }

                                <div className="rate-tariffs-list__container">

                                    <div className='search_input'>
                                        <div className='search_input__container'>
                                            <input
                                                className='search_input__input'
                                                placeholder={`${winWidth > 767 ? 'Введите номер или название сервиса' : 'Номер или название сервиса'}`}
                                                value={stateSearchInput}
                                                // первая регулярка на запрет пробела первым символом, вторая на запрет двух и более подряд пробелов
                                                onChange={(e) => {
                                                    this.setState({stateSearchInput: e.target.value.replace(/^\s/g, '').replace(/\s+/g, ' ')})
                                                }}
                                                // maxLength={50}
                                            />
                                            <button
                                                className="search_input__icon_search"
                                            >
                                                <img src={`${require('../../../images/icons/search-gray.svg')}`}
                                                     alt="Поиск"/>
                                            </button>
                                            {
                                                stateSearchInput &&
                                                <div
                                                    onClick={() => {
                                                        this.setState({stateSearchInput: ""}, () => {
                                                            clearTimeout(this.timer)
                                                            this.onSearch('')
                                                            this.setState({isShownSearchDesc: true})
                                                        })
                                                    }}
                                                    className='search_input__icon_close'
                                                >
                                                    <img src={`${require('../../../images/icons/close-gray.svg')}`}
                                                         alt="Закрыть"/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        tariffs.loading ? (
                                            <div className="loader-container">
                                                <span className="loader"/>
                                            </div>
                                        ) : (
                                            <Fragment>
                                                {
                                                    (tariffs.isError && tariffs.smsTariffs.message) ? (
                                                        <div>{tariffs.smsTariffs.message}</div>
                                                    ) : (
                                                        <RateTariffsListContent
                                                            tariffList={tariffList}
                                                            isSmsTariffs={true}
                                                            pagination={pagination}
                                                            setCurrentPage={this.setCurrentPage}
                                                            stateSearchInput={stateSearchInput}
                                                            sibling={winWidth > 767 ? 1 : 0}
                                                            loaded={this.state.loaded}
                                                            supportTariffsSmsText={supportTariffsSms}
                                                            isShownSearch={this.state.isShownSearchDesc}
                                                        />
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <RightNav/>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        forms: state.forms,
        routes: state.routes,
        isGuest: state.client.isGuest,
        clientInfo: state.client.clientInfo,
        support: state.support,
        regions: state.regions,
        tariffs: state.tariffs
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getForms: (group) => dispatch(formsList(group)),
            // getComplain: () => dispatch(complain()),
            getPage: (page, region) => dispatch(getPage(page, region)),
            getTariffsSupportList: (tariffType, term, page, categoryId, perPage) => dispatch(getTariffsSupportList(tariffType, term, page, categoryId, perPage))
        }
    }
)(SmsTariffs);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const smsTariffsRouteRequests = [
    {
        func: 'getForms',
        args: ["support"],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];