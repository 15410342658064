import React, {Component} from 'react';

class RatesPackageConditions extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let items = this.props.items;
        let showModal = this.props.showModal;

        return (
            <div className="b-service-inner__price-list-block b-service-inner__price-list-block_btn">
                <div className="page__title-3 page__title-3_mobile-less page__title-3_pp_serv-packs">Условия</div>
                <div className="b-service-inner__price-list b-service-inner__price-list_btn">
                    <div className="b-price-list b-price-list_adapt_default b-price-list_btn">
                        <div className="b-price-list__wrapper">
                            <div className="b-price-list__list">
                                {items.map((item, key) =>
                                        <div className="b-price-list__item" key={key}>
                                            <div className="b-price-list__text">{item.name}</div>
                                                {item.subscription_fee === 0 && item.activation_price > 0 ?
                                                    <div className="b-price-list__price">{item.activation_price}&nbsp;
                                                        <span className="u-rub">₽/подкл.</span>
                                                    </div> :
                                                    <div className="b-price-list__price">{item.subscription_fee}&nbsp;
                                                        <span className="u-rub">₽</span>
                                                    </div>
                                                }
                                            <div className="b-price-list__btn">
                                                <div className="u-btn u-btn_default u-btn_sm u-btn_mobile-responsive" onClick={showModal('change-rate', item)}>Подключить</div>
                                            </div>
                                        </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesPackageConditions;