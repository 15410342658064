import React, { Component } from 'react';

class BBblock extends Component {

    render() {

        let content = this.props.content;

        return (
            <div className="b-service-inner__content-area b-service-inner__content-area_top b-service-inner__content-area_pp_si-conf">
                <div className="b-content-area" dangerouslySetInnerHTML={{__html: content}} />
            </div>
        )
    }
}

export default BBblock;