import InputIcon from "../components/common/InputIcon";
import InputError from "../components/common/InputError";
import React from "react";

/**
 * Prepare form data from fields
 * [
 *      attribute: {
 *          ...props,
 *          value: {attrVal}
 *      }
 * ]
 * @param fields
 * @returns {FormData}
 */
export function getFormData(fields) {
    var data = new FormData();

    for (var attribute in fields) {
        if (fields.hasOwnProperty(attribute)) {
            if (!fields[attribute].disabled && fields[attribute].value) {
                data.append(attribute, fields[attribute].value);
            }
        }
    }

    return data;
}

export function getObjectData(fields) {
    var data = {};

    for (var attribute in fields) {
        if (fields.hasOwnProperty(attribute)) {
            if (!fields[attribute].disabled && fields[attribute].value) {
                data[attribute] = fields[attribute].value;
            }
        }
    }

    return data;
}

export function whiteSpaceValidator(val) {
    if(val.indexOf(' ') >= 0) return false;
    return true
}

export function emailValidator (val) {

    if (!val) return true;
    // let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\")|([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/;
    // let pattern = /^(([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/;

    // Regex с возможностью поддоменов
    let pattern = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/;

    // оригинал
    // let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(val);
}

export function removeDateFormatMask(date){
    let arr = date.split('.');
    if (arr.length !== 3) return date;
    return arr[2]+'-'+arr[1]+'-'+arr[0];
}

export function phoneValidator (val) {
    if (!val) return true;
    if (val.replace(/\D/gim, '').length !== 11) {
        return false;
    }
    return true;
}

export function usernameValidator (val) {

    if (val.length < 3) {
        return false;
    }
    return true;
}

export function emptyValidator (val) {

    if (val.length === 0 || val.trim().length === 0) {
        return false;
    }
    return true;
}
export function checkedValidator (val) {

    return !!val;
}

export function emptyTextarea (val) {

    if (val === '' || val.trim().length === 0) {
        return false;
    }
    return true;
}

export function docNumberValidator (val) {

    if (val.replace(/\D/gim, '').length !== 10) {
        return false;
    }
    return true;
}

export function unitCodeValidator (val) {

    if (val.length !== 6) {
        return false;
    }
    return true;
}

export function passwordValidator (val, minLength = 10, rules = []) {

    if (val.length < minLength) return false;

    if (rules.length) {
        for (var i = 0; i < rules.length; i++) {
            var item = rules[i];
            switch (item.alias) {
                case "allow_lower_cyrillic":
                    if (item.value == 0 && /[а-я]/.test(val)) {
                        return false;
                    }
                    else break;
                case "allow_upper_cyrillic":
                    if (item.value == 0 && /[А-Я]/.test(val)) {
                        return false;
                    }
                    else break;
                case "allow_lower_latin":
                    if (item.value == 0 && /[a-z]/.test(val)) {
                        return false;
                    }
                    else break;
                case "allow_upper_latin":
                    if (item.value == 0 && /[A-Z]/.test(val)) {
                        return false;
                    }
                    else break;
                case "allow_digits":
                    if (item.value == 0 && /[0-9]/.test(val)) {
                        return false;
                    }
                    else break;
                case "allow_digits":
                    if (item.value == 0 && /[.,;:!?\-()"']/.test(val)) {
                        return false;
                    }
                    else break;
                case "required_lower_cyrillic":
                    if (item.value == 1 && !/[а-я]/.test(val)) {
                        return false;
                    }
                    else break;
                case "required_upper_cyrillic":
                    if (item.value == 1 && !/[А-Я]/.test(val)) {
                        return false;
                    }
                    else break;
                case "required_lower_latin":
                    if (item.value == 1 && !/[a-z]/.test(val)) {
                        return false;
                    }
                    else break;
                case "required_upper_latin":
                    if (item.value == 1 && !/[A-Z]/.test(val)) {
                        return false;
                    }
                    else break;
                case "required_digits":
                    if (item.value == 1 && !/[0-9]/.test(val)) {
                        return false;
                    }
                    else break;
                case "required_punctuation_marks":
                    if (item.value == 1 && !/[.,;:!?\-()"']/.test(val)) {
                        return false;
                    }
                    else break;
            }
        }
    }

    if (!rules.length && (/[А-Яа-я]/.test(val) || !/[A-Z]/.test(val) || !/[a-z]/.test(val) || !/[0-9]/.test(val))) {
        return false;
    }

    return true;
}

export function phoneAccouintValidator (val) {

    let l = val.length;

    if (l === 7 || l === 8 || l === 10) {
        return true;
    }

    return false;
}

export function fileValidator (val, allowedTypes = [], maxSize = 2*1024*1024) {
    let arr = [];
    if (Array.isArray(val)) {
        arr = val;
    } else {
        arr = [val];
    }

    let result = true;

    arr.map((val) => {
        if (val.size > maxSize || allowedTypes.length && allowedTypes.indexOf(val.type) === -1) {
            result = false;
        }
    });

    return result;
}

export function checkboxValidator (val) {
    return val;
}

export function innValidator (val) {
    if (!val) return true;
    return ((val.length === 10 || val.length === 12) && /^[0-9]+$/i.test(val));
}
export function licNumberValidator(val) {
    if (!val) return true;
    return (/^[0-9]+$/i.test(val) && val.length === 10);
}
export function dogovorNumberValidator (val) {
    if (!val) return true;
    return (/^[0-9-]+$/i.test(val) && val.length <= 12);
}

export function phoneValidatorText (val) {
    return phoneValidator(val) ? "" : "Укажите корректный номер телефона"
}

export function emailValidatorText (val) {
    if (val === "") return "";
    return emailValidator(val) ? "" : "Укажите корректный e-mail"
}

export function codeValidatorText (val) {
    return unitCodeValidator(val) ? "" : "Укажите корректный код"
}
export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function onFieldChange (name) {
    return (e) => {
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: {
                    ...this.state.fields[name],
                    value: this.state.fields[name].mask ? this.state.fields[name].mask(e.target.value) : e.target.value
                }
            }
        })
    }
}

export function onBlur (name) {
    return (e) => {
        let field = this.state.fields[name];
        if (field.hasOwnProperty('validators')) {
            let valid = true;
            field.validators.forEach(function (func) {
                if (!func.call(this, field.value)) {
                    valid = false;
                }
            });
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: !valid
                    }
                }
            })
        }
    }
}

export function onFocus (name) {
    return (e) => {
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: {
                    ...this.state.fields[name],
                    hasError: null
                }
            }
        })
    }
}

export function renderField (field) {
    let fieldConfig = {...this.state.fields[field]};

    return <div className="b-input-text__wrapper">
        <div className="b-input-text__label">
            <div className="b-input-text__label-text">{fieldConfig.label}{fieldConfig.required ?
                <span className={"orange"}>*</span> : ''}</div>
        </div>
        <div className="b-input-text__input-area">
            <input className={"b-input-text__input" + (fieldConfig.hasError ? " has-error" : "")}
                   type="text"
                   value={fieldConfig.value}
                   placeholder={fieldConfig.placeholder}
                   disabled={fieldConfig.disabled}
                   onChange={this.onFieldChange(field)}
                   onBlur={this.onBlur(field)}
                   onFocus={this.onFocus(field)}
            />
            <InputError hasError={fieldConfig.hasError} errorText={fieldConfig.textError || 'Поле обязательно для заполнения'}/>
        </div>
    </div>
}