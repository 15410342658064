import React, {Component} from "react";

export default class TooltipQuestion extends Component{

    constructor(props) {
        super(props);
        this.state = {
            state: false,
            edgeDistance: false,
        };
    }

    componentDidMount() {
        

    }

    onEnter = (e) => {
        const distance = e.target.getBoundingClientRect();

        if (distance.right < 205) {
            this.setState({ edgeDistance: true });
        } else {
            this.setState({ edgeDistance: false });
        }
        this.setState({ state: true });
        
    }

    onLeave = () => {
        this.setState({ state: false });
    }

    render(){
        let { state, edgeDistance } = this.state;
        let {
            text = '',
            typeColor = '',
            isHoveredLayer = false // флаг для того, чтобы тултип не скрывался, когда переводят курсор на него
        } = this.props;

        return (
            <div 
                className="tooltip_question"
                id="tooltip_question"
                onClick={(e) => {
                    if(!isHoveredLayer) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                onMouseLeave={() => this.onLeave()}
            >
                {isHoveredLayer && <div className="tooltip_bg" />}
                {
                    this.props.children ?
                      <div onMouseEnter={(e) => this.onEnter(e)}>{this.props.children}</div> :
                      <div
                        className={`tooltip_question__cercle ${typeColor === 'white' ? 'whitecol' : ''}${typeColor === 'black' ? 'blackcol' : ''}`}
                        onMouseEnter={(e) => this.onEnter(e)}
                      >?</div>
                }
                <div
                  className={`tooltip_question__content ${state ? 'active' : ''} ${edgeDistance ? 'distance' : ''}`}
                  dangerouslySetInnerHTML={{__html: text}}
                />
            </div>
        )
    }
}