import React, {Component} from 'react';

import InnerPageHeader from '../../components/common/InnerPageHeader';
import RatesList from '../../components/Rates/RatesList';
import Collapse from '../../components/common/Collapse';
import AdditionalServicesList from '../../components/common/AdditionalServicesList';

import MyTariffReminder from "../../components/Rates/MyTariffReminder";
import RecommendedServices from "../../components/Rates/RecommendedServices";
import {tariffIndividualList} from "../../actions/tariffs";
import {connect} from "react-redux";
import Tooltip from "../../components/common/Tooltip";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SidebarHeadInfo from "../../components/common/SidebarHeadInfo";
import Page404 from "../Page404";
import {Route} from "react-router-dom";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import DownloadDoc from "../../components/common/DownloadDoc";
import LinkC from "../../components/common/LinkC";
import {MySidebarInfo} from "../../components/Lk/DetailTafiff";
import {constuctorUrl, mainUrl, mediaUrl} from "../../tools/config";
import {isEmptyObj} from "../../tools/tools";
import {postTariffConstructor, setTariffParams, settings} from "../../actions/constructor";
import Icon from "../../components/common/Icon";


class RateTariffIndividual extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tariff: {},
            isMine: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        setNotFound: PropTypes.func
    };

    componentWillMount() {
        this.tariffsState();
        this.props.getTariffsIndividual();

        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }
    }

    tariffsState = () => {
        let isMine = !this.props.isGuest && this.props.clientInfo.tariff && this.props.clientInfo.region && this.context.selectedRegion.alias == this.props.clientInfo.region.alias && this.props.match.params.slug == this.props.clientInfo.tariff.slug;
        let tariff = this.props.tariffs.individualTariffsList.find(x => x.region_id === this.context.selectedRegion.id && x.slug === this.props.match.params.slug);
        this.setState({isMine, tariff});
        if (isMine) {
            this.getLink()
        }
    }

    getLink = () => {
        let details = this.props.clientInfo.tariff && this.props.clientInfo.tariff.details;
        if (details) {
            let {
                is_data_unlim,
                is_ivi,
                is_motiv_voice_unlim,
                is_socnet_unlim,
                is_yotube_unlim,
                minutes,
                router,
                sms,
                traffic
            } = details;
            let options = [];
            if (is_data_unlim) {
                options.push('DATA_UNLIM');
                if (router) {
                    options.push('ROUTER')
                }
            }
            if (is_motiv_voice_unlim) {
                options.push('MVOICE_UNLIM')
            }
            if (is_socnet_unlim) {
                options.push('SOCIAL_UNLIM')
            }
            if (sms) {
                options.push('SMS_PACK')
            }
            if (is_yotube_unlim) {
                options.push('YOUTUBE_UNLIM')
            }
            if (is_ivi) {
                options.push('IVI')
            }
            // this.props.postTariffConstructor({
            //     min: minutes || 0,
            //     data: is_data_unlim || !traffic ? 0 : traffic,
            //     options
            // }, this.context.selectedRegion.id);
            this.passingParams({
                min: minutes || 0,
                data: is_data_unlim || !traffic ? 0 : traffic,
                options
            }, this.context.selectedRegion.id);
        }
    }

    async passingParams(tariff, regionId) {
        await this.props.setTariffParams(tariff, regionId);
        await this.props.postTariffConstructor(tariff, regionId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.tariffs.individualTariffsList.length && this.props.tariffs.individualTariffsList.length) {
            this.tariffsState();
        }
        if (prevProps.tariffConstructor.recalcData !== this.props.tariffConstructor.recalcData) {
            this.setState({
                tariff: {
                    ...this.state.tariff,
                    description_url: this.props.tariffConstructor.recalcData.pdf_file
                }
            })
        }
        if ((!prevProps.clientInfo.tariff && this.props.clientInfo.tariff) || (!prevProps.clientInfo.region && this.props.clientInfo.region)) {
            let isMine = !this.props.isGuest && this.props.clientInfo.tariff && this.props.clientInfo.region && this.context.selectedRegion.alias == this.props.clientInfo.region.alias && this.props.match.params.slug == this.props.clientInfo.tariff.slug;
            this.setState({
                isMine
            })
            if (isMine) {
                this.getLink();
            }
        }
    }

    getDescription = () => {
        let details = this.props.clientInfo.tariff && this.props.clientInfo.tariff.details;
        let remains = this.props.clientInfo.remains;
        let settings = this.props.settings;
        let options = {};
        settings && settings.options_names.map((option) => {
            options[option.alias] = option.value;
        })
        const detailsToOptions = {
            router: options.router,
            is_socnet_unlim: options.social_network_unlim,
            is_motiv_voice_unlim: options.motiv_voice_unlim,
            is_yotube_unlim: options.youtube_unlim,
            is_ivi: options.ivi,
            home_region_checked: options.everywhere_at_home
        }
        let detailsDescription = [];

        if (details) {
            if (details.traffic > 0) {
                detailsDescription.push(details.traffic + " ГБ");
            }
            if (typeof details.minutes !== 'undefined') {
                detailsDescription.push((details.minutes || 0) + " минут");
            }

            for (var attr in detailsToOptions) {
                if (details[attr]) {
                    detailsDescription.push(detailsToOptions[attr])
                }
            }

            if (details.sms > 0) {
                detailsDescription.push(details.sms + " sms");
            }
        } else {
            remains && remains.map((remain) => {
                detailsDescription.push(remain.total_amount === "Безлимит" ? "безлимитны"+(remain.type === "DATA" ? "й" : "е")+" "+remain.title.toLowerCase() : remain.total_amount + " " + remain.unit);
            })
        }

        return <React.Fragment>
            {details && details.is_data_unlim 
                ? <React.Fragment><Icon name={'icon_unlimTariff'} className={'unlim-modal u-icon u-icon_logo unlim-tariff-info'}/> ГБ, </React.Fragment> 
                : ""
            }{detailsDescription.join(', ')}
        </React.Fragment>
    }

    render() {

        let selectedRegion = this.context.selectedRegion;

        let {tariff, isMine} = this.state;

        if (typeof tariff === 'undefined') {
            return (
                this.props.tariffs.isOk ? <Route component={Page404} /> : <main className="page__main-holder" role="main"/>
            )
        }

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/tariffs",
                title: "Тарифы"
            },
            {
                url: null,
                title: "Индивидуальные тарифы"
            },
            {
                url: null,
                title: isMine ? "Мой текущий тариф" : "Тариф «" + (tariff.name) + "»"
            }
        ];

        let {
            balance,
            paymentLink
        } = this.props.clientInfo;

        if (!tariff.name) return null;

        let slugSvoi = this.props.clientInfo && this.props.clientInfo.tariff && this.props.clientInfo.tariff.slug.indexOf('svoi');
        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        let details = this.props.clientInfo.tariff && this.props.clientInfo.tariff.details;
        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={tariff.meta_title}
                    meta={[
                        { "name": "description", "content": tariff.meta_desc },
                        { "name": "keywords", "content": tariff.meta_keywords }
                    ]}
                >
                    <link rel="canonical" href={pageLink} />
                </Helmet>
                <div className="b-service-inner b-service-inner_adapt_default">
                    <div className="b-service-inner__service-header">
                        <div className="b-inner-intro b-inner-intro_adapt_default">
                            <div style={tariff.background ? {
                                backgroundColor: tariff.background.color,
                                backgroundImage: tariff.background.url ? 'url("'+tariff.background.url+'")' : '',
                                backgroundSize: tariff.background.is_cover ? 'cover' : 'auto 100%',
                                backgroundPosition: 'center bottom',
                                backgroundRepeat: 'no-repeat'
                            } : {}} className={`b-inner-intro__wrapper b-inner-intro__wrapper_tariff ${!this.props.isGuest ? 'dop-size' : ''}`}>
                            <div className="b-inner-intro__inner page__container">
                                    <div className="b-inner-intro__header"/>
                                    <div className="b-inner-intro__main-area">
                                        <div className="b-inner-intro__main-area-wrapper">
                                            <RatesBreadcrumbs color={tariff.background ? tariff.background.breadcrumbs_color : null} items={breadcrumbs}/>
                                            <h1 style={tariff.background && tariff.background.title_color ? {
                                                color: tariff.background.title_color
                                            } : {}} className={`b-inner-intro__title${tariff.background && tariff.background.url ? ' tariff__title' : ''}`}>Тариф «{isMine && this.props.clientInfo.tariff ? this.props.clientInfo.tariff.title : tariff.name}»</h1>
                                            {isMine ? <div style={tariff.background && tariff.background.breadcrumbs_color ? {
                                                color: tariff.background.breadcrumbs_color
                                            } : {}} className="b-inner-intro__text b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                Свой тариф:&nbsp;{this.getDescription()}
                                            </div> : null}
                                            <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: tariff.short_description}}/>
                                            {isMine && !!this.props.clientInfo.tariff ? <MySidebarInfo balance={balance && balance.value}
                                                                     charge_date={this.props.clientInfo.tariff && this.props.clientInfo.tariff.charge_date}
                                                                     paymentLink={paymentLink}
                                                                     details={details}
                                                                     fee={this.props.clientInfo.tariff.fee}
                                                                     color={tariff.background && tariff.background.price_color ? tariff.background.price_color : null}
                                            /> : <SidebarHeadInfo item={tariff} color={tariff.background && tariff.background.price_color ? tariff.background.price_color : null}/>}
                                            {isMine ? <div className="b-inner-intro__btn">
                                                <LinkC className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive" to="/services/all">Настроить услуги</LinkC>
                                                    <LinkC className="b-inner-intro__link" style={tariff.background && tariff.background.link_color ? {
                                                    color: tariff.background.link_color
                                                } : {}} to={mainUrl+this.context.selectedRegion.alias+"/tariffs#constructor"}>Изменить тариф</LinkC>
                                            </div> :
                                            <div className="b-inner-intro__btn">
                                                <div
                                                    className="b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                    Индивидуальный тариф
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isMine && !!this.props.clientInfo.tariff ? <div className="page__container">
                        <MyTariffReminder tariff={{...tariff, options: this.props.clientInfo.tariff.options, details: this.props.clientInfo.tariff.details}} exchange={this.props.clientInfo.exchange}/>
                    </div> : null}
                    <div className="page__container">
                        <div className="b-with-aside-area b-with-aside-area_adapt_default">
                            <div className="b-with-aside-area__column">
                                <div className="b-service-inner__price-list-block">
                                    {tariff && Array.isArray(tariff.info) && tariff.info.map((item, key) =>
                                        <React.Fragment key={key}>
                                            <div className="page__title-3 page__title-3_mobile-less">{item.title}</div>
                                            <div className="b-service-inner__price-list">
                                                <div className="b-price-list b-price-list b-price-list_adapt_default">
                                                    <div className="b-price-list__wrapper">
                                                        <div className="b-price-list__list">

                                                            {item.items.map((item, key) =>
                                                                <div className="b-price-list__item" key={key}>
                                                                    <div
                                                                        className="b-price-list__text">{item.title}</div>
                                                                    {item.notice ?
                                                                        <Tooltip label={<span
                                                                            className="img-icon icon-info"/>}
                                                                                 openedLabel={<span
                                                                                     className="img-icon icon-info-select"/>}
                                                                                 className="numbers-tooltip">
                                                                            <p>{item.notice}</p>
                                                                        </Tooltip>
                                                                        : ''
                                                                    }
                                                                    {item.link ?
                                                                        <LinkC to={item.link} targetBlank={true}
                                                                               className="b-price-list__price"
                                                                               noFollow={true}>{item.value}</LinkC> :
                                                                        <div
                                                                            className="b-price-list__price">{item.value}</div>}
                                                                    {item.measure ? <div
                                                                        className="b-price-list__valute">&nbsp;{item.measure}</div> : null}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>

                                {slugSvoi === -1 ? <DownloadDoc
                                    url={tariff.description_url}
                                    title={"Скачать информацию о тарифе"}
                                    size={tariff.description_file_size}
                                /> : null}

                                <RatesCollapsing info_dropdown={tariff.info_dropdown}/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        isGuest: state.client.isGuest,
        clientInfo: state.client.clientInfo,
        tariffConstructor: state.tariffConstructor,
        regions: state.regions,
        settings: state.constructor.settings
    }),
    (dispatch) => {
        return {
            getTariffsIndividual: () => dispatch(tariffIndividualList()),
            postTariffConstructor: (tariff, region) => dispatch(postTariffConstructor(tariff, region)),
            setTariffParams:(tariff, regionId) => dispatch(setTariffParams(tariff, regionId)),
            getSettings: (regionId) => dispatch(settings(regionId))
        }
    }
)(RateTariffIndividual);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */

export const RatesTariffsIndividualRequests = [
    {
        func: 'getTariffsIndividual',
        args: [],
        object: 'tariffs.individualTariffsList',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    }
];