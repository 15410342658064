import React, {Component} from 'react';

export const DOTS = '...';

export default class Paginator extends Component {

    constructor(props) {
        super(props);
    }

    onNext = () => {
        this.props.onPageChange(parseInt(this.props.currentPage) + 1);
    };

    onPrevious = () => {
        this.props.onPageChange(parseInt(this.props.currentPage) - 1);
    };

    range = (start, end) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };
    paginationRange = (
        currentPage,
        totalCount,
        siblingCount = 1,
        pageSize,
        pageCount
    ) => {
        const totalPageCount = pageCount;

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return this.range(1, totalPageCount);
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = this.range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {

            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = this.range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = this.range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }

    render() {
        let {
            onPageChange,
            totalCount,
            siblingCount = 1,
            currentPage,
            pageSize,
            pageCount
        } = this.props;

        const pagRange = this.paginationRange(currentPage, totalCount, siblingCount, pageSize, pageCount)

        let lastPage = pagRange[pagRange.length - 1];

        return (
            <div
                className='paginator'
            >
                {/* Left navigation arrow */}

                <svg className={`paginator-arrow paginator-arrow-left ${currentPage == 1 ? 'paginator-arrow-disabled' : ''}`}
                     width="40" height="40" viewBox="0 0 40 40" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     onClick={currentPage > 1 ? this.onPrevious : null}
                >
                    <rect width="40" height="40" rx="20" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M21.2531 25.1641L17.2522 20.6641L16.6614 19.9996L17.2522 19.3352L21.2531 14.8352L22.7478 16.1641L19.3376 19.9996L22.7478 23.8351L21.2531 25.1641Z"
                          fill="black"/>
                </svg>
                {/*<li*/}
                {/*    className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`}*/}
                {/*    onClick={this.onPrevious}*/}
                {/*>*/}
                {/*    <div className="arrow left"/>*/}
                {/*</li>*/}
                {pagRange.map(pageNumber => {

                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        // return <li className="pagination-item dots">&#8230;</li>;
                        return <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="20" fill="white"/>
                            <path
                                d="M17.1338 25.136C17.1338 25.424 17.0378 25.6747 16.8458 25.888C16.6538 26.1013 16.3924 26.208 16.0618 26.208C15.7311 26.208 15.4698 26.1013 15.2778 25.888C15.0858 25.6747 14.9898 25.424 14.9898 25.136C14.9898 24.848 15.0858 24.5973 15.2778 24.384C15.4698 24.1707 15.7311 24.064 16.0618 24.064C16.3924 24.064 16.6538 24.1707 16.8458 24.384C17.0378 24.5973 17.1338 24.848 17.1338 25.136ZM21.0713 25.136C21.0713 25.424 20.9753 25.6747 20.7833 25.888C20.5913 26.1013 20.3299 26.208 19.9993 26.208C19.6686 26.208 19.4073 26.1013 19.2153 25.888C19.0233 25.6747 18.9273 25.424 18.9273 25.136C18.9273 24.848 19.0233 24.5973 19.2153 24.384C19.4073 24.1707 19.6686 24.064 19.9993 24.064C20.3299 24.064 20.5913 24.1707 20.7833 24.384C20.9753 24.5973 21.0713 24.848 21.0713 25.136ZM25.0088 25.136C25.0088 25.424 24.9128 25.6747 24.7208 25.888C24.5288 26.1013 24.2674 26.208 23.9368 26.208C23.6061 26.208 23.3448 26.1013 23.1528 25.888C22.9608 25.6747 22.8648 25.424 22.8648 25.136C22.8648 24.848 22.9608 24.5973 23.1528 24.384C23.3448 24.1707 23.6061 24.064 23.9368 24.064C24.2674 24.064 24.5288 24.1707 24.7208 24.384C24.9128 24.5973 25.0088 24.848 25.0088 25.136Z"
                                fill="#B9B9BA"/>
                        </svg>
                    }

                    // Render our Page Pills
                    return (
                        // <li
                        //     className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
                        //     onClick={() => onPageChange(pageNumber)}
                        // >
                        //     {pageNumber}
                        // </li>
                        <div
                            className={`paginator-num ${pageNumber == currentPage ? 'paginator-num--active' : ''}`}
                            onClick={() => onPageChange(pageNumber)}
                        >{pageNumber}</div>
                    );
                })}
                {/*  Right Navigation arrow */}
                <svg className={`paginator-arrow paginator-arrow-right ${currentPage == lastPage ? 'paginator-arrow-disabled' : ''}`}
                     width="40" height="40"
                     viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                     onClick={currentPage < lastPage ? this.onNext : null}
                >
                    <rect width="40" height="40" rx="20" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18.7469 14.8359L22.7478 19.336L23.3386 20.0004L22.7478 20.6649L18.7469 25.1648L17.2522 23.8359L20.6624 20.0004L17.2522 16.1648L18.7469 14.8359Z"
                          fill="black"/>
                </svg>
                {/*<li*/}
                {/*    className={`pagination-item ${currentPage === lastPage ? 'disabled' : ''}`}*/}
                {/*    onClick={this.onNext}*/}
                {/*>*/}
                {/*    <div className="arrow right"/>*/}
                {/*</li>*/}
            </div>
        )
    }
}
