import React, { Component } from 'react';
import InfoblockLink from "./InfoblockLink";

class InfoBlockTitleLink extends Component {

    render() {

        let block = this.props.blocks[0];

        return (
            <div className="b-offers-plates__head">
                <div className="b-offers-plates__title">{block.title}</div>
                <InfoblockLink className={"b-offers-plates__show-more"} item={block}/>
            </div>
        )
    }
}

export default InfoBlockTitleLink;