import React from "react";
import {getMeasure} from "../../tools/tools";
import TariffDescription from "../common/TariffDescription";
import LinkC from "../common/LinkC";

const B2bTariffCard = (props) => {
    const {
        tariffsLink,
        name,
        slug,
        subscription_fee,
        writeOffPeriod,
        mainCategory,
        onClickConnection
    } = props;

    const connect = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();

            onClickConnection()
        }
    }

    return <LinkC to={`${tariffsLink}/${slug}`} className="b-page__tariff-item">
        <div className="b-tariffs-table b-tariffs-table_theme_ecom b-tariffs-table_adapt_ecom">
            <div className="b-tariffs-table__item">
                <div className="b-tariffs-table__row">
                    <div className="b-tariffs-table__col-name">
                        <div className="b-tariffs-table__type">{mainCategory.title}</div>
                        <div className="b-tariffs-table__name">Тариф&nbsp;
                            <span className="b-tariffs-table__name-inner">«{name}»</span>
                        </div>
                        <div className="b-tariffs-table__price">
                            <div className="b-tariffs-table__price-num">
                                {subscription_fee}
                            </div>
                            <div>
                                <div className="b-tariffs-table__price-top">
                                    <span className="u-rub">{writeOffPeriod !== null ? getMeasure(writeOffPeriod.measure, 0) : ''}</span>
                                </div>
                                <div className="b-tariffs-table__price-bottom">{writeOffPeriod !== null ? '/ ' + getMeasure(writeOffPeriod.measure, 1) : ''}</div>
                            </div>
                        </div>
                    </div>
                    <TariffDescription tariff={props} slugSvoi={-1}/>
                    <div className="b-tariffs-table__col-options"/>
                    <div className="b-tariffs-table__col-activate">
                       <div className="b-tariffs-table__links">
                            <button onClick={connect} className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive button-dropdown__btn">Подключить</button>
                        </div>
                        <div className="b-tariffs-table__links">
                            <div className="b-tariffs-table__link">Подробнее о тарифе</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LinkC>
}

export default B2bTariffCard;