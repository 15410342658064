import React, { Component } from "react";
import Icon from "../Icon";

export default class SelectCustom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
        };

        this.advertisingRef = React.createRef();
    }

    componentDidMount() {
        // для закрытия выпадашки по клику в не ее области
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    // убираем выпадашку по клику вне его области "пицеман"
    handleClickOutside = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        // includes --- ищет указанный элемет в массиве, возвращает true или false
        if (!path.includes(this.advertisingRef.current)) {
            this.setState({
                toggle: false,
            });
        }
    };

    // открывает/закрывает выпадашку
    toggleDrop = () => {
        this.setState((prevState) => {
            return {
                toggle: !prevState.toggle
            }
        });
    }

    render() {
        let { toggle } = this.state;
        let {
            options = [],
            changeCustom = () => {},
            defaultPaymentType = {},
            withoutArrow = false
        } = this.props;

        if (options.length === 0) {
            return 'Данные в селекте отсутствуют';
        }

        return (
            <div
                className="select_custom"
                ref={this.advertisingRef}
            >
                <div
                    className="select_custom__content"
                    onClick={this.toggleDrop}
                >
                    <div className="select_custom__wrap_icon_and_label">
                        <div className="select_custom__wrap_icon_lable">
                            <img src={defaultPaymentType && defaultPaymentType.icon} alt=""/>
                        </div>
                        <div className="select_custom__text_lable">
                            {defaultPaymentType && defaultPaymentType.title}
                        </div>
                    </div>

                    {
                        !withoutArrow &&
                      <div className="select_custom__wrap_arrow">
                          <Icon
                            name="icon_arrow-down_black"
                            className={`u-icon_select_custom ${toggle ? ' active_img' : ''}`}
                          />
                      </div>
                    }
                </div>

                <div className={`select_custom__drop ${toggle ? ' active' : ''}`}>
                    {options && options.map((item) =>
                        <div
                            key={item.alias}
                            className="select_custom__drop_item"
                            onClick={() => {
                                this.toggleDrop();
                                changeCustom(item);
                            }}
                        >
                            <div className="select_custom__drop_item_icon_lable">
                                <img src={item.icon} alt=""/>
                            </div>
                            <div className="select_custom__drop_item_text_lable">
                                {item.title}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}