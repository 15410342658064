import React, { Component } from 'react';
import AsideMenuItem from './AsideMenuItem';
import PropTypes from "prop-types";

class AsideMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            menu: [
                {
                    sys_name: "aboutRoute",
                    menu: [
                        //Временно скрыта, подробнее в MOTIV-1196
                        // {
                        //     alias: 'values',
                        //     name: 'Ценности',
                        //     link: '/values',
                        // },
                        {
                            sys_name: "leadershipRoute"
                        },
                        {
                            sys_name: "legalInformationRoute"
                        },
                    ]
                },
                {
                    sys_name: "contactsRoute",
                    menu: [
                        {
                            sys_name: "forSubscribersRoute"
                        },
                        {
                            sys_name: "forPressRoute"
                        },
                        {
                            sys_name: "forPartnersRoute"
                        },
                        {
                            sys_name: "forBuisnessRoute"
                        },
                    ]
                },
                {
                    sys_name: "pressCenterRoute",
                    menu: [
                        {
                            sys_name: "newsRoute"
                        },
                        {
                            sys_name: "mediaBankRoute"
                        },
                        {
                            sys_name: "formStyleRoute"
                        },
                    ]
                },
                {
                    sys_name: "vakansiiRoute",
                    menu: [
                        {
                            sys_name: "careerInMotivRoute"
                        }
                    ]
                },
            ]
        }
    }

    static contextTypes = {
        toggleOpenedClass: PropTypes.func,
        locationPath: PropTypes.string,
        megaMenuOpened: PropTypes.object,
    };

    componentDidMount(){
        const route = this.props.route;
        if ( !!route ){
            this.setRouteState( route );
        }
    }

    setRouteState(route){
        if ( !!route ){
            let routeVakansii = route && route.list.find(x => x.sys_name === "vakansiiRoute");
            let routeCareer = route && route.list.find(x => x.sys_name === "careerInMotivRoute");
            let routePressCenter = route && route.list.find(x => x.sys_name === "pressCenterRoute");
            let routeAllNews = route && route.list.find(x => x.sys_name === "newsRoute");
            let routeMediaBank = route && route.list.find(x => x.sys_name === "mediaBankRoute");
            let routeFormStyle = route && route.list.find(x => x.sys_name === "formStyleRoute");

            let routeAbout = route && route.list.find(x => x.sys_name === "aboutRoute");
            let routeLeadership = route && route.list.find(x => x.sys_name === "leadershipRoute");
            let routeLegalInformation = route && route.list.find(x => x.sys_name === "legalInformationRoute");
            let routeContacts = route && route.list.find(x => x.sys_name === "contactsRoute");
            let routeForSubscribers = route && route.list.find(x => x.sys_name === "forSubscribersRoute");
            let routeForPress = route && route.list.find(x => x.sys_name === "forPressRoute");
            let routeForPartners = route && route.list.find(x => x.sys_name === "forPartnersRoute");
            let routeForBuisness = route && route.list.find(x => x.sys_name === "forBuisnessRoute");

            const linkAbout = routeAbout && routeAbout.link;
            const linkLeadership = routeLeadership && routeLeadership.link;
            const linkLegalInformation = routeLegalInformation && routeLegalInformation.link;

            const linkVakansii = routeVakansii && routeVakansii.link;
            const linkCareer = routeCareer && routeCareer.link;
            const linkPressCenter = routePressCenter && routePressCenter.link;
            const linkAllNews = routeAllNews && routeAllNews.link;
            const linkMediaBank = routeMediaBank && routeMediaBank.link;
            const linkFormStyle = routeFormStyle && routeFormStyle.link;

            const linkContacts = routeContacts && routeContacts.link;
            const linkForSubscribers = routeForSubscribers && routeForSubscribers.link;
            const linkForPress = routeForPress && routeForPress.link;
            const linkForPartners = routeForPartners && routeForPartners.link;
            const linkForBuisness = routeForBuisness && routeForBuisness.link;

            const titleAbout = routeAbout && routeAbout.menu_title;
            const titleLeadership = routeLeadership && routeLeadership.menu_title;
            const titleLegalInformation = routeLegalInformation && routeLegalInformation.menu_title;

            const titleVakansii = routeVakansii && routeVakansii.menu_title;
            const titleCareer = routeCareer && routeCareer.menu_title;
            const titlePressCenter = routePressCenter && routePressCenter.menu_title;
            const titleAllNews = routeAllNews && routeAllNews.menu_title;
            const titleMediaBank = routeMediaBank && routeMediaBank.menu_title;
            const titleFormStyle = routeFormStyle && routeFormStyle.menu_title;

            const titleContacts = routeContacts && routeContacts.menu_title;
            const titleForSubscribers = routeForSubscribers && routeForSubscribers.menu_title;
            const titleForPress = routeForPress && routeForPress.menu_title;
            const titleForPartners = routeForPartners && routeForPartners.menu_title;
            const titleForBuisness = routeForBuisness && routeForBuisness.menu_title;

            let menu = this.state.menu;
            let menuCopy = this.arrayClone(menu);

            // О компании
            menuCopy[0].name = titleAbout;
            menuCopy[0].link = linkAbout;
            menuCopy[0].menu[0].name = titleLeadership;
            menuCopy[0].menu[0].link = linkLeadership;
            menuCopy[0].menu[1].name = titleLegalInformation;
            menuCopy[0].menu[1].link = linkLegalInformation;
            // Контакты
            menuCopy[1].name = titleContacts;
            menuCopy[1].link = linkContacts;
            menuCopy[1].menu[0].name = titleForSubscribers;
            menuCopy[1].menu[0].link = linkForSubscribers;
            menuCopy[1].menu[1].name = titleForPress;
            menuCopy[1].menu[1].link = linkForPress;
            menuCopy[1].menu[2].name = titleForPartners;
            menuCopy[1].menu[2].link = linkForPartners;
            menuCopy[1].menu[3].name = titleForBuisness;
            menuCopy[1].menu[3].link = linkForBuisness;
            // Пресс центр
            menuCopy[2].name = titlePressCenter;
            menuCopy[2].link = linkPressCenter;
            menuCopy[2].menu[0].name = titleAllNews;
            menuCopy[2].menu[0].link = linkAllNews;
            menuCopy[2].menu[1].name = titleMediaBank;
            menuCopy[2].menu[1].link = linkMediaBank;
            menuCopy[2].menu[2].name = titleFormStyle;
            menuCopy[2].menu[2].link = linkFormStyle;
            // Работа в МОТИВ
            menuCopy[3].name = titleVakansii;
            menuCopy[3].link = linkVakansii;
            menuCopy[3].menu[0].name = titleCareer;
            menuCopy[3].menu[0].link = linkCareer;

            this.setState({
                menu: menuCopy
            });
        }
    }

    componentDidUpdate() {
        let toggle = this.state.toggle;
        const overlayClass = document.querySelector('.u-overlay');
        const megaMenuOpened = this.context.megaMenuOpened;

        if (typeof window !== "undefined"){
            if ( toggle === true || !!overlayClass && toggle === false && megaMenuOpened === false ){
                this.context.toggleOpenedClass();
            }
        }
    }

    arrayClone = (arr)=> {
        let i, copy;
        if (Array.isArray(arr)) {
            copy = arr.slice(0);
            for (i = 0; i < copy.length; i++) {
                copy[i] = this.arrayClone(copy[i]);
            }
            return copy;
        } else if(typeof arr === 'object') {
            let obj = {};
            for (let key in arr) {
                if ((Array.isArray(arr[key])) || (typeof arr[key] === 'object')) {
                    obj = {...obj, [key]: this.arrayClone(arr[key]) }
                }
                else {
                    obj = {...obj, [key]: arr[key]}
                }
            }
            return obj;
        } else {
            return arr;
        }
    };

    onToggle = () => {
        this.setState((prevState) => {
            return {
                toggle: !prevState.toggle
            }
        });
    };

    render() {
        let { toggle, menu } = this.state;
        let { flag, flagSubMenu, customTitleMenu = false } = this.props;

        return (<React.Fragment>
            <div className={(toggle ? "rotate b-aside-menu-top" : "b-aside-menu-top")} onClick={ this.onToggle }>{customTitleMenu ? customTitleMenu : 'О компании'}</div>
            <div className={`b-aside-menu b-aside-menu_adapt_default ${toggle ? "toggle " : ""}`}>
                { menu.map((item, index) =>
                    <AsideMenuItem
                        item={item}
                        key={index}
                        flag={flag}
                        flagSubMenu={flagSubMenu}
                    />)
                }
            </div>
        </React.Fragment>)
    }
}

export default AsideMenu;