import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {promotionsArchivePageAction} from "../../actions/promotions"
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import Icon from '../../components/common/Icon';
import {Link} from "react-router-dom";

class ArchivedPromotions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount(){
        if(!this.props.promotionsArchive.length){
            this.props.promotionsArchivePageAction();
        }
    }
    
    componentDidMount(){
        this.props.promotionsArchivePageAction(null, window.innerWidth < 768 ? 1 : null);
    }

    componentDidUpdate(prevProps, prevState){
        // пагинация
        if(prevState.page !== this.state.page){
            this.props.promotionsArchivePageAction(this.state.page, window.innerWidth < 768 ? 1 : null);
        }
    }

    getMore = () => {
        this.setState({
            page: this.state.page + 1
        })
    }

    render() {
        let {
            promotionsArchive,
            paginationArchive,
            regions,
            location,
        } = this.props

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/actions',
                title: "Акции и специальные предложения"
            },
            {
                url: "",
                title: 'Архив акций'
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'actionsArchiveB2CRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                <div className="payments-page-container__content">
                    <div className="payments-page-container__wrapper">
                        <div className="info-page-container__wrap-breadcrumbs archived_promotions__wrap_breadcrambs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="payments-page__title">Архив акций</h1>

                        <div className="archived_promotions__all">
                            {promotionsArchive && promotionsArchive.map((item) =>
                                <Link 
                                    key={item.id}
                                    className="archived_promotions__cart"
                                    to={`/${selectedRegion.alias}/actions/archive/${item.id}`}
                                >
                                    <div className="archived_promotions__cart_img">
                                        <img src={item.photo} alt=""/>
                                    </div> 
                                    <div className="archived_promotions__cart_date">{item.date}</div>
                                    <div className="archived_promotions__cart_title">{item.title}</div>
                                    <div className="archived_promotions__cart_info" dangerouslySetInnerHTML={{__html: item.short_description || item.description}} />
                                </Link>
                            )}
                        </div>
                        {(paginationArchive && (paginationArchive.currentPage < paginationArchive.pageCount))
                            && (
                                <div className="news-and-events__link-to-news">
                                    <div 
                                        className="more-news-block" 
                                        onClick={this.getMore}
                                    >
                                        <Icon name={"icon_read-more-news"}/>
                                        <div className="more-news-block__title">Показать еще</div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            
        )
    }
}

export default connect(
    state => ({
        promotionsArchive: state.promotions.promotionsArchive,
        paginationArchive: state.promotions.paginationArchive,
        routes: state.routes,
        regions: state.regions,
    }),
    (dispatch) => {
        return {
            promotionsArchivePageAction: (pageNum, is_mobile) => dispatch(promotionsArchivePageAction(pageNum, is_mobile)),
        }
    }
)(ArchivedPromotions);