import React, {Component} from "react";
import Icon from "../common/Icon";

export default class AlertBlock extends Component{

    constructor(props){
        super(props);

        this.state = {
            shown: true
        }
    }

    render(){

        let {
            text
        } = this.props;


        return  text && this.state.shown ? <div className="b-support-faq__alert">
                    <Icon name={'icon_i'} className={'b-support-faq__icon u-icon u-icon_i'} />
                    <div className="b-support-faq__alert-text" dangerouslySetInnerHTML={{__html: text}} />
                    <div className="b-support-faq__close" onClick={() => {this.setState({shown: false})}}>
                        <Icon name={'icon_close-white'} className={'u-icon u-icon_close-white'} />
                    </div>
                </div> : null;
    }
}

