import React, { Component } from 'react';
import {phoneValidator} from "../../tools/form-helper";
import {numberMask, phoneMask} from "../../tools/mask";
import InputError from "../common/InputError";
import {connect} from "react-redux";
import { userPhoneValueAction } from '../../actions/common';

export class RecoveryForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            step: 0,
            phone: "",
            code: "",
            disabledBtn: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.phoneError != props.error && props.error) {
            return {...state, phoneError: props.error}
        }
        return state;
    }

    // добавляем телефон при перерендере модалки
    componentDidMount(){
        let {
            userPhoneValue
        } = this.props

        if(!!userPhoneValue && userPhoneValue.length === 10){
            this.setState({
                phone: phoneMask(this.props.userPhoneValue)
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        let {
            phone,
            code,
            step
        } = this.state;
        if (this.state.disabledBtn === false) {
            if (step === 0 && phone && phoneValidator(phone)) {
                this.props.recover && this.props.recover(JSON.stringify({username: phone.replace(/\D/g, '').substr(1)}))
            }
            this.disabledBtn();
        }

        // обнуляем глобальное состояние
        this.props.userPhoneValueAction('')
    };

    disabledBtn =()=> {
        if (!this.state.disabledBtn) {
            this.setState({disabledBtn: true})
        }
    };

    onBlur = (e) => {

        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {

            var err = "";
            switch (target.name) {
                case "phone":
                    err = target.value ? (phoneValidator(target.value) ? "" : "Некорректный телефон") : "Обязательно для заполнения";
                    break;
                case "code":
                    err = target.value ? (target.value.length < 4 ? "" : "Введите 4 символа") : "Обязательно для заполнения";
                    break;
            }

            return {
                ...prevState,
                [target.name+"Error"]: err
            }
        })
    };

    onFocus = (e) => {

        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {

            return {
                ...prevState,
                [target.name+"Error"]: ""
            }
        })
    };

    onChange = (e) => {

        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                ...prevState,
                [target.name]: target.name === "code" ? numberMask(4)(target.value) : phoneMask(target.value)
            }
        })
    };

    render() {

        let {
            recovery_form_description,
            recovery_way_description,
            recovery_way_title
        } = this.props.recoveryFormSettings;

        let {
            step,
            phone,
            code,
            phoneError,
            codeError,
            disabledBtn
        } = this.state;

        let inputFunctions = {
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onChange: this.onChange
        };

        return <div className="main-modal modal-container modal-password-recover">
            <div className="close-modal">
            </div>
            <div className="modal-content-wrap">
                <div className="modal-title">Восстановление пароля</div>
                <div className="modal-content">
                    {recovery_form_description ? <div className="modal-subtitle">
                        {recovery_form_description}
                    </div> : null}
                    <div className="options">
                        <form className="option-sms sms-code" onSubmit={this.onSubmit}>
                            <label className="radio">
                                {recovery_way_title}
                                <input type="radio" name="optPass" id="sms" defaultChecked/>
                                <div className="radio__text"></div>

                                {step === 0 ? <div className="option-info">
                                    <div className="text">
                                        {recovery_way_description}
                                    </div>
                                    <div className="row-bottom">
                                        <div className={"input-text-wrap input_recovery"+(phoneError ? " input-text-wrap_has_error" : "")}>
                                            <div className="label">Номер телефона</div>
                                            <input type="tel" name="phone" value={phone} {...inputFunctions}/>
                                            <InputError hasError={phoneError} errorText={phoneError}/>
                                        </div>
                                        <button type="submit" className={"modal-btn-default"+(phone && phoneValidator(phone) && disabledBtn === false ? "" : " disabled")}>Получить пароль</button>
                                    </div>
                                </div> : <div className="option-info">
                                    <div className="text">
                                        Введите код из SMS–сообщения
                                    </div>
                                    <div className="row-bottom">
                                        <div className={"input-text-wrap"+(codeError ? " input-text-wrap_has_error" : "")}>
                                            <div className="label">Код подтверждения</div>
                                            <input type="text" name="code" value={code} {...inputFunctions}/>
                                            <InputError hasError={codeError} errorText={codeError}/>
                                            <div className="repeat-send"><a href="">Выслать еще раз</a></div>
                                        </div>
                                        <button type="submit" className={"modal-btn-default"+(code && code.length === 4 ? "" : " disabled")}> Войти</button>
                                    </div>
                                </div>}

                            </label>

                        </form>
                        {/*<div className="option-mail">*/}
                            {/*<label className="radio">*/}
                                {/*Отправить пароль на e–mail*/}
                                {/*<input type="radio" name="optPass" id="email" />*/}
                                {/*<div className="radio__text"></div>*/}

                                {/*<div className="option-info">*/}
                                    {/*<div className="text">*/}
                                        {/*Введите адрес электронной почты к которой привязан лицевой счет*/}
                                    {/*</div>*/}
                                    {/*<div className="row-bottom">*/}
                                        {/*<div className="input-text-wrap">*/}
                                            {/*<div className="label">E–mail</div>*/}
                                            {/*<input type="mail"/>*/}
                                        {/*</div>*/}
                                        {/*<button className="modal-btn-default"> Отправить</button>*/}
                                    {/*</div>*/}

                                {/*</div>*/}

                            {/*</label>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>
        </div>
    }
}

export default connect(
    state => ({
        userPhoneValue: state.common.userPhoneValue,
    }),
    (dispatch) => {
        return {
            userPhoneValueAction: (data) => dispatch(userPhoneValueAction(data)),
        }
    }
)(RecoveryForm);