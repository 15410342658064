import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {numberMask} from "../../tools/mask";
import {phoneAccouintValidator, passwordValidator} from "../../tools/form-helper";
import InputError from "../common/InputError";
import Icon from "../common/Icon";
import {isMobile} from "react-device-detect";
import { clearState } from "../../actions/client";
import { userPhoneValueAction } from "../../actions/common";

export class AuthForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showTitle: props.showTitle,
            shown: false,
            formValues: {
                username: "",
                password: "",
                rememberMe: false
            },
            formErrors: {
                username: "",
                password: ""
            },
            disabledSubmitBtn: false
        }
    }

    static getDerivedStateFromProps(props, state){
        // if (state.formErrors.password != props.error && props.error) {
        //     return {...state, formErrors: {...state.formErrors, password: props.error}}
        // }


        if (state.formErrors.password != props.error && props.error) {
            return {...state, formErrors: {...state.formErrors, password: props.error}}
        }
        return state;
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.error !== this.props.error && !!this.props.error && this.state.disabledSubmitBtn) {
            this.setState({disabledSubmitBtn: false});
        }

        if (prevProps.error === this.props.error && this.props.error === '' && this.state.disabledSubmitBtn) {
            setTimeout(() => {
                this.setState({disabledSubmitBtn: false});
            }, 4000);
        }

        if(prevState.formErrors.username !== this.state.formErrors.username && this.props.isGuest){
            this.props.clearState()
        }

        // добавляем телефон в глобальное состояние при автозаполнении поля
        if(prevState.formValues.username !== this.state.formValues.username){
            this.props.userPhoneValueAction(this.state.formValues.username);
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        let {
            username,
            password,
            rememberMe
        } = this.state.formValues;

        let {
            auth_form_password_min_length,
            auth_form_password_requirements
        } = this.props.authFormSettings;

        if (username && password && phoneAccouintValidator(username) && passwordValidator(password, auth_form_password_min_length, auth_form_password_requirements) && this.props.login) {
            this.setState({disabledSubmitBtn: true});
            this.props.login(JSON.stringify({
                username: username.replace(/\D/g, ''),
                password
            }), rememberMe)
        }
    };

    onBlur = (e) => {

        var target = e.target || e.nativeEvent.target;

        let {
            auth_form_password_min_length,
            auth_form_password_requirements
        } = this.props.authFormSettings;

        this.setState((prevState) => {

            var err = "";
            switch (target.name) {
                case "username":
                    err = target.value ? (phoneAccouintValidator(target.value) ? "" : "Некорректный телефон или лицевой счет") : "Обязательно для заполнения";
                    break;
                case "password":
                    err = target.value ? (passwordValidator(target.value, auth_form_password_min_length, auth_form_password_requirements) ? "" : "Не соответствует требованиям к паролю") : "Обязательно для заполнения";
                    break;
            }

            return {
                formErrors: {
                    ...prevState.formErrors,
                    [target.name]: err
                }
            }
        })
    };

    onFocus = (e) => {

        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {

            return {
                formErrors: {
                    ...prevState.formErrors,
                    [target.name]: ""
                }
            }
        })
    };

    onChange = (e) => {

        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                formValues: {
                    ...prevState.formValues,
                    [target.name]: target.name === "username" ? numberMask(10)(target.value) : target.value
                }
            }
        })

        // добовляем в глобальное состояние телефон
        if(!this.props.userPhoneValue){
            this.props.userPhoneValueAction(target.value)
        }
    };

    onCheckboxChange = (e) => {
        var target = e.target || e.nativeEvent.target;
        this.setState((prevState) => {
            return {
                formValues: {
                    ...prevState.formValues,
                    [target.name]: !prevState.formValues.rememberMe
                }
            }
        })
    };

    toggleShown = () => {
        this.setState((prevState) => {
            return {shown: !prevState.shown}
        })
    };

    render() {

        let {
            username,
            password
        } = this.state.formValues;

        let errors = this.state.formErrors;

        let { shown, disabledSubmitBtn } = this.state;

        let inputFunctions = {
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onChange: this.onChange
        };

        const showQr = this.props.showTitle && !isMobile;

        return (
            <div className="main-modal modal-container modal-authorize">
                <div className="close-modal">
                </div>
                <div className="modal-content-wrap">
                    {this.props.showTitle ? <div className="modal-title">Авторизация</div> : ''}
                    <div className={`modal-content ${showQr ? 'auth_form_with_qr' : ''}`}>
                        {this.props.authFormSettings && this.props.authFormSettings.auth_form_description ? <div className="modal-subtitle" dangerouslySetInnerHTML={{__html: this.props.authFormSettings.auth_form_description}}/> : null}
                        <form className="row-auth" onSubmit={this.onSubmit}>
                            <div className={"input-text-wrap"+(errors.username ? " input-text-wrap_has_error" : "")}>
                                <div className="label">Номер телефона / лицевой счет</div>
                                <input 
                                    type="tel" 
                                    name="username" 
                                    value={username} 
                                    {...inputFunctions}
                                />
                                <InputError hasError={!!errors.username} errorText={errors.username}/>
                            </div>
                            <div className={"input-text-wrap"+(errors.password ? " input-text-wrap_has_error" : "")}>
                                <div className="label">Пароль</div>
                                <span onClick={this.toggleShown} className={"icon-eye_wrap"}>
                                    <Icon name={shown ? "icon_eye_orange" : "icon_eye"} className={"b-input-text__password-icon u-icon u-icon_eye"}/>
                                </span>
                                <input
                                    className="auth-input"
                                    type={shown ? "text" : "password"}
                                    name="password"
                                    value={password}
                                    {...inputFunctions}
                                />
                                <InputError hasError={!!errors.password} errorText={errors.password}/>
                            </div>
                            <button className={"modal-btn-default"+(!disabledSubmitBtn && username && password && phoneAccouintValidator(username) && passwordValidator(password) ? "" : " disabled")} type="submit">Войти</button>
                        </form>
                        <div className="row-auth">
                            <div className="row-checkbox">
                                <label className="checkbox">
                                    <input type="checkbox" name="rememberMe" onChange={this.onCheckboxChange}/>
                                    <div className="checkbox__text">
                                        Запомнить меня
                                    </div>
                                </label>
                                <div className="forgot-pass">
                                    <a href="#" onClick={this.props.onForgetLinkClick}>
                                        Забыли пароль?
                                    </a>
                                </div>
                                {/*<div className="forgot-pass">*/}
                                    {/*<a href="">*/}
                                        {/*Использовать PUK*/}
                                    {/*</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        {showQr ? <div className={'qr'}>
                            {this.props.qrText ? <span dangerouslySetInnerHTML={{__html: this.props.qrText}}/> : <span>Открой в&nbsp;мобильном приложении</span>}
                            <div className={'qr-img'}/>
                        </div> : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        isGuest: state.client.isGuest,
        userPhoneValue: state.common.userPhoneValue,
    }),
    (dispatch) => {
        return {
            clearState: () => dispatch(clearState()),
            userPhoneValueAction: (data) => dispatch(userPhoneValueAction(data)),
        }
    }
)(AuthForm);
