import {feedbackSubjects} from "../actions/support";

let initState = {
    inProgress: false,
    data: {},
    error: false,
    isOk: false,
    notices: [],
    fastDecisions: [],
    ussd: [],
    feedbackSubjects: [],
    feedbackSuccess: false,
    feedbackForm: {},
    subscribeError: {},
    subscribeSuccess: false,
    isSubscribed: false,
    isUnsubscribed: false

};

import {parseErrors} from "../tools/api-helper";

export function support (state = initState, action) {
    switch (action.type) {
        case "SUPPORT":
            return {...state, inProgress: true, isOk: false};
        case "SUPPORT_SUCCESS":
            return {...state, inProgress: false, data: action.data, errors: {}, isOk: true};
        case "SUPPORT_VALIDATION_ERROR":
            return {...state, inProgress: false, errors: parseErrors(action.data), isOk: false};
        case "SUPPORT_NOTICES_SUCCESS":
            return {...state, notices: action.data};
        case "CLEAR_SUPPORT_DATA":
            return {inProgress: false, data: {}, error: false, isOk: false, notices: []};
        case "SUPPORT_FAST_DECISIONS":
            return {...state, isOk: false};
        case "SUPPORT_FAST_DECISIONS_SUCCESS":
            return {...state, fastDecisions: action.data, isOk: true};
        case "SUPPORT_USSD":
            return {...state, isOk: false};
        case "SUPPORT_USSD_SUCCESS":
            return {...state, ussd: action.data, isOk: true};
        case "SUPPORT_COMPLAIN":
            return {...state, isOk: false};
        case "SUPPORT_COMPLAIN_SUCCESS":
            return {...state, complain: action.data, isOk: true};
        case "GET_PAGE_SUCCESS":
            return {...state, [action.data.page]: action.data, isOk: true};
        case "FEEDBACK_SUBJECTS":
            return {...state, isOk: false};
        case "FEEDBACK_SUBJECTS_SUCCESS":
            const feedbackSubjects = action.data.map(el => ({...el, value: el.id, label: el.title}))
            return {...state, feedbackSubjects, isOk: true};
        case "FEEDBACK_REQUEST":
            return {...state, isOk: false, inProgress: true, feedbackError: {}, feedbackSuccess: false};
        case "FEEDBACK_REQUEST_SUCCESS":
            return {...state, isOk: true, inProgress: false, feedbackError: {}, feedbackSuccess: true};
        case "FEEDBACK_REQUEST_VALIDATION_ERROR":
            return {...state, feedbackError: parseErrors(action.data), isOk: true, inProgress: false};
        case "FEEDBACK_FORM":
            return {...state, isOk: false, feedbackForm: {}};
        case "FEEDBACK_FORM_SUCCESS":
            return {...state, isOk: true, feedbackForm: {...action.data.find((item) => item.alias === 'feedback_form_b2b')}};
        case "ON_SUBSCRIPTION":
            return {...state, isOk: false, inProgress: true, subscribeError: {}, subscribeSuccess: false};
        case "ON_SUBSCRIPTION_SUCCESS":
            return {...state, isOk: true, inProgress: false, subscribeError: {}, subscribeSuccess: true};
        case "ON_SUBSCRIPTION_VALIDATION_ERROR":
            return {...state, subscribeError: parseErrors(action.data), isOk: true, inProgress: false};
        case "ON_SUBSCRIPTION_IN":
            return {...state, isOk: false, inProgress: true, subscribeError: {}, subscribeSuccess: false, isSubscribed: false};
        case "ON_SUBSCRIPTION_IN_SUCCESS":
            return {...state, isOk: true, inProgress: false, subscribeError: {}, subscribeSuccess: true, isSubscribed: true};
        // case "ON_SUBSCRIPTION_IN_VALIDATION_ERROR":
        case "ON_SUBSCRIPTION_IN_NOT_FOUND":
            return {...state, subscribeError: action.data.message || 'Произошла ошибка. Попробуйте снова.', isOk: true, inProgress: false, isSubscribed: true};
        case "ON_SUBSCRIPTION_OUT":
            return {...state, isOk: false, inProgress: true, subscribeError: {}, subscribeSuccess: false, isUnsubscribed: false};
        case "ON_SUBSCRIPTION_OUT_SUCCESS":
            return {...state, isOk: true, inProgress: false, subscribeError: {}, subscribeSuccess: true, isUnsubscribed: true};
        // case "ON_SUBSCRIPTION_OUT_VALIDATION_ERROR":
        case "ON_SUBSCRIPTION_OUT_NOT_FOUND":
            return {...state, subscribeError: action.data.message || 'Произошла ошибка. Попробуйте снова.', isOk: true, inProgress: false, isUnsubscribed: true};
    }
    return state;
}