import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {Route} from "react-router-dom";
import Page404 from "../Page404";

import {promotionsDetailPageAction, promotionsDetailArchivePageAction} from "../../actions/promotions"
import LinkC from "../../components/common/LinkC";
import Icon from '../../components/common/Icon';
import {Link} from "react-router-dom";
import CarouselRatePromotion from '../../components/Promotions/CarouselRatePromotion';
import {isEmptyObj} from '../../tools/tools';

class RatePromotion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        pushHistory: PropTypes.func,
        locationPath: PropTypes.string,
        setHeaderClassName: PropTypes.func
    };

    componentWillMount(){
        this.props.promotionsDetailPageAction(this.props.match.params.slug, this.context.selectedRegion.id);
        this.props.promotionsDetailArchivePageAction(this.props.match.params.slug, this.context.selectedRegion.id); 
    }

    componentDidMount(){
        this.props.promotionsDetailPageAction(this.props.match.params.slug, this.context.selectedRegion.id);
        this.props.promotionsDetailArchivePageAction(this.props.match.params.slug, this.context.selectedRegion.id);
    }

    componentDidUpdate(prevProps, prevState){
        // обновление детальной страницы при переходе по блоку другие акции
        if(prevProps.match.params.slug !== this.props.match.params.slug){
            this.props.promotionsDetailPageAction(this.props.match.params.slug, this.context.selectedRegion.id);
        }
    }



    render() {
        let {
            detailPage,
            detailPageArchive,
            regions,
            location,
            promotions,
        } = this.props

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.breadcrumbs) : (detailPage && detailPage.breadcrumbs)
        let title = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.title) : (detailPage && detailPage.title)
        let date = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.date) : (detailPage && detailPage.date)
        let categories = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.categories) : (detailPage && detailPage.categories)
        let photo = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.photo) : (detailPage && detailPage.photo)
        let description = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.description) : (detailPage && detailPage.description)
        let info = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.info) : (detailPage && detailPage.info)
        let document = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.document) : (detailPage && detailPage.document)
        let button = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.button) : (detailPage && detailPage.button)
        let others = !isEmptyObj(detailPageArchive) ? (detailPageArchive && detailPageArchive.others) : (detailPage && detailPage.others)

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        const curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 2];

        // после 404 добавляет маржин снизу у шапки
        if (typeof document !== 'undefined') {
            this.context.setHeaderClassName("page__header-holder");
        }

        // если такой акции нет, переправляем на 404 страницу
        if (promotions.isNotFound) {
            return (
                <Route component={Page404} />
            )
        }

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={`${curentPath === 'actions' ? 'Акция' : curentPath === 'archive' ? 'Архивная акция' : ''}`}
                    meta={[
                        {
                            "name": "description",
                            "content": ""
                        },
                        {"name": "keywords", "content": ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                <div className="payments-page-container__content">
                    <div className="payments-page-container__wrapper">
                        <div className="info-page-container__wrap-breadcrumbs archived_promotions__wrap_breadcrambs">
                            <div className="b-breadcrumbs b-breadcrumbs_theme_grey">
                                <ul className="b-breadcrumbs__list">
                                    {breadcrumbs && breadcrumbs.map((el, id) =>
                                        <li key={id} className="b-breadcrumbs__item">
                                            {el.url
                                                ?   <Link className="b-breadcrumbs__link" to={`/${selectedRegion.alias}${el.url}`}>{el.label}</Link>
                                                :   <span dangerouslySetInnerHTML={{__html: el.label}}/>
                                            }
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <h1 className="payments-page__title promotion_title">{title}</h1>
                        
                        <div className="promotion__date">{date}</div>
                        {categories && categories.length
                            ?   <div className="promotion__categories">
                                    {categories.map((el) =>
                                        <React.Fragment key={el.id}>
                                            <Link to={`/${selectedRegion.alias}/actions/category/${el.id}`} className="promotion__category">{el.title}</Link>
                                        </React.Fragment>
                                    )}
                                </div>
                            :   null
                        }

                        <div className="promotion__banner_wrapper">
                            <div className="promotion__banner">
                                {photo && <img src={photo} alt="banner"/>}
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: description}} className="promotion__description"/>
                        <div dangerouslySetInnerHTML={{__html: info}} className="promotion__footnote" />

                        {document &&
                            (<div className="promotion__document_wrapper">
                                <div className="promotion__document_img">
                                    <Icon name={"icon_actions_pdf"}/>
                                </div>
                                <div className="promotion__document_info">
                                    <a className="promotion__document_link" target="_blank" href={document.url}>{document.name}</a>
                                    <div className="promotion__document_size">{`(размер: ${document.size})`}</div>
                                </div>
                            </div>)
                        }
                        {button &&
                            (<LinkC to={button.url} className="promotion__btn">{button.title}</LinkC>)
                        }

                        {(others && others.length > 0)
                            && (
                                <div className="promotion__other_promotions">
                                    <h2 className="promotion__other_promotions_title">Другие акции</h2>

                                    <div className="promotion__other_promotions_wrapper">
                                        {others && others.map((i) =>
                                            <Link 
                                                key={i.id} 
                                                className="archived_promotions__cart"
                                                to={`/${selectedRegion.alias}/actions/${i.id}`}
                                            >
                                                <div className="archived_promotions__cart_img">
                                                    <img src={i.photo} alt=""/>
                                                    <div className="archived_promotions__category_wrapper">
                                                        <div className="promotion__category">{i.categories[0].title}</div>
                                                    </div>
                                                </div> 
                                                <div className="archived_promotions__cart_date">{i.date}</div>
                                                <div className="archived_promotions__cart_title">{i.title}</div>
                                                <div className="archived_promotions__cart_info" dangerouslySetInnerHTML={{__html: i.short_description || i.description}} />
                                            </Link>                                
                                        )}
                                    </div>
                                    
                                    <div className="promotion__other_carousel">
                                        {others && 
                                            <CarouselRatePromotion data={others} />
                                        }
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            
        )
    }
}

export default connect(
    state => ({
        detailPage: state.promotions.detailPage,
        detailPageArchive: state.promotions.detailPageArchive,
        promotions: state.promotions,
        routes: state.routes,
        regions: state.regions,
    }),
    (dispatch) => {
        return {
            promotionsDetailPageAction: (slug, regionId) => dispatch(promotionsDetailPageAction(slug, regionId)),
            promotionsDetailArchivePageAction: (slug, regionId) => dispatch(promotionsDetailArchivePageAction(slug, regionId)),
        }
    }
)(RatePromotion);