import React, { Component } from 'react';
import { renderActiveField } from '../../tools/tools'

class RequisitesAbout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { requisites } = this.props;

        return (
            <div className="b-internet-ipad__tariffs-table">
                <div className="legal-information-page__requisites-wrap-items">
                    {requisites.inn && requisites.kpp ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            ИНН/КПП:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.inn}/{requisites.kpp}
                        </div>
                    </div> : null}
                    {requisites.payment_account ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            Р/сч:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.payment_account}
                        </div>
                    </div> : null}
                    {requisites.correspondent_account ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            К/сч:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.correspondent_account}
                        </div>
                    </div> : null}
                    {requisites.bank ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            Банк:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.bank}
                        </div>
                    </div> : null}
                    {requisites.bik ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            БИК:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.bik}
                        </div>
                    </div> : null}
                    {requisites.legal_address ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            Юридический адрес:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.legal_address}
                        </div>
                    </div> : null}
                    {requisites.address ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            Почтовый адрес:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.address}
                        </div>
                    </div> : null}
                    {requisites.phone ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            Телефон:
                        </div>
                        {renderActiveField(requisites.phone, 'phone')}
                    </div> : null}
                    {requisites.fax ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            Факс:
                        </div>
                        <div className="legal-information-page__requisites-item-value">
                            {requisites.fax}
                        </div>
                    </div> : null}
                    {requisites.email ? <div className="legal-information-page__requisites-item">
                        <div className="legal-information-page__requisites-item-label">
                            E-mail:
                        </div>
                        {renderActiveField(requisites.email, 'email')}
                    </div> : null}
                </div>
            </div>
        )
    }
}

export default RequisitesAbout;