import React, {Component} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import {onlyCyrillicChars, phoneMask, timeFormatMask, timeBlurMask, numberMask} from "../../tools/mask";
import {
    emailValidator,
    phoneValidator,
    usernameValidator,
    unitCodeValidator,
    emptyValidator
} from "../../tools/form-helper";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";
import Icon from "../common/Icon";
import {sms, verifySms} from "../../actions/sms";
import Timer from "../common/Timer";
import {support} from "../../actions/support";


class SecurityModal extends Component{

    constructor(props) {
        super(props);

        let now = new Date();

        this.state = {
            fields: {
                fio: {
                    type: "text",
                    name: "fio",
                    label: "ФИО",
                    value: "",
                    placeholder: "",
                    mask: onlyCyrillicChars,
                    validators: [usernameValidator],
                    hasError: null,
                    textError: "Укажите ФИО",
                    required: true
                },
                phone_number: {
                    type: "text",
                    name: "phone_number",
                    label: "ТЕЛЕФОН",
                    value: "",
                    placeholder: "+7",
                    hasError: null,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    required: true,
                    textError: "Укажите номер телефона",
                },
                email: {
                    type: "text",
                    name: "email",
                    label: "E-MAIL",
                    value: "",
                    placeholder: "",
                    hasError: null,
                    validators: [emailValidator],
                    textError: "Укажите корректный email",
                },
                code: {
                    type: "text",
                    name: "code",
                    label: "КОД ПОДТВЕРЖДЕНИЯ",
                    value: "",
                    placeholder: "",
                    hasError: null,
                    validators: [unitCodeValidator],
                },
                days: {
                    type: "numbers",
                    name: "days",
                    value: now.getDate(),
                    placeholder: "",
                    readOnly: true,
                    hasError: null,
                },
                month: {
                    type: "numbers",
                    name: "month",
                    value: now.getMonth(),
                    placeholder: "",
                    readOnly: true,
                    hasError: null,
                },
                year: {
                    type: "numbers",
                    name: "year",
                    value: now.getFullYear(),
                    placeholder: "",
                    readOnly: true,
                    hasError: null,
                },
                time: {
                    type: "time",
                    name: "time",
                    label: "ПРИМЕРНОЕ ВРЕМЯ ИНЦИДЕНТА",
                    value: "",
                    placeholder: "",
                    hasError: null,
                    mask: timeFormatMask
                },
                scammer_info: {
                    type: "text",
                    name: "scammer_info",
                    label: "ТЕЛЕФОН ИЛИ ВЕБ-САЙТ МОШЕННИКОВ",
                    value: "",
                    placeholder: "",
                    hasError: null,
                },
                amount: {
                    type: "text",
                    name: "amount",
                    label: "СУММА УЩЕРБА В РУБЛЯХ",
                    value: "",
                    placeholder: "",
                    mask: numberMask(7),
                    hasError: null,
                },
                comment: {
                    type: "text",
                    name: "comment",
                    label: "ПОДРОБНОСТИ МОШЕННИЧЕСТВА",
                    value: "",
                    placeholder: "",
                    hasError: null,
                    required: true,
                    textError: "Поле обязательно для заполнения",
                    validators: [emptyValidator]
                }
            },
            step: 1,
            timer: true,
            agreement: false,
            file: null
        }
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
    };

    componentWillReceiveProps (nextProps) {

        let sms = nextProps.sms;
        let support = nextProps.support;

        if (sms.data.hasOwnProperty('query_id')) {
            this.setState({step: 2});
        }

        if (sms.error) {
            this.setState({step: 'error'});
        }

        if (sms.verified === true) {
            this.setState({step: 3});
        }

        if (support.isOk) {
            this.props.openSuccess();
        }

        if (nextProps.errors != this.props.errors && Object.keys(nextProps.errors).length) {
            let fields = {...this.state.fields};
            for (let attr in fields) {
                fields[attr].hasError = nextProps.errors.hasOwnProperty(attr);
                if (nextProps.errors.hasOwnProperty(attr)) {
                    fields[attr].textError = nextProps.errors[attr];
                }
            }
        }
    }

    onFieldChange = (name) => {
        return (e) => {
            let fields = {...this.state.fields};
            fields[name].value = fields[name].mask ? fields[name].mask(e.target.value) : e.target.value;
            this.setState({fields});
        }
    };

    onFileChange = (e) => {
        if (e.target.files.length) {
            this.setState({file: e.target.files[0]});
        } else {
            this.setState({file: null})
        }
    };

    toggleAgreement = () => {
        this.setState((prevState) => {
            return {
                agreement: !prevState.agreement
            }
        })
    };

    onBlur = (name) => {
        return (e) => {

            let hasError = this.validateField(name);

            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: hasError
                    }
                }
            });
        };
    };

    validateField = (name) => {

        let field = this.state.fields[name];
        let hasError = false;
        if (name == 'time') {
            field.value = timeBlurMask(field.value);
        }
        if (field.hasOwnProperty('validators')) {
            field.validators.forEach(function (func) {
                if (!func.call(this, field.value)) {
                    hasError = true;
                }
            });
            if (!field.required && field.value.length === 0) {
                hasError = null
            }
        }

        return hasError
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    renderArrow = (name, type) => {
        return <a className={"b-input-counter__count-item b-input-counter__count-item_" + type} onClick={(e) => {
            e.preventDefault();
            this.changeDate(name, type)
        }} href="#"/>
    };

    changeDate = (name, type) => {
        let fields = {...this.state.fields};

        fields[name].value = fields[name].value + (type === 'increment' ? 1 : (-1));

        let daysInMonth = 33 - new Date(fields.year.value, fields.month.value, 33).getDate();

        switch (name) {
            case 'days':
                if (fields[name].value > daysInMonth) {
                    fields[name].value = 1;

                    this.setState({fields}, () => {
                        this.changeDate('month', 'increment');
                    });
                    return;
                }
                if (fields[name].value < 1) {
                    fields[name].value = 33 - new Date(fields.year.value, fields.month.value - 1, 33).getDate();

                    this.setState({fields}, () => {
                        this.changeDate('month', 'decrement');
                    });
                    return;
                }
                break;
            case 'month':
                if (fields[name].value > 11) {
                    fields[name].value = 0;

                    this.setState({fields}, () => {
                        this.changeDate('year', 'increment');
                    });
                    return;
                }
                if (fields[name].value < 0) {
                    fields[name].value = 11;

                    this.setState({fields}, () => {
                        this.changeDate('year', 'decrement');
                    });
                    return;
                }
                if (fields.days.value > daysInMonth) {
                    fields.days.value = daysInMonth;
                }
                break;
        }

        this.setState({fields});
    };

    sendSms = () => {
        this.props.sendSms(this.state.fields.phone_number.value);
        this.setState({timer: true});
    };

    verifyCode = (e) => {
        e.preventDefault();
        this.props.verifySms(this.state.fields.code.value, this.props.sms.data.query_id);
    };

    timerEnd = () => {
        this.setState({
            timer: false
        });
    };

    validate = () => {
        let fields = {...this.state.fields};
        let isValid = true;
        for (let attr in fields) {
            if (fields[attr].hasOwnProperty('validators')) {
                let hasError = false;
                fields[attr].validators.forEach(function (func) {
                    if (!func.call(this, fields[attr].value)) {
                        hasError = true;
                        isValid = false;
                        console.log(attr);
                    }
                });
                if (!fields[attr].required && fields[attr].value.length === 0) {
                    hasError = null
                }

                fields[attr].hasError = hasError;
            }
        }
        return isValid;
    };

    submitForm = (e) => {

        e.preventDefault();

        if (this.validate()) {
            let fields = this.state.fields;

            let body = new FormData();

            body.append('abnum', fields.phone_number.value.replace(/\D/gim, '').slice(1));
            body.append('fio', fields.fio.value);
            body.append('email', fields.email.value);
            body.append('comment', fields.comment.value);
            body.append('date', fields.days.value + '.' + (fields.month.value < 10 ? '0'+(fields.month.value + 1) : (fields.month.value + 1)) + '.' + fields.year.value);
            body.append('time', fields.time.value);
            body.append('scammer_info', fields.scammer_info.value);
            body.append('amount', fields.amount.value);
            if (this.state.file) body.append('file', this.state.file);

            this.props.sendSupportForm(body)
        }

    };

    renderField = (name) => {
        let field = {...this.state.fields[name]};
        let input = null;

        switch (name) {
            case "days" :
            case "month":
            case "year":
                return  <div className="b-input-counter b-input-counter_adapt_default">
                            <div className="b-input-counter__wrapper">
                                <div className="b-input-counter__input-area">
                                    <input className="b-input-counter__input"
                                           name={field.name}
                                           type="text"
                                           readOnly={field.readOnly}
                                           value={name == "month" ? field.value + 1 : field.value}
                                    />
                                    <div className="b-input-counter__counter-block">
                                        {this.renderArrow(name, 'increment')}
                                        {this.renderArrow(name, 'decrement')}
                                    </div>
                                </div>
                            </div>
                        </div>;
            default:
                input = <div className="b-input-text__input-area">
                            <InputIcon error={field.hasError}/>
                            <input className={"b-input-text__input" + (field.hasError ? " has-error" : "")}
                                   type="text"
                                   name={field.name}
                                   value={field.value}
                                   onChange={this.onFieldChange(name)}
                                   readOnly={field.readOnly}
                                   onBlur={this.onBlur(name)}
                                   onFocus={this.onFocus(name)}
                            />
                            <InputError hasError={field.hasError} errorText={field.textError}/>
                        </div>;
        }

        return  <div className={"b-input-text b-input-text_adapt_small" + (field.required ? " b-input-text_required" : "")}>
                    <div className="b-input-text__wrapper">
                        <div className="b-input-text__label">
                            <div className="b-input-text__label-text">{field.label}</div>
                        </div>
                        {input}
                    </div>
                </div>
    };

    render() {

        let step = this.state.step;
        let sms = this.props.sms;
        let support = this.props.support;

        let {
            security_agreement_checkbox,
            upload_file_comment
        } = this.props.settings;

        return  <Modal className="u-modal u-modal_active u-modal-top tariff-connection-modal" close={this.props.close}>
            <div className="b-form-spam b-form-spam_adapt_default">
                <div className="b-form-spam__wrapper">
                    <div className="b-form-spam__title">Сообщить о мошенничестве</div>
                    <div className="b-form-spam__form">
                        <form>
                            <div className="b-form-spam__form-body">
                                <div className="b-form-spam__row">
                                    <div className="b-form-spam__row-title">Ваши личные данные</div>
                                    <div className="b-form-spam__input b-form-spam__input_full-width">
                                        {this.renderField('fio')}
                                    </div>
                                    <div className="b-form-spam__input b-form-spam__input_half">
                                        {this.renderField('phone_number')}
                                    </div>
                                    <div className="b-form-spam__input b-form-spam__input_half">
                                        {this.renderField('email')}
                                    </div>
                                </div>
                                <div className="b-form-spam__row">
                                    {
                                        step === 1
                                        ?   <div className={"b-form-spam__btn u-btn u-btn_default u-btn_responsive u-btn_adapt_mobile-sm" + (this.validateField("phone_number") === false && sms.inProgress === false ? "" : " disabled")}
                                                  onClick={this.sendSms}
                                            >
                                                Подтвердить номер телефона
                                            </div>
                                        :   ""
                                    }
                                    {
                                        step === 2
                                             ?   <React.Fragment>
                                                    <div className={"b-form-spam__input b-form-spam__input_full-width"}>
                                                        <div className="text-lg">{sms.data.message ? sms.data.message : "SMS с кодом подтверждения отправлено на указанный номер, введите его в поле ниже."}</div>
                                                    </div>
                                                    <div className={"b-form-spam__input_third"}>
                                                            <div className="code-field">
                                                                {this.renderField("code")}
                                                            </div>
                                                            {this.state.timer
                                                                ? <div className={"orange"}>Заказать код повторно можно через <Timer callbackFunc={this.timerEnd}/> секунд</div>
                                                                : <div className={"link-accent pointer"} onClick={this.sendSms}>Выслать еще раз</div>
                                                            }
                                                            <button onClick={this.verifyCode} type="submit" className={"b-form-spam__btn-confirm-code u-btn u-btn_default u-btn_adapt_mobile-sm" + (this.validateField("code") === false && sms.inProgress === false ? "" : " disabled")}>Подтвердить</button>
                                                    </div>
                                                 </React.Fragment>

                                                :   ""
                                    }
                                    {
                                        step === 'error'
                                        ?   <div className={"b-form-spam__input b-form-spam__input_full-width"}>
                                                <div className="text-lg">{sms.data.message ? sms.data.message : "Неизвестная ошибка."}</div>
                                            </div>
                                        :   ""
                                    }

                                </div>
                                <div className={"b-form-spam__row" + (step === 3 ? "" : " disabled")}>
                                    <div className="b-form-spam__row-title">Подробности мошенничества</div>
                                    <div className="b-form-spam__input-group b-form-spam__input-group_calendar">
                                        <div className="b-form-spam__input-group-label">ДАТА ИНЦИДЕНТА</div>
                                        <div className="b-form-spam__input-group-inner">
                                            <div className="b-form-spam__input b-form-spam__input_calc-day">
                                                {this.renderField('days')}
                                            </div>
                                            <div className="b-form-spam__input b-form-spam__input_calc-month">
                                                {this.renderField('month')}
                                            </div>
                                            <div className="b-form-spam__input b-form-spam__input_calc-year">
                                                {this.renderField('year')}
                                            </div>
                                            <div className="b-form-spam__calendar">
                                                <Icon name={'icon_calendar2'} className={'b-form-spam__calendar-icon u-icon u-icon_calendar2'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="b-form-spam__input b-form-spam__input_incedent">
                                        <div className="b-form-spam__input-incedent">
                                            {this.renderField('time')}
                                        </div>
                                    </div>
                                    <div className="b-form-spam__input b-form-spam__input_half">
                                        {this.renderField('scammer_info')}
                                    </div>
                                    <div className="b-form-spam__input b-form-spam__input_half">
                                        {this.renderField('amount')}
                                    </div>
                                    <div className="b-form-spam__input b-form-spam__input_full-width b-form-spam__input_text-area">
                                        <div className="b-input-textarea b-input-textarea_adapt_default b-input-textarea b-input-textarea_required">
                                            <div className="b-input-textarea__label">
                                                <div className="b-input-textarea__label-text">ПОДРОБНОСТИ
                                                    МОШЕННИЧЕСТВА
                                                </div>
                                            </div>
                                            <div className="b-input-textarea__textarea-wrap">
                                                <textarea className="b-input-textarea__textarea" onChange={this.onFieldChange('comment')}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"b-form-spam__form-footer" + (step === 3 ? "" : " disabled")}>
                                <div className="b-form-spam__attach-area">
                                    <label className="b-form-spam__attach-label">
                                        <span className="b-form-spam__attach-icon-area">
                                            <Icon name={'icon_clip'} className={'b-form-spam__b-form-spam__attach-icon u-icon u-icon_clip'} />
                                        </span>
                                        {this.state.file ? this.state.file.name : "Прикрепить документ"}
                                        <input className="b-form-spam__attach-input" type="file" onChange={this.onFileChange}/>
                                    </label>
                                    <span className="b-form-spam__description">{upload_file_comment}</span>
                                    <div className="b-step-form__block b-step-form__block_checkbox-agree">
                                        <div className="b-step-form__checkbox-item">
                                            <div className="b-input-checkbox b-input-checkbox_adapt_mobile-small">
                                                <label className="b-input-checkbox__wrap" htmlFor="checkbox_agree">
                                                    <input className="b-input-checkbox__input"
                                                           type="checkbox"
                                                           id="checkbox_agree"
                                                           onChange={this.toggleAgreement}
                                                           checked={this.state.agreement ? "checked" : false}
                                                    />
                                                    <span className="b-input-checkbox__icon"/>
                                                    <span
                                                        className="b-input-checkbox__text"
                                                        dangerouslySetInnerHTML={{ __html: security_agreement_checkbox }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="b-form-spam__submit-btn-area">
                                    <div className="b-form-spam__description">
                                        <div className="b-form-spam__req">*</div>
                                        – поля обязательные для заполнения
                                    </div>
                                    <a onClick={this.submitForm} className={"b-form-spam__submit-btn u-btn u-btn_lg-responsive u-btn_default"+(!this.state.agreement || !this.validate() || support.inProgress ? " disabled" : "")} href="#">Отправить</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
                </Modal>
    }
}

export default connect(
    state => ({
        sms: state.sms,
        support: state.support
    }),
    (dispatch) => {
        return {
            sendSms: (phone) => dispatch(sms(phone)),
            verifySms: (code, query_id) => dispatch(verifySms(code, query_id)),
            sendSupportForm: (data) => dispatch(support(data))
        }
    }
)(SecurityModal);
