const initState = { isOk: false, list: [], listImages: [], imageData: [], mnpBonuses: [] };

export function infoBlocks(state = initState, action) {
    switch (action.type) {
        case 'INFO_BLOCKS_LIST':
            return { ...state, isOk: false };
        case 'INFO_BLOCKS_LIST_SUCCESS':
            let infoBlocks = [...state.list];
            action.data.filter((item) => {
                if (infoBlocks.filter((elem) => {
                    return item.alias === elem.alias
                }).length === 0) {
                    infoBlocks.push(item);
                }
            });
            return { ...state, list: infoBlocks, isOk: true };

        case 'INFO_BLOCKS_IMAGES_LIST':
            return { ...state, isOk: false };
        case 'INFO_BLOCKS_IMAGES_LIST_SUCCESS':
            return { ...state, listImages: action.data, isOk: true };

        case 'INFO_BLOCK_IMAGE':
            return { ...state, imageData: [], isOk: false };
        case 'INFO_BLOCK_IMAGE_SUCCESS':
            return { ...state, imageData: action.data, isOk: true };

        case 'MNP_BONUSES':
            return { ...state, isOk: false };
        case 'MNP_BONUSES_SUCCESS':
            return { ...state, isOk: true, mnpBonuses: [...action.data] };
    }
    return state;
}