import React, { Component } from "react";

export default class CheckboxNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: !!props.value
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.value !== this.state.checked) {
            this.setState({checked: !!nextProps.value})
        }
    }

    onChange = () => {
        this.setState((prevState) => {
            return {
                checked: !prevState.checked
            }
        }, () => {
            this.props.onChange(this.props.name, this.state.checked);
        });
    };

    render() {

        let {
            checkboxClick = () => { },
            name,
            label,
            children,
            disabled,
            disabledRqs = false,
            forced,
        } = this.props;

        let checked = this.state.checked;

        return (
            <div className={"switcher-wrap" + (checked ? " checked" : "") + (disabled ? " disabled" : "") + (forced ? " forced" : "")}>
                <div className="switcher-input" onClick={checkboxClick}>
                    <input
                        id={name}
                        className="checkbox-input"
                        type="checkbox"
                        checked={forced || this.state.checked}
                        onChange={this.onChange}
                        disabled={disabled || disabledRqs}
                    />
                    <label className="switcher-input__label" htmlFor={name}  />
                </div>
            </div>
        )
    }
}
