const initState = { isOk:false, completeSetsList: [] };

export function completeSets(state = initState, action) {
    switch (action.type) {
        case 'COMPLETE_SETS_LIST':
            return {...state, isOk: false};
        case 'COMPLETE_SETS_LIST_SUCCESS':
            return {...state, completeSetsList: action.data, isOk: true};
    }
    return state;
}