import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ReactDOM from 'react-dom';
import SwitcherInputNew from "../common/SwitcherInputNew";
import { Social } from "../common/TariffDescription"
import Icon from "../common/Icon";
import RateListItemNew from '../Rates/RateListItemNew';

class RateListItemAlljs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: true
        };

        this.sortRef = React.createRef();
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        pushHistory: PropTypes.func,
        setHasItemModal: PropTypes.func
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    isOpen = () => {
        this.setState({
            opened: !this.state.opened,
        });
    }

    render() {
        let {
            opened
        } = this.state

        let {
            checkboxClick,
            nameData,
            valueData,
            onChangeData,
            nameRouter,
            valueRouter,
            onChangeRouter,
            nameSocial,
            valueSocial,
            onChangeSocial,
            nameVoice,
            valueVoice,
            onChangeVoice,
            nameYoutube,
            valueYoutube,
            onChangeYoutube,
            nameIvi,
            valueIvi,
            onChangeIvi,
            nameHome,
            valueHome,
            onChangeHome,
            currentSmsPack,
            optionSmsArr,
            chOn,
            disabledRqs,
            maxFixedPrice
        } = this.props

        let {
            name,
            category,
        } = this.props.data

        return (
            <div className="detail_wrapper">
                <div className="detail_title_wrapper" onClick={() => this.isOpen()}>
                    <button className="detail_btn_tabs">
                        <Icon
                            name="icon_arrow-down"
                            className={`detail_icon ${opened ? ' active' : ''}`}
                        />
                    </button>
                    <h4 className="detail_title">{name}</h4>
                </div>

                <div className={`detail_item_all ${opened ? 'active' : ''}`}>
                    {category && category.map((item) =>
                        <RateListItemNew
                            key={item.type}
                            data={item}
                            maxFixedPrice={maxFixedPrice}

                            checkboxClick={checkboxClick}

                            nameData={nameData}
                            valueData={valueData}
                            onChangeData={onChangeData}

                            nameRouter={nameRouter}
                            valueRouter={valueRouter}
                            onChangeRouter={onChangeRouter}

                            nameSocial={nameSocial}
                            valueSocial={valueSocial}
                            onChangeSocial={onChangeSocial}

                            nameVoice={nameVoice}
                            valueVoice={valueVoice}
                            onChangeVoice={onChangeVoice}

                            nameYoutube={nameYoutube}
                            valueYoutube={valueYoutube}
                            onChangeYoutube={onChangeYoutube}

                            nameIvi={nameIvi}
                            valueIvi={valueIvi}
                            onChangeIvi={onChangeIvi}

                            nameHome={nameHome}
                            valueHome={valueHome}
                            onChangeHome={onChangeHome}

                            currentSmsPack={currentSmsPack}
                            optionSmsArr={optionSmsArr}
                            toggleSmsPack={this.props.toggleSmsPack}

                            chOn={chOn}
                            disabledRqs={disabledRqs}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({

    }),
    (dispatch) => {
        return {

        }
    }
)(RateListItemAlljs);
