import React, { Component } from 'react';
import PropTypes from "prop-types";
import RatesList from "./RatesList";

class RatesByCategories extends Component {

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    tariffsByCategories (tariffs, region_id, rank_field)
    {
        let categories = [];
        let result = [];

        tariffs.forEach(function (elem) {
            if (elem.region_id === region_id) {
                categories[elem.mainCategory.id] = elem.mainCategory;
            }
        });

        categories = categories.filter(x => x[rank_field] !== null);

        categories = categories.sort(function (catA, catB) {
            return catB[rank_field] - catA[rank_field];
        });

        categories.forEach(function (cat, i) {

            let obj = {title: '', items : []};

            obj.title = cat.title;
            result[i] = obj;

            tariffs.forEach(function (elem) {
                if (cat.id === elem.mainCategory.id && elem.region_id === region_id) {
                    result[i].items.push(elem);
                }
            });
        });

        return result;
    }

    sortByCatRank (items) {
        return items.sort(function (t1, t2) {
            return t2.rank_categories - t1.rank_categories;
        })
    }

    render() {

        let region = this.context.selectedRegion;
        let {
            tariffs,
            rankField,
            isChooseItem = false,
            currentPage,
            onClickConnect = ()=> {},
            showCategoryTitle = true
        } = this.props;

        tariffs = this.tariffsByCategories(tariffs, region.id, rankField);

        return (
            <React.Fragment>
                {tariffs.length ? tariffs.map((item, key) =>
                    <div className="rate-category" key={key}>
                        <h3 className="page__title-3" id={key + 1}>{showCategoryTitle ? item.title : ''}</h3>
                        <RatesList
                            rates={this.sortByCatRank(item.items)}
                            currentPage={currentPage} byCat={true}
                            selectedRegion={region}
                            isChooseItem={isChooseItem}
                            onClickConnect={onClickConnect}
                        />
                    </div>
                ) : <h3 className="page__title-3">Тарифов не найдено</h3>}
            </React.Fragment>
        )
    }
}

export default RatesByCategories;