/**
 * @param count
 * @returns {function(*): *}
 */
export function numberMask(count = 0) {
    return (value) => {
        if (!value) return "";
        value = value.replace(/\D/gim, '');
        if (count && value.length > count) value.slice(0, count);
        return value;
    }
}

export function priceMask(value) {
    if (!value) return "";
    value = value.toString().replace(/\D/gim, '');
    var valArr = value.split('');
    valArr.reverse();
    var resArr = [];
    for (var i = 0; i < valArr.length; i++){
        if (i != 0 && i % 3 == 0) resArr.push(" ");
        resArr.push(valArr[i]);
    }
    return resArr.reverse().join('');
}

export function serverDateFormatMask(date) {
    if (!date) return '';
    console.log(date);
    return date.split('.').reverse().join('-');
}

export function phoneMask(value) {
    var next = value.replace(/[^+0-9]/gim, '');
    var arr = next.split('');

    if (arr[0] && arr[0] == 8) {
        arr.splice(0, 1, '+');
        arr.splice(1, 0, 7);
    }
    if (arr[0] && arr[0] != '+') {
        arr.splice(0, 0, '+');
    }
    if (arr[1] && arr[1] != 7) {
        arr.splice(1, 0, 7);
    }
    arr = arr.slice(0,12);
    var str = '';
    for (var i = 0; i < arr.length; i++) {
        switch (i) {
            case 2:
            case 5:
            case 8:
            case 10:
                str += ' '+arr[i];
                break;
            default:
                str += arr[i];
        }
    }
    return str;
}

export function documentNumberMask(value) {
    var next = value.replace(/\D/gim, '');
    var arr = next.split('');

    arr = arr.slice(0,10);
    var str = '';
    for (var i = 0; i < arr.length; i++) {
        switch (i) {
            case 2:
            case 4:
                str += ' '+arr[i];
                break;
            default:
                str += arr[i];
        }
    }
    return str;
}

export function unitCodeMask(value) {
    var next = value.replace(/\D/gim, '');
    var arr = next.split('');

    arr = arr.slice(0,6);
    var str = '';
    for (var i = 0; i < arr.length; i++) {
        switch (i) {
            case 3:
                str += ' '+arr[i];
                break;
            default:
                str += arr[i];
        }
    }
    return str;
}

export function onlyCyrillicChars (value) {
    return value.replace(/[^а-яё\s]/i, "")
}

export function onlyCyrillicCharsNumbersDash (value) {
    return value.replace(/[^а-яё0-9-\s]/i, "")
}

export function onlyLetters (value) {
    return value.replace(/[^а-яА-ЯёЁa-zA-Z\s]+$/, "")
}

export function dateFormatMask(next = '') {

    next = next.replace(/\./gi, '');
    if (next.length > 8) next = next.substr(0, 8);
    let arr = next.split('');
    //day
    if (arr.length && arr[0] > 3) arr.splice(0, 0, 0);
    if (arr.length > 1 && arr[0] == 3 && arr[1] > 1) arr.splice(0, 0, 0);
    //month
    if (arr.length > 2 && arr[2] > 1) arr.splice(2, 0, 0);
    if (arr.length > 3 && arr[2] == 1 && arr[3] > 2) arr.splice(2, 0, 0);
    //year
    let now = new Date(Date.now());
    let currYear = (now.getFullYear() + '').split('');
    if (arr.length > 4 && arr[4] > currYear[0]) arr.splice(4, 1);
    if (arr.length > 5 && arr[4] == currYear[0] && arr[5] > currYear[1]) arr.splice(5, 1);
    if (arr.length > 6 && arr[4] == currYear[0] && arr[5] == currYear[1] && arr[6] > currYear[2]) arr.splice(6, 1);
    if (arr.length > 7 && arr[4] == currYear[0] && arr[5] == currYear[1] && arr[6] == currYear[2] && arr[7] > currYear[3]) arr.splice(7, 1);
    var res = '';

    for (var i = 0; i < Math.min(arr.length, 10); i++) {
        if (!isNumeric(arr[i])) continue;
        switch (i) {
            case 2:
            case 4:
                res += '.' + arr[i];
                break;
            default:
                res += arr[i];
        }
    }
    return res;
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function timeFormatMask (val) {
    val = val.replace(/[^\d:]/, "");
    let arr = val.split(":");

    if (arr[0] >= 0) {
        if (arr[0].substr(0, 2) > 23) {
            arr[1] = arr[0].substr(1)+(arr[1] ? arr[1] : "");
            arr[0] = arr[0].substr(0,1);
        } else {
            arr[1] = arr[0].substr(2)+(arr[1] ? arr[1] : "");
            arr[0] = arr[0].substr(0, 2);
        }
    }

    if (arr[1] >= 0) {
        if (arr[1].substr(0, 2) > 59) {
            arr[1] = '0'+arr[1].substr(0, 1);
        } else {
            arr[1] = arr[1].substr(0, 2);
        }
    }

    return arr.join(":");
}

export function timeBlurMask (val) {
    val = val.replace(/[^\d:]/, "");
    let arr = val.split(":");

    if (!arr[0]) {
        arr[0] = '00';
    }

    if (arr[0].length == 1) {
        arr[0] = '0'+arr[0];
    }

    if (!arr[1]) {
        arr[1] = '00';
    }
    if (arr[1].length == 1) {
        arr[1] = '0'+arr[1];
    }

    return arr.join(":");
}

export function datePretty(value, showTime = false) {
    if (!value) return '-';
    var date = new Date(value);
    var result = date.toLocaleDateString('ru', {timeZone: 'UTC'});

    if (showTime) {
        result += ' '+date.toLocaleTimeString('ru', {timeZone: 'UTC', minute: 'numeric', hour: 'numeric'})
    }

    return result;
}