import objectFitImages from "object-fit-images";

const initState = {
    flagValue: '',
    userPhoneValue: '',
    tariffConnectionValue: 'quick',
    timerValue: true,
    connectionType: '',
    advertisingLabelingMobToggle: false,
    advertisingLabelingMobGetData: {},
};

export function common(state = initState, action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            let doNotScroll = action.payload.state !== null && typeof action.payload.state !== "undefined" && action.payload.state.hasOwnProperty('doNotScroll');
            if (typeof document !== 'undefined' && doNotScroll !== true) {
                window.scrollTo(0, 0);
            }
            objectFitImages();

        case 'FLAG_VALUE':
            return {...state, flagValue: action.data};
            
        case 'USER_PHONE_VALUE':
            return {...state, userPhoneValue: action.data};

        case 'TARIFF_CONNECTION_VALUE':
            return {...state, tariffConnectionValue: action.data};

        case 'TIMER_VALUE':
            return {...state, timerValue: action.data};

        case 'TARIFF_CONNECTION_TYPE':
            return {...state, connectionType: action.data};
        
        case 'ADVERTISING_LABELING_MOB_TOGGLE':
            return {...state, advertisingLabelingMobToggle: action.data};
            
        case 'ADVERTISING_LABELING_MOB_GET_DATA':
            return {...state, advertisingLabelingMobGetData: action.data};
    }
    
    return state;
}