import {
    call,
    put
} from 'redux-saga/effects';

import {
    checkErrors,
    fetchClientApi
} from '../tools/api-helper';

export function infoBlocksList(region, group, tariff) {
    return {
        type: 'INFO_BLOCKS_LIST',
        region,
        group,
        tariff
    }
}

export function* fetchInfoBlocksList(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi("/infoblocks" + (action.region ? '?region=' + action.region : '') + (action.group ? '&group=' + action.group : '') + (action.tariff ? '&tariff_id=' + action.tariff : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }
    } catch (e) {
        console.log(e);
    }
}


export function infoBlocksImagesList() {
    return {
        type: 'INFO_BLOCKS_IMAGES_LIST'
    }
}

export function* fetchInfoBlocksImagesList(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi("/infoblocks?group=independent_banners", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function infoBlockImage(id) {
    return {
        type: 'INFO_BLOCK_IMAGE',
        id,
    }
}

export function* fetchInfoBlockImage(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi("/infoblocks?id=" + action.id, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }
    } catch (e) {
        console.log(e);
    }
}

export function getMnpBonuses() {
    return {
        type: 'MNP_BONUSES',
    }
}

export function* fetchMnpBonuses(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi("/mnp-bonuses", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}