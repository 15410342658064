import React, { Component } from "react";
import Radio from "../common/Radio";
import CheckBox from "../common/CheckBox";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";

export class RenderField extends Component {

    onBlur = (name) => {
        if (this.props.onblur) {
            this.props.onblur(name);
        }
    };

    render() {

        let {
            field,
            children,
            onChange,
        } = this.props;

        switch (field.type) {
            case 'radio':
                return <Radio
                    className={"mnp-contacts-info__radios" + (field.className ? " " + field.className : "")}
                    name={field.name}
                    items={field.items}
                    value={field.value}
                    onChange={onChange}
                />;
            case 'checkbox':
                return <CheckBox name={field.name}
                    label={field.label} value={field.value} onChange={onChange} />;
            case 'textarea':
                return <div className={"input__wrap"}>
                    <div className="input__label">{field.label}</div>
                    <textarea rows="6" {...field} className={(field.className ? " " + field.className : "")} onChange={(e) => {
                        onChange(field.name, e.target.value)
                    }} />
                    {children}
                </div>;
            case 'password':
                return <div className={"input__wrap" + (field.className ? " " + field.className : "")}>
                    <div className="input__label">{field.label}</div>
                    <div className={"b-input-text__input-area"}>
                        <InputIcon error={field.hasError} className={"b-input__change-rate"} />
                        <input
                            placeholder={field.placeholder ? field.placeholder : ""}
                            name={field.name}
                            disabled={field.disabled}
                            className={"b-input-text__input" + (field.hasError ? " has-error" : "")}
                            required={field.required}
                            autoComplete="off"
                            value={field.value}
                            type={field.type}
                            onChange={(e) => {
                                onChange(field.name, e.target.value)
                            }}
                            onBlur={() => {
                                this.onBlur(field.name)
                            }}
                        />
                        <InputError hasError={field.hasError} errorText={field.textError} className={"b-input__change-rate"} />
                        {children}
                    </div>
                </div>;
            default:
                return <div className={"input__wrap" + (field.className ? " " + field.className : "")}>
                    <div className="input__label">{field.label}</div>
                    <div className={"b-input-text__input-area"}>
                        <InputIcon error={field.hasError} className={"b-input__change-rate"} />
                        <input
                            placeholder={field.placeholder ? field.placeholder : ""}
                            name={field.name}
                            disabled={field.disabled}
                            className={"b-input-text__input" + (field.hasError ? " has-error" : "")}
                            required={field.required}
                            autoComplete="off"
                            value={field.value}
                            onChange={(e) => {
                                onChange(field.name, e.target.value)
                            }}
                            onBlur={() => {
                                this.onBlur(field.name)
                            }}
                        />
                        <InputError hasError={field.hasError} errorText={field.textError} className={"b-input__change-rate"} />
                        {children}
                    </div>
                </div>
        }
    }
}

export function onChange(name, value) {
    let fields = { ...this.state.fields };
    let field = fields[name];
    field.value = field.mask ? field.mask(value) : value;
    fields[name] = field;
    this.setState({ fields });
}