import React, { Component } from 'react';
import PropTypes from "prop-types";

class LinkC extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    render() {

        let {
            to,
            func,
            noFollow,
            children,
            className,
            targetBlank,
            onMouseEnter =()=>{},
            onMouseLeave =()=>{},
            style = {}
        } = this.props;

        let link = '';
        let id = null;

        let fetchBefore = this.context.fetchBefore;

        if (to.indexOf('http') > -1 || to.indexOf('viber') > -1) {
            link = <a style={style} href={to} target={targetBlank ? "_blank" : ""} className={className} rel={noFollow ? 'nofollow' : ''}>{children}</a>
        } else {
            if (typeof func === 'function') {
                link = <a style={style} onClick={func} href="#" target={targetBlank ? "_blank" : ""} className={className} rel={noFollow ? 'nofollow' : ''}>{children}</a>
            } else {

                let linkItems = to.split('/');
                if (linkItems.length > 2) {
                    if (linkItems[1] === 'services' && linkItems[2] !== 'archive') {
                        id = linkItems[2];
                    } else {
                        id = linkItems[3];
                    }
                }
                if (targetBlank) {
                    link = <a style={style} href={"/"+this.context.selectedRegion.alias+to} target={targetBlank ? "_blank" : ""} className={className} rel={noFollow ? 'nofollow' : ''}>{children}</a>
                } else {
                    link = <a style={style} href={"/"+this.context.selectedRegion.alias+to}
                                className={className}
                                onClick={fetchBefore("/"+this.context.selectedRegion.alias+to, id)}
                                rel={noFollow ? 'nofollow' : ''}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                            >
                        {children}
                    </a>
                }
            }
        }

        return (
            <React.Fragment>
                {link}
            </React.Fragment>
        )
    }
}

export default LinkC;
