import React, {Component} from 'react';
import TopBlock from "../../components/Lk/TopBlock";
import {connect} from "react-redux";
import LinkC from "../../components/common/LinkC";
import {mainUrl, mediaUrl, lkUrl, b2bUrl} from "../../tools/config";

import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";

class Payments extends Component{

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isLk: PropTypes.bool,
    };

    render(){

        let {
            tariff,
            balance,
            paymentLink
        } = this.props.clientInfo;
        let detailsMenuItem = this.props.lkMenu.find((item) => item.alias === "details");

        // получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'paymentsLKRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        // получаем параметры для перехода на другой сайт
        let region = this.context.selectedRegion.alias;
        let regionSlug = this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug;
        let detailUrl = (regionSlug ? "/" + regionSlug : "") + "/payments-transfers";
        let detailUrlLk = (regionSlug ? regionSlug : region) + "/payments-transfers";
        let detailDeferredPageUrl = (regionSlug ? "/" + regionSlug : "") + "/deferred-payment";
        let detailDeferredPageUrlLk = (regionSlug ? regionSlug : region) + "/deferred-payment";

        return <div>
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <TopBlock title="Настройка оплаты"/>
            <div className="page__content-simple page__content-simple_adapt_default">
                <div className="b-lk-number__tariff b-lk-number__payments">
                    <div className="b-lk-number__row">
                        <div className="b-lk-number__tarif-left b-lk-number__payments-left">
                            <div className="b-lk-number__name">Баланс <span className="date">на {(new Date()).toLocaleDateString()}</span></div>
                            {balance ? <div className="b-lk-number__val balance-val">{balance.value} <span class="price-rub">₽</span></div> : null}
                        </div>
                        <div className="b-lk-number__tarif-right b-lk-number__payments-right">
                            <div className="b-lk-number__name">
                                Абонентская плата:
                                <div className="b-lk-number__val">
                                    {tariff && (tariff.details || tariff.fee) ?
                                        <React.Fragment>{(tariff.details && tariff.details.fee || tariff.fee)}<span className="price-rub"> ₽</span></React.Fragment>
                                    : "-"}
                                </div>
                            </div>
                            <div className="b-lk-number__name">
                                Следующий платеж:
                                <div className="b-lk-number__val">{tariff && tariff.charge_date ? (new Date(tariff.charge_date)).toLocaleDateString() : "-"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="b-lk-number__link-row" key="tariff-change">
                        <LinkC className="b-lk-number__link" to={this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl}>Пополнить баланс</LinkC>
                    </div>
                    <div className="b-lk-number__link-row" key="tariff-change">
                        <LinkC className="b-lk-number__link" to={this.context.isLk ? (mainUrl + detailDeferredPageUrlLk) : detailDeferredPageUrl}>Отложенный платеж</LinkC>
                    </div>
                    <div className="b-lk-number__link-row" key="redirect">
                        <LinkC className="b-lk-number__link" to={detailsMenuItem ? detailsMenuItem.link : "#"}>Заказ детализации</LinkC>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default connect(
    state => ({
        clientInfo: state.client.clientInfo,
        lkMenu: state.client.lkMenu,

        routes: state.routes,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
    })
)(Payments)