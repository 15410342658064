import {
    call,
    put,
    take
} from 'redux-saga/effects';
import {
    fetchClientApi,
    fetchClientLkApi,
    checkErrors
} from '../tools/api-helper';
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {getCookie} from '../tools/tools';
import {checkToken} from "./client";

// экшэн Пополнить баланс
export function paymentsMethodsAction(data, regionId = null) {
    return {
        type: 'PAYMENTS_METHODS',
        regionId,
        data
    }
}

// сага Пополнить баланс
export function* fetchPaymentsMethods(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi(`/top-up-balance/${action.regionId}`, {
                method: 'POST',
                body: action.data
            })
        });

        if(status === 200){
            window.location.href = data && data.form_url;
        }

        if(error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

// экшэн Пополнить баланс Spb
export function paymentsMethodsSpbAction(data, regionId = null, mode = '', transition = false) {
    return {
        type: 'PAYMENTS_METHODS_SPB',
        data,
        regionId,
        mode,
        transition,
    }
}

// сага Пополнить баланс Spb
export function* fetchPaymentsMethodsSpbSaga(action) {
    try {

        const {status, data, error} = yield call(() => {
            return fetchClientApi(`/payment-sbp/${action.regionId}`, {
                method: 'POST',
                body: action.data
            })
        });

        if((data && data.http_code === 200) && (action && action.mode === 'MOBILE') && (action && action.transition)){
            window.location.href = data && data.form_url;
        }
        
        if(error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

// очищает ссылку которая появляется после нажатия на кнопку ПОДЕЛИТЬСЯ
export function clearDataPaymentsMethodsSpbAction() {
    return {
        type: 'CLEAR_DATA_PAYMENTS_METHODS_SPB',
    }
}

export function clearPaymentsMethodsData() {
    return {
        type: 'CLEAR_PAYMENTS_METHODS_DATA'
    }
}

// экшэн запрос для заполнения селекта
export function paymentTypesAction() {
    return {
        type: 'PAYMENT_TYPES'
    }
}
// сага запрос для заполнения селекта
export function* fetchPaymentTypesSaga(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/payment-types", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
// 

export function otherPaymentMethods() {
    return {
        type: 'PAYMENTS_OTHER_PAYMENT_METHODS',
    }
}

export function* fetchOtherPaymentMethods(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/other-payment-methods", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
//
export function paymentMethods() {
    return {
        type: 'PAYMENT_METHODS',
    }
}

export function* fetchPaymentMethods(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/payment-methods", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
//
export function paymentLimits(phone = null, regionId = null) {
    return {
        type: 'PAYMENTS_LIMITS',
        regionId,
        phone
    }
}

export function* fetchPaymentLimits(action) {
    try {
        yield put(checkToken());
        yield take("TOKEN_CHECKED")

        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/payment-limits/${action.regionId}${action.phone ? `?phone=${action.phone}` : ''}`, {
                method: 'GET',
                token: getCookie("access_token")
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}
// 
export function motivPayPageAction() {
    return {
        type: 'MOTIV_PAY_PAGE',
    }
}

export function* fetchMotivPayPageSaga(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/page/motivPayRoute", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
// 
export function deferredPaymentPageAction() {
    return {
        type: 'DEFERRED_PAYMENT_PAGE',
    }
}

export function* fetchDeferredPaymentPageSaga(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/page/deferredPaymentRoute", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
// 
export function paymentTransfersPageAction() {
    return {
        type: 'PAYMENT_TRANSFERS_PAGE',
    }
}

export function* fetchPaymentTransfersPageSaga(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/page/paymentsTransfersRoute", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
// 
export function paymentsMethodPageAction() {
    return {
        type: 'PAYMENT_METHOD_PAGE',
    }
}

export function* fetchPaymentMethodPageSaga(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/page/paymentsMethodsRoute", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}