import React, { Component } from 'react';
import PropTypes from "prop-types";
import LinkC from "../../../js/components/common/LinkC";
import { Link } from "react-router-dom";

class InfoBlockLastNews extends Component {

  static contextTypes = {
    selectedRegion: PropTypes.object,
  };

  render() {

    const selectedRegion = this.context.selectedRegion.alias;
    let { newsBasic } = this.props.data;
    let { list } = this.props.linkPageNews;

    const routeAllNews = list && list.find(x => x.alias === 'news');

    return (
      <div className="info-block-last-news">
        <div className="info-block-last-news__title">Новости</div>

        <div className="info-block-last-news__all-card-news">
          {newsBasic.slice(0, 4).map((el) =>
            <Link key={el.id} className={"info-block-last-news__card-news"} to={'/' + selectedRegion + routeAllNews.link + '/' + el.id}>
              {el && el.photo &&
                <div className="info-block-last-news__wrap-img">
                  <img className="info-block-last-news__img" src={el.photo} alt="Изображение новости 333" />
                </div>
              }

              {el && el.date && <div className="info-block-last-news__text-date" dangerouslySetInnerHTML={{ __html: el.date }} />}
              {el && el.title && <div className="info-block-last-news__card-title" dangerouslySetInnerHTML={{ __html: el.title }} />}
              {el && el.short_description && <div className="info-block-last-news__card-description" dangerouslySetInnerHTML={{ __html: el.short_description }} />}
            </Link>
          )}
        </div>

        <div className="info-block-last-news__wrap-link-all-news">
          <LinkC to={"/news"} className="info-block-last-news__link-all-news">Смотреть все</LinkC>
        </div>
      </div>
    )
  }
}

export default InfoBlockLastNews;