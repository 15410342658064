import {
    call,
    put,
    take
} from 'redux-saga/effects';
import {
    fetchClientApi,
    fetchClientLkApi,
    checkErrors
} from '../tools/api-helper';
import {hideLoading, showLoading} from "react-redux-loading-bar";

// 
export function promotionsClearPageAction() {
    return {
        type: 'PROMOTIONS_CLEAR_PAGE',
    }
}

export function promotionsPageAction(regionId, categoryId = null, pageNum = null, is_mobile = null) {
    return {
        type: pageNum ? 'PROMOTIONS_PAGE_NEW' : 'PROMOTIONS_PAGE',
        regionId,
        categoryId,
        pageNum,
        is_mobile
    }
}

export function* fetchPromotionsPageSaga(action) {
    try {
        yield put(showLoading());

        let params = [];
        if (action.regionId) {
            params.push(`region_id=${action.regionId}`);
        }
        if (action.categoryId) {
            params.push(`category_id=${action.categoryId}`);
        }
        if (action.pageNum) {
            params.push(`page=${action.pageNum}`);
        }
        if (action.is_mobile) {
            params.push(`is_mobile=${action.is_mobile}`);
        }
        const { status, data, error, pagination } = yield call( () => {
            return fetchClientApi(`/actions?${params.length ? params.join('&') : ''}`, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
            yield put({type: 'PROMOTIONS_PAGE_PAGINATION', pagination});
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
// 
export function promotionsCategoryAction(regionId, categoryId = null) {
    return {
        type: 'PROMOTIONS_CATEGORY',
        regionId,
        categoryId
    }
}

export function* fetchPromotionsCategorySaga(action) {
    try {
        yield put(showLoading());

        let params = [];
        if (action.regionId) {
            params.push(`region_id=${action.regionId}`);
        }
        if (action.categoryId) {
            params.push(`category_id=${action.categoryId}`);
        }
        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/actions/categories?${params.length ? params.join('&') : ''}`, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
//
export function promotionsArchivePageAction(pageNum = null, is_mobile = null) {
    return {
        type: pageNum ? 'PROMOTIONS_ARCHIVE_PAGE_NEW' : 'PROMOTIONS_ARCHIVE_PAGE',
        pageNum,
        is_mobile
    }
}

export function* fetchPromotionsArchivePageSaga(action) {
    try {
        yield put(showLoading());

        const { status, data, error, pagination } = yield call( () => {
            return fetchClientApi(`/actions/archive${action.pageNum ? `?page=${action.pageNum}` : ''}${action.pageNum ? '&' : '?'}${action.is_mobile ? `is_mobile=${action.is_mobile}` : ''}`, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
            yield put({type: 'PROMOTIONS_ARCHIVE_PAGE_PAGINATION', pagination});
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
//
export function promotionsDetailPageAction (slug, regionId) {
    return {
        type: 'PROMOTIONS_DETAIL_PAGE',
        slug,
        regionId,
    }
}

export function* fetchPromotionsDetailPageSaga (action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/actions/${action.slug}?region_id=${action.regionId}`, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
//
export function promotionsDetailArchivePageAction (slug, regionId) {
    return {
        type: 'PROMOTIONS_DETAIL_ARCHIVE_PAGE',
        slug,
        regionId,
    }
}

export function* fetchPromotionsDetailArchivePageSaga (action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/actions/archive/${action.slug}?region_id=${action.regionId}`, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
