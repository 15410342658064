import React, { Component } from 'react';
import Icon from "../common/Icon";

class SmallCollapse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened
        }
    }

    collapse = () => {
        this.setState((prevState) => {
            return {opened: !prevState.opened}
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.opened !== this.state.opened) {
            this.setState({opened: nextProps.opened})
        }
    }

    render() {
        let {
            title,
            children
        } = this.props;

        return  <div
                    className={"b-small-collapser__item" + (this.state.opened ? " b-small-collapser__item_collapsed" : "")}
                >
                    <div className="b-small-collapser__item-title" onClick={this.collapse}>
                        <Icon name={"icon_spoiler"} className={"b-small-collapser__icon-close icon u-icon u-icon_spoiler"} />
                        <Icon name={"icon_spoiler-open"} className={"b-small-collapser__icon-open icon u-icon u-icon_spoiler-open"} />
                        <div dangerouslySetInnerHTML={{__html: title}} />
                    </div>
                    <div className={"u-collapser" + (this.state.opened ? " u-collapser_open" : "")}>
                        <div className="u-collapser__container">
                            <div className="b-small-collapser__collapsed-area">
                                <div className="b-support-hidden-text b-support-hidden-text_adapt_default">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }
}

export default SmallCollapse;