import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

let routesSysName = [
    {
        sysName: 'supportRoute',
        name: 'Помощь и поддержка'
    },
    {
        sysName: 'fastDecisionsRoute',
        name: 'Быстрые решения'
    },
    {
        sysName: 'securityRoute',
        name: 'Безопасность и защита от мошенничества'
    },
    {
        sysName: 'motivOnMapRoute',
        name: 'Карта покрытия'
    },
    {
        sysName: 'complainRoute',
        name: 'Добро пожаловаться'
    },
    {
        sysName: 'termsOfServiceRoute',
        name: 'Правила оказания услуг'
    },
];

class MobileNav extends Component {

    constructor(props){
        super(props);

        let routes = [];
        let routesObj = props.routes ? props.routes.list : [];

        routesSysName.forEach(function (item) {
            let route = routesObj.find(x => x.sys_name === item.sysName);
            item['href'] = route ? route.route : "/";
        });

        if (routesObj.length > 0) {
            routes = routesSysName
        }

        this.state = {
            routes: routes,
            opened: false
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        locationPath: PropTypes.string,
        toggleOpenedClass: PropTypes.func
    };

    isActive (href, path) {

        let sectionPath = path.split('/')[2];
        let sectionNavItem = href.split('/')[1];

        if (sectionPath === sectionNavItem) {
            return true;
        }

        return false;
    }

    activeTitle = (path) => {
        let sectionPath = path.split('/')[2];
        let route = this.state.routes.find(x => x.href === '/'+sectionPath);
        return route ? route.name : '';
    };

    openMenu = () => {
        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        })
    };


    render() {

        let {
            routes,
            opened
        } = this.state;

        let regionAlias = this.context.selectedRegion.alias;
        let path = this.context.locationPath;
        let fetchBefore = this.context.fetchBefore;

        return (
            <div className="b-header_adapt_default b-header_adapt_default__mobile-nav">
                <div className="b-header__aside-menu b-header__aside-menu_active">
                    <div className={"b-aside-menu b-aside-menu_theme_header" + (opened ? " b-aside-menu_dd-active" : "")}>
                        <div className="b-aside-menu__dropdown-header-area" onClick={this.openMenu}>
                            <div className="b-aside-menu__dropdown-elem">{this.activeTitle(path)}</div>
                        </div>
                        <div className="b-aside-menu__dd-wrap">
                            {routes.map((item, index) =>
                                item.href !== '/' ? <div className={"b-aside-menu__group" + (this.isActive(item.href, path) ? " b-aside-menu__group_open" : "")} key={index}>
                                    {   <a href={'/' + regionAlias + item.href}
                                           className={"b-aside-menu__link"}
                                           onClick={fetchBefore('/' + regionAlias + item.href)}>{item.name}
                                    </a>
                                    }
                                </div> : null
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes
    }),
    (dispatch) => {
        return {

        }
    }
)(MobileNav);


/*
onClick={(e) => {
                                               e.preventDefault();
                                               fetchBefore('/' + regionAlias + item.href);
                                               this.setState({
                                                   opened: false
                                               })
                                           }}


 */