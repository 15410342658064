import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList} from "../../actions/routes";
import RightNav from "../../components/Support/RightNav";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import RatesServicesFilter from "../../components/Rates/RatesServicesFilter";
import {fastDecisions} from "../../actions/support";
import LinkC from "../../components/common/LinkC";
import {sortByProp} from "../../tools/tools";
import MobileNav from "../../components/Support/MobileNav";
import UssdLink from "../../components/common/UssdLink";

const categoryBase = {
    id: 1000000,
    slug: 1000000,
    title: 'Все',
    in_filter: true,
    rank_filter: 1000000,
};

class FastDecisions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            slug: categoryBase.slug,
            categoriesFilter : this.categoriesFromList(),
        };
    }
        // для ssr
    componentWillMount() {
        this.props.getRoutes();

            // ssr для центрального контента
        let regionId = this.context && this.context.selectedRegion.id;
        this.props.getFastDecisions(regionId);
    }

    componentDidMount(){
        let regionId = this.context && this.context.selectedRegion.id;
        this.props.getFastDecisions(regionId);
    }

    componentDidUpdate(prevProps){
        if (prevProps.support.fastDecisions !== this.props.support.fastDecisions){
            this.setState({
                categoriesFilter:  this.categoriesFromList()
            })
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    categoriesFromList = () => {

        let cats = [];
        let decisions = this.props.support.fastDecisions;

        cats.push(categoryBase);

        if (decisions.length > 0) {
            decisions.forEach(function(item) {
                cats.push({
                    id: item.id,
                    slug: item.id,
                    title: item.title,
                    in_filter: true,
                    rank_filter: item.rank_filter,
                })
            })
        }
        
        cats = sortByProp(cats, 'rank_filter');
        
        return cats;
    };

    toggleCat = (slug) => {
        this.setState({slug: slug});
    };

    render() {

        let selectedRegion = this.context.selectedRegion;
        let fetchBefore = this.context.fetchBefore;

        let {
            support,
            routes,
            regions,
            location
        } = this.props;

        let {
            slug
        } = this.state;

        sortByProp(support.fastDecisions, 'rank_page');        

        let supportRoute = routes ? routes.list.find(x => x.sys_name === 'supportRoute') : null;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + supportRoute.route,
                title: "Поддержка"
            },
            {
                url: "",
                title: "Быстрые решения"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");        

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'fastDecisionsRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder main-media-bank" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_fast"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container  motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <RatesBreadcrumbs items={breadcrumbs} />
                                </div>
                                <div className="b-intro-simple__title">Быстрые решения</div>
                            </div>
                            <div className="b-support b-support_adapt_default">
                                <div className="b-support__wrapper">
                                    <RatesServicesFilter cats={this.state.categoriesFilter} slug={slug} toggleCat={this.toggleCat} />
                                    <div className="b-support__list-collapser">
                                        <div className="b-list-collapser b-list-collapser_adapt_support-fast">
                                            <div className="b-list-collapser__list">
                                                {support.fastDecisions.map((item, index) =>
                                                    slug === item.id || slug === categoryBase.slug ?
                                                    <div key={index} className="b-list-collapser__item b-list-collapser__item_collapsed">
                                                        <div className="b-list-collapser__item-header js-collapser"
                                                             data-collapser-id="fs-2">
                                                            <div className="b-list-collapser__item-title" id={index + 1}>{item.title}</div>
                                                        </div>
                                                        <div className="u-collapser u-collapser_open">
                                                            <div className="u-collapser__container">
                                                                <div className="b-list-collapser__collapsed-area">
                                                                    <div className="b-list-collapser__content-area">
                                                                        <div className="b-list-collapser__content-item">
                                                                            <div
                                                                                className="b-content-area b-content-area_usual">
                                                                                <p>{item.description}</p>
                                                                                {item.ussd.length > 0
                                                                                    ?
                                                                                        <React.Fragment>
                                                                                        <h4>Команды:</h4>
                                                                                                <ul className="commands">
                                                                                                    {item.ussd.map((ussd, key) =>
                                                                                                        <React.Fragment key={key}>
                                                                                                            {
                                                                                                                ussd.region_id === selectedRegion.id
                                                                                                                    ?   <li>
                                                                                                                        Наберите: {ussd.type === "ussd" ? <UssdLink ussd={ussd.code} className={"black-link"} /> : <strong>{ussd.code}</strong>} –&nbsp;
                                                                                                                        {ussd.slug
                                                                                                                            ?   ussd.class === 'services'
                                                                                                                                ?   <a href={"/" + selectedRegion.alias + "/" + ussd.class + "/" + ussd.slug} onClick={fetchBefore("/" + selectedRegion.alias + "/" + ussd.class + "/" + ussd.slug, ussd.slug, null)}>{ussd.desc}</a>
                                                                                                                                :   <LinkC to={"/" + ussd.class + "/" + ussd.slug}>{ussd.desc}</LinkC>
                                                                                                                            :   ussd.type !== 'ivr'
                                                                                                                                ?   <React.Fragment>{ussd.desc}</React.Fragment>
                                                                                                                                :   ''

                                                                                                                        }
                                                                                                                    </li>
                                                                                                                    :   ""
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </ul>
                                                                                        </React.Fragment>
                                                                                    :   ""
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :  ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <RightNav />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        support: state.support,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getFastDecisions: (regionId) => dispatch(fastDecisions(regionId)),
        }
    }
)(FastDecisions);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const fastDecisionsRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getFastDecisions',
        args: [],
        object: 'support.fastDecisions',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
];