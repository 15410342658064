import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class Form extends Component {

    constructor(props) {
        super(props);
    }

    /*static childContextTypes = {
        validate: PropTypes.bool
    };*/

    /*getChildContext() {
        return {
            validate: this.props.validate
        }
    }*/

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit && this.props.onSubmit();
    };

    render() {

        let {
            className,
            children,
            noValidate,
        } = this.props;

        return   <form className={"form"+(className ? " "+className : "")}
                     noValidate={noValidate} //turn off default browser validation
                     onSubmit={this.onSubmit}>
                    {children}
                 </form>
    }
}