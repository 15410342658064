import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  advertisingLabelingMobToggleAction,
  advertisingLabelingMobGetDataAction,
} from '../../actions/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class AdvertisingLabelingMob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameBtnforCopy: 'Скопировать токен',
      copied: false,
    }
  }

  // клик по КОПИРОВАТЬ ТОКЕН
  copy = () => {
    this.setState({ nameBtnforCopy: 'Скопировано' });
    setTimeout(() => {
      this.setState({ nameBtnforCopy: 'Скопировать токен' });
    }, 1000);
  }

  render() {
    const {
      advertisingLabelingMobToggle = false,
      advertisingLabelingMobToggleAction = () => { },
      advertisingLabelingMobGetData = {},
      advertisingLabelingMobGetDataAction = () => { },
    } = this.props;

    const { nameBtnforCopy } = this.state;
    const { token = '', advertiser = '', type = '' } = advertisingLabelingMobGetData;

    const isMainType = (type === 'mainType' ? 'mainType' : '');

    return (
      <div className={`advertising_labeling_mob ${advertisingLabelingMobToggle ? 'active' : ''} ${isMainType}`}>
        <div
          className='advertising_labeling_mob__content'
        >
          <div className={`advertising_labeling_mob__wrap_window_info ${isMainType}`}>
            <div
              className={'advertising_labeling_mob__close'}
              onClick={() => {
                advertisingLabelingMobToggleAction(false);
                advertisingLabelingMobGetDataAction({});
              }}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4L4 12" stroke="#B9B9BA" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 12L4 4" stroke="#B9B9BA" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className='advertising_labeling_mob__description'>
              {advertiser || 'Рекламодатель не заполнен'}
            </div>

            <CopyToClipboard
              text={token}
              onCopy={() => this.setState({ copied: true })}>
              <div
                className='advertising_labeling_mob__wrap_copy'
                onClick={() => this.copy()}
              >
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 10.5H14V2.5H6V5.5" stroke="#FA6600" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11 5.5H3V13.5H11V5.5Z" stroke="#FA6600" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className='advertising_labeling_mob__copy_name'>
                  {token ? nameBtnforCopy : 'Рекламодатель не заполнен'}
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    );
  };
};

export default connect(
  state => ({
    advertisingLabelingMobToggle: state.common.advertisingLabelingMobToggle,
    advertisingLabelingMobGetData: state.common.advertisingLabelingMobGetData,
  }),
  dispatch => {
    return {
      advertisingLabelingMobToggleAction: (data) => dispatch(advertisingLabelingMobToggleAction(data)),
      advertisingLabelingMobGetDataAction: (data) => dispatch(advertisingLabelingMobGetDataAction(data)),
    }
  }
)(AdvertisingLabelingMob);