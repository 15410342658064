import React, {Component} from "react";
import hover from '../../../icons/motiv_card_back_on.svg'
import over from '../../../icons/motiv_card_back_over.svg'
import LinkC from "../common/LinkC";
import PropTypes from "prop-types";
import {getMeasure} from "../../tools/tools"
import SwitcherInput from "../common/SwitcherInput";
import {connect} from "react-redux";
import Modal from "../common/Modal";
import {clearState, recovery, toggleServices} from "../../actions/client";
import ChangeRate from "../Rates/ChangeRate";
import ChangeRateOk from "../modals/ChangeRateOk";
import ChangeRateConfirmation from "../modals/ChangeRateConfirmation";
import RecoveryForm from "../../components/Forms/RecoveryForm";

class B2bServiceCard extends Component {

    constructor(props, context) {
        super(props, context);

        const servicesRoute = context.appRoutes.find((item) => item.sys_name === 'servicesB2BRoute')
        this.state = {
            on: false,
            off: false,
            route: servicesRoute ? servicesRoute.route : '/services',

            modal: null,
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    showModal(type) {
        return (e) => {
            e.stopPropagation();
            this.setState({modal: type});
        }
    }

    static getDerivedStateFromProps(props, state){
        if (props.successMessageTitle && "recovery" === state.modal) {
            return {...state, modal: "recovery-ok"}
        }
        if (props.toggleServiceError && "change-rate" === state.modal) {
            return {...state, modal: "change-rate-error"}
        }
        if (props.toggleServiceMessage && "change-rate" === state.modal) {
            return {...state, modal: "change-rate-ok"}
        }
        return state;
    }

    static contextTypes = {
        appRoutes: PropTypes.bool,

        selectedRegion: PropTypes.object,
        pushHistory: PropTypes.func,
        fetchBefore:  PropTypes.func
    }

    pushHistory = (url) => {
        return () => {
            this.context.pushHistory(url);
        }
    };

    hideModal(e) {
        this.setState({
            modal: null,
        });
        if (typeof e !== 'undefined') {
            e.stopPropagation();
        }
        this.props.clear()
    }

    onService = (id) => {
        this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), this.props.item, true);
    };

    offService = (id) => {
        this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), this.props.item, false);
    };

    renderModal() {
        let content = "";
        let formsSettings = this.props.formsSettings;
        let isOn = this.props.item.isOn;
        switch (this.state.modal) {
            case 'change-rate':
                content = this.props.isGuest ? <ChangeRate
                    onSuccess={this.showModal('change-rate-ok')}
                    item={this.props.item}
                    hideModal={this.hideModal}
                    title={(isOn ? "Отключить" : "Подключить")+" услугу «" + this.props.item.name + "»"}
                    type={"service"}
                    forgetLinkClick={this.showModal('recovery')}
                /> : <ChangeRateConfirmation type={"service"}
                                             item={{
                                                 name: this.props.item.name,
                                                 isOn,
                                                 info: [{
                                                     items: isOn ? [
                                                         {
                                                             title: "Дата отключения",
                                                             value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                         }
                                                     ] : [
                                                         {
                                                             title: "Абонентская плата",
                                                             value: this.props.item.subscription_fee || 0,
                                                             measure: "₽"
                                                         },
                                                         {
                                                             title: "Стоимость подключения",
                                                             value: this.props.item.activation_price || 0,
                                                             measure: "₽"
                                                         },
                                                         {
                                                             title: "Дата подключения",
                                                             value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                         }
                                                     ]
                                                 }]
                                             }}
                                             notice={isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_form_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_form_description}
                                             onClick={() => {isOn ? this.offService(this.props.item.id_billing) : this.onService(this.props.item.id_billing)}}
                                             onCancel={this.hideModal}
                />;
                break;
            case 'change-rate-ok':
                content = <ChangeRateOk item={this.props.item}
                                        title={!isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_title : formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_title}
                                        description={!isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_description}
                                        type="service"
                                        onClick={this.hideModal}/>;
                break;
            case 'change-rate-error':
                content = <ChangeRateOk type="error"
                                        item={this.props.item}
                                        title={this.props.toggleServiceError === "error" ? (isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_title : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_title) : this.props.toggleServiceError}
                                        description={this.props.toggleServiceError === "error" ? (isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_description) : ""}
                                        onClick={this.hideModal}
                />;
                break;
            case 'recovery':
                content = <RecoveryForm recover={this.props.recover} error={this.props.recoveryError}/>;
                break;
            case 'recovery-ok':
                content = <ChangeRateOk type={"recovery"} onClick={this.hideModal}/>;
                break;
            default:
                return null;
        }
        return <Modal close={this.hideModal}>{content}</Modal>
    }

    hover = () => {
        this.setState({on: true, off: false})
    }

    over = () => {
        this.setState({off: true}, () => {
            setTimeout(() => this.setState({on: false}), 100)
        })
    }

    render() {
        const {item} = this.props;

        const {on, off, route} = this.state;

        return (
            <LinkC
                to={route+'/'+item.slug}
                className={'tariff-b2b-card'}
                onMouseEnter={this.hover}
                onMouseLeave={this.over}
            >
                <svg className={`${on ? 'rotate-in' : ''}${off ? 'rotate-out' : ''}`} id="eHo1Qx9CpGW1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 100 100" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                    <path  id="eHo1Qx9CpGW2"
                        d="M3.34408,21.7313C4.4226,4.17568,26.4525,-5.21136,41.8525,5.32267L79.8868,31.3392C94.4768,41.3191,92.1452,61.9196,75.6291,68.9571L34.9312,86.2987C18.4151,93.3363,-0.341382,81.7216,0.680406,65.0894L3.34408,21.7313Z"
                        fill="rgb(241,112,48)" stroke="none" strokeWidth="1"/>
                </svg>
                <div className={'tariff-b2b-card__body'}>
                    <div className={'tariff-b2b-card__title'}>{item.name}</div>
                    <div className={'tariff-b2b-card__description'}>{item.short_description}</div>
                    <div className="b-tariff-plate__price-block">
                        <div className="b-tariff-plate__wrap-price">
                            <div className="b-tariff-plate__price">
                                {item.subscription_fee ? item.subscription_fee : 0}
                                <div className="b-tariff-plate__valute">₽</div>
                            </div>
                            <div className="b-tariff-plate__period">{item.writeOffPeriod ? `/${getMeasure(item.writeOffPeriod.measure, 1)}` : ''}</div>
                        </div>

                        <div 
                            className={"b-tariff-plate__wrap-switcher"}
                            style={{visibility: (item.sms_activation_info && item.sms_activation_desc) || (item.ussd_activation_code && item.ussd_activation_desc) ? 'visible' : 'hidden'}}
                            onClick={this.showModal('change-rate')}
                        >
                            <SwitcherInput id={"switcher-service-" + item.id} name={false} value={item.isOn || !!this.state.modal} />
                        </div> 
                    </div>
                </div>
                <div className={'tariff-b2b-card__btn'}>Подробнее об услуге</div>
                {this.renderModal()}
            </LinkC>
        )
    }
}

export default connect(
    state => ({
        isGuest: state.client.isGuest,
        recoveryError: state.client.recoveryFormError || state.client.fatalError,
        successMessageTitle: state.client.successMessageTitle,
        toggleServiceMessage: state.client.toggleServiceMessage,
        toggleServiceError: state.client.toggleServiceError,
        formsSettings: state.client.formsSettings
    }),
    (dispatch) => {
        return {
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),
            toggleService: (data, service, on) => dispatch(toggleServices(data, service, on))
        }
    }
)(B2bServiceCard);