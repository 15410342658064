import React, {Component} from 'react';
import RateServiceListItem from "./RateServiceListItem";

class RatesServiceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false
        }
    }

    toggleList = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        });
    };

    render() {
        let items = this.props.items;
        let currentPage = this.props.currentPage;

        return (
            <React.Fragment>
                <div className="b-services-group__list">
                    {items.map((item, idx) =>
                        idx < 2 || this.state.opened || this.props.opened
                            ? <RateServiceListItem currentPage={currentPage} item={item} key={idx} />
                            : ''
                    )}
                </div>
                {!this.state.opened && items.length > 2 && !this.props.opened
                    ? <a onClick={this.toggleList} className="b-services-group__show-more-btn" href="#">Смотреть все</a>
                    : ''
                }
            </React.Fragment>
        )
    }
}

export default RatesServiceList;
