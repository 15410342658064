const initState = { isOk:false, list: [] };

export function faq(state = initState, action) {

    switch (action.type) {
        case 'FAQ_LIST':
            return {...state, isOk: false};
        case 'FAQ_LIST_SUCCESS':
            let faq = [...state.list];
            action.data.filter((item) => {
                if (faq.filter((elem) => {
                    return item.id === elem.id
                }).length === 0) {
                    faq.push(item);
                }
            });
            return {...state, list: faq, isOk: true};
    }
    return state;
}