import React, { Component } from 'react';
import {connect} from "react-redux";
import {routesList} from "../../actions/routes";
import { Link } from "react-router-dom";
import LinkC from "../../../js/components/common/LinkC";
import { Helmet } from "react-helmet";
import Img from 'react-lazilyload-img';
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";
import {infoBlocksList} from "../../actions/infoBlocks";
import InfoBlock from "../../components/common/InfoBlock";

class Values extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount() {
        let regionId = this.context && this.context.selectedRegion.id;

        if (this.props.infoBlocks && this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'company_worth');
        }
    }

    render() {

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "",
                title: 'Ценности'
            }
        ];
        const { infoBlocks, regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        return (
            <div className="page__container info-page-container">
                <Helmet
                    title={"Ценности"}
                    meta={[
                        { "name": "description", "content": "Ценности | Оператор связи МОТИВ" },
                        // { "name": "keywords", "content": route && route.keywords ? route.keywords : "" }
                    ]}
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}>
                    <link rel="canonical" href={pageLink} />
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="info-page-container__title">Ценности</h1>
                        <div className="info-page-container__content-left-part-big">
                            {infoBlocks.list.length > 0 ?
                                <InfoBlock type={'company_image'} data={infoBlocks && infoBlocks.list} section={'company_image'} /> :
                            null}
                            <div className="values-page__wrap-big-text">
                                <h3 className="values-page__big-text-title">
                                    Миссия МОТИВ:
                                </h3>
                                <div className="values-page__big-text">
                                    Улучшаем качество жизни наших абонентов, предлагая легкий доступ к необходимой информации,
                                    развлечениям, товарам и услугам с помощью высокоскоростного и повсеместного мобильного
                                    интернета.
                                </div>
                                <h3 className="values-page__big-text-title">
                                    В центре внимания - клиент
                                </h3>
                                <div className="values-page__big-text mb-0">
                                    Мы работаем с людьми и для людей. <br />
                                    Мы уважаем и ценим наших абонентов, сотрудников, партнеров и поставщиков. <br />
                                    Именно ради абонентов мы вместе с партнерами и поставщиками, развиваем сеть, запускаем
                                    новые услуги, совершенствуем технологии и процессы, повышаем уровень сервиса. <br />
                                    Удовлетворенность абонента - конечная цель любых действий каждого нашего сотрудника. <br />
                                    Мы дорожим своей положительной репутацией в обществе и деловых кругах. В работе мы
                                    придерживаемся принципов взаимоуважения, порядочности и справедливости.
                                </div>
                            </div>

                            <div class="values-page__wrap-video">
                                {infoBlocks.list.length > 0 ?
                                    <InfoBlock type={'company_video'} data={infoBlocks && infoBlocks.list} section={'company_video'} /> :
                                null}
                            </div>

                            <div className="values-page__wrap-all-cube-img">
                                <div className="values-page__wrap-cube-img">
                                    <div className="values-page__cube-img">
                                        < Img
                                            className=""
                                            src={require("../../../images/content/info-pages/cube1.jpg")}
                                            alt=""
                                        />
                                    </div>
                                    <div className="values-page__cube-text">
                                        <h4 className="values-page__cube-text-title">
                                            Ответственность
                                        </h4>
                                        <div className="values-page__cube-text-description">
                                            Мы выполняем свои обещания, честны с клиентами, сотрудниками и партнерами,
                                            их доверие – наше наиболее ценное достижение.
                                            Мы в равной степени ответственно относимся к здоровью и безопасным условиям
                                            труда наших сотрудников, конфиденциальности данных наших клиентов и комфорту
                                            населения, проживающего на лицензионной территории.
                                        </div>
                                    </div>
                                </div>

                                <div className="values-page__wrap-cube-img">
                                    <div className="values-page__cube-img">
                                        < Img
                                            className=""
                                            src={require("../../../images/content/info-pages/cube2.jpg")}
                                            alt=""
                                        />
                                    </div>
                                    <div className="values-page__cube-text">
                                        <h4 className="values-page__cube-text-title">
                                            Эффективность и проактивность
                                        </h4>
                                        <div className="values-page__cube-text-description">
                                            Оптимальное соотношение потраченных усилий и полученного результата – главный
                                            критерий оценки работы наших сотрудников.
                                            Мы максимально рационально используем ресурсы компании и постоянно совершенствуем
                                            рабочие процессы. Нет препятствий, которые могут помешать нам находить оптимальные
                                            решения
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="values-page__wrap-team">
                                <h4 className="values-page__cube-text-title">
                                    Единая команда
                                </h4>
                                <div className="values-page__team-text-description">
                                    Мы убеждены, что залогом успеха любого бизнеса является сильная и эффективная
                                    команда профессионалов.<br />
                                    Основа взаимоотношения между сотрудниками – уважение, доверие и взаимопомощь.
                                    Мы внимательно слушаем и слышим друг друга независимо от занимаемых должностей,
                                    возраста и опыта работы.<br />
                                    Каждый из нас вносит свой вклад в развитие бизнеса и благополучия компании.<br />
                                    Каждый сотрудник получает вознаграждение, соответствующее своей зоне ответственности и
                                    вкладу в общий результат.<br />
                                    Компетентность, профессионализм и ответственность – основные качества, которые помогают
                                    карьерному росту внутри компании.<br />
                                    Мы – команда единомышленников, у нас общие ценности и цели, высокая вовлеченность и вера в успех.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <AsideMenu flag={'about'} flagSubMenu={'values'} route={this.props.routesVacancies} />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        regions: state.regions,
        infoBlocks: state.infoBlocks || []
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group))
        }
    }
)(Values);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const ValuesRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['company_worth'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "company_image"},
            {type: "company_video"}
        ]
    },
];
