import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Icon from "../common/Icon";
import AbonentContent from './AbonentContent';
import SwitcherInput from "../common/SwitcherInput";
import {phoneMask} from "../../tools/mask";
import {phoneValidator, emptyValidator} from "../../tools/form-helper";
import Input from "../Constructor/Input";
import OffersCategories from './OffersCategories';
import AboutModal from "../../components/modals/constructor/AboutModal";
import {getNelementsArray, isEmptyObj, checkAllUrlParams, setCookie} from './../../tools/tools';
import {userPhoneValueAction, timerValueAction, tariffConectionTypeAction} from '../../actions/common';
import {
    checkAvailableConstructor,
    activateTariffConstructor,
    activateMyTariff,
    clearActivationData,
    clearOperatorData,
    checkOperator,
    checkBalanceLack
} from "../../actions/activation";
import {scroller} from 'react-scroll';
import {InView} from 'react-intersection-observer';
import TooltipQuestion from '../common/TooltipQuestion/TooltipQuestion';
import {callLogin, callLogout} from "../../actions/client";

class TariffCalculatorNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formValid: null,
            errorMessage: '',
            opened: false,

            modal: '',
            // toggleContent: false,
            scrollTarget: false,
            isSvoi: false,

            fields: {
                form_name: {
                    value: "Конструктор"
                },
                phone: {
                    typeInput: "text",
                    label: <span className="tariff_block__label"
                                 dangerouslySetInnerHTML={{__html: 'Номер телефона для подключения тарифа'}}/>,
                    name: "phone",
                    value: '',
                    mask: phoneMask,
                    validators: [phoneValidator],
                    errorText: "Введите номер телефона",
                    placeholder: "+7",
                    emptyField: false,
                    required: true,
                },
            }
        }

        this.renderModal = this.renderModal.bind(this);
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
        selectedRegion: PropTypes.object
    };

    componentWillMount() {

    }

    componentDidMount() {
        let {
            clientInfo,
            isGuest,
            tariffConnectionValue
        } = this.props;

        let clientPhoneValue = clientInfo && clientInfo.username ? clientInfo.username : '';

        if (!isGuest && !!clientPhoneValue && tariffConnectionValue === 'quick') {
            this.setFieldValue(clientPhoneValue);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let {
            fields,
            errorMessage,
        } = this.state;

        let {
            activation,
            clientInfo,
            isGuest,
            userPhoneValue,
            tariffConnectionValue,
            connectionType,
            isCheckBalance
        } = this.props;

        let clientPhoneValue = clientInfo && clientInfo.username ? clientInfo.username : '';
        let operator = activation.mnp;

        // заполняем поле телефона если авторизованы и tariffConnectionValue === 'quick'
        if (prevProps.clientInfo !== clientInfo && !isGuest && tariffConnectionValue === 'quick') {
            this.setFieldValue(clientPhoneValue);
            this.props.userPhoneValueAction(clientPhoneValue);
        }

        // очищаем поле ввода номера телефона если mnp
        if (prevProps.tariffConnectionValue !== tariffConnectionValue && tariffConnectionValue !== 'quick') {
            this.setFieldValue('');
        }

        // возвращаем номер в поле ввода номера телефона если quick
        if (prevProps.tariffConnectionValue !== tariffConnectionValue && tariffConnectionValue === 'quick') {
            this.setFieldValue(clientPhoneValue);
        }

        // при выходе с авторизованной зоны убираем телефон и ошибки
        if (prevProps.isGuest !== isGuest && isGuest) {
            this.setState({
                errorMessage: '',
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        emptyField: false,
                        value: ''
                    },
                },
            });
            // this.setFieldValue('');
            this.props.clearActivationData();
        }

        // при заходе в авторизованную зону убираем телефон и ошибки
        if (prevProps.isGuest !== isGuest && !isGuest) {
            this.setState({
                errorMessage: '',
            });
            this.props.clearActivationData();
            if (tariffConnectionValue === 'mnp') {
                this.setState({
                    fields: {
                        ...this.state.fields,
                        phone: {
                            ...this.state.fields.phone,
                            value: '',
                            emptyField: false
                        },
                    }
                });
            }
        }

        // очищаем ошибки если сменили tariffConnectionValue
        if (prevProps.tariffConnectionValue !== tariffConnectionValue && (errorMessage !== '' || this.state.fields.phone.emptyField)) {
            this.setState({
                errorMessage: '',
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        emptyField: false,
                        value: ''
                    },
                },
            });
            this.props.clearActivationData();
            this.props.clearOperatorData();
            if (!isGuest) {
                this.setFieldValue(clientPhoneValue);
            }
        }

        // очищаем ошибки при выходе с модального окна
        if (prevProps.activation !== activation && errorMessage !== '') {
            this.setState({
                errorMessage: '',
            });
        }

        // убираем красное поле
        if (prevState.errorMessage !== errorMessage && errorMessage === '') {
            this.setState({
                // errorMessage: '',
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        emptyField: false,
                    },
                },
            });
        }

        // путь ст --- если номер не МОТИВ
        if (prevProps.activation !== activation && activation.error && activation.available && activation.available.hasOwnProperty('message') && tariffConnectionValue !== 'mnp' && activation.available.code !== 132 && activation.activated.code !== 133 && (connectionType === 'svoi' || connectionType === 'svoiCheck') && activation.available.message !== 'Возникла ошибка, пожалуйста, попробуйте позже' && !activation.errorBalanceLack) {
            this.props.openModal('phone-check');
        }


        if (prevProps.activation !== activation && (activation.available && activation.available.hasOwnProperty('message') && activation.available.message === 'Возникла ошибка, пожалуйста, попробуйте позже') && connectionType === 'svoi') {
            this.setState({
                errorMessage: activation.available.message,
            });
        }

        // путь мнп --- если номер МОТИВ и нужный регион
        if (prevProps.activation !== activation && tariffConnectionValue === 'mnp' && operator.operatorCode === 'mEKATERINBURG' && operator.regionId === this.context.selectedRegion.id && activation.available.message !== 'Возникла ошибка, пожалуйста, попробуйте позже' && !activation.errorBalanceLack) {
            this.props.openModal('phone-check');
        }


        // срабатывает когда номер мотив и в объекте available имеется поле query_id
        if (prevProps.activation.available !== activation.available && activation.available.hasOwnProperty('query_id') && connectionType === 'svoi') {
            // this.props.onClickConnect();
        }
        if (prevProps.activation.available !== activation.available && connectionType === 'svoiCheck' && prevProps.activation !== activation && !activation.error) {
            this.props.onClickConnect(true);
            this.props.tariffConectionTypeAction('');
        }
        // отключаем флажок для новых модалок
        if (prevProps.activation.available !== activation.available && isEmptyObj(activation.available)) {
            this.props.tariffConectionTypeAction('');
        }

        // если не мотив 3003, если мотив и не регион, то выводим ошибку
        if (prevProps.operator !== operator && this.state.fields.phone.emptyField === false) {
            if (operator.StatusCode === 3003) {
                this.setState({
                    fields: {
                        ...this.state.fields,
                        phone: {
                            ...this.state.fields.phone,
                            emptyField: true
                        },
                    },
                    errorMessage: "Оператор не определен"
                });
            }
            if (operator.statusCode === 1001 && operator.regionId !== this.context.selectedRegion.id) {
                this.setState({
                    fields: {
                        ...this.state.fields,
                        phone: {
                            ...this.state.fields.phone,
                            emptyField: true
                        },
                    },
                    errorMessage: "Выбран неверный регион"
                });
            }
        }
        // if (prevProps.operator !== operator && this.state.fields.phone.emptyField === false) {
        //     if (operator.StatusCode === 3003 || operator.regionId && operator.regionId !== this.context.selectedRegion.id) {
        //         this.setState({
        //             fields: {
        //                 ...this.state.fields,
        //                 phone: {
        //                     ...this.state.fields.phone,
        //                     emptyField: true
        //                 },
        //             },
        //             // errorMessage: ""
        //         });
        //     }
        // }

        // удаляем телефон с инпута
        if (prevProps.userPhoneValue !== userPhoneValue && userPhoneValue === '' && this.state.fields.phone.value !== '' && isGuest) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        value: ''
                    },
                }
            });
        }

        // проверяем, пришел ли ответ от проверки баланса
        if (this.props.activation.balanceLack && (prevProps.activation.balanceLack !== this.props.activation.balanceLack)) {
            if (this.props.activation.balanceLack.allowed === true) {
                if (this.props.isGuest) {
                    // this.props.openModal('tariff-connection-check')
                    this.props.activateTariff();
                } else {
                    this.props.onClickConnect();
                    this.props.userPhoneValueAction(this.state.fields.phone.value);
                }
            } else if(this.props.activation.balanceLack.allowed === false) {
                // this.props.activateTariff();
                this.props.openModal('tariff-balance-lack')
            }
        }
    };

    componentWillReceiveProps(nextProps) {
        let available = nextProps.activation.available;

        // срабатывает когда номер не мотив и в объекте available имеется поле message
        if (nextProps.activation.error && available.hasOwnProperty('message') && available.code === 132) {
            this.setState({
                errorMessage: available.message,
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        emptyField: true
                    },
                },
            });
        }

        // если номер подходит по критериям то открываем модалку
        if (nextProps.activation.mnp.regionId && this.props.activation.mnp !== nextProps.activation.mnp) {
            let operator = nextProps.activation.mnp;
            if (operator.statusCode !== 3003 && operator.regionId && operator.regionId === this.context.selectedRegion.id && operator.operatorCode !== operator.ownOperatorCode && this.props.connectionType === 'svoi') {
                this.props.onClickConnect();
            }
        }
    }

    isOpen = () => {
        this.setState({
            opened: !this.state.opened,
        });
    }

    // добавляет тот же номер, что и при авторизации
    setFieldValue = (clientPhoneValue = '') => {
        let phoneValue = clientPhoneValue ? phoneMask(clientPhoneValue) : '';

        this.setState({
            fields: {
                ...this.state.fields,
                phone: {
                    ...this.state.fields.phone,
                    value: phoneValue,
                    emptyField: false
                },
            }
        });
    };

    onFieldChange = (name, value) => {
        let fields = {...this.state.fields};
        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        if (value) {
            fields[name].emptyField = false;
        }

        this.setState({fields});

        // если номер не полный окрашиваем инпут
        if (!fields[name].value || fields[name].value.length !== 16) {
            this.setState({
                // fields: {
                //     ...this.state.fields,
                //     [name]: {
                //         ...this.state.fields[name],
                //         emptyField: true
                //     }
                // },
                errorMessage: ''
            });
            this.props.clearOperatorData();
            this.props.clearActivationData();
        }
    };

    renderModal() {
        switch (this.state.modal) {
            case "about-modal":
                return <AboutModal
                    constructorParams={this.props.constructorParams}
                    dataForUrl={this.props.dataForUrl}
                    close={this.closeModal}
                />;

            default:
                return null;
        }
    }

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        }, () => {
            // this.props.clearData ? this.props.clearData() : "";
            // this.props.clearSupportData ? this.props.clearSupportData() : "";
        });
    };
    closeModal = (e) => {
        if (e) e.preventDefault();
        this.props.clearActivationData();
        // this.props.clearOperatorData();
        this.setState({modal: ''});
        // this.showScroll();
    };

    onSubmit = (e) => {
        e.preventDefault();

        let userPhone = this.state.fields.phone.value;

        // // при отправке проверяем заполненность номера
        if (userPhone.length !== 16 || this.state.errorMessage !== '') {
            this.setState({
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        emptyField: true
                    },
                },
            });
            this.props.tariffConectionTypeAction('');
        }
        if (userPhone.length === 16) {
            this.props.checkAvailableConstructor(userPhone, this.context.selectedRegion.id, null);

            this.props.userPhoneValueAction(this.state.fields.phone.value);
            this.props.timerValueAction(true);

            this.props.tariffConectionTypeAction('svoiCheck');
        }
    };

    // проверяем оператора
    checkOperator = (e) => {
        e.preventDefault();
        let userPhone = this.state.fields.phone.value;

        // при отправке проверяем заполненность номера 
        if (userPhone.length !== 16 || this.state.errorMessage !== '') {
            this.setState({
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        emptyField: true
                    },
                }
            });
            this.props.tariffConectionTypeAction('');
        }

        if (userPhone.length === 16 && !this.props.activation.inProgress) {
            let data = {
                phone: userPhone.replace(/\D/gim, ''),
            };
            this.props.checkOperator(data);
            this.props.userPhoneValueAction(userPhone);
            this.props.tariffConectionTypeAction('svoi');
        }
    };

    // открываем модальное окно
    authClick = () => {
        let {
            clientInfo
        } = this.props;

        let region = clientInfo && clientInfo.region

        // if ((region && region.title) !== (this.context.selectedRegion && this.context.selectedRegion.name)) {
        //     console.log(region.title + ' Регион title')
        //     this.setState({errorMessage: "Выбран неверный регион"});
        // } else {

            let recObj = this.props.tariffConstructor && this.props.tariffConstructor.recalcData;
            let defObj = this.props.tariffConstructor && this.props.tariffConstructor.defaultData;
            let abonPrice = isEmptyObj(recObj)
                ? defObj.abon_discount ? defObj.abon_discount : defObj.abon
                : recObj.abon_discount ? recObj.abon_discount : recObj.abon

            // this.props.checkAvailableConstructor(
            //     this.state.fields.phone.value,
            //     this.context.selectedRegion.id,
            //     {
            //         phone: this.state.fields.phone.value,
            //         abon: abonPrice
            //     }
            // );
            // this.props.timerValueAction(true);

            // this.props.onClickConnect();
            this.props.checkBalanceLack({
                abnum: this.state.fields.phone.value,
                abon: abonPrice
            })
            this.props.userPhoneValueAction(this.state.fields.phone.value);
        // }
    }

    // якорь к нужному месtу
    handleScroll = (target) => {
        scroller.scrollTo(target, {
            duration: 500,
            delay: 0,
            offset: -(window.innerHeight - 55),
            smooth: 'easeInOutQuart'
        });
    };

    render() {
        let {
            opened,
            toggleContent,
            errorMessage,
            scrollTarget
        } = this.state;

        let {
            phone,
        } = this.state.fields;

        let {
            name,
            toggleInputValueTariff,
            inputTariffNameRef,
            tariffConstructor,
            activation,
            tariffConnectionValue,
            isGuest,
            dataSliderParams,
            constrUrlLink,

            checkboxClick,
            nameData,
            valueData,
            onChangeData,
            nameRouter,
            valueRouter,
            onChangeRouter,
            nameSocial,
            valueSocial,
            onChangeSocial,
            nameVoice,
            valueVoice,
            onChangeVoice,
            nameYoutube,
            valueYoutube,
            onChangeYoutube,
            nameIvi,
            valueIvi,
            onChangeIvi,
            nameHome,
            valueHome,
            onChangeHome,
            currentSmsPack,
            optionSmsArr,
            chOn,
            disabledRqs,
            settings,
            maxFixedPrice
        } = this.props;


        let defaultConstructorData = tariffConstructor && tariffConstructor.defaultData && tariffConstructor.defaultData.constructor;
        let recalcConstructorData = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.constructor;
        let dataOptions = recalcConstructorData.options;

        let min = isEmptyObj(recalcConstructorData) ? defaultConstructorData.min : recalcConstructorData.min;
        let data = isEmptyObj(recalcConstructorData) ? defaultConstructorData.data : recalcConstructorData.data;
        let sms = isEmptyObj(recalcConstructorData) ? defaultConstructorData.sms_count : recalcConstructorData.sms_count;

        let data_unlim = dataOptions && dataOptions.includes('DATA_UNLIM');
        let router = dataOptions && dataOptions.includes('ROUTER');
        let socisl_unlim = dataOptions && dataOptions.includes('SOCIAL_UNLIM');
        let voice_unlim = dataOptions && dataOptions.includes('MVOICE_UNLIM');
        let ytb_unlim = dataOptions && dataOptions.includes('YOUTUBE_UNLIM');
        let ivi = dataOptions && dataOptions.includes('IVI');
        let home = dataOptions && dataOptions.includes('HOME_REGION_CHECKED');

        let recObj = tariffConstructor && tariffConstructor.recalcData;
        let defObj = tariffConstructor && tariffConstructor.defaultData;
        let abonPrice = isEmptyObj(recObj)
            ? defObj.abon_discount ? defObj.abon_discount : defObj.abon
            : recObj.abon_discount ? recObj.abon_discount : recObj.abon

        // let fee = isEmptyObj(recObj)
        //     ? defObj.subs_fee_discount ? defObj.subs_fee_discount : defObj.subs_fee
        //     : recObj.subs_fee_discount ? recObj.subs_fee_discount : recObj.subs_fee
        let abon_const_sms = isEmptyObj(recObj)
            ? defObj.abon_const_sms
            : recObj.abon_const_sms

        // все опции
        let options = recObj.options;
        // создаем новый массив и записываем в него совпадения из общего списка опций
        let newOptions = [];
        dataOptions && dataOptions.forEach(function (i) {
            options && options.forEach(function (x) {
                if (x.type === i) {
                    newOptions.push(x)
                }
            })
        });

        // распределяем полученные опции на месячные и суточные
        let monthOptions = [], dayOptions = [];
        newOptions && newOptions.forEach(function (i) {
            if (i.type === 'ROUTER' || i.type === 'YOUTUBE_UNLIM' || i.type === 'IVI' || i.type === 'HOME_REGION_CHECKED') {
                monthOptions.push(i)
            }
            if (i.type === 'SOCIAL_UNLIM' || i.type === 'MVOICE_UNLIM') {
                dayOptions.push(i)
            }
        });

        // i - текущее значение аккумулятора, current - элемент массива в текущей итерации
        let sumM = monthOptions.reduce((i, current) => i + (current.price_discount >= 0 ? current.price_discount : current.price), 0);
        let sumD = dayOptions.reduce((i, current) => i + (current.price_discount >= 0 ? current.price_discount : current.price), 0);

        // находим совпадение по ключу profitable_offer
        let showOffers = options && options.some((i) => i.profitable_offer);

        let operator = activation && activation.mnp;

        // ссылка для купить сим-карту
        let urlLink = '';
        if (typeof window !== "undefined") {
            urlLink = 'https://sim.motivtelecom.ru/tarif?' + this.context.selectedRegion.alias + constrUrlLink;
        }

        return (
            <div className="tariffs_calculator">
                <div className="tariffs_calculator__tariff_name">
                    <input
                        className={`tariffs_calculator__tariff_name_input ${toggleInputValueTariff ? "disabled" : ''}`}
                        value={name}
                        placeholder={toggleInputValueTariff ? 'Свой_тариф' : ''}
                        disabled={toggleInputValueTariff}
                        onChange={this.props.onTariffNameChange}
                        ref={inputTariffNameRef}
                        onBlur={this.props.onBlurName}
                    />

                    <button className="tariffs_calculator__tariff_name_btn"
                            onClick={() => this.props.toggleTariffBtn()}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M13.7679 2.05853C12.7915 1.08214 11.2085 1.08223 10.2322 2.05872L1.14641 11.1465L1 11.2929V11.5V14V14.5V15H1.5H4.5H4.70714L14 15V14L5.70692 14L13.9397 5.76544C14.9158 4.7891 14.9157 3.20633 13.9395 2.2301L13.7679 2.05853ZM4.29286 14L11.2932 6.9981L9.00035 4.7052L2 11.7071V14H4.29286ZM10.9394 2.76575C11.5251 2.17985 12.475 2.1798 13.0608 2.76563L13.2324 2.93721C13.8181 3.52295 13.8182 4.47261 13.2325 5.05841L12.0003 6.29091L9.70738 3.99802L10.9394 2.76575Z"
                                  fill="#FA6600"/>
                        </svg>
                    </button>
                </div>
                <div
                    className="tariffs_calculator__tariff_about"
                    onClick={() => this.openModal('about-modal')}
                >
                    Подробнее
                </div>

                <div className="tariffs_calculator__tariff_subtitle">
                    Выбранные условия тарифа
                </div>
                {defaultConstructorData &&
                    (<div className="tariffs_calculator__tariff_terms">
                        {dataSliderParams && dataSliderParams.cost_type
                            ? <React.Fragment>
                                <div>{dataSliderParams && dataSliderParams.data_unlim
                                    ? data === 0
                                        ? dataSliderParams.valueMinutes
                                        : dataSliderParams.fixMinValMinutes
                                    : dataSliderParams.valueMinutes} Мин,&nbsp;
                                </div>
                                <div>{dataSliderParams && dataSliderParams.data_unlim ? "Безлимит" : `${dataSliderParams.valueSvoiGb} Гб`},&nbsp;</div>
                                <div>{sms ? sms : 0} Смс</div>
                            </React.Fragment>
                            : <React.Fragment>
                                <div>{min} Мин,&nbsp;</div>
                                <div>{data && data_unlim ? "Безлимит" : `${data} Гб`},&nbsp;</div>
                                <div>{sms ? sms : 0} Смс</div>
                            </React.Fragment>
                        }

                        {router &&
                            (<React.Fragment>
                                <div>,&nbsp;</div>
                                <div>{'Режим роутера'}</div>
                            </React.Fragment>)
                        }
                        {socisl_unlim &&
                            (<React.Fragment>
                                <div>,&nbsp;</div>
                                <div>{'Безлимит на соцсети'}</div>
                            </React.Fragment>)
                        }
                        {voice_unlim &&
                            (<React.Fragment>
                                <div>,&nbsp;</div>
                                <div>{'Безлимитные звонки внутри сети'}</div>
                            </React.Fragment>)
                        }
                        {ytb_unlim &&
                            (<React.Fragment>
                                <div>,&nbsp;</div>
                                <div>{'Безлимит на видео'}</div>
                            </React.Fragment>)
                        }
                        {home &&
                            (<React.Fragment>
                                <div>,&nbsp;</div>
                                <div>{'Везде как дома'}</div>
                            </React.Fragment>)
                        }
                        {ivi &&
                            (<React.Fragment>
                                <div>,&nbsp;</div>
                                <div>{'Подписка на сервис Иви'}</div>
                            </React.Fragment>)
                        }
                    </div>)
                }

                <div className="tariffs_calculator__tariff_payment">
                    <div className="top_container">
                        <button className="top_container__btn" onClick={() => this.isOpen()}>
                            <Icon
                                name="icon_arrow-down"
                                className={`u-icon_tariffs_calculator ${opened ? ' active_img' : ''}`}
                            />
                        </button>
                        <div className="top_container__wrapper">
                            <div className="top_container__subtitle">Абонентская плата</div>
                            <div className="top_container__price">
                                {tariffConnectionValue === 'quick' && recObj.abon_discount ? abonPrice : recObj.abon}
                                ₽/мес
                            </div>
                        </div>
                    </div>

                    <div className={`bottom_container__hidden ${opened ? ' bottom_container' : ''}`}>
                        <AbonentContent
                            title={"Ежемесячная плата за тариф"}
                            price={tariffConnectionValue === 'quick' ? abon_const_sms : recObj.abon}
                            currency={'₽/мес'}
                            tooltipText={settings.monthly_fee_for_tariff_tooltip}
                        />
                    </div>
                    {monthOptions.length > 0 &&
                        (<div className={`bottom_container__hidden ${opened ? ' bottom_container' : ''}`}>
                            <AbonentContent
                                title={"Ежемесячное списание за услуги"}
                                price={sumM}
                                newOptions={monthOptions}
                                currency={'₽/мес'}
                                tooltipText={settings.monthly_writeoff_for_services_tooltip}
                            />
                        </div>)
                    }
                    {dayOptions.length > 0 &&
                        (<div className={`bottom_container__hidden ${opened ? ' bottom_container' : ''}`}>
                            <AbonentContent
                                title={"Ежедневное списание за услуги"}
                                price={sumD}
                                newOptions={dayOptions}
                                currency={'₽/сут'}
                                tooltipText={settings.daily_writeoff_for_services_tooltip}
                            />
                        </div>)
                    }
                </div>

                {showOffers &&
                    (<div className="tariffs_calculator__offers">
                        <div className="tariffs_calculator__offers_wrapper">
                            {/* <Icon
                                name="icon_percent"
                                className="percent"
                            /> */}
                            <div className="subtitle">Выгодные предложения</div>
                            {settings.great_deals_tooltip
                                && (
                                    <div className='sms__wrap_tooltip'>
                                        <TooltipQuestion
                                            typeColor="black"
                                            text={settings.great_deals_tooltip}
                                        />
                                    </div>
                                )}
                        </div>

                        <div className="tariffs_calculator__offers_categories">
                            {options && options.filter((q) => q.profitable_offer).map(item =>
                                <OffersCategories
                                    key={item.type}
                                    data={item}
                                    maxFixedPrice={maxFixedPrice}

                                    checkboxClick={checkboxClick}

                                    nameData={nameData}
                                    valueData={valueData}
                                    onChangeData={onChangeData}

                                    nameRouter={nameRouter}
                                    valueRouter={valueRouter}
                                    onChangeRouter={onChangeRouter}

                                    nameSocial={nameSocial}
                                    valueSocial={valueSocial}
                                    onChangeSocial={onChangeSocial}

                                    nameVoice={nameVoice}
                                    valueVoice={valueVoice}
                                    onChangeVoice={onChangeVoice}

                                    nameYoutube={nameYoutube}
                                    valueYoutube={valueYoutube}
                                    onChangeYoutube={onChangeYoutube}

                                    nameIvi={nameIvi}
                                    valueIvi={valueIvi}
                                    onChangeIvi={onChangeIvi}

                                    nameHome={nameHome}
                                    valueHome={valueHome}
                                    onChangeHome={onChangeHome}

                                    currentSmsPack={currentSmsPack}
                                    optionSmsArr={optionSmsArr}
                                    toggleSmsPack={this.props.toggleSmsPack}

                                    chOn={chOn}
                                    disabledRqs={disabledRqs}
                                />
                            )}
                        </div>
                    </div>)
                }

                <div className="tariffs_calculator__final">
                    <div className="tariffs_calculator__final_prices">
                        <div className="text">ИТОГО</div>
                        <div className="prices">
                            {tariffConnectionValue === 'quick' && recObj.discount &&
                                (<div className="prices_old">
                                    <div className="prices_old_text">{recObj.abon}</div>
                                    <div className="prices_tooltip" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation()
                                    }}>
                                        -{recObj.discount}%
                                        <div className="prices_tooltip_text">
                                            {recObj.discount_text}
                                        </div>
                                    </div>
                                </div>)
                            }
                            <div className="prices_new">
                                {
                                    recObj.save_price && (abonPrice !== recObj.subs_fee || recObj.abon !== recObj.subs_fee) &&
                                      <div className="prices_new_discount">
                                          <TooltipQuestion
                                            typeColor="black"
                                            text={recObj.save_price_text}
                                            isHoveredLayer={true}
                                          >
                                              <div className="prices_tooltip prices_new_discount_text">ПЕРВЫЙ МЕСЯЦ</div>
                                          </TooltipQuestion>
                                      </div>
                                }
                                {(tariffConnectionValue === 'quick' && recObj.discount) || recObj.save_price ? abonPrice : recObj.abon}
                                <div className="prices_new__text">
                                    <div></div>
                                    <div>₽/месяц</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        recObj.save_price && (abonPrice !== recObj.subs_fee || recObj.abon !== recObj.subs_fee) &&
                        <div className="save_price_text">Далее: {recObj.abon} ₽/месяц</div>
                    }


                    {
                        tariffConnectionValue === 'quick' && this.props.isGuest && (
                            <div className="authorization_link__tariffs" onClick={() => this.props.callLogin()}>
                                <div className="authorization_link__link_wrap">
                                    Для получения индивидуального предложения, пожалуйста,&nbsp;
                                    <span className="authorization_link__link">
                                        авторизуйтесь
                                    </span>
                                </div>
                                <span className="authorization_link__tariffs__img">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6276 18.627 24 12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.627 0 24 5.3724 24 12" fill="#FC692F" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7579 20.8L10.3924 19.7474C10.3924 19.7474 10.4171 12.281 10.3924 10.9531C9.34882 10.4177 8.79996 9.31883 8.79996 8.0502C8.79996 6.25519 10.2326 4.80005 12 4.80005C13.7673 4.80005 15.2 6.25519 15.2 8.0502C15.2 9.28307 14.5997 10.3556 13.6038 10.9065C13.603 11.124 13.6022 12.259 13.6022 12.259L12 13.9591L13.6021 15.4707L13.6023 16.3406L12 17.6059L13.6033 18.7194L13.6038 19.5434L11.7579 20.8ZM12.8 6.39996H11.2V7.99996H12.8V6.39996Z" fill="white" />
                                    </svg>
                                </span>
                            </div>
                        )
                    }

                    {tariffConnectionValue !== 'buy_sim_card' &&
                      (<div className="tariffs_calculator__phone">
                          <div className="input_wrapper">
                              <Input
                                {...phone}
                                labelInBottom={true}
                                name={phone.name}
                                onChange={this.onFieldChange}
                                disabled={tariffConnectionValue === 'quick' && !isGuest}
                              />
                          </div>
                          {errorMessage ? <div className="message">{errorMessage}</div> : null}
                          {(phone.emptyField && !errorMessage) && <div className="message">{phone.errorText}</div>}
                      </div>)
                    }

                    <div className={`tariffs_calculator__scroll_btn_wrapper ${scrollTarget ? "hide" : ''} ${tariffConstructor.isLoadingPostTariff ? 'tariffs_calculator__scroll_btn_wrapper__loading': ''}`}>
                        <button
                            className="tariffs_calculator__scroll_btn"
                            onClick={() => this.handleScroll('scroll_data')}
                        >
                            {
                                recObj.save_price ?
                                  <span>ИТОГО: {recObj.abon_discount} ₽/месяц</span> :
                                  <span>ИТОГО: {tariffConnectionValue === 'quick' && recObj.discount ? abonPrice : recObj.abon} ₽/месяц</span>
                            }
                            <Icon name={'arrow_down'} className="scroll_btn_arrow_down"/>
                            {tariffConstructor.isLoadingPostTariff && <span className="tariffs_calculator__scroll_btn_wrapper__loader" />}
                        </button>
                    </div>
                    <InView
                        className="tariffs_calculator__wrap_final_btn"
                        as="div"
                        onChange={(inView, entry) => this.setState({scrollTarget: inView})}
                        id="scroll_data"
                    >
                        {tariffConnectionValue === 'quick'
                            && (
                                <button
                                    className={`tariffs_calculator__final_btn ${tariffConstructor.isLoadingPostTariff ? 'tariffs_calculator__final_btn__loading': ''}`}
                                    onClick={isGuest ? this.onSubmit : this.authClick}
                                    disabled={tariffConstructor.isLoadingPostTariff}
                                >
                                    <span>Сменить тариф</span>
                                    {tariffConstructor.isLoadingPostTariff && <span className="tariffs_calculator__final_btn__loader" />}
                                </button>
                            )}
                        {tariffConnectionValue === 'mnp'
                            && (
                                <button
                                  className={`tariffs_calculator__final_btn ${tariffConstructor.isLoadingPostTariff ? 'tariffs_calculator__final_btn__loading': ''}`}
                                  onClick={this.checkOperator}
                                    disabled={tariffConstructor.isLoadingPostTariff}
                                >
                                    <span>Перейти в Мотив</span>
                                    {tariffConstructor.isLoadingPostTariff && <span className="tariffs_calculator__final_btn__loader" />}
                                </button>
                            )}
                        {tariffConnectionValue === 'buy_sim_card'
                            && (
                                <a
                                  className={`tariffs_calculator__final_btn ${tariffConstructor.isLoadingPostTariff ? 'tariffs_calculator__final_btn__loading': ''}`}
                                  href={urlLink}
                                    target="_blank"
                                >
                                    <span>Купить сим-карту</span>
                                    {tariffConstructor.isLoadingPostTariff && <span className="tariffs_calculator__final_btn__loader" />}
                                </a>
                            )}
                    </InView>
                </div>
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        activation: state.activation,
        clientInfo: state.client.clientInfo,
        isGuest: state.client.isGuest,
        settings: state.constructor.settings,
        tariffConstructor: state.tariffConstructor,
        userPhoneValue: state.common.userPhoneValue,
        tariffConnectionValue: state.common.tariffConnectionValue,
        timerValue: state.common.timerValue,
        connectionType: state.common.connectionType,
        isCheckBalance: state.common.isCheckBalance,

        // clientInfo: state.client.clientInfo,userPhoneValue: state.common.userPhoneValue,
    }),
    (dispatch) => {
        return {
            checkAvailableConstructor: (phone, regionId, checkData) => dispatch(checkAvailableConstructor(phone, regionId, checkData)),
            userPhoneValueAction: (phone) => dispatch(userPhoneValueAction(phone)),
            timerValueAction: (data) => dispatch(timerValueAction(data)),
            tariffConectionTypeAction: (data) => dispatch(tariffConectionTypeAction(data)),
            checkBalanceLack: (checkData) => dispatch(checkBalanceLack(checkData)),

            activateMyTariff: (tariff, regionId) => dispatch(activateMyTariff(tariff, regionId)),
            checkOperator: (formData, params) => dispatch(checkOperator(formData, params)),
            clearOperatorData: () => dispatch(clearOperatorData()),
            clearActivationData: () => dispatch(clearActivationData()),
            callLogin: () => dispatch(callLogin()),
        }
    }
)(TariffCalculatorNew);