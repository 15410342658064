import {parseErrors} from "../tools/api-helper";

const initState = {
    isOk:false, 
    available: {}, 
    activated: false, 
    activatedMyTariff: false,
    mnp: {},
    inProgress: false, 
    error: false,
    is500Error: false,
    word: null,
    raduoVal: '',
    b2bForm: {},
    balanceLack: null,
    errorBalanceLack: false,
    isOtherNumber: false,
};

export function activation(state = initState, action) {
    switch (action.type) {
        case 'CHECK_AVAILABLE':
            return {...state, isOk: false, inProgress: true};
        case 'CLEAR_ACTIVATION_DATA':
            return {...state, available: {}, activated: false, mnp: {}, activatedMyTariff: false};
        case 'CHECK_AVAILABLE_SUCCESS':
            return {...state, available: action.data, isOk: true, inProgress: false};
        case 'ACTIVATE_ITEM':
            return {...state, isOk: false, inProgress: true};
        case 'ACTIVATE_ITEM_SUCCESS':
            return {...state, activated: action.data, isOk: true, inProgress: false};
        case 'CHECK_OPERATOR':
            return {...state, isOk: false, inProgress: true};
        case 'CHECK_RADIO_VALUE':
            return {...state, raduoVal: action.value};
        case 'CHECK_OPERATOR_SUCCESS':
            return {...state, mnp: action.data, isOk: true, inProgress: false};
        case 'CLEAR_OPERATOR_DATA':
            return {...state, isOk: false, mnp: {}, isOtherNumber: false};
        case 'CHECK_AVAILABLE_CONSTRUCTOR':
            return {...state, isOk: false, activated: false, inProgress: true, error: false, errorBalanceLack: false};
        case 'CHECK_AVAILABLE_CONSTRUCTOR_SUCCESS':
            return {...state, available: action.data, isOk: true, inProgress: false};
        case 'CHECK_AVAILABLE_CONSTRUCTOR_BAD_REQUEST':
        case 'CHECK_AVAILABLE_CONSTRUCTOR_LOGOUT':
        case 'CHECK_AVAILABLE_CONSTRUCTOR_FORBIDDEN':
        case 'CHECK_AVAILABLE_CONSTRUCTOR_NOT_FOUND':
        case 'CHECK_AVAILABLE_CONSTRUCTOR_ERROR':
            return {...state, available: action.data, isOk: true, inProgress: false, error: true};
        case 'CHECK_BALANCE_LACK':
            return {...state, isOk: false, activated: false, inProgress: true, error: false, balanceLack: null, errorBalanceLack: false};
        case 'CHECK_BALANCE_LACK_SUCCESS':
            return {...state, balanceLack: action.data, isOk: true, inProgress: false};
        case 'CHECK_BALANCE_LACK_BAD_REQUEST':
        case 'CHECK_BALANCE_LACK_LOGOUT':
        case 'CHECK_BALANCE_LACK_FORBIDDEN':
        case 'CHECK_BALANCE_LACK_NOT_FOUND':
        case 'CHECK_BALANCE_LACK_ERROR':
            return {...state, balanceLack: action.data, isOk: true, inProgress: false, errorBalanceLack: true};
        case "ACTIVATE_TARIFF_CONSTRUCTOR":
            return {...state, inProgress: true, error: false, is500Error: false, errorBalanceLack: false};
        case "ACTIVATE_TARIFF_CONSTRUCTOR_SUCCESS":
            return {...state, activated: true, inProgress: false, isOk: true};
        case 'ACTIVATE_TARIFF_CONSTRUCTOR_BAD_REQUEST':
        case 'ACTIVATE_TARIFF_CONSTRUCTOR_LOGOUT':
        case 'ACTIVATE_TARIFF_CONSTRUCTOR_FORBIDDEN':
        case 'ACTIVATE_TARIFF_CONSTRUCTOR_NOT_FOUND':
            return {...state, activated: action.data, isOk: true, inProgress: false, error: true};
        case 'ACTIVATE_TARIFF_CONSTRUCTOR_ERROR':
            return {...state, isOk: true, inProgressMyTariff: false, is500Error: true}
        case 'CHECK_WORD':
            return {...state, word: null, isOk: false, inProgress: true};
        case 'CHECK_WORD_SUCCESS':
            return {...state, word: action.data, isOk: true, inProgress: false};
        case 'CLEAR_WORD':
            return {...state, word: null};
        case "ACTIVATE_MY_TARIFF":
            return {...state, inProgressMyTariff: true, error: false, is500Error: false, errorBalanceLack: false};
        case "ACTIVATE_MY_TARIFF_SUCCESS":
            return {...state, activatedMyTariff: true, inProgressMyTariff: false, isOk: true, is500Error: false};
        case 'ACTIVATE_MY_TARIFF_BAD_REQUEST':
        case 'ACTIVATE_MY_TARIFF_FORBIDDEN':
        case 'ACTIVATE_MY_TARIFF_UNAUTHORIZED':
        case 'ACTIVATE_MY_TARIFF_CONSTRUCTOR_FORBIDDEN':
            return {...state, activatedMyTariff: action.data, isOk: true, inProgressMyTariff: false, error: true};
        case 'ACTIVATE_MY_TARIFF_ERROR':
            return {...state, isOk: true, inProgressMyTariff: false, is500Error: true}

        case 'SEND_B2B_REQUEST':
        case 'SEND_B2B_REQUEST_CLEAR':
            return {...state, b2bForm: {}};
        case 'SEND_B2B_REQUEST_SUCCESS':
            return {...state, b2bForm: {result: 'success'}};
        case 'SEND_B2B_REQUEST_VALIDATION_ERROR':
            return {...state, b2bForm: {result: 'error', errors: parseErrors(action.data)}};
        case 'SEND_B2B_REQUEST_BAD_REQUEST':
        case 'SEND_B2B_REQUEST_ERROR':
            return {...state, b2bForm: {result: 'error', message: action.data.message || 'Возникла ошибка, пожалуйста попробуйте позже'}};
        case "CLEAR_TARIFF_POPUP_ERROR":
            return {...state, inProgress: false, isOk: true, inProgressMyTariff: false, error: false, is500Error: false, errorBalanceLack: false};

        case 'USER_TYPE':
            return {...state, isOtherNumber: false};
        case 'USER_TYPE_SUCCESS':
            return {...state, isOtherNumber: false};
        case 'USER_TYPE_BAD_REQUEST':
            return {...state, isOtherNumber: true};
    }
    return state;
}