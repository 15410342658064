import React, {Component} from "react";
import {mediaUrl} from "../../tools/config";
import PropTypes from "prop-types";

class PressCenterSocial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleBtn: false
        };
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    componentDidMount() {
        const el = document.querySelector(".page") || undefined;
        if (typeof el !== 'undefined') {
            el.addEventListener("click", this.closeSocialMore)
        }
    }

    componentWillUnmount(){
        const el = document.querySelector(".page") || undefined;
        el.removeEventListener("click", this.closeSocialMore);
    }

    toggleNewsList = () => {
        this.setState((prevState) => {
            return {
                toggleBtn: !prevState.toggleBtn
            }
        })
    };

    closeSocialMore = () => {
        if (this.state.toggleBtn === true){
            this.setState({
                toggleBtn: false
            })
        }
    };

    render() {
        const { toggleBtn } = this.state;
        const dataAll = this.props.data;
        const dataFirstPart = this.props.data && this.props.data.slice(0, 3);
        const dataLastPart = this.props.data && this.props.data.slice(3);

        return(
            dataAll.length !== 0 ? <div className="press-center-social">
                <span className="press-center-social-title">Мы в социальных сетях:</span>
                <div className="press-center-social-group">
                    {dataFirstPart && dataFirstPart.map((el) =>
                        el.link ? <a
                            key={ el.id }
                            href={ el.link }
                            target="_blank"
                            className="press-center-social-btn"
                            style={{backgroundImage: 'url('+mediaUrl + el.imagePath+')', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "30% center"}}
                        />
                        : null
                    )}
                    {dataAll.length > 3 ? <div className="press-center-social-btn-more" onClick={ this.toggleNewsList }>
                        <span className="social-btn-more--dot" />
                        <span className="social-btn-more--dot" />
                        <span className="social-btn-more--dot" />
                    </div> : null}
                    {!!toggleBtn ?
                        <div className="press-center-social-more">
                            <span className="social-more-close-btn" onClick={ this.closeSocialMore }>&times;</span>
                            {dataLastPart.map((el) =>
                                <a
                                    key={ el.id }
                                    href={ el.link }
                                    target="_blank"
                                    className="press-center-social-more__link"
                                >
                                    { el.imagePath ? <div className="press-center-social-more__link-img" style={{backgroundImage: 'url('+mediaUrl + el.imagePath+')', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "30% center"}} /> : null }
                                    { el.text ? el.text : null }
                                </a>
                            )}
                        </div>
                    : null}

                </div>
            </div> : null
        )
    }
}

export default PressCenterSocial;