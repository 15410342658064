const initState = { isOk:false, vacancies: []};

export function vacancies(state = initState, action) {

    switch (action.type) {
        case 'VACANCIES_LIST':
            return {...state, isOk: false};
        case 'VACANCIES_LIST_SUCCESS':
            return {...state, vacancies: action.data, isOk: true};
    }
    return state;
}