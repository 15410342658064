import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function docsList(name, businessLine = 'b2c') {
    return {
        type: 'DOCS_LIST',
        name,
        businessLine
    }
}

export function* fetchDocsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                `/docs${action.name ? '/'+action.name : ''}${action.businessLine === 'b2b' ? '?is_b2b=1' : '?is_b2c=1'}` , {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}