import {
    call,
    put
} from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function servicesList(regionId, categoryRankField, serviceRankField, businessLine = 'b2c') {
    return {
        type: 'SERVICE_LIST',
        regionId,
        categoryRankField,
        serviceRankField,
        businessLine
    }
}

export function* fetchServicesList(action) {
    try {

        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/services?statusId=1"
                + (action.regionId ? '&regionId='+action.regionId : '')
                + (action.categoryRankField ? '&categoryRankField='+action.categoryRankField : '')
                + (action.serviceRankField ? '&serviceRankField='+action.serviceRankField : '')
                + (action.businessLine ? '&businessLine='+action.businessLine : ''), {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function servicesArchiveList(regionId, categoryRankField) {
    return {
        type: 'SERVICE_LIST_ARCHIVE',
        regionId,
        categoryRankField
    }
}

export function* fetchServicesArchiveList(action) {
    try {

        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/services?statusId=2" + (action.regionId ? '&regionId='+action.regionId : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}

export function servicesView (slug, regionId, businessLine = 'b2c') {
    return {
        type: 'SERVICE_VIEW',
        slug,
        regionId,
        businessLine
    }
}

export function* fetchServicesView (action) {
    try {
        yield put(showLoading());

        let params = []
        if (action.regionId) params.push('regionId=' + action.regionId);
        if (action.businessLine) params.push('businessLine=' + action.businessLine);
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/services/"+ action.slug + (params.length ? '?'+params.join('&') : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}
export function termsOfService () {
    return {
        type: 'TERMS_OF_SERVICE'
    }
}

export function* fetchTermsOfService(action) {
    try {

        // yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/page/termsOfService", {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        // yield put(hideLoading());
    }
}