import React, { Component } from 'react';

class ServiceBlockList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { content, files, children } = this.props;

        return (
            <div className="b-promo-block_color_black b-promo-block_adapt_career">
            {content ? <div className="description-txt" dangerouslySetInnerHTML={{ __html: content }}/> : null}

            {children}

            {files && files.length ? <div className="form-style-block">
                <div className="documents-block">
                    <div className="container">
                        {files && files.map((item, key) =>
                            <div key={key} className="document-block">
                                {item.extension ? <div className="document__rectangle">
                                    <span className="document__rectangle-text" dangerouslySetInnerHTML={{ __html: item.extension }}/>
                                </div> : null}
                                <div className="document__info">
                                    {item.title ? <div className="document__title">
                                        <a className="document-link" target="_blank" href={item.link ? item.link : "#"}>{ item.title }</a>
                                    </div> : null}
                                    {item.size ? <div className="document__size">{'(размер: ' + item.size + ')'}</div> : null}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div> : <div className="form-style-block">
                <div className="document-block">Документы не найдены</div>
            </div>}
        </div>
    )
    }
}

export default ServiceBlockList;