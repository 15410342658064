import React, {Component} from "react";
import Modal from "../common/Modal";
import VacancyForm from "../Forms/VacancyForm";
import Icon from "../common/Icon";

export class VacancyModalForm extends Component {

    render() {

        let { close, modalComment, regions, vacancyList, selectedRegion, vacancyCity_id, vacancyId, specializationId } = this.props;
        return <Modal className="u-modal u-modal_active u-modal-sm modal-info" close={close}>
            <VacancyForm
                modalComment={ modalComment }
                regions={ regions }
                vacancyList={ vacancyList }
                selectedRegion={ selectedRegion }
                vacancyCity_id={ vacancyCity_id }
                vacancyId={ vacancyId }
                specializationId={ specializationId }
            />
        </Modal>
    }
}

export class SuccessVacancyModalForm extends Component {

    render() {

        let { close, dataSuccesModal } = this.props;
        let data = dataSuccesModal ? dataSuccesModal[0] : null;

        return <Modal className="u-modal u-modal_active modal-fullscreen modal-success-info" close={close}>
            <Icon name={'icon-form-ok'} className={"modal-success-info__icon"}/>
            {data && data.title ? <span className="modal-success-info-title" dangerouslySetInnerHTML={{__html: data.title}} /> : null}
            {data && data.text ? <span className="modal-success-info-description" dangerouslySetInnerHTML={{__html: data.text}} /> : null}
        </Modal>
    }
}
