import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';

import {createBrowserHistory, createMemoryHistory} from "history";
import { routerMiddleware } from 'react-router-redux';

import reducer from "./reducers/rootReducer";

const sagaMiddleware = createSagaMiddleware();
const history = typeof document === 'undefined' ? createMemoryHistory() : createBrowserHistory();
const middleware = routerMiddleware(history);

const reduxMiddlewares = [
    middleware,
    sagaMiddleware,
];

export default (initialState) => {
    const store = createStore(
        reducer,
        initialState,
        compose(applyMiddleware(...reduxMiddlewares)),
    );

    store.runSaga = sagaMiddleware.run;

    store.regions = {
        'list': [],
        'selected': {},
        'notFound': false,
        'host': '',
        'cookie': {}
    };

    store.routesVacancies = { isOk: false, list: [], vacancyRoutes: [] };
    store.supportsRoute = { isOk: false, list: [], supportsRoute: [] };

    store.mnpConstructor = null;

    store.close = () => store.dispatch(END);

    return store;
};

export {history};