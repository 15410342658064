import React, { Component } from 'react';

export default class UssdLink extends Component {

    replaceSharp (str) {
        return str.replace(new RegExp("#",'g'), "%23");
    }

    render() {
        let {
            ussd,
            className,
            children
        } = this.props;

        return (children
                    ? children
                    : <span dangerouslySetInnerHTML={{__html: ussd}}/>
        )
    }
}