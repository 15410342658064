import React, { Component } from 'react';
import LinkC from "../common/LinkC";
import PropTypes from "prop-types";

class InfoblockLink extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        regions: PropTypes.array
    };

    applySelectedRegion = (link) => {
        const aliases = this.context.regions.map((reg) => reg.alias);
        aliases.forEach((alias) => {
            if (link.indexOf(alias) !== -1 && alias !== this.context.selectedRegion.alias) {
                link = link.replace(alias, this.context.selectedRegion.alias);
                return link;
            }
        })
        return link;
    }

    render() {

        let {
            link,
            link_title,
            target_blank,
            noFollow
        } = this.props.item;

        let {
            className,
            children
        } = this.props;

        let content = typeof children === 'undefined' ? link_title : children;

        if (!link) {
            return content;
        }

        return (
            <LinkC className={className} to={this.applySelectedRegion(link)} noFollow={noFollow} targetBlank={target_blank}>
                {content}
            </LinkC>
        )
    }
}

export default InfoblockLink;