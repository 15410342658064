import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

export function coverImagesList(regionId) {
    return {
        type: 'COVER_IMAGES_LIST',
        region: regionId
    }
}

export function* fetchCoverImagesList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/cover-image?regionId=" + action.region, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}