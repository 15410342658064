let initState = {axis: {x: [], y: []}, price: {fee: 0, fee_promo: 0, percent_promo: 0}};

export function constructor(state = initState, action) {
    switch (action.type) {
        case "AXIS_VALUES_SUCCESS":
            return {...state, axis: {
                x: action.data.m.split(",").map((item) => parseInt(item, 10)),
                y: action.data.gb.split(",").map((item) => parseInt(item, 10) || 0)
            },
            params: {
                gb_high_from: action.data.gb_high_from,
                gb_low_from: action.data.gb_low_from,
                m_high_from: action.data.m_high_from,
                m_low_from: action.data.m_low_from
            }
        };
        case "TARIFF_PRICE_SUCCESS":
            return {...state, price: {...action.data, percent_promo: 100 - Math.round(action.data.fee_total_promo*100/action.data.fee_total)}};
        case "CONSTRUCTOR_BANNER_SUCCESS":
            return {...state, banner: action.data};
        case "CONSTRUCTOR_SETTINGS_SUCCESS":
            return {...state, settings: action.data};
        case "CONSTRUCTOR_PDF_SUCCESS":
            return {...state, url: action.data.url};
    }
    return state;
}