import React, {Component} from "react";

export default function NewsDescription({item}){

    return ( !!item ? <div className="news-description-block">
            {item && item.title ? <span className="description-block__title" dangerouslySetInnerHTML={{ __html: item.title }} /> : null}
            {item && item.date ? <span className="description-block__date">{item.date}</span> : null}
            {item && item.photo ?
                <div className="description-img-wrap">
                    <div className="description-block__img"
                         style={{ backgroundImage: "url(" + item.photo + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}
                    />
                </div> : null}
            {item && item.description ? <div className="description-block__txt" dangerouslySetInnerHTML={{ __html: item.description }} /> : null}
        </div> : null
    )
}