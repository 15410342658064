import React, { Component } from 'react';

import {tariffArchiveList} from "../../actions/tariffs";
import {connect} from "react-redux";
import Tooltip from "../../components/common/Tooltip";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SidebarHeadInfo from "../../components/common/SidebarHeadInfo";
import Page404 from "../Page404";
import {Route} from "react-router-dom";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import DownloadDoc from "../../components/common/DownloadDoc";
import LinkC from "../../components/common/LinkC";
import {MySidebarInfo} from "../../components/Lk/DetailTafiff";
import MyTariffReminder from "../../components/Rates/MyTariffReminder";
import {settings} from "../../actions/constructor";


class RateTariffArchive extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        setNotFound: PropTypes.func
    };

    componentWillMount() {
        if (this.props.tariffs.archiveTariffsList.length === 0) {
            this.props.getTariffsArchive();
        }

        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }
    }

    render() {

        let selectedRegion = this.context.selectedRegion;

        //this.isMine = this.props.match.params.id != 1;

        let tariff = this.props.tariffs.archiveTariffsList.find(x => x.slug === this.props.match.params.slug && x.region_id === selectedRegion.id);
        let isMine = !this.props.isGuest && tariff && this.props.clientInfo.tariff && tariff.id === this.props.clientInfo.tariff.id;

        if (typeof tariff === 'undefined') {
            return (
                this.props.tariffs.isOk ? <Route component={Page404} /> : <main className="page__main-holder" role="main"/>
            )
        }

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/tariffs",
                title: "Тарифы"
            },
            {
                url: "/" + selectedRegion.alias + "/tariffs/archive",
                title: "Архив тарифов"
            },
            {
                url: null,
                title: isMine ? "Мой текущий тариф" : "Тариф «" + tariff.name + "»"
            }
        ];

        let {
            balance,
            paymentLink,
            remains
        } = this.props.clientInfo;

        let details = this.props.clientInfo.tariff && this.props.clientInfo.tariff.details;
        let detailsDescription = [];
        if (details) {
            if (details.minutes > 0) {
                detailsDescription.push(details.minutes + " минут");
            }
            if (details.traffic > 0) {
                detailsDescription.push(details.traffic + " ГБ");
            }
            if (details.sms > 0) {
                detailsDescription.push(details.sms + " sms");
            }
        } else {
            remains && remains.map((remain) => {
                detailsDescription.push(remain.total_amount === "Безлимит" ? "безлимитны"+(remain.type === "DATA" ? "й" : "е")+" "+remain.title.toLowerCase() : remain.total_amount + " " + remain.unit);
            })
        }

        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");
        
        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={tariff.meta_title}
                    meta={[
                        { "name": "description", "content": tariff.meta_desc },
                        { "name": "keywords", "content": tariff.meta_keywords }
                    ]}
                >
                    <link rel="canonical" href={pageLink} />
                </Helmet>
                <div className="b-service-inner b-service-inner_adapt_default">
                    <div className="b-service-inner__service-header">
                        <div className="b-inner-intro b-inner-intro_adapt_default">
                            <div style={tariff.background ? {
                                backgroundColor: tariff.background.color,
                                backgroundImage: tariff.background.url ? 'url("'+tariff.background.url+'")' : '',
                                backgroundSize: tariff.background.is_cover ? 'cover' : 'auto 100%',
                                backgroundPosition: 'center bottom',
                                backgroundRepeat: 'no-repeat'
                            } : {}} className={`b-inner-intro__wrapper b-inner-intro__wrapper_tariff ${!this.props.isGuest ? 'dop-size' : ''}`}>
                                <div className="b-inner-intro__inner page__container">
                                    <div className="b-inner-intro__header" />
                                    <div className="b-inner-intro__main-area">
                                        <div className="b-inner-intro__main-area-wrapper">
                                            <RatesBreadcrumbs color={tariff.background ? tariff.background.breadcrumbs_color : null} items={breadcrumbs} />
                                            <h1 style={tariff.background && tariff.background.title_color ? {
                                                color: tariff.background.title_color
                                            } : {}} className={`b-inner-intro__title${tariff.background && tariff.background.url ? ' tariff__title' : ''}`}>Тариф «{tariff.name}»</h1>
                                            <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: tariff.short_description}}/>
                                            {isMine ? <MySidebarInfo balance={balance && balance.value}
                                                                     charge_date={this.props.clientInfo.tariff && this.props.clientInfo.tariff.charge_date}
                                                                     paymentLink={paymentLink}
                                                                     details={details}
                                                                     fee={this.props.clientInfo.tariff.fee}
                                                                     color={tariff.background && tariff.background.price_color ? tariff.background.price_color : null}
                                            /> : <SidebarHeadInfo item={tariff} color={tariff.background && tariff.background.price_color ? tariff.background.price_color : null}/>}
                                            {isMine ? <div className="b-inner-intro__btn">
                                                <LinkC className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive" to="/services/all">Настроить услуги</LinkC>
                                                <LinkC className="b-inner-intro__link" style={tariff.background && tariff.background.link_color ? {
                                                    color: tariff.background.link_color
                                                } : {}} to="/tariffs">Изменить тариф</LinkC>
                                            </div> :
                                            <div className="b-inner-intro__btn">
                                                <div style={tariff.background && tariff.background.breadcrumbs_color ? {
                                                    color: tariff.background.breadcrumbs_color
                                                } : {}} className="b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                    Перемещен в архив
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isMine ? <div className="page__container">
                        <MyTariffReminder tariff={{...tariff, options: this.props.clientInfo.tariff.options}} exchange={this.props.clientInfo.exchange}/>
                    </div> : null}
                    <div className="page__container">
                        <div className="b-with-aside-area b-with-aside-area_adapt_default">
                            <div className="b-with-aside-area__column">
                                <div className="b-service-inner__price-list-block">
                                    {tariff.info.map((item, key) =>
                                        <React.Fragment key={key}>
                                            <div className="page__title-3 page__title-3_mobile-less">{item.title}</div>
                                            <div className="b-service-inner__price-list">
                                                <div className="b-price-list b-price-list b-price-list_adapt_default">
                                                    <div className="b-price-list__wrapper">
                                                        <div className="b-price-list__list">

                                                            {item.items.map((item, key) =>
                                                                <div className="b-price-list__item" key={key}>
                                                                    <div className="b-price-list__text">{item.title}</div>
                                                                    { item.notice ?
                                                                        <Tooltip label={<span className="img-icon icon-info"/>} openedLabel={<span className="img-icon icon-info-select"/>} className="numbers-tooltip">
                                                                            <p>{item.notice}</p>
                                                                        </Tooltip>
                                                                        : ''
                                                                    }
                                                                    {item.link ? <LinkC to={item.link} targetBlank={true} className="b-price-list__price" noFollow={true}>{item.value}</LinkC> : <div className="b-price-list__price">{item.value}</div>}
                                                                    {item.measure ? <div className="b-price-list__valute">&nbsp;{item.measure}</div> : null}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>

                                <DownloadDoc url={tariff.description_url} title={"Скачать информацию о тарифе"} size={tariff.description_file_size}/>

                                <RatesCollapsing info_dropdown={tariff.info_dropdown}/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        isGuest: state.client.isGuest,
        clientInfo: state.client.clientInfo,
        regions: state.regions,
        settings: state.constructor.settings
    }),
    (dispatch) => {
        return {
            getTariffsArchive : () => dispatch (tariffArchiveList()),
            getSettings: (regionId) => dispatch(settings(regionId))
        }
    }
)(RateTariffArchive);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */

export const RatesTariffsArchiveRequests = [
    {
        func: 'getTariffsArchive',
        args: [],
        object: 'tariffs.archiveTariffsList',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    }
];