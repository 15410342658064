import React from "react";
import {mediaUrl} from "../../tools/config";
import Icon from "../common/Icon";
import InfoblockLink from "./InfoblockLink";
import AdvertisingLabeling from "../AdvertisingLabeling/AdvertisingLabeling";

export default function InfoBlockHomeBigBanner({blocks}) {
    return <div>
        {blocks.map((block) => <div className="page__banner-offer" key={block.alias}>
            <AdvertisingLabeling
                token={block && block.token}
                advertiser={block && block.advertiser}
            />
            <div className="b-banner-offer b-banner-offer_adapt_default">
                <div className="b-banner-offer__wrapper">
                    <div className="b-banner-offer__img-sector" style={{backgroundImage: 'url('+mediaUrl + block.imagePath+')'}}/>
                    <div className="b-banner-offer__text-sector">
                        <div className="b-banner-offer__inner-area">
                            <div className="b-banner-offer__label"></div>
                            <div className="b-banner-offer__title">{block.title}</div>
                            <div className="b-banner-offer__text">{block.text}</div>
                        </div>
                        <div className="b-banner-offer__action-area">
                            <InfoblockLink className={"b-banner-offer__link"} item={block}>
                                {block.link_title}
                                {/*<Icon className="b-banner-offer__link-icon u-icon u-icon_arr-circled" name="icon_arr-circled"/>*/}
                            </InfoblockLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
    </div>
}
