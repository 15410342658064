import React, {Component} from "react";
import PropTypes from "prop-types";

import {phoneMask, numberMask, dateFormatMask} from "../../tools/mask";
import {getObjectData, emailValidator, phoneValidator, emptyValidator} from "../../tools/form-helper";
import Select from "../common/Select";
import DatePicker from "../common/Date";
import {normalizeForSelect} from "../../tools/tools";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";


export default class DeliveryAddress extends Component{

    constructor(props, context){
        super(props, context);

        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        this.state = {
            fields: {
                callback_phone: {
                    type: "text",
                    label: "Номер телефона для связи",
                    value: phoneMask(context.fields.callback_phone),
                    required: true,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: false,
                    textError: "Укажите корректный номер телефона"
                },
                email: {
                    type: "text",
                    label: "E–mail",
                    value: context.fields.email,
                    required: true,
                    validators: [emailValidator],
                    hasError: false,
                    textError: "Укажите корректный email"
                },
                city: {
                    type: "select",
                    label: "Город",
                    value: context.fields.city,
                    required: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Выберите город",
                    options: props.cities ? props.cities.map((item) => {
                        return {
                            label: item,
                            value: item
                        }
                    }) : []
                },
                metro: {
                    type: "metro",
                    label: "Станция метро",
                    value: "",
                    classN: "metroSelect",
                    options: [],
                },
                address: {
                    type: "address",
                    label: "Улица",
                    value: "",
                    required: true,
                    trigger: false,
                    options: [],
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите улицу",
                },
                house: {
                    type: 'house',
                    label: "Дом",
                    value: "",
                    required: true,
                    trigger: false,
                    options: [],
                    validators: [emptyValidator],
                    hasError: null,
                },
                block: {
                    type: 'block',
                    label: "Корпус",
                    value: "",
                    required: false,
                    trigger: false,
                    options: []
                },
                flat: {
                    type: "text",
                    label: "Квартира",
                    value: "",
                    required: false,
                    hasError: null,
                    textError: ""
                },
                postcode: {
                    type: "text",
                    label: "Индекс",
                    value: "",
                    mask: numberMask(6),
                    hasError: null,
                    textError: ""
                },
                delivery_date: {
                    type: "date",
                    label: "День доставки",
                    value: "",
                    required: true,
                    minDate: tomorrow,
                    maxDate: Date.now() + props.date_days*24*3600000,
                    mask: dateFormatMask,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Укажите день доставки",
                },
                delivery_time: {
                    type: "select",
                    label: "Время доставки",
                    value: "",
                    options: props.delivery_time ? props.delivery_time.map((item) => {
                        return {
                            label: item,
                            value: item
                        }
                    }) : [],
                    required: true,
                    validators: [emptyValidator],
                    hasError: null,
                    textError: "Выберите время доставки",
                },
                comment: {
                    type: "textarea",
                    label: "Дополнительная информация",
                    value: ""
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.address != this.props.address) {
            var fields = {...this.state.fields};
            fields.address.options = nextProps.address.map((item) => {
                return {
                    label: item.value,
                    value: item.value
                }
            });
            this.setState({fields});
        }
        if (nextProps.metro != this.props.metro) {
            var fields = {...this.state.fields};
            fields.metro.options = nextProps.metro.map((item) => {
                return {
                    label: item.value,
                    value: item.value
                }
            });
            this.setState({fields});
        }
    }

    onFieldChange = (name) => {
        return (e) => {
            var fields = {...this.state.fields};
            fields[name].value = fields[name].mask ? fields[name].mask(e.target.value) : e.target.value;
            this.setState({fields});
        }
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    renderField = (name) => {
        let field = {...this.state.fields[name]};
        let fields = this.state.fields;
        let input = null;

        switch (field.type) {
            case "select":
                input = <Select options={field.options || []}
                                value={field.value}
                                className="b-choose-plates__select"
                                onChange={(attr, value) => {
                                    var fields = {...this.state.fields};
                                    fields[name].value = value;
                                    fields[name].hasError = false;
                                    this.setState({fields});
                                }}
                                hasError={field.hasError}
                                onblur={this.onBlur(name)}
                                onfocus={this.onFocus(name)}
                                errorText={field.textError}
                />;
                break;
            case "address":
            case "metro":
            case "house":
            case "block":

                let options = [];

                if (field.type === "address") {
                    if (this.props.forms.hasOwnProperty('address') && this.props.forms.address.length > 0) {
                        options = normalizeForSelect(this.props.forms.address);
                    }
                }

                if (field.type === "house") {
                    if (this.props.forms.hasOwnProperty('house') && this.props.forms.house.length > 0) {
                        options = normalizeForSelect(this.props.forms.house);
                    }
                }

                if (field.type === "block") {
                    if (this.props.forms.hasOwnProperty('block') && this.props.forms.block.length > 0) {
                        options = normalizeForSelect(this.props.forms.block);
                    }
                }

                let disabled = false;

                if (field.type === "metro") {
                    if (this.props.forms.hasOwnProperty('metro') && this.props.forms.metro.length > 0) {
                        options = normalizeForSelect(this.props.forms.metro);
                    }

                    if (this.state.fields.city.value !== 'Екатеринбург') {
                        disabled = true;
                    }
                }
            let classN = "";
            {field.classN ? classN = field.classN : classN="" }
                input = <Select options={options}
                                value={field.value}
                                className={"b-choose-plates__select " + classN}
                                onChange={(attr, value) => {
                                    let fields = {...this.state.fields};
                                    fields[name].value = value;
                                    fields[name].hasError = false;
                                    if (name === "address") {
                                        this.props.clearStreet();
                                        fields.house.value = '';
                                        fields.block.value = '';
                                        fields.flat.value = '';
                                        fields.postcode.value = '';
                                        let postcode = this.props.forms.address.filter((item) => {return item.value === value;})[0].postcode;
                                        if (postcode !== null) {
                                            fields.postcode.value = postcode;
                                        }
                                    }
                                    if (name === "house") {
                                        this.props.clearHouse();
                                        fields.block.value = '';
                                        fields.flat.value = '';
                                        fields.postcode.value = '';
                                        let postcode = this.props.forms.house.filter((item) => {return item.value === value;})[0].postcode;
                                        if (postcode !== null) {
                                            fields.postcode.value = postcode;
                                        }
                                    }
                                    if (name === "block") {
                                        let postcode = this.props.forms.block.filter((item) => {return item.value === value;})[0].postcode;
                                        if (postcode !== null) {
                                            fields.postcode.value = postcode;
                                        }
                                    }
                                    if (name === 'metro') {
                                        this.props.clearAddress();
                                        fields.address.value = '';
                                        fields.house.value = '';
                                        fields.block.value = '';
                                        fields.flat.value = '';
                                        fields.postcode.value = '';
                                    }
                                    this.setState({fields});
                                }}
                                onChangeValue={(attr, value) => {
                                    let fields = {...this.state.fields};
                                    fields[name].value = value;
                                    fields[name].hasError = false;
                                    this.setState({fields});
                                }}
                                disabled={disabled}
                                trigger={field.trigger}
                                onFilter={(val) => {
                                        if (field.type === "address" && val.length >= 3) {
                                            this.props.addressAutoComplete(field.type, val, {city: fields.city.value})
                                        }
                                        if (field.type === "house" && fields.address.value.length >= 3) {
                                            this.props.addressAutoComplete(field.type, fields.address.value + ' ' + val, {city: fields.city.value})
                                        }
                                        if (field.type === "metro" && val.length >= 3) {
                                            this.props.addressAutoComplete(field.type, val, {city: fields.city.value})
                                        }
                                        if (field.type === "block" && fields.house.value && fields.address.value.length >= 3) {
                                            this.props.addressAutoComplete(field.type, fields.address.value + ' ' + fields.house.value + ' ' + val, {city: fields.city.value})
                                        }
                                    }
                                }
                                allowSearch={true}
                                hasError={field.hasError}
                                onblur={this.onBlur(name)}
                                onfocus={this.onFocus(name)}
                                errorText={field.textError}
                />;
                break;
            case "date":
                input = <DatePicker onChange={(val) => {
                        var fields = {...this.state.fields};
                        fields[name].value = val ? val : "";
                        this.setState({fields});
                    }}
                    minDate={field.minDate}
                    maxDate={field.maxDate}
                    hasError={field.hasError}
                    onblur={this.onBlur(name)}
                    onfocus={this.onFocus(name)}
                />;
                break;
            case "textarea":
                input = <div className="b-input-textarea__textarea-wrap">
                    <textarea className="b-input-textarea__textarea"
                              value={field.value}
                              onChange={this.onFieldChange(name)}
                    />
                </div>;
                break;
            default:
                input = <div className="b-input-text__input-area">
                    <InputIcon error={field.hasError}/>
                    <input className={"b-input-text__input" + (field.hasError ? " has-error" : "")}
                           type="text"
                           value={field.value}
                           onChange={this.onFieldChange(name)}
                           readOnly={field.readOnly}
                           onBlur={this.onBlur(name)}
                           onFocus={this.onFocus(name)}
                    />
                    <InputError hasError={field.hasError} errorText={field.textError}/>
                </div>;
        }

        return <div className="b-input-text__wrapper b-input-textarea_adapt_mnp-step-form">
            <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                <div className="b-input-text__label-text">{field.label}{field.required ? <span className={"orange"}>*</span> : ''}</div>
            </div>
            {input}
        </div>
    };

    static contextTypes = {
        goToStep: PropTypes.func,
        step: PropTypes.number,
        fields: PropTypes.object
    };

    goBack = () => {
        this.context.goToStep(this.context.step - 1);
    };

    onSubmit = (e) => {
        if (e) e.preventDefault();
        if (this.isValid() && !this.props.inProgress) {
            this.props.onSubmit({...getObjectData({...this.state.fields}), form_name: "Шаг4.2"});
        }
    };

    isValid = () => {
        let fields = {...this.state.fields};

        for (let attr in fields) {
            if (fields[attr].error || (fields[attr].required && !fields[attr].value)) {
                return false;
            }
        }

        if (!emailValidator(fields.email.value)) {
            return false;
        }

        if (fields.callback_phone.value.replace(/\D/gim, '').length !== 11) {
            return false;
        }

        return true;
    };

    render() {
        return <form className="page__step-form" onSubmit={this.onSubmit}>
            <div className="b-step-form b-step-form_adapt_default">
                <div className="b-step-form__wrapper">
                    <div className="b-step-form__block">
                        <div className="b-step-form__row">
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("callback_phone")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("email")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    {this.renderField("city")}
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    {this.renderField("metro")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__block">
                        <div className="b-step-form__row">
                            <div className="b-step-form__item b-step-form__item_size_third">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("address")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("house")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("block")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("flat")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_one_sixth">
                                <div className="b-step-form__input">
                                    <div className="b-input-text b-input-text_adapt_mobile-small">
                                        {this.renderField("postcode")}
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    <div className="b-input-date b-input-date_adapt_mnp">
                                        {this.renderField("delivery_date")}
                                        {this.state.fields.delivery_date.hasError
                                            ? <InputError hasError={true} errorText={this.state.fields.delivery_date.textError}/>
                                            : <div className="b-input-date__bottom-text b-input-date__bottom-text b-input-date__bottom-text_grey">{this.props.date_comment}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_half">
                                <div className="b-step-form__input">
                                    {this.renderField("delivery_time")}
                                </div>
                            </div>
                            <div className="b-step-form__item b-step-form__item_size_full b-step-form__item_textarea">
                                <div className="b-step-form__input b-step-form__input_textarea">
                                    {this.renderField("comment")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="b-step-form__btns-sector">
                        <div className="b-step-form__btn-item u-btn u-btn u-btn_lg-responsive u-btn u-btn_transparent"
                             onClick={this.goBack}
                        >Назад</div>
                        <button id={"mnp-delivery-info-button"} type="submit"
                                className={"b-step-form__btn-item u-btn u-btn u-btn_lg-responsive u-btn u-btn_default"+(!this.isValid() || this.props.inProgress ? " disabled" : "")}>
                            Далее
                        </button>
                    </div>
                </div>
            </div>
        </form>
    }
}