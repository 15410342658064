import React, { Component } from 'react';
import Icon from '../common/Icon';
import TooltipCustom from "../common/TooltipQuestion/TooltipCustom";

class BalansBlock extends Component {
	constructor(props) {
		super(props);

		this.state = {
			remainsTotal: this.prepareRemains()
		}
	}

	componentDidMount() {
		this.setState({ remainsTotal: this.prepareRemains() });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.clientInfo.packageRemains !== this.props.clientInfo.packageRemains && this.props.clientInfo.packageRemains) {
			this.setState({ remainsTotal: this.prepareRemains() })
		}
		if (prevProps.clientInfo.remainsTotal !== this.props.clientInfo.remainsTotal || prevProps.clientInfo.tariff !== this.props.clientInfo.tariff) {
			this.setState({ remainsTotal: this.prepareRemains() })
		}
	}

	totalForm = (props) => {
        let {tariff} = this.props.clientInfo;

        let hasData = false;
		let preparedRemains = [
            {
                type: 'DATA',
                rest_amount: 0,
                unit: 'Гб'
            },
            {
                type: 'VOICE',
                rest_amount: 0,
                unit: 'Мин'
            },
            {
                type: 'SMS',
                rest_amount: 0,
                unit: 'Смс'
            }
        ]

        props && props.map((remain) => {
            switch (remain.type) {
                case 'DATA':
                    hasData = true;
                    preparedRemains[0] = {
                        ...remain,
                        wifi: remain.rest_amount >= 1
                    }
                    break;
                case 'VOICE':
                    preparedRemains[1] = remain
                    break;
                case 'SMS':
                    preparedRemains[2] = remain
                    break;
            }
            return remain;
        });

        if (!hasData && tariff && tariff.details && tariff.details.is_data_unlim) {
            preparedRemains[0] = {
                total_amount: "Безлимит",
                type: 'DATA',
                wifi: tariff.details.router,
                roaming: tariff && tariff.roaming_type === "N" ? true : false,
            }
            // preparedRemains[0] = {
            //     total_amount: "Безлимит",
            //     type: 'DATA',
            //     wifi: tariff.details.router,
            // }
        }

        return preparedRemains;
    }
	prepareRemains = () => {
        let {
			remainsTotal,
			packageRemains
		} = this.props.clientInfo;

		let isHomeRegion = this.props.clientInfo && this.props.clientInfo.tariff && this.props.clientInfo.tariff.options && this.props.clientInfo.tariff.options.some((el) => el.id === 1994);

		let totalHome = [];
		packageRemains && packageRemains.forEach(element => {
			if (element.service.id === 1994) {
				totalHome.push(element)
			}
		});

				// всегда показывать тотал
        // if (isHomeRegion) {
			// return this.totalForm(totalHome);
        // } else if (remainsTotal) {
            return this.totalForm(remainsTotal);
        // }
    }
	// prepareRemains = () => {
	// 	let { remainsTotal, tariff } = this.props.clientInfo;

	// 	let hasData = false;
	// 	let preparedRemains = [
	// 		{
	// 			type: 'DATA',
	// 			rest_amount: 0,
	// 			unit: 'Гб'
	// 		},
	// 		{
	// 			type: 'VOICE',
	// 			rest_amount: 0,
	// 			unit: 'Мин'
	// 		},
	// 		{
	// 			type: 'SMS',
	// 			rest_amount: 0,
	// 			unit: 'Смс'
	// 		}
	// 	];

	// 	if (remainsTotal) {
	// 		remainsTotal.map((remain) => {
	// 			switch (remain.type) {
	// 				case 'DATA':
	// 					hasData = true;
	// 					preparedRemains[0] = {
	// 						...remain,
	// 						wifi: remain.rest_amount >= 1
	// 					}
	// 					break;
	// 				case 'VOICE':
	// 					preparedRemains[1] = remain
	// 					break;
	// 				case 'SMS':
	// 					preparedRemains[2] = remain
	// 					break;
	// 			}
	// 			return remain;
	// 		})
	// 	}

	// 	if (!hasData && tariff && tariff.details && tariff.details.is_data_unlim) {
	// 		preparedRemains[0] = {
	// 			total_amount: "Безлимит",
	// 			type: 'DATA',
	// 			wifi: tariff.details.router,
	// 		}
	// 	}

	// 	return preparedRemains;
	// }

	renderRemain = (remain) => {

		let icon = null;

		switch (remain.type) {
			case "SMS":
				icon = <Icon name="icon_sms-header" className="u-icon u-icon_sms-header" />;
				break;
			case "VOICE":
				icon = <Icon name="icon_mobile" className="u-icon u-icon_mobile" />;
				break;
			default:
				icon = <Icon name="icon_internet-header" className="u-icon u-icon_internet-header" />;
		}

		return <div className="b-tariff-review__value" key={remain.type}>
			{remain.wifi ? <TooltipCustom
				text={'На Вашем тарифе доступна раздача интернета на другие устройства'}
				label='<span class="img-icon icon-wifi icon-wifi-header" />'
				customClasses='wifi-tooltip'
			/> : null}
			<div className="b-tariff-review__value-icon">
				{icon}
			</div>
			<div className="b-tariff-review__value-text">
				{!!remain.roaming 
                    ? "0 Гб"
                    : remain.total_amount === "Безлимит"
                        ? <span className="img-icon icon-infinity-black" />
                        : (remain.cost
                            ? remain.cost + " " + "₽/" + (remain.type === "DATA"
                                ? "Мб"
                                : remain.unit)
                            : remain.rest_amount + " " + remain.unit)
                }
				{/* {remain.total_amount === "Безлимит"
					? <span className="img-icon icon-infinity-black" />
					: (remain.cost
						? remain.cost + " " + "₽/" + (remain.type === "DATA" ? "Мб" : remain.unit)
						: remain.rest_amount + " " + remain.unit)
				} */}
			</div>
		</div>
	};

	render() {
		let remainsTotal = this.state.remainsTotal;
		let isHomeRegion = this.props.clientInfo && this.props.clientInfo.tariff && this.props.clientInfo.tariff.options && this.props.clientInfo.tariff.options.some((el) => el.id === 1994);

		return (
			<div>
				{(!this.props.isGuest) &&
					<div className="balans_block">
						{this.props.clientInfo.balance && (
							<div className="balans_block__wrap_balans">
								<div className="balans_block__balans_name">Баланс</div>
								<div className="balans_block__balans_value">
									{this.props.clientInfo.balance.value}
									<span>₽</span>
								</div>
							</div>
						)}

						{remainsTotal && remainsTotal.length ?
							<div className="balans_block__wrap_balans">
								<div className="balans_block__balans_name">
									{this.props.clientInfo && this.props.clientInfo.tariff && this.props.clientInfo.tariff.roaming_type === 'N' ? 'Остатки в поездках по России' : 'Остаток по пакету'}
								</div>
								<div className="balans_block__balans_value wrap">
									{remainsTotal.map((remain) => this.renderRemain(remain))}
								</div>
							</div>
							: null}
					</div>
				}
			</div>
		);
	};
};

export default BalansBlock;