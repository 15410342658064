import React, {Component} from "react";

export default class Input extends Component{

    constructor(props){
        super(props);

        this.state = {
            focus: false
        }
    }

    render(){

        let {
            name,
            label,
            value = "",
            placeholder = "",
            hint,
            error,
            onChange,
            dot_orange,
            hasError,
            errorText,
            onBlur = ()=>{},
            disabled = false,
            className = false,

            emptyField,

            operator,
            balanceKey,
            errorTextApi,
            labelInBottom,
        } = this.props;

        let typeInput =  this.props.typeInput ? this.props.typeInput : "text";

        return <div className={"form-group"+(error && !this.state.focus ? " error" : "")}>
            {(!labelInBottom && label) ? <label className="form-group-label">{label}
                {dot_orange ? <span className="orange">*</span> : null}
            </label> : null}
            <input type= {typeInput}
                   className={"text-input "+(hasError && !this.state.focus ? " input-form-error" : "") + (disabled ? " input-field-disabled" : "") + (!!className ? className : "") + (emptyField ? "empty" : '')}
                   placeholder={placeholder}
                   value={value}
                   name={name}
                   onChange={(e) => {onChange(name, e.target.value)}}
                   onBlur={() => {onBlur(); this.setState({focus: false})}}
                   onFocus={() => {this.setState({focus: true})}}
                   disabled={ disabled }
            />
            {
                (labelInBottom && label) &&
                <label className="form-group-label label_in_bottom">
                    {label}{dot_orange && <span className="orange">*</span>}
                </label>
            }
            {hint ? <div className="form-field-hint"><div className="hint-text">{hint}</div></div> : null}
            {/* {error || hasError ? <div className="form-field-error">{error ? error : errorText}</div> : null} */}

            {balanceKey
            ?   errorTextApi
                ?   null
                :   (operator && operator.operatorCode)
                    ?   (operator && operator.statusCode === 1001 && operator.operatorCode === "mEKATERINBURG")
                        ?   null
                        :   <div className="form-field-error">Введенный номер не принадлежит Мотив</div>

                    :   (operator && operator.StatusCode === 3003)
                        ?   <div className="form-field-error">Введенный номер не принадлежит Мотив</div>

                        :   error || hasError 
                            &&  <div className="form-field-error">{error ? error : errorText}</div>

            :   error || hasError 
                &&   (<div className="form-field-error">{error ? error : errorText}</div>)
            }
        </div>
    }
}

