const initState = { isOk: false, mainMenuList: [] };

export function mainMenu(state = initState, action) {
    switch (action.type) {
        case 'MAIN_MENU_LIST':
            return { ...state, isOk: false };
        case 'MAIN_MENU_LIST_SUCCESS':
            return { ...state, mainMenuList: action.data, isOk: true };
    }
    return state;
}