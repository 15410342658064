import React, { Component } from 'react';
import AsideMenuItem from '../Vacancies/AsideMenuItem';
import PropTypes from "prop-types";

class SmallMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            menu: [
                {
                    alias: 'payments-transfers',
                    link: '/payments-transfers',
                    name: 'Платежи'
                },
                {
                    alias: 'deferred-payment',
                    link: '/deferred-payment',
                    name: 'Отложенный платеж'
                },
                {
                    alias: 'motiv-pay',
                    link: '/motiv-pay',
                    name: 'МОТИВ.pay'
                },
                {
                    alias: 'payments-methods',
                    link: '/payments-methods',
                    name: 'Способы оплаты'
                },
            ]
        }
    }

    static contextTypes = {
        toggleOpenedClass: PropTypes.func,
        locationPath: PropTypes.string,
        megaMenuOpened: PropTypes.object,
    };

    componentDidUpdate() {
        let toggle = this.state.toggle;
        const overlayClass = document.querySelector('.u-overlay');
        const megaMenuOpened = this.context.megaMenuOpened;

        if (typeof window !== "undefined"){
            if ( toggle === true || !!overlayClass && toggle === false && megaMenuOpened === false ){
                this.context.toggleOpenedClass();
            }
        }
    }

    onToggle = () => {
        this.setState((prevState) => {
            return {
                toggle: !prevState.toggle
            }
        });
    };

    render() {
        let {
            toggle,
            menu
        } = this.state;

        let {
            flag,
            flagSubMenu,
            customTitleMenu = false
        } = this.props;

        return (<React.Fragment>
            <div
                className={(toggle ? "rotate b-aside-menu-top" : "b-aside-menu-top")}
                onClick={ this.onToggle }
            >
                {customTitleMenu ? customTitleMenu : 'О компании'}
            </div>
            <div className={`b-aside-menu b-aside-menu_adapt_default ${toggle ? "toggle " : ""}`}>
                { menu.map((item, index) =>
                    <AsideMenuItem
                        item={item}
                        key={index}
                        flag={flag}
                        flagSubMenu={flagSubMenu}
                    />)
                }
            </div>
        </React.Fragment>)
    }
}

export default SmallMenu;