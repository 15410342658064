import React from "react";
import LinkC from "../common/LinkC";
import Icon from "../common/Icon";

const ServicesServices = ({services = []}) => {
    const nameId = "Обмен минут на Гб";

    return <div className="b-services-list__services-group">
        <div className="b-services-group b-services-group_adapt_default">
            <div className="b-services-group__wrapper b-services-group__box">
                {services.map((item) => 
                    <LinkC 
                        className={'services_service'} 
                        key={item.id} 
                        to={item.name === nameId ? '/services/services/'+item.slug : '/deferred-payment'}
                    >
                        <span>{item.name}</span>
                        <Icon className="img-icon u-icon_spir" name={item.name === nameId ? "icon_spir" : "icon_deferred"}/>
                    </LinkC>)
                }
            </div>
        </div>
    </div>
}
export default  ServicesServices;