import React, {Component} from 'react';
import RatesList from '../../components/Rates/RatesList';
import {tariffsList} from "../../actions/tariffs";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import DownloadDoc from '../../components/common/DownloadDoc';
import Modal from "../../components/common/Modal";
import ChangeRate from "../../components/Rates/ChangeRate";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SidebarHeadInfo from "../../components/common/SidebarHeadInfo";
import ButtonDropdown from "../../components/common/ButtonDropdown";
import RatesPriceList from "../../components/Rates/RatesPriceList";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import ChangeRateOk from "../../components/modals/ChangeRateOk";
import {Route} from "react-router-dom";
import Page404 from "../Page404";
import RatesAdditionalServices from "../../components/Rates/RatesAdditionalServices";
import UssdLink from "../../components/common/UssdLink";
import ChangeRateConfirmation from "../../components/modals/ChangeRateConfirmation";
import RecoveryForm from "../../components/Forms/RecoveryForm";
import {recovery, clearState, changeTariff, exchangeMins} from "../../actions/client";
import LinkC from "../../components/common/LinkC";
import {MySidebarInfo} from "../../components/Lk/DetailTafiff"
import MyTariffReminder from "../../components/Rates/MyTariffReminder";
import {getNelementsArray, setCookie} from './../../tools/tools';
import {InfoModal, SuccessInfoModal} from "../../components/modals/constructor/InfoModal";
import TariffConnectionModal from "../../components/modals/constructor/TariffConnectionModal";
import {clearActivationData} from "../../actions/activation";
import {settings} from "../../actions/constructor";
import {cookieDomain} from "../../tools/config";
class RateInner extends Component {

    constructor(props) {
        super(props);

        let tariff = props.tariffs.tariffsLists.find(x => x.slug === props.match.params.slug);

        if (typeof tariff === 'undefined' && props.internet.hasOwnProperty('internetItemsList')) {
            tariff = props.internet.internetItemsList.tariffsTab.find(x => x.slug === props.match.params.slug);
        }

        if (typeof tariff === 'undefined' && props.internet.hasOwnProperty('internetItemsList')) {
            tariff = props.internet.internetItemsList.tariffsRouter.find(x => x.slug === props.match.params.slug);
        }

        this.state = {
            modal: null,
            tariff,
            optionNames: []
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        setNotFound: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    static getDerivedStateFromProps(props, state){
        if (props.successMessageTitle && "recovery" === state.modal) {
            return {...state, modal: "recovery-ok"}
        }
        if (props.changeTariffMessage && ["change-rate", "change-rate-personal"].indexOf(state.modal) !== -1) {
            return {...state, modal: "change-rate-ok"}
        }
        if (props.changeTariffError && ["change-rate", "change-rate-personal"].indexOf(state.modal) !== -1) {
            return {...state, modal: "change-rate-err"}
        }
        return state;
    }

    // для ssr
    componentWillMount() {
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (!this.props.clientInfo.exchange) {
            this.props.getExchangeMins(this.context.selectedRegion.id)
        }
        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }
    }

    componentDidMount() {
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (!this.props.clientInfo.exchange) {
            this.props.getExchangeMins(this.context.selectedRegion.id)
        }
        if (!!this.props.tariffConstructor.defaultData && this.state.optionNames.length === 0 && this.props.tariffConstructor.defaultData && this.props.tariffConstructor.defaultData.options && this.props.tariffConstructor.defaultData.options.length > 0) {
            this.setState({optionNames: this.props.tariffConstructor.defaultData.options})
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.forms && this.props.forms.isSubmitOk != prevProps.forms.isSubmitOk && this.props.forms.isSubmitOk) {
            this.openSuccess("success")
        }

        if (prevState.modal !== this.state.modal && this.state.modal === 'tariff-connection') {
            if (this.props.isGuest) {
                const el = document.querySelector('.phone_focus');
                if (el) el.focus();
            } else {
                const el = document.querySelector('.email_focus');
                if (el) el.focus();
            }
        }

        if ( !!this.props.tariffConstructor.recalcData && !!this.props.tariffConstructor.recalcData.options) {
            if (JSON.stringify(prevProps.tariffConstructor.recalcData.options) !== JSON.stringify(this.props.tariffConstructor.recalcData.options)) {
                const arr = this.props.tariffConstructor.recalcData.options;
                const copyOptionNames = [...this.state.optionNames];
                let optionNames = [];

                if (copyOptionNames.length < 7) {
                    for (let i = 0; i < arr.length; i++) {
                        const item = copyOptionNames.find(x => x.type === arr[i].type);

                        if (!item) {
                            optionNames.push(arr[i]);
                        }

                    }

                    this.setState({optionNames: [...copyOptionNames, ...optionNames]})
                }
            }
        }
    }

    showModal(type) {
        return (e) => {
            e.preventDefault();
            this.setState({modal: type});
        }
    }

    showSuccess = () => {
        this.setState({modal: 'change-rate-ok'})
    };

    hideModal() {
        this.setState({modal: null});
        this.props.clear();
    }

    renderModal() {
        let content = "";
        let title = "Перейти на тариф «" + this.state.tariff.name + "»";
        let formsSettings = this.props.formsSettings;

        let modifiedUrl = this.props.location && this.props.location.pathname;
        let urlStr = modifiedUrl.substr(1).split('-');
        let tariffParams = this.state.tariff;
        let data_unlim_info = tariffParams.svoi && tariffParams.svoi.data_unlim ? 1 : 0;
        let smsCount = tariffParams.sms_count ? parseInt(tariffParams.sms_count) : 0;
        let sms = tariffParams.svoi && tariffParams.svoi.sms ? 1 : 0;
        let paramGb = tariffParams.svoi && tariffParams.svoi.data ? (tariffParams.svoi && (tariffParams.svoi.data === "безлимит" || tariffParams.svoi.data === "Безлимит") ? 0 : parseInt(tariffParams.svoi.data)) : false;
        let paramMin = tariffParams.svoi && parseInt(tariffParams.svoi.min);
        let paramSocialUnlim = tariffParams.svoi && tariffParams.svoi.social_network_unlim ? 1 : 0;
        let paramMotivVoiceUnlim = tariffParams.svoi && tariffParams.svoi.motiv_voice_unlim ? 1 : 0;

        const { optionNames } = this.state;
        const objOptionNames = {};

        if ( optionNames.length > 0 ) {
            optionNames.forEach(el => {objOptionNames[el.type] = el.name})
        }

        switch (this.state.modal) {
            case 'change-rate':
            case 'change-rate-personal' :
                content = this.props.isGuest ? <ChangeRate
                    onSuccess={this.showSuccess}
                    item={this.state.tariff}
                    hideModal={this.hideModal}
                    forgetLinkClick={this.showModal('recovery')}
                    title={title}
                    type={"tariff"}
                    value={this.state.modal === "change-rate-personal" ? "personal" : null}
                /> : <ChangeRateConfirmation type={"tariff"}
                                             item={{
                                                 name: this.state.tariff.name,
                                                 info: [{
                                                     items: [
                                                         {
                                                             title: "Абонентская плата",
                                                             value: this.state.tariff.subscription_fee,
                                                             measure: "₽"
                                                         },
                                                         {
                                                             title: "Дата перехода",
                                                             value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                         }
                                                     ]
                                                 }]
                                             }}
                                             notice={formsSettings.tariff_form && formsSettings.tariff_form.tariff_form_description}
                                             onClick={() => {this.props.changeTariff(JSON.stringify({id: this.state.tariff.id}))}}
                                             onCancel={this.hideModal}

                />;
                break;
            case 'change-rate-phone' :
                content = <ChangeRate
                    onSuccess={this.showSuccess}
                    item={this.state.tariff}
                    value={"phone"}
                    hideModal={this.hideModal}
                    title={title}
                    type={"tariff"}
                />;
                break;
            case 'change-rate-ok':
                content = <ChangeRateOk title={formsSettings.tariff_form && formsSettings.tariff_form.tariff_change_title} description={formsSettings.tariff_form && formsSettings.tariff_form.tariff_change_description} item={this.state.tariff} type={"tariff"} onClick={this.hideModal}  />;
                break;
            case 'change-rate-err':
                content = <ChangeRateOk title={this.props.changeTariffError === "error" ? formsSettings.tariff_form && formsSettings.tariff_form.tariff_unavailable_title : this.props.changeTariffError} description={this.props.changeTariffError === "error" ? formsSettings.tariff_form && formsSettings.tariff_form.tariff_unavailable_description : ""} item={this.state.tariff} type={"error"} onClick={this.hideModal}/>;
                break;
            case 'recovery':
                content = <RecoveryForm recover={this.props.recover} error={this.props.recoveryError}/>;
                break;
            case 'recovery-ok':
                content = <ChangeRateOk type={"recovery"} onClick={this.hideModal}/>;
                break;
            case "tariff-connection":
                return <TariffConnectionModal tariffName={this.state.tariff.name}
                                            close={this.closeModal}
                                            openSuccess={this.openSuccess}
                                            settings={this.props.settings}
                                            data_unlim_info={data_unlim_info}
                                            urlStr={urlStr}
                                            typeConnectionModal={'svoi'}
                                            tariffParams={tariffParams}
                />;
            case "success":
                return <SuccessInfoModal title="Ваша заявка принята!"
                                        text={this.props.settings.submitting_request_mnp_description}
                                        btnText="Отлично!"
                                        close={this.closeModal}
                />;
            case "success-tariff-connection":
                return <SuccessInfoModal title={'Тариф "' + title + '" успешно подключен'}
                                        btnText="Ок!"
                                        close={this.closeModal}
                                        gb={paramGb}
                                        min={paramMin}
                                        social_unlim={paramSocialUnlim}
                                        sms={sms}
                                        motiv_voice_unlim={paramMotivVoiceUnlim}
                                        smsCount={smsCount}
                                         settings={this.props.settings}
                />;

            default:
                return null;
        }
        return <Modal close={this.hideModal}>{content}</Modal>
    }

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.props.clearActivationData();
        this.setState({modal: null});
        this.showScroll();
    };

    hideScroll = () =>{
        if (typeof document != "undefined") {
            const el = document.querySelector('.pp-mod_services-inner');
            el.classList.add('no-scroll');
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.pp-mod_services-inner');
            el.classList.remove('no-scroll');
        }
    };

    openSuccess = (modal) => {
        if (!this.props.isGuest && modal === 'success-tariff-connection') {
            setCookie('has_connection', new Date().getTime() + 300000, {expires: 300, path: '/', domain: cookieDomain})
        }
        this.setState({modal: modal});
    };

    openTariffConnectionModal = (modal, tariff) => {
        let tariffState = tariff ? this.props.tariffs.tariffsLists.find(x => x.id === tariff) : this.props.tariffs.tariffsLists.find(x => x.slug === this.props.match.params.slug);
        this.setState({modal, tariff: tariffState});
        this.hideScroll();
    };

    getOtherTariffs (currentTariff, tariffs) {
        let result = [];
        let tariff;

        if (currentTariff.otherTariffs.length === 2) {
            tariff = tariffs.find(x => x.id === currentTariff.otherTariffs[0].tariff_id);
            if (typeof tariff !== 'undefined') {
                result.push(tariff);
            }
            tariff = tariffs.find(x => x.id === currentTariff.otherTariffs[1].tariff_id);
            if (typeof tariff !== 'undefined') {
                result.push(tariff);
            }
        }

        return result;
    }

    rand (max, old = -1) {

        let rand = Math.floor(Math.random() * max);

        if (rand === old) {
            rand = this.rand(max, old);
        }

        return rand;
    }

    render() {

        let selectedRegion = this.context.selectedRegion;

        let tariff = this.props.tariffs.tariffsLists.find(x => x.slug === this.props.match.params.slug);

        if (typeof tariff === 'undefined' && this.props.internet.hasOwnProperty('internetItemsList')) {
            tariff = this.props.internet.internetItemsList.tariffsTab.find(x => x.slug === this.props.match.params.slug);
        }

        if (typeof tariff === 'undefined' && this.props.internet.hasOwnProperty('internetItemsList')) {
            tariff = this.props.internet.internetItemsList.tariffsRouter.find(x => x.slug === this.props.match.params.slug);
        }

        let isMine = tariff && this.props.clientInfo.tariff && tariff.id === this.props.clientInfo.tariff.id;

        if (typeof tariff === 'undefined') {
            if (typeof document !== 'undefined') {
                this.context.setHeaderClassName("page__header-holder");
            }

            return (
                this.props.tariffs.isOk ? <Route component={Page404} /> : <main className="page__main-holder" role="main"/>
            )
        }

        if (tariff !== null && tariff.tariffServices.length > 0) {

            if (tariff.info_dropdown === null) {
                tariff.info_dropdown = {
                    title: "Подробнее о тарифе",
                    items: []
                };
            }
        }

        let paramSvoi = this.props.match.params && this.props.match.params.slug.indexOf('svoi');

        let tarifsList = [];
        let otherTariffs = [];
        if (paramSvoi !== -1) {
            const allTariffsLists = [...this.props.tariffs.tariffsLists];
            allTariffsLists.forEach( el => {
                if (el.slug.indexOf('svoi') !== -1 && tariff.id !== el.id) {
                    tarifsList.push(el);
                }
            })
            let amountElem = tarifsList && tarifsList.length >= 2 ? 2 : tarifsList.length;
            otherTariffs = getNelementsArray(tarifsList, amountElem);
            if (otherTariffs.length === 0) {
                otherTariffs = this.getOtherTariffs(tariff, allTariffsLists, selectedRegion.id);
            }
        } else {
            tarifsList = this.props.tariffs.tariffsLists;
            otherTariffs = this.getOtherTariffs(tariff, tarifsList, selectedRegion.id);
        }

        let additionalServices = tariff.additionalServices.slice(0, 3);

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/tariffs",
                title: "Тарифы"
            },
            {
                url: null,
                title: isMine ? "Мой текущий тариф" : "Тариф «" + tariff.name + "»"
            }
        ];

        if (this.props.location.state) {
            if (this.props.location.state.hasOwnProperty('state') && this.props.location.state.state && Object.keys(this.props.location.state.state).length > 0) {
                breadcrumbs.splice(0, 1, this.props.location.state.state);
            }
        }

        let {
            balance,
            paymentLink
        } = this.props.clientInfo;

        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={tariff.meta_title}
                    meta={[
                        { "name": "description", "content": tariff.meta_desc },
                        { "name": "keywords", "content": tariff.meta_keywords }
                    ]}
                >
                    <link rel="canonical" href={pageLink} />
                </Helmet>
                <div className="b-service-inner b-service-inner_adapt_default">
                    <div className="b-service-inner__service-header">
                        <div className="b-inner-intro b-inner-intro_adapt_default">
                            <div style={tariff.background ? {
                                backgroundColor: tariff.background.color,
                                backgroundImage: tariff.background.url ? 'url("'+tariff.background.url+'")' : '',
                                backgroundSize: tariff.background.is_cover ? 'cover' : 'auto 100%',
                                backgroundPosition: 'center bottom',
                                backgroundRepeat: 'no-repeat'
                            } : {}} className={`b-inner-intro__wrapper b-inner-intro__wrapper_tariff ${!this.props.isGuest ? 'dop-size' : ''}`}>
                                <div className="b-inner-intro__inner page__container">
                                <div className="b-inner-intro__header" />
                                <div className="b-inner-intro__main-area">
                                    <div className="b-inner-intro__main-area-wrapper">
                                        <RatesBreadcrumbs color={tariff.background ? tariff.background.breadcrumbs_color : null} items={breadcrumbs} />
                                        <div style={tariff.background && tariff.background.title_color ? {
                                            color: tariff.background.title_color
                                        } : {}} className={`b-inner-intro__title${tariff.background && tariff.background.url ? ' tariff__title' : ''}`}>Тариф «{tariff.name}»</div>
                                        <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: tariff.short_description}}/>
                                        {isMine ? <MySidebarInfo balance={balance && balance.value}
                                                                 charge_date={this.props.clientInfo.tariff && this.props.clientInfo.tariff.charge_date}
                                                                 paymentLink={paymentLink}
                                                                 fee={tariff.subscription_fee}
                                                                 color={tariff.background && tariff.background.price_color ? tariff.background.price_color : null}
                                        /> : <SidebarHeadInfo item={tariff} type={"tariff"}
                                                              color={tariff.background && tariff.background.price_color ? tariff.background.price_color : null}
                                        />}
                                        {
                                            tariff.status.id === 1 ?
                                                (isMine ? <div className="b-inner-intro__btn">
                                                    <LinkC className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive" to="/services/all">Настроить услуги</LinkC>
                                                    <LinkC className="b-inner-intro__link" to="/tariffs">Изменить тариф</LinkC>
                                                </div> : <div className="b-inner-intro__btn">
                                                    {paramSvoi !== -1 ?
                                                        <button className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive u-btn_open-modal" onClick={()=> this.openTariffConnectionModal('tariff-connection')}>{this.props.isGuest ? 'Купить тариф' : 'Сменить тариф'}</button> :
                                                        <ButtonDropdown name={"Купить тариф"} shopUrl={tariff.online_shop_link}/>}
                                                    {paramSvoi !== -1 ?
                                                        null
                                                        : <a  style={tariff.background && tariff.background.link_color ? {
                                                            color: tariff.background.link_color
                                                        } : {}} className="b-inner-intro__link" href="#" onClick={this.showModal('change-rate')}>Сменить тариф</a> }
                                                    {paramSvoi === -1 ?
                                                    <React.Fragment>
                                                        <a className="b-inner-intro__add-info ">&mdash;&nbsp; Для абонентов МОТИВ</a>
                                                        <a className="b-inner-intro__add-info--mobile">Для абонентов МОТИВ</a>
                                                    </React.Fragment> :
                                                        null }
                                                </div>)
                                                : tariff.status.id === 2 ?
                                                <div className="b-inner-intro__btn">
                                                    <div className="b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                        Перемещен в архив
                                                    </div>
                                                </div>
                                                : <div className="b-inner-intro__btn">
                                                    <div className="b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                        Индивидуальный тариф
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    {isMine ? <div className="page__container">
                        <MyTariffReminder tariff={{...tariff, options: this.props.clientInfo.tariff.options}}  exchange={this.props.clientInfo.exchange}/>
                    </div> : null}
                   <div className="page__container">
                        <div className="b-with-aside-area b-with-aside-area_adapt_default">
                            <div className="b-with-aside-area__aside">
                                {this.props.isGuest && paramSvoi === -1 ? <div className="b-service-inner__aside-block">
                                    <div className="b-plug-ways b-plug-ways_adapt_default">
                                        <div className="b-plug-ways__wrapper">
                                            <div className="b-plug-ways__title">Способы подключения</div>
                                            <div className="b-plug-ways__inner">
                                                <div className="b-plug-ways__paragraph">Наберите короткую команду
                                                    <div className="b-plug-ways__command">
                                                        <UssdLink ussd={tariff.ussd_activation_code} className={"black-link"} />
                                                    </div>
                                                </div>
                                                { tariff.switch_by_account
                                                    ?   <div className="b-plug-ways__paragraph">Через&nbsp;
                                                           <a href="#" onClick={this.showModal('change-rate-personal')}>личный кабинет</a>
                                                        </div>
                                                    :   ""
                                                }
                                                <div className="b-plug-ways__paragraph">Другие&nbsp;
                                                    <a href="#" onClick={this.showModal('change-rate-phone')}>способы подключения</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            <div className="b-with-aside-area__column">
                                <RatesPriceList blocks={tariff.info} />
                                <RatesCollapsing info_dropdown={tariff.info_dropdown} tariffServices={tariff.tariffServices} />
                                <DownloadDoc url={tariff.description_url} title={"Скачать информацию о тарифе"} size={tariff.description_file_size}/>
                                {
                                    additionalServices.length > 0
                                        ? <RatesAdditionalServices title={"Дополнительные услуги"} items={additionalServices}/>
                                        : ''
                                }
                            </div>
                        </div>
                        {   otherTariffs.length > 0
                                ?  <React.Fragment>
                                       <div className="page__hr" />
                                       <div className="page__title-3 page__title-3_mobile-less">Другие тарифы</div>
                                       <RatesList onClickConnect={this.openTariffConnectionModal} rates={otherTariffs} byCat={false} linkToShop={true} selectedRegion={selectedRegion} />
                                   </React.Fragment>
                                : ''
                        }
                    </div>
                </div>
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        internet: state.internet,
        isGuest: state.client.isGuest,
        clientInfo: state.client.clientInfo,
        recoveryError: state.client.recoveryFormError || state.client.fatalError,
        successMessageTitle: state.client.successMessageTitle,
        changeTariffMessage: state.client.changeTariffMessage,
        changeTariffError: state.client.changeTariffError,
        settings: state.constructor.settings,
        formsSettings: state.client.formsSettings,
        activation: state.activation,
        forms: state.forms,
        tariffConstructor: state.tariffConstructor,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getTariffs : (regionId) => dispatch (tariffsList(regionId)),
            recover: (data) => dispatch(recovery(data)),
            changeTariff: (data) => dispatch(changeTariff(data)),
            clear: () => dispatch(clearState()),
            getExchangeMins: (regionId) => dispatch(exchangeMins(regionId)),
            clearActivationData: () => dispatch(clearActivationData()),
            getSettings: (regionId) => dispatch(settings(regionId))
        }
    }
)(RateInner);