import React, {Component} from "react";

import Icon from "../common/Icon";
import Select from "../common/Select";
import {emptyValidator, phoneValidator} from "../../tools/form-helper";

export default class GettingType extends Component{

    constructor(props){
        super(props);

        this.state = {
            city: "",
            city_err: null,
        }
    }

    onCityChange = (attr, value) => {
        this.setState({city: value, city_err: null});
    };

    onSubmit = (type) => {
        if ((this.state.city && !this.props.inProgress) || type === 'pickup' ) {
            return () => {
                let data = {
                    city: this.state.city,
                    getting_type: type == "delivery" ? "Доставка" : "В офис продаж",
                    getting_type_alias: type,
                    form_name: "Шаг2"
                };
                this.props.onSubmit(data);
            }
        }
    };

    onFocus = () => {
        this.setState({city_err: null});
    };

    validateCity = () => {
        this.setState({city_err: !emptyValidator(this.state.city)});
    };

    render(){

        var {
            getting_type_delivery_title,
            getting_type_pickup_title,
            getting_type_delivery_description,
            getting_type_pickup_description,
            cities,
            inProgress
        } = this.props;

        return <div className="page__b-choose-plates">
            <div className="b-choose-plates b-choose-plates_adapt-default">
                <div className="b-choose-plates__b-choose-plates">
                    <div className="b-choose-plates__row">
                        <div className="b-choose-plates__col">
                            <div className="b-choose-plates__item">
                                <Icon className="b-choose-plates__icon b-choose-plates__icon_hand u-icon u-icon_hand" name="icon_hand"/>
                                <div className="b-choose-plates__title">{getting_type_delivery_title}</div>
                                <div className="b-choose-plates__text">{getting_type_delivery_description}</div>
                                <div className="b-choose-plates__select">
                                    <div className="b-input-select">
                                        <Select className="b-input-select__select js-select"
                                                options={cities ? cities.map((item) => {
                                                    return {
                                                        label: item,
                                                        value: item
                                                    }
                                                }) : []}
                                                value={this.state.city}
                                                onChange={this.onCityChange}
                                                onblur={this.validateCity}
                                                onfocus={this.onFocus}
                                                hasError={this.state.city_err}
                                        />
                                    </div>
                                </div>
                                <button id={"mnp-type-delivery"} className={"b-choose-plates__btn" + (!this.state.city || inProgress ? " disabled" : "")}
                                        onClick={this.onSubmit("delivery")}
                                >Выбрать</button>
                            </div>
                        </div>
                        <div className="b-choose-plates__col">
                            <div className="b-choose-plates__item">
                                <Icon className="b-choose-plates__icon b-choose-plates__icon_planet u-icon u-icon_il-planet" name="icon_il-planet"/>
                                <div className="b-choose-plates__title">{getting_type_pickup_title}</div>
                                <div className="b-choose-plates__text">{getting_type_pickup_description}</div>
                                <button id={"mnp-type-pickup"} className={"b-choose-plates__btn"  + (inProgress ? " disabled" : "")}
                                        onClick={this.onSubmit("pickup")}
                                >Выбрать</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
