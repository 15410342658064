import React, {Component} from "react";
import {connect} from "react-redux";
import Page404 from "../Page404";
import {Helmet} from "react-helmet";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SidebarHeadInfo from "../../components/common/SidebarHeadInfo";
import RatesActivationMethods from "../../components/Rates/RatesActivationMethods";
import RatesPackageConditions from "../../components/Rates/RatesPackageConditions";
import RatesPriceList from "../../components/Rates/RatesPriceList";
import BBblock from "../../components/Services/BBblock";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import RateCooperation from "../../components/Rates/RateCooperation";
import RatesAdditionalServices from "../../components/Rates/RatesAdditionalServices";
import PropTypes from "prop-types";
import {servicesView} from "../../actions/services";
import ChangeRate from "../../components/Rates/ChangeRate";
import Modal from "../../components/common/Modal";

class ServiceDetail extends Component {
    constructor(props, context) {
        super(props, context);

        const service = props.services.find((item) => item.slug === props.match.params.slug)

        this.state = {
            slug: props.match.params.slug,
            service,
            breadcrumbs: this.makeBreadcrumbs(service),
            modal: null
        }
    }

    makeBreadcrumbs = (service) => {
        const {appRoutes, selectedRegion} = this.context;
        const servicesRoute = appRoutes.find((item) => item.sys_name === 'servicesB2BRoute')
        return servicesRoute && service ? [
            {
                url: "/" + selectedRegion.alias + servicesRoute.route,
                route: servicesRoute.route,
                title: "Услуги"
            },
            {
                url: "/" + selectedRegion.alias + servicesRoute.route + '?category=' + service.mainCategory.slug,
                route: "/" + selectedRegion.alias + servicesRoute.route,
                title: service.mainCategory.title
            },
            {
                url: null,
                title: service.name
            }
        ] : [];
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        appRoutes: PropTypes.array,
        setHeaderClassName: PropTypes.func
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.slug !== prevState.slug) {
            const service = nextProps.services.find((item) => item.slug === nextProps.match.params.slug);

            let breadcrumbs = [...prevState.breadcrumbs];
            breadcrumbs[1].url = breadcrumbs[1].route + '?category' + service.mainCategory.slug;
            breadcrumbs[1].title = service.mainCategory.title;
            breadcrumbs[2].title = service.name

            return {
                ...prevState,
                slug: nextProps.match.params.slug,
                service,
                breadcrumbs
            };
        }
        return prevState;
    }

    componentWillMount() {
        if (!this.state.service) {
            this.props.getService(this.state.slug, this.context.selectedRegion.id)
        }
    }

    showModal = (modal) => {
        return (e) => {
            if (typeof e !== 'undefined') {
                e.preventDefault();
            }
            this.setState({modal})
        }
    }

    hideModal = () => {
        this.setState({modal: null})
    }

    renderModal = () => {
        const {modal, service} = this.state;

        if (!modal) return null;

        let variables = {
            onSuccess: () => {},
            item: service,
            disableQuick: true,
            isGuest: true,
            isB2b: true,
            hideModal: this.hideModal
        }

        switch (modal) {
            case 'change-rate-phone':
                variables = {
                    ...variables,
                    type: 'service',
                    title: "Подключить услугу «" + service.name + "»",
                    value: "phone"
                }
                break;
            case 'deactivate-rate-personal':
            case 'check-rate-personal':
                variables = {
                    ...variables,
                    type: modal,
                    title: `${modal === 'deactivate-rate-personal' ? 'Отключить услугу' : 'Проверка статуса для услуги'} «${service.name}»`,
                    value: ""
                }
                break;
        }

        return <Modal close={this.hideModal}><ChangeRate {...variables}/></Modal>
    }

    render() {
        let {service, breadcrumbs} = this.state;

        if (!service) return <Page404/>

        return (
            <main className="page__main-holder " role="main">
                <Helmet
                    title={service.name + " | Оператор сотовой связи МОТИВ"}
                    meta={[
                        {"name": "description", "content": service.meta_desc},
                        {"name": "keywords", "content": service.meta_keywords}
                    ]}>
                </Helmet>
                <div className="b-service-inner b-service-inner_adapt_default">
                    <div className="b-service-inner__service-header b-service-inner__service-header--mob">
                        <div className="b-inner-intro b-inner-intro_adapt_default">
                            <div className="b-inner-intro__wrapper">
                                <div className="b-inner-intro__inner page__container">
                                    <div className="b-inner-intro__header" />
                                    <div className="b-inner-intro__main-area">
                                        <div className="b-inner-intro__main-area-wrapper">
                                            <RatesBreadcrumbs items={breadcrumbs} />
                                            <h1 className="b-inner-intro__title">Услуга «{service.name}»</h1>
                                            <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: service.short_description}}/>
                                            {   service.base_service
                                                ?   ''
                                                :   <SidebarHeadInfo item={service} isMine={false} type={"service"} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page__container">
                        <div className="b-with-aside-area b-with-aside-area_adapt_default">
                            <RatesActivationMethods isGuest={true} isB2b={true} item={service} settings={this.props.settings} showModal={this.showModal} />
                            <div className="b-with-aside-area__column">
                                {service.additional_description
                                    ?   <div className="b-service-inner__content-area b-service-inner__content-area_top b-service-inner__content-area_pp_si-conf">
                                        <div className="b-content-area" dangerouslySetInnerHTML={{__html: service.additional_description}} />
                                    </div> : ''}
                                {
                                    service.base_service && service.description
                                        ?   <div className="b-service-inner__content-area b-service-inner__content-area_top b-service-inner__content-area_pp_si-conf">
                                            <div className="b-content-area">
                                                {service.description}
                                            </div>
                                        </div>
                                        : service.is_package
                                            ?   null//<RatesPackageConditions items={service.packageServices} showModal={this.showModal} />
                                            :    service.info
                                                ?    <RatesPriceList blocks={service.info} />
                                                :    <BBblock content={service.additional_description} />
                                }
                                {
                                    service.info_dropdown
                                        ?    <RatesCollapsing info_dropdown={service.info_dropdown} />
                                        :    ''
                                }
                                {
                                    service.serviceServiceCooperations.length > 0
                                        ?
                                        <React.Fragment>
                                            <div className="page__title-3 page__title-3_mobile-less page__title-3_socnmest-serv-inner">
                                                Совместимость с другими услугами
                                            </div>
                                            <RateCooperation items={service.serviceServiceCooperations} section={"services"} />
                                        </React.Fragment>
                                        :   ''
                                }
                                {
                                    service.tariffServiceCooperations.length > 0
                                        ?   <React.Fragment>
                                            <div className="page__title-3 page__title-3_mobile-less page__title-3_socnmest-serv-inner">
                                                Совместимость с тарифами
                                            </div>
                                            <RateCooperation items={service.tariffServiceCooperations} section={"tariffs"} />
                                        </React.Fragment>
                                        :   ''
                                }
                            </div>
                        </div>
                        {service.randomServices.length > 0 && <RatesAdditionalServices title={"Другие услуги в категории"} items={service.randomServices} />}
                    </div>
                </div>
                {this.renderModal()}
            </main>
        );
    }
}

export default connect(
    (state) => ({
        services: state.services.service,
        settings: state.settings
    }),
    (dispatch) => {
        return {
            getService : (slug, regionId) => dispatch(servicesView(slug, regionId, 'b2b')),
        }
    }
)(ServiceDetail)

export const ServiceB2bRequests = [
    {
        func: 'getService',
        args: ['b2b'],
        object: 'services.service',
        checkField: 'slug',
        main: true,
        withRegion: true,
        objectReceived: false
    }
];