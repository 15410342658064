import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import LinkC from "../../../js/components/common/LinkC";
import Img from 'react-lazilyload-img';
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import {getPage, routesList, routesVacanciesList} from "../../actions/routes";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";
import {infoBlocksList} from "../../actions/infoBlocks";
import InfoBlock from "../../components/common/InfoBlock";
import {faqList} from "../../actions/questions";
import RatesFaq from "../../components/Rates/RatesFaq";
import FaqSearch from "../../components/Support/FaqSearch";
import AlertBlock from "../../components/Support/AlertBlock";
import RightNav from "../../components/Support/RightNav";
import {RightSupportSlidesInfoBlock} from "../../components/InfoBlocks/InfoBlockSupport";

import {Helmet} from "react-helmet";
import SupportFeedbackForm from "../../components/Support/SupportFeedbackForm";

class Support extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount() {
        const {page, getPage, infoBlocks, getInfoBlocks, faq, getFaq} = this.props;

        if (!page) {
            getPage()
        }
        if (!faq.length) {
            getInfoBlocks(this.context.selectedRegion.id)
        }
        if (!faq.length) {
            getFaq(this.context.selectedRegion.id)
        }
    }

    render() {
        const {page, infoBlocks, faq} = this.props;
     
        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.b2b.find(x => x.sys_name === 'supportB2BRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            // <div className="page__container info-page-container b-support-page-b2b">
            <div className="page__container b-support-page-b2b">
                <Helmet                    
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                </Helmet>

                <h1 className="page__title">Обслуживание и поддержка</h1>
                <div className="page__cols">
                    <div className="page__main-col page__main-col_no-mb">
                        <div className="b-support-faq b-support-faq__b2b b-support-faq_adapt-defoult">
                            {page && page.content ?
                                <div className="legal-information-page__description"
                                     dangerouslySetInnerHTML={{__html: page.content}}/>
                                : null}
                            <SupportFeedbackForm />
                            {faq && faq.list.length ? <RatesFaq items={faq.list} title={'Ответы на вопросы'}/> : null}
                        </div>
                    </div>
                    <div className="page__right-aside about-aside-menu">
                        <div className="right-nav-wrap--support page__aside-cols page__aside-cols_support">
                            <InfoBlock section="support_right_banners" type="b2b_lk"
                                       data={infoBlocks}/>
                            <InfoBlock section="support_right_banners" type="support_right_banners"
                                       data={infoBlocks}/>
                            <InfoBlock section="support_right_messenger"
                                       subsection="support_right_messengers_banners"
                                       type="support_right_messenger"
                                       data={infoBlocks}
                                       additionalProps={{}}
                            />
                        </div>
                    </div>
                </div>
                <div className='page_adapt_support-faq'>
                    <div className="page__right-aside hidden-lg">
                        <div className="page__aside-cols page__aside-cols_support">
                            <div className="page__aside-cols_support__left">
                                <InfoBlock section="support_right_banners" type="b2b_lk"
                                           data={infoBlocks}/>
                                <InfoBlock section="support_right_banners" type="support_right_banners"
                                           data={infoBlocks}/>
                            </div>
                            <div className="page__aside-cols_support__right">
                                <InfoBlock section="support_right_messenger"
                                           subsection="support_right_messengers_banners"
                                           type="support_right_messenger"
                                           data={infoBlocks}
                                           additionalProps={{}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        page: state.support.supportB2BRoute,
        infoBlocks: state.infoBlocks.list.filter((item) => item.groups.map((group) => group.alias).indexOf('support_b2b') !== -1),
        faq: state.faq,
        // faq: state.faq.list.sort((a, b) => a.rank < b.rank || (a.rank === b.rank && a.question < b.question) ? -1 : 1),
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getPage: () => dispatch(getPage('supportB2BRoute')),
            getInfoBlocks: (region) => dispatch(infoBlocksList(region, 'support_b2b')),
            getFaq: (region) => dispatch(faqList(region, null, null, 'b2b'))
        }
    }
)(Support);

export const B2bSupportRequests = [
    {
        func: 'getPage',
        args: ['supportB2BRoute'],
        object: 'support.supportB2BRoute',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['support_b2b'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
];
