const initState = { isOk:false, mediaImg: [], mediaVideo: [], mediaAll: [], mediaAlbum: []};

export function media(state = initState, action) {
    switch (action.type) {
        case 'MEDIA_IMG_LIST':
            return {...state, isOk: false};
        case 'MEDIA_IMG_LIST_MORE':
            return {...state, isOk: false};
        case 'MEDIA_VIDEO_LIST':
            return {...state, isOk: false};
        case 'MEDIA_VIDEO_LIST_MORE':
            return {...state, isOk: false};
        case 'MEDIA_LIST':
            return {...state, isOk: false};
        case 'MEDIA_LIST_MORE':
            return {...state, isOk: false};
        case 'MEDIA_ALBUM_ID':
            return {...state, isOk: false};

        case 'MEDIA_IMG_LIST_SUCCESS':
            return {...state, mediaImg: action.data, isOk: true};
        case 'MEDIA_VIDEO_LIST_SUCCESS':
            return {...state, mediaVideo: action.data, isOk: true};
        case 'MEDIA_LIST_SUCCESS':
            return {...state, mediaAll: action.data, isOk: true};
        case 'MEDIA_IMG_LIST_MORE_SUCCESS':
            return {...state, mediaImg: {data: [...state.mediaImg.data, ...action.data.data], pagination: action.data.pagination}, isOk: true};
        case 'MEDIA_VIDEO_LIST_MORE_SUCCESS':
            return {...state, mediaVideo: {data: [...state.mediaVideo.data, ...action.data.data], pagination: action.data.pagination}, isOk: true};
        case 'MEDIA_LIST_MORE_SUCCESS':
            return {...state, mediaAll: {data: [...state.mediaAll.data, ...action.data.data], pagination: action.data.pagination}, isOk: true};
        case 'MEDIA_ALBUM_ID_SUCCESS':
            return {...state, mediaAlbum: action.data, isOk: true};
        case 'MAIN_PAGE_VIDEOS_SUCCESS':
            return {...state, homeVideos: action.data};
    }
    return state;
}