import React, {Component} from "react";
import PropsTypes from "prop-types";
import {mediaUrl} from "../../tools/config";
import {smoothScroll}  from '../../tools/tools';
import AdvertisingLabeling from "../AdvertisingLabeling/AdvertisingLabeling";

export default class InfoBlockFullScreenBanner extends Component{

    static contextTypes = {
        scrollTo: PropsTypes.func
    };

    render() {

        let {
            blocks,
            subblocks,
            isGuest,
        } = this.props;

        let block = blocks.length ? blocks[0] : null;
        let ind = block.title.indexOf('<br>');

        return block ? <div className="page__promo-intro">
            <AdvertisingLabeling
                token={block && block.token}
                advertiser={block && block.advertiser}
                type="mainType"
            />
            <div className="b-promo-intro b-promo-intro_mnp b-promo-intro_adapt_mnp">
                <div className={`b-promo-intro__wrapper ${!isGuest ? 'dop-size' : ''}`} style={{backgroundImage: "url("+mediaUrl+block.imagePath+")"}}>
                    <div className="b-promo-intro__page-header"/>
                    <div className="page__container">
                        <div className="b-promo-intro__inner">
                            <div className="b-promo-intro__title">
                                {ind !== -1 ? block.title.substr(0,block.title.indexOf('<br>')) : block.title}
                            </div>
                            <div className="b-promo-intro__title-cont">
                                {ind !== -1 ? block.title.substr(block.title.indexOf('<br>') + 4) : null}
                            </div>
                            <div className="b-promo-intro__advantages-plates">
                                <div className="b-advantages-plates b-advantages-plates_adapt_mnp">
                                    <div className="b-advantages-plates__wrapper">
                                        <div className="b-advantages-plates__header"/>
                                        <div className="b-advantages-plates__list">
                                            {block.text ? block.text : subblocks.map((item) => <div key={item.alias} className="b-advantages-plates__item">
                                                <div className="b-advantages-plates__icon">
                                                    <img className="b-advantages-plates__icon-self u-icon u-icon_wi-fi_uncolored" src={mediaUrl+item.imagePath}/>
                                                </div>
                                                <div className="b-advantages-plates__inner">
                                                    <div className="b-advantages-plates__title">{item.title}</div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-promo-intro__btn-area">
                                <a className="b-promo-intro__btn b-promo-intro__btn_choose u-btn u-btn_default u-btn_responsive"
                                   onClick={(e) => {e.preventDefault(); smoothScroll('start-move-form')}}
                                >Перейти в МОТИВ</a>
                                <a className="b-promo-intro__btn b-promo-intro__btn_call u-btn u-btn_transparent-white"
                                   onClick={(e) => {e.preventDefault(); smoothScroll('mnp-callback-form')}}
                                >Заказать звонок</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : null;
    }
}
