import React, { Component } from 'react';

class InfoBlockText extends Component {

    render() {

        let block = this.props.blocks[0];

        return (
            <div className="b-roaming__add-info">
                {block.text}
            </div>
        )
    }
}

export default InfoBlockText;