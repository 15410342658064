import React, {Component, Fragment} from 'react';
import TooltipInfo from "./TooltipQuestion/TooltipInfo";

class CollapseRateTariffs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened
        }
    }

    collapse() {
        if(this.props.item.description) {
            this.setState((prevState) => {
                return {opened: !prevState.opened}
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.opened !== this.props.opened && nextProps.opened !== this.state.opened) {
            this.setState({opened: nextProps.opened})
        }
    }

    componentDidMount(){
        if (this.props.openedDefault) {
            this.setState({opened: true})
        }
    }

    render() {
        let {
            item,
            isSmsTariffs,
            children
        } = this.props;

        let {
            title,
            title_note,
            number,
            cost,
            cost_note,
            service_type,
            provider,
            description
        } = item;

        return (
            <div className={`b-list-collapser__item ${!description && !isSmsTariffs ? 'b-list-collapser__item__not' : ''} ${this.state.opened ? "b-list-collapser__item_collapsed" : ""}`}>
                <div className="b-list-collapser__item-header" onClick={this.collapse.bind(this)}>
                    <div className="b-list-collapser__item-title">
                        <div className="b-list-collapser__item-title-title">Наименование</div>
                        <div className="b-list-collapser__item-title-text">
                            {
                                (!!description || isSmsTariffs) &&
                                <Fragment>
                                    <svg className="b-list-collapser__item-arrow b-list-collapser__item-closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3369 14.7477L6.83591 10.7477L8.16447 9.25275L12.001 12.6623L15.8357 9.25289L17.1646 10.7476L12.6656 14.7476L12.0013 15.3382L11.3369 14.7477Z" fill="black"/>
                                    </svg>
                                    <svg className="b-list-collapser__item-arrow b-list-collapser__item-opened" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.83566 13.2526L11.3357 9.25258L12.0001 8.66197L12.6645 9.25266L17.1635 13.2527L15.8346 14.7473L11.9999 11.338L8.16438 14.7474L6.83566 13.2526Z" fill="#FA6600"/>
                                    </svg>
                                </Fragment>
                            }
                            <div className="b-list-collapser__item-title-text-inner">
                                {title}
                                {title_note && <TooltipInfo text={title_note} />}
                            </div>
                        </div>
                    </div>
                    <div className="b-list-collapser__item-number b-list-collapser__item-number-desktop">{number}</div>
                    <div className="b-list-collapser__item-cost b-list-collapser__item-cost-desktop">
                        {cost}
                        {cost_note && <TooltipInfo text={cost_note} />}
                    </div>
                </div>
                <div className={"u-collapser" + (this.state.opened ? " u-collapser_open" : "")}>
                    <div className="u-collapser__container">
                        <div className="b-list-collapser__collapsed-area ">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="b-list-collapser__item-number b-list-collapser__item-number-mobile">
                    <div className="b-list-collapser__item-number-mobile-title">
                        Номер
                    </div>
                    <div>{number}</div>
                </div>
                <div className="b-list-collapser__item-cost b-list-collapser__item-cost-mobile">
                    <div className="b-list-collapser__item-cost-mobile-title">
                        <span>Цена, руб.</span>
                        <TooltipInfo text={"Цены указаны с учетом НДС (НДС начисляется, согласно действующему законода-тельству РФ)"} />
                    </div>
                    <div className="b-list-collapser__item-cost-mobile-title-text">
                        {cost}
                        {cost_note && <TooltipInfo text={cost_note} />}
                    </div>
                </div>
            </div>
        )
    }
}

export default CollapseRateTariffs;