import React, {Component} from "react";

export default class Tooltip extends Component{

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            onLeft: false
        };

        this._tooltipLabel = null;
    }

    toggle = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.setState((prevState) => {
            return {
                opened: !prevState.opened,
                onLeft: this.isOnTop()
            }
        });
    };

    handlerOpenStylesModal = () => {
        if (this.state.opened) {

            // left

            if (this._tooltipLabel.getBoundingClientRect().left < 286) {
                return {left: `${-this._tooltipLabel.getBoundingClientRect().left + 10}px`};
            }

            // right

            else if ((window.innerWidth || document.body.clientWidth) - this._tooltipLabel.getBoundingClientRect().left < 286){
                return {right: `${ -((window.innerWidth || document.body.clientWidth) - this._tooltipLabel.getBoundingClientRect().left) + 40 }px`};
            }

            // default

            else {
                return {};
            }
        } else {
            return {};
        }
    };

    // change position arrow

    handlerOpenStylesArrow = () => {
        if (this.state.opened) {

            // left

            if (this._tooltipLabel.getBoundingClientRect().left < 286) {
                return {right: `calc(100% - ${this._tooltipLabel.getBoundingClientRect().left}px - 10px - 11px)`};
            }

            // right

            else if ((window.innerWidth || document.body.clientWidth) - this._tooltipLabel.getBoundingClientRect().left < 286) {
                return {right: `${((window.innerWidth || document.body.clientWidth) - this._tooltipLabel.getBoundingClientRect().left) - 40}px`};
            }

            // common

            else {
                return {};
            }

        } else {
            return {};
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.windowClick);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.windowClick);
        window.removeEventListener('resize', this.onResize);
    }

    windowClick = () => {
        this.setState({opened: false});
    };

    onResize = () => {
        if (this.props.id) {
            if (this.isOnTop()) {
                if (this.state.onLeft === false) {
                    this.setState({
                        onLeft: true
                    });
                }
            } else {
                if (this.state.onLeft === true) {
                    this.setState({
                        onLeft: false
                    });
                }
            }
        }
    };

    isOnTop = () => {
        let elem = document.getElementById(this.props.id);

        if (elem !== null) {
            let distance = elem.getBoundingClientRect();

            let windowWidth = window.innerWidth;
            return windowWidth-distance.right < 306;
        }

        return false;
    };

    render(){
        let {
            label,
            openedLabel,
            className = "",
            children,
            id,
            position = "top",
            showOnClick = true
        } = this.props;

        openedLabel = typeof openedLabel === 'undefined' ? label : openedLabel;

        let functions = showOnClick ? {
            onClick: this.toggle
        } : {
            onMouseEnter: this.toggle,
            onMouseLeave: this.toggle
        };

        return <div id={(id ? id : "")} className={"tooltip " + className}>
                   <span className="tooltip__label" {...functions} ref={ref => this._tooltipLabel = ref}>{this.state.opened ? openedLabel : label}</span>
                   {this.state.opened ? <div className={"tooltip__inner tooltip__inner-"+position} style={this.handlerOpenStylesModal()}>
                       <div className="tooltip__arrow" style={this.handlerOpenStylesArrow()} />
                       <span className="tooltip__close" onClick={this.toggle}>&times;</span>
                       {children}
                   </div> : null}
               </div>
    }
}