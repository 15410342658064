import React, { Component } from 'react';
import {Scrollbars} from "react-custom-scrollbars";
import PropTypes from "prop-types"

export default class ListOffices extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.address != nextProps.address && nextProps.address.length == 1 && nextProps.address[0].lat && nextProps.address[0].lon) {
            nextProps.getClosestShopsList(this.context.selectedRegion.id, nextProps.address[0].lat, nextProps.address[0].lon, nextProps.address[0].region, nextProps.address[0].city, nextProps.address[0].street);
        }
    }

    render() {

        let {
            items
        } = this.props;

        return(
            <div className="page__office-list office-list-block">
                <Scrollbars style={{ width: "100%", height: "500px" }}>
                    {
                        items.map((item, key) =>
                            <div className="office-list-block__item office-item-block" key={key}>
                                <div className="office-item-block__info">
                                    <div className="office-item-block__position">
                                        <p className="office-item-block__address">{item.city.name}, {item.address}</p>
                                        {
                                            item.metro ?
                                                <p className="office-item-block__metro">
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='13'
                                                         height='13'
                                                         viewBox='0 0 13 13'
                                                         className="office-item-block__metro-icon">
                                                        <path
                                                            d='M0 8l4 5H0zM13 8l-4 5h4zM0 .497l6.45 6.548L12.869.497V5.44l-6.417 6.58L0 5.44z'
                                                            fill='#23B289' fillRule='evenodd'/>
                                                    </svg>
                                                    {item.metro}
                                                </p>
                                                : null
                                        }

                                    </div>
                                    <div className="office-item-block__work-time">
                                        <p className="office-item-block__weekdays">
                                            {item.working_time}
                                        </p>
                                    </div>
                                </div>
                                <div className="office-item-block__btn">
                                    <button className="u-btn u-btn_transparent">Выбрать</button>
                                </div>
                            </div>
                        )
                    }
                </Scrollbars>
            </div>
        )
    }
}