import React, {Component} from 'react';
import PropTypes from "prop-types";
import {mediaUrl} from "../../tools/config";
import {getMeasure}  from '../../tools/tools';
import LinkC from "../common/LinkC";

class RatesCompleteSetListItem extends Component {

    constructor(props){
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    render() {

        let item = this.props.item;
        let selectedRegion = this.context.selectedRegion;
        let fetchBefore = this.context.fetchBefore;

        return (
            <div className="b-services-group__item">
                <div className="b-packs-table__pack-item">
                    <div className="b-packs-table__header">
                        <div className="b-packs-table__title">
                            <img className="b-packs-table__title_img" src={mediaUrl + item.iconPath} alt="img"/>
                            {item.title}
                        </div>
                    </div>
                    <div className="b-packs-table__body">
                        <div className="b-packs-table__combo-block">
                            <div className="b-packs-table__combo-item b-packs-table__combo-item_first">
                                <div className="b-packs-table__combo-label">Тариф «{item.tariffObj.name}»</div>
                                <div className="b-packs-table__combo-info">
                                    {item.tariffObj.subscription_fee}&nbsp;₽/в месяц
                                </div>
                            </div>
                            <div className="b-packs-table__plus">
                                <div className="u-plus u-plus_color_grey" />
                            </div>
                            <div className="b-packs-table__combo-item b-packs-table__combo-item_second">
                                <div className="b-packs-table__combo-label">{item.product_title}</div>
                                <div className="b-packs-table__combo-info">{item.product_description}</div>
                            </div>
                        </div>
                        <div className="b-packs-table__price">
                            <div className="b-packs-table__old-price">
                                {item.old_price}&nbsp;₽
                            </div>
                            <div className="b-packs-table__current-price">{item.price}
                                <div className="b-packs-table__price-valute">
                                    <span className="u-rub">₽</span>
                                </div>
                            </div>
                        </div>
                        <div className="b-packs-table__action-block">
                            <div className="b-packs-table__btn">
                                <a href={item.link} className="u-btn u-btn_responsive u-btn_default u-btn_adapt_mobile-sm">Купить</a>
                            </div>
                            <LinkC targetBlank={true} className={"b-packs-table__more-info"} to={"/tariffs/" + item.tariffObj.slug}>Подробнее о тарифе</LinkC>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default RatesCompleteSetListItem;