import React, { Component } from 'react';

class BigSearchInput extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
        }
    }

    render() {
        let {
            stateSearchInput,
            onChangeBigInput,
            onSearchBigInput,
            onCancelBigInput,
            stateSearchHint,
            сlickToInput,
            onClickItem,
            dropDown,
            secondDropDown,
            refDropDown,
            refBigSearch,
        } = this.props;

        return (
            <div className='big_search_input'>
                <div className='big_search_input__container'>
                    <input 
                        className='big_search_input__input'
                        placeholder='Поиск'
                        value={stateSearchInput}
                        // первая регулярка на запрет пробела первым символом, вторая на запрет двух и более подряд пробелов
                        onChange={(e) => onChangeBigInput(e.target.value.replace(/^\s/g, '').replace(/\s+/g, ' '))}
                        onClick={сlickToInput}
                        maxLength={50}
                        onKeyDown={(event) => {
                            // по клику на Enter
                            if (event.which === 13) {
                                onSearchBigInput();
                            }
                        }}
                        ref={refBigSearch}
                    />
                    <button
                        onClick={onSearchBigInput}
                        className="big_search_input__icon_search"
                    >
                        <img src={`${require('../../../images/icons/search-black.svg')}`} alt="Поиск" />
                    </button>
                    {stateSearchInput &&
                        <div
                            onClick={onCancelBigInput}
                            className='big_search_input__icon_close'
                        >
                            <img src={`${require('../../../images/icons/close-black.svg')}`} alt="Закрыть" />
                        </div>
                    }

                    <div
                        className={`big_search_input__drop ${(secondDropDown && dropDown) ? 'active' : ''}`}
                        ref={refDropDown}
                    >
                        {stateSearchHint && stateSearchHint.map((i, index) => (
                            <div
                                className='big_search_input__drop_item'
                                key={index}
                                onClick={(e) => onClickItem(e.target.textContent)}
                            >
                                {i.title}
                            </div>
                        ))}
                        
                        {stateSearchHint && stateSearchHint.length < 1
                            && (
                                <div>
                                    По вашему запросу ничего не найдено.
                                </div>
                            )}
                    </div>

                </div>
            </div>
        )
    }
}

export default BigSearchInput;