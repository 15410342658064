import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

class Breadcrumbs extends Component {

    constructor(props) {
        super(props)
    }

    static contextTypes = {
        fetchBefore: PropTypes.func,
    };

    render() {

        let { breadcrumbs } = this.props;
        let fetchBefore = this.context.fetchBefore;

        return (
            <div className="b-breadcrumbs b-breadcrumbs_theme_grey">
                <ul className="b-breadcrumbs__list">
                    {breadcrumbs.map((el, id) =>
                        <li key={id} className="b-breadcrumbs__item">
                            {   el.url
                                ?   el.state
                                    ? <Link className="b-breadcrumbs__link" to={el.url}>{ el.title }</Link>
                                    : <Link className="b-breadcrumbs__link" to={el.url}>{ el.title }</Link>
                                :   <span dangerouslySetInnerHTML={{__html: el.title}}/>
                            }
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default Breadcrumbs;