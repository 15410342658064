import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

export function flagIsB2bAction() {
    return {
        type: 'FLAG_ISB2B'        
    }
}

export function contactsAbout() {
    return {
        type: 'CONTACTS_ABOUT'
    }
}

export function* fetchContactsAbout(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/contacts/about", {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function docsAbout(region = null, businessLine = 'b2c') {
    return {
        type: 'DOCS_ABOUT',
        region,
        businessLine
    }
}

export function* fetchDocsAbout(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                `/docs/about?regionId=${action.region}&is_b2${action.businessLine === 'b2b' ? 'b' : 'c'}=1`, {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function getCompanyParams(params, type = false) {
    return {
        type: type === 'licenses' ? 'COMPANY_LICENSES' : type === 'policy' ? 'COMPANY_POLICY' : type === 'allLicenses' ? 'COMPANY_ALL_LICENSES' : 'COMPANY_REQUISITES',
        params
    }
}

export function* fetchGetCompanyParams(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                action.params, {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function getCompanies(regionId = null) {
    return {
        type: 'COMPANIES_LIST',
        regionId
    }
}

export function* fetchGetCompanies(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                `/companies${action.regionId ? '?regionId='+action.regionId : ''}`, {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}
