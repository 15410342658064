import React, { Component } from 'react';

import Modal from "../common/Modal";
import PropTypes from "prop-types";
import TariffDescription from "../common/TariffDescription";

import RatesPriceList from "../../components/Rates/RatesPriceList";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import DownloadDoc from '../../components/common/DownloadDoc';
import {getMeasure} from "../../tools/tools";

export default class RateChooseItem extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: false
        };
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({modal: true});
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        chooseTariff: PropTypes.func
    };

    hideModal = () => {
        this.setState({modal: false});
    }

    renderModal = () => {
        let {name, short_description, info, info_dropdown, description_url, subscription_fee} = this.props.data;

        if (this.state.modal) {
            return <Modal wrapperClassName="u-modal-wrapper u-modal-wrapper_mnp-tariff"
                          innerClassName="u-modal-inner u-modal-inner_no-padding" close={this.hideModal}>
                <div className="b-tariff-modal b-tariff-modal_adapt_default">
                    <div className="b-tariff-modal__wrapper">
                        <div className="b-tariff-modal__header">
                            <div className="b-tariff-modal__title">Тариф «{name}»</div>
                            <div className="b-tariff-modal__text" dangerouslySetInnerHTML={{__html: short_description}}/>
                            <div className="b-tariff-modal__price">{subscription_fee} ₽/месяц</div>
                            <div className="b-tariff-modal__choose-btn u-btn u-btn_default u-btn u-btn_responsive u-btn u-btn_adapt_mobile-sm" onClick={() => {this.context.chooseTariff(name)}}>Выбрать тариф</div>
                        </div>
                        <RatesPriceList blocks={info} />
                        <RatesCollapsing info_dropdown={info_dropdown}/>
                        <DownloadDoc url={description_url} title={"Скачать информацию о тарифе"} />
                    </div>
                </div>
            </Modal>;
        }
        else return null;
    }

    render() {
        let {mainCategory, name, subscription_fee, info_promo, slug, id, online_shop_link, writeOffPeriod} = this.props.data;

        let selectedRegion = this.context.selectedRegion;

        return (
            <div className="b-page__tariff-item" itemID={id}>
                <div className="b-tariffs-table b-tariffs-table_theme_ecom b-tariffs-table_adapt_ecom">
                    <div className="b-tariffs-table__item">
                        <div className="b-tariffs-table__row">
                            <div className="b-tariffs-table__col-name">
                                {
                                    !this.props.byCat
                                        ?   <div className="b-tariffs-table__type">{mainCategory.title}</div>
                                        :   ''
                                }
                                <div className="b-tariffs-table__name">Тариф&nbsp;
                                    <span className="b-tariffs-table__name-inner">«{name}»</span>
                                </div>
                                <div className="b-tariffs-table__price">
                                    <div className="b-tariffs-table__price-num">{subscription_fee}</div>
                                    <div>
                                        <div className="b-tariffs-table__price-top">
                                            <span className="u-rub">{writeOffPeriod !== null ? getMeasure(writeOffPeriod.measure, 0) : ''}</span>
                                        </div>
                                        <div className="b-tariffs-table__price-bottom">{writeOffPeriod !== null ? '/ ' + getMeasure(writeOffPeriod.measure, 1) : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <TariffDescription tariff={this.props.data} />

                            <div className="b-tariffs-table__col-options">
                                {/*<div className="b-tariffs-table__options-title b-tariffs-table__options-title_undefined">
                                        Акции тарифа
                                    </div>
                                    <div className="b-tariffs-table__options-block">
                                        <div className="b-tariffs-table__options-group">
                                            <label className="b-tariffs-table__label">
                                                <input className="b-tariffs-table__checkbox" type="checkbox"/>
                                                <span className="b-tariffs-table__checkbox-inner">Скидка 50% на абонентскую плату</span>
                                                <span className="b-tariffs-table__checkbox-mo">
                                                    <span className="b-input-switcher b-input-switcher_size_small">
                                                        <span className="b-input-switcher__wrapper">
                                                            <label className="b-input-switcher__area">
                                                                <input className="b-input-switcher__input-hidden" type="checkbox"/>
                                                                <span className="b-input-switcher__switcher">
                                                                    <span className="b-input-switcher__switcher-walker"></span>
                                                                </span>
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="b-tariffs-table__options-group">
                                            <label className="b-tariffs-table__label">
                                                <input className="b-tariffs-table__checkbox" type="checkbox"/>
                                                <span className="b-tariffs-table__checkbox-inner">Серебряный номер при подключении</span>
                                                <span className="b-tariffs-table__checkbox-mo">
                                                    <span className="b-input-switcher b-input-switcher_size_small">
                                                        <span className="b-input-switcher__wrapper">
                                                            <label className="b-input-switcher__area">
                                                                <input className="b-input-switcher__input-hidden" type="checkbox"/>
                                                                <span className="b-input-switcher__switcher">
                                                                    <span className="b-input-switcher__switcher-walker"></span>
                                                                </span>
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>*/}
                            </div>
                            <div className="b-tariffs-table__col-activate">
                                <div className="b-tariffs-table__button b-tariffs-table__button_fixed b-tariffs-table__button_responsive"
                                   onClick={() => {this.context.chooseTariff(name)}}>Выбрать тариф</div>
                                <div className="b-tariffs-table__links">
                                    <a className="b-tariffs-table__link" href="#" onClick={this.showModal}>Подробнее о тарифе</a>
                                </div>
                            </div>

                            {this.renderModal()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}