import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {getPage, routesList} from "../../actions/routes";

class PolicyAgreement extends Component {

    constructor(props) {
        super(props);        
    }

    componentWillMount() {
        if (!this.props.policyAgreement) {
            this.props.getPage('policyAgreementRoute')
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    render() {

        let {
            policyAgreement,
            regions,
            location
        } = this.props;

        let isOk = !!policyAgreement;
        let pageTitle = isOk ? policyAgreement.title : null;
        let content = isOk ? policyAgreement.content : null;

        let pageLink = `https://${regions.host}${location.pathname}`;

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'policyAgreementRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container">
                    <h1 className="page__title">{pageTitle}</h1>
                    <div className={'policy-agreement'} dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        policyAgreement: state.support.policyAgreementRoute,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getPage: (page, company = null) => dispatch(getPage(page, null, company))
        }
    }
)(PolicyAgreement);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const policyAgreementRequests = [
    {
        func: 'getPage',
        args: ['policyAgreementRoute'],
        object: 'support.policyAgreementRoute',
        checkField: null,
        main: true,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];