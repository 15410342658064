import React, { Component } from 'react';
import { mediaUrl } from "../../tools/config";

class DocumentsAbout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        return (
            <div className="b-download-doc__wrapper">
                {data.map((item, key) => {
                    return (
                        <a key={key}
                            className="b-download-doc__item b-download-doc__item_pdf legal-information-page__item-pdf"
                            href={item.link}
                            target="_blank">
                            {item.title ?
                                <div className="legal-information-page__title-pdf" dangerouslySetInnerHTML={{__html: item.title}} />
                            : null}
                            {item.info ?
                                <div className="legal-information-page__description-pdf" dangerouslySetInnerHTML={{__html: item.info}} />
                            : null}
                        </a>
                    )
                })}
            </div>
        )
    }
}

export default DocumentsAbout;