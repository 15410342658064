import React, { Component } from 'react';

export default class OfficeCardMap extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {
            item,
            closeCard,
        } = this.props;

        return(
            <div className="b-map__salon-card salon-card-block">
                <button className="salon-card-block__close-btn" onClick={closeCard}></button>
                <div className="salon-card-block__content">
                    <p className="salon-card-block__address">
                        {item.city.name}, {item.address}
                    </p>
                    <div className="salon-card-block__time-work">
                        <p className="salon-card-block__weekdays">
                            {item.working_time}
                        </p>
                    </div>
                    {
                        item.metro
                            ? <p className="salon-card-block__metro">{item.metro}</p>
                            : null
                    }
                    <button className="salon-card-block__btn-choose u-btn u-btn_transparent">Выбрать</button>
                </div>
            </div>
        )
    }
}