import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function chatForm() {
    return {
        type: 'CHAT_FORM'
    }
}

export function* fetchChatForm(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/chat", {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}