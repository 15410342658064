import React, { Component } from 'react';

import TabsFilterCarousel from "./TabsFilterCarousel";

class TabsFilter extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {

        };

        this.scrollDiv = React.createRef();
    }

    componentDidMount() {
        // let activeTab = this.props.activeTab;
        // let widthWindow = window.innerWidth;

        // let el = document.querySelector(".all_tabs_container__wrapper");
        // let widthAllTabs = el.querySelector(".all_tabs_container__list_desc").offsetWidth;
        // let active = el.querySelector(".active").offsetWidth;
        // console.log('el',el);
        // console.log('widthAllTabs',widthAllTabs);
        // console.log('widthAllTabs',widthAllTabs);
        // console.log('widthWindow',widthWindow);
        // console.log('active',active);

        // let tabPosition2 = widthAllTabs / 2 - widthWindow / 2 - 146 - ((48 + 24) / 2);   // длинна всего контейнера | длинна экрана | первый элемент | марджины?
        // let tabPosition3 = widthAllTabs - widthWindow / 2 - 223 - 230 + 42;              // длинна всего контейнера | длинна экрана | третий элемент | четвертый элемент | марджины?

        // let tabPosition = widthAllTabs - widthWindow ;

        // console.log('tabPosition',tabPosition);

        // if (activeTab === 0 || activeTab === 1) {
        //     this.scrollDiv.current.scrollIntoView({ block: "nearest", inline: "start" });
        // }
        // if (activeTab === 2) {
        //     // el.scrollBy(tabPosition, 0);
        //     // el.scrollBy(30, 0); //320
        //     // el.scrollBy(0, 0); //400
        // }
        // if (activeTab === 3) {
        //     el.scrollBy(tabPosition, 0);
        //     // el.scrollBy(100, 0); // 320
        //     // el.scrollBy(90, 0); //400
        //     // el.scrollBy(15, 0); //500
        // }
        // if (activeTab === 4) {
        //     el.scrollBy(tabPosition, 0);
        //     // el.scrollBy(350, 0); // 320
        //     // el.scrollBy(310, 0); //400
        // }
        // if (activeTab === 5) {
        //     // el.scrollBy(tabPosition3, 0);
        //     // el.scrollBy(260, 0); // 320
        //     // el.scrollBy(0, 0); //400
        // }
        // if (activeTab === 7 || activeTab === 6) {
        //     this.scrollDiv.current.scrollIntoView({ block: "nearest", inline: "end" });
        // }
    }

    componentDidUpdate() {
        // let activeTab = this.props.activeTab;
        // let widthWindow = window.innerWidth;

        // let el = document.querySelector(".all_tabs_container__wrapper");
        // let widthAllTabs = el.querySelector(".all_tabs_container__list_desc").offsetWidth;
        // let active = el.querySelector(".active").offsetWidth;
        // console.log('el',el);
        // console.log('widthAllTabs',widthAllTabs);
        // console.log('active',active);

        // let tabPosition = widthWindow - active / 2

        // let tabPosition2 = widthAllTabs / 2 - widthWindow / 2 - 146 - ((48 + 24) / 2);   // длинна всего контейнера | длинна экрана | первый элемент | марджины?
        // let tabPosition3 = widthAllTabs - widthWindow / 2 - 223 - 230 + 42;              // длинна всего контейнера | длинна экрана | третий элемент | четвертый элемент | марджины?

        // if (activeTab === 0 || activeTab === 1) {
        //     this.scrollDiv.current.scrollIntoView({ block: "nearest", inline: "start" });
        // }
        // if (activeTab === 2) {
        //     // el.scrollBy(tabPosition2, 0);
        //     el.scrollBy(100, 0); //320
        // //     // el.scrollBy(80, 0); //400
        // }
        // if (activeTab === 3) {
        //     el.scrollBy(tabPosition, 0);
        //     // el.scrollBy(350, 0); // 320
        //     // el.scrollBy(310, 0); //400
        // }
        // if (activeTab === 4) {
        //     el.scrollBy(tabPosition3, 0);
        //     // el.scrollBy(350, 0); // 320
        //     // el.scrollBy(310, 0); //400
        // }
        // if (activeTab === 5) {
        //     el.scrollBy(tabPosition3, 0);
        //     // el.scrollBy(350, 0); // 320
        //     // el.scrollBy(310, 0); //400
        // }
        // if (activeTab === 7 || activeTab === 6) {
        //     this.scrollDiv.current.scrollIntoView({ block: "nearest", inline: "end" });
        // }
    }

    render() {
        // let cats = this.props.cats;
        // let activeTab = this.props.slug;

        let {
            activeTab,
            cats,
            toggleCat = () => { },
            isHiddenAll
        } = this.props

        return (

            <div className="all_tabs_container">
                <div className="all_tabs_container__wrapper">
                    <div className="all_tabs_container__list_desc">
                        {
                            !isHiddenAll &&
                            <div
                                onClick={(e) => { toggleCat('Все') }}
                                className={`tabs_container__list__item ${'Все' === activeTab ? 'active' : ''}`}
                            >
                                Все
                            </div>
                        }
                        {cats.map((item, index) =>
                            <div
                                key={item.name}
                                onClick={(e) => { toggleCat(item.name) }}
                                className={`tabs_container__list__item ${(item.name === activeTab || item.id === activeTab) ? 'active' : ''}`}
                            >
                                {item.name}
                            </div>
                        )}
                    </div>

                    {/* <div className="all_tabs_container__list_mob">
                        <div
                            onClick={(e) => { toggleCat('Все') }}
                            className={`tabs_container__list__item ${'Все' === activeTab ? 'active' : ''}`}
                        >
                            Все
                        </div>
                        <TabsFilterCarousel blocks={cats} slug={activeTab} toggleCat={this.props.toggleCat} />
                    </div> */}
                </div>
            </div>
        )
    }
}

export default TabsFilter;