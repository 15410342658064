import React, { Component } from 'react';

export default class TabSwitchItem extends Component {

    renderItem = () => {

        let {
            activeTab,
            onClickFunc,
            item,
            hasWrap,
            idx
        } = this.props;

        let result = <a className={"b-tabs-header__item" + ((activeTab === idx) ? " b-tabs-header__item_active b-tabs-header__item_active--media" : "")}
                        onTouchStart={e=>e.target.classList.add("b-tabs-header__item_hover_effect")}
                        href="#" onClick={onClickFunc(idx, item.tab)}>{item.title}</a>;

        if (hasWrap) {
            result = <div className="b-tabs-header__item-wrap">{result}</div>;
        }

        return result;
    };

    render() {
        return this.renderItem()
    }
}