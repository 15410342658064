import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RateListItemNew from '../Rates/RateListItemNew';
import RateListItemAll from '../Rates/RateListItemAll';
import TabsFilter from "../../components/Rates/TabsFilter";
import { isTomorrow } from 'date-fns';

class AdditionalServices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'Популярное',
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    componentWillMount() {

    }

    componentDidMount() {

    }

    toggleCat = (data) => {
        this.setState({
            activeTab: data
        });
    };

    createArrayForOtherTabs = () => {
        let {
            tariffConstructor
        } = this.props;

        let optionsArr = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.options
        let categoryList = tariffConstructor && tariffConstructor.recalcData.categories
        let cate = categoryList && categoryList.find(i => i.name === this.state.activeTab);
        let arr = [];

        // ищем совпадения между 2 массивами и записываем данные в новый массив
        cate && cate.options.forEach(function (item) {
            optionsArr && optionsArr.forEach(function (i) {
                if (i.type === item.type) {
                    arr.push(i);
                }
            })
        });

        return arr;
    }
    createArrayForAllTabs = () => {
        let {
            tariffConstructor,
        } = this.props;

        let optionsArr = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.options
        let categoryList = tariffConstructor && tariffConstructor.defaultData.categories

        // создаем массив элементов для таба 'Все'
        let copyCategoryList = [...categoryList];
        let tabsAll = copyCategoryList.map((item) => {
            let arr2 = [];
            // ищем совпадения между 2 массивами и записываем данные в новый массив
            item.options.forEach(function (i) {
                optionsArr && optionsArr.forEach(function (x) {
                    if (x.type === i.type) {
                        arr2.push(x)
                    }
                })
            });

            return {
                name: item.name,
                category: arr2,
            }
        });

        return tabsAll;
    }

    render() {
        let {
            activeTab,
        } = this.state;

        let {
            tariffConstructor,
            optionOtherArr,
            maxFixedPrice,

            checkboxClick,
            nameData,
            valueData,
            onChangeData,
            nameRouter,
            valueRouter,
            onChangeRouter,
            nameSocial,
            valueSocial,
            onChangeSocial,
            nameVoice,
            valueVoice,
            onChangeVoice,
            nameYoutube,
            valueYoutube,
            onChangeYoutube,
            nameIvi,
            valueIvi,
            onChangeIvi,
            nameHome,
            valueHome,
            onChangeHome,
            currentSmsPack,
            optionSmsArr,
            chOn,
            disabledRqs
        } = this.props

        let categoryList = tariffConstructor && tariffConstructor.defaultData.categories

        let arr = this.createArrayForOtherTabs();
        let arr2 = this.createArrayForAllTabs();

        return (
            <div className="tariffs_additional_services">
                <h2>Подключите дополнительные услуги</h2>
                <TabsFilter
                    cats={categoryList}
                    activeTab={activeTab}
                    toggleCat={this.toggleCat}
                />

                {activeTab !== 'Все' &&
                    (<React.Fragment>
                        {arr && arr.map((item) =>
                            <RateListItemNew
                                key={item.type}
                                data={item}
                                maxFixedPrice={maxFixedPrice}

                                checkboxClick={checkboxClick}

                                nameData={nameData}
                                valueData={valueData}
                                onChangeData={onChangeData}

                                nameRouter={nameRouter}
                                valueRouter={valueRouter}
                                onChangeRouter={onChangeRouter}

                                nameSocial={nameSocial}
                                valueSocial={valueSocial}
                                onChangeSocial={onChangeSocial}

                                nameVoice={nameVoice}
                                valueVoice={valueVoice}
                                onChangeVoice={onChangeVoice}

                                nameYoutube={nameYoutube}
                                valueYoutube={valueYoutube}
                                onChangeYoutube={onChangeYoutube}

                                nameIvi={nameIvi}
                                valueIvi={valueIvi}
                                onChangeIvi={onChangeIvi}

                                nameHome={nameHome}
                                valueHome={valueHome}
                                onChangeHome={onChangeHome}

                                currentSmsPack={currentSmsPack}
                                optionSmsArr={optionSmsArr}
                                toggleSmsPack={this.props.toggleSmsPack}

                                chOn={chOn}
                                disabledRqs={disabledRqs}
                            />)
                        }
                    </React.Fragment>)
                }
                {activeTab === 'Все' &&
                    (<React.Fragment>
                        {arr2.map((item) =>
                            <RateListItemAll
                                key={item.type}
                                data={item}
                                maxFixedPrice={maxFixedPrice}

                                checkboxClick={checkboxClick}

                                nameData={nameData}
                                valueData={valueData}
                                onChangeData={onChangeData}

                                nameRouter={nameRouter}
                                valueRouter={valueRouter}
                                onChangeRouter={onChangeRouter}

                                nameSocial={nameSocial}
                                valueSocial={valueSocial}
                                onChangeSocial={onChangeSocial}

                                nameVoice={nameVoice}
                                valueVoice={valueVoice}
                                onChangeVoice={onChangeVoice}

                                nameYoutube={nameYoutube}
                                valueYoutube={valueYoutube}
                                onChangeYoutube={onChangeYoutube}

                                nameIvi={nameIvi}
                                valueIvi={valueIvi}
                                onChangeIvi={onChangeIvi}

                                nameHome={nameHome}
                                valueHome={valueHome}
                                onChangeHome={onChangeHome}

                                currentSmsPack={currentSmsPack}
                                optionSmsArr={optionSmsArr}
                                toggleSmsPack={this.props.toggleSmsPack}

                                chOn={chOn}
                                disabledRqs={disabledRqs}
                            />)
                        }
                    </React.Fragment>)
                }
            </div>
        )
    }
}

export default connect(
    state => ({
        tariffConstructor: state.tariffConstructor
    })
)(AdditionalServices);