import React, {Component} from 'react';
import {connect} from "react-redux";
import {tariffArchiveList} from "../../actions/tariffs";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import RatesArchiveCatList from "../../components/Rates/RatesArchiveCatList";

class RatesArchive extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        if (this.props.tariffs.archiveTariffsList.length === 0) {
            this.props.getTariffsArchive();
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    tariffsByCategories (tariffs)
    {
        let categories = [];
        let result = [];

        /*tariffs.sort(function (t1, t2) {
            return t2.rank - t1.rank;
        });*/

        tariffs.forEach(function (elem) {
            categories[elem.mainCategory.id] = elem.mainCategory;
        });

        categories = categories.filter(v => v);

        categories = categories.sort(function (catA, catB) {
            return catB.rank_main - catA.rank_main;
        });

        categories.forEach(function (cat, i) {

            let obj = {'title': '', 'items' : []};

            obj.title = cat.title;
            result[i] = obj;

            tariffs.forEach(function (elem) {
                if (cat.id === elem.mainCategory.id) {
                    result[i].items.push(elem);
                }
            });
        });

        return result;
    }

    render() {
        const { regions, location } = this.props;
        let archiveTariffsList = this.props.tariffs.archiveTariffsList;
        let selectedRegion = this.context.selectedRegion;

        archiveTariffsList = archiveTariffsList.filter(x => x.region_id === this.context.selectedRegion.id);

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/tariffs",
                title: "Тарифы"
            },
            {
                url: null,
                title: "Архив тарифов"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");
       
        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'tariffsArchiveRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container">
                    <div className="b-services-archive b-services-archive_adapt_default">
                        <div className="b-services-archive__wrap">
                            <div className="page__intro-simple">
                                <div className="b-intro-simple b-intro-simple_adapt_default">
                                    <RatesBreadcrumbs items={breadcrumbs} />
                                    <div className="b-intro-simple__title">Архив тарифов</div>
                                    <div className="b-intro-simple__subtitle">Обращаем внимание на то, что архивные тарифные планы закрыты для перехода и подключения.</div>
                                </div>
                                <RatesArchiveCatList items={archiveTariffsList} url={"/" + selectedRegion.alias + "/tariffs/archive/"} type={'tariff'} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        regions: state.regions,

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getTariffsArchive : () => dispatch (tariffArchiveList())
        }
    }
)(RatesArchive)


