import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RatesLinks from '../../components/Rates/RatesLinks';
import InfoBlock from '../../components/common/InfoBlock';
import { infoBlocksList } from "../../actions/infoBlocks";
import RatesList from '../../components/Rates/RatesList';
import { tariffsList, tariffFilterSettings, tariffSelectionSettings } from "../../actions/tariffs";
import { getNelementsArray, isEmptyObj, checkAllUrlParams, setCookie } from './../../tools/tools';
import { checkWord, clearWord, clearActivationData } from "../../actions/activation";
import { tariffInfo, exchangeMinsSend, exchangeMins, services } from "../../actions/client";
import { auth, recovery, clearState } from "../../actions/client";
import TariffConnectionModal from "../../components/modals/constructor/TariffConnectionModal";
import {InfoModal, SuccessInfoModal} from "../../components/modals/constructor/InfoModal";
import {cookieDomain} from "../../tools/config";
import RatesByCategories from "../../components/Rates/RatesByCategories";
import LinkC from "../../components/common/LinkC";

import Slider from 'rc-slider';
import {Modal} from "../common/Modal";
import SuccessForm from "../Forms/SuccessForm";

class ReadyTariffs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readyTariffs: [],
            idTariff: '',
            name: '',
            testWorld: false,
            constructorParams: {},
        }

    }

    static contextTypes = {
        isGuest: PropTypes.bool,
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func
    };
    static childContextTypes = {
        setHasItemModal: PropTypes.func,
        constructorParams: PropTypes.object,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.client.exchangeMinsSuccess) {
            return { ...state, modal: "exchange-success" }
        }
        if (props.client.exchangeMinsError) {
            return { ...state, modal: "exchange-error" }
        }

        if (!props.isGuest && state.modal === "AuthorizationModal") {
            return { ...state, modal: null }
        }

        if (props.client.fatalError && ["AuthorizationModal", "RecoveryModal"].indexOf(state.modal) > -1) {
            return { ...state, modal: "ErrorModal" }
        }

        if (props.client.successMessageTitle && "RecoveryModal" === state.modal) {
            return { ...state, modal: "SuccessModal" }
        }
        return state;
    }

    componentWillMount() {
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }

        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'tariffs');
        }
    }

    componentDidMount() {
        if (this.state.readyTariffs.length === 0) {
            this.readyTariffsSet();
        }

        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }

        // if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'tariffs');
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.readyTariffs.length === 0) {
            this.readyTariffsSet();
        }

        if (prevState.modal !== this.state.modal && this.state.modal === 'tariff-connection') {
            if (typeof document !== "undefined") {
                if (this.props.isGuest) {
                    const el = document.querySelector('.phone_focus');
                    if (el) el.focus();
                } else {
                    const el = document.querySelector('.email_focus');
                    if (el) el.focus();
                }
            }
        }

        if (this.props.forms && this.props.forms.isSubmitOk != prevProps.forms.isSubmitOk && this.props.forms.isSubmitOk && !this.props.connectionType) {
            if(this.props.forms.isSubmitOk === 'order_sent_failed') {
                this.openSuccess("repeat-mnp-modal")
            } else {
                this.openSuccess("success")
            }
        }
    }

    // getChildContext() {
    //     return {
    //         constructorParams: this.state.constructorParams,
    //         setHasItemModal: (val) => {
    //             this.setState({ hasItemModal: val })
    //         }
    //     }
    // }

    readyTariffsSet = () => {
        let tariffs = [];
        let count = 0;
        if (this.props.tariffs && this.props.tariffs.tariffsLists && this.props.tariffs.tariffsLists.length > 0 && this.state.readyTariffs.length === 0) {
            const allTariffsLists = [...this.props.tariffs.tariffsLists];
            let tarifsListSvoi = [];

            allTariffsLists.forEach(el => {
                if (el.slug.indexOf('svoi') !== -1) {
                    tarifsListSvoi.push(el);
                }
            })

            // let amountElem = tarifsListSvoi && tarifsListSvoi.length >= 4 ? 4 : tarifsListSvoi.length;
            // tariffs = getNelementsArray(tarifsListSvoi, amountElem);

            this.setState({
                readyTariffs: tarifsListSvoi
                // readyTariffs: tariffs
            })
        }
    }

    openTariffConnectionModal = (modal, idTariff) => {
        if (!!idTariff && this.state.idTariff === '') {
            this.setState({
                idTariff
            })
        }

        if (modal === 'tariff-connection') {
            if (this.state.name !== "") {
                this.props.checkWord(this.state.name);
                this.setState({ testWorld: true });
            } else {
                this.setState({ modal });
            }
        } else {
            this.setState({ modal });
        }

        this.hideScroll();
    };

    openSuccess = (modal) => {
        if (!this.props.isGuest && modal === 'success-tariff-connection') {
            setCookie('has_connection', new Date().getTime() + 300000, {expires: 300, path: '/', domain: cookieDomain})
        }
        this.setState({modal: modal});
    };

    renderModal() {
        let modifiedUrl = this.props.location && this.props.location.pathname;
        let urlStr = modifiedUrl.substr(1).split('-');
        let idTariff = this.state.idTariff;
        let tariff = this.props.tariffs && this.props.tariffs.tariffsLists.filter(x => x.id === idTariff);
        let tariffParams = !!idTariff && tariff && tariff[0];

        let tariffNameModal = !!idTariff && tariffParams.name;
        let data_unlim_info = !!idTariff && (tariffParams.svoi.data_unlim ? 1 : 0);
        let sms = !!idTariff && (tariffParams.svoi.sms ? 1 : 0);
        let smsCount = !!idTariff && (tariffParams.sms_count ? parseInt(tariffParams.sms_count) : 0);
        let paramGb = !!idTariff && (tariffParams.svoi.data === "безлимит" || tariffParams.svoi.data === "Безлимит" ? 0 : parseInt(tariffParams.svoi.data));
        let router = !!idTariff && tariffParams.svoi.router;
        let paramMin = !!idTariff && parseInt(tariffParams.svoi.min);
        let paramSocialUnlim = !!idTariff && (tariffParams.svoi.social_network_unlim ? 1 : 0);
        let paramMotivVoiceUnlim = !!idTariff && (tariffParams.svoi.motiv_voice_unlim ? 1 : 0);
        let youtube_unlim = !!idTariff && (tariffParams.svoi.youtube_unlim ? 1 : 0);
        let ivi = !!idTariff && (tariffParams.svoi.ivi ? 1 : 0);
        let everywhere_at_home = !!idTariff && (tariffParams.svoi.home_region_checked ? 1 : 0);

        const objOptionNames = {};
        if (this.props.tariffConstructor.recalcData.options) {
            this.props.tariffConstructor.recalcData.options.forEach(el => { objOptionNames[el.type] = el.name })
        } else if (this.props.tariffConstructor.defaultData.options) {
            this.props.tariffConstructor.defaultData.options.forEach(el => { objOptionNames[el.type] = el.name })
        }

        if (typeof document !== 'undefined') {
            if (this.state.modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        switch (this.state.modal) {
            case "filter":
                let filterSettings = this.props.filterSettings;

                return <Modal close={this.toggleModal(null)} innerClassName="u-modal-inner no-padding" closeBtnClassName="mnp-popup__close mnp-popup__close--white">
                    {filterSettings && filterSettings.isOk ? <RatesFilter {...filterSettings} onTrafficChange={this.onTrafficChange} closeModal={this.toggleModal(null)} /> : null}
                </Modal>;

            // case "wrong-name":
            //     return <InfoModal close={this.closeModal} />;
            case "tariff-connection":
                return <TariffConnectionModal tariffName={tariffNameModal}
                    close={this.closeModal}
                    openSuccess={this.openSuccess}
                    settings={this.props.settings}
                    data_unlim_info={data_unlim_info}
                    urlStr={urlStr}
                    typeConnectionModal={'svoi'}
                    tariffParams={tariffParams}
                    smsCount={smsCount}
                    apiOptionNames={objOptionNames}
                />;
            case "success":
                return <SuccessInfoModal title="Ваша заявка принята!"
                    text={this.props.settings.submitting_request_mnp_description}
                    btnText="Отлично!"
                    close={this.closeModal}
                    apiOptionNames={objOptionNames}
                />;
            case "success-tariff-connection":
                return <SuccessInfoModal title={'Тариф "' + tariffNameModal + '" успешно подключен'}
                    btnText="Ок!"
                    close={this.closeModal}

                    router={router}
                    gb={paramGb}
                    min={paramMin}
                    social_unlim={paramSocialUnlim}
                    sms={sms}
                    smsCount={smsCount}
                    motiv_voice_unlim={paramMotivVoiceUnlim}
                    youtube_unlim={youtube_unlim}
                    ivi={ivi}
                    everywhere_at_home={everywhere_at_home}
                    apiOptionNames={objOptionNames}
                    settings={this.props.settings}
                    isConstructor={!idTariff}
                />;
            case "repeat-mnp-modal":
                const form = this.props.forms.list.filter((item) => {return item.alias === "order_sent_failed"});
                const title = form[0].settings.hasOwnProperty('mnp_form_registry_title') ? form[0].settings['mnp_form_registry_title'] : "Ваша заявка принята";
                const text = form[0].settings.hasOwnProperty('mnp_form_registry_description') ? form[0].settings['mnp_form_registry_description'].replace("{{registry_time}}", form[0].settings['mnp_form_registry_time']) : "";

                return <Modal close={this.closeModal} isFromSuccessModal={true}>
                    <SuccessForm title={title} text={text} icon={false} />
                </Modal>;

            default:
                return null;
        }
    }

    hideScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.page');
            if (el) el.classList.add('no-scroll');
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.page');
            if (el) el.classList.remove('no-scroll');
        }
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        // this.props.clearActivationData();
        this.setState({ modal: '' });
        this.showScroll();

        if (this.state.idTariff !== '') {
            this.setState({
                idTariff: ''
            })
        }
    };

    sortByCatRank (items) {
        return items.sort(function (t1, t2) {
            return t2.rank_categories - t1.rank_categories;
        })
    }

    render() {
        let { filterData, constructorParams, readyTariffs, name, smsCount } = this.state;
        let {
            activeTab,
        } = this.props;
        let selectedRegion = this.context.selectedRegion;

        let infoBlocks = this.props.infoBlocks.list;
        return (
            <div className="ready_tariffs">
                <li className={`tabs-content__item ${activeTab === 1 ? " tabs-content__item--active" : ""}`}>
                    <div className="page__title-3 page__title-3_mobile-less">Выбрать готовый тариф</div>
                    <RatesList
                        // rates={readyTariffs}
                        rates={this.sortByCatRank(readyTariffs.slice(0, 10))}
                        byCat={false}
                        linkToShop={true}
                        selectedRegion={selectedRegion}
                        onClickConnect={this.openTariffConnectionModal}
                    />
                </li>
                <li className={"tabs-content__item" + ((activeTab === 2) ? " tabs-content__item--active" : "")}>
                    <RatesByCategories showCategoryTitle={false} tariffs={this.props.tariffsRT} rankField={'rank_smart'} onClickConnect={this.openTariffConnectionModal} />
                </li>
                <li className={"tabs-content__item" + ((activeTab === 3) ? " tabs-content__item--active" : "")}>
                    <RatesByCategories tariffs={this.props.tariffsRT} rankField={'rank_main'} onClickConnect={this.openTariffConnectionModal} />
                </li>

                {activeTab !== 0
                    ? <React.Fragment>
                        <div className="page__container">
                            <div className="tabs-button__block">
                                <RatesLinks title={"Архив тарифов"} url={"/" + selectedRegion.alias + "/tariffs/archive"} />
                                {activeTab !== 0 && activeTab !== 3 
                                    ? <div 
                                        className="b-tariffs-list__archive-link b-tariffs-list__category-list" 
                                        onClick={() => this.props.toggleCat(3)}
                                    >
                                        Все тарифы по категориям
                                    </div> 
                                    : null
                                }
                            </div>

                            {activeTab === 3 && (
                                <div className="tabs-button__block-footnote">
                                    <p>*Все тарифы - тарифы, доступные для перехода и подключения.</p>
                                    <p>Если Вы не нашли информацию о каком-либо тарифе, то для ее получения можете обратиться в&nbsp; 
                                        <LinkC to={"/for-subscribers"} className="tabs-button__block-footnote-link">контакт-центр</LinkC>
                                        &nbsp;или&nbsp;
                                        <LinkC to={"/motiv-on-map"} className="tabs-button__block-footnote-link">офис обслуживания</LinkC>.
                                    </p>
                                </div>
                            )}

                            <InfoBlock type={'banner'} data={infoBlocks} section={'main_page_banners'} />
                        </div>
                        <div className="page__container">
                            <div className="rates-slider">
                                <InfoBlock type={'before-slider'} data={infoBlocks} section={'infoblock_groups_promos'}/>
                                <InfoBlock type={'slider'} data={infoBlocks} section={'main_page_slider'}/>
                            </div>
                        </div>
                    </React.Fragment>
                    : null
                }
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        client: state.client,
        isGuest: state.client.isGuest,
        infoBlocks: state.infoBlocks,
        tariffConstructor: state.tariffConstructor,
        settings: state.constructor.settings,
        forms: state.forms,
        connectionType: state.common.connectionType,
    }),
    (dispatch) => {
        return {
            getTariffs: (regionId) => dispatch(tariffsList(regionId)),
            checkWord: (word) => dispatch(checkWord(word)),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            auth: (data, rememberMe) => dispatch(auth(data, rememberMe)),
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),
        }
    }
)(ReadyTariffs);
