import React, {Component} from "react";

export default function ContactsMedia({contacts}){
    let data = contacts ? contacts[0] : null;

    return ( !!data ? <div className="contacts-media-block">
            {data && data.title ? <span className="contacts-media-block__title" dangerouslySetInnerHTML={{__html: data.title}} /> : null}
            <div className="contacts-block">
                {data && data.address ?
                        <div className="contacts-media-block__address-block">
                            <span className="contacts-media-block__dscr">Адрес</span>
                            <span className="contacts-media-block__txt" dangerouslySetInnerHTML={{__html: data.address}} />
                        </div>
                : null}
                {data && data.fio ?
                    <div className="contacts-media-block__fio-block">
                        <span className="contacts-media-block__txt" dangerouslySetInnerHTML={{__html: data.fio}} />
                    </div>
                    : null}
                {data && data.phone ?
                    <div className="contacts-media-block__phone-block">
                        <span className="contacts-media-block__dscr">Телефон</span>
                        {data && data.phone.split(';').map((phone) =>
                            <a className="contacts-media-block__txt" href={phone ? "tel:"+phone.replace(/\D/, '') : "#"}>{ phone }</a>
                        )}
                    </div>
                : null}
                {data && data.email ?
                    <div className="contacts-media-block__email-block">
                        <span className="contacts-media-block__dscr">E-mail</span>
                        <a className="contacts-media-block__txt contacts-media-block__email" href={'mailto:' + data.email }>{ data.email }</a>
                    </div>
                : null}
            </div>
        </div> : null
    )
}