import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import LinkC from "../../../js/components/common/LinkC";
import Img from 'react-lazilyload-img';
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { dataForSubscribersAction } from '../../actions/contacts';
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import { routesList, routesVacanciesList } from "../../actions/routes";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";


class ForSubscribers extends Component {
    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    // для ssr
    componentWillMount() {
        this.props.dataForSubscribersAction();
    }

    componentDidMount(){
        this.props.dataForSubscribersAction();
    }

    render() {

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "/" + selectedRegion.alias + '/contacts',
                title: "Контакты"
            },
            {
                url: "",
                title: 'Для абонентов'
            }
        ];

        const {
            regions,
            location,
            stateDataForSubscribers,
        } = this.props;
        
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'forSubscribersRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        
        return (
            <div className="page__container info-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="info-page-container__title">{stateDataForSubscribers && stateDataForSubscribers[0] && stateDataForSubscribers[0].title}</h1>

                        <div
                            className="for-subscribers-page__content"
                            dangerouslySetInnerHTML={{__html: stateDataForSubscribers && stateDataForSubscribers[0] && stateDataForSubscribers[0].content}}
                        />

                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <AsideMenu 
                                flag={"contactsRoute"} 
                                flagSubMenu={"forSubscribersRoute"} 
                                route={this.props.routesVacancies} 
                                customTitleMenu={'Контакты'} 
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        regions: state.regions,
        stateDataForSubscribers: state.contacts.stateDataForSubscribers,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            dataForSubscribersAction: () => dispatch(dataForSubscribersAction()),
        }
    }
)(ForSubscribers);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const ForSubscribersRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];
