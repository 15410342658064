import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { checkOperator, clearOperatorData } from "../../actions/activation";
import { submitMnpForm } from "../../actions/forms";
import { cookieDomain } from "../../tools/config";
import { emailValidatorText, phoneValidatorText } from "../../tools/form-helper";
import { phoneMask } from "../../tools/mask";
import { checkUtmParams, getCookie, setCookie, setIdButton } from "../../tools/tools";
import FormField from "./FormField";
import PdfLink from "./PdfLink";


class MnpConnectionForm extends Component {

    constructor(props) {
        super(props);

        props.clearOperatorData();

        this.state = {
            fields: {
                form_name: {
                    value: "тариф “Конструктор”"
                },
                phone_number: {
                    type: "text",
                    name: "phone_number",
                    label: "Введите номер для переноса",
                    value: "",
                    placeholder: "+7",
                    error: "",
                    mask: phoneMask,
                    validate: phoneValidatorText,
                    typeInput: "phone"
                },
                email: {
                    type: "text",
                    name: "email",
                    label: "Ваш e-mail",
                    value: "",
                    placeholder: "info@mail.ru",
                    hint: "На указанный электронный адрес мы вышлем письмо с подробным описанием подключаемого тарифа.",
                    error: "",
                    validate: emailValidatorText,
                    typeInput: "email"
                },
                i_agree: {
                    type: "checkbox",
                    name: "i_agree",
                    label: <span dangerouslySetInnerHTML={{ __html: props.settings.rules_checkbox_text }} />,
                    value: false,
                    error: ""
                },
                tariff_conditions: {
                    type: "checkbox",
                    name: "tariff_conditions",
                    label: <span>С <PdfLink constructor={props.constructor} tariffParams={this.props.tariffParams} className="link-accent">условиями тарифа</PdfLink> ознакомлен, условия понятны, претензий к Оператору не имею.</span>,
                    value: false,
                    error: ""
                },
                tariff_transfer: {
                    type: "checkbox",
                    name: "tariff_transfer",
                    label: <span>Я соглашаюсь на получение консультации и информационных смс по переносу номера от "МОТИВ".</span>,
                    value: false,
                    error: ""
                }
            },
            formValid: false,
            disabledBtn: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        constructorParams: PropTypes.object,
        locationPath: PropTypes.string,
    };

    componentWillReceiveProps(nextProps) {

        if (nextProps.activation.mnp.regionId && (this.props.activation.mnp !== nextProps.activation.mnp)) {
            let operator = nextProps.activation.mnp;
            if (operator.statusCode != 3003 && operator.regionId && (operator.regionId === this.context.selectedRegion.id) && (operator.operatorCode !== operator.ownOperatorCode)) {
                //     this.setMnpCookie(nextProps.activation.mnp);
                //     let link = document.getElementById("mnp-link");
                //     link.href = mainUrl + this.context.selectedRegion.alias + '/mnp';
                //     console.log(link.href);
                //     link.click();
                this.sendForm(nextProps.activation.mnp);
            }
        }

        if (!!nextProps.activation.mnp) {
            if (!!nextProps.activation.mnp.statusCode && this.props.activation.mnp.statusCode !== nextProps.activation.mnp.statusCode && nextProps.activation.mnp.statusCode === 3003 ||
                nextProps.activation.mnp.regionId !== this.context.selectedRegion.id ||
                nextProps.activation.mnp.hasOwnProperty('operatorCode') && nextProps.activation.mnp.operatorCode === nextProps.activation.mnp.ownOperatorCode) {
                if (!!this.state.disabledBtn) this.setState({ disabledBtn: false });
            }
        }
    }

    componentWillUnmount() {
        if (!!this.state.disabledBtn) this.setState({ disabledBtn: false });
    }

    onChange = (name, value) => {
        let fields = { ...this.state.fields };

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        fields[name].error = fields[name].validate ? fields[name].validate(fields[name].value) : "";

        this.setState({ fields });

        this.validateForm();
    };

    validateForm = () => {
        let fields = { ...this.state.fields };

        let formValid = true;

        if (fields['phone_number'].validate(fields['phone_number'].value) !== "") {
            formValid = false;
        }
        /*if (fields['email'].validate(fields['email'].value) !== "") {
            formValid = false;
        }*/

        if (this.props.typeConnectionModal === 'svoi' && fields['tariff_transfer'].value === false) {
            formValid = false;
        }

        this.setState({ formValid: formValid });
    };

    renderField = (name, className = false) => {
        let disabled = this.state.fields[name].type === "checkbox" && this.props.activation.inProgress;
        const disabledBtn = this.state.disabledBtn;

        return <FormField
            {...this.state.fields[name]}
            disabled={disabled}
            onChange={this.onChange}
            className={className + (disabledBtn ? ' phone-number__disabled' : '')}
        />;
    };

    checkOperator = (e) => {
        e.preventDefault();

        if (!this.props.activation.inProgress) {
            let data = {
                phone: this.state.fields.phone_number.value.replace(/\D/gim, ''),
            };
            let tariffParams = this.props.tariffParams;
            if (!!tariffParams) {
                this.props.checkOperator(data, 'ready_svoi');
            } else {
                this.props.checkOperator(data);
            }

        }
        this.setState({ disabledBtn: true });
    };

    // сбор аналитики MNP
    collectionOfAnalyticsMnp() {
        try {
            ym(5462218, 'reachGoal', 'mnp_form_send');
            // ga('create', 'UA-120539241-1', 'auto');
            // ga('send', 'event', 'forms_sent', 'ga_mnp_form_send');
            VK.Goal('lead');
            var _tmr = window._tmr || (window._tmr = []); _tmr.push({ "type": "reachGoal", "id": 2767574, "goal": "mnp" });
            ttq.track('SubmitForm');


            let tariffParams = this.props.tariffParams;
            let tariff_id = false;

            if (!!tariffParams) {
                tariff_id = tariffParams.svoi && tariffParams.svoi.tariff_id
            } else {
                tariff_id = false
            }

            const data = !!tariffParams && (tariffParams.svoi.data === 'безлимит' || tariffParams.svoi.data === 'Безлимит' ? 0 : parseInt(tariffParams.svoi.data));
            const min = !!tariffParams && parseInt(tariffParams.svoi.min);
            const sms = !!tariffParams && (!!tariffParams.svoi.sms && tariffParams.svoi.sms !== '' ? parseInt(tariffParams.svoi.sms) : 0);
            const youtube_unlim = !!tariffParams && (!!tariffParams.svoi.youtube_unlim ? 1 : 0);
            const motiv_voice_unlim = !!tariffParams && (!!tariffParams.svoi.motiv_voice_unlim ? 1 : 0);
            const socialNetworkUnlim = !!tariffParams && (!!tariffParams.svoi.social_network_unlim ? 1 : 0);
            const ivi = !!tariffParams && (!!tariffParams.svoi.ivi ? 1 : 0);
            const data_unlim = !!tariffParams && (!!tariffParams.svoi.data_unlim ? 1 : 0);
            const router = !!tariffParams && (!!tariffParams.svoi.router ? 1 : 0);
            const abon = !!tariffParams && parseInt(tariffParams.svoi.abon);

            const paramsOldFormsTarifs = {
                name: `MNP Констр ${data}Гб ${min}минут ${+sms} ${youtube_unlim} ${motiv_voice_unlim} ${socialNetworkUnlim} ${ivi} ${data_unlim} ${router}`,
                price: abon,
                id: tariff_id,
            };

            // для GTM
            setDataLayer('oldFormsTarifs', paramsOldFormsTarifs);
        } catch (e) {
            console.log(e);
        }
    };

    sendForm(mnp) {
        let fields = Object.assign({}, this.state.fields);
        let constructor = this.context.constructorParams;
        let settings = this.props.settings;
        let data = new FormData();
        let urlStr = this.props.urlStr;
        let tariffParams = this.props.tariffParams;

        let paramSms = 0;
        if (tariffParams) {
            paramSms = tariffParams.svoi.sms ? tariffParams.svoi.sms : 0;
            this.collectionOfAnalyticsMnp();
        }

        fields.phone_number.value = fields.phone_number.value.replace(/\D/gim, '');
        let data_unlim = tariffParams && (!!tariffParams.svoi.data_unlim ? 1 : 0);

        let utm_start = getCookie("utm_start");

        const copy_start_params = getCookie("copy_start_params");
        let utm_finish = checkUtmParams();
        if (typeof utm_start === 'undefined' && !!copy_start_params) {
            utm_start = copy_start_params;
        }

        data.append('phone', fields.phone_number.value);
        if (mnp && mnp.operatorTitle) data.append('operator', mnp.operatorTitle);
        data.append('form_name', tariffParams && tariffParams.name);
        data.append('region', this.context.selectedRegion.name);
        data.append('constructor_info[id]', tariffParams && tariffParams.id);
        data.append('constructor_info[name]', tariffParams && tariffParams.name);
        data.append('constructor_info[min]', tariffParams && tariffParams.svoi.min);
        data.append('constructor_info[traffic]', tariffParams && (!tariffParams.svoi.data_unlim ? tariffParams.svoi.data : 0));
        data.append('constructor_info[sms]', paramSms);
        data.append('constructor_info[abon]', tariffParams && tariffParams.svoi.abon);
        if (this.props.flagValue !== 'mnp') {
            data.append('constructor_info[abon_discount]', tariffParams && tariffParams.svoi.abon_discount);
        }
        data.append('constructor_info[social_network_unlim]', tariffParams && (!!tariffParams.svoi.social_network_unlim ? 1 : 0));
        data.append('constructor_info[data_unlim]', data_unlim);
        data.append('constructor_info[motiv_voice_unlim]', tariffParams && (!!tariffParams.svoi.motiv_voice_unlim ? 1 : 0));
        data.append('constructor_info[youtube_unlim]', tariffParams && (!!tariffParams.svoi.youtube_unlim ? 1 : 0));
        data.append('constructor_info[ivi]', tariffParams && (!!tariffParams.svoi.ivi ? 1 : 0));
        data.append('constructor_info[home_region_checked]', tariffParams && (!!tariffParams.svoi.home_region_checked ? 1 : 0));
        if (data_unlim) {
            data.append('constructor_info[router]', tariffParams && (!!tariffParams.svoi.router ? 1 : 0));
        }

        if (!this.props.isGuest && !!utm_start) {
            data.append('utm_start', utm_start);
            data.append('utm_finish', utm_finish);
        }

        this.props.submitForm(data, 'callback_form');
    };

    openCallbackModal = (e) => {
        e.preventDefault();
        this.props.changeTab('callback');
    };

    setMnpCookie = (checkOperatorObj) => {

        let constructorParams = this.context.constructorParams;
        let settings = this.props.settings;
        let urlStr = this.props.urlStr;

        let data = JSON.stringify({
            id: this.props.settings.constructor_tariff_id,
            name: this.props.tariffName,
            min: constructorParams.min,
            traffic: constructorParams.gb,
            sms: constructorParams.sms ? settings.sms_pack_count : 0,
            abon: this.props.price.fee_with_sms,
            abonPromo: this.props.price.fee_with_sms_promo,
            social_network_unlim: constructorParams.social_unlim,
            checkOperatorObj: checkOperatorObj,
            phone: this.state.fields.phone_number.value,
            userTariffName: this.props.userTariffName
        });

        setCookie('mnpData', data, {
            path: '/',
            expires: 60,
            domain: cookieDomain
            //domain: 'localhost'
        });
    };

    render() {
        let { typeConnectionModal = false, tariffParams = false, isGuest } = this.props;
        const { disabledBtn } = this.state;
        let operator = this.props.activation.mnp;
        let idButton = 'st-mnp-button';

        if (tariffParams) {
            if (isGuest) idButton = setIdButton(this.context.selectedRegion.id, 'premade', (tariffParams.slug + '-' + 'st'), 'mnp', 'button');
        } else {
            if (isGuest) idButton = setIdButton(this.context.selectedRegion.id, 'selfmade', 'st', 'mnp', 'button');
        }

        let lightInfo = this.props.settings && this.props.settings.mnp_additional_description || "";

        let basicUrl = '';
        if (typeof window !== "undefined") {
            basicUrl = window.location.origin + '/' + this.context.selectedRegion.alias + '/mnp';
        }

        return <div className="mnp-connection">
            {this.props.settings.mnp_description ? <div className="text-lg">{this.props.settings.mnp_description}</div> : null}
            <form
                onKeyDown={(event) => {
                    // по клику на Enter предотвращаем любые всплытия
                    if (event.which === 13 || event.keyCode == 13 || event.key === "Enter") {
                        event.preventDefault();
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                    }
                }}
            >
                {typeConnectionModal === 'svoi' && operator.StatusCode !== 3003 || !typeConnectionModal && operator.StatusCode !== 3003 ? <div className="form-row">
                    {this.renderField("phone_number", "phone_focus")}
                    <div className="form-group" />
                </div> : null}
                {typeConnectionModal === 'svoi' && operator.StatusCode !== 3003 ? <div className="tariff_transfer-check">{this.renderField("tariff_transfer")}</div> : null}
                {typeConnectionModal !== 'svoi' ? <div className="light-info">{lightInfo}</div> : null}
                {operator.StatusCode === 3003
                    ? <div className="form-response">
                        <div className="title">Оператор не определен :(</div>
                        <div className="text">
                            Возможно, ваш номер зарегистрирован в регионе, где нет присутствия оператора Мотив,&nbsp;
                            <a href={basicUrl + '?anchor'} className={"link-accent"}>закажите звонок</a>&nbsp;
                            {/* <a onClick={this.openCallbackModal} href="#" className={"link-accent"}>закажите звонок</a>&nbsp; */}
                            у наших специалистов или попробуйте ввести другой номер</div>
                    </div>
                    : operator.hasOwnProperty('operatorCode') && operator.operatorCode === operator.ownOperatorCode
                        ? <div className="form-response">
                            <div className="title">Спасибо, что Вы с нами!</div>
                            <div className="text">Мы благодарим Вас за то, что вы пользуетесь услугами опрератора МОТИВ!</div>
                        </div>
                        : operator.regionId && operator.regionId !== this.context.selectedRegion.id
                            ? <div className="form-response">
                                <div className="title">Выбран не верный регион :(</div>
                                <div className="text"></div>
                            </div>
                            : ""
                }
                {typeConnectionModal === 'svoi' && operator.StatusCode !== 3003 || !typeConnectionModal && operator.StatusCode !== 3003 ? <div className="form-row form-tariff-connect form-tariff-connect__svoi">
                    {typeConnectionModal === 'svoi' ? <div className="light-info">{lightInfo}</div> : null}
                    <div />
                    <button
                        id={idButton}
                        onClick={this.checkOperator}
                        type="submit"
                        className={"st-mnp-button btn btn-accent btn-chevron-right" + (this.state.formValid && !disabledBtn ? "" : " disabled")}
                    >
                        Проверить номер
                    </button>
                </div> : null}
            </form>
            <a style={{ display: "none" }} id={"mnp-link"} href={"#"} />
        </div>
    }
}

export default connect(
    state => ({
        activation: state.activation,
        settings: state.constructor.settings,
        price: state.constructor.price,
        tariffConstructor: state.tariffConstructor,
        forms: state.forms,
        flagValue: state.common.flagValue
    }),
    (dispatch) => {
        return {
            checkOperator: (formData, params) => dispatch(checkOperator(formData, params)),
            clearOperatorData: () => dispatch(clearOperatorData()),
            submitForm: (formData, alias) => dispatch(submitMnpForm(formData, alias)),
        }
    }
)(MnpConnectionForm);