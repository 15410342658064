import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

class RatesArchiveCatList extends Component {

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore:  PropTypes.func
    };

    sortByAlphaBet(items) {
        items = items.sort(function(a, b){
            if (a.name[0] > b.name[0]) return 1;
            else if (a.name[0] < b.name[0]) return -1;
            else return 0;
        });
        return items;
    }

    render() {

        let {
            items,
            type
        } = this.props;

        let selectedRegion = this.context.selectedRegion;
        let fetchBefore = this.context.fetchBefore;

        items = this.sortByAlphaBet(items);

        return (
            <div className="b-services-archive__content-area">
                <div className="b-services-archive__content-group" >
                    <div className="b-services-archive__group-title" />
                    <div className="b-services-archive__group-list">
                        {items.map((item, key) =>
                            <React.Fragment key={key}>
                                {
                                    type === 'service'
                                        ?   <div className="b-services-archive__item">
                                                <a className="b-services-archive__link" onClick={fetchBefore("/" + selectedRegion.alias + "/services/archive/" + item.slug, item.slug, null)} >{item.name}</a>
                                            </div>

                                        : ""
                                }
                                {
                                    type === 'tariff'
                                        ?   <div className="b-services-archive__item">
                                                <Link className="b-services-archive__link" to={this.props.url + item.slug}>{item.name}</Link>
                                            </div>

                                        :   ""
                                }
                             </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesArchiveCatList;
