const initState = { isOk: false, list: [], selected: {} };

export function regions(state = initState, action) {
    switch (action.type) {
        case 'REGION_LIST':
            return { ...state, isOk: false };
        case 'REGION_LIST_SUCCESS':
            return { ...state, ...action.data, isOk: true };
        case 'REGION_LIST_B2B':
            return { ...state, isOk: false };
        case 'REGION_LIST_SUCCESS_B2B':
            return { ...state, ...action.data, isOk: true };
    }
    return state;
}