import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import QuickConnectionFormNew from "./QuickConnectionFormNew";
import MnpConnectionFormNew from "./MnpConnectionFormNew";
import CallbackConnectionForm from "./CallbackConnectionForm";

import { createBrowserHistory, createMemoryHistory } from "history";

class TariffConnectionNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // type: 'mnp',
            // type: 'quick',
            // type: this.tariffConnectionValue,
        };

    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    componentDidMount() {

    }

    componentWillMount() {

    }

    componentDidUpdate(prevState) {

    }

    renderForm = () => {
        let {
            tariffConnectionValue,
            tariffConstructor,
            checkBalance,
            isTariffConnection
        } = this.props;

        let recalc = tariffConstructor && tariffConstructor.recalcData
        let pdfFile = recalc.pdf_file

        switch (tariffConnectionValue) {
            case "quick":
                return <QuickConnectionFormNew
                    tariffName={this.props.tariffName}
                    userPhone={this.props.userPhone}
                    close={this.props.close}
                    openSuccess={this.props.openSuccess}
                    smsCount={this.props.smsCount}
                    constructor={this.props.constructor}
                    pdfFile={pdfFile}
                    checkBalance={checkBalance}
                    isTariffConnection={isTariffConnection}

                    // settings={this.props.settings}
                    // urlStr={this.props.urlStr}
                    // isGuest={this.props.isGuest}
                    // typeConnectionModal={this.props.typeConnectionModal}
                    // tariffParams={this.props.tariffParams}
                    // linkFile={this.props.linkFile}
                />;
            case "mnp":
                return <MnpConnectionFormNew 
                    tariffName={this.props.tariffName}
                    userPhone={this.props.userPhone}
                    close={this.props.close}
                    
                    // settings={this.props.settings}
                    // changeTab={this.onChange}
                    // constructor={this.props.constructor}
                    // urlStr={this.props.urlStr}
                    // typeConnectionModal={this.props.typeConnectionModal}
                    // tariffParams={this.props.tariffParams}
                    // isGuest={this.props.isGuest}
                    // smsCount={this.props.smsCount}
                />;
            // case "callback":
            //     return <CallbackConnectionForm userTariffName={this.props.userTariffName}
            //         tariffName={this.props.tariffName}
            //         close={this.props.close}
            //         openSuccess={this.props.openSuccess}
            //         settings={this.props.settings}
            //         constructor={this.props.constructor}
            //         tariffParams={this.props.tariffParams}
            //         urlStr={this.props.urlStr}
            //     />;

            default:
                return "";
        }
    };

    render() {

        return (
            <div className={"tariff-connection"}>
                {this.renderForm()}
            </div>
        )
    }
}

export default connect(
    state => ({
        tariffConnectionValue: state.common.tariffConnectionValue,
        tariffConstructor: state.tariffConstructor,
    }),
    (dispatch) => {
        return {

        }
    }
)(TariffConnectionNew);
