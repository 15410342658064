import React, { Component } from 'react';
import PropTypes from "prop-types";
import {mediaUrl} from "../../tools/config";
import InfoblockLink from "../InfoBlocks/InfoblockLink";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

class RatesThreeQuartersBanner extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    render() {

        let block = this.props.blocks[0];

        return (
            <div className="b-promo-invite__promo-block">
                <AdvertisingLabeling
                    token={block && block.token}
                    advertiser={block && block.advertiser}
                />
                <div className="b-promo-block b-promo-block_theme_invite b-promo-block_adapt_promo-invite">
                    <div className="b-promo-block__wrapper" style={{backgroundImage: "url(" + mediaUrl + block.imagePath + ")"}}>
                        <div className="b-promo-block__inner">
                            <div className="b-promo-block__title" dangerouslySetInnerHTML={{__html: block.title}} />
                            <p className="b-promo-block__text">{block.text}</p>
                        </div>
                        <div className="b-promo-block__link-block">
                            <InfoblockLink className={"b-promo-block__link"} item={block}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesThreeQuartersBanner;


