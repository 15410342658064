const initState = { isOk: false, list: [] };

export function coverImages(state = initState, action) {
    switch (action.type) {
        case 'COVER_IMAGES_LIST':
            return {...state, isOk: false};
        case 'COVER_IMAGES_LIST_SUCCESS':
            let list = (state.list && state.list.length > 0) ? state.list : [];
            let duplicates = state.duplicates || [];
            if (list.indexOf(item => item.region_id === action.data[0].region_id) == -1) {
                //check duplicates - hmao and yanao now have the same common coords
                duplicates = getDuplicatesIds(duplicates, list, action.data[0]);
                list = list.concat(action.data);
            }
            return { ...state, list: list, duplicates: duplicates, isOk: true };
    }
    return state;
}


const getDuplicatesIds = (oldDuplicates, oldList, newItem) => {
    const result = oldDuplicates;
    if (oldList.length > 0) {
        for (var i = 0; i < oldList.length; i++) {
            if (oldList[i].type === newItem.type && JSON.stringify(oldList[i].coords) === JSON.stringify(newItem.coords)) {
                if (result.indexOf(oldList[i].region_id) === -1) {
                    result.push(oldList[i].region_id);
                }
                if (result.indexOf(newItem.region_id) === -1) {
                    result.push(newItem.region_id);
                }
            }
        }
    }
    return result;
}