import React, {Component} from "react";
import Modal from "../common/Modal";
import MediaSlider from "../PressCenter/MediaSlider";
import MediaPlayer from "../PressCenter/MediaPlayer";

export class MediaModalPicture extends Component {

    render() {

        let { close, items, idAlbumPicture, idItem, sliderItem, modal } = this.props;

        return <Modal className="u-modal u-modal_active u-modal-sm modal-info media-modal" close={close}>
            <MediaSlider
                items={ items }
                idAlbumPicture={ idAlbumPicture }
                idItem={ idItem }
                sliderItem={ sliderItem }
                modal={ modal }
            />
        </Modal>
    }
}

export class MediaModalVideo extends Component {

    render() {

        let { close, items, idAlbumPicture, idItem, sliderItem } = this.props;

        return <Modal className="u-modal u-modal_active u-modal-sm modal-info media-modal" close={close}>
            <MediaPlayer
                items={ items }
            />
        </Modal>
    }
}
