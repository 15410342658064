import React, {Component} from 'react'

export default class Costs extends  Component {

    render(){

        let {
            services,
            onceDetailServiceId
        } = this.props;

        return <div className="page_lk-details_costs">
            {services.map((service) => <div className="page__action-block" key={service.id}>
                <div className="b-message-action b-message-action_rowed b-message-action_adapt_rowed">
                    <div className="b-message-action__wrapper">
                        <div className="b-message-action__inner">
                            <div className="b-message-action__title">{service.name}</div>
                            <div className="b-message-action__text" dangerouslySetInnerHTML={{__html: service.short_description}}/>
                        </div>
                        <div className="b-message-action__action-block">
                            <div className="b-message-action__btn">
                                <button onClick={this.props.showModal(service.id_billing == onceDetailServiceId ? 'order-details' : 'info-details', {service})} className="u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive">Заказать детализацию</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>

    }
}