import React, {Component} from "react";
import {connect} from "react-redux";
import {routesList, routesVacanciesList} from "../../actions/routes";
import {contactsList} from "../../actions/contacts";
import Page404 from "../Page404";
import {Route} from "react-router-dom";
import {Helmet} from "react-helmet";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import PropTypes from "prop-types";
import ContactsMedia from "../../components/PressCenter/ContactsMedia";
import Documents from "../../components/PressCenter/Document";
import {docsList} from "../../actions/docs";

class FormStyle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formStyleTitle: 'Фирменный&nbsp;стиль'
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    componentWillMount() {
        if (this.props.contactsList && this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("press");
        }
        this.props.getDocsList('form_style');
    }

    render() {
        const { regions, location } = this.props;
        // if (typeof document !== 'undefined') {
        //     this.context.setHeaderClassName("page__header-holder press-center-header");
        // }
        let selectedRegion = this.context.selectedRegion;
        let route = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "formStyleRoute");
        let contactsMedia = this.props.contactsList && this.props.contactsList.contacts;
        const {formStyleTitle} = this.state;
        const routePressCenter = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "pressCenterRoute");
        let linkBreadcrumbsPressCenter = routePressCenter && routePressCenter.link;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + linkBreadcrumbsPressCenter,
                title: "Пресс центр"
            },
            {
                url: "",
                title: "Фирменный стиль"
            }
        ];
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        if (!route) {
            return <Route component={Page404}/>
        }

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'formStyleRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder main-press-center" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container motiv-vacancies">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs}/>
                                </div>
                                <Documents title={this.state.formStyleTitle} documents={this.props.docs}/>
                            </div>
                        </div>
                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <AsideMenu
                                    flag={routePressCenter.sys_name}
                                    flagSubMenu={route.sys_name}
                                    route={this.props.routesVacancies}
                                    customTitleMenu={'Пресс центр'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        contactsList: state.contacts || [],
        docs: state.docs || [],
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getContactsList: (name) => dispatch(contactsList(name)),
            getDocsList: (name) => dispatch(docsList(name)),
        }
    }
)(FormStyle);

export const FormStyleRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getContactsList',
        args: ['press'],
        object: 'contactsList.contacts',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    }
];
