import React, {Component} from "react";

export default class TooltipInfo extends Component{

    constructor(props) {
        super(props);
        this.state = {
            state: false,
            edgeDistance: false,
        };
    }

    componentDidMount() {
        

    }

    onEnter = (e) => {
        this.setState({ state: true });

        const distance = e.target.getBoundingClientRect();

        if (distance.right < 205) {
            this.setState({ edgeDistance: true });
        } else {
            this.setState({ edgeDistance: false });
        }
        
    }

    onLeave = () => {
        this.setState({ state: false });
    }

    render(){
        let { state, edgeDistance } = this.state;
        let {
            text = '',
            typeColor = ''
        } = this.props;

        return (
            <div 
                className="tooltip_question tooltip_info"
                id="tooltip_question"
                onClick={(e) => {
                    e.preventDefault(); 
                    e.stopPropagation();
                    this.onEnter(e)
                }}
                onMouseLeave={() => {this.onLeave()}}
            >
                {/*<div*/}
                {/*    className={`tooltip_question__cercle ${typeColor === 'white' ? 'whitecol' : ''}${typeColor === 'black' ? 'blackcol' : ''}`}*/}
                {/*    onMouseEnter={(e) => this.onEnter(e)}*/}
                {/*>?</div>*/}
                <svg onMouseEnter={(e) => {this.onEnter(e)}} className="rates-tariffs-info-label" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="rates-tariffs-info-label--1" d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z" stroke="#FA6600" stroke-linecap="round" stroke-linejoin="round"/>
                    <path className="rates-tariffs-info-label--2" d="M9.5 9.5H10V13H10.5" stroke="#FA6600" stroke-linecap="round" stroke-linejoin="round"/>
                    <path className="rates-tariffs-info-label--3" d="M9.875 8C10.2892 8 10.625 7.66421 10.625 7.25C10.625 6.83579 10.2892 6.5 9.875 6.5C9.46079 6.5 9.125 6.83579 9.125 7.25C9.125 7.66421 9.46079 8 9.875 8Z" fill="#FA6600"/>
                </svg>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    className={`tooltip_question__content ${state ? 'active' : ''} ${edgeDistance ? 'distance' : ''}`}>
                    {text}
                </div>
            </div>
        )
    }
}