import React, {Component} from "react";
import Modal from "../../common/Modal";
import TariffConnection from "../../Constructor/TariffConnection";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {activateTariffConstructor, checkAvailableConstructor} from "../../../actions/activation";
import PdfLink from "../../Constructor/PdfLink";
import Icon from "../../common/Icon";

class TariffConnectionModal extends Component{

    constructor(props){
        super(props);

        this.state = {}
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
    };

    render(){

        let { settings, urlStr, typeConnectionModal = false, tariffParams = false, tariffConstructor, apiOptionNames = {} } = this.props;
        let price = 0;

        if (!!tariffParams) {
            price = (!!tariffParams.svoi.abon_discount && +tariffParams.svoi.abon_discount <= +tariffParams.svoi.abon) ? tariffParams.svoi.abon_discount : (!!tariffParams.svoi.abon ? tariffParams.svoi.abon : tariffParams.svoi.abon_discount);
        }

        if (!tariffParams) {
            price = (tariffConstructor && tariffConstructor.recalcData && !!tariffConstructor.recalcData.abon_discount) ? tariffConstructor.recalcData.abon_discount : tariffConstructor.recalcData.abon
        }

        let constructor = this.context.constructorParams;

        let tariffName = this.props.tariffName ? this.props.tariffName : "Свой тариф";

        let smsCount = this.props.smsCount ? this.props.smsCount : 0;

        let dataUnlim = settings && settings.info_title_data_unlim_hide ? parseInt(settings.info_title_data_unlim_hide, 10) : false;

        let router = tariffParams ? tariffParams.svoi.router : constructor.router;

        let abon = '', percent = '', discount_period = '', dscrOptionsConstructor = '', linkFile = '', subscriptionFeeInc = '';

        let optionsNames = {};
        settings.options_names.map((option) => {
            optionsNames[option.alias] = option.value;
        })

        const { IVI, MVOICE_UNLIM, SMS_PACK, SOCIAL_UNLIM, YOUTUBE_UNLIM, ROUTER } = apiOptionNames;

        if (!!tariffParams) {
            abon = tariffParams.svoi.abon;
            percent = tariffParams.svoi.percent_promo;
            discount_period = false;
            linkFile = tariffParams.description_url;
        } else {
            abon = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.abon;
            percent = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.discount;
            discount_period = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.discount_period;
            dscrOptionsConstructor = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.description_options;
            linkFile = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.pdf_file;
            subscriptionFeeInc = tariffConstructor && tariffConstructor.recalcData && tariffConstructor.recalcData.subscription_fee_inc;
        }

        return <Modal className="u-modal u-modal_active u-modal-top tariff-connection-modal tariff-svoi-modal" close={this.props.close}>
            <div className="tariff-title">
                <h2 className="modal-title">Подключение тарифа «{tariffName}»</h2>
                <div className="price">
                    <div>Абонентская плата:</div>

                    {this.props.flagValue !== 'mnp' 
                    ?   (<React.Fragment>
                            <div className="cost">
                                {price}
                                <span className="unit">
                                    <span>₽</span>
                                    /месяц
                                </span>
                            </div>
                            {!!percent && +percent > 0 
                            ?   <div className="price-promo">
                                    <span className="price-abon">{abon}</span>
                                    <span className="price-percent">-{percent}%</span>
                                    {!!discount_period ? <span className="price-discr">первые {discount_period} месяца</span> : null}
                                </div> 
                            : null}
                        </React.Fragment>)
                    :   <div className="cost">
                            {abon}
                            <span className="unit">
                                <span>₽</span>
                                /месяц
                            </span>
                        </div>
                    }

                </div>
            </div>
            {tariffParams.svoi && tariffParams.svoi.subscription_fee_inc ?
                <div className="tariff-name-info light-info" dangerouslySetInnerHTML={{__html: tariffParams.svoi.subscription_fee_inc}}/>
                : null}
            {subscriptionFeeInc ?
                <div className="tariff-name-info light-info" dangerouslySetInnerHTML={{__html: subscriptionFeeInc}}/>
                : null}
            <div className="tariff-traffic-info">
                {tariffParams ? <div>
                    {tariffParams.svoi.data === 0 || tariffParams.svoi.data_unlim ?
                        <Icon name={'icon_unlimModal'} className={'unlim-modal u-icon u-icon_logo'}/>
                    : tariffParams.svoi.data} Гб,
                    {' ' + tariffParams.svoi.min} Мин
                    {(tariffParams.svoi.data === 0 || tariffParams.svoi.data_unlim) && tariffParams.svoi.router ? `, ${optionsNames.router}` : '' }
                    {tariffParams.svoi.social_network_unlim ? `, ${optionsNames.social_network_unlim}` : ''}
                    {tariffParams.svoi.motiv_voice_unlim ? `, ${optionsNames.motiv_voice_unlim}` : ''}
                    {tariffParams.svoi.youtube_unlim ? `, ${optionsNames.youtube_unlim}` : ''}
                    {tariffParams.svoi.ivi ? `, ${optionsNames.ivi}` : ''}
                    {!!tariffParams.svoi.sms && tariffParams.svoi.sms !== '' ? `, ${tariffParams.svoi.sms} Смс` : ''}
                    {tariffParams.svoi.home_region_checked ? `, ${optionsNames.everywhere_at_home}` : ''}
                </div> :
                <div>
                    {constructor.gb === 0 || constructor.data_unlim ?
                        <Icon name={'icon_unlimModal'} className={'unlim-modal u-icon u-icon_logo'}/>
                    : constructor.gb} ГБ,
                    {' ' + constructor.min} мин
                    {(constructor.gb === 0 || constructor.data_unlim) && constructor.router && !!ROUTER ? `, ${ROUTER}` : '' }
                    {constructor.social_unlim && !!SOCIAL_UNLIM ? `, ${SOCIAL_UNLIM}` : '' }
                    {constructor.motiv_voice_unlim && !!MVOICE_UNLIM ? `, ${MVOICE_UNLIM}` : ''}
                    {constructor.youtube_unlim && !!YOUTUBE_UNLIM ? `, ${YOUTUBE_UNLIM}` : ''}
                    {constructor.ivi && !!IVI ? `, ${IVI}` : ''}
                    {constructor.sms && !!SMS_PACK ? `, ${SMS_PACK}` : ''}
                </div>}

                {this.props.flagValue !== 'mnp' &&
                    (<PdfLink className={"pdf-link"} tariffParams={tariffParams} constructor={constructor} linkFile={linkFile}>
                        Скачать полную информацию о тарифе
                    </PdfLink>)
                }
            </div>
            {this.props.data_unlim_info === 1 && dataUnlim === 0 && !router && !!settings.info_title_data_unlim ?
                <div className="unlim-dsrc-block">
                    <Icon name="icon_i-orange"/>
                    <span className="capt-unlim-dsrc">{settings.info_title_data_unlim}</span>
                </div>
                :
                null
            }
            {!!tariffParams && !!tariffParams.svoi.description_options || !tariffParams && !!dscrOptionsConstructor ?
                <div className="unlim-dsrc-block">
                    <Icon name="icon_i-orange"/>
                    <span className="capt-unlim-dsrc" dangerouslySetInnerHTML={{__html: !!tariffParams && !!tariffParams.svoi.description_options ? tariffParams.svoi.description_options : !tariffParams && !!dscrOptionsConstructor ? dscrOptionsConstructor : ''}}/>
                </div>
                :
                null
            }
            <TariffConnection
                userTariffName={this.props.tariffName}
                close={this.props.close}
                tariffName={tariffName}
                openSuccess={this.props.openSuccess}
                constructor={constructor}
                settings={this.props.settings}
                urlStr={urlStr}
                typeConnectionModal={typeConnectionModal}
                isGuest={this.props.isGuest}
                tariffParams={tariffParams}
                smsCount={smsCount}
                linkFile={linkFile}
            />
        </Modal>
    }
}

export default connect(
    state => ({
        activation: state.activation,
        price: state.constructor.price,
        url: state.constructor.url,
        settings: state.constructor.settings,
        isGuest: state.client.isGuest,
        tariffConstructor: state.tariffConstructor,
        flagValue: state.common.flagValue
    }),
    (dispatch) => {
        return {
            checkAvailableConstructor: (phone, regionId) => dispatch(checkAvailableConstructor(phone, regionId)),
            activateTariff: (tariff, regionId) => dispatch(activateTariffConstructor(tariff, regionId)),
        }
    }
)(TariffConnectionModal);