import React, {Component} from 'react'

import {connect} from "react-redux";
import {infoBlocksList} from "../../actions/infoBlocks";
import PropTypes from "prop-types";
import InfoBlock from "../../components/common/InfoBlock";
import {tariffsList} from "../../actions/tariffs";
import HomePageTariff from "../../components/Rates/HomePageTariff";
import RatesCarousel from "../../components/Rates/RatesCarousel";
import LinkC from "../../components/common/LinkC";
import Modal from "../../components/common/Modal";
import B2bTariffConnectionModal from "../../components/modals/B2bTariffConnectionModal";
import {servicesList} from "../../actions/services";
import {withTariffModal} from "./TariffConnection";

import {Helmet} from "react-helmet";

class Business extends Component {
    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        setHeaderClassName: PropTypes.func,
        appRoutes: PropTypes.array
    };

    componentWillMount() {
        let {infoBlocks, getInfoBlocks, popularTariffs, getTariffs, popularServices, getServices} = this.props;
        if (!infoBlocks.length) {
            getInfoBlocks(this.context.selectedRegion.id, 'home_page_b2b')
        } else {
            if (infoBlocks.find((item) => item.type.alias === 'full_screen_carousel' && item.regions.map((reg) => reg.id).indexOf(this.context.selectedRegion.id) !== -1)) {
                this.context.setHeaderClassName('page__header-holder_float page__header-holder_white', true)
            } else {
                this.context.setHeaderClassName('', true)
            }
        }

        if (!popularTariffs.length) {
            getTariffs(this.context.selectedRegion.id, null, 'rank_popular', 'b2b')
        }

        if (!popularServices.length) {
            getServices(this.context.selectedRegion.id, null, 'rank_popular', 'b2b')
        }
    }

    onClickConnection = (name, tariffId) => {
        this.props.onClickConnection(name, this.props.popularTariffs.find((item) => item.id === tariffId))
    }

    renderPopular = (type) => {
        const {popularTariffs, popularServices} = this.props;

        const items = (type === 'tariff' ? popularTariffs : popularServices);

        const allLinkRoute = this.context.appRoutes.find((item) => item.sys_name === (type === 'tariff' ? 'tariffsB2BRoute' : 'servicesB2BRoute'))

        return items.length ? <div className="page__container">
            <div className="page__tariff-plates">
                <div className="b-tariff-plates b-tariff-plates_adapt_default js-tarif-plates">
                    <div className="b-tariff-plates__wrapper">
                        <div className="b-tariff-plates__head">
                            <div className="b-tariff-plates__title">{type === 'tariff' ? 'Тарифы' : 'Услуги'} для бизнеса</div>
                        </div>
                        <div className={`b-tariff-plates__plates b-tariff-plates__plates__${type}`}>
                            <div className={"rates-carousel__wrap rates-carousel__wrap-fz"}>
                                <RatesCarousel
                                    type={`b2b${type === 'tariff' ? '' : '-service'}`}
                                    blocks={items}
                                    selectedRegion={this.context.selectedRegion}
                                    isGuest={true}
                                    onClickConnect={this.onClickConnection}
                                    customClass={"tariff-caurosel__wrap"}
                                    firstBlock={false}
                                />
                            </div>
                        </div>
                        {allLinkRoute ? <div className="b-tariff-plates__link-section">
                            <LinkC className="b-tariff-plates__link"
                                   to={allLinkRoute.route}>
                                Все {type === 'tariff' ? 'тарифы' : 'услуги'}
                            </LinkC>
                        </div> : null}
                    </div>
                </div>
            </div>
        </div> : null
    }

    render() {
        const {infoBlocks} = this.props;
        
        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.b2b.find(x => x.sys_name === 'mainB2BRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <main className="page__main-holder" role="main">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <InfoBlock type={'full_screen_carousel'} data={infoBlocks} section={'full_screen_carousel'} />
            {this.renderPopular('tariff')}
            <InfoBlock type={'become_client_b2b'} data={infoBlocks} section={'become_client_b2b'} />
            {this.renderPopular('service')}
            <div className="page__container">
                <div className="page__icon-links">
                    <div className="b-icon-links b-icon-links_adapt_default">
                        <div className="b-icon-links__wrapper">
                            <InfoBlock type="link_with_icon" data={infoBlocks} section="link_with_icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    }
}

export default connect(
    (state) => ({
        infoBlocks: state.infoBlocks.list.filter((item) => item.groups.map((group) => group.alias).indexOf('home_page_b2b') !== -1),
        popularTariffs: state.tariffs.tariffsLists.filter((a) => !!a.rank_popular).sort((a, b) => a.rank_popular < b.rank_popular ? -1 : 1),
        popularServices: state.services.servicesLists.filter((a) => !!a.rank_popular).sort((a, b) => a.rank_popular < b.rank_popular ? -1 : 1),

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getTariffs : (regionId, group, field, businessLine) => dispatch(tariffsList(regionId, group, field, businessLine)),
            getServices : (regionId, group, field, businessLine) => dispatch(servicesList(regionId, group, field, businessLine)),
        }
    }
)(withTariffModal(Business))

export const BusinessHomeRequests = [
    {
        func: 'getInfoBlocks',
        args: ['home_page_b2b'],
        object: 'infoBlocks.list',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: 'full_screen_carousel'},
            {type: 'become_client_b2b'},
        ]
    },
    {
        func: 'getTariffs',
        args: [null, 'rank_popular', 'b2b'],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getServices',
        args: [null, 'rank_popular', 'b2b'],
        object: 'services.servicesLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
];