import React, {Component} from "react";
import Slider from "rc-slider";
import {getWord} from "../../tools/tools";
import {mediaUrl} from "../../tools/config";

export default class RatesFilter extends Component{

    constructor(props){
        super(props);

        var values = {};

        props.sliders_settings.map((setting) => {values['x'+setting.id] = setting.default_value;});

        var formula = props.formula;

        for (var key in values) {
            formula = formula.replace(new RegExp(key), values[key]);
        }

        this.state = {
            result: Math.ceil(eval(formula)/(1024*1024)),
            values
        }
    }

    setVal = (id, val) => {
        var values = {...this.state.values};
        values['x'+id] = val;

        var formula = this.props.formula;

        for (var key in values) {
            formula = formula.replace(new RegExp(key), values[key]);
        }

        this.setState({
            result: Math.ceil(eval(formula)/(1024*1024)),
            values
        }, () => {
            this.props.onTrafficChange && this.props.onTrafficChange(this.state.result);
        })
    };

    onTariffClick = () => {
        this.props.onTrafficChange(this.state.result);
        this.props.closeModal();
    };

    render(){

        let {
            title,
            subtitle,
            response_title,
            sliders_settings,
            description,
            right_bottom_sign,
        } = this.props;

        return <div className="rates-filter">
            <div className="rates-filters">
                <h3>{title}</h3>
                <div>{subtitle}</div>
                <div className="rates-filters__sliders">
                    {sliders_settings.map((setting) =>
                        <RateSlider key={setting.id}
                                    id={setting.id}
                                    icon={setting.icon}
                                    title={setting.legend}
                                    from={0}
                                    to={setting.max_value}
                                    step={setting.scale_step}
                                    value={setting.default_value}
                                    outside={setting.outside_scale_value}
                                    names={setting.unit.split(',')}
                                    setVal={this.setVal}
                        />
                    )}
                </div>
                <div className="swiper-notification text_light">
                    {description}
                </div>
            </div>
            <div className="rates-info">
                <div>
                    <div className="rates-info__text">
                        {response_title}
                    </div>
                    <div className="rates-info__value">
                        <span>{this.state.result}</span>гб
                    </div>
                </div>
                <div>
                    <button className="u-btn u-btn_default u-btn_adapt_mobile-sm btn--white" onClick={this.onTariffClick}>Подходящие тарифы (4)</button>
                    <div className="swiper-notification">
                        {right_bottom_sign}
                    </div>
                </div>
            </div>
        </div>
    }
}

class RateSlider extends Component{

    constructor(props){
        super(props);

        this.isMobileWidth = 767;

        this.state = {
            value: props.value || 10,
            isMobileWidth: (window.innerWidth <= this.isMobileWidth)
        }

        this.marks = {};
        this.markStep = Math.round((props.to - props.from)/5);
        for (var i = 0; i <= props.to; i += this.markStep) {
            this.marks[i] = i;
        }

        if (props.outside) {
            this.marks[props.to + this.markStep] = this.state.isMobileWidth ? 'ထ' : 'много';
        }
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        if (this.state.isMobileWidth != (window.innerWidth <= this.isMobileWidth)) {
            this.marks[this.props.to + this.markStep] = window.innerWidth > this.isMobileWidth ? 'много' : 'ထ';
            this.setState({isMobileWidth: (window.innerWidth <= this.isMobileWidth)});
        }
    }

    onChange = (val) => {
        this.setState({value: val});
        this.props.setVal(this.props.id, val > this.props.to ? this.props.outside : val);
    }

    render(){
        let {
            icon,
            title,
            from,
            to,
            outside,
            names
        } = this.props;

        return <div className="rates-filter__slider">
            {icon ? <img className={"img-icon"} src={mediaUrl + icon}/> : <span className="img-icon" />}
            <div className="rates-filters__slider__title">{title}</div>
            <Slider vertical={!this.state.isMobileWidth} min={from} max={to + this.markStep} marks={this.state.isMobileWidth ? {} : this.marks}
                    step={this.markStep} value={this.state.value} onChange={this.onChange} />
            <div className="rates-filter__slider__value">
                <span>{this.marks[this.state.value] || this.state.value}</span>&nbsp;
                {this.state.value > to ? names[2] : getWord(this.state.value, names[0], names[1], names[2])}
            </div>
        </div>
    }
}