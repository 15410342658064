import React, {Component} from 'react'
import InputError from "../common/InputError"
import DateInput from "../common/Date"
import Icon from "../common/Icon";
import {emailValidator} from '../../tools/form-helper'
import RatesPriceList from "../Rates/RatesPriceList";

export default class OrderDetailsForm extends Component {

    constructor(props){
        super(props);

        let today = new Date();

        this.state = {
            values: {
                email: "",
                date_from: "",
                date_to: ""
            },
            errors: {
                email: "",
                date_from: "",
                date_to: ""
            },
            info: [{
                items: [
                    {
                        title: "Стоимость подключения",
                        value: 0,
                        measure: "₽"
                    },
                    {
                        title: "Дата запроса",
                        value: today ? today.toLocaleDateString('ru', {day: "numeric", month: "long", year: "numeric"}) : null
                    }
                ]
            }],
            notice: props.notice ? this.getNotice(props.notice, props.dayPrice, 0, 0) : null
        }
    }

    onChange = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState({
            values: {
                ...this.state.values,
                [target.name]: target.value
            }
        })
    };

    onDateChange = (name) => {
        return (value) => {

            this.setState((prevState) => {
                return {
                    ...prevState,
                    values: {
                        ...prevState.values,
                        [name]: value ? value.split('.').reverse().join('-') : ""
                    }
                }
            }, () => {
                this.setPrice();
            })
        }
    };

    onBlur = (e) => {
        var target = e.target || e.nativeEvent.target;
        let err = ""
        if (!target.value) {
            err = "Обязательно для заполнения"
        } else if (target.name === 'email') {
            if (!emailValidator(target.value)) {
                err = "Некорректный e-mail"
            }
        } else if (target.value.indexOf('_') !== -1) {
            err = "Некорректная дата"
        } else {
            if ((new Date(target.value.split('.').reverse().join('-'))).getTime() > (new Date()).getTime()) {
                err = "Будущая дата недопустима"
            }

            let threeMonths = new Date();
            threeMonths.setMonth(threeMonths.getMonth() - 3);
            threeMonths.setHours(0, 0, 0, 0);

            if ((new Date(target.value.split('.').reverse().join('-'))).getTime() < threeMonths.getTime()) {
                err = "Дата ранее 3 мес назад недопустима"
            }
        }

        this.setState({
            errors: {
                ...this.state.errors,
                [target.name]: err
            }
        })
    };

    onFocus = (e) => {

        var target = e.target || e.nativeEvent.target;

        this.setState({
            errors: {
                ...this.state.errors,
                [target.name]: ""
            }
        })
    };

    onSubmit = (e) => {

        e.preventDefault();

        let {
            values
        } = this.state;

        let errors = {...this.state.errors};

        if (!values.email) {
            errors.email = "Обязательно для заполнения";
        } else {
            if (!emailValidator(values.email)) {
                errors.email = "Некорректный e-mail";
            }
        }

        if (!errors.date_from) {
            if (!values.date_from) {
                errors.date_from = "Обязательно для заполнения";
            } else if (values.date_from.indexOf('_') !== -1) {
                errors.date_from = "Некорректная дата";
            }
        }

        if (!errors.date_to) {
            if (!values.date_to) {
                errors.date_to = "Обязательно для заполнения";
            } else if (values.date_to.indexOf('_') !== -1) {
                errors.date_to = "Некорректная дата";
            }
        }

        if (!errors.date_from && !errors.date_to && values.date_to < values.date_from) {
            errors.date_to = "Должна быть больше даты начала";
        }

        if (errors.date_to || errors.date_from || errors.email) {
            this.setState({errors});
        } else {
            this.setState({errors: {}});
            this.props.onSubmit(
                JSON.stringify({
                    emails: [values.email],
                    date_from: values.date_from+"T00:00:00Z",
                    date_to: values.date_to+"T23:59:59Z"
                })
            )
        }
    };

    inputProps = (name) => {

        let {
            values
        } = this.state;

        return {
            name: name,
            value: values[name],
            onChange: name === 'email' ? this.onChange : this.onDateChange(name),
            [name === 'email' ? 'onBlur' : 'onblur']: this.onBlur,
            [name === 'email' ? 'onFocus' : 'onfocus']: this.onFocus
        }
    };

    setPrice = () => {
        let {
            date_from,
            date_to
        } = this.state.values;

        let info = [...this.state.info], notice = this.props.notice || "";

        if (date_from && date_to) {

            let from = new Date(date_from);
            let to = new Date(date_to);
            let days = (to.getTime() - from.getTime())/(24*3600000);

            info[0].items[0].value = this.props.dayPrice*days;
            notice = this.getNotice(notice, this.props.dayPrice, days, this.props.dayPrice*days);
        } else {
            info[0].items[0].value = 0;
            notice = this.getNotice(notice, this.props.dayPrice, 0, 0);
        }

        this.setState({info, notice})
    };

    getNotice = (notice, dayCost, daysCount, totalCost) => {
        return notice.replace("{{dayCost}}", dayCost).replace("{{daysCount}}", daysCount).replace("{{totalCost}}", totalCost);
    };

    render(){

        let {
            title,
            goBack = () => {}
        } = this.props;

        let threeMonths = new Date();
        threeMonths.setMonth(threeMonths.getMonth() - 3);
        threeMonths.setHours(0, 0, 0, 0);

        let {errors, info, notice} = this.state;

        return <div className="b-lk-block b-lk-block_adapt-default order-details-form">
            <div className="b-lk-block__title">Запрос на предоставление услуги «{title}»</div>
            {notice ? <div className="b-lk-block__sub-title">{notice}</div> : null}
            <div className="b-lk-block__form">
                <form onSubmit={this.onSubmit}>
                    <div className="form-group form-email">
                        <label className="form-group-label">E-mail</label>
                        <input className={"b-input-text__input"+(errors.email ? " has-error" : "")}
                               {...this.inputProps('email')}
                        />
                        <InputError hasError={!!errors.email} errorText={errors.email}/>
                    </div>
                    <div className="form-group period">
                        <div className={"period-first" + (errors.date_from ? " date-has-error" : "")}>
                            <label className="form-group-label">Дата начала детализации</label>
                            <DateInput {...this.inputProps('date_from')} minDate={threeMonths}/>
                            <InputError hasError={!!errors.date_from} errorText={errors.date_from}/>
                        </div>
                        <Icon name="icon_arrow-right" className="period-icon"/>
                        <div className={"period-second" + (errors.date_to ? " date-has-error" : "")}>
                            <label className="form-group-label">Дата конца детализации</label>
                            <DateInput {...this.inputProps('date_to')} minDate={threeMonths}/>
                            <InputError hasError={!!errors.date_to} errorText={errors.date_to}/>
                        </div>
                    </div>
                    {info ? <RatesPriceList blocks={info} /> : null}
                    <div className="b-service-inner__price-list-block buttons-group text-right">
                        <button className="u-btn u-btn_transparent-grey u-btn_adapt_default u-btn_mobile-responsive" type="reset" onClick={goBack}>Отмена</button>
                        <button className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive" type="submit">Заказать</button>
                    </div>
                </form>
            </div>
        </div>

    }
}