const initState = {
    isOk: false,
    roamingFirstRequest: false,
    callingFirstRequest: false,
    roamingSearchList: [],
    callingSearchList: [],
    roamingSearchPlacesList: [],
    callingSearchPlacesList: [],
    roamingMemos: []
};

export function roaming(state = initState, action) {
    switch (action.type) {
        case 'ROAMING_SEARCH':
            return {...state, isOk: false};
        case 'ROAMING_SEARCH_SUCCESS':
            return {...state, roamingSearchList: action.data, isOk: true, roamingFirstRequest: true};
        case 'ROAMING_SEARCH_CLEAR':
            return {...state, roamingSearchList: [], placeFromUrl: ''};
        case 'CALLING_SEARCH':
            return {...state, isOk: false};
        case 'CALLING_SEARCH_SUCCESS':
            return {...state, callingSearchList: action.data, isOk: true, callingFirstRequest: true};
        case 'ROAMING_SEARCH_PLACES':
            return {...state, isOk: false};
        case 'ROAMING_SEARCH_PLACES_SUCCESS':
            return {...state, roamingSearchPlacesList: action.data, isOk: true};
        case 'CALLING_SEARCH_PLACES':
            return {...state, isOk: false};
        case 'CALLING_SEARCH_PLACES_SUCCESS':
            return {...state, callingSearchPlacesList: action.data, isOk: true};
        case 'ROAMING_PLACE_SUCCESS':
            return action.data ? {...state, placeFromUrl: action.data} : state;
        case 'ROAMING_MEMOS_SUCCESS':
            return {...state, roamingMemos: action.data};
    }
    return state;
}