import React, {Component} from "react";
import Icon from "../common/Icon";
import {mediaUrl} from "../../tools/config";

export default function VacancySocial({ dataVacancySocial }){

    return ( <div className="vacancy-social-block">
            {dataVacancySocial && dataVacancySocial.map((el, key) =>
                <React.Fragment>
                    {el.link && el.text ? <a
                        key={ key }
                        href={ el.link }
                        target="_blank"
                        className={'ts-social-button-share vacancy-social-block--item'}
                        style={{backgroundImage: 'url('+mediaUrl + el.imagePath+')', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                            <div className="social-block--item-txt">{ el.text }</div>
                    </a> : null}
                </React.Fragment>
            )}
        </div>

    )
}