import React, { Component } from 'react';
import PropTypes from "prop-types";

import InfoBlock from "../../components/common/InfoBlock";

class ListOfServices extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        let {
            infoBlocks
        } = this.props

        return (
            <div className="list-of-services__container">
                <div className="list-of-services__content">
                    {/* type = нужный case section = api type.alias */}
                    <InfoBlock type={'list_of_services_main_img'} data={ infoBlocks } section={'home_page_payments'} />

                    <InfoBlock type={'list_of_services_main'} data={ infoBlocks } section={'home_page_payments'} />

                    <InfoBlock type={'list_of_services_group'} data={ infoBlocks } section={'link_with_icon'} />
                </div>
            </div>
        )
    }
}

export default ListOfServices;