const initState = { isOk:false, docs: []};

export function docs(state = initState, action) {

    switch (action.type) {
        case 'DOCS_LIST':
            return {...state, isOk: false};
        case 'DOCS_LIST_SUCCESS':
            return {...state, docs: action.data, isOk: true};
    }
    return state;
}