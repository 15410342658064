import React, {Component} from "react";
import SwitcherInput from "../common/SwitcherInput";

export default class RecommendedServices extends Component {

    constructor(props){
        super(props);

        this.state = {
            collapsed: props.collapsable ? true : false
        }
    }

    toggleServices = () => {
        if (this.props.collapsable) {
            this.setState((prevState) => {
                return {
                    collapsed: !prevState.collapsed
                }
            })
        }
    }

    render(){
        let {collapsed} = this.state;

        return <div className="recommended-services">
            {collapsed ? <div className="recommended-services__header">
                 <h3>Мы рекомендуем вам  <span className="text_accent">2 услуги</span></h3>
                    {this.props.collapsable ? <div className="flex-between" onClick={this.toggleServices}><nobr>Показать рекомендации</nobr><span className="icon-chevron down"/></div> : null}
                </div> :
                <div className="recommended-services__header">
                    <h3>Заканчиваются гигабайты интернета? Воспользуйтесь <span className="text_accent">услугами</span></h3>
                    {this.props.collapsable ? <div className="flex-between" onClick={this.toggleServices}><nobr>Свернуть рекомендации</nobr><span className="icon-chevron up"/></div> : null}
                </div>}
            {collapsed ? "" : <div className="recommended-services__list">
                {this.props.services.map((item, key) => <ServiceItem key={key} item={item}/>)}
            </div>}
        </div>
    }
}

class ServiceItem extends Component{
    render(){
        let item = this.props.item;
        return <div className="service-item">
            <SwitcherInput id={item.id}/>
            <div className="service-item__info">
                <div className="text_lg">{item.title}</div>
                <div className="text_light">{item.description}</div>
            </div>
            <div className="service-item__cost">
                <div className="rate-item__price">
                    <div className="rate-item__price-num">{item.price}</div>
                    <div className="rate-item__measure">
                        <div className="rate-item__measure-cur">₽</div>
                        <div className="rate-item__period"> /подключение</div>
                    </div>
                </div>
                <a className="rate-item__actions-link" href="#">Подробнее об услуге</a>
            </div>
        </div>
    }
}