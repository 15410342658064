import React, {Component} from 'react'
import Icon from "../common/Icon"
import {passwordValidator} from '../../tools/form-helper'
import InputError from "../common/InputError";

export default class ChangePasswordForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            values: {
                old_password: "",
                new_password: ""
            },
            errors: {
                old_password: "",
                new_password: ""
            }
        }
    }

    static getDerivedStateFromProps(props, state){
        if (JSON.stringify(props.errors) != JSON.stringify(state.propsErrors)  && JSON.stringify(state.errors) != JSON.stringify(props.errors)) {
            return {...state, errors: props.errors, propsErrors: props.errors}
        }
        if (props.clear) {
            return {
                values: {
                    old_password: "",
                    new_password: ""
                },
                errors: {
                    old_password: "",
                    new_password: ""
                }
            }
        }
        return state;
    }

    onChange = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                values: {
                    ...prevState.values,
                    [target.name]: target.value
                }
            }
        })
    };

    onBlur = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [target.name]: this.checkError(target.value, true)
                    // [target.name]: this.checkError(target.value, true, passwordValidator)
                }
            }
        })
    };

    onFocus = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState((prevState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [target.name]: ""
                }
            }
        })
    };

    checkError = (value, required, validator) => {

        let {
            auth_form_password_min_length,
            auth_form_password_requirements
        } = this.props.formSettings;

        if (!value && required) {
            return "Обязательно для заполнения";
        }
        if (!validator || validator(value, auth_form_password_min_length, auth_form_password_requirements)) {
            return "";
        }
        return "Не соответствует требованиям пароля"
    };

    onSubmit = (e) => {

        e.preventDefault();

        let {
            values
        } = this.state;

        this.setState({
            errors: {
                old_password: this.checkError(values.old_password, true),
                new_password: this.checkError(values.new_password, true)
                // new_password: this.checkError(values.new_password, true, passwordValidator)
            }
        }, () => {
            let {
                errors
            } = this.state;

            if (values.old_password && values.new_password && !errors.old_password && !errors.new_password) {
                // this.props.onSubmit(JSON.stringify(this.state.values))
                this.props.onSubmit(values)
            }
        });
    };

    inputProps = (name) => {

        let {
            values,
            errors
        } = this.state;

        return {
            name,
            value: values[name],
            error: errors[name],
            onChange: this.onChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus
        }
    };

    render(){

        let {
            title,
            notice
        } = this.props;

        return <form className="page__simple-form" onSubmit={this.onSubmit}>
            <div className="b-form-simple b-form-simple_adapt_default">
                <div className="b-form-simple__wrapper">
                    <div className="b-form-simple__header">
                        <div className="b-form-simple__title">{title}</div>
                        <div className="b-form-simple__text">{notice}</div>
                    </div>
                    <div className="b-form-simple__inputs-list">
                        <PasswordField label="Текущий пароль:"
                                        {...this.inputProps("old_password")}
                        />
                        <PasswordField label="Новый пароль:"
                                       {...this.inputProps("new_password")}
                        />
                    </div>
                    <div className="b-form-simple__btns-area">
                        <div className="b-form-simple__btn-item b-form-simple__btn-item_offset-2">
                            <button type="submit" className="b-form-simple__btn u-btn u-btn u-btn_default u-btn u-btn_responsive u-btn u-btn_adapt_mobile-sm">Сменить пароль</button>
                            <a
                              href={window.location.hostname.includes('buroburo') || window.location.hostname.includes('localhost') ? 'https://st-login.motivtelecom.ru/blitz/recovery' : 'https://login.motivtelecom.ru/blitz/recovery'}
                              className='change-password-forget'
                            >
                                Забыли старый пароль?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    }
}

class PasswordField extends Component {

    constructor(props){
        super(props);

        this.state = {
            shown: false
        }
    }

    toggleShown = () => {
        this.setState((prevState) => {
            return {shown: !prevState.shown}
        })
    };

    render(){

        let {
            value,
            label,
            name,
            error,
            onChange,
            onBlur,
            onFocus
        } = this.props;

        let {
            shown
        } = this.state;

        return <div className="b-form-simple__row">
            <div className="b-form-simple__row-item b-form-simple__row-item_label">
                <div className="b-form-simple__label-text">{label}</div>
            </div>
            <div className="b-form-simple__row-item b-form-simple__row-item_input">
                <div
                    className="b-input-text b-input-text b-input-text_type_password b-input-text_adapt_mobile-small">
                    <div className="b-input-text__wrapper">
                        <div className={"b-input-text__input-area"}>
                            <span onClick={this.toggleShown}>
                                <Icon name={shown ? "icon_eye_orange" : "icon_eye"} className={"b-input-text__password-icon u-icon u-icon_eye"}/>
                            </span>
                            {/*<Icon name="icon_done" className="b-input-text__approved-icon u-icon u-icon_done"/>*/}
                            <input className={"b-input-text__input"+(error ? " has-error" : "")}
                                   type={shown ? "text" : "password"}
                                   value={value}
                                   name={name}
                                   onChange={onChange}
                                   onBlur={onBlur}
                                   onFocus={onFocus}
                            />
                            <InputError hasError={!!error} errorText={error}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}