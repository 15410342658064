import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

class PromotionTooltip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltipOn: false,
        };

        this.sortRef = React.createRef();
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        pushHistory: PropTypes.func,
        setHasItemModal: PropTypes.func
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    // убираем тултип по клику вне него "пицеман"
    handleClickOutside = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        // includes --- ищет указанный элемет в массиве, возвращает true или false
        if (!path.includes(this.sortRef.current)) {
            this.setState({
                tooltipOn: false,
            });
        }
    };

    isOnTooltip = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            tooltipOn: !this.state.tooltipOn,
        });
    }

    render() {
        let {
            tooltipOn,
        } = this.state;

        let {
            data,
        } = this.props;

        return (
            <div
                className={`prices_tooltip_wrapper ${tooltipOn ? "prices_tooltip_wrapper_active" : ''} `}
                onClick={this.isOnTooltip}
                ref={this.sortRef}
            >
                {data.name}                                                
                <div
                    className={`text ${tooltipOn ? "text_active" : ''}`}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                />
            </div>
        )
    }
}

export default connect(
    state => ({

    }),
    (dispatch) => {
        return {

        }
    }
)(PromotionTooltip);
