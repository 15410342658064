import React, { Component } from 'react';
import Checkbox from "../Constructor/Checkbox";

class TariffCalculatorCheckboxes extends Component {

    render() {

         let {
            nameData,
            valueData,
            labelData,
            onChangeData,
            nameRouter,
            valueRouter,
            labelRouter,
            onChangeRouter,
            data_unlim_hint,
            router_hint,
            nameSocial,
            valueSocial,
            labelSocial,
            onChangeSocial,
            social_unlim_hint,
            nameVoice,
            valueVoice,
            labelVoice,
            onChangeVoice,
            voice_unlim_hint,
            nameSms,
            valueSms,
            labelSms,
            onChangeSms,
            checkboxClick,
            nameYoutube,
            valueYoutube,
            labelYoutube,
            onChangeYoutube,
            youtube_unlim_hint,
            nameIvi,
            valueIvi,
            labelIvi,
            onChangeIvi,
            ivi_hint,
            chOn,
            disabledRqs,
            sms_hint
        } = this.props;

        return (
            <div className="constructor__additional-services">
                <div>
                    {!!chOn.dataChOn && labelData ? <Checkbox
                        name={nameData}
                        value={valueData}
                        label={labelData}
                        onChange={onChangeData}
                        checkboxClick={checkboxClick}
                        typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        >
                            {data_unlim_hint && !valueRouter ?
                                <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                    <div className="tooltip_text">{data_unlim_hint}</div>
                                </div> :
                            null}
                    </Checkbox> : null}
                    {valueData && !!chOn.routerChOn && labelRouter ? <Checkbox
                        name={nameRouter}
                        value={valueRouter}
                        label={labelRouter}
                        onChange={onChangeRouter}
                        typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                    >
                        {router_hint ?
                            <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                <div className="tooltip_text">{router_hint}</div>
                            </div> :
                            null}
                    </Checkbox> : null}
                    {!!chOn.socialChOn && labelSocial ? <Checkbox
                        name={nameSocial}
                        value={valueSocial}
                        label={labelSocial}
                        onChange={onChangeSocial}
                        typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                    >
                        {social_unlim_hint ?
                            <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                <div className="tooltip_text">{social_unlim_hint}</div>
                            </div> :
                        null}
                    </Checkbox> : null}
                    {!!chOn.ytbChOn && labelYoutube ? <Checkbox
                        name={nameYoutube}
                        value={valueYoutube}
                        label={labelYoutube}
                        onChange={onChangeYoutube}
                        typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        >
                        {youtube_unlim_hint ?
                            <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                <div className="tooltip_text">{youtube_unlim_hint}</div>
                            </div> :
                            null}
                    </Checkbox> : null}
                </div>
                <div>
                    {!!chOn.iviChOn && labelIvi ? <Checkbox
                        name={nameIvi}
                        value={valueIvi}
                        label={labelIvi}
                        onChange={onChangeIvi}
                        typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        >
                        {ivi_hint ?
                            <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                <div className="tooltip_text">{ivi_hint}</div>
                            </div> :
                        null}
                    </Checkbox> : null}
                    {!!chOn.voiceChOn && labelVoice ? <Checkbox
                        name={nameVoice}
                        value={valueVoice}
                        label={labelVoice}
                        onChange={onChangeVoice}
                        typeBlockingGet={true}
                        disabledRqs={disabledRqs}
                        >
                        {voice_unlim_hint ?
                            <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                <div className="tooltip_text">{voice_unlim_hint}</div>
                            </div> :
                            null}
                    </Checkbox> : null}
                    {!!chOn.smsChOn && labelSms 
                        ?   <Checkbox
                                name={nameSms}
                                value={valueSms}
                                label={labelSms}
                                onChange={onChangeSms}
                                typeBlockingGet={true}
                                disabledRqs={disabledRqs}
                            >
                                {sms_hint 
                                    ?   <div className="small-tooltip" onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                            <div className="tooltip_text centered">{sms_hint}</div>
                                        </div> 
                                    :   null
                                }
                            </Checkbox>
                        :   null
                    }
                </div>
            </div>
        )
    }
}

export default TariffCalculatorCheckboxes;