import React, { Component } from 'react';
import PropTypes from "prop-types"
import RateServiceListItem from "../Rates/RateServiceListItem";
import Icon from "../common/Icon"

class InfoBlockAdvertService extends Component {

    constructor(props){
        super(props);
        this.state = {
            collapsed: props.canCollapse,
            hasItemModal: null
        }
    }

    static childContextTypes = {
        setHasItemModal: PropTypes.func
    };

    getChildContext(){
        return {
            setHasItemModal: (val) => {
                this.setState({hasItemModal: val})
            }
        }
    }

    toggleCollapse = () => {
        this.setState((prevState) => {
            return {
                collapsed: !prevState.collapsed
            }
        })
    };

    render() {

        let block = this.props.blocks[0];
        let services = block.services.filter((service) => (this.state.hasItemModal == service.id || !this.props.myServices || this.props.myServices.indexOf(service.id_billing) == -1) &&
            service.region_id == this.props.myRegion && (service.id_billing != this.props.exchangeId || this.props.canExchange));

        if (!services.length) return null;

        return (
            <div className="b-lk-number__servises">
                <div className="b-lk-servises b-lk-servises_adapt-default">
                    <div className="flex-between b-lk-servises__title-block">
                        <div className="b-lk-servises__title" dangerouslySetInnerHTML={{__html: block.title}} />
                        {this.props.canCollapse ? <div className="flex-between" onClick={this.toggleCollapse}>
                            <nobr>{this.state.collapsed ? "Показать" : "Скрыть"} рекомендации</nobr>
                            <span className={"icon-chevron "+(this.state.collapsed ? "down" : "up")}/>
                        </div> : null}
                    </div>
                    {this.state.collapsed ? null : <div className="b-lk-servises__list">
                        {services.map((service) => service.id_billing == this.props.exchangeId ? <div className="b-lk-servises__item">
                                <div className="b-options-table b-options-table_adapt-lk">
                                    <div className="b-options-table__item b-options-table__item_gray b-options-table__item_center">
                                        <div className="b-options-table__item-elem b-options-table__item-elem_switcher b-options-table__item-elem_gray">
                                            <Icon name="icon_spir" className="u-icon u-icon_spir"/>
                                        </div>
                                        <div className="b-options-table__item-elem b-options-table__item-elem_text b-options-table__item-elem_gray">
                                            <div className="b-options-table__text-title b-options-table__text-title_gray">
                                                {service.name}
                                            </div>
                                        </div>
                                        <div className="b-options-table__item-elem b-options-table__item-elem_btn b-options-table__item-elem_gray">
                                            <div className="b-options-table__btn" onClick={this.props.showModal('exchange')}>Менять</div>
                                        </div>
                                    </div>
                                </div>
                            </div> : <RateServiceListItem key={service.id} item={{...service, isOn: this.props.myServices && this.props.myServices.indexOf(service.id_billing) !== -1}} isAdvert={true}/>)}
                    </div>}
                </div>
            </div>
        )
    }
}

export default InfoBlockAdvertService;