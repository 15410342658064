import React, {Component} from "react";
import {RenderField, onChange} from "./PopupHelpers";
import Collapse from "../common/Collapse";
import RatesPhoneCommand from './RatesPhoneCommand';
import QuickConnection from "../modals/QuickConnection";
import {PersonalCabinet} from "../modals/PersonalCabinet";
import {connect} from "react-redux";
import {activateItem, checkAvailable, clearActivationData} from "../../actions/activation";
import {settingsList} from "../../actions/settings";
import {auth, callLogin} from "../../actions/client";
import AuthForm from "../Forms/AuthForm";
import PropTypes from "prop-types";
import IconSigninEnter from "../../../images/icons/icon-signin-enter.svg";

class ChangeRate extends Component {

    constructor(props, context) {
        super(props, context);

        let {
            type,
            item
        } = props;

        let fields = {
            changingWay: {
                name: 'changingWay',
                type: "radio",
                value: "",
                items: []
            }
        };

        if (!context.isB2b) {

            if ((type === 'tariff' || (type === 'service' && item.quick_activation)) && props.isGuest) {
                fields.changingWay.items.push({
                    label: "Быстрое подключение",
                    value: "quick"
                });
            }

            if (item.switch_by_account && props.isGuest) {
                fields.changingWay.items.push({
                    label: "Войти в личный кабинет",
                    value: "personal"
                });
            }
        }

        if ((item.sms_activation_info && item.sms_activation_desc) || (item.ussd_activation_code && item.ussd_activation_desc) || item.switch_opio || item.activation_contact_center) {
            fields.changingWay.items.push({
                label: "Ввести команду на телефоне",
                value: "phone"
            });
        }

        if (type === 'deactivate-rate-personal') {
            fields.changingWay.items = [];
            
            if (this.props.isGuest) {
                if (item.deactivation_by_account) {
                    fields.changingWay.items.push({
                        label: "Войти в личный кабинет",
                        value:"personal"
                    });
                }
            }
            
            if ((item.sms_deactivation_code && item.sms_deactivation_info) || (item.ussd_deactivation_code && item.ussd_deactivation_desc)) {
                fields.changingWay.items.push({
                    label: "Ввести команду на телефоне",
                    value: "phone"
                });
            }
        }

        if (type === 'check-rate-personal') {
            fields.changingWay.items = [];
            if (item.status_check_by_account) {
                fields.changingWay.items.push({
                    label: "Войти в личный кабинет",
                    value:"personal"
                });
            }
            if ((item.sms_status_code && item.sms_status_info) || (item.ussd_status_code && item.ussd_status_desc)) {
                fields.changingWay.items.push({
                    label: "Ввести команду на телефоне",
                    value: "phone"
                });
            }
        }

        if (fields.changingWay.items.length > 0) {
            fields.changingWay.value = fields.changingWay.items[0].value;
        }


        this.tabletWidth = 1023;

        this.state = {
            fields,
            isTabletWidth: (window.innerWidth <= 1023)
        };

        this.onChange = onChange.bind(this);

    }

    static contextTypes = {
        isB2b: PropTypes.bool
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize);
        this.props.clearData();
    }

    componentWillMount () {
        if (this.props.value) {
            this.onChange('changingWay', this.activeRadio());
        }
        this.props.getActivationInfo();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    // устанавливаем активную радиокнопку первую из списка доступных
    activeRadio = () => {
        let changingWay = {...this.state.fields.changingWay};

        if (changingWay.items.length > 0) {
            let changeValue = changingWay.items.find((i) => i.value === this.props.value);

            return changeValue && changeValue.value || changingWay.items[0].value;
        }

        return this.props.value;
    };

    onResize = () => {
        if (this.state.isTabletWidth != (window.innerWidth <= this.tabletWidth)) {
            this.setState({isTabletWidth: (window.innerWidth <= this.tabletWidth)});
        }
    };

    sendNumberForCheck = (phone) => {
        let {
            type,
            item
        } = this.props;

        this.props.sendCheckAvailable(type, phone, item.hasOwnProperty('id_billing') ? item.id_billing : item.id);
    };

    sendActivateItem = (code) => {

        let {
            type,
        } = this.props;

        this.props.sendActivateItem(type, code, this.props.activation.available.query_id);
    };

    renderWayBlock(way) {

        let {
            item,
            type,
            formsSettings
        } = this.props;

        switch (way){
            case "quick":
                return <QuickConnection
                    onSuccess={this.props.onSuccess}
                    sendNumberForCheck={this.sendNumberForCheck}
                    sendActivateItem={this.sendActivateItem}
                    activation={this.props.activation}
                    item={item}
                    type={type}
                    info_text={this.props.settings.modal_tariff_fast_activation_ability_text}
                />;
            case "personal":
                return <div className="mnp-popup_phone_commands">
                    {/*<div className="info-text">{this.props.settings.modal_tariff_activation_login_account_text}</div>*/}

                    <div className="popup__call-login" onClick={() => {this.props.callLogin();}} >
                        <span>Для подключения тарифа, пожалуйста, <span className="popup__call-login__link">авторизуйтесь</span></span>
                        <img src={IconSigninEnter} alt="" role="presentation" />
                    </div>

                    {/*<AuthForm onForgetLinkClick={this.props.forgetLinkClick}*/}
                    {/*             login={this.props.login}*/}
                    {/*             error={this.props.authError}*/}
                    {/*             showTitle={false}*/}
                    {/*             authFormSettings={formsSettings.auth_form}*/}
                    {/*             qrText={this.props.settings.qr_code_description}*/}
                    {/*/>*/}
                </div>;
            // case "personal":
            //     return <PersonalCabinet
            //         onSuccess={this.props.onSuccess}
            //         onLinkClick={this.props.forgetLinkClick}
            //         login={this.props.login}
            //         error={this.props.authError}
            //         hideModal={this.props.hideModal}
            //         info_text={this.props.settings.modal_tariff_activation_login_account_text}
            //     />;
            case "phone":
                return <RatesPhoneCommand
                    item={item}
                    type={type}
                    info_text={this.props.settings.modal_tariff_activation_codes_info_text}
                />;
            default:
                return null;
        }
    }

    render() {

        let title = this.props.title;
        let changingWay = {...this.state.fields.changingWay};

        if (changingWay.items.length === 0) {
            return null;
        }

        return this.state.isTabletWidth
            ?   <div className="swiper-container mnp-popup__change-rate text_md">
                    <div className="b-plug-auth__title">{title}</div>
                    {changingWay.items.map((item, key) =>
                        <Collapse key={key} title={item.label} opened={changingWay.value == item.value}>
                            {this.renderWayBlock(item.value)}
                        </Collapse>
                    )}
                </div>
            :   <div className="swiper-container mnp-popup__change-rate text_md">
                    <div className="b-plug-auth__title">{title}</div>
                        <RenderField field={changingWay} onChange={this.onChange}/>
                        {this.renderWayBlock(changingWay.value)}
                </div>;
    }
}

export default connect(
    state => ({
        activation: state.activation,
        settings: state.settings,
        authError: state.client.authFormError || state.client.fatalError,
        isGuest: state.client.isGuest,
        formsSettings: state.client.formsSettings
    }),
    (dispatch) => {
        return {
            sendCheckAvailable: (kind, phone, serv_id) => dispatch(checkAvailable(kind, phone, serv_id)),
            sendActivateItem: (kind, verify_code, query_id) =>dispatch(activateItem(kind, verify_code, query_id)),
            clearData: () => dispatch(clearActivationData()),
            getActivationInfo: () => dispatch(settingsList("modal_tariff_fast_activation_ability_text;modal_tariff_activation_login_account_text;modal_tariff_activation_codes_info_text")),
            login: (data) => dispatch(auth(data)),
            callLogin: () => dispatch(callLogin()),
        }
    }
)(ChangeRate);