import routes from '../routes';
import {matchPath} from "react-router-dom";


export function getPreloadInfo (path, allRoutes = []) {

    if (path.indexOf('?') !== -1) {
        path = path.substr(0, path.indexOf('?'))
    }

    let data = {}, lkData = {};

    for (let i = 0; i < allRoutes.length; i++) {
        if (!!matchPath(path, allRoutes[i])) {
            data = allRoutes[i].requests;
            lkData = allRoutes[i].requestsLk || [];
            break;
        }
    }

    if (data === {}) {
        console.warn('Route not Found');
    }

    return {data, lkData};
}

export function getHeaderInfo (path) {
    let data = {};

    for (let i = 0; i < routes.length; i++) {
        if (!!matchPath(path, routes[i])) {
            data = routes[i].headerClass;
            break;
        }
    }

    return data;
}