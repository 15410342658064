import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {getPage, routesList} from "../../actions/routes";
import MobileNav from "../../components/Support/MobileNav";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import RightNav from "../../components/Support/RightNav";
import ServiceBlockList from "../../components/Services/ServiceBlockList.js"
import {getCompanies} from "../../actions/company";
import Select from "../../components/common/Select";

class InternetTelephony extends Component {

    constructor(props) {
        super(props);

        let route = props.routes.list.find(x => x.route === props.location.pathname.substr(props.location.pathname.lastIndexOf('/')));
        // let route = props.routes.list.find(x => x.sys_name === 'supportInternetRoute');
        // let route = props.routes.list.find(x => x.sys_name === 'telephonyRoute');
        this.state = {
            route: route,
            pageName: route.sys_name,
            // pageName: route.sys_name.replace('Route', ''),
            selectedCompany: props.companies.length ? props.companies[0].value : null
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.companies.length && !state.selectedCompany) {
            return {
                ...state,
                selectedCompany: props.companies[0].value
            };
        }
        return state;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.support[this.state.pageName] && this.state.selectedCompany) {
            this.props.getPage(this.state.pageName, this.context.selectedRegion.id, this.state.selectedCompany)
        }
    }

    componentDidMount() {
        if (!this.props.support[this.state.pageName] && this.state.selectedCompany) {
            this.props.getPage(this.state.pageName, this.context.selectedRegion.id, this.state.selectedCompany)
        }
        if (!this.props.companies.length) {
            this.props.getCompanies(this.context.selectedRegion.id)
        }
    }

    componentWillMount() {
        if (!this.props.support[this.state.pageName] && this.state.selectedCompany) {
            this.props.getPage(this.state.pageName, this.context.selectedRegion.id, this.state.selectedCompany)
        }
        if (!this.props.companies.length) {
            this.props.getCompanies(this.context.selectedRegion.id)
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    companyChange = (name, value) => {
        this.props.getPage(this.state.pageName, this.context.selectedRegion.id, value)
        this.setState({selectedCompany: value})
    }

    render() {

        let {
            routes,
            support,
            regions,
            location,
            companies
        } = this.props;

        let {
            route,
            pageName,
            selectedCompany
        } = this.state;

        let item = support[pageName];

        let selectedRegion = this.context.selectedRegion;
        let isOk = !!item;
        let pageTitle = isOk ? item.title : null;
        let content = isOk ? item.content : null;
        let files = isOk ? item.files : null;

        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder header-service-rules");
        let supportRoute = routes ? routes.list.find(x => x.sys_name === 'supportRoute') : null;
        let rulesRoute = routes ? routes.list.find(x => x.sys_name === 'termsOfServiceRoute') : null;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + supportRoute.route,
                title: "Поддержка"
            },
            {
                url: "/" + selectedRegion.alias + rulesRoute.route,
                title: "Правила оказания услуг"
            },
            {
                url: "",
                title: pageTitle
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname}`;

        //получаем нужный нам SEO {} 
        const currentMetaTags = route && route.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder main-media-bank" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple__breadcrumbs">
                                <RatesBreadcrumbs items={breadcrumbs} />
                            </div>
                            { pageTitle ? <div className="b-intro-simple__title complain-txt__title">{ pageTitle }</div> : null }

                            <ServiceBlockList content={ content } files={ files }>
                                <div className="legal-information-page__wrap-select">
                                    <Select
                                        value={selectedCompany}
                                        options={companies}
                                        onChange={this.companyChange}
                                        customClassName={'select-company'}
                                    />
                                </div>
                            </ServiceBlockList>
                        </div>
                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <RightNav />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        companies: state.company.list,
        support: state.support,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getCompanies: (regionId) => dispatch(getCompanies(regionId)),
            getPage: (page, region, company = null) => dispatch(getPage(page, region, company))
        }
    }
)(InternetTelephony);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const internetRequests = [
    {
        func: 'getCompanies',
        args: [],
        object: 'company.list',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];

export const telephonyRequests = [
    {
        func: 'getCompanies',
        args: [],
        object: 'company.list',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];