const initState = {isOk:false, list: [], selected: {}};

export function regions(state = initState, action) {
    switch (action.type) {
        case 'REGION_LIST':
            return {...state, isOk: false};
        case 'REGION_LIST_SUCCESS':
            return {...state, ...action.data, isOk: true};
    }
    return state;
}