import {
    call,
    put
} from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function completeSetsList(regionId) {
    return {
        type: 'COMPLETE_SETS_LIST',
        regionId
    }
}

export function* fetchCompleteSetsList(action) {
    try {
        yield put(showLoading());

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/complete-sets" + (action.regionId ? '?regionId='+action.regionId : ''), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}