import React, { Component } from 'react';

class DownloadDoc extends Component {

    render() {

        let {title, url, size} = this.props;

        return (
            <div className="b-roaming__download-doc">
                <div className="b-download-doc">
                    <div className="b-download-doc__wrapper">
                        <a className="b-download-doc__item b-download-doc__item_pdf" href={url} target="_blank">
                            {title}
                            {size ? " ("+size+")" : null}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownloadDoc;