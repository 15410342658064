import {setScale} from './../tools/tools';
let initState = {
    defaultData: {},
    recalcData: {}, 
    errors: false,
    isConstructorOk: false
};

export function tariffConstructor(state = initState, action) {
    switch (action.type) {
        case "GET_TARIFF_CONSTRUCTOR_SUCCESS":
            const defaultScope = setScale(action.data.scope);
            return {...state, errors: false, defaultData: action.data, axis: {
                    x: defaultScope.m.split(",").map((item) => parseInt(item, 10)),
                    y: defaultScope.gb.split(",").map((item) => parseInt(item, 10) || 0)
                },
            };
        case 'POST_TARIFF_CONSTRUCTOR':
            return {...state, isLoadingPostTariff: true}
        case 'POST_TARIFF_CONSTRUCTOR_BAD_REQUEST':
        case 'POST_TARIFF_CONSTRUCTOR_FORBIDDEN':
        case 'POST_TARIFF_CONSTRUCTOR_ERROR':
            return {...state, errors: action.data, number: Math.random(), isLoadingPostTariff: false};
        case "POST_TARIFF_CONSTRUCTOR_SUCCESS":
            return {...state, recalcData: action.data, errors: false, number: Math.random(), isLoadingPostTariff: false};

        case "IS_CONSTRUCTOR_OK":
            return {...state, isConstructorOk: action.data};
    }
    return state;
}