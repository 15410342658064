import React, {Component} from 'react'
import Slider from 'rc-slider'

export default class ExchangeForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: 0,
            gb: 0,
            disabledSubmitBtn: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isSuccess) {
            return {
                ...state,
                value: 0,
                gb: 0,
                disabledSubmitBtn: false
            }
        }
    }

    onInputChange = (e) => {

        if (e.target.value) {
            var val = parseInt(e.target.value);

            if (val !== NaN) {
                if (val > this.props.max) {
                    this.onChange(this.props.max)
                } else if (val < 0) {
                    this.onChange(0)
                } else {
                    this.onChange(val)
                }
            }
        } else {
            this.onChange(0)
        }

    };

    onChange = (val) => {
        let gb = (this.props.rate*val)/1024;
        this.setState({value: val, gb: (parseInt(gb) == gb) ? parseInt(gb) : gb.toFixed(2)})
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(JSON.stringify({minutes: this.state.value}))
        this.setState({disabledSubmitBtn: true}, () => {
            this.checkSubmitBtn()
        })
    };

    checkSubmitBtn = () => {
        setTimeout(() => {
            this.setState({disabledSubmitBtn: false});
        }, 4000)
    }

    render(){

        let {
            max,
            notice,
            title = 'Обменять минуты на гигабайты'
        } = this.props;

        let {
            value,
            gb,
            disabledSubmitBtn
        } = this.state;

        return <div className="mnp-popup-ok swiper-container mnp-popup__wrapper ex-min-popup">
            <form className="text-center" onSubmit={this.onSubmit}>
                {title ? <div className="mnp-popup__title b-callback-form__title">{title}</div> : null}
                <div className="ex-min-popup__text">Доступно для обмена: <b>{max} Мин</b></div>
                <div className="ex-min-popup__text-block">
                    <input className="ex-min-popup__minutes ex-min-popup__number" onFocus={() => {this.onChange(0)}} value={value} onChange={this.onInputChange}/>
                    <b className="ex-min-popup__bold-text">минут</b>
                    <div className="ex-min-popup__block">
                        <b className="ex-min-popup__bold-text">на</b>
                        <b className="ex-min-popup__number text_lg">{gb}</b>
                        <b className="ex-min-popup__bold-text">Гб</b>
                    </div>
                </div>
                <Slider max={max} min={0} marks={{0: "0 мин", [max]: max+" мин"}} onChange={this.onChange} value={value}/>
                <button type="submit" className={"btn btn--mnp-next u-btn u-btn_default"+(value && !disabledSubmitBtn ? "" : " disabled")}><b>Обменять</b></button>
                {notice ? <div className="mnp-popup__bottom">{notice}</div> : null}
            </form>
        </div>
    }
}