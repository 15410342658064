import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  advertisingLabelingMobToggleAction,
  advertisingLabelingMobGetDataAction,
} from '../../actions/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class AdvertisingLabeling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      nameBtnforCopy: 'Скопировать токен',
      copied: false,
    }

    this.advertisingRef = React.createRef();
  }

  componentDidMount() {
    // для закрытия тултипа
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  // клик по кнопке РЕКЛАМА
  toggle = () => {
    const { show } = this.state;
    const { advertisingLabelingMobToggleAction, type } = this.props;

    this.setState({ show: !show });

    if (type === 'mainType' ? window.innerWidth <= 1679 : window.innerWidth <= 767) {
      advertisingLabelingMobToggleAction(true);
    }
  }

  // клик по КОПИРОВАТЬ ТОКЕН
  copy = () => {
    this.setState({ nameBtnforCopy: 'Скопировано' });
    setTimeout(() => {
      this.setState({ nameBtnforCopy: 'Скопировать токен' });
    }, 1000);
  }

  // содержимое кнопки "РЕКЛАМА"
  contentBtn = () => {
    return (
      <React.Fragment>
        <div className='advertising_labeling__front_word'>
          Реклама
        </div>
        <div className='advertising_labeling__wrap_svg_info'>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM6.875 3.5C7.02334 3.5 7.16834 3.54399 7.29168 3.6264C7.41502 3.70881 7.51115 3.82594 7.56791 3.96299C7.62468 4.10003 7.63953 4.25083 7.61059 4.39632C7.58165 4.5418 7.51022 4.67544 7.40533 4.78033C7.30044 4.88522 7.16681 4.95665 7.02132 4.98559C6.87583 5.01453 6.72503 4.99968 6.58799 4.94291C6.45095 4.88614 6.33381 4.79001 6.2514 4.66668C6.16899 4.54334 6.125 4.39834 6.125 4.25C6.125 4.05109 6.20402 3.86032 6.34467 3.71967C6.48532 3.57902 6.67609 3.5 6.875 3.5ZM7.5 10.5H7C6.86739 10.5 6.74022 10.4473 6.64645 10.3536C6.55268 10.2598 6.5 10.1326 6.5 10V7C6.36739 7 6.24022 6.94732 6.14645 6.85355C6.05268 6.75979 6 6.63261 6 6.5C6 6.36739 6.05268 6.24021 6.14645 6.14645C6.24022 6.05268 6.36739 6 6.5 6H7C7.13261 6 7.25979 6.05268 7.35356 6.14645C7.44732 6.24021 7.5 6.36739 7.5 6.5V9.5C7.63261 9.5 7.75979 9.55268 7.85356 9.64645C7.94732 9.74021 8 9.86739 8 10C8 10.1326 7.94732 10.2598 7.85356 10.3536C7.75979 10.4473 7.63261 10.5 7.5 10.5Z" fill="white" />
          </svg>
        </div>
      </React.Fragment>
    )
  }


  // убираем тултип по клику вне его области "пицеман"
  handleClickOutside = (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      // includes --- ищет указанный элемет в массиве, возвращает true или false
      if (!path.includes(this.advertisingRef.current)) {
          this.setState({
            show: false,
          });
      }
  };


  render() {
    const {
      token = '',
      advertiser = '',
      advertisingLabelingMobGetDataAction = () => { },
      type = '',
    } = this.props;

    const { show, nameBtnforCopy } = this.state;
    const isMainType = (type === 'mainType' ? 'mainType' : '');

    if (!token) return null;

    return (
      <React.Fragment>
        <div
          className={`advertising_labeling ${isMainType}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div
            className='advertising_labeling__content'
            onClick={() => advertisingLabelingMobGetDataAction({ token, advertiser, type })}
          >
            <div 
              className={`advertising_labeling__wrap_window_info ${show ? 'active' : ''} ${isMainType}`}
              ref={this.advertisingRef}
            >
              <div className='advertising_labeling__description'>
                {advertiser || 'Рекламодатель не заполнен'}
              </div>

              <CopyToClipboard
                text={token}
                onCopy={() => this.setState({ copied: true })}>
                <div
                  className='advertising_labeling__wrap_copy'
                  onClick={() => this.copy()}
                >
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 10.5H14V2.5H6V5.5" stroke="#FA6600" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 5.5H3V13.5H11V5.5Z" stroke="#FA6600" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div className='advertising_labeling__copy_name'>
                    {token ? nameBtnforCopy : 'Рекламодатель не заполнен'}
                  </div>
                </div>
              </CopyToClipboard>
            </div>

            <div
              className='advertising_labeling__wrap_front_word'
              onClick={() => this.toggle()}
            >
              <div className={`advertising_labeling__content_btn_mob ${isMainType}`}>
                {this.contentBtn()}
              </div>

              <div className={`advertising_labeling__content_btn_desc ${isMainType}`}>
                {show
                  && (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4L4 12" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 12L4 4" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  )}

                {!show
                  && (
                    <React.Fragment>
                      {this.contentBtn()}
                    </React.Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
};

export default connect(
  state => ({}),
  dispatch => {
    return {
      advertisingLabelingMobToggleAction: (data) => dispatch(advertisingLabelingMobToggleAction(data)),
      advertisingLabelingMobGetDataAction: (data) => dispatch(advertisingLabelingMobGetDataAction(data)),
    }
  }
)(AdvertisingLabeling);