const initState = { isOk:false };

export function internet(state = initState, action) {
    switch (action.type) {
        case 'INTERNET_ITEMS_LIST':
            return {...state, isOk: false};
        case 'INTERNET_ITEMS_LIST_SUCCESS':
            return {...state, internetItemsList: action.data, isOk: true};
    }
    return state;
}