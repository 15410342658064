import React, { Component } from 'react';

import RateListItem from './RateListItem';
import RateChooseItem from "./RateChooseItem";

class RatesList extends Component {

    render() {

        if (typeof this.props.rates === "undefined") {
            return (
                <div className="b-page__tariffs-table">
                </div>
            );
        }


        var {
            rates,
            currentPage,
            byCat,
            linkToShop,
            isChooseItem,
            canRecommend,
            onClickConnect = () => {},
        } = this.props;

        let socBl = false;
        let funBl = false;

        if ( rates.length > 0) {
            if (rates.find(x => !!x.svoi && x.svoi.social_network_unlim === true)) socBl = true;
            if (rates.find(x => !!x.svoi && x.svoi.youtube_unlim === true) || rates.find(x => !!x.svoi && x.svoi.ivi === true)) funBl = true;
        }

        let socData = {
            socBl,
            funBl
        }

        return (

            <div className="b-page__tariffs-table">
                {rates.map((rate, index) =>
                    isChooseItem ?
                        <RateChooseItem data={rate} currentPage={currentPage} key={rate.id} byCat={byCat} linkToShop={linkToShop} /> :
                        <RateListItem
                            data={rate}
                            index={index}
                            currentPage={currentPage}
                            key={rate.id} byCat={byCat}
                            linkToShop={linkToShop}
                            canRecommend={canRecommend}
                            onClickConnect={onClickConnect}
                            socData={socData}
                        />
                )}
            </div>
        )
    }
}

export default RatesList;
