import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'

class LinkResultSearch extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    // подставляет нужный регион
    switchRegions = () => {
        const idRerion = this.props.arrRegions.find((i) => i === this.context.selectedRegion.id);

        if (!idRerion) {
            switch (this.props.arrRegions[0]) {
                case 66: return 'sverdlovsk_oblast';
                case 45: return 'kurgan_oblast';
                case 86: return 'khmao';
                case 89: return 'yanao';

                default: return 'sverdlovsk_oblast';
            }
        } else {
            return this.context.selectedRegion.alias;
        }
    }

    render() {

        let {
            preTo,
            to,
            children,
            className,
            style = {},
        } = this.props;

        let id = null;

        // let fetchBefore = this.context.fetchBefore;

        let linkItems = to.split('/');
        if (linkItems.length > 2) {
            if (linkItems[1] === 'services' && linkItems[2] !== 'archive') {
                id = linkItems[2];
            } else {
                id = linkItems[3];
            }
        }

        return (
            <React.Fragment>
                <Link
                    style={style} to={`/${this.switchRegions() + to}`}
                    className={className}
                // onClick={fetchBefore("/"+'yanao'+to, id)}
                // target="_blank"
                >
                    {children}
                </Link>
            </React.Fragment>
        )
    }
}

export default LinkResultSearch;
