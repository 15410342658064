import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList, routesVacanciesList} from "../../actions/routes";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import InfoBlock from "../../components/common/InfoBlock";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import {infoBlocksList} from "../../actions/infoBlocks";
import ContactsMedia from "../../components/PressCenter/ContactsMedia"
import {contactsList} from "../../actions/contacts";
import {newsList} from "../../actions/news";
import Modal from "../../components/common/Modal";

import NewsAndEvents from "../../components/PressCenter/NewsAndEvents";
import Input from "../../components/Constructor/Input";
import CheckBox from "../../components/common/CheckBox";
import {emailValidator} from "../../tools/form-helper";
import {checkCallbackForm} from "../../actions/callback";
import {onSubscription} from "../../actions/support";
import {formsList} from "../../actions/forms";

class PressCenter extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            listTitle: "Пресс центр",
            newsTitle: "Новости и события",
            modalEmail: false,
            modalEmailSuccess: false,
            agreementChecked: false,
            fields: {
                email: {
                    typeInput: "text",
                    placeholder: "Введите E-Mail",
                    label: "Email",
                    value: "",
                    name: "email",
                    required: true,
                    validators: [emailValidator],
                    hasError: false,
                    errorText: "Укажите корректный email",
                    dot_orange: true
                },
                firstname: {
                    typeInput: "text",
                    placeholder: "Введите Имя",
                    label: "ИМЯ",
                    value: "",
                    name: "firstname",
                    // required: true,
                    hasError: false,
                    errorText: "Укажите имя",
                    // dot_orange: true
                },
                company: {
                    typeInput: "text",
                    placeholder: "Введите название",
                    label: "СМИ",
                    value: "",
                    name: "company",
                    // required: true,
                    hasError: false,
                    errorText: "Укажите компанию",
                    // dot_orange: true
                },
            }
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    componentWillMount() {
        let regionId = this.context && this.context.selectedRegion.id;

        if (this.props.infoBlocks && this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(regionId, 'about_company');
        }

        if (this.props.contactsList && this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("press");
        }

        if (this.props.routesVacancies.list.length === 0) {
            this.props.getRoutesVacancies();
        }

        if (this.props.newsList && this.props.newsList.news.length === 0 || this.props.newsList.news && !Array.isArray(this.props.newsList.news.data) || this.props.newsList.news && this.props.newsList.news.data.length > 6) {
            this.props.getNewsList('?list_type=mobile');
        }
    }
    componentDidMount() {
        if (typeof location != "undefined") {
            this.props.loadForm(location.origin + location.pathname, this.context.isB2b ? 'b2b' : '');
        }

        this.props.formsList('mailing');
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.callbackForm.recaptchaSiteKey !== nextProps.callbackForm.recaptchaSiteKey) {
            this.createReCaptcha(nextProps.callbackForm.recaptchaSiteKey);
        }
        if ((this.props.support.subscribeSuccess !== nextProps.support.subscribeSuccess) && nextProps.support.subscribeSuccess) {
            this.setState({modalEmail: false, modalEmailSuccess: true})
        }
        if (this.props.support.subscribeError !== nextProps.support.subscribeError) {
            if (nextProps.support.subscribeError != this.props.support.subscribeError) {
                let fields = {...this.state.fields};
                if(nextProps.support.subscribeError.hasOwnProperty('captcha')) {
                    fields['email'].hasError = true
                    fields['email'].errorText = nextProps.support.subscribeError['captcha'];
                } else {
                    for (let attr in fields) {
                        fields[attr].hasError = nextProps.support.subscribeError.hasOwnProperty(attr);
                        if (nextProps.support.subscribeError.hasOwnProperty(attr)) {
                            fields[attr].errorText = nextProps.support.subscribeError[attr];
                        }
                    }
                }
            }
        }
    }
    createReCaptcha = (siteKey) => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
        script.async = true;
        script.defer = true;
        document.body.appendChild(script)
    }
    onAgreementChange = (name, val) => this.setState((prev) => {
        return {
            agreementChecked: !prev.agreementChecked
        }
    })
    onFieldChange = (name, value) => {
        let fields = {...this.state.fields};

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        let valid = true;
        if (!value) {
            if (name !== 'firstname' && name !== 'company') {
                valid = false
            }
        }
        if (fields[name].hasOwnProperty('validators') && !!fields[name].validators) {
            fields[name].validators.forEach(function (func) {
                if (!func.call(this, fields[name].value)) {
                    valid = false;
                }
            });
        }
        fields[name].hasError = !valid;
        this.setState({fields});
    };
    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };
    onBlur = (name) => {

        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                if (name !== 'firstname' && name !== 'company') {
                    this.setState({
                        fields: {
                            ...this.state.fields,
                            [name]: {
                                ...this.state.fields[name],
                                hasError: !valid
                            }
                        }
                    });
                }
            }
        };
    };
    onSubmit = (e) => {
        e.preventDefault();

        if(this.isValid()) {
            window.grecaptcha
                .execute(this.props.callbackForm.recaptchaSiteKey)
                .then(token => {
                    const fields = {...this.state.fields}

                    this.setState({captchaError: ''})

                    let data = {
                        agreement: this.state.agreementChecked ? 1 : 0,
                        captcha: token,
                        firstname: fields.firstname.value,
                        email: fields.email.value,
                        company: fields.company.value
                    }
                    if(this.props.client.clientInfo && this.props.client.clientInfo.subs_id) data.subs_id = this.props.client.clientInfo.subs_id

                    this.props.onSubscription(data);
                })
        }
    };

    isValid = () => {
        const fields = {...this.state.fields}
        return (
            (fields["email"].value && !fields['email'].hasError) &&
            this.state.agreementChecked
        )
    }

    render() {
        const {regions, location} = this.props;
        // if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder press-center-header");
        let infoBlocks = this.props.infoBlocks.list;
        let selectedRegion = this.context.selectedRegion;
        let route = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "pressCenterRoute");
        let routeAllNews = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "newsRoute");
        let linkMediaBank = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "mediaBankRoute");
        let contactsMedia = this.props.contactsList && this.props.contactsList.contacts;
        let dataNews = this.props.newsList && this.props.newsList.news.data;

        const {email, firstname, company} = this.state.fields;

        const {newsTitle} = this.state;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "",
                title: "Пресс центр"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'pressCenterRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        const mailingForm = this.props.forms && this.props.forms.list.find(x => x.alias === 'mailing_form')

        return (
            <main className="page__main-holder main-press-center" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {
                            "name": "keywords",
                            "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""
                        }
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container motiv-vacancies motiv-press-center">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs}/>
                                </div>
                            </div>
                            <span className="carousel-about-company-title"
                                  dangerouslySetInnerHTML={{__html: 'Пресс центр'}}/>
                            <InfoBlock type={'about_press_center_carousel'} data={infoBlocks}
                                       title={this.state.listTitle} section={'press_center_big_carousel'}/>
                            {/* блок с новостями в прессцентре */}
                            {routeAllNews ?
                                <NewsAndEvents
                                    data={dataNews}
                                    title={newsTitle}
                                    linkPageNews={routeAllNews.link}
                                    buttonBlockHidden={true}
                                /> :
                                null
                            }

                            <a href="https://t.me/motiv_news" className="info-page-container__subscribe-mobile"
                               target="_blank">
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/telegram.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на новости</span>
                                </div>
                            </a>
                            <button
                                className="info-page-container__subscribe-mobile info-page-container__subscribe-email"
                                onClick={() => {
                                    this.setState({modalEmail: true})
                                }}>
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/email.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на рассылку</span>
                                </div>
                            </button>

                            <div className="press-center-social-block press-center-social-block--main-page">
                                <InfoBlock type={'press-center-social'} data={infoBlocks} section={'about_social'}/>
                            </div>
                            {/* блок с медиабанком в прессцентре */}
                            {linkMediaBank ?
                                <InfoBlock
                                    type={'about_press_center_carousel_video'}
                                    data={infoBlocks}
                                    section={'about_video_carousel'}
                                    linkMediaBank={linkMediaBank.link}
                                /> :
                                null
                            }
                            {linkMediaBank ?
                                <InfoBlock type={'media_materials_link'} data={infoBlocks}
                                           section={'about_link_with_icon'}/> :
                                null
                            }
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <AsideMenu
                                    flag={route.sys_name}
                                    route={this.props.routesVacancies}
                                    customTitleMenu={'Пресс центр'}
                                />
                            </div>

                            <a href="https://t.me/motiv_news" className="info-page-container__subscribe ml"
                               target="_blank">
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/telegram.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на новости</span>
                                </div>
                            </a>
                            <button className="info-page-container__subscribe info-page-container__subscribe-email ml"
                                    onClick={() => {
                                        this.setState({modalEmail: true})
                                    }}>
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/email.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на рассылку</span>
                                </div>
                            </button>

                            <div className="press-center-social-block press-center-social-block--aside-menu">
                                <InfoBlock type={'press-center-social'} data={infoBlocks} section={'about_social'}/>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.modalEmail &&
                        <Modal className="u-modal u-modal_active modal-fullscreen modal-email" close={() => {
                            this.setState({modalEmail: false})
                        }}>
                            <div className="content">
                                <h3 className="modal-email__title">{mailingForm && mailingForm.settings.mailing_form_header}</h3>
                                <p className="modal-email__text">{mailingForm && mailingForm.settings.mailing_form_infotext}</p>
                                <div className="modal-email__form">
                                    <form onSubmit={this.onSubmit} className="vacancy-modal-form support-form">
                                        <div className="form-row form-vacancy-row form-support-row">
                                            <div
                                                className="form-vacancy-row--email form-vacancy-label-column form-support-row-item modal-email-form-inner">
                                                <Input {...email}
                                                       onChange={this.onFieldChange}
                                                       name={email.name}
                                                       onFocus={this.onFocus(email.name)}
                                                       onBlur={this.onBlur(email.name)}
                                                />
                                                <Input {...firstname}
                                                       onChange={this.onFieldChange}
                                                       name={firstname.name}
                                                       onFocus={this.onFocus(firstname.name)}
                                                       onBlur={this.onBlur(firstname.name)}
                                                />
                                                <Input {...company}
                                                       onChange={this.onFieldChange}
                                                       name={company.name}
                                                       onFocus={this.onFocus(company.name)}
                                                       onBlur={this.onBlur(company.name)}
                                                />
                                                <div
                                                    className="vacancy-modal-btn-block support-send-block modal-email-btn">
                                                    <button
                                                        onClick={this.onSubmit}
                                                        type="submit"
                                                        className={`u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default support-form-send-btn ${!this.isValid() ? 'disabled' : ''}`}>
                                                        Подписаться
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-email-agreement">
                                    <CheckBox
                                        name={'agree-feedback'}
                                        // label={(this.props.support.feedbackForm && this.props.support.feedbackForm.settings) ? this.props.support.feedbackForm.settings.feedback_form_b2b_agreement : ''}
                                        label={mailingForm && mailingForm.settings.mailing_form_agreement}
                                        value={this.state.agreementChecked}
                                        onChange={this.onAgreementChange}
                                    />
                                </div>
                                <div className="modal-email-recaptcha">
                                    Этот сайт защищен reCAPTCHA и применяются <div className="hide-br"></div>
                                    <a href="https://policies.google.com/privacy" target='_blank'>Политика
                                        конфиденциальности</a> и <a href="https://policies.google.com/terms"
                                                                    target='_blank'>Условия обслуживания</a> Google
                                </div>
                            </div>
                        </Modal>
                    }
                    {
                        this.state.modalEmailSuccess &&
                        <Modal className="u-modal u-modal_active modal-fullscreen modal-email modal-email-success"
                               close={() => {
                                   this.setState({modalEmailSuccess: false})
                               }}>
                            <div className="content">
                                <img src={`${require('../../../icons/circle-check.svg')}`}
                                     className="modal-email-success__check" alt="Успешно"/>
                                <div className="modal-email-success__title">{mailingForm && mailingForm.settings.mailing_form_success_header}</div>
                                <div className="modal-email-success__text">{mailingForm && mailingForm.settings.mailing_form_success_description}</div>
                                <div
                                    className="vacancy-modal-btn-block support-send-block modal-email-btn modal-email-success-btn">
                                    <button
                                        onClick={() => {
                                            this.setState({modalEmailSuccess: false})
                                        }}
                                        type="submit"
                                        className={"u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default support-form-send-btn"}>
                                        Хорошо
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    }
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        infoBlocks: state.infoBlocks || [],
        contactsList: state.contacts || [],
        newsList: state.news || [],
        regions: state.regions,
        support: state.support,
        callbackForm: state.callbackForm,
        client: state.client,
        forms: state.forms
    }),
    (dispatch) => {
        return {
            formsList: (group) => dispatch(formsList(group)),
            settingsList: (name) => dispatch(checkCallbackForm(name)),
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getContactsList: (name) => dispatch(contactsList(name)),
            getNewsList: (listType) => dispatch(newsList(listType)),
            onSubscription: (data) => dispatch(onSubscription(data)),
            loadForm: (url, businessLine) => dispatch(checkCallbackForm(url, businessLine)),
        }
    }
)(PressCenter);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const PressCenterRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getContactsList',
        args: ['press'],
        object: 'contactsList.contacts',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['about_company'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "press_center_big_carousel_0"},
            {type: "press_center_big_carousel_1"},
            {type: "about_video_carousel0"},
            {type: "about_video_carousel1"},
            {type: "press_center_bottom_link_1"},
            {type: "press_center_bottom_link_2"},
            {type: "press_center_bottom_link_3"},
            {type: "about_social_vk"},
            {type: "about_social_fb"},
            {type: "about_social_ok"},
        ]
    },
    {
        func: 'getNewsList',
        args: ['?list_type=mobile'],
        object: 'newsList.news',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];
