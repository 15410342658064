import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SmallMenu from "../../../js/components/PaymentsTransfers/SmallMenu";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import { deferredPaymentPageAction } from "../../actions/paymentsTransfers"
import LinkC from "../../../js/components/common/LinkC";
import { phoneMask, numberMask } from "../../tools/mask";
import { getRounding, colorNum } from '../../tools/tools';
import { phoneValidator, emptyValidator } from "../../tools/form-helper";
import Input from "../../components/Constructor/Input";
import Modal from "../../components/common/Modal";
import {auth, recovery, clearState, promisedInfoAction, clientPromisedAction, callLogin} from "../../actions/client";
import SuccessModal from "../../components/modals/SuccessModal";
import AuthorizationModal from "../../components/modals/AuthorizationModal";
import RecoveryModal from "../../components/modals/RecoveryModal";
import RangeInput from '../../components/common/RangeInput';

class DeferredPayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: "",

            fields: {
                form_name: {
                    value: "Пополнить баланс"
                },
                phone: {
                    typeInput: "text",
                    label: <span className="top-up-balance_label" dangerouslySetInnerHTML={{ __html: 'Номер телефона МОТИВ' }} />,
                    name: "phone",
                    value: "",
                    required: true,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    disabled: true
                },
                sum: {
                    typeInput: "text",
                    label: <span className="top-up-balance_label" dangerouslySetInnerHTML={{ __html: 'сумма, руб.' }} />,
                    name: "sum",
                    value: "",
                    required: true,
                    mask: numberMask(1),
                    validators: [emptyValidator],
                    disabled: true
                },
            },

            valueRange: 0,
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount() {
        let {
            paymentDeferred,
        } = this.props

        if (!paymentDeferred.length) {
            this.props.deferredPaymentPageAction();
        }
    }

    componentDidMount() {
        let {
            clientInfo,
            isGuest,
            promisedInfo
        } = this.props

        let clientPhoneValue = clientInfo && clientInfo.username;
        let limitValue = promisedInfo && promisedInfo.pay_sum;

        if (!this.props.paymentDeferred.length) {
            this.props.deferredPaymentPageAction();
        }
        if (!isGuest && !promisedInfo) {
            this.props.promisedInfoAction();
        }

        // заполняем инпуты при переходе с другого урла
        if (!isGuest && !!clientPhoneValue) {
            this.setFieldValue(clientPhoneValue, limitValue);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        let {
            clientInfo,
            isGuest,
            promisedInfo,
        } = this.props

        let clientPhoneValue = clientInfo && clientInfo.username;
        let limitValue = promisedInfo && promisedInfo.pay_sum;

        if (prevState.modal && !this.state.modal) {
            this.props.clear();
        }

        // обновляем данные по promisedInfo при авторизации
        if (!isGuest && !promisedInfo && clientPhoneValue !== prevProps.clientInfo.username) {
            this.props.promisedInfoAction();
        }

        // добавляем телефон если авторизованы, обновляется при перезагрузке страницы
        if (!isGuest && clientPhoneValue !== prevProps.clientInfo.username) {
            this.setFieldValue(clientPhoneValue, limitValue);
        }
        // добавляем сумму если авторизованы
        if (!isGuest && limitValue !== (prevProps.promisedInfo && prevProps.promisedInfo.pay_sum)) {
            this.setFieldValue(clientPhoneValue, limitValue);
        }

        // устанавливаем максимальное значение в инпутрендж при загрузке страницы
        if (!isGuest && (this.props.promisedInfo !== prevProps.promisedInfo) && this.props.promisedInfo) {
            this.setState({
                valueRange: this.props.promisedInfo.max_sum
            })
            // изменяет цвет значения инпута
            colorNum(promisedInfo.max_sum, promisedInfo.min_sum, promisedInfo.max_sum);
        
            // у инпутренджа, если максимальная сумма больше 500, то скрываем промежуточные числа (250, 350 итд)
            const elemEven = document.querySelectorAll('.deferred_payment_slider .rc-slider-mark span:nth-child(even)')
            elemEven.forEach(element => {
                if (promisedInfo.max_sum > 500) {
                    element.style.color = 'transparent'; 
                }
            });

            // у инпутренджа, если максимальная сумма меньше 500, то скрываем последнее число
            const elemLast = document.querySelector('.deferred_payment_slider .rc-slider-mark span:last-child')
            if (elemLast && promisedInfo.max_sum < 500) {
                elemLast.style.color = 'transparent'; 
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.client.promisedError) {
            return { ...state, modal: "promised-error" }
        }

        if (!props.isGuest && state.modal === "AuthorizationModal") {
            return { ...state, modal: null }
        }

        if (props.client.fatalError && ["AuthorizationModal", "RecoveryModal"].indexOf(state.modal) > -1) {
            return { ...state, modal: "ErrorModal" }
        }

        if (props.client.successMessageTitle && "RecoveryModal" === state.modal) {
            return { ...state, modal: "SuccessModal" }
        }
        return state;
    }

    // добавляет тот же номер, что и при авторизации
    setFieldValue = (clientPhoneValue = '', clientLimitValue = '') => {
        let phoneValue = clientPhoneValue ? phoneMask(clientPhoneValue) : null;
        // let sumValue = clientLimitValue ? clientLimitValue.toString() : null;
        let sumValue = clientLimitValue;

        this.setState({
            fields: {
                ...this.state.fields,
                phone: {
                    ...this.state.fields.phone,
                    value: phoneValue,
                },
                sum: {
                    ...this.state.fields.sum,
                    value: sumValue,
                },
            }
        });
    };

    // склонение слов
    // n --- принимет число
    // text_forms --- принимает массив к примеру ['магазин', 'магазина', 'магазинов']
    declOfNum(n, text_forms) {
        n = Math.abs(n) % 100;
        const n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    }

    renderModal = () => {
        let {
            modal
        } = this.state;

        let {
            authFormError,
            formsSettings,
            successMessageTitle,
            successMessage,
            recoveryFormError,
            fatalErrorTitle,
            fatalError
        } = this.props.client

        if (typeof document !== "undefined") {
            if (modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        const paymentNoteErrorTilte = this.props.promisedError && this.props.promisedError.message
        const paymentNoteSuccessTilte = 'Услуга Отложенный платеж успешно подключена'

        switch (modal) {
            case "promised-error":
                return <SuccessModal
                    title={paymentNoteErrorTilte}
                    text={''}
                    icon={false}
                    btnText="ОК"
                    close={this.closeModal}
                />;
            case "promised-ok":
                return <SuccessModal
                    title={paymentNoteSuccessTilte}
                    text={''}
                    btnText="ОК"
                    close={this.closeModal}
                />;

            case "AuthorizationModal":
                return <AuthorizationModal login={this.props.auth}
                    onForgetLinkClick={
                        (e) => {
                            e.preventDefault();
                            this.openModal("RecoveryModal")
                        }
                    }
                    error={authFormError}
                    close={this.closeModal}
                    subtitle={formsSettings && formsSettings.auth_form.auth_form_description}
                    authFormSettings={formsSettings ? formsSettings.auth_form : {}}
                    qrText={this.props.qrText}
                />;
            case "RecoveryModal":
                return <RecoveryModal close={this.closeModal}
                    recover={this.props.recover}
                    error={this.props.recoveryFormError}
                    modal={this.state.modal}
                    recoveryFormSettings={formsSettings ? formsSettings.recovery_form : {}}
                />;

            case "ErrorModal":
                return <SuccessModal title={fatalErrorTitle}
                    text={fatalError}
                    icon={false}
                    btnText="ОК"
                    close={this.closeModal}
                />;
            case "SuccessModal":
                return <SuccessModal
                    title={successMessageTitle}
                    text={successMessage}
                    icon={true}
                    btnText="ОК"
                    close={this.onForgotPasswordClickHandler}
                />;
            default:
                return "";
        }
    };

    onForgotPasswordClickHandler = () => {
        this.setState(() => {
            return {
                modal: null
            }
        }, () => {
            this.openModal('AuthorizationModal');
        });
    };

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        }, () => {
            this.props.clearData ? this.props.clearData() : "";
            this.props.clearSupportData ? this.props.clearSupportData() : "";
        });
    };

    closeModal = () => {
        this.setState({ modal: null });
        this.props.clear();
    };

    login = () => {
        this.openModal("AuthorizationModal");
    };

    onSubmit = (e) => {
        e.preventDefault();

        let {
            promisedInfo,
        } = this.props

        let payment_available = promisedInfo && promisedInfo.payment_available

        this.props.clientPromisedAction({ paySum: String(this.state.valueRange) });

        if (payment_available) {
            this.setState({ modal: "promised-ok" });
        }
    };

    // округляем число по десяткам
    onBlur = (param) => {
        const {
            promisedInfo
        } = this.props;

        if (param === 'XVal') {
            return (val) => {
                // округляет число до десятых
                let okr = (Math.ceil(parseInt(+val.target.value) / 10) * 10);
                let notNaN = isNaN(okr) ? promisedInfo.min_sum : okr;
                let value = (notNaN < promisedInfo.min_sum) ? promisedInfo.min_sum : notNaN;

                this.setState({
                    valueRange: value
                })
            }
        }
    };

    // изменяет значение ползунка
    onChange = (param) => {
        const {
            promisedInfo
        } = this.props;

        if (param === 'XVal') {
            return (val) => {
                // изменяет цвет значения инпута
                colorNum(val, promisedInfo.min_sum, promisedInfo.max_sum);

                // разрешение ввода только цифр
                let value = String(val).replace(/\D/g, '') || 0;
                this.setState({
                    valueRange: (value > promisedInfo.max_sum) ? promisedInfo.max_sum : value
                })
            }
        }
    };

    // для ползунка
    getRange = (object, item, roundingLow, roundingHigh) => {
        const keys = Object.keys(object);
        let arrRange = [];
        let range = {};
        let result = null;

        for (let i = 0; i < keys.length - 1; i++) {
            arrRange.push({ min: object[keys[i]], max: object[keys[i + 1]] })
        }

        for (let j = 0; j < arrRange.length; j++) {

            if (item >= arrRange[j].min && item <= arrRange[j].max || item >= arrRange[j].min && isNaN(arrRange[j].max)) {
                range = (arrRange[j]);
            }
        }

        if (!!range) {
            let roundLow = getRounding(range.min, !isNaN(range.max) ? range.max : range.min + 10, parseInt(roundingLow));
            let roundHigh = getRounding(range.min, !isNaN(range.max) ? range.max : range.min + 10, parseInt(roundingHigh));

            if (roundingLow === 0 && roundingHigh === 0 || item > roundLow && item < range.max && roundingHigh === 0 || item > roundLow && item < roundHigh) {
                result = item
            } else if (item <= roundLow && !isNaN(range.max) || item <= roundLow && isNaN(range.max)) {
                result = range.min
            } else if (item >= roundHigh) {
                result = range.max
            }
        }

        return result
    }

    // для ползунка
    onAfterChangeSlider = (type, objectMarks, items, roundingLow, roundingHigh) => {
        let result = null;

        roundingLow = roundingLow === '' ? 0 : parseInt(roundingLow);
        roundingHigh = roundingHigh === '' ? 0 : parseInt(roundingHigh);

        if (objectMarks && items >= 0) {
            result = this.getRange(objectMarks, items, roundingLow, roundingHigh);

            if (type === 'deferredPayment') {
                if (result !== null) {
                    this.setState({
                        valueRange: result
                    })
                }
            }
        }
    }

    render() {
        let {
            phone,
            sum,
        } = this.state.fields;

        let {
            paymentDeferred,
            isGuest,
            promisedInfo,
            routes
        } = this.props

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/payments-transfers',
                title: "Платежи"
            },
            {
                url: "",
                title: 'Отложенный платеж'
            }
        ];

        let payment_available = promisedInfo && promisedInfo.payment_available

        let duration = promisedInfo && promisedInfo.duration
        // склоняет окончания слов
        const toggleDurationText = this.declOfNum(duration, ['день', 'дня', 'дней']);

        const itemDate = promisedInfo && promisedInfo.expired_day
        // выводит дату нужного формата
        const date = itemDate ? (new Date(itemDate)).toLocaleDateString('ru') : ''

        //получаем нужный нам SEO {}
        let routeSeo = routes && routes.list.find(x => x.sys_name === 'deferredPaymentRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_internet" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        { "name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : "" }
                    ]}>
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>

                        {paymentDeferred && paymentDeferred.map((item) =>
                            <React.Fragment key={item.id}>
                                <h1 className="payments-page__title">{item.title}</h1>

                                {isGuest &&
                                    (<div className="authorization_wrapper">
                                        <div className="authorization_wrapper__text">
                                            Для подключения услуги “Отложенный платеж”, пожалуйста,
                                            <span className="authorization_wrapper__link" onClick={this.props.callLogin}>
                                                авторизуйтесь
                                            </span>
                                        </div>
                                        <span className="authorization_wrapper__img">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6276 18.627 24 12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.627 0 24 5.3724 24 12" fill="#FC692F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7579 20.8L10.3924 19.7474C10.3924 19.7474 10.4171 12.281 10.3924 10.9531C9.34882 10.4177 8.79996 9.31883 8.79996 8.0502C8.79996 6.25519 10.2326 4.80005 12 4.80005C13.7673 4.80005 15.2 6.25519 15.2 8.0502C15.2 9.28307 14.5997 10.3556 13.6038 10.9065C13.603 11.124 13.6022 12.259 13.6022 12.259L12 13.9591L13.6021 15.4707L13.6023 16.3406L12 17.6059L13.6033 18.7194L13.6038 19.5434L11.7579 20.8ZM12.8 6.39996H11.2V7.99996H12.8V6.39996Z" fill="white" />
                                            </svg>
                                        </span>
                                    </div>)
                                }

                                {!isGuest &&
                                    (<React.Fragment>
                                        {promisedInfo && !payment_available &&
                                            (<div
                                                className={`informer-wrapper 
                                                    ${promisedInfo && promisedInfo.expired_day !== '' ? "informer-green" : ''} 
                                                    ${promisedInfo && promisedInfo.info_text !== '' ? "informer-blue" : ''} 
                                                    ${promisedInfo && promisedInfo.balance_info !== '' ? "informer-red" : ''}
                                                `}>
                                                {(promisedInfo && promisedInfo.expired_day !== '') &&
                                                    (<React.Fragment>
                                                        <span className="informer-wrapper__img">
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21.5 13L14.1625 20L10.5 16.5" stroke="#34A632" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#34A632" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <div
                                                            className="informer-wrapper__text"
                                                            dangerouslySetInnerHTML={{ __html: `Услуга “Отложенный платеж” подключена и действует до ${date}` }}
                                                        />
                                                    </React.Fragment>)
                                                }
                                                {(promisedInfo && promisedInfo.info_text !== '') &&
                                                    (<React.Fragment>
                                                        <span className="informer-wrapper__img">
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#0E81E8" stroke-width="2" stroke-miterlimit="10" />
                                                                <path d="M16 9V16H23" stroke="#0E81E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <div
                                                            className="informer-wrapper__text"
                                                            dangerouslySetInnerHTML={{ __html: promisedInfo && promisedInfo.info_text }}
                                                        />
                                                    </React.Fragment>)
                                                }
                                                {(promisedInfo && promisedInfo.balance_info !== '') &&
                                                    (<React.Fragment>
                                                        <span className="informer-wrapper__img">
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 8V24C5 24.5304 5.21071 25.0391 5.58579 25.4142C5.96086 25.7893 6.46957 26 7 26H27C27.2652 26 27.5196 25.8946 27.7071 25.7071C27.8946 25.5196 28 25.2652 28 25V11C28 10.7348 27.8946 10.4804 27.7071 10.2929C27.5196 10.1054 27.2652 10 27 10H7C6.46957 10 5.96086 9.78929 5.58579 9.41421C5.21071 9.03914 5 8.53043 5 8ZM5 8C5 7.46957 5.21071 6.96086 5.58579 6.58579C5.96086 6.21071 6.46957 6 7 6H24" stroke="#FA6600" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M22.5 19.5C23.3284 19.5 24 18.8284 24 18C24 17.1716 23.3284 16.5 22.5 16.5C21.6716 16.5 21 17.1716 21 18C21 18.8284 21.6716 19.5 22.5 19.5Z" fill="#FA6600" />
                                                            </svg>
                                                        </span>
                                                        <div
                                                            className="informer-wrapper__text"
                                                            dangerouslySetInnerHTML={{ __html: promisedInfo && promisedInfo.balance_info }}
                                                        />
                                                    </React.Fragment>)
                                                }
                                            </div>)
                                        }

                                        {(payment_available || (promisedInfo && promisedInfo.expired_day !== '' && !payment_available))
                                            && (
                                                <React.Fragment>
                                                    {((promisedInfo && promisedInfo.min_sum) !== (promisedInfo && promisedInfo.max_sum))
                                                        && (
                                                            <div className="terms-wrapper">
                                                                {payment_available &&
                                                                    (<form className="row-balance deferred_payment_wrap">
                                                                        <div className="deferred_payment_slider_inputs">
                                                                            <h3>Введите сумму отложенного платежа</h3>
                                                                            <div className="deferred_payment_slider">
                                                                                <RangeInput
                                                                                    type={'deferredPayment'}
                                                                                    min={promisedInfo && promisedInfo.min_sum}
                                                                                    max={promisedInfo && promisedInfo.max_sum}
                                                                                    marks={(promisedInfo && promisedInfo.marks) || {}}
                                                                                    stepSlider={10}
                                                                                    dots={true}
                                                                                    value={this.state.valueRange}
                                                                                    valueSvoi={this.state.valueRange}
                                                                                    onChange={this.onChange('XVal')}
                                                                                    // onFocus={this.onFocus('svoiGb')}
                                                                                    onBlur={this.onBlur('XVal')}
                                                                                    onKeyPress={(e) => this.onKeyPress(e)}
                                                                                    onAfterChange={() => this.onAfterChangeSlider('deferredPayment', promisedInfo && promisedInfo.marks, this.state.valueRange, 0, 0)}
                                                                                    // unit={'ГБ'}
                                                                                    valueData={0}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="row-balance-wrap-btn-cell">
                                                                            <div className="row-balance-wrap-btn">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="row-balance-btn"
                                                                                    onClick={this.onSubmit}
                                                                                >
                                                                                    Пополнить
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>)
                                                                }
                                                            </div>
                                                        )}

                                                    {((promisedInfo && promisedInfo.min_sum) === (promisedInfo && promisedInfo.max_sum))
                                                        && (
                                                            <div className="terms-wrapper">
                                                                {payment_available &&
                                                                    (<form
                                                                        className="row-balance row-deferred-payment"
                                                                    >
                                                                        <div className="row-balance-inputs">
                                                                            <h3>Пополнить счет</h3>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-text-wrap">
                                                                                    <Input {...phone} name={phone.name} />
                                                                                </div>
                                                                                <div className="input-text-wrap">
                                                                                    <Input {...sum} name={sum.name} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <button
                                                                            type="submit"
                                                                            className="row-balance-btn"
                                                                            onClick={this.onSubmit}
                                                                        >
                                                                            Пополнить
                                                                        </button>
                                                                    </form>)
                                                                }
                                                            </div>
                                                        )}

                                                    <h3 className="terms-wrapper__info_header">Условия</h3>
                                                    <div className="terms-wrapper__info">
                                                        <div className="terms-wrapper__info-text">Действителен</div>
                                                        <div className="terms-wrapper__info-answer">{promisedInfo && promisedInfo.duration}&nbsp;{toggleDurationText}</div>
                                                    </div>
                                                    <div className="terms-wrapper__info">
                                                        <div className="terms-wrapper__info-text">Сумма платежа</div>
                                                        <div className="terms-wrapper__info-answer">{payment_available ? this.state.valueRange : (promisedInfo && promisedInfo.pay_sum)}&nbsp;₽</div>
                                                    </div>
                                                    <div className="terms-wrapper__info">
                                                        <div className="terms-wrapper__info-text">Стоимость услуги</div>
                                                        <div className="terms-wrapper__info-answer">{payment_available ? ((this.state.valueRange / 100) * 10) : (promisedInfo && promisedInfo.service_amount)}&nbsp;₽</div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </React.Fragment>)
                                }

                                <div className="deferred-payment__block">
                                    <div className="deferred-payment__info" dangerouslySetInnerHTML={{ __html: item.content }} />
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <SmallMenu
                                // при назначении держит открытое меню
                                flag={'deferred-payment'}
                                // при назначении ищет и подчеркивает ссылку
                                flagSubMenu={'deferred-payment'}
                                // title в мобильном меню
                                customTitleMenu={'Отложенный платеж'}
                            />
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        paymentDeferred: state.paymentsTransfers.paymentDeferred,
        routes: state.routes,
        client: state.client,
        clientInfo: state.client.clientInfo,
        isGuest: state.client.isGuest,
        promisedInfo: state.client.promisedInfo,
        promisedError: state.client.promisedError,
        qrText: state.settings.qr_code_description,
        settings: state.settings
    }),
    (dispatch) => {
        return {
            deferredPaymentPageAction: () => dispatch(deferredPaymentPageAction()),
            auth: (data, rememberMe) => dispatch(auth(data, rememberMe)),
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),
            promisedInfoAction: () => dispatch(promisedInfoAction()),
            clientPromisedAction: (data) => dispatch(clientPromisedAction(data)),
            callLogin: () => dispatch(callLogin()),
        }
    }
)(DeferredPayment);













