import React, {Component} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {tariffsList} from "../../actions/tariffs";
import B2bTariffCard from "../../components/Rates/B2bTariffCard";
import {withTariffModal} from "./TariffConnection";

import {Helmet} from "react-helmet";

class Tariffs extends Component {

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        appRoutes: PropTypes.array
    };

    componentWillMount() {
        const {tariffs, getTariffs} = this.props;
        if (!tariffs.length) {
            getTariffs(this.context.selectedRegion.id)
        }
    }

    onConnect = (tariff) => {
        return () => this.props.onClickConnection('tariff-connection', tariff)
    }

    render() {
        const {tariffs} = this.props;
        
        const tariffRoute = this.context.appRoutes.find((item) => item.sys_name === 'tariffsB2BRoute');

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.b2b.find(x => x.sys_name === 'tariffsB2BRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <main className="page__main-holder b2b-page" role="main">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <div className="page__container">
                <h1 className="page__title">Тарифы</h1>
                {tariffs.length ? tariffs.map((tariff) => <B2bTariffCard
                    key={tariff.id}
                    tariffsLink={tariffRoute.route}
                    {...tariff}
                    onClickConnection={this.onConnect(tariff)}
                />) : 'Тарифы не найдены'}
            </div>
        </main>
    }
}

const TariffsWithModal = withTariffModal(Tariffs)

function prepareTariffs(tariffs) {
    return tariffs
        .filter((a) => !!a.rank_categories)
        .sort((a, b) => a.rank_categories < b.rank_categories || (a.rank_categories === b.rank_categories && a.name < b.name) ? -1 : 1)
}

export default connect(
    (state) => ({
        tariffs: prepareTariffs(state.tariffs.tariffsLists),

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getTariffs : (regionId) => dispatch(tariffsList(regionId, null, 'rank_categories', 'b2b')),
        }
    }
)(TariffsWithModal)

export const BusinessTariffsRequests = [
    {
        func: 'getTariffs',
        args: [null, 'rank_categories', 'b2b'],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
];