import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList, routesVacanciesList} from "../../actions/routes";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import ContactsMedia from "../../components/PressCenter/ContactsMedia"
import {contactsList} from "../../actions/contacts";
import {newsList} from "../../actions/news";

import NewsAndEvents from "../../components/PressCenter/NewsAndEvents";
import {emailValidator} from "../../tools/form-helper";
import {infoBlocksList} from "../../actions/infoBlocks";
import {onSubscription} from "../../actions/support";
import {checkCallbackForm} from "../../actions/callback";
import {formsList} from "../../actions/forms";
import Input from "../../components/Constructor/Input";
import CheckBox from "../../components/common/CheckBox";
import Modal from "../../components/common/Modal";

class AllNews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMore: false,
            newsTitle: "Новости",
            mobile: typeof window === "undefined" ? true : window.innerWidth <= 426,
            markupNewsBlock: "basic",
            listProps: false,
            widthWindow: typeof window === "undefined" ? 320 : window.innerWidth,
            diapazon: null,
            valueSearch: '',
            modalEmail: false,
            modalEmailSuccess: false,
            agreementChecked: false,
            fields: {
                email: {
                    typeInput: "text",
                    placeholder: "Введите E-Mail",
                    label: "Email",
                    value: "",
                    name: "email",
                    required: true,
                    validators: [emailValidator],
                    hasError: false,
                    errorText: "Укажите корректный email",
                    dot_orange: true
                },
                firstname: {
                    typeInput: "text",
                    placeholder: "Введите Имя",
                    label: "ИМЯ",
                    value: "",
                    name: "firstname",
                    // required: true,
                    hasError: false,
                    errorText: "Укажите имя",
                    // dot_orange: true
                },
                company: {
                    typeInput: "text",
                    placeholder: "Введите название",
                    label: "СМИ",
                    value: "",
                    name: "company",
                    // required: true,
                    hasError: false,
                    errorText: "Укажите компанию",
                    // dot_orange: true
                },
            }
        };
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    componentWillMount() {
        if (this.props.contactsList && this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("press");
        }

        if ( this.props.routesVacancies.list.length === 0 ){
            this.props.getRoutesVacancies();
        }

        const mobile = this.state.mobile;

        if ((this.props.newsList.news && !!this.props.newsList.news.data && this.props.newsList.news.data.length === 0) || ( this.props.newsList.news &&  !Array.isArray(this.props.newsList.news.data)) || this.props.newsList.news && this.props.newsList.news.data.length === 6 ){
            if (mobile) {
                this.props.getNewsList("?list_type=mobile");
            }else{
                this.props.getNewsList("?list_type=basic");
            }
        }
        if (mobile && this.props.newsList.news && !!this.props.newsList.news.data && this.props.newsList.news.data.length > 6) {
            this.props.getNewsList("?list_type=mobile");
        }
    }

    componentDidMount(){
        if (typeof window !== "undefined") {
            window.addEventListener('resize', this.onResizeNews);
        }

        if (typeof location != "undefined") {
            this.props.loadForm(location.origin + location.pathname, this.context.isB2b ? 'b2b' : '');
        }
        this.props.formsList('mailing')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeNews);
    };
    componentWillReceiveProps(nextProps) {
        if (this.props.callbackForm.recaptchaSiteKey !== nextProps.callbackForm.recaptchaSiteKey) {
            this.createReCaptcha(nextProps.callbackForm.recaptchaSiteKey);
        }
        if ((this.props.support.subscribeSuccess !== nextProps.support.subscribeSuccess) && nextProps.support.subscribeSuccess) {
            this.setState({modalEmail: false, modalEmailSuccess: true})
        }
        if (this.props.support.subscribeError !== nextProps.support.subscribeError) {
            if (nextProps.support.subscribeError != this.props.support.subscribeError) {
                let fields = {...this.state.fields};
                if(nextProps.support.subscribeError.hasOwnProperty('captcha')) {
                    fields['email'].hasError = true
                    fields['email'].errorText = nextProps.support.subscribeError['captcha'];
                } else {
                    for (let attr in fields) {
                        fields[attr].hasError = nextProps.support.subscribeError.hasOwnProperty(attr);
                        if (nextProps.support.subscribeError.hasOwnProperty(attr)) {
                            fields[attr].errorText = nextProps.support.subscribeError[attr];
                        }
                    }
                }
            }
        }
    }

    onResizeNews = (mode) => {
        const ww = window.innerWidth;
        const markupNewsBlock = this.state.markupNewsBlock;
        const currentPage = this.props.newsList.news.pagination.currentPage;

        this.setState({
            mobile: typeof window === "undefined" ? true : window.innerWidth <= 426,
        })

        /* Все внутри этой функции должно быть внутри условия проверки на изменившуюся ширину экрана!
        Иначе на мобильном происходит лишний запуск этой функции - ресайз запускается при прокрутке и автоматическом скрытии адресной строки браузера */

        if ( ww != this.state.widthWindow) {
            this.setState({widthWindow: ww});

            if (ww <= 426) {
                if (markupNewsBlock === "list") {
                    this.setState({
                        listProps: true
                    })
                }
            }
            if (ww >= 426 && markupNewsBlock !== "list") {
                this.setState({
                    listProps: false
                });
            }

            if (mode === 'defaultNews') {
                if (window.innerWidth <= 426) {
                    this.props.getNewsList("?list_type=mobile&page=" + currentPage);
                    if ( markupNewsBlock === "list" ) {
                        this.setState({
                            listProps: true
                        })
                    }
                }
                if (window.innerWidth >= 426 && markupNewsBlock !== "list") {
                    this.setState({
                        listProps: false
                    });
                }
            }

            if (this.state.mobile && this.props.newsList.news && this.props.newsList.news.data.length > 6 && window.innerWidth <= 426) {
                this.props.getNewsList("?list_type=mobile");
            }

            if (!this.state.mobile && this.props.newsList.news && this.props.newsList.news.data.length === 6 && window.innerWidth >= 530) {
                this.props.getNewsList("?list_type=basic");
            }
        }

        if (mode === "defaultNews") {
            if (window.innerWidth <= 426 ) {
                this.props.getNewsList("?list_type=mobile");
            }

            if (!this.state.mobile && window.innerWidth >= 530) {
                this.props.getNewsList("?list_type=basic");
            }
        }
    };

    getToggleNewsList = () => {
        let dataNews = this.props.newsList.news && !!this.props.newsList.news.data.length;
        if ( dataNews === true ) this.props.getNewsList("?list_type=list");
        this.setState({
            markupNewsBlock: "list"
        })
    };

    getToggleNewsSqr = () => {
        let dataNews = this.props.newsList.news && !!this.props.newsList.news.data.length;
        if ( dataNews === true ) this.props.getNewsList("?list_type=basic");
        this.setState({
            markupNewsBlock: "basic"
        })
    };

    getValueSearch = (data) => {
        this.setState({
            valueSearch: data
        })
    }

    // загрузить еще новости
    getMore = () => {
        const currentPage = +this.props.newsList.news.pagination.currentPage;
        const pageCount = +this.props.newsList.news.pagination.pageCount;
        const markupNewsBlock = this.state.markupNewsBlock;
        let currentNextPage = Number(currentPage);

        if ( markupNewsBlock === "list" ) {
            if ( currentPage < pageCount ) {
                currentNextPage++;
            }
            this.props.getNewsList("?list_type=list&page=" + currentNextPage, true, this.state.valueSearch);
        } else if ( !!this.props.newsList.news.pagination && window.innerWidth >= 426) {
            if ( currentPage < pageCount ) {
                currentNextPage++;
            }
            
            // если даные из календаря не получены, то подгружаем новости из всего списка новостей, 
            // если получены, то подгружаем новости из выбранного диапазона
            if (this.state.diapazon === null){
                this.props.getNewsList("?list_type=basic&page=" + currentNextPage, true, this.state.valueSearch);
            } else {
                this.props.getNewsList(this.state.diapazon + '&page=' + currentNextPage, true, this.state.valueSearch);
            }
            
        } else {
            if ( currentPage < pageCount ) {
                currentNextPage++;
            }
            this.props.getNewsList("?list_type=mobile&page=" + currentNextPage, true, this.state.valueSearch);
        }
        this.setState({
            showMore: true
        })
    };

    // получаем строку данных из календаря
    getDiapazon = (data) => {
        this.setState({ diapazon: data })
    };

    // очищаем строку данных из календаря
    resetDiapazon = () => { // -----------------
        this.setState({ diapazon: null })
    };


    createReCaptcha = (siteKey) => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
        script.async = true;
        script.defer = true;
        document.body.appendChild(script)
    }
    onAgreementChange = (name, val) => this.setState((prev) => {
        return {
            agreementChecked: !prev.agreementChecked
        }
    })
    onFieldChange = (name, value) => {
        let fields = {...this.state.fields};

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        let valid = true;
        if (!value) {
            if (name !== 'firstname' && name !== 'company') {
                valid = false
            }
        }
        if (fields[name].hasOwnProperty('validators') && !!fields[name].validators) {
            fields[name].validators.forEach(function (func) {
                if (!func.call(this, fields[name].value)) {
                    valid = false;
                }
            });
        }
        fields[name].hasError = !valid;
        this.setState({fields});
    };
    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };
    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                if (name !== 'firstname' && name !== 'company') {
                    this.setState({
                        fields: {
                            ...this.state.fields,
                            [name]: {
                                ...this.state.fields[name],
                                hasError: !valid
                            }
                        }
                    });
                }
            }
        };
    };
    onSubmit = (e) => {
        e.preventDefault();

        if(this.isValid()) {
            window.grecaptcha
              .execute(this.props.callbackForm.recaptchaSiteKey)
              .then(token => {
                  const fields = {...this.state.fields}

                  this.setState({captchaError: ''})

                  let data = {
                      agreement: this.state.agreementChecked ? 1 : 0,
                      captcha: token,
                      firstname: fields.firstname.value,
                      email: fields.email.value,
                      company: fields.company.value
                  }
                  if(this.props.client.clientInfo && this.props.client.clientInfo.subs_id) data.subs_id = this.props.client.clientInfo.subs_id

                  this.props.onSubscription(data);
              })
        }
    };
    isValid = () => {
        const fields = {...this.state.fields}
        return (
          (fields["email"].value && !fields['email'].hasError) &&
          this.state.agreementChecked
        )
    }

    render() {
        const { regions, location } = this.props;
        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder all-news-header");
        const { newsTitle, listProps, widthWindow } = this.state;
        const selectedRegion = this.context.selectedRegion;
        const route = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "pressCenterRoute");
        const routeAllNews = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "newsRoute");
        let contactsMedia = this.props.contactsList && this.props.contactsList.contacts;
        let dataNews = this.props.newsList.news && this.props.newsList.news.data;
        let propsPagination = this.props.newsList && this.props.newsList.news.pagination;

        let linkBreadcrumbsPressCenter = route && route.link;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + linkBreadcrumbsPressCenter,
                title: "Пресс центр"
            },
            {
                url: "",
                title: "Новости"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'newsRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        const {email, firstname, company} = this.state.fields;
        const mailingForm = this.props.forms && this.props.forms.list.find(x => x.alias === 'mailing_form')

        return (
            <main className="page__main-holder main-press-center" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container motiv-vacancies">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </div>
                            </div>
                            <NewsAndEvents
                                className={ 'all-news-block' }
                                data={ dataNews }
                                title={ newsTitle }
                                showMore={ true }
                                newsAll={ true }
                                clickList={ this.getToggleNewsList }
                                clickSqr={ this.getToggleNewsSqr }
                                getMore={ this.getMore }
                                listProps={ listProps }
                                linkPageNews={ routeAllNews.link }
                                onResizeNews={ this.onResizeNews }
                                propsPagination={ propsPagination }
                                widthWindow={ widthWindow }
                                getDiapazon={this.getDiapazon}
                                resetDiapazon={this.resetDiapazon}
                                getValueSearch={this.getValueSearch}
                            />

                            <a href="https://t.me/motiv_news" className="info-page-container__subscribe-mobile" target="_blank">
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/telegram.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на новости</span>
                                </div>
                            </a>
                            <button
                              className="info-page-container__subscribe-mobile info-page-container__subscribe-email"
                              onClick={() => {
                                  this.setState({modalEmail: true})
                              }}>
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/email.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на рассылку</span>
                                </div>
                            </button>
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <AsideMenu
                                    flag={route.sys_name}
                                    flagSubMenu={routeAllNews.sys_name}
                                    route={this.props.routesVacancies}
                                    customTitleMenu={'Пресс центр'}
                                />
                            </div>

                            <a href="https://t.me/motiv_news" className="info-page-container__subscribe ml" target="_blank">
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/telegram.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на новости</span>
                                </div>
                            </a>
                            <button className="info-page-container__subscribe info-page-container__subscribe-email ml"
                                    onClick={() => {
                                        this.setState({modalEmail: true})
                                    }}>
                                <div className="info-page-container__subscribe-wrapper">
                                    <img src={`${require('../../../icons/email.svg')}`} alt="Подписаться"/>
                                    <span>Подписаться на рассылку</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    {
                      this.state.modalEmail &&
                      <Modal className="u-modal u-modal_active modal-fullscreen modal-email" close={() => {
                          this.setState({modalEmail: false})
                      }}>
                          <div className="content">
                              <h3 className="modal-email__title">{mailingForm && mailingForm.settings.mailing_form_header}</h3>
                              <p className="modal-email__text">{mailingForm && mailingForm.settings.mailing_form_infotext}</p>
                              <div className="modal-email__form">
                                  <form onSubmit={this.onSubmit} className="vacancy-modal-form support-form">
                                      <div className="form-row form-vacancy-row form-support-row">
                                          <div
                                            className="form-vacancy-row--email form-vacancy-label-column form-support-row-item modal-email-form-inner">
                                              <Input {...email}
                                                     onChange={this.onFieldChange}
                                                     name={email.name}
                                                     onFocus={this.onFocus(email.name)}
                                                     onBlur={this.onBlur(email.name)}
                                              />
                                              <Input {...firstname}
                                                     onChange={this.onFieldChange}
                                                     name={firstname.name}
                                                     onFocus={this.onFocus(firstname.name)}
                                                     onBlur={this.onBlur(firstname.name)}
                                              />
                                              <Input {...company}
                                                     onChange={this.onFieldChange}
                                                     name={company.name}
                                                     onFocus={this.onFocus(company.name)}
                                                     onBlur={this.onBlur(company.name)}
                                              />
                                              <div
                                                className="vacancy-modal-btn-block support-send-block modal-email-btn">
                                                  <button
                                                    onClick={this.onSubmit}
                                                    type="submit"
                                                    className={`u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default support-form-send-btn ${!this.isValid() ? 'disabled' : ''}`}>
                                                      Подписаться
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                              <div className="modal-email-agreement">
                                  <CheckBox
                                    name={'agree-feedback'}
                                    // label={(this.props.support.feedbackForm && this.props.support.feedbackForm.settings) ? this.props.support.feedbackForm.settings.feedback_form_b2b_agreement : ''}
                                    label={mailingForm && mailingForm.settings.mailing_form_agreement}
                                    value={this.state.agreementChecked}
                                    onChange={this.onAgreementChange}
                                  />
                              </div>
                              <div className="modal-email-recaptcha">
                                  Этот сайт защищен reCAPTCHA и применяются <div className="hide-br"></div>
                                  <a href="https://policies.google.com/privacy" target='_blank'>Политика
                                      конфиденциальности</a> и <a href="https://policies.google.com/terms"
                                                                  target='_blank'>Условия обслуживания</a> Google
                              </div>
                          </div>
                      </Modal>
                    }
                    {
                      this.state.modalEmailSuccess &&
                      <Modal className="u-modal u-modal_active modal-fullscreen modal-email modal-email-success"
                             close={() => {
                                 this.setState({modalEmailSuccess: false})
                             }}>
                          <div className="content">
                              <img src={`${require('../../../icons/circle-check.svg')}`}
                                   className="modal-email-success__check" alt="Успешно"/>
                              <div className="modal-email-success__title">{mailingForm && mailingForm.settings.mailing_form_success_header}</div>
                              <div className="modal-email-success__text">{mailingForm && mailingForm.settings.mailing_form_success_description}</div>
                              <div
                                className="vacancy-modal-btn-block support-send-block modal-email-btn modal-email-success-btn">
                                  <button
                                    onClick={() => {
                                        this.setState({modalEmailSuccess: false})
                                    }}
                                    type="submit"
                                    className={"u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default support-form-send-btn"}>
                                      Хорошо
                                  </button>
                              </div>
                          </div>
                      </Modal>
                    }
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        contactsList: state.contacts || [],
        newsList: state.news || [],
        regions: state.regions,
        callbackForm: state.callbackForm,
        forms: state.forms,
        client: state.client,
        support: state.support,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getContactsList: (name) => dispatch(contactsList(name)),
            getNewsList: (listType, loadMore, finding) => dispatch(newsList(listType, loadMore, finding)),
            formsList: (group) => dispatch(formsList(group)),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            onSubscription: (data) => dispatch(onSubscription(data)),
            loadForm: (url, businessLine) => dispatch(checkCallbackForm(url, businessLine)),
        }
    }
)(AllNews);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const AllNewsRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getContactsList',
        args: ['press'],
        object: 'contactsList.contacts',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getNewsList',
        args: ['?list_type=basic'],
        object: 'newsList.news',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];