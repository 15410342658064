import React, {Component} from 'react';
import PropsTypes from 'prop-types'
import {connect} from 'react-redux'
import TopBlock from "../../components/Lk/TopBlock";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import Replenishments from "../../components/Lk/Replenishments";
import {details, payments, costsServices} from "../../actions/client"
import OrderHistory from "../../components/Lk/OrderHistory";
import Costs from "../../components/Lk/Costs";
import Expenses from "../../components/Lk/Expenses";

import {Helmet} from "react-helmet";

class Details extends Component {

    constructor(props){
        super(props);

        let tabs = [
            {title: "Пополнения"},
            {title: "Заказанные документы"}
        ];

        let {
            costsServices,
            availableServices
        } = props;

        let availableCostServices = availableServices.indexOf(1322) !== -1;

        if (availableCostServices) tabs = [{title: "Расходы"}, ...tabs];

        this.state = {
            tabs,
            activeTab: 0,
            costs: {
                title: "Заказ детализации на e-mail",
                subtitle: "Детализация&nbsp;&mdash; это подробный отчёт по&nbsp;тратам на&nbsp;каждую операцию (SMS, выходы в&nbsp;интернет и&nbsp;т.д.) за&nbsp;последние три месяца"
            }
        };
    }

    static contextTypes = {
        selectedRegion: PropsTypes.object
    };

    static getDerivedStateFromProps(props, state) {

        if (state.tabs.length < 3) {

            let {
                costsServices,
                availableServices
            } = props;

            let availableCostServices = availableServices.indexOf(1322) !== -1;

            if (availableCostServices) {
                return {...state, tabs: [{title: "Расходы"}, ...state.tabs]}
            }
        }

        return state;
    }

    componentDidMount(){
        if (!this.props.details || !this.props.details.length) {
            this.props.getDetails()
        }
        if (!this.props.payments || !this.props.payments.length) {
            this.props.getPayments()
        }
        if (!this.props.costsServices || !this.props.costsServices.length) {
            this.props.getCostsServices(this.context.selectedRegion.id)
        }
    }

    toggleTab = (i) => {
        return (e) => {
            if (e) e.preventDefault();

            this.setState({activeTab: i})
        }
    };

    renderContent = (tab) => {

        let {
            details,
            detailsMessage,
            payments,
            paymentsMessage,
            costsServices,
            availableServices,
            settings
        } = this.props;

        let availableCostServices = availableServices.indexOf(1322) !== -1;

        switch (tab) {
            case 0:
                return availableCostServices ? <Expenses items={this.state.costs} showModal={this.props.showModal} /> : null;
            // case 0:
            //     return availableCostServices.length ? <Costs showModal={this.props.showModal}
            //                                          services={availableCostServices}
            //                                          onceDetailServiceId={settings.details_service_id}
            //     /> : null;
            case 1:
                return payments.length || paymentsMessage ? <Replenishments items={payments} message={paymentsMessage}/> : null;
            case 2:
                return details.length || detailsMessage ? <OrderHistory items={details} message={detailsMessage}/> : null;
            default:
                return "";
        }
    };

    render(){

        let {tabs, activeTab} = this.state;

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'detailsLKRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);
        
        return <div className="page_lk-details">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <TopBlock title="Детализация и отчеты"/>
            <div className="page__tabs">
                <TabsSwitcher items={tabs} onClickFunc={this.toggleTab} activeTab={activeTab}/>
            </div>
            {this.renderContent(tabs.length === 3 ? activeTab : activeTab  + 1)}
        </div>
    }
}

export default connect(
    state => ({
        details: state.client.details || [],
        detailsMessage: state.client.detailsMessage,
        payments: state.client.payments || [],
        paymentsMessage: state.client.paymentsMessage,
        costsServices: state.client.costsServices || [],
        settings: state.client.settings || {},
        availableServices: state.client.availableServices || [],

        routes: state.routes,
    }),
    dispatch => {
        return {
            getCostsServices: (regionId) => dispatch(costsServices(regionId)),
            getDetails: () => dispatch(details()),
            getPayments: () => dispatch(payments())
        }
    }
)(Details)
