import React, { Component } from 'react';
import Icon from "./Icon";

export default class InputIcon extends Component {

    render() {
        let {
            error,
            className,
            emptyValue,
            value
        } = this.props;

        if (error === null) return null;
        if (value === '' && emptyValue) return null;

        return (
            <React.Fragment>
                {error
                    ? <div className={"tooltip b-input-text__tooltip" + (className ? " " + className : "")}>
                        <span><Icon name={"input_err"} className={"u-icon u-icon-error"} /></span>
                      </div>
                    : <div className={"tooltip b-input-text__tooltip" + (className ? " " + className : "")}>
                        <span><Icon name={"input_arr"} className={"u-icon u-icon-error"} /></span>
                      </div>
                }
            </React.Fragment>
        )
    }
}
