import React, { Component } from 'react'
import PropTypes from "prop-types";
import Icon from "../common/Icon";
import MediaPlayer from "./MediaPlayer";
import Img from "react-lazilyload-img";

export default class AlbumItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    componentDidUpdate(prevProps) {
        const items = this.props.items;
        const stateItems = this.state.items;

        if (items && items.length !== stateItems.length) {
            let newItems = [];
            items.map((item) => {
                if (item.type === "Видеоальбом") {
                    item.content.forEach(videoId => {
                        const idYoutube = /https:\/\/(?:www\.)?rutube\.ru\/video\/([\w\d]{32})\/?.*/gi.exec(videoId.link);
                        if (idYoutube && idYoutube.length > 1) {
                            // Используем idYoutube[1] (второй элемент массива) если он существует
                            videoId.linkPreview = `https://rutube.ru/api/video/${idYoutube[1]}/thumbnail/?redirect=1`;
                        } else {
                            // Если совпадения не найдено, указываем картинку по умолчанию (rutube)
                            videoId.linkPreview = 'https://static.rutube.ru/static/img/png/player-stubs/countdown-stub-bg.png'; // или какое-то другое значение по умолчанию
                        }
                    })
                }
                newItems.push(item)
            })
            this.setState({ items: newItems })
        }

    };

    renderItemsVideo = (type, el, clickItemBlock, openModal) => {
        console.log(el)
        switch (type) {
            case "desktop":
                return <div className="album-video-block" key={el.id} onClick={(e) => { e.stopPropagation(); clickItemBlock("video", el.id) }}>
                    {el.title ? <span className="album-video-block__title" dangerouslySetInnerHTML={{ __html: el.title }} /> : null}
                    <div className="album-video__item-block">
                        {el.content ? el.content.map((videoItem) =>
                            <div className="video__item-block-wrap">
                                <div
                                    key={videoItem.id}
                                    onClick={openModal("video", videoItem.id)}
                                    className="video-item"
                                    style={{
                                        backgroundImage: "url(" + videoItem.linkPreview + ")",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        backgroundSize: "contain"
                                    }}>
                                    <Icon name={"icon_play-media"} className={"icon-video-prev"} />
                                </div>
                                <span className="album-video-block__caption" dangerouslySetInnerHTML={{ __html: videoItem.title }} />
                            </div>
                        ) : null}
                    </div>
                </div>;
            case "mobile":
                return <div className="album-video-block" key={el.id} onClick={(e) => { e.stopPropagation(); clickItemBlock("video", el.id) }}>
                    {el.title ? <span className="album-video-block__title" dangerouslySetInnerHTML={{ __html: el.title }} /> : null}
                    <div className="album-video__item-block">
                        {el.content ? el.content.map((videoItem) =>
                            <div className="video__item-block-wrap">
                                <MediaPlayer
                                    items={videoItem} className={"media-player__mobile"}
                                />
                                <span className="album-video-block__caption" dangerouslySetInnerHTML={{ __html: videoItem.title }} />
                            </div>
                        ) : null}
                    </div>
                </div>;
            default:
                return "";
        }
    };

    renderItemsPicture = (el, clickItemBlock, openModal) => {
        return <div className="album-picture-block" key={el.id} onClick={(e) => { e.stopPropagation(); clickItemBlock("picture", el.id) }}>
            <span className="album-picture-block__title" dangerouslySetInnerHTML={{ __html: el.title }} />
            <div className="album-picture__item-block">
                {el.content ? el.content.map((imgItem) =>
                    <Img
                        key={imgItem.id}
                        // src={ imgItem.smallMiniaturePath }
                        src={imgItem.path}
                        className="picture-item"
                        onClick={openModal("picture", imgItem.id)}
                    />
                ) : null}
            </div>
        </div>
    }

    render() {

        let { items } = this.state;
        let { openModal, clickItemBlock, type, getMore, mediaPagination } = this.props;

        let currentPage = mediaPagination && mediaPagination.currentPage;
        let pageCount = mediaPagination && mediaPagination.pageCount;

        return (<div className="album-video">
            {items.map((el) => el.type === "Видеоальбом" ? this.renderItemsVideo(type, el, clickItemBlock, openModal) : this.renderItemsPicture(el, clickItemBlock, openModal))}
            {currentPage < pageCount ?
                <div className="news-and-events__link-to-news">
                    <div className="more-picture-block" onClick={getMore}>
                        <Icon name={"icon_read-more-news"} />
                        <div className="more-news-block__title" >Показать еще</div>
                    </div>
                </div>
                : null}
        </div>)
    }
}