import React, {Component} from 'react'
import {phoneMask} from '../../tools/mask'
import InputError from "../common/InputError"
import Select from "../common/Select";

export default class UnblockNumberForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            codeword: "",
            error: "",
            propError: ""
        }
    }

    static getDerivedStateFromProps(props, state){
        if (state.propError !== props.error) {
            return {...state, error: props.error, propError: props.error}
        }
        return state;
    }

    onChange = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState({
            codeword: target.value
        })
    };

    onBlur = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState({
            error: target.value ? "" : "Обязательно для заполнения"
        })
    };

    onFocus = () => {

        this.setState({error: ""})
    };

    onSubmit = (e) => {

        e.preventDefault();

        let {
            codeword
        } = this.state;

        this.setState({
            error: codeword ? "" : "Обязательно для заполнения"
        }, () => {
            let {
                error
            } = this.state;

            if (!error) {
                this.props.onSubmit(JSON.stringify({codeword}))
            }
        });
    };

    inputProps = (name) => {

        let {
            codeword
        } = this.state;

        return {
            name: name,
            value: codeword,
            onChange: this.onChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus
        }
    };

    render(){

        let {
            number = "",
            formSettings = {}
        } = this.props;

        let {error} = this.state;

        let {
            unblock_form_description,
            unblock_codeword_comment
        } = formSettings;

        return <div className="b-lk-block b-lk-block_adapt-default">
            <div className="b-lk-block__title">Снятие блокировки номера <span className="no-wrap">{phoneMask(number)}</span></div>
            {unblock_form_description ? <div className="b-lk-block__sub-title">{unblock_form_description}</div> : null}
            <div className="b-lk-block__form">
                <form onSubmit={this.onSubmit}>
                    <div className="b-lk-block__select">
                        <input className={"b-input-text__input"+(error ? " has-error" : "")}
                               placeholder="Введите кодовое слово"
                               {...this.inputProps('codeword')}
                        />
                        <InputError hasError={!!error} errorText={<span dangerouslySetInnerHTML={{__html: error}}/>}/>
                        {unblock_codeword_comment ? <div className="b-lk-block__sub-text">
                            {unblock_codeword_comment}
                        </div> : null}
                    </div>
                    <div className="b-lk-block__btn-cont">
                        <button className="b-lk-block__btn" type="submit">Снять блокировку</button>
                    </div>
                </form>
            </div>
        </div>

    }
}