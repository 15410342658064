import React, { Component } from 'react';
import {connect} from "react-redux";
import { Helmet } from "react-helmet";

class CookieAgreementContainer extends Component {

    constructor(props) {

        super(props);

        this.state = {

        };
    }



    componentWillMount() {

    }

    render() {

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={"Мобильный интернет: тарифы, условия подключения | МОТИВ"}
                    htmlAttributes={{"class": "pp-mod_internet"}}
                />
                <div className="page__container">
                    <h1>
                        ПОЛИТИКА ООО «ЕКАТЕРИНБУРГ-2000» («МОТИВ») по работе с файлами сookie
                    </h1>
                    <p>
                        Для пользования сайтом <a href="https://motivtelecom.ru">https://motivtelecom.ru</a> (далее по тексту - «Сервис»), принадлежащий ООО «ЕКАТЕРИНБУРГ-2000» (ИНН 6661079603, ОГРН 1026605229122 (далее по тексту –«Оператор»), регистрация и авторизация не требуется, если Пользователь является только его посетителем.<br/>
                        Оператор собирает персональные данные посетителей Сервиса в том объёме, который предусмотрен законодательством Российской Федерации, Политикой и Положением об обработке и защите персональных данных Оператора.<br/>
                        В случае авторизации на Сервисе Оператора, например, для получения консультаций и (или) активации услуг, доступных абонентам, для защиты информации, полученной в ходе прохождения процедуры авторизации, используется стандартная технология кодирования, поскольку данные направляются через Интернет.<br/>
                    </p>
                    <h2>1. Определение и терминология</h2>
                    <p>
                        Файл сookie – небольшой фрагмент данных, состоящий из символов, как правило, из букв и цифр, отправленный веб-сервером на устройство пользователя и сохраненный на нём, в начале Интернет-сессии. <br/>
                        Интернет-сессия - все запросы, сделанные Пользователем в конкретный период времени в сети Интернет, в том числе, это серия запросов и (или) переходов, в зависимости от потребностей пользователя. <br/>
                        Веб-браузер (браузер) - программное обеспечение  для отображения содержания веб-сайтов. В глобальной сети браузеры используют для запроса, обработки, манипулирования. Браузер автоматически пересылает файл сookie веб-серверу в составе HTTP-запроса. <br/>
                    </p>
                    <h2>2. Цели сбора информации через файлы cookie</h2>
                    <p>
                        Файлы cookie содержат сведения о действиях пользователя на Сервисе Оператора и иных сайтах, сведения об оборудовании и программном обеспечении пользователя, IP-адрес, дата и время Интернет-сессии. <br/>
                        Файлы cookie нужны для: <br/>
                        <ul>
                            <li>Помощи Пользователю в получении необходимой информации и облегчения работ в обработке заказов Пользователя на Сервисе Оператора.</li>
                            <li>Анализа информации о посещении страниц Пользователем для совершенствования Сервиса Оператора.</li>
                            <li>Повышения защищенности системы безопасности веб-портала в целом и Сервисов Оператора в частности.</li>
                            <li>Совершенствования рекламных и иных продуктов, улучшения качества услуг, которые Оператор предоставляет Пользователям, в том числе своим абонентам и партнёрам.</li>
                            <li>Определения предпочтений Пользователей, предоставления целевой информации по продуктам и услугам Оператора.</li>
                            <li>Предоставления рекламного контента, учитывая интересы Пользователей.</li>
                            <li>Определения количества посетителей Сервиса Оператора и изучения их интересов для повышения его эффективности и для наилучшего понимания интересов его Пользователей.</li>
                        </ul>
                        Файлы cookie обрабатываются Оператором с использованием метрических программ Яндекс.Метрика, top.mail.ru и других.
                    </p>
                    <h2>3. Виды используемых cookie</h2>
                    <p>
                        Сессионные (временные) - существуют только во временной памяти, пока Пользователь находится на Сервисе Оператора. Они обычно удаляются после того, как закрывается страница браузера. Такой вид файлов cookie необходим, чтобы избегать повторного ввода информации. <br/>
                        Постоянные - позволяют идентифицировать устройство Пользователя, посредством которого использовался Сервис Оператора. При возвращении Пользователя на Сервис, они «вспоминают» информацию об устройстве и о действиях, совершенных ранее. Постоянные cookie хранятся на устройстве Пользователя и удаляются в автоматическом режиме через определённое время, установленное в них самих. Также эти файлы cookie могут быть удалены самим Пользователем. Браузер будет передавать сохраненные файлы cookie на сервер при каждом посещении сайта, пока данные не будут удалены. <br/>
                        Обязательные - необходимы для корректной работы веб-портала в целом и Сервиса Оператора в частности. Служб входа в систему и обеспечения безопасности аккаунта Пользователя. Обязательные cookie отвечают за хранение данных авторизации Пользователя (имени и пароля), необходимых для посещения Сервисов, требующих авторизации, например, личного кабинета абонента. <br/>
                        Сторонние - принадлежат другому домену. Создатели сторонних файлов cookie встраивают их на страницы интересующих сайтов, например, в виде рекламных блоков. Таким образом они могут получать данные о посетителях. <br/>
                        Защищённые - такие файлы cookie передаются через шифрованное https-соединение HttpOnly. Они не видны коду браузера и отправляются только на сервер Оператора, что позволяет предотвратить к ним несанкционированный доступ. <br/>
                    </p>
                    <h2>4. Сроки хранения и уничтожения файлов cookie</h2>
                    <p>
                      Хранение и уничтожение файлов сookie полностью автоматизировано. В файлах сookie может устанавливаться дата их удаления, в этом случае они будут автоматически удалены браузером в указанный в них срок. Если дата удаления не указана, то cookie удаляются сразу, как только Пользователь закроет браузер. Указание срока действия файлов сookie позволяет сохранить их более чем на одну Интернет-сессию (постоянные cookie). Например, интернет-магазины могут использовать постоянные cookie для хранения кодов товаров, которые Пользователь только просматривал или поместил в корзину покупок. Даже, если Пользователь закроет браузер, не совершив покупки, то при последующем входе ему не придётся формировать корзину заново. <br/>
                      Хранение cookie также может ограничиваться в зависимости от веб-сервера, домена или поддомена, где они были созданы. <br/>
                      Оператор использует информацию, содержащуюся в файлах cookie, описанных выше видов, которые будут храниться на устройстве Пользователя в течение периода, необходимого для достижения их цели, после чего они будут автоматически удалены с него. Для различных видов файлов cookie срок хранения может отличаться в зависимости от целей, для которых они созданы. <br/>
                    </p>
                    <h2>5. Управление файлами cookie</h2>
                    <p>
                        Большинство интернет-браузеров изначально настроены автоматически принимать cookie. Способы управления cookie содержатся в инструкции браузера. Настроить браузер можно индивидуально, согласно предпочтениям Пользователя. Отключение cookie, которые использует Оператор, может повлиять на работу в Интернете. В частности, Пользователь не сможет получать персональную информацию при посещении Сервисов Оператора. <br/>
                        Если для просмотра и доступа к Сервисам Оператора Пользователем используются различные устройства, например, компьютер, смартфон, планшет и прочие, то на каждом используемом устройстве потребуется индивидуальная настройка браузера для работы с файлами cookie. <br/>
                        Пользователь в любое время может изменить настройки браузера таким образом, чтобы предупреждать, когда файлы данного типа будут отправлены на устройство, запретить (блокировать) cookie либо отказаться от использования Сервисов Оператора. <br/>
                    </p>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({

    }),
    (dispatch) => {
        return {

        }
    }
)(CookieAgreementContainer);