import React, {Component, Fragment} from "react";
import LinkC from "../common/LinkC";
import Icon from "../common/Icon";
import Collapse from "../common/Collapse";
import SmallCollapse from "../Support/SmallCollapse";
import FaqCollapseForm from "../common/FaqCollapseForm";

class ServicesCashback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faq: this.props.cashbackFaq
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.cashbackFaq !== this.props.cashbackFaq) {
      this.setState({faq: this.props.cashbackFaq})
    }
  }

  render() {
    const {
      isGuest,
      packageRemains,
      cashbackService2028,
      cashbackServiceAvailable2028,
      callLogin,
      toggleServiceIsLoading,
    } = this.props;

    return (
        <div className="services_cashback_container">
            <div className="services_cashback_block">
              <div className="services_cashback_block-main">
                <div className="services_cashback_block-main_is-cashback">
                  {
                    this.props.isLoadedForAuth && this.props.cashback ? (
                      <Fragment>
                        {/* Авторизованная зона. Кэшбэк есть 9003956513 */}
                        {
                          cashbackService2028 && this.props.cashback.charge_rest && <Fragment>
                            <h3 className="services_cashback_block-main_is-cashback-title">{this.props.cashback.title_text}</h3>
                            <p className="services_cashback_block-main_is-cashback-subtitle">{this.props.cashback.rest_info}</p>
                            <div className="services_cashback_block-main_is-cashback_progress">
                              <div
                                className="services_cashback_block-main_is-cashback_progress-orange"
                                style={{width: `${Math.floor(this.props.cashback.charge_rest / this.props.cashback.limit * 100)}%`}}
                              />
                              <div className="services_cashback_block-main_is-cashback_progress-left">{this.props.cashback.charge_rest} ₽</div>
                              <div className="services_cashback_block-main_is-cashback_progress-right">{this.props.cashback.limit} ₽</div>
                            </div>
                            <div className="services_cashback_block-main_is-cashback-update-text">{this.props.cashback.cashback_info}</div>
                          </Fragment>
                        }
                        {/**/}

                        {/* Авторизованная зона. Кэшбэка нет 9025900010 */}
                        {
                          !cashbackService2028 && cashbackServiceAvailable2028 &&
                          <Fragment>
                            <h3 className="services_cashback_block-main_is-cashback-title">Кэшбэк не подключен</h3>
                            <p className="services_cashback_block-main_is-cashback-subtitle">
                              Пользуетесь связью как обычно и за каждую потраченную 1000₽ на услуги связи получайте пакет 5 Гб интернета бесплатно
                            </p>
                            <button
                              className={`services_cashback_block-main_not-cashback_btn ${toggleServiceIsLoading ? 'disabled' : ''}`}
                              onClick={!toggleServiceIsLoading ? () => {this.props.activateCashback(true)} : () => {}}
                            >Подключить</button>
                          </Fragment>
                        }
                        {/*  */}

                        {/* Авторизованная зона. Неподходящий тариф 9028742712 */}
                        {
                          !cashbackService2028 && !cashbackServiceAvailable2028 &&
                          <Fragment>
                            <h3 className="services_cashback_block-main_is-cashback-title">
                              Извините, Ваш тариф не участвует в акции "Кэшбэк"</h3>
                            <p className="services_cashback_block-main_is-cashback-subtitle">
                              Для участия в Акции необходимо сменить тариф
                            </p>
                            <LinkC
                              className='services_cashback_block-main_not-cashback_btn services_cashback_block-main_change-tariff-cashback_btn'
                              to={this.props.changeTariffLink}
                            >
                              Сменить тариф
                            </LinkC>
                          </Fragment>
                        }
                        {/*  */}

                        {/* Авторизованная зона. Кэшбэк не найден */}
                        {
                          cashbackService2028 && this.props.cashback.error && <Fragment>
                            <h3 className="services_cashback_block-main_is-cashback-title">Кэшбэк не найден</h3>
                            <p className="services_cashback_block-main_is-cashback-subtitle">
                              Скоро здесь появится счетчик кэшбэка. Обновление происходит 1 раз в сутки, попробуйте зайти позже
                            </p>
                          </Fragment>
                        }
                        {/*  */}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <h3 className="services_cashback_block-main_is-cashback-title">Пожалуйста, авторизуйтесь</h3>
                        <p className="services_cashback_block-main_is-cashback-subtitle">
                          Для просмотра накопленного кэшбэка по Вашему номеру
                        </p>
                        <button
                          className='services_cashback_block-main_not-cashback_btn services_cashback_block-main_guest-cashback_btn'
                          onClick={callLogin}
                        >
                          Авторизоваться
                        </button>
                      </Fragment>
                    )
                  }

                </div>
              </div>
              {
                this.props.isLoadedForAuth && packageRemains &&
                <div className="services_cashback_block-sub">
                  <div className="services_cashback_block-sub-title">Полученные пакеты</div>
                  <div className="services_cashback_block-sub-pockets">
                    <div className="services_cashback_block-sub-pockets-title">{packageRemains.service.title}</div>
                    <div className="services_cashback_block-sub-pockets-date">
                      <div className="services_cashback_block-sub-pockets-data-subtitle">Действует до</div>
                      <div className="services_cashback_block-sub-pockets-data-value">{(new Date(packageRemains.date)).toLocaleDateString('ru')}</div>
                    </div>
                    <div className="services_cashback_block-sub-pockets-date">
                      <div className="services_cashback_block-sub-pockets-data-subtitle">Осталось</div>
                      <div className="services_cashback_block-sub-pockets-data-value">{packageRemains.rest_amount} Гб</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          {/*<div className="services_cashback_more">*/}
          {/*  <h3 className="services_cashback_more_title">Подробнее о кэшбэке</h3>*/}
          {/*  <div className="services_cashback_more-blocks">*/}
          {/*    <div className="services_cashback_more-block">*/}
          {/*      <img src={`${require('../../../images/icons/icon-cashback-1.svg')}`} alt=""/>*/}
          {/*      <div className="services_cashback_more-block_title">Стоимость участия</div>*/}
          {/*      <div className="services_cashback_more-block_text">Стоимость участия абсолютно бесплатная</div>*/}
          {/*    </div>*/}
          {/*    <div className="services_cashback_more-block">*/}
          {/*      <img src={`${require('../../../images/icons/icon-cashback-2.svg')}`} alt=""/>*/}
          {/*      <div className="services_cashback_more-block_title">Условия получения</div>*/}
          {/*      <div className="services_cashback_more-block_text">*/}
          {/*        За каждую потраченную 1000 руб. на услуги связи абонент получает пакет 5 Гб интернета бесплатно на 90 суток.*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="services_cashback_faq">
            <div className="b-support-faq__title">Вопросы и ответы</div>
            <div className="b-support-faq__block">
              <div className="b-small-collapser b-small-collapser_adapt-defoult">
                {
                  this.state.faq.map((cat, index) =>
                    <div id={"faq-category-"+cat.id} key={"faq-category-"+cat.id}>
                      <Collapse
                        key={"faq-category-"+cat.id}
                        title={cat.question}
                      >
                        <div className="services_cashback_faq__block" dangerouslySetInnerHTML={{__html: cat.answer}} />
                      </Collapse>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {
            !isGuest && cashbackService2028 &&
            <div className="services_cashback_reject">
              <button
                className="services_cashback_reject_btn"
                onClick={() => {this.props.showModal('change-rate-off')}}
              >Отказаться от участия</button>
            </div>
          }
        </div>
    )
  }
}
export default ServicesCashback;