import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import LinkC from "../../../js/components/common/LinkC";
import Img from 'react-lazilyload-img';
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import { routesList, routesVacanciesList } from "../../actions/routes";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";
import {contactsAbout} from "../../actions/company";

class LeaderShip extends Component {
    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount() {
        if (this.props.company && typeof this.props.company.contactsAbout === "undefined") {
            this.props.getContactsAbout();
        }
    }

    render() {

        const { company } = this.props;
        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "",
                title: 'Руководство'
            }
        ];

        const { regions, location } = this.props;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'leadershipRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="info-page-container__content info-page-container__leader">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="info-page-container__title">Руководство</h1>
                        <div className="info-page-container__content-left-part-big">

                            {company.contactsAbout && company.contactsAbout.length > 0 ?
                                <div className="leadership-page__wrap-leaders">
                                    {company.contactsAbout.map((el, id) => {
                                        return (
                                            <div key={id} className="leadership-page__leader">
                                                {el.img ?
                                                    <div
                                                        className={"leadership-page__img"}
                                                        style={{
                                                            backgroundImage: `url(${el.img})`,
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center",
                                                            backgroundSize: "cover"
                                                        }}
                                                    />
                                                : null}
                                                {el.fio ?
                                                    <div className="leadership-page__leader-name" dangerouslySetInnerHTML={{__html: el.fio}} />
                                                : null}
                                                {el.position ?
                                                    <div className="leadership-page__leader-position" dangerouslySetInnerHTML={{__html: el.position}} />
                                                : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            : null}

                        </div>
                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <AsideMenu 
                                flag={"aboutRoute"}
                                flagSubMenu={"leadershipRoute"}
                                route={this.props.routesVacancies}
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        regions: state.regions,
        company: state.company,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getContactsAbout: () => dispatch(contactsAbout())
        }
    }
)(LeaderShip);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const LeaderShipRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getContactsAbout',
        args: [],
        object: 'company.contactsAbout',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];
