const initState = { isOk:false, megaMenuList: [] };

export function megaMenu (state = initState, action) {
    switch (action.type) {
        case 'MEGA_MENU_ITEMS_LIST':
            return {...state, isOk: false};
        case 'MEGA_MENU_ITEMS_LIST_SUCCESS':
            return {...state, megaMenuList: action.data, isOk: true};
    }
    return state;
}