import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SwitcherInputNew from "../common/SwitcherInputNew";

class OffersCategories extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    componentWillMount() {

    }

    componentDidMount() {

    }


    render() {
        let {
            data,

            checkboxClick,
            nameData,
            valueData,
            onChangeData,
            nameRouter,
            valueRouter,
            onChangeRouter,
            nameSocial,
            valueSocial,
            onChangeSocial,
            nameVoice,
            valueVoice,
            onChangeVoice,
            nameYoutube,
            valueYoutube,
            onChangeYoutube,
            nameIvi,
            valueIvi,
            onChangeIvi,
            nameHome,
            valueHome,
            onChangeHome,
            currentSmsPack,
            optionSmsArr,
            chOn,
            disabledRqs,
            maxFixedPrice
        } = this.props

        let {
            name,
            price,
            price_discount,
            type,
            unit
        } = data

        return (
            <div className="wrapper">
                <div className="description">
                    <div className="text">{name}</div>
                    <div className="circle"></div>
                    <div className="price_wrapper">
                        {/* {price_discount >= 0 && (<div className="old_price">{price}</div>)} */}
                        <div className="new_price">{price_discount >= 0 ? price_discount : price}&nbsp;{unit && unit.slice(0, 5)}</div>
                    </div>
                </div>
                <div>
                    <SwitcherInputNew
                        checkboxClick={checkboxClick}
                        maxFixedPrice={maxFixedPrice}

                        nameData={nameData}
                        valueData={valueData}
                        onChangeData={onChangeData}

                        nameRouter={nameRouter}
                        valueRouter={valueRouter}
                        onChangeRouter={onChangeRouter}

                        nameSocial={nameSocial}
                        valueSocial={valueSocial}
                        onChangeSocial={onChangeSocial}

                        nameVoice={nameVoice}
                        valueVoice={valueVoice}
                        onChangeVoice={onChangeVoice}

                        nameYoutube={nameYoutube}
                        valueYoutube={valueYoutube}
                        onChangeYoutube={onChangeYoutube}

                        nameIvi={nameIvi}
                        valueIvi={valueIvi}
                        onChangeIvi={onChangeIvi}

                        nameHome={nameHome}
                        valueHome={valueHome}
                        onChangeHome={onChangeHome}

                        currentSmsPack={currentSmsPack}
                        optionSmsArr={optionSmsArr}
                        toggleSmsPack={this.props.toggleSmsPack}

                        chOn={chOn}
                        disabledRqs={disabledRqs}

                        type={type}
                    />
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({

    })
)(OffersCategories);