import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { submitMnpForm } from "../../actions/forms";
import FormField from "./FormField";
// import { getDomainFromSubdomain, setCookie, setIdButton } from "../../tools/tools";
import { addBrackets, checkUtmParams, getCookie, isEmptyObj } from '../../tools/tools';
// import {submitMnpForm} from "../../actions/forms";
import TooltipQuestion from "../common/TooltipQuestion/TooltipQuestion";

class MnpConnectionFormNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                form_name: {
                    value: "тариф “Конструктор”"
                },
                tariff_transfer: {
                    type: "checkbox",
                    name: "tariff_transfer",
                    label: <span dangerouslySetInnerHTML={{ __html: props.settings.agreement_to_consultation }} />,
                    value: false,
                    error: ""
                }
            },
            formValid: false,
            disabledBtn: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        constructorParams: PropTypes.object,
    };

    onChange = (name, value) => {
        let fields = { ...this.state.fields };

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        fields[name].error = fields[name].validate ? fields[name].validate(fields[name].value) : "";

        this.setState({ fields });

        this.validateForm();
    };

    validateForm = () => {
        let fields = { ...this.state.fields };

        let formValid = true;

        // if (fields['phone_number'].validate(fields['phone_number'].value) !== "") {
        //     formValid = false;
        // }
        /*if (fields['email'].validate(fields['email'].value) !== "") {
            formValid = false;
        }*/

        // if (this.props.typeConnectionModal === 'svoi' && fields['tariff_transfer'].value === false) {
        //     formValid = false;
        // }
        if (fields['tariff_transfer'].value === false) {
            formValid = false;
        }

        this.setState({ formValid: formValid });
    };

    renderField = (name, className = false) => {
        let disabled = this.state.fields[name].type === "checkbox" && this.props.activation.inProgress;
        // const disabledBtn = this.state.disabledBtn;
        return <FormField
            {...this.state.fields[name]}
            disabled={disabled}
            onChange={this.onChange}
            className={className}
        />;
    };

    // сбор аналитики MNP
    collectionOfAnalyticsMnp() {
        try {
            ym(5462218, 'reachGoal', 'mnp_form_send');
            // ga('create', 'UA-120539241-1', 'auto');
            // ga('send', 'event', 'forms_sent', 'ga_mnp_form_send');
            VK.Goal('lead');
            var _tmr = window._tmr || (window._tmr = []); _tmr.push({ "type": "reachGoal", "id": 2767574, "goal": "mnp" });
            ttq.track('SubmitForm');

            let { tariffConstructor } = this.props
            let constructor = this.context.constructorParams;
            let recObj = tariffConstructor && tariffConstructor.recalcData;
            let recalcConstructorData = recObj && recObj.constructor;
            let sms_count = isEmptyObj(recalcConstructorData) ? defaultConstructorData.sms_count : recalcConstructorData.sms_count;
            let subs_fee = isEmptyObj(recObj) ? defObj.subs_fee : recObj.subs_fee;
            let { gb, min, youtube_unlim, motiv_voice_unlim, social_unlim, ivi, data_unlim, router, user_cost } = constructor;
            const paramsNewFormsTarifs = {
                name: `MNP Констр ${gb}Гб ${min}минут ${+sms_count} ${youtube_unlim} ${motiv_voice_unlim} ${social_unlim} ${ivi} ${data_unlim} ${router}`,
                price: +user_cost,
            };

            // для GTM
            setDataLayer('newFormsTarifs', paramsNewFormsTarifs);
        } catch (e) {
            console.log(e);
        }
    };

    // непосредственно сама отправка формы
    sendForm = (e) => {
        e.preventDefault();
        this.collectionOfAnalyticsMnp();

        let {
            tariffConstructor,
            userPhoneValue,
            tariffName,
            settings,
            activation
        } = this.props

        let data = new FormData();
        let fields = Object.assign({}, this.state.fields);
        let constructor = this.context.constructorParams;
        let phone = userPhoneValue.replace(/\D/gim, '');

        let recObj = tariffConstructor && tariffConstructor.recalcData;
        let defObj = tariffConstructor && tariffConstructor.defaultData;
        let defaultConstructorData = defObj && defObj.constructor;
        let recalcConstructorData = recObj && recObj.constructor;
        let sms = isEmptyObj(recalcConstructorData) ? defaultConstructorData.sms_count : recalcConstructorData.sms_count;
        let subs_fee = isEmptyObj(recObj) ? defObj.subs_fee : recObj.subs_fee;


        let utm_start = getCookie("utm_start");
        const copy_start_params = getCookie("copy_start_params");
        let utm_finish = checkUtmParams();
        if (typeof utm_start === 'undefined' && !!copy_start_params) {
            utm_start = copy_start_params;
        }

        data.append('phone', phone);
        data.append('operator', activation.mnp.operatorTitle);
        data.append('form_name', fields.form_name.value);
        data.append('region', this.context.selectedRegion.name);
        data.append('constructor_info[id]', settings.constructor_tariff_id);
        data.append('constructor_info[name]', tariffName);
        data.append('constructor_info[min]', constructor.min);
        data.append('constructor_info[traffic]', constructor.gb);
        data.append('constructor_info[sms]', +sms);
        data.append('constructor_info[abon]', subs_fee);
        data.append('constructor_info[social_network_unlim]', constructor.social_unlim);
        data.append('constructor_info[data_unlim]', constructor.data_unlim);
        data.append('constructor_info[motiv_voice_unlim]', constructor.motiv_voice_unlim);
        data.append('constructor_info[youtube_unlim]', constructor.youtube_unlim);
        data.append('constructor_info[ivi]', constructor.ivi);
        data.append('constructor_info[home_region_checked]', constructor.home_region_checked);

        if (constructor.data_unlim) {
            data.append('constructor_info[router]', constructor.router);
        }

        if (!this.props.isGuest && !!utm_start) {
            data.append('utm_start', utm_start);
            data.append('utm_finish', utm_finish);
        }

        this.props.submitForm(data, 'callback_form');
    };

    render() {
        let {
            userPhone,
            settings,
            activation
        } = this.props;

        let operator = activation && activation.mnp;

        const indPhone = settings.switching_to_tariff_note.indexOf('{{switching_to_tariff_msisdn}}');
        const formatTextPhone = (indPhone !== -1) ? `${settings.switching_to_tariff_note.substr(0, indPhone)}<span>${addBrackets(userPhone)}</span>&ensp;${settings.switching_to_tariff_note.substr(indPhone + '{{switching_to_tariff_msisdn}}'.length)}` : settings.switching_to_tariff_note;

        return <div className="mnp_connection">
            {(!isEmptyObj(operator) && operator.StatusCode === 3003) ? <div className="message">Оператор не определен</div>
                : (!isEmptyObj(operator) && operator.statusCode === 1001 && operator.regionId !== this.context.selectedRegion.id) ? <div className="message">Выбран неверный регион</div>
                    : <form
                        onKeyDown={(event) => {
                            // по клику на Enter предотвращаем любые всплытия
                            if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
                                event.preventDefault();
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                            }
                        }}
                    >
                        <div className="information">
                            <div className="information_phone" dangerouslySetInnerHTML={{ __html: formatTextPhone }}>
                            </div>
                            <div className="wrap_information_text">
                                <div className="information_text">
                                    {settings.switching_to_tariff_hint}
                                </div>
                                {settings.switching_to_tariff_tooltip
                                    && (
                                        <div className='sms__wrap_tooltip_information'>
                                            <TooltipQuestion text={settings.switching_to_tariff_tooltip} />
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="tariff_transfer-check">
                            {this.renderField("tariff_transfer")}
                        </div>
                        <div className="form_row btns">
                            <button
                                className="btn_cancel"
                                onClick={this.props.close}
                            >
                                Отмена
                            </button>
                            <button
                                className={`btn_accent ${this.state.formValid ? '' : "disabled"}`}
                                onClick={this.sendForm}
                                type="submit"
                            >
                                Перейти в Мотив
                            </button>
                        </div>
                    </form>
            }
        </div>
    }
}

export default connect(
    state => ({
        isGuest: state.client.isGuest,
        activation: state.activation,
        tariffConstructor: state.tariffConstructor,
        userPhoneValue: state.common.userPhoneValue,
        settings: state.constructor.settings,
    }),
    (dispatch) => {
        return {
            submitForm: (formData, alias) => dispatch(submitMnpForm(formData, alias)),
        }
    }
)(MnpConnectionFormNew);
