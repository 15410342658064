import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icon from '../common/Icon';
import InfoBlock from "../common/InfoBlock";

import losi from "../../../images/content/list-of-services.jpg";

class ListOfServicesMainInfoblock extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        return (
            <div className="list-of-services__infoblocks">
                <h3>Перевести средства абоненту МОТИВ</h3>
                <span>Переводить денежные средства со своего лицевого счета на лицевой счет другого абонента МОТИВ.</span>

                <a href="#" className="list-of-services__all-sevices">Все способы перевода</a>
            </div>
        )
    }
}

export default ListOfServicesMainInfoblock;