import React, { Component } from 'react';
import PropTypes from "prop-types";
import {getCookie} from "../../tools/tools";

class HeaderChooseRegion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openedTrue: false,
            closed: false
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object
    };

    openedTrue = () => {
        this.setState({
            openedTrue: true
        });
    };

    openedFalse = () => {
        this.setState({
            openedTrue: false
        });
    };

    anotherReg = () => {
        this.setState({
            closed: true
        });
    };

    render() {

        let opened = this.props.opened;

        if (typeof document !== "undefined") {
            if (typeof getCookie('regionName') === 'undefined' && !this.state.openedTrue) {
                this.openedTrue();
            }
        }

        if (this.state.closed) return null;

        return (
            <div className={"b-header__choose-region" + (opened || this.state.openedTrue ? " opened" : "")}>
                <div className="b-header__choose-region-text">Вы здесь: {this.context.selectedRegion.name}?</div>
                <div className="b-header__choose-region-buttons">
                    <div className="u-btn u-btn u-btn_default u-btn b-header__choose-region-button-left" onClick={() => {
                        this.openedFalse();
                        this.props.closeCorrectRegion();
                    }}>Да, верно</div>
                    <div className="u-btn u-btn u-btn_white u-btn b-header__choose-region-button-right" onClick={() => {
                        this.anotherReg();
                        this.props.closeCorrectRegionAndDrop();
                    }}>Другой регион</div>
                </div>
            </div>
        )
    }
}

export default HeaderChooseRegion;