import {clientApiUrl, clientLkApiUrl, cookieDomain, esiaUrl} from "./config";
import fetch from "isomorphic-fetch";
import {setCookie} from "./tools";

/**
 * Fetch helper function
 * @param url
 * @param params
 * @returns {Promise<{status: number, data: any, headers: Headers} | {error: any}>}
 */
export function fetchApi(url, params = {}) {
    return fetch(url, params).then(response => {
        return response.json()
            .then(json => {

                var pagination = {};
                if (response.headers.get('X-Pagination-Current-Page')) pagination.currentPage = response.headers.get('X-Pagination-Current-Page');
                if (response.headers.get('X-Pagination-Page-Count')) pagination.pageCount = response.headers.get('X-Pagination-Page-Count');
                if (response.headers.get('X-Pagination-Per-Page')) pagination.perPage = response.headers.get('X-Pagination-Per-Page');
                if (response.headers.get('X-Pagination-Total-Count')) pagination.totalCount = response.headers.get('X-Pagination-Total-Count');

                return {
                    status: response.status,
                    data: json,
                    headers: response.headers,
                    pagination: pagination
                }
            }).catch(error => ({
                status: response.status,
                data: {},
                headers: response.headers
            }));
    }).catch(error => ({
        error: error
    }));
}

/**
 * Fetch to client api
 * @param url
 * @param params
 * @returns {Promise<{status: number, data: any, headers: Headers}|{error: any}>}
 */
export function fetchClientApi(url, params = {}, header = {})
{
    let apiurl = clientApiUrl + url, headers = {...header};
    if (params.token) {
        headers.Authorization = 'Bearer ' + params.token;
        return fetchApi(apiurl, { ...params, headers: headers });
    }

    return fetchApi(apiurl, params);
}

// esia
export function fetchEsiaApi(url, params = {}) {
    let apiurl = esiaUrl + url, headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

    return fetchApi(apiurl, { ...params, headers: headers });
}

/**
 * Fetch to client lk api
 * @param url
 * @param params
 * @returns {Promise<{status: number, data: any, headers: Headers}|{error: any}>}
 */
export function fetchClientLkApi(url, params = {}) {
    let apiurl = clientLkApiUrl + url, headers = params.headers ? params.headers : { 'Accept': 'application/json', 'Content-Type': 'application/json' };

    if (params.token) {
        headers.Authorization = 'Bearer ' + params.token;
        return fetchApi(apiurl, { ...params, headers: headers });
    }

    return fetchApi(apiurl, { ...params, headers: headers });
}

/**
 *
 * @param url
 * @param method
 * @returns {Promise<{status: number, data: Blob, headers: Headers} | {error: *}>}
 */
export function fetchFileApi(url, method = 'GET') {

    let apiurl = clientApiUrl + url;

    return fetch(apiurl, {
        method
    }).then(response => {
        return response.status == 200 ? response.blob()
            .then(value => ({
                status: response.status,
                data: value,
                headers: response.headers
            })) : response.json()
                .then(value => ({
                    status: response.status,
                    data: value,
                    headers: response.headers
                }));

    }).catch(error => ({ error: error.message }));
}

/**
 * Check response
 * @param type
 * @param data
 * @param status
 * @returns {*}
 */
export function checkErrors(type, data, status) {
    switch (status) {
        case 200:
            return { type: type + '_SUCCESS', data: data };
        case 201:
            return { type: type + '_CREATED', data: data };
        case 202:
        case 204:
            return { type: type + '_EMPTY_BODY', data: data };
        case 400:
            return { type: type + '_BAD_REQUEST', data: data };
        case 401:
            let options = {
                'max-age': -1,
                path: '/'
            };
            if (cookieDomain) {
                options.domain = cookieDomain;
            }
            setCookie("access_token", "", options);
            setCookie("refresh_token", "",options);
            setCookie("expires_in", "",options);
            if(type === 'CHECK_LOGIN') return { type: type + '_UNAUTHORIZED', data: data }
            else return { type: 'LOGOUT_SUCCESS'};
        case 403:
            return { type: type + '_FORBIDDEN', data: data };
        case 404:
            return { type: type + '_NOT_FOUND', data: data };
        case 409:
            return { type: type + '_CONFLICT', data: data };
        case 422:
            return { type: type + '_VALIDATION_ERROR', data: data };
        case 423:
            return { type: type + '_BLOCKED', data: data };
        default:
            console.error(status + ": " + data.message + " " + data);
            return { type: type + '_ERROR', data: data };
    }
}

/**
 * Parse errors of data validation response as object
 * @param errors
 * @returns {*}
 */
export function parseErrors(errors) {
    var errorsObject = {};
    errors.map((err) => {
        errorsObject[err.field] = err.message;
    });
    return errorsObject;
}