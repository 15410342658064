import React, {Component} from 'react';
import PriceItem from "../common/PriceItem";

class RatesPriceList extends Component {

    constructor(props) {
        super(props);
    }

    render () {

        let blocks = this.props.blocks;

        return (
            <div className="b-service-inner__price-list-block">
                {blocks.map((item, key) =>
                    <React.Fragment key={key}>
                        {
                            item.title
                                ?   <div className="page__title-3 page__title-3_mobile-less">{item.title}</div>
                                :   ''
                        }
                        <div className="b-service-inner__price-list">
                            <div className="b-price-list b-price-list b-price-list_adapt_default">
                                <div className="b-price-list__wrapper">
                                    <div className="b-price-list__list">
                                        {item.items.map((item, key) =>
                                            <PriceItem item={item} key={key} num={key}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default RatesPriceList;