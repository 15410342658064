import React from 'react';
import {mediaUrl} from '../../tools/config';

export default function InfoBlockAdvantagesPlates({blocks}) {

        return  <div className="page__advantages-plates">
                    <div className="b-advantages-plates b-advantages-plates_adapt_mnp-index">
                        <div className="b-advantages-plates__wrapper">
                            <div className="b-advantages-plates__header"/>
                                <div className="b-advantages-plates__list">
                                    {blocks.map((block) =>
                                    <div key={block.alias} className="b-advantages-plates__item">
                                        <div className="b-advantages-plates__icon">
                                            <img className="b-advantages-plates__icon-self u-icon u-icon_doc" src={mediaUrl+block.imagePath}/>
                                        </div>
                                        <div className="b-advantages-plates__inner">
                                            <div className="b-advantages-plates__title">{block.title}</div>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </div>
                </div>
}