import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function roamingSearch(region, regionId, international) {
    return {
        type: 'ROAMING_SEARCH',
        region,
        regionId,
        international
    }
}

export function* fetchRoamingSearch(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi(
                "/roaming-prices?region=" + encodeURI(action.region) + '&regionId=' + action.regionId + '&international=' + action.international , {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function roamingSearchPlaces(search, international) {
    return {
        type: 'ROAMING_SEARCH_PLACES',
        search,
        international
    }
}

export function* fetchRoamingSearchPlaces(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi(
                "/roaming-places?search=" + encodeURI(action.search) + '&international=' + action.international, {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}


export function callingSearch(fromRegionId, tariffId, whitherRegion) {
    return {
        type: 'CALLING_SEARCH',
        fromRegionId,
        tariffId,
        whitherRegion
    }
}

export function* fetchCallingSearch(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi(
                "/external-call-prices?fromRegionId=" + action.fromRegionId
                + "&tariffId=" + action.tariffId + "&whitherRegion=" + action.whitherRegion,
                {
                    method: 'GET',
                }
            )
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function callingSearchPlaces(fromRegionId, tariffId, search) {
    return {
        type: 'CALLING_SEARCH_PLACES',
        fromRegionId,
        tariffId,
        search
    }
}

export function* fetchCallingSearchPlaces(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi(
                "/external-call-places?fromRegionId=" + action.fromRegionId
                + "&tariffId=" + action.tariffId + "&search=" + action.search,
                {
                    method: 'GET',
                }
            )
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function roamingClear()
{
    return {
        type: 'ROAMING_SEARCH_CLEAR'
    }
}

export function getRoamingPlaceByAlias(alias)
{
    return {
        type: 'ROAMING_PLACE',
        alias
    }
}

export function* fetchRoamingPlaceByAlias(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi(
                "/roaming-place/"+action.alias,
                {
                    method: 'GET',
                }
            )
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function getRoamingMemos(tabId)
{
    return {
        type: 'ROAMING_MEMOS',
        tabId
    }
}

export function* fetchRoamingMemos(action) {
    try {
        const { status, data, error } = yield call(() => {
            return fetchClientApi(
                "/roaming-memos/"+action.tabId,
                {
                    method: 'GET',
                }
            )
        });
        console.log(data)
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}