import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {complain} from "../../actions/support";
import {formsList} from "../../actions/forms";
import {getPage, routesList} from "../../actions/routes";
import MobileNav from "../../components/Support/MobileNav";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import ComplainContent from "../../components/Rates/ComplainContent";
import RightNav from "../../components/Support/RightNav";
import ComplainForm from "../../components/Forms/ComplainForm";

class Complain extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        if (!this.props.support.complainRoute) {
            this.props.getPage('complainRoute', this.context.selectedRegion.id)
        }

        // if (typeof this.props.support.complain === "undefined") {
        //     this.props.getComplain();
        // }

        this.props.getRoutes();

        let form = this.props.forms ? this.props.forms.list.find(x => x.alias === 'appeal_form') : null;

        if (this.props.forms.list.length === 0 || !form) {
            this.props.getForms("support");
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    render() {

        let {
            forms,
            support,
            isGuest,
            clientInfo,
            routes,
            regions,
            location
        } = this.props;
        
        let selectedRegion = this.context.selectedRegion;
        // let complainItem = support && support.complain && support.complain.length ? support.complain[0] : {};
        let form = forms ? forms.list.find(x => x.alias === 'appeal_form') : null;

        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder header-complain");
        let supportRoute = routes ? routes.list.find(x => x.sys_name === 'supportRoute') : null;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + supportRoute.route,
                title: "Поддержка"
            },
            {
                url: "",
                title: "Добро пожаловаться"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'complainRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder complain-page  main-media-bank" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container  motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <RatesBreadcrumbs items={breadcrumbs} />
                                </div>
                            </div>
                            <ComplainContent complainRoute={support && support.complainRoute} />

                            <ComplainForm items={ form } isGuest={ isGuest } clientInfo={ clientInfo } />
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <RightNav />
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        forms: state.forms,
        routes: state.routes,
        isGuest: state.client.isGuest,
        clientInfo: state.client.clientInfo,
        support: state.support,
        regions: state.regions,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getForms: (group) => dispatch(formsList(group)),
            // getComplain: () => dispatch(complain()),
            getPage: (page, region) => dispatch(getPage(page, region))
        }
    }
)(Complain);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const complainRequests = [
    {
        func: 'getForms',
        args: ["support"],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];