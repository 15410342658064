const initState = {isOk:false, list: []};

export function shops(state = initState, action) {
    switch (action.type) {
        case 'SHOPS_LIST':
        case 'CLOSEST_SHOPS_LIST':
            return {...state, isOk: false};
        case 'SHOPS_LIST_SUCCESS':
        case 'CLOSEST_SHOPS_LIST_SUCCESS':
            return {...state, list: action.data, isOk: true};
    }
    return state;
}