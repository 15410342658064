import {getCookie} from "../tools/tools";
import React from "react";
import {parseErrors} from '../tools/api-helper'
import {
    LkPageRequestsAbonent,
    LkPageRequestsDetails,
    LkPageRequestsMyAppeals,
    LkPageRequestsPayments,
    LkPageRequestsSecurity,
    LkPageRequestsServices
} from '../containers/LK/LKPage'
import {obmenGbId} from "../tools/config";

export const initState = {
    authFormError: "",
    fatalError: "",
    clientInfo: {},
    lkMenu: [],
    lkRoutes: [],
    isGuest: !getCookie("access_token"),
    checkReq: true,
    checkLoginError: false,
    loginErrorText: '',
    cashback: {},
    allAppeals: [],
    filteredAppeals: [],
    appealsLoading: true,
    toggleServiceIsLoading: false,
};

function prepareService(item){

    var writeOffPeriod = {
        id: 4,
        measure: "₽/подключение",
        title: "Подключение"
    };

    if (item.payment_interval) {
        switch (item.payment_interval.type) {
            case "month":
                writeOffPeriod = {
                    id: 3,
                    title:"Ежемесячно",
                    measure: "₽/месяц"
                };
                break;
            case "day":
                writeOffPeriod = {
                    id: 2,
                    measure: "₽/сутки",
                    title: "Посуточно"
                };
                break;
        }
    }

    return {
        id: item.id,
        id_billing: item.id,
        name: item.title,
        canSwitch: true,
        canDisable: !!item.can_disable,
        subscription_fee: item.fee,
        activation_fee: item.connection_fee,
        isOn: item.status.id === 1,
        writeOffPeriod,
        slug: item.slug,
        short_description: item.short_description,
        date: item.date
    }
}

function prepareFormsSettings(data) {
    var result = {};
    data.map((item) => {
        result[item.alias] = item.settings;
    });
    return result;
}

export function client(state = initState, action) {

    switch (action.type) {

        case 'AUTH':
            return {...state, authFormError: "", fatalError: ""};
        case 'RECOVERY_PASSWORD':
            return {...state, fatalError: "", successMessage: "", successMessageTitle: ""};
        case 'AUTH_SUCCESS':
            return {...state, isGuest: false};
        case 'RECOVERY_PASSWORD_EMPTY_BODY':
            return {...state, successMessage: "", successMessageTitle: "Пароль отправлен по смс"};
        case 'AUTH_BAD_REQUEST':
            return {...state, authFormError: action.data.message};
        case 'RECOVERY_PASSWORD_BAD_REQUEST':
            return {...state, recoveryFormError: action.data.message};

        case 'AUTH_FORBIDDEN':
            return {...state, authFormError: action.data.message};
        // case 'AUTH_FORBIDDEN':
        //     return {...state, fatalError: action.data.description, fatalErrorTitle: action.data.title};

        case 'AUTH_ERROR':
            return {...state, fatalError: action.data.details, fatalErrorTitle: "Ошибка входа в личный аккаунт"};
        case 'RECOVERY_PASSWORD_ERROR':
            return {...state, fatalError: action.data.details, fatalErrorTitle: "Ошибка восстановления пароля"};

        case 'CLIENT_INFO_SUCCESS':
            let data = {...action.data};
            delete data['tariff'];
            return {...state, clientInfo: {...state.clientInfo, ...data, paymentLink: "https://cell.motivtelecom.ru/sverdlovsk-oblast/card-payment?abnum="+data.username}};

        case "CLIENT_REMAINS_TOTAL_SUCCESS":
            let remainsTotal = [], packageRemainsTotal = [];

            action.data.map((item) => {
                let itemNew;

                switch (item.type) {
                    case "DATA":
                        itemNew = {
                            ...item,
                            title: "Интернет",
                            unit: "Гб",
                            total_amount: item.total_amount % 1024 > 0 ? (item.total_amount/1024).toFixed(1) : item.total_amount/1024,
                            rest_amount: item.rest_amount % 1024 > 0 ? (item.rest_amount/1024).toFixed(1) : item.rest_amount/1024,
                            moved_amount: item.moved_amount % 1024 > 0 ? (item.moved_amount/1024).toFixed(1) : item.moved_amount/1024,
                            order: 1
                        };
                        break;
                    case "VOICE":
                        itemNew = {...item, title: "Звонки", unit: "Мин", order: 2};
                        break;
                    case "SMS":
                        itemNew = {...item, title: "Сообщения", unit: "Смс", order: 3};
                        break;
                    default:
                        itemNew = item;
                }

                if (itemNew.total_amount == -1) {
                    itemNew.total_amount = "Безлимит"
                }

                remainsTotal.push(itemNew)
            });

            remainsTotal.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);});
            
            return {...state, isGuest: false, clientInfo: {...state.clientInfo, remainsTotal, packageRemainsTotal}};

        case 'CLIENT_REMAINS_INFO_SUCCESS':
            var remains = [], packageRemains = [];

            action.data.map((item) => {
                var itemNew;

                switch (item.type) {
                    case "DATA":
                        itemNew = {
                            ...item,
                            title: "Интернет",
                            unit: "Гб",
                            total_amount: item.total_amount % 1024 > 0 ? (item.total_amount/1024).toFixed(1) : item.total_amount/1024,
                            rest_amount: item.rest_amount % 1024 > 0 ? (item.rest_amount/1024).toFixed(1) : item.rest_amount/1024,
                            moved_amount: item.moved_amount % 1024 > 0 ? (item.moved_amount/1024).toFixed(1) : item.moved_amount/1024,
                            order: 1
                        };
                        break;
                    case "VOICE":
                        itemNew = {...item, title: "Звонки", unit: "Мин", order: 2};
                        break;
                    case "SMS":
                        itemNew = {...item, title: "Сообщения", unit: "Смс", order: 3};
                        break;
                    default:
                        itemNew = item;
                }

                if (itemNew.total_amount == -1) {
                    itemNew.total_amount = "Безлимит"
                }

                if(itemNew.cost){
                    remains.push(itemNew)
                } else {
                    if (itemNew.service && itemNew.service.is_tariff_plan) {
                        if (!remains.find((remainItem) => remainItem.type === itemNew.type) && itemNew.total_amount != 0) {
                            remains.push(itemNew)
                        }
    
                    } else {
                        packageRemains.push(itemNew)
                    }
                }
                // if (itemNew.service.is_tariff_plan) {
                //     if (!remains.find((remainItem) => remainItem.type === itemNew.type) && itemNew.total_amount != 0) {
                //         remains.push(itemNew)
                //     }

                // } else {
                //     packageRemains.push(itemNew)
                // }
            });

            remains.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);});
            
            return {...state, isGuest: false, clientInfo: {...state.clientInfo, remains, packageRemains}};
        // //     //TODO: check transferred remains
        case 'CLIENT_REMAINS_HISTORY_SUCCESS':
            var remainsHistory = [], tariff = {...state.clientInfo.tariff};
            action.data.map((item) => {
                var itemNew;
                let subtitle = item.date ? "действуют до "+(new Date(item.date)).toLocaleDateString('ru') : "";

                switch (item.type) {
                    case "DATA":
                        if (item.exchange > 0) {
                            // item.moved_amount = Math.max(0, item.moved_amount - item.exchange);
                            let exchangeRemain = {
                                id: item.id,
                                is_primary: false,
                                notice: "",
                                service_type: "exchange",
                                moved_amount: item.exchange % 1024 > 0 ? (item.exchange/1024).toFixed(1) : item.exchange/1024,
                                unit: "Гб",
                                title: "Обменные минуты на гигабайты",
                                subtitle,
                                type: "DATA"
                            };

                            if (exchangeRemain.moved_amount > 0) {
                                if (tariff.options) {
                                    tariff.options.push(exchangeRemain)
                                } else {
                                    tariff.options = [exchangeRemain];
                                }
                            }

                        }

                        itemNew = {
                            ...item,
                            title: "Интернет",
                            unit: "Гб",
                            moved_amount: item.moved_amount % 1024 > 0 ? (item.moved_amount/1024).toFixed(1) : item.moved_amount/1024
                        };
                        break;
                    case "VOICE":
                        itemNew = {
                            ...item,
                            title: "Звонки",
                            unit: "Мин"
                        };
                        break;
                    case "SMS":
                        itemNew = {
                            ...item,
                            title: "Сообщения",
                            unit: "Смс"
                        };
                        break;
                    default:
                        itemNew = item;
                }

                if (itemNew.moved_amount > 0) {
                    remainsHistory.push(itemNew)
                }

            });
            return {...state, isGuest: false, clientInfo: {...state.clientInfo, remainsHistory, tariff}};
        case 'TARIFF_INFO_SUCCESS':
            let tariff = {...action.data};
            if (tariff.details) {
                if (tariff.details.traffic.indexOf('.') > -1 && (tariff.details.traffic * 10) % 10 === 0) {
                    tariff.details.traffic = Math.round(tariff.details.traffic);
                }
                if (tariff.details.minutes.indexOf('.') > -1 && (tariff.details.minutes * 10) % 10 === 0) {
                    tariff.details.minutes = Math.round(tariff.details.minutes);
                }
            }

            if (state.exchangeTariffCooperations) {
                for (let i = 0; i < state.exchangeTariffCooperations.length; i++) {
                    if (state.exchangeTariffCooperations[i].slug === tariff.slug) {
                        return {...state, clientInfo: {...state.clientInfo, tariff, exchange: {...state.clientInfo.exchange, allowForTariff: true}}};
                    }
                }
            }
            return {...state, clientInfo: {...state.clientInfo, tariff}};
        case 'CHANGE_STATUS':
            return {...state, changeStatusError: ""};
        case 'CHANGE_STATUS_BLOCK_SUCCESS':
        case 'CHANGE_STATUS_UNBLOCK_SUCCESS':
            return {...state, clientInfo: {...state.clientInfo, status: action.data}};

        case 'CHANGE_STATUS_UNBLOCK_FORBIDDEN':
            let attemptsCount = action.data.details && action.data.details.attempts_count;
            return {...state, changeStatusError: attemptsCount > 0 ? action.data.message+" Количество оставшихся попыток: "+attemptsCount : action.data.message};
        case 'CHANGE_STATUS_BLOCK_BAD_REQUEST':
        case 'CHANGE_STATUS_UNBLOCK_BAD_REQUEST':
        case 'CHANGE_STATUS_BLOCK_FORBIDDEN':
        case 'CHANGE_STATUS_BLOCK_ERROR':
        case 'CHANGE_STATUS_UNBLOCK_ERROR':
            return {...state, changeStatusError: action.data.message};

        case 'CHANGE_TARIFF':
            return {...state, changeTariffMessage: "", changeTariffError: ""};
        case 'CHANGE_TARIFF_SUCCESS':
            return {...state, changeTariffMessage: "Ежемесячный платеж будет списан в вместе с абонентской платой", clientInfo: {...state.clientInfo, tariff: action.data}};
        case 'CHANGE_TARIFF_BAD_REQUEST':
        case 'CHANGE_TARIFF_FORBIDDEN':
            return {...state, changeTariffError: action.data.message};
        case 'CHANGE_TARIFF_ERROR':
            return {...state, changeTariffError: "error"};

        case 'CHANGE_PASSWORD':
            return {...state, changePasswordErrors: {}, changePasswordSuccess: "", changePasswordError: ""};
        case 'CHANGE_PASSWORD_EMPTY_BODY':
            return {...state, changePasswordErrors: {}, changePasswordSuccess: "Пароль успешно изменен"};
        case 'CHANGE_PASSWORD_BAD_REQUEST':
            //TODO: change when fix
            return {...state, changePasswordErrors: action.data.details ? parseErrors(action.data.details) : {old_password: action.data.message}, changePasswordSuccess: ""};
        case 'CHANGE_PASSWORD_ERROR':
            return {...state, changePasswordError: action.data.message};

        case 'MY_SERVICES_SUCCESS':
            var onIds = action.data.map((item) => item.id);
            return {
                ...state,
                services: action.data.map((item) => {
                    return {...prepareService(item), isOn: true}
                }),
                banServices: state.banServices ? state.banServices.map((item) => {return {...item, isOn: onIds.indexOf(item.id_billing) != -1}}) : undefined,
                costsServices: state.costsServices ? state.costsServices.map((item) => {return {...item, isOn: onIds.indexOf(item.id_billing) != -1}}) : undefined
            };

        case 'TOGGLE_SERVICES':
            return {...state, toggleServiceMessage: "", toggleServiceError: "", toggleServiceIsLoading: true};

        case 'TOGGLE_SERVICES_SUCCESS':
        case 'TOGGLE_SERVICES_EMPTY_BODY':
            var services = [...state.services];
            var banServices = state.banServices ? [...state.banServices].map((item) => {
                return item.id_billing == action.data.service.id_billing ? {...item, isOn: action.data.on} : item;
            }) : undefined;
            if (action.data.on) {
                services.push({...action.data.service, isOn: true});
            } else {
                services = action.data.clearAfter ? services.map((item) => {return item.id_billing == action.data.service.id_billing ? {...item, isOn: false} : item}) :
                    services.filter((item) => item.id_billing !== action.data.service.id_billing);
            }
            let serviceName = action.data.service.name ? "«"+action.data.service.name+"»" : "";
            return {
                ...state,
                toggleServiceMessage: action.data.on ? "Услуга "+serviceName+" успешно подключена" : "Услуга "+serviceName+" успешно отключена",
                services,
                banServices,
                clearAfter: action.data.clearAfter,
                toggleServiceIsLoading: false
            };

        case 'TOGGLE_SERVICES_BAD_REQUEST':
        case 'TOGGLE_SERVICES_FORBIDDEN':
            return {...state, toggleServiceError: action.data.message, toggleServiceIsLoading: false};
        case 'TOGGLE_SERVICES_ERROR':
            return {...state, toggleServiceError: "error", toggleServiceIsLoading: false};

        case 'ORDER_DETAILS':
            return {...state, detalizationError: "", detalizationSuccess: ""};
        case 'ORDER_DETAILS_EMPTY_BODY':
            return {...state, detalizationError: "", detalizationSuccess: "Запрос успешно отправлен"};
        case 'ORDER_DETAILS_BAD_REQUEST':
        case 'ORDER_DETAILS_FORBIDDEN':
        case 'ORDER_DETAILS_ERROR':
            return {...state, detalizationError: action.data.message, detalizationSuccess: ""};

        case 'MY_DETAILS':
            return {
                ...state,
                detailsMessage: ""
            };

        case 'MY_DETAILS_SUCCESS':
            return {
                ...state,
                details: action.data.map((item) => {
                    switch (item.type) {
                        case "details":
                            item.type = "разовая детализация";
                            break;
                        case "balance_details":
                            item.type = "разовая детализация по балансу";
                            break;
                    }
                    return item
                })
            };

        case 'MY_DETAILS_BAD_REQUEST':
            return {
                ...state,
                detailsMessage: action.data.message
            };

        case 'MY_DETAILS_ERROR':
        case 'MY_DETAILS_NOT_FOUND':
            return {
                ...state,
                detailsMessage: JSON.parse(action.data.details).error_description
            };

        case 'MY_PAYMENTS':
            return {
                ...state,
                paymentsMessage: ""
            };

        case 'MY_PAYMENTS_SUCCESS':
            return {
                ...state,
                payments: action.data
            };

        case 'MY_PAYMENTS_BAD_REQUEST':
            return {
                ...state,
                paymentsMessage: action.data.message
            };

        case 'MY_PAYMENTS_NOT_FOUND':
            return {
                ...state,
                paymentsMessage: action.data.message ? action.data.message : action.data.details
            };

        case 'MY_PAYMENTS_ERROR':
            return {
                ...state,
                paymentsMessage: JSON.parse(action.data.details).error_description
            };

        case 'EXCHANGE_MINS_SUCCESS':
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    exchange: state.clientInfo.exchange ? {...state.clientInfo.exchange, ...action.data} : action.data
                }
            };

        case 'SERVICE_VIEW_SUCCESS':
            if (state.settings && action.data.id_billing == state.settings.exchange_service_id) {
                let allowForTariff = false, exchangeTariffCooperations = [];
                if (action.data.tariffServiceCooperations.length && action.data.tariffServiceCooperations[0].items) {
                    exchangeTariffCooperations = action.data.tariffServiceCooperations[0].items;
                    if (state.clientInfo.tariff) {
                        for (let i = 0; i < action.data.tariffServiceCooperations[0].items.length; i++) {
                            if (action.data.tariffServiceCooperations[0].items[i].slug === state.clientInfo.tariff.slug) {
                                allowForTariff = true;
                                break;
                            }
                        }
                    }
                }
                return {
                    ...state,
                    clientInfo: state.clientInfo.tariff ? {
                        ...state.clientInfo,
                        exchange: state.clientInfo.exchange ? {...state.clientInfo.exchange, allowForTariff} : {allowForTariff}
                    } : {...state.clientInfo},
                    exchangeTariffCooperations
                };
            }
            return state;

        case 'BAN_SERVICES_SUCCESS':
            var onIds = state.services ? state.services.map((item) => item.id_billing) : [];
            return {
                ...state,
                banServices: action.data.map((item) => {return {...item, isOn: onIds.indexOf(item.id_billing) != -1}})
            };

        case 'CHECK_REQ':
            return {
                ...state,
                checkReq: action.data,
            };

        case 'CHANGE_CLIENT_INFO':
            return {
                ...state,
                changeInfoError: '',
                changeInfoSuccess: '',
                checkReq: false,
            };
        case 'CHANGE_CLIENT_INFO_EMPTY_BODY':
            return {
                ...state,
                changeInfoError: '',
                changeInfoSuccess: 'Запрос успешно отправлен'
            };
        case 'CHANGE_CLIENT_INFO_BAD_REQUEST':
        case 'CHANGE_CLIENT_INFO_ERROR':
            return {
                ...state,
                changeInfoError: action.data.message,
                changeInfoSuccess: ''
            };

        case 'EXCHANGE_MINS_SEND':
            return {
                ...state,
                exchangeMinsSuccess: "",
                exchangeMinsError: ""
            };

        case 'EXCHANGE_MINS_SEND_EMPTY_BODY':
            let exchange = {...state.clientInfo.exchange};
            exchange.max_minutes -= action.data.minutes;
            return {
                ...state,
                exchangeMinsSuccess: "Запрос успешно отправлен",
                exchangeMinsError: "",
                clientInfo: {
                    ...state.clientInfo,
                    exchange
                }
            };

        case 'EXCHANGE_MINS_SEND_FORBIDDEN':
        case 'EXCHANGE_MINS_SEND_BAD_REQUEST':
        case 'EXCHANGE_MINS_SEND_ERROR':
            return {
                ...state,
                exchangeMinsSuccess: "",
                exchangeMinsError: action.data.message
            };

        case 'LK_FORMS_SETTINGS_SUCCESS':
            return {
                ...state,
                formsSettings: prepareFormsSettings(action.data)
            };

        case 'LK_MENU_SUCCESS':

            let icons = {
                info: "icon_user-big",
                appeals: "icon_paper",
                tariffs: "icon_slices",
                services: "icon_new_slices",
                // services: "icon_slices",
                security: "icon_security",
                payments: "icon_wallet",
                details: "icon_paper"
            };

            let titles = {
                info: "Абонент и номер",
                appeals: "Мои обращения",
                tariffs: "Текущий тариф",
                services: "Подключенные услуги",
                security: "Безопасность и вход",
                payments: "Настройка оплаты",
                details: "Детализация и отчеты"
            };

            let sort = {
                info: 1,
                appeals: 2,
                tariffs: 3,
                services: 4,
                security: 5,
                payments: 6,
                details: 7
            }
            return {
                ...state,
                lkMenu: action.data.map((item) => {
                    return {
                        ...item,
                        iconName: icons[item.alias],
                        seoTitle: item.title,
                        title: titles[item.alias],
                        sort: sort[item.alias]
                    };
                }),
                lkRoutes: action.data.map((item) => {
                    let route = {
                        exact: true,
                        requests: [],
                        headerClass: "",
                        path: "/:region"+item.link
                    };
                    switch (item.alias) {
                        case "info":
                            route.requestsLk = LkPageRequestsAbonent;
                            break;
                        case "appeals":
                            route.requestsLk = LkPageRequestsMyAppeals;
                            break;
                        case "security":
                            route.requestsLk = LkPageRequestsSecurity;
                            break;
                        case "details":
                            route.requestsLk = LkPageRequestsDetails;
                            break;
                        case "payments":
                            route.requestsLk = LkPageRequestsPayments;
                            break;
                    }
                    return route;
                })
            };
        case 'LK_SETTINGS_SUCCESS':
            return {
                ...state,
                settings: action.data
            };
        case 'COSTS_SERVICES_SUCCESS':
            var onIds = state.services ? state.services.map((item) => item.id_billing) : [];
            return {
                ...state,
                costsServices: action.data.map((item) => {return {...item, isOn: onIds.indexOf(item.id_billing) != -1}})
            };

        case 'AVAILABLE_SERVICES_SUCCESS':
            let availableServices = action.data.map((item) => item.id);
            let availableServicesAll = action.data;

            if (state.services) {
                availableServices = state.services ? [...availableServices, ...state.services.map((item) => item.id_billing)] : availableServices
                availableServicesAll = state.services ? [...availableServicesAll, ...state.services] : availableServicesAll
            }

            return {
                ...state,
                availableServices,
                availableServicesAll,
            };

        case 'CLIENT_PROMISED_INFO_SUCCESS':
            return {...state, promisedInfo: action.data};

        case 'CLIENT_PROMISED_BAD_REQUEST':
        case 'CLIENT_PROMISED_FORBIDDEN':
            return {...state, promisedError: action.data};

        case 'LOGOUT':
            return {...state, fatalError: ""};
        case 'LOGOUT_ERROR':
            return {...state, fatalError: action.data.details, fatalErrorTitle: "Ошибка выхода"};
        case 'LOGOUT_SUCCESS':
        case 'LOGOUT_EMPTY_BODY':
            return {...initState, formsSettings: state.formsSettings, settings: state.settings, isGuest: true};

        case 'CHECK_LOGIN':
            return {...state, checkLoginError: false, loginErrorText: ''};
        case 'CHECK_LOGIN_SUCCESS':
            return {...state, checkLoginError: false, isGuest: false, loginErrorText: ''};
        case 'CHECK_LOGIN_BAD_REQUEST':
        case 'CHECK_LOGIN_FORBIDDEN':
        case 'CHECK_LOGIN_CONFLICT':
        case 'CHECK_LOGIN_ERROR':
        case 'CHECK_LOGIN_UNAUTHORIZED':
            return {...state, checkLoginError: true, isGuest: true, loginErrorText: action.data.message};


        case 'SERVICE_CASHBACK':
            return {...state, cashback: {error: false}};
        case 'SERVICE_CASHBACK_SUCCESS':
            return {...state, cashback: {...action.data, error: false}};
        case 'SERVICE_CASHBACK_NOT_FOUND':
            return {...state, cashback: {error: true}};

        case 'CLEAR_STATE':
            return {
                ...state,
                fatalError: "",
                successMessage: "",
                successMessageTitle: "",
                authFormError: "",
                recoveryFormError: "",
                changeTariffMessage: "",
                changeTariffError: "",
                changePasswordSuccess: "",
                changePasswordError: "",
                changePasswordErrors: {},
                changeStatusError: "",
                toggleServiceMessage: "",
                toggleServiceError: "",
                detalizationError: "",
                detalizationSuccess: "",
                changeInfoError: '',
                changeInfoSuccess: '',
                exchangeMinsSuccess: '',
                exchangeMinsError: '',
                services: state.clearAfter ? state.services.filter((item) => item.isOn !== false) : state.services,
                clearAfter: false,
                promisedError: ""
            };


        case 'ALL_MY_APPEALS':
            return {...state, allAppeals: [], filteredAppeals: [], fatalError: '', appealsLoading: true};
        case 'ALL_MY_APPEALS_SUCCESS':
            return {...state, allAppeals: action.data, filteredAppeals: action.data, fatalError: '', appealsLoading: false};
        case 'ALL_MY_APPEALS_NOT_FOUND':
            return {...state, fatalError: 'Ошибка получения обращений', appealsLoading: false};
        case 'FILTERED_MY_APPEALS':
            return {...state, filteredAppeals: [], fatalError: '', appealsLoading: true};
        case 'FILTERED_MY_APPEALS_SUCCESS':
            return {...state, filteredAppeals: action.data, fatalError: '', appealsLoading: false};
        case 'FILTERED_MY_APPEALS_NOT_FOUND':
            return {...state, fatalError: 'Ошибка получения обращений', appealsLoading: false};
    }

    return state;
}