import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { phoneMask, numberMask } from "../../tools/mask";
import { phoneValidator, emptyValidator } from "../../tools/form-helper";
import {
    paymentsMethodsAction,
    paymentsMethodsSpbAction,
    clearPaymentsMethodsData,
    paymentLimits,
    clearDataPaymentsMethodsSpbAction,
    paymentTypesAction,
} from "../../actions/paymentsTransfers"
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { checkOperator, clearOperatorData } from "../../actions/activation";
import { settingsList } from "../../actions/settings";
import SuccessModal from "../modals/SuccessModal";
import SelectCustom from '../common/SelectCustom/SelectCustom';
import Input from "../Constructor/Input";
import ShareForPayments from '../common/ShareForPayments/ShareForPayments';
import { isMobile } from "react-device-detect";
import appStore from '../../../images/content/app-store.svg';
import googlePlay from '../../../images/content/google-play.svg';
import qr_code_apple from '../../../images/content/qr_code_apple.svg';
import qr_code_google from '../../../images/content/qr_code_google.svg';
import { set } from 'date-fns';
import { userType } from "../../actions/client";

class TopUpBalanceForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formValid: null,
            blockSubmitBtn: true,
            checkBtnSPB: true,
            loading: false,
            resize: false,
            modal: false,
            activeOptions: {},

            fields: {
                form_name: {
                    value: "Пополнить баланс"
                },
                phone: {
                    typeInput: "text",
                    label: <span className="top-up-balance_label" dangerouslySetInnerHTML={{ __html: 'Номер телефона МОТИВ' }} />,
                    name: "phone",
                    value: "",
                    required: true,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: null,
                    errorText: "Укажите корректный номер телефона",
                    dot_orange: false,
                    placeholder: "+7",
                    disabled: false
                },
                sum: {
                    typeInput: "text",
                    label: <span className="top-up-balance_label" dangerouslySetInnerHTML={{ __html: 'сумма, руб.' }} />,
                    name: "sum",
                    value: "",
                    required: true,
                    mask: numberMask(1),
                    validators: [emptyValidator],
                    hasError: null,
                    errorText: "Укажите сумму",
                    dot_orange: false,
                    placeholder: "₽",
                    disabled: false
                },
            },
        }
    }

    static contextTypes = {
        fields: PropTypes.object,
        selectedRegion: PropTypes.object,
    };

    componentDidMount() {
        let {
            isGuest,
            clientInfo,
            limits
        } = this.props

        let regionId = this.context && this.context.selectedRegion.id;
        let clientPhoneValue = clientInfo && clientInfo.username ? clientInfo.username : '';
        let limitValue = limits && limits.payment_initial_sum ? limits.payment_initial_sum : '';

        if (!isGuest && !!clientPhoneValue && !!limitValue && this.state.blockSubmitBtn === true) {
            this.setState({ blockSubmitBtn: false });
        }

        this.searchParams = new URLSearchParams(document.location.search)
        if (this.searchParams.get('phone') && this.searchParams.get('payment')) {
            this.setFieldValue(this.searchParams.get('phone'), this.searchParams.get('payment'))
        }

        if (!isGuest && !!clientPhoneValue && !!limitValue && !(this.searchParams.get('phone') && this.searchParams.get('payment'))) {
            this.setFieldValue(clientPhoneValue, limitValue);
            // this.checkOperator()
        }

        // запрос
        if (!isGuest && !!clientPhoneValue && !limits) {
            this.props.paymentLimits(clientPhoneValue, regionId)
        }

        this.props.paymentTypesAction();

        // this.clearFields();
        this.props.clearPaymentsMethodsData();
        this.props.clearOperatorData();

        window.addEventListener('resize', this.onResize);
        this.onResize();


    }

    componentWillMount() {
        let {
            settings,
            isGuest,
            clientInfo,
            limits
        } = this.props

        let regionId = this.context && this.context.selectedRegion.id;
        let clientPhoneValue = clientInfo && clientInfo.username ? clientInfo.username : '';
        let limitValue = limits && limits.payment_initial_sum ? limits.payment_initial_sum : '';

        if (!isGuest && !!clientPhoneValue && !!limitValue) {
            this.setFieldValue(clientPhoneValue, limitValue);
        }

        if (!settings || !settings.min_payment_amount || !settings.max_payment_amount || !settings.payment_note_success || !settings.payment_note_error) {
            this.props.settingsList()
        }

        // запрос
        if (!isGuest && !!clientPhoneValue && !limits) {
            this.props.paymentLimits(clientPhoneValue, regionId)
        }
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps, prevState) {
        let {
            activation,
            isGuest,
            clientInfo,
            paymentsTransfers,
            limits
        } = this.props

        let regionId = this.context && this.context.selectedRegion.id;
        let clientPhoneValue = clientInfo && clientInfo.username ? clientInfo.username : '';
        let limitValue = limits && limits.payment_initial_sum ? limits.payment_initial_sum : '';
        let phoneValue = this.state.fields.phone.value;
        let sumValue = this.state.fields.sum.value;
        let blockSubmitBtn = this.state.blockSubmitBtn;
        let operator = activation.mnp;

        // добавляем телефон при авторизации, обновляется при перезагрузке страницы
        // разблок кнопки при заполнении поля c телефоном
        if (!isGuest && !!clientPhoneValue !== !!prevProps.clientInfo.username) {
            // очищаем все ошибки и поля при авторизации
            this.clearFields();
            this.props.clearPaymentsMethodsData();
            this.props.clearOperatorData();

            if (!(this.searchParams.get('phone') && this.searchParams.get('payment'))) this.setFieldValue(clientPhoneValue, limitValue)
            else this.setFieldValue(this.searchParams.get('phone'), this.searchParams.get('payment'))

            this.props.paymentLimits(clientPhoneValue, regionId)
        }

        // добавляем сумму если авторизованы
        if ((!isGuest && !!limits !== !!prevProps.limits && !sumValue) && !(this.searchParams.get('phone') && this.searchParams.get('payment'))) {
            this.setFieldValue(clientPhoneValue, limitValue);
        }

        // очищаем поля и ошибки при выходе с авторизованной зоны или при входе
        if (isGuest !== prevProps.isGuest) {
            this.clearFields();

            this.props.clearPaymentsMethodsData();
            this.props.clearOperatorData();
        }

        if (this.state.loading === true && phoneValue && phoneValue.length !== 16) {
            this.setState({ loading: false });
        }

        if (this.state.loading === true && activation && activation.mnp !== {}) {
            this.props.hideLoading();
        }

        // проверяем на наличие апи ошибки и убераем ошибку если инпут не полный
        if (!!paymentsTransfers.errors && phoneValue.length !== 16) {
            this.props.clearPaymentsMethodsData();
            this.props.clearOperatorData();
        }

        // разблок кнопки при заполнении всех инпутов и приходе нужного оператора
        if (phoneValue && sumValue && prevState.blockSubmitBtn && operator.statusCode === 1001 && operator.StatusCode !== 3003 && operator.operatorCode === "mEKATERINBURG") {
            this.setState({ blockSubmitBtn: false });
        }

        // разблокирует кнопку после автозаполнения полей
        if (prevState.fields.phone.value !== phoneValue && prevState.fields.sum.value !== sumValue && blockSubmitBtn === true) {
            this.setState({ blockSubmitBtn: false });
        }

        // блокирует кнопку если приходит ошибка
        if (blockSubmitBtn === false && !!paymentsTransfers.errors && !!phoneValue) {
            this.setState({ blockSubmitBtn: true });
        }

        // блокирует кнопку при пустых инпутах
        if (blockSubmitBtn === false && !phoneValue && !sumValue) {
            this.setState({ blockSubmitBtn: true });
        }
        // блокирует кнопку если номер не полный
        if (blockSubmitBtn === false && phoneValue.length !== 16) {
            this.setState({ blockSubmitBtn: true });
            this.props.clearOperatorData();
        }

        if (((operator.StatusCode === 3003) || (operator.statusCode === 1001 && operator.operatorCode !== "mEKATERINBURG") || activation.isOtherNumber) && !this.state.modal) {
            this.setState({ modal: true });
            this.props.clearOperatorData();
        }

        const isMob = isMobile ? 'MOBILE' : 'DESKTOP';
        const prevPropsIsSubmitOk = prevProps.paymentsTransfers && prevProps.paymentsTransfers.isSubmitOk;
        const propsIsSubmitOk = this.props.paymentsTransfers && this.props.paymentsTransfers.isSubmitOk;
        const errorSpb = !(!!this.props.paymentsTransfers.dataSpb.error_msg);

        // открываем попап с qr кодом если --- мы на десктопе И нет ошибки от сервера у (СПБ метода) И нажата кнопка (ПОПОЛНИТЬ) И способ опдаты СБП И запрос прошел успешно
        if (isMob === 'DESKTOP' && errorSpb && this.state.checkBtnSPB && (this.state.activeOptions && this.state.activeOptions.alias === 'sbp') && (prevPropsIsSubmitOk !== propsIsSubmitOk && propsIsSubmitOk)) {
            this.props.openModal('qrcode_modal');
        }

        // получаем и вставляем нужный объект в селект
        if (this.props.paymentTypes !== prevProps.paymentTypes && this.props.paymentTypes.length) {
            let defaultpaymentType = this.props.paymentTypes.find(i => i.is_default === true);

            if (defaultpaymentType) {
                this.setState({ activeOptions: defaultpaymentType });
            } else {
                this.setState({ activeOptions: this.props.paymentTypes[0] });
            }
        }
    };

    // ловим изменения ширины экрана
    onResize = () => {
        if (window.innerWidth > 768) {
            this.setState({
                resize: true
            })
        } else {
            this.setState({
                resize: false
            })
        }
    };

    hideScroll = () => {
        let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("no-scroll");
            scrollTop = window.pageYOffset;
            document.body.style.position = "fixed";
            document.body.style.top = -scrollTop + 'px';
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            document.body.classList.remove('no-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            window.scroll(0, this.scrollTop);
        }
    };

    clearFields = () => {
        this.setState({
            fields: {
                ...this.state.fields,

                phone: {
                    ...this.state.fields.phone,
                    value: '',
                    hasError: false
                },
                sum: {
                    ...this.state.fields.sum,
                    value: '',
                    hasError: false
                },
            },
        });
    };

    // blockFields = () => {
    //     this.setState({
    //         fields: {
    //             ...this.state.fields,

    //             phone: {
    //                 ...this.state.fields.phone,
    //                 disabled: true,
    //             },
    //         },
    //         blockSubmitBtn: true
    //     });
    // };

    // unlockFields = () => {
    //     let isGuest = this.props.client.isGuest;

    //     if (!isGuest) {
    //         this.setState({
    //             fields: {
    //                 ...this.state.fields,
    //             },
    //             blockSubmitBtn: false
    //         });
    //     } else {
    //         this.setState({
    //             fields: {
    //                 ...this.state.fields,

    //                 phone: {
    //                     ...this.state.fields.phone,
    //                     disabled: false,
    //                 },
    //             },
    //             blockSubmitBtn: false
    //         });
    //     }
    // };

    // добавляет тот же номер, что и при авторизации
    setFieldValue = (clientPhoneValue = '', clientLimitValue = '') => {
        let phoneValue = clientPhoneValue ? phoneMask(clientPhoneValue) : null;
        let sumValue = clientLimitValue ? clientLimitValue.toString() : null;

        this.setState({
            fields: {
                ...this.state.fields,
                phone: {
                    ...this.state.fields.phone,
                    value: phoneValue,
                },
                sum: {
                    ...this.state.fields.sum,
                    value: sumValue,
                },
            }
        });
    };

    // clearValue = () => {
    //     this.setState({
    //         fields: {
    //             ...this.state.fields,
    //             phone: {
    //                 ...this.state.fields.phone,
    //                 value: '',
    //             },
    //             sum: {
    //                 ...this.state.fields.sum,
    //                 value: '',
    //             },
    //         }
    //     });
    // };

    // emptyFields = (name) => {
    //     let fields = {...this.state.fields};
    //     fields[name].hasError = true;
    //     this.setState({fields});
    // };

    onFieldChange = (name, value) => {
        let fields = { ...this.state.fields };
        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;

        this.setState({ fields });

        if (!!fields["phone"].value) {
            this.setState({
                formValid: true
            });
        } else {
            this.setState({
                formValid: false
            });
        }
        if (!!fields["sum"].value) {
            this.setState({
                formValid: true
            });
        } else {
            this.setState({
                formValid: false
            });
        }

        // удаляем ошибку и сменяем состояние кнопки при изменении значения в поле sum 
        if (name === "sum") {
            this.setState({
                blockSubmitBtn: false,
            });
            this.props.clearPaymentsMethodsData();
        }
    };

    // получаем результат выбранной опции из селекта ВЫБЕРИТЕ СПОСОБ ОПЛАТЫ
    onChangeCustom = (data) => {
        this.setState({
            activeOptions: data,
            blockSubmitBtn: false, //разблокируем кнопку
        });
        // удаляем ошибку
        this.props.clearPaymentsMethodsData();
        this.props.clearDataPaymentsMethodsSpbAction();
    }

    // отправка формы
    onSubmit = (e) => {
        e.preventDefault();

        const { activeOptions } = this.state;

        let fields = this.state.fields;
        let regionId = this.context.selectedRegion.id;

        let forward_url = 'https://motiv.me/payments-transfers-forward';
        let error_url = 'https://motiv.me/payments-transfers-error';

        let data = new FormData();

        data.append('phone', fields.phone.value.replace(/\D/gim, '').slice(1));
        data.append('sum', +fields.sum.value);

        const isMob = isMobile ? 'MOBILE' : 'DESKTOP';

        // Система быстрых платежей
        if (activeOptions && activeOptions.alias === 'sbp') {
            data.append('mode', isMob);

            this.setState({
                checkBtnSPB: true,
            });

            this.props.paymentsMethodsSpbAction(data, regionId, isMob, true);
        }
        // Банковская карта
        if (activeOptions && activeOptions.alias === 'bank_card') {
            data.append('forward_url', forward_url);
            data.append('error_url', error_url);

            this.props.paymentsMethodsAction(data, regionId);
        }
    };

    // по клику на кнопку ПОДЕЛИТЬСЯ
    onOpenShare = () => {
        this.props.clearDataPaymentsMethodsSpbAction();

        setTimeout(() => {
            let fields = this.state.fields;
            let regionId = this.context.selectedRegion.id;

            let data = new FormData();

            data.append('phone', fields.phone.value.replace(/\D/gim, '').slice(1));
            data.append('sum', +fields.sum.value);
            data.append('mode', 'MOBILE');

            this.setState({
                checkBtnSPB: false,
            });

            this.props.paymentsMethodsSpbAction(data, regionId, 'MOBILE', false);
        }, 100);
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    onBlur = (name) => {
        return () => {
            let field = this.state.fields[name];

            if (field.hasOwnProperty('validators')) {
                let valid = true;

                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }

            if (name === 'phone' && this.state.fields.phone.value.length === 16) {
                this.checkOperator()
            }
            if (this.state.fields.phone.value.length < 16) {
                this.props.clearOperatorData();
                this.props.clearPaymentsMethodsData();
            }

            if (this.state.fields.phone.value === "" || this.state.fields.sum.value === "") {
                this.setState({ blockSubmitBtn: true });
            }

            if (this.state.fields.phone.value.length === 16 && this.state.fields.sum.value && this.state.blockSubmitBtn === true) {
                this.props.clearPaymentsMethodsData();
                this.props.clearOperatorData();
            }

            this.props.clearDataPaymentsMethodsSpbAction();
        };
    };

    checkOperator = () => {
        let data = {
            phone: this.state.fields.phone.value.replace(/\D/gim, ''),
        };
        // this.props.checkOperator(data);
        this.props.userType(data.phone);

        this.setState({
            loading: true
        });
        this.props.showLoading()
    };

    renderModal = () => {
        let settings = this.props.settings
        let paymentNoteSuccess = settings && settings.payment_note_success
        let paymentNoteError = settings && settings.payment_note_error
        let paymentNoteSuccessTilte = paymentNoteSuccess && paymentNoteSuccess.title
        let paymentNoteSuccessDescription = paymentNoteSuccess && paymentNoteSuccess.description
        let paymentNoteErrorTilte = paymentNoteError && paymentNoteError.title
        let paymentNoteErrorDescription = paymentNoteError && paymentNoteError.description

        let forwardHash = '';
        let errorHash = '';
        if (typeof document !== "undefined") {
            let hash = window.location.hash
            forwardHash = hash.includes('#forward');
            errorHash = hash.includes('#error');
        }

        let resize = this.state.resize
        let titleNotMotivClient = "Этот номер не обслуживается в Мотив"
        let textNotMotivClient = `Вы можете пополнить баланс другого оператора со своего номера мотив через сайт <a href="https://motivpay.ru/" target="_blank">МОТИВ.Pay</a> или с помощью приложения в своем телефоне.`
        let motivPay = <React.Fragment>
            {resize
                ? <div className={"mobile-apps mobile-apps__top-up-balance"}>
                    <div className={"mobile-apps__img"}><img src={qr_code_apple} alt="" role="presentation" /></div>
                    <div className={"mobile-apps__img"}><img src={qr_code_google} alt="" role="presentation" /></div>
                </div>
                : <div className={"mobile-apps mobile-apps__top-up-balance"}>
                    <a href="https://itunes.apple.com/ru/app/payjet/id965077720?mt=8" target="_blank">
                        <img src={appStore} alt="" role="presentation" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.isimplelab.isimplemobile.payjet" target="_blank">
                        <img src={googlePlay} alt="" role="presentation" />
                    </a>
                </div>
            }
        </React.Fragment>

        if (this.state.modal) {
            this.hideScroll();
            return <SuccessModal
                title={titleNotMotivClient}
                text={textNotMotivClient}
                icon={false}
                btnText="ОК"
                close={this.closeModal}
                payments
                motivPay={motivPay}
                typeWidth={'md'}
                className={"u-modal u-modal_active u-modal-top tariff-connection-modal modal-flex-center"}
            />;
        }

        if (forwardHash) {
            this.hideScroll();
            return <SuccessModal
                title={paymentNoteSuccessTilte}
                text={paymentNoteSuccessDescription}
                btnText="Ок"
                close={this.closeModal}
                payments
            />;
        }
        if (errorHash) {
            this.hideScroll();
            return <SuccessModal
                title={paymentNoteErrorTilte}
                text={paymentNoteErrorDescription}
                icon={false}
                btnText="ОК"
                close={this.closeModal}
                payments
            />;
        }
    };

    closeModal = (e) => {
        let basicUrl = window.location.origin + window.location.pathname;

        if (e) e.preventDefault();

        this.setState({ modal: false });
        this.showScroll();
        history.pushState(null, null, basicUrl);
        this.props.clearOperatorData();
    };

    // отображает текстовый блок
    informTextContent = (type) => {
        let {
            settings,
            paymentsTransfers,
        } = this.props


        let regionId = this.context.selectedRegion.id;
        let allMinPaymentAmount = settings && settings.min_payment_amount;
        let allMaxPaymentAmount = settings && settings.max_payment_amount;
        // Number(i.region_id) переводим строку в число
        let min_payment_amount = allMinPaymentAmount && allMinPaymentAmount.find(i => Number(i.region_id) === regionId);
        let max_payment_amount = allMaxPaymentAmount && allMaxPaymentAmount.find(i => Number(i.region_id) === regionId);

        let errorObj = paymentsTransfers && paymentsTransfers.errors
        // вытягиваем из объекта значение без имеющегося ключа
        let errorTextApi = errorObj && errorObj[Object.keys(errorObj)[0]];
        const errorSpb = paymentsTransfers && paymentsTransfers.dataSpb && paymentsTransfers.dataSpb.error_msg;

        return (
            <div className={`top-up-balance__callout ${type}`} style={{ visibility: !this.state.fields.phone.hasError && !errorTextApi && !errorSpb ? 'visible' : 'hidden' }}>
                <div className="top-up-balance__callout_text">
                    Минимальная сумма платежа - {min_payment_amount && min_payment_amount.price} руб.
                    Максимальная - {max_payment_amount && max_payment_amount.price} руб.
                </div>
            </div>
        );
    }

    render() {

        let {
            phone,
            sum,
        } = this.state.fields;

        let {
            blockSubmitBtn,
            activeOptions
        } = this.state;

        let {
            paymentsTransfers,
            activation,
        } = this.props

        let operator = activation.mnp;

        let errorObj = paymentsTransfers && paymentsTransfers.errors
        // вытягиваем из объекта значение без имеющегося ключа
        let errorTextApi = errorObj && errorObj[Object.keys(errorObj)[0]];
        const errorSpb = paymentsTransfers && paymentsTransfers.dataSpb && paymentsTransfers.dataSpb.error_msg;

        const options_spb = [
            { value: 'spb_1', label: 'Система быстрых платежей', icon_name: 'icon_spb_1' },
            { value: 'spb_2', label: 'Банковская карта', icon_name: 'icon_spb_2' },
        ];

        return (
            <div className="top-up-balance__container">
                <div className="top-up-balance__content">
                    <h3 className="content-page__title">Пополнить баланс</h3>
                    <form
                        onSubmit={this.onSubmit}
                        autoComplete="off"
                    >
                        <div className="row-balance">
                            <div className="row-balance-inputs">
                                <div className="top-up-balance_input-wrapper">
                                    <div className="input-text-wrap top-up-balance_wrap_inp">
                                        <Input {...phone}
                                            onChange={this.onFieldChange}
                                            name={phone.name}
                                            onFocus={this.onFocus(phone.name)}
                                            onBlur={this.onBlur(phone.name)}
                                            operator={operator}
                                            balanceKey={true}
                                            errorTextApi={errorTextApi}
                                        />
                                    </div>
                                    <div className="input-text-wrap top-up-balance_wrap_inp mr0">
                                        <Input {...sum}
                                            onChange={this.onFieldChange}
                                            name={sum.name}
                                            onFocus={this.onFocus(sum.name)}
                                            onBlur={this.onBlur(sum.name)}
                                            errorTextApi={errorTextApi}
                                        />
                                    </div>
                                </div>
                                {(errorTextApi || errorSpb) && <div className="top-up-balance__api-error">{errorTextApi || errorSpb}</div>}
                            </div>
                        </div>

                        <div className="top-up-balance__wrap_callout">
                            {this.informTextContent('desctop')}

                            <div className="top-up-balance__wrap_select_and_btn">
                                <div className="top-up-balance__wrap_select">
                                    <span className="top-up-balance_label" dangerouslySetInnerHTML={{ __html: 'Способ оплаты' }} />
                                    <SelectCustom
                                        options={this.props.paymentTypes}
                                        changeCustom={this.onChangeCustom}
                                        defaultPaymentType={activeOptions}
                                        withoutArrow={true}
                                    />
                                </div>

                                <div className="row-balance-wrap-btn-cell">
                                    <div className="top-up-balance-wrap-double-btn">
                                        <div className="top-up-balance-wrap-btn">
                                            {this.state.activeOptions.alias === "bank_cart_new" ?
                                                <a href='https://pay-old.motivtelecom.ru/sverdlovsk-oblast/card-payment'>
                                                    <button
                                                        type="button"
                                                        id="top-up-balance__submit"
                                                        className={`row-balance-btn ${this.state.blockSubmitBtn ? "disabled" : ""} ${errorTextApi ? "move" : ""}`}
                                                    >
                                                        Пополнить
                                                    </button>
                                                </a>
                                                :
                                                <button
                                                    type="submit"
                                                    id="top-up-balance__submit"
                                                    className={`row-balance-btn ${this.state.blockSubmitBtn ? "disabled" : ""} ${errorTextApi ? "move" : ""}`}
                                                    onClick={this.onSubmit}
                                                >
                                                    Пополнить
                                                </button>
                                            }
                                        </div>

                                        {this.informTextContent('mobile')}

                                        <div className="top-up-balance-wrap-share">
                                            <ShareForPayments
                                                onOpenShare={this.onOpenShare}
                                                data={paymentsTransfers.dataSpb}
                                                phone={phone.value}
                                                sum={sum.value}
                                                disable={!(phone.value && sum.value && (activeOptions && activeOptions.alias === 'sbp'))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        paymentsTransfers: state.paymentsTransfers,
        limits: state.paymentsTransfers.paymentLimits,

        activation: state.activation,
        clientInfo: state.client.clientInfo,
        isGuest: state.client.isGuest,
        settings: state.settings,
        paymentTypes: state.paymentsTransfers.paymentTypes
    }),
    (dispatch) => {
        return {
            paymentsMethodsAction: (data, regionId) => dispatch(paymentsMethodsAction(data, regionId)),
            paymentsMethodsSpbAction: (data, regionId, mode, transition) => dispatch(paymentsMethodsSpbAction(data, regionId, mode, transition)),
            clearDataPaymentsMethodsSpbAction: () => dispatch(clearDataPaymentsMethodsSpbAction()),
            paymentLimits: (data, regionId) => dispatch(paymentLimits(data, regionId)),
            paymentTypesAction: (data) => dispatch(paymentTypesAction(data)),

            checkOperator: (formData, params) => dispatch(checkOperator(formData, params)),
            clearOperatorData: () => dispatch(clearOperatorData()),
            showLoading: () => dispatch(showLoading()),
            hideLoading: () => dispatch(hideLoading()),
            settingsList: () => dispatch(settingsList("min_payment_amount;max_payment_amount;payment_note_success;payment_note_error")),
            clearPaymentsMethodsData: () => dispatch(clearPaymentsMethodsData()),
            userType: (phone) => dispatch(userType(phone)),
        }
    }
)(TopUpBalanceForm);