import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Icon from "../common/Icon";
import InfoblockLink from "./InfoblockLink";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import RuTubeEmbed from "../common/RutubeEmbed";

function InfoBlockHomeBigBannerWithMedia({ blocks, videos }) {
    const slider = React.createRef();
    const [dots, setDots] = useState([]);
    const [previous, setPrevious] = useState(null);
    const [current, setCurrent] = useState(0);
    const [players, setPlayers] = useState({});
    const [canPlay, setCanPlay] = useState(false);
    let interval;

    const sendMessageToPlayer = (player, message) => {
        player.contentWindow.postMessage(JSON.stringify(message), '*');
    }

    const checkAllPlayersReady = () => {
        return !isMobile && videos && videos.length && videos.length === Object.keys(players).length;
    }

    const checkCanPlay = () => {
        const slider = document.getElementById("video-slider");
        if (slider && canPlay !== window.innerHeight + window.scrollY > slider.offsetTop) {
            setCanPlay(window.innerHeight + window.scrollY > slider.offsetTop);
        }
    }

    useEffect(() => {
        if (isMobile && previous !== null && players[previous].getPlayerState() === 1) {
            players[previous].pauseVideo();
            players[current].playVideo();
        }

        if (checkAllPlayersReady()) {
            if (canPlay) {
                if (previous !== null) {
                    sendMessageToPlayer(players[previous], { type: 'player:pause', data: {} })
                } else {
                    sendMessageToPlayer(players[current], { type: 'player:mute', data: {} })
                }
                sendMessageToPlayer(players[current], { type: 'player:play', data: {} })
            } else {
                sendMessageToPlayer(players[current], { type: 'player:pause', data: {} })
            }
        }
    }, [canPlay, players, previous, current])

    useEffect(() => {
        if (typeof window !== 'undefined' && checkAllPlayersReady()) {
            checkCanPlay();
            window.addEventListener('scroll', checkCanPlay)
            document.addEventListener('onscroll', checkCanPlay)
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('scroll', checkCanPlay)
                document.removeEventListener('onscroll', checkCanPlay)
            }
        }
    }, [players, canPlay]);

    useEffect(() => {
        if (videos) {
            let newDots = [{ active: true }];
            for (var i = 1; i < videos.length; i++) {
                newDots.push({ active: false })
            }
            setDots(newDots);
        }
    }, [videos])

    const settings = {
        dots: false,
        infinite: false,
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        draggable: false,
        beforeChange: (current, next) => changeDot(current, next)
    }

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 2,
            iv_load_policy: 3,
            loop: 1,
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
            enablejsapi: 1,
            playsinline: 1
            // origin: window.location.origin
        }
    };

    const playSlider = () => {
        interval = setInterval(() => {
            slider.current && slider.current.slickGoTo(current === videos.length - 1 ? 0 : current + 1)
        }, 5000);
    };

    const pauseSlider = () => {
        clearInterval(interval)
    };

    const slickTo = (e) => {
        let idx = e.target.getAttribute('data-index');
        slider.current.slickGoTo(idx);
    }

    const changeDot = (prev, idx) => {
        let newDots = [...dots];
        newDots.forEach(function (el) {
            el.active = false;
        });
        newDots[idx].active = true;
        setDots(newDots);

        setPrevious(prev);
        setCurrent(idx);
    }

    const extractRutubeVideoId = (link) => {
        const regex = /https:\/\/rutube\.ru\/video\/([\w\d]{32})\//;
        const match = regex.exec(link);
        return match ? match[1] : null;
    }

    return videos && videos.length ? <div id="video-slider">
        {blocks.map((block) => <div className="page__banner-offer page__banner-media" key={block.alias}>
            <div className="b-banner-offer b-banner-offer_adapt_default">
                <div className="b-banner-offer__wrapper">
                    <div className="b-banner-offer__text-sector">
                        <div className="b-banner-offer__inner-area">
                            <div className="b-banner-offer__title">{block.title}</div>
                            <div className="b-banner-offer__text">{block.text}</div>
                        </div>
                        <div className="b-banner-offer__action-area">
                            <InfoblockLink className={"b-banner-offer__link"} item={block}>
                                {block.link_title}
                                {/*<Icon className="b-banner-offer__link-icon u-icon u-icon_arr-circled" name="icon_arr-circled"/>*/}
                            </InfoblockLink>
                        </div>
                    </div>
                    <div className="b-banner-offer__img-sector">
                        <div className="b-service-slider b-service-slider_adapt_default js-init">
                            <div className="b-service-slider__wrapper">
                                <div>
                                    <Slider ref={slider} {...settings}>
                                        {videos ? videos.map((block, index) =>
                                            <div className="carousel-about-company-video" key={index}>
                                                {extractRutubeVideoId(block.link) !== null ?
                                                    <RuTubeEmbed
                                                        onReady={(e) => {
                                                            setPlayers(prevPlayers => ({
                                                                ...prevPlayers,
                                                                [index]: e.target
                                                            }))
                                                            if (isMobile) {
                                                                e.target.unMute()
                                                            }
                                                        }}
                                                        containerClassName="carousel-about-company-video__player"
                                                        videoId={extractRutubeVideoId(block.link)}
                                                        opts={opts}
                                                        onPlay={pauseSlider}
                                                        onEnd={playSlider}
                                                    /> :
                                                    null}
                                            </div>
                                        ) : null}
                                    </Slider>
                                    <button className={'slick-arrow slick-prev'} onClick={() => current === 0 ? slider.current.slickGoTo(videos.length - 1) : slider.current.slickPrev()}>prev</button>
                                    <button className={'slick-arrow slick-next'} onClick={() => current === videos.length - 1 ? slider.current.slickGoTo(0) : slider.current.slickNext()}>next</button>
                                </div>
                                <div className="b-service-slider__controls b-service-slider__controls-press-center">
                                    <div style={{ height: '25px' }} />
                                    <div className="b-service-slider__dots b-service-slider__dots-press-center">
                                        <ul className="slick-dots" role="tablist">
                                            {dots.map((dot, index) =>
                                                <li className={dot.active ? "slick-active" : ""} role="presentation" key={index}>
                                                    <button type="button" onClick={slickTo} data-index={index} />
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        className="b-main-slider__circle"
                                                        version="1.1"
                                                        viewBox="0 0 60 60">
                                                        <circle className="circle__background" cx="30" cy="30" r="25" stroke="hsla(0,0%,100%,.5)" strokeWidth="3" fill="rgba(0,0,0,0)" />
                                                        <circle className="b-main-slider__path" style={{ animationDuration: "30s" }} cx="30" cy="30" r="25" stroke="#fff" strokeWidth="3" fill="rgba(0,0,0,0)" />
                                                    </svg>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
    </div> : null
}

export default connect(state => ({
    videos: state.media.homeVideos
}), null)(InfoBlockHomeBigBannerWithMedia)