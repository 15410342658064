import {parseErrors} from "../tools/api-helper";

let initState = {
    inProgress: false,
    otherPaymentMethods: [],
    paymentMethods: false,
    paymentLimits: false,

    paymentTransfers: [],
    paymentMethod: [],
    paymentPays: [],
    paymentDeferred: [],

    promotionsArchive: [],
    dataSpb: {},

    paymentTypes: []
};

export function paymentsTransfers(state = initState, action) {
    switch (action.type) {   
        case "PAYMENTS_METHODS":
            return {...state, isSubmitOk: false, inProgress: true, submitError: "", errorValidation: false};
        case "PAYMENTS_METHODS_SUCCESS":
            return {...state, isSubmitOk: true, complainFormOk: true, data: action.data};
        case 'CLEAR_PAYMENTS_METHODS_DATA':
            return {...state, isSubmitOk: false, complainFormOk: false, errorValidation: false, complainFormError: false, errors: false};
        case 'PAYMENTS_METHODS_BAD_REQUEST':
            return {...state, submitError: action.data.message, inProgress: false, error: action.error, complainFormError: true};
        case 'PAYMENTS_METHODS_ERROR':
            return {...state, inProgress: false, formError: true};
        case "PAYMENTS_METHODS_VALIDATION_ERROR":
            return {...state, inProgress: false, errors: parseErrors(action.data), errorValidation: true, isOk: false, complainFormError: true};

        case "PAYMENTS_METHODS_SPB":
            return {...state, isSubmitOk: false, inProgress: true, submitError: "", errorValidation: false};
        case "PAYMENTS_METHODS_SPB_SUCCESS":
            return {...state, isSubmitOk: true, complainFormOk: true, dataSpb: action.data};
        case "CLEAR_DATA_PAYMENTS_METHODS_SPB":
            return {...state, dataSpb: {}};
        case 'CLEAR_PAYMENTS_METHODS_SPB_DATA':
            return {...state, isSubmitOk: false, complainFormOk: false, errorValidation: false, complainFormError: false, errors: false};
        case 'PAYMENTS_METHODS_SPB_BAD_REQUEST':
            return {...state, submitError: action.data.message, inProgress: false, error: action.error, complainFormError: true};
        case 'PAYMENTS_METHODS_SPB_ERROR':
            return {...state, inProgress: false, formError: true};
        case "PAYMENTS_METHODS_SPB_VALIDATION_ERROR":
            return {...state, inProgress: false, errors: parseErrors(action.data), errorValidation: true, isOk: false, complainFormError: true};

        case "PAYMENT_TYPES":
            return {...state, isOk: false};
        case "PAYMENT_TYPES_SUCCESS":
            return {...state, paymentTypes: action.data, isOk: true};

        case "PAYMENTS_OTHER_PAYMENT_METHODS":
            return {...state, isOk: false};
        case "PAYMENTS_OTHER_PAYMENT_METHODS_SUCCESS":
            return {...state, otherPaymentMethods: action.data, isOk: true};

        case "PAYMENT_METHODS":
            return {...state, isOk: false};
        case "PAYMENT_METHODS_SUCCESS":
            return {...state, paymentMethods: action.data, isOk: true};

        case "PAYMENTS_LIMITS":
            return {...state, isOk: false};
        case "PAYMENTS_LIMITS_SUCCESS":
            return {...state, paymentLimits: action.data, isOk: true};

        case "PAYMENT_TRANSFERS_PAGE_SUCCESS":
            return {...state, paymentTransfers: action.data};
        case "PAYMENT_METHOD_PAGE_SUCCESS":
            return {...state, paymentMethod: action.data};
        case "MOTIV_PAY_PAGE_SUCCESS":
            return {...state, paymentPays: action.data};
        case "DEFERRED_PAYMENT_PAGE_SUCCESS":
            return {...state, paymentDeferred: action.data};
    }
    return state;
}