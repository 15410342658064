import React, { Component } from 'react';
import TooltipQuestion from "./TooltipQuestion/TooltipQuestion";

export default class Radio extends Component {


    onChange = (e) => {
        this.props.onChange(this.props.name, e.target.value);
    }


    render() {
        let {
            className = "",
            name = "",
            value = "",
            items = [],
            withTooltips,
        } = this.props;

        return (
            <div className={className}>
                {items.map((item, index) =>
                    <div className="pill-input__item" key={index}>
                        <input id={name+"-"+item.value} type="radio"
                               className="radio__input" name={name} checked={item.value == value ? "checked" : false}
                               value={item.value} onChange={this.onChange}
                        />
                        <label className="radio__label main_form__radio__label" htmlFor={name+"-"+item.value}>
                            <div className="main_form__radio__label__content">
                                {item.label}
                                {withTooltips && item.tooltip && <TooltipQuestion text={item.tooltip} />}
                            </div>
                        </label>
                    </div>
                )}
            </div>
        )
    }
}
