import React, {Component} from "react";

export default function Documents({documents, title, description}) {
    let data = documents ? documents.docs : null;

    return (
        <div className="b-promo-block_color_black b-promo-block_adapt_career">
            <div className="b-intro-simple__title" dangerouslySetInnerHTML={{__html: title ? title : 'Документы'}}/>
            <div className="form_style-description" dangerouslySetInnerHTML={{__html: description ? description : 'Для просмотра логотипа в оригинальном размере щелкните по ссылке.'}}/>
            {!!data ? <div className="form-style-block">
                <div className="documents-block">
                    <div className="container">
                        {data.map((doc) => {
                            return <div className="document-block">
                                {doc && doc.extension ?
                                    <div className="document__rectangle">
                                        <span className="document__rectangle-text" dangerouslySetInnerHTML={{__html: doc.extension}}/>
                                    </div>
                                    : null}
                                <div className="document__info">
                                    {doc && doc.title ?
                                        <div className="document__title">
                                            <a className="document-link" href={doc.link ? doc.link : "#"}>{doc.title}</a>
                                        </div>
                                        : null}
                                    {doc && doc.size ? <div className="document__size">{'(размер: ' + doc.size + ')'}</div> : null}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div> : null}
        </div>
    );
}