import React, { Component } from 'react';
import {mediaUrl} from "../../tools/config";
import InfoblockLink from "./InfoblockLink";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

class InfoBlockProductCard extends Component {

    render() {

        let blocks = this.props.blocks;

        return (
            <div className="b-offers-plates__body">
                <div className="b-offers-plates__list">
                    {blocks.map((item, key) =>
                        <div className="b-offers-plates__list-item" key={key}>
                            <AdvertisingLabeling
                                token={item && item.token}
                                advertiser={item && item.advertiser}
                            />
                            <div className="b-offers-plates__item-inner">
                                <div className="b-offers-plates__name">{item.title}</div>
                                <div className="b-offers-plates__price">{item.text}
                                    <div className="b-offers-plates__valute">&nbsp;
                                        <span className="u-rub">₽</span>
                                    </div>
                                </div>
                                <div className="b-offers-plates__image-block">
                                    <img className="b-offers-plates__img" src={mediaUrl + item.imagePath} alt="image"
                                         role="presentation"/>
                                </div>
                                <InfoblockLink className={"b-offers-plates__basket-btn"} item={item}>
                                    <span className="b-offers-plates__btn-text">Купить</span>
                                </InfoblockLink>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default InfoBlockProductCard;