import React, {Component} from "react";
import PdfLink from "./PdfLink";
import PropTypes from "prop-types";

export default class TariffInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shown: null,
            shownDescr: true,
        }
    }

    componentWillMount() {
        if (this.props.type === 'inner') {
            this.setState({
                shownDescr: false
            })
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        pushHistory: PropTypes.func
    };

    toggleTooltip = (item) => {
        item = this.state.shown === item ? null : item;
        this.setState({
            shown: item
        });
    };

    toggleDescr = () => {
        this.setState((prevState) => {
            return {
                shownDescr: !prevState.shownDescr
            }
        });
    };

    render(){
        let info = this.props.info;
        let aboutPageUrl = "/" + this.context.selectedRegion.alias + "/" + this.props.settings.route;
        let {shown, shownDescr} = this.state;
        let {type = 'external', innerTitle = ''} = this.props;

        return <div className="tariff-info">
            <div className="wrap-lg">
                {type !== 'inner' ?
                    <h2 className="title">Подробнее о тарифе</h2> :
                    <div className="inner-title-wrap" onClick={this.toggleDescr}>
                        <div className="inner-btn" />
                        <span className="inner-title">{!shownDescr ? innerTitle : "свернуть"}</span>
                    </div>
                }
                {shownDescr ? <div className="info-table">
                    {info.map((item) => <div className={"info-table-row"+(item.additionalClassName ? " "+item.additionalClassName : "")+(item.infoTooltip && item.txtTooltip ? " unlim-info-wrap" : "")}>
                            {item.infoTooltip && item.txtTooltip ? <div className="info-tooltip-block" onClick={(e) => {e.stopPropagation(); this.toggleTooltip(item.id)}}>
                                {shown === item.id ?
                                    <div className="info-tooltip">
                                        <span dangerouslySetInnerHTML={{__html: item.txtTooltip}}/>
                                        <span className="info-tooltip__close">×</span>
                                    </div>
                                : null}
                            </div> : null}
                            <div className="label" title={item.tooltip} dangerouslySetInnerHTML={{__html: item.label}} />
                            <div className="value" dangerouslySetInnerHTML={{__html: item.value}}/>
                        </div> )}
                    </div> :
                null }
                {type !== 'inner' ?
                    <React.Fragment>
                        <PdfLink className={"pdf-link"} constructor={this.props.constructor}>Информация о тарифе</PdfLink>
                        <a className={"about-link dd"}  href={aboutPageUrl} target="_blank">{this.props.settings.link_title}</a>
                    </React.Fragment>
                : null}
            </div>
        </div>
    }
}
