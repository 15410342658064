import React, {Component} from 'react';
import {connect} from "react-redux";
import {servicesArchiveList} from "../../actions/services";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import RatesArchiveCatList from "../../components/Rates/RatesArchiveCatList";

class RatesServiceArchive extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    componentWillMount(){
        if (this.props.services.archiveServicesList.length === 0) {
            this.props.getServicesArchive(this.context.selectedRegion.id);
        }
    }

    servicesByCategories (services)
    {
        let categories = [];
        let result = [];

        /*tariffs.sort(function (t1, t2) {
            return t2.rank - t1.rank;
        });*/

        services.forEach(function (elem) {
            categories[elem.mainCategory.id] = elem.mainCategory;
        });

        categories = categories.filter(v => v);

        categories = categories.sort(function (catA, catB) {
            return catB.rank_main - catA.rank_main;
        });

        categories.forEach(function (cat, i) {

            let obj = {'title': '', 'items' : []};

            obj.title = cat.title;
            result[i] = obj;

            services.forEach(function (elem) {
                if (cat.id === elem.mainCategory.id) {
                    result[i].items.push(elem);
                }
            });
        });

        return result;
    }

    render() {
        const { regions, location } = this.props;
        if (typeof this.props.services.archiveServicesList === 'undefined') {
            return (
                <main className="page__main-holder" role="main" />
            )
        }

        let archiveList = this.props.services.archiveServicesList;
        let selectedRegion = this.context.selectedRegion;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/services",
                title: "Услуги"
            },
            {
                url: null,
                title: "Архив услуг"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");
        
        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'servicesArchiveRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder" role="main">
                <Helmet                    
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container">
                    <div className="b-services-archive b-services-archive_adapt_default">
                        <div className="b-services-archive__wrap">
                            <div className="page__intro-simple">
                                <div className="b-intro-simple b-intro-simple_adapt_default">
                                    <RatesBreadcrumbs items={breadcrumbs} />
                                    <div className="b-intro-simple__title">Архив услуг</div>
                                    <div className="b-intro-simple__subtitle">Обращаем внимание на то, что архивные услуги закрыты для подключения.</div>
                                </div>
                                <RatesArchiveCatList items={archiveList} type={"service"} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        services: state.services,
        regions: state.regions,

        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getServicesArchive : (regionId) => dispatch (servicesArchiveList(regionId))
        }
    }
)(RatesServiceArchive)

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */

export const RatesServicesArchiveRequests = [
    {
        func: 'getServicesArchive',
        args: [],
        object: 'services.archiveServicesList',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    }
];