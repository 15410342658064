import React, { Component } from 'react';
import { mediaUrl } from "../../tools/config";
import Slider from 'react-slick';
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';



class CarouselAbout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dots: []
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.slickTo = this.slickTo.bind(this);
    }

    componentWillMount () {

        let dots = [];

        this.props.blocks.forEach(function (el, i) {
            dots.push({
                active: i === 0,
            });
        });

        this.state.dots = dots;
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    slickTo (e) {
        let idx = e.target.getAttribute('data-index');
        this.slider.slickGoTo(idx);
    }

    changeDot (idx) {
        this.setState(() => {
            this.state.dots.forEach(function (el) {
                el.active = false;
            });
            this.state.dots[idx].active = true;
            return {dots : this.state.dots}
        });
    }

    render() {
        let blocks = this.props.blocks;
        let title = this.props.title;
        let typeSettings = blocks.length ? blocks[0].type : {};

        const settings = {
            dots: false,
            infinite: true,
            autoplay: 4900,
            speed: 1000,
            autoplaySpeed: 4900,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            draggable: false,
            beforeChange: (current, next) => this.changeDot(next)
        };

        return (
            <div className="about-page__slider-wrap-content">
                <div className="about-page__slider-items-list">
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        {blocks.map((block, index) =>
                            <div className="about-page__slider-item" key={index}>
                                <AdvertisingLabeling
                                    token={block && block.token}
                                    advertiser={block && block.advertiser}
                                />
                                <img src={mediaUrl + block.imagePath} alt={block.title} />
                                <div className="about-page__slider-item-content">
                                    <h3
                                        className="about-page__slider-item-content-title"
                                        dangerouslySetInnerHTML={{ __html: block.title }}
                                    ></h3>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
                <div className="about-page__slider-controls">
                    <div className="about-page__slider-controls-wrap-arrow">
                        <div onClick={this.previous} className="about-page__slider-controls-arrow_many about-page__slider-controls-arrow_left" />
                        <div onClick={this.next} className="about-page__slider-controls-arrow_many about-page__slider-controls-arrow_right" />
                    </div>
                    <div className="about-page__slider-controls-wrap-dots">
                        <ul className="slick-dots" role="tablist">
                            {this.state.dots.map((dot, index) =>
                                <li className={dot.active ? "slick-active" : ""} role="presentation" key={index}>
                                    <button type="button" onClick={this.slickTo} data-index={index}/>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default CarouselAbout;