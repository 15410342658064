import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList, routesVacanciesList} from "../../actions/routes";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import ContactsMedia from "../../components/PressCenter/ContactsMedia";
import {contactsList} from "../../actions/contacts";
import {mediaList} from "../../actions/media";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import {MediaModalPicture, MediaModalVideo} from "../../components/modals/MediaModal";
import AlbumItem from "../../components/PressCenter/AlbumItem";
import Icon from "../../components/common/Icon";
import DataPickerDay from "../../components/PressCenter/DataPickerDay";
import {DateUtils} from "react-day-picker";

class MediaBank extends Component {

    constructor(props) {
        super(props);

        this.state = {
            btnAlbumMoreHidden: false,
            mediaBankTitle: "Медиа–банк",
            activeTab: 0,
            modal: null,
            modalLock: false,
            idItem: null,
            idAlbumPicture: null,
            idAlbumVideo: null,
            typeVideoRender: "desktop",
            showCalendar: false,
            from: null,
            to: null
        };
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    // для ssr
    componentWillMount() {
        // ssr для контактов
        if (this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("press");
        }
        // ssr для фотоальбомов
        if (this.props.mediaList && this.props.mediaList.mediaImg.length === 0) {
            this.props.getMediaList("?type=0", "img");
        }
        // ssr для видеоальбомов
        if (this.props.mediaList && this.props.mediaList.mediaVideo.length === 0) {
            this.props.getMediaList("?type=1", "video");
        }

        if (this.props.mediaList && this.props.mediaList.mediaAll.length === 0) {
            this.props.getMediaList("", "all");
        }
    }

    componentDidMount(){
        if (this.props.contactsList.contacts.length === 0) {
            this.props.getContactsList("press");
        }

        if (this.props.mediaList && this.props.mediaList.mediaImg.length === 0){
            this.props.getMediaList("?type=0", "img");
        }

        if (this.props.mediaList && this.props.mediaList.mediaVideo.length === 0){
            this.props.getMediaList("?type=1", "video");
        }

        if (this.props.mediaList && this.props.mediaList.mediaAll.length === 0) {
            this.props.getMediaList("", "all");
        }

        if (typeof window !== "undefined") {
            if ( this.state.typeVideoRender === "desktop" && window.innerWidth <= 767) {
                this.setState({
                    typeVideoRender: "mobile",
                    modalLock: true,
                })
            } else {
                this.setState({
                    typeVideoRender: "desktop",
                    modalLock: false,
                })
            }
            window.addEventListener('resize', this.onResizeWidth);
        }

        let hash = this.props.location && this.props.location.hash;
        if ( hash === "#videoAll") {
            this.setState({
                activeTab: 1
            });
        }
    };

    componentDidUpdate(prevProps, prevState){
        let modal = this.state.modal;
        if (typeof document !== 'undefined') {
            if (modal === "picture" || modal === "video") {
                this.hideScroll();
            } else {
                this.showScroll();
            }
        }

        let hash = this.props.location && this.props.location.hash;
        if ( hash === "#videoAll") {
            this.removeHash();
        }
        const el = document.querySelector(".lock-news-page") || undefined;
        if (typeof el !== 'undefined'){
            el.addEventListener("click", this.closeWindowCalendar);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeWidth);
        const el = document.querySelector(".lock-news-page") || undefined;
        if (typeof el !== 'undefined'){
            el.removeEventListener("click", this.closeWindowCalendar);
        }
    };

    removeHash = () => {
        window.history.replaceState(null, null, window.location.pathname);
    };

    getMore = (pagination, type) => {
        return () => {
            const currentPage = pagination.currentPage;
            const pageCount = pagination.pageCount;
            let currentNextPage = Number(currentPage);

            if (currentPage < pageCount) {
                currentNextPage++;
                const dateParams = this.getDateParams()
                this.props.getMediaList("?"+(type === "all" ? "" : "type="+(type === "img" ? 0 : 1)+"&")+(dateParams ? dateParams+'&' : '')+"page=" + currentNextPage, type, true);
            }
        }
    };

    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();
            this.setState(() => {
                return {
                    activeTab: val
                }
            })
        }
    };

    openModal = (modal, idItem) => {
        let modalLock = this.state.modalLock;
        if (!modalLock){
            return (e) => {
                if (e) e.preventDefault();
                this.setState({
                    modal: modal,
                    idItem: idItem
                });
            };
        }
    };

    onResizeWidth = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                modalLock: true,
                modal: null,
                typeVideoRender: "mobile",
            })
        } else {
            this.setState({
                modalLock: false,
                typeVideoRender: "desktop",
            })
        }
    };

    hideScroll = () =>{
        // let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("on-media-modal");
            // scrollTop = window.pageYOffset;
            // document.body.style.position = "fixed";
            // document.body.style.top = -scrollTop + 'px';
        }
    };

    closeModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: 'close'});
        this.showScroll();
    };

    showScroll = () => {

        if (typeof document != "undefined") {
            document.body.classList.remove('on-media-modal');
            // document.body.style.position = '';
            // document.body.style.top = '';
            // window.scroll(0, this.scrollTop);
        }
    };

    renderModal = () => {
        let modal = this.state.modal;
        let idAlbumPicture = this.state.idAlbumPicture;
        let idAlbumVideo = this.state.idAlbumVideo;
        let idItem = this.state.idItem;
        let dataAlbumPicture = this.props.mediaList && this.props.mediaList.mediaImg;
        let dataAlbumVideo = this.props.mediaList && this.props.mediaList.mediaVideo;

        if ( !!idAlbumPicture || !!idAlbumVideo ){
            let itemsAlbumPicture = dataAlbumPicture.data.find(album => album.id === idAlbumPicture);
            let sliderItem = (modal === "picture") ? itemsAlbumPicture.content.findIndex(x => x.id === idItem) : null;
            let itemsAlbumVideo = dataAlbumVideo.data.find(album => album.id === idAlbumVideo);
            let videoItem = (modal === "video") ? itemsAlbumVideo.content.find(x => x.id === idItem) : null;

            switch ( modal ) {
                case "picture":
                    return <MediaModalPicture
                        close={ this.closeModal }
                        items={ itemsAlbumPicture }
                        idAlbumPicture={ idAlbumPicture }
                        idItem={ idItem }
                        sliderItem={ sliderItem }
                        modal={ modal }
                    />;
                case "video":
                    return <MediaModalVideo
                        close={ this.closeModal }
                        items={ videoItem }
                    />;
                default:
                    return "";
            }
        }
    };

    clickItemBlock = (type, item) => {
        switch (type) {
            case "picture":
                return this.setState({
                    idAlbumPicture: item,
                });
            case "video":
                return this.setState({
                    idAlbumVideo: item,
                });
            default:
                return "";
        }
    };

    setShowCalendar = () => {
        this.setState((prevState) => {
            return {
                showCalendar: !prevState.showCalendar
            }
        });
    };

    closeWindowCalendar = (e) => {
        if (e) e.preventDefault();
        if ( !!this.state.showCalendar  ) {
            this.setState({
                showCalendar: false
            })
        }
    };

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    };

    getDateParams = () => {
        let {from, to} = this.state;

        if (!from && !to) {
            return ''
        }
        const dateTo = new Date(from < to ? to : from);
        if (dateTo) {
            dateTo.setDate(dateTo.getDate() + 1)
        }

        return `date_from=${from.toLocaleDateString().split('.').reverse().join('-')}&date_to=${dateTo.toLocaleDateString().split('.').reverse().join('-')}`
    }

    getApply = () => {
        let {showCalendar} = this.state;

        const dateParams = this.getDateParams()
        this.props.getMediaList('?'+dateParams, 'all');
        this.props.getMediaList('?type=0&'+dateParams, 'img');
        this.props.getMediaList('?type=1&'+dateParams, 'video');

        if ( !!showCalendar ) {
            this.setShowCalendar();
        }
    };

    filterReset = () => {
        let from = this.state.from;
        let to = this.state.to;
        this.props.getMediaList('', 'all');
        this.props.getMediaList('?type=0', 'img');
        this.props.getMediaList('?type=1', 'video');

        if ( from !== null || to !== null ) {
            this.setState({
                from: null,
                to: null,
                showCalendar: false
            })
        }
    };

    render() {
        const { regions, location, mediaList } = this.props;
        let {
            btnAlbumMoreHidden,
            mediaBankTitle,
            activeTab,
            typeVideoRender,
            showCalendar,
            from,
            to
        } = this.state;

        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder media-bank-header");
        const route = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "pressCenterRoute");
        const routeMediaBank = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "mediaBankRoute");
        let contactsMedia = this.props.contactsList && this.props.contactsList.contacts;
        let linkBreadcrumbsPressCenter = route && route.link;
        let selectedRegion = this.context.selectedRegion;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + linkBreadcrumbsPressCenter,
                title: "Пресс центр"
            },
            {
                url: "",
                title: "Медиа–банк"
            }
        ];

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'mediaBankRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder main-media-bank" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </div>
                            </div>

                            <div className="media-bank-block">
                                <div className="album-picture__title">
                                    <div dangerouslySetInnerHTML={{ __html: mediaBankTitle }}/>
                                    <div className="news-and-events__button-calendar" onClick={(e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation()
                                    }}>
                                        <div className="news-and-events__calendar" onClick={this.setShowCalendar}>
                                            <Icon name={"icon_calendar"} className={"news-and-events--calendar-icon"} />
                                            <span className="news-and-events--calendar-title">Календарь</span>
                                        </div>
                                        <div className="media-data-picker__wrap">
                                            <DataPickerDay
                                                from={ from }
                                                to={ to }
                                                handleDayClick={ this.handleDayClick }
                                                showCalendar={ showCalendar }
                                                getApply={ this.getApply }
                                                filterReset={ this.filterReset }
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="media-bank-block__tabs-header">
                                    <TabsSwitcher items={[
                                        {title: "Рекламные ролики и видео"},
                                        {title: "Фотогалерея"},
                                        {title: "Общее"}
                                    ]} onClickFunc={this.onToggleTab} activeTab={activeTab}/>
                                </div>
                                <ul className="tabs__content" ref="content">
                                    <li className={"tabs-content__item"+(activeTab === 0 ? " tabs-content__item--active" : "")}>
                                        <AlbumItem
                                            items={ mediaList.mediaVideo.data }
                                            mediaPagination={ mediaList.mediaVideo.pagination }
                                            openModal={ this.openModal }
                                            clickItemBlock={ this.clickItemBlock }
                                            type={ typeVideoRender }
                                            getMore={ this.getMore(mediaList.mediaVideo.pagination, "video") }
                                        />
                                    </li>

                                    <li className={"tabs-content__item" + ((activeTab === 1) ? " tabs-content__item--active" : "")}>
                                        <AlbumItem
                                            items={ mediaList.mediaImg.data }
                                            mediaPagination={ mediaList.mediaImg.pagination }
                                            getMore={ this.getMore(mediaList.mediaImg.pagination, "img") }
                                            openModal={ this.openModal }
                                            clickItemBlock={ this.clickItemBlock }
                                        />
                                    </li>

                                    <li className={"tabs-content__item" + ((activeTab === 2) ? " tabs-content__item--active" : "")}>
                                        <AlbumItem
                                            items={ mediaList.mediaAll.data }
                                            mediaPagination={ mediaList.mediaAll.pagination }
                                            getMore={ this.getMore(mediaList.mediaAll.pagination, "all") }
                                            openModal={ this.openModal }
                                            clickItemBlock={ this.clickItemBlock }
                                            type={ typeVideoRender }
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <AsideMenu
                                    flag={route.sys_name}
                                    flagSubMenu={routeMediaBank.sys_name}
                                    route={this.props.routesVacancies}
                                    customTitleMenu={'Пресс центр'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        contactsList: state.contacts || [],
        mediaList: state.media || [],
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getContactsList: (name) => dispatch(contactsList(name)),
            getMediaList: (listType, mediaType, loadMore) => dispatch(mediaList(listType, mediaType, loadMore)),
        }
    }
)(MediaBank);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const MediaBankRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getContactsList',
        args: ['press'],
        object: 'contactsList.contacts',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];