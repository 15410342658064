import React from 'react'
import LinkC from "../common/LinkC";

export default function TopBlock({title}) {

        return <div className="b-intro-simple b-intro-simple_adapt_default">
            <div className="b-intro-simple__breadcrumbs">
                <div className="b-breadcrumbs b-breadcrumbs b-breadcrumbs_theme_grey">
                    <ul className="b-breadcrumbs__list">
                        <li className="b-breadcrumbs__item">
                            <LinkC className="b-breadcrumbs__link" to="/">Настройка аккаунта</LinkC>
                        </li>
                        <li className="b-breadcrumbs__item">
                            <span className="b-breadcrumbs__link">{title}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="b-intro-simple__title">{title}
                {/*<div className="b-intro-simple__tags">*/}
                    {/*<div className="b-intro-simple__tag u-tag">Мой номер</div>*/}
                {/*</div>*/}
            </div>
        </div>
}