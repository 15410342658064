import React, {Component} from "react";
import InfoblockLink from "./InfoblockLink";
import {mediaUrl} from "../../tools/config";

export default function InfoBlockBanner({dataBanner}){
    let data = dataBanner ? dataBanner[0] : null;
    let imagePath = data && data.imagePath;
    return (<div className="page__promo-career">
        <div className="b-promo-block b-promo-block_theme_career b-promo-block_color_black b-promo-block_adapt_career">
            {data && data.title ? <div className="b-intro-simple__title"  dangerouslySetInnerHTML={{__html: data.title}} /> : null}
            <div className="b-promo-block__wrapper"
                 style={{backgroundImage: 'url('+mediaUrl + imagePath+')'}}>
                {data && data.title ? <div className="b-promo-block__title" dangerouslySetInnerHTML={{__html: data.text}}/> : null}
            </div>
        </div>
    </div>)
}