import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icon from '../common/Icon';
import {b2bUrl, cookieDomain, oldRegions} from "../../tools/config";
import HeaderChooseRegion from "./HeaderChooseRegion";
import { getDomain, setCookie, setRegionCookie } from "../../tools/tools";
import { mainUrl } from "../../tools/config";
import LinkC from "../common/LinkC";
import { highlightUrl } from '../../tools/url-helper';

class HeaderTop extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            selected: {},
            openedCorrectRegion: false
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        regions: PropTypes.array,
        toggleRegion: PropTypes.func,
        isB2b: PropTypes.bool,
        isLk: PropTypes.bool
    };


    dropMenu = (value) => {
        return () => {
            this.setState(() => {
                return { opened: value }
            })
        }
    };

    closeCorrectRegion = () => {
        setCookie('regionName', this.context.selectedRegion.alias, {
            path: '/',
            expires: 30 * 3600 * 24,
            //domain: '.localhost'
            domain: cookieDomain
        });

        this.setState({
            openedCorrectRegion: false
        })
    };

    closeCorrectRegionAndDrop = () => {
        this.setState({
            openedCorrectRegion: false,
            opened: true
        })
    };

    select = (value) => {
        return () => {
            this.context.toggleRegion(value);
            this.context.selectedRegion = value;
            this.setState(() => {
                return { opened: false }
            });
            setRegionCookie(value.alias)
        }
    };

    render() {

        let regions = this.context.regions;
        let motivRegion = oldRegions[this.context.selectedRegion.alias];
        let currentRegion = this.context.selectedRegion.alias;
        let { motivOnMapRoute, isB2b } = this.props;

        return (
            <div className="b-header__top-line">
                <div className="page__container">
                    <div className="b-header__top-items">
                        <div className="b-header__top-item b-header__top-item_region"
                            onMouseEnter={this.dropMenu(true)}
                            onMouseLeave={this.dropMenu(false)} >
                            <div className="b-region-selector b-region-selector_adapt_default">
                                <div className={"b-region-selector__line" + (this.state.opened ? " active" : "")}>
                                    <span className="b-region-selector__label">Ваш регион:</span>
                                    <span className="b-region-selector__value">
                                        <span>{this.context.selectedRegion.name}</span>
                                        <Icon name={'icon_region-arrow'} className={'b-region-selector__arrow u-icon u-icon_region-arrow'} />
                                    </span>
                                    <ul className="reg-select-list">
                                        {regions.map((item, key) =>
                                            <li className="reg-select-list__item" key={key} onClick={this.select(item)}>
                                                {item.name}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <HeaderChooseRegion hasCookieRegion={this.state.hasCookieRegion} opened={this.state.openedCorrectRegion} closeCorrectRegion={this.closeCorrectRegion} closeCorrectRegionAndDrop={this.closeCorrectRegionAndDrop} />
                        <div className="b-header__top-item b-header__top-item_sections">
                            <div className="b-sections-menu">
                                <div className="b-sections-menu__items">
                                    <div className="b-sections-menu__item">
                                        <LinkC to={isB2b ? mainUrl : "/"} className="b-sections-menu__link" targetBlank={isB2b}>
                                            <span className={"b-sections-menu__link-text" + (!isB2b ? " b-sections-menu__link-text--thin" : "")}>Частным клиентам</span>
                                        </LinkC>
                                    </div>
                                    <div className="b-sections-menu__item">
                                        <LinkC to={isB2b ? "/" : b2bUrl} className="b-sections-menu__link" targetBlank={!isB2b}>
                                            <span className={"b-sections-menu__link-text" + (isB2b ? " b-sections-menu__link-text--thin" : "")}>Бизнесу</span>
                                        </LinkC>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-header__top-item b-header__top-item_offices b-header__double_items">
                            <a className="b-sections-menu__link b-sections-menu__double_item" href="https://shop.motivtelecom.ru/" target="_blank">
                                <div className={'b-header__wrap_icon_internet_shop'}>
                                    <Icon name={'icon_internet_shop'} className={'b-header__icon_internet_shop'} />
                                </div>
                                <span className="b-sections-menu__link-text_double">Интернет–магазин</span>
                                {/*<span className="b-sections-menu__counter">0</span>*/}
                            </a>
                            <a className="b-header__top-link" target="_blank" href={this.context.isLk || this.context.isB2b ? mainUrl + currentRegion + motivOnMapRoute : "/" + currentRegion + motivOnMapRoute}>Офисы и покрытие</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderTop;