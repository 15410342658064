import React, {Component} from "react";
import {mediaUrl} from "../../tools/config";
import PropTypes from "prop-types";

export default class PdfLink extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    render() {

        let {
            constructor,
            children,
            className,
            tariffParams = false,
            linkFile = false
        } = this.props;

        let gb = '';
        let url = '';

        if (!!tariffParams) {
            url = tariffParams.description_url ? tariffParams.description_url : '#';
        } else {
            url = mediaUrl + 'svoi-tariff-' + this.context.selectedRegion.id + '-' + constructor.min + '-' + gb + '-' + constructor.sms + '-' + constructor.social_unlim + '-' + constructor.motiv_voice_unlim + '.pdf';
        }

        return <a href={!!linkFile ? linkFile : url} target="_blank" className={className}>{children}</a>

    }
}
