import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {routesList} from "../../actions/routes";
import OfficeMap from "../../components/common/OfficeMap";
import { addressAutoComplete } from "../../actions/forms";
import { shopsList, closestShopsList } from "../../actions/shops";
import { coverImagesList } from "../../actions/coverImages";
import backImg from "../../../images/icons/back.png";

class MotivOnMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTabs: true,
            referrer: null
        };

        this._regions = [];
    }

    loadRegion = () => {
        if (this._regions.length === 0) {
            return;
        }
        this.props.getCoverImages(this._regions.shift());
    }


    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if ( this.props.shops.list.length === 0 ) {
            this.props.getShopsList(regionId);
        }
        if ( this.props.routes.list.length === 0 ) {
            this.props.getRoutes();
        }

        if (this._regions.length === 0) {
            this._regions = this.props.regions.list.map(item => item.id).filter(item => item !== regionId);
            this._regions.unshift(regionId)
        }

        if (typeof document !== "undefined") {
            this.loadRegion();
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        goBack: PropTypes.func
    };

    goBack = (event) => {
        event.preventDefault();

        this.context.goBack();
    };

    render() {
        let reff = this.props.history && this.props.history.length;
        let selectedRegion = this.context.selectedRegion;
        let {
            forms,
            shops,
            addressAutoComplete,
            getClosestShopsList,
            getShopsList,
            regions,
            location
        } = this.props;        

        let {
            showTabs
        } = this.state

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'motivOnMapRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam  coverage_map"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container">

                </div>
                <div className="page__container page__container_map">
                    <OfficeMap  items={shops.list}
                                address={forms.addressFull || []}
                                addressAutoComplete={addressAutoComplete}
                                getClosestShopsList={getClosestShopsList}
                                getShopsList={getShopsList}
                                inProgress={forms.inProgress}
                                showTabs={showTabs}
                                coverCoords={this.props.coverImages.list}
                                loadMore={() => this.loadRegion()}
                                duplicates={this.props.coverImages.duplicates}
                    />
                    {reff > 1 ? <a className="b-map__back-btn" href="#" onClick={this.goBack}>
                        <img className="b-map__btn-icon" src={backImg} alt="" role="presentation" />
                        <span className="b-map__btn-text">Вернуться назад</span>
                    </a> : null}
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        shops: state.shops,
        forms: state.forms,
        settings: state.settings,
        coverImages: state.coverImages,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            addressAutoComplete: (type, query, params) => dispatch(addressAutoComplete(type, query, params)),
            getClosestShopsList: (regionId, lat, lon, region, city, street) => dispatch(closestShopsList(regionId, lat, lon, region, city, street)),
            getShopsList: (regionId) => dispatch(shopsList(regionId)),
            getCoverImages: (regionId) => dispatch(coverImagesList(regionId))
        }
    }
)(withRouter(MotivOnMap));

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const motivOnMapRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getShopsList',
        args: [],
        object: 'shops.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },

];