import React, {Component} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {tariffsList} from "../../actions/tariffs";
import B2bTariffCard from "../../components/Rates/B2bTariffCard";
import {withTariffModal} from "./TariffConnection";
import {Helmet} from "react-helmet";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SidebarHeadInfo from "../../components/common/SidebarHeadInfo";
import RatesPriceList from "../../components/Rates/RatesPriceList";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import DownloadDoc from "../../components/common/DownloadDoc";
import Page404 from "../Page404";

function makeOtherTariffs(tariffs, slug) {
    let other = [], indexes = [];

    if (tariffs.length) {
        while (other.length < Math.min(2, tariffs.length - 1) ) {
            const rand = Math.floor(Math.random() * tariffs.length);
            const tariff = tariffs[rand];
            if (tariff.slug !== slug && indexes.indexOf(rand) === -1) {
                other.push(tariff)
                indexes.push(rand)
            }
        }
    }

    return other;
}

class TariffDetail extends Component {

    constructor(props, context) {
        super(props, context);

        const tariff = props.tariffs.find((item) => item.slug === props.match.params.slug)

        this.state = {
            slug: props.match.params.slug,
            tariff,
            breadcrumbs: this.makeBreadcrumbs(tariff),
            other: makeOtherTariffs(props.tariffs, props.match.params.slug)
        }
    }

    makeBreadcrumbs = (tariff) => {
        const {appRoutes, selectedRegion} = this.context;
        const tariffsRoute = appRoutes.find((item) => item.sys_name === 'tariffsB2BRoute')
        return tariffsRoute && tariff ? [
            {
                url: "/" + selectedRegion.alias + tariffsRoute.route,
                route: tariffsRoute.route,
                title: "Тарифы"
            },
            {
                url: null,
                title: "Тариф «" + tariff.name + "»"
            }
        ] : [];
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        appRoutes: PropTypes.array,
        setHeaderClassName: PropTypes.func
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.slug !== prevState.slug) {
            nextProps.modalClose()
            const tariff = nextProps.tariffs.find((item) => item.slug === nextProps.match.params.slug);

            let breadcrumbs = [...prevState.breadcrumbs];
            breadcrumbs[1].title = "Тариф «" + tariff.name + "»"

            return {
                ...prevState,
                slug: nextProps.match.params.slug,
                tariff,
                breadcrumbs,
                other: makeOtherTariffs(nextProps.tariffs, tariff.slug)
            };
        }
        return prevState;
    }

    componentWillMount() {
        if (!this.state.tariff) {
            this.props.getTariffs(this.context.selectedRegion.id)
        }
    }

    render() {
        const {onClickConnection} = this.props;
        const {
            tariff,
            breadcrumbs,
            other
        } = this.state;

        if (!tariff) return <Page404/>;

        return <main className="page__main-holder b2b-page" role="main">
            <Helmet title={"Подключить тариф «" + tariff.name + "» | Оператор связи МОТИВ"}/>
            <div className="b-service-inner b-service-inner_adapt_default">
                <div className="b-service-inner__service-header">
                    <div className="b-inner-intro b-inner-intro_adapt_default">
                        <div style={tariff.background ? {
                            backgroundColor: tariff.background.color,
                            backgroundImage: tariff.background.url ? 'url("' + tariff.background.url + '")' : '',
                            backgroundSize: tariff.background.is_cover ? 'cover' : 'auto 100%',
                            backgroundPosition: 'center bottom',
                            backgroundRepeat: 'no-repeat'
                        } : {}} className="b-inner-intro__wrapper b-inner-intro__wrapper_tariff">
                            <div className="b-inner-intro__inner page__container">
                                <div className="b-inner-intro__header"/>
                                <div className="b-inner-intro__main-area">
                                    <div className="b-inner-intro__main-area-wrapper">
                                        <RatesBreadcrumbs
                                            color={tariff.background ? tariff.background.breadcrumbs_color : null}
                                            items={breadcrumbs}/>
                                        <div style={tariff.background && tariff.background.title_color ? {
                                            color: tariff.background.title_color
                                        } : {}}
                                             className={`b-inner-intro__title${tariff.background && tariff.background.url ? ' tariff__title' : ''}`}>Тариф
                                            «{tariff.name}»
                                        </div>
                                        <div className="b-inner-intro__text"
                                             dangerouslySetInnerHTML={{__html: tariff.short_description}}/>
                                        <SidebarHeadInfo item={tariff}
                                                         type={"tariff"}
                                                         color={tariff.background ? tariff.background.price_color : null}
                                        />
                                        <div className={'b-inner-intro__btn'}>
                                            <button
                                                className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive u-btn_open-modal"
                                                onClick={() => onClickConnection('tariff-connection', tariff)}
                                            >
                                                Подключить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page__container">
                    <div className="b-with-aside-area b-with-aside-area_adapt_default">
                        <div className="b-with-aside-area__column">
                            <RatesPriceList blocks={tariff.info}/>
                            {tariff.info_dropdown ? <RatesCollapsing info_dropdown={tariff.info_dropdown}
                                             tariffServices={tariff.tariffServices}/> : null}
                            <DownloadDoc url={tariff.description_url} title={"Скачать информацию о тарифе"}
                                         size={tariff.description_file_size}/>
                        </div>
                    </div>
                    {other.length > 0 && <React.Fragment>
                        <div className="page__hr" />
                        <div className="page__title-3 page__title-3_mobile-less">Другие тарифы</div>
                        {other.map((tariff) => <B2bTariffCard
                            key={tariff.id}
                            tariffsLink={breadcrumbs[0].route}
                            {...tariff}
                            onClickConnection={() => onClickConnection('tariff-connection', tariff)}
                        />)}
                    </React.Fragment>}
                </div>
            </div>
        </main>
    }
}

const TariffDetailWithModal = withTariffModal(TariffDetail)

export default connect(
    (state) => ({
        tariffs: state.tariffs.tariffsLists,
    }),
    (dispatch) => {
        return {
            getTariffs: (regionId) => dispatch(tariffsList(regionId, null, null, 'b2b')),
        }
    }
)(TariffDetailWithModal)

export const BusinessTariffDetailRequests = [
    {
        func: 'getTariffs',
        args: [null, null, 'b2b'],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
];