import React, { Component } from 'react';
import {onChange, RenderField} from "../Rates/PopupHelpers";
import Form from "./form/Form";
import {feedbackFaqRequest} from "../../actions/feedback";
import {connect} from "react-redux";

class FaqCollapseForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened,
            send: false,
            fields: {
                comment: {
                    type: 'textarea',
                    label: "",
                    name: "comment",
                    value: "",
                    required: true,
                    placeholder: "",
                    error: '',
                    className: 'feedback-form__textarea'
                },
            },
        };

        this.onChange = onChange.bind(this);
    }

    sendFaqFeedbackUseless = () => {
        this.props.sendFeedbackFaq(this.props.id, false, this.state.fields.comment.value);
        this.setSend();
    };

    sendFaqFeedbackUsefull = () => {
        this.props.sendFeedbackFaq(this.props.id, true, '');
        this.setSend();
    };

    setSend() {
        this.setState(() => {
            return {send: true}
        })
    }

    collapse() {
        this.setState((prevState) => {
            return {opened: !prevState.opened}
        });
    }

    render() {

        let comment = this.state.fields.comment;

        return (
            <div className="feedback-form__inner">
                {this.state.send
                    ?   <div className="feedback-form__send-success">Отзыв отправлен. Спасибо!</div>
                    :   <React.Fragment>
                        <div className="feedback-form__btns-wrap">
                            <div className="feedback-form__label">Информация была полезной?</div>
                            <div className="feedback-form__btn-container">
                                <button className="btn btn--feedback-form" onClick={this.sendFaqFeedbackUsefull}>
                                    <span className="btn__text">Да</span>
                                </button>
                                <button className="btn btn--feedback-form" onClick={this.collapse.bind(this)}>
                                    <span className="btn__text">Нет</span>
                                </button>
                            </div>
                        </div>
                        <div className={(this.state.opened ? " b-list-collapser__item_collapsed" : "")}>
                            <div className={"u-collapser" + (this.state.opened ? " u-collapser_open" : "")}>
                                <div className="u-collapser__container">
                                    <div className="b-list-collapser__collapsed-area">
                                        <Form className={"feedback-form__form"}
                                              NoValidate={false}
                                              onSubmit={this.sendFaqFeedbackUseless}>
                                            <div className="feedback-form__title">Приносим извинения</div>
                                            <div className="feedback-form__text">Как можно улучшить этот ответ?</div>
                                            <RenderField field={comment} onChange={this.onChange}/>
                                            <button onClick={this.sendFaqFeedbackUseless} className="feedback-form__btn u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive">
                                                <span className="">Отправить</span>
                                            </button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default connect(
    state => ({
        feedback: state.feedback
    }),
    (dispatch) => {
        return {
            sendFeedbackFaq: (id, vote, comment) => dispatch(feedbackFaqRequest(id, vote, comment)),
        }
    }
)(FaqCollapseForm);