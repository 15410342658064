import React, { Component } from 'react';
import PropTypes from "prop-types";
import {mediaUrl} from '../../tools/config';

import InfoblockLink from "../InfoBlocks/InfoblockLink";

class ListOfServicesGroupInfoblocks extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const dataAll = this.props.data

        return (
            dataAll.length !== 0 
            ?   <div className="list-of-services__infoblocks-links">
                    {dataAll.map(item => 
                        <div className="infoblocks-links__items" key={item.id}>
                            <img src={mediaUrl + item.imagePath} alt="" className="infoblocks-links__items-img" />
                            <InfoblockLink className={"infoblocks-links__items-title"} item={item}>
                                {item.link_title}
                            </InfoblockLink>
                        </div>
                    )}
                </div>
            : null
        )
    }
}

export default ListOfServicesGroupInfoblocks;