import {
    call,
    put,
    take,
    select
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {
    setCookie,
    getCookie,
    deleteCookie,
    getTimezone
} from '../tools/tools';

import {cookieDomain, mainUrl} from "../tools/config";
const client = state => state.client;

export function checkCallbackForm(url, businessLine = 'b2c') {
    return {
        type: "CHECK_CALLBACK_FORM",
        url,
        businessLine
    }
}

export function* fetchCheckCallbackForm(action) {
    
    let params = [];
    params.push('url='+action.url);
    // params.push('url=https://frontend.motiv.prestage.vm.buroburo.tech/sverdlovsk_oblast/mnp'); //для разблокировки инпутов на b2c
    // params.push('url=https://b2b.motiv.prestage.vm.buroburo.tech/sverdlovsk_oblast/support'); //для разблокировки инпутов на b2b 
    params.push('timezone='+getTimezone());
    let clientData = yield select(client);
    if (clientData && !clientData.isGuest && Object.keys(clientData.clientInfo).length) {
        params.push('phone='+clientData.clientInfo.username);
        params.push('isAuthorized=1');
    }
    let lastRequestId = getCookie('motiv_callback_request');
    if (lastRequestId) {
        params.push('requestId='+lastRequestId);
    }

    const {status, data, error} = yield call(() => {
        // return fetchClientApi("/callback?"+params.join('&'),
        return fetchClientApi(`/callback${action.businessLine ? `?businessLineId=${action.businessLine}` : ''}${action.businessLine ? '&' : '?'}` + params.join('&'), {
            method: 'GET'
        })
    });

    if (error) {
        console.log(error);
    } else {
        if (status == 200 && data.formEnabled && typeof window !== "undefined") {
            deleteCookie('motiv_callback_request')
        }
        yield put(checkErrors(action.type, data, status));
    }
}

export function checkSlots(configId) {
    return {
        type: "CHECK_SLOTS",
        configId
    }
}

export function* fetchSlots(action) {
    let params = [];
    params.push('configId='+action.configId);
    params.push('timezone='+getTimezone());

    const {status, data, error} = yield call(() => {
        return fetchClientApi("/callback-intervals?"+params.join('&'), {
            method: 'GET'
        })
    });

    if (error) {
        console.log(error);
    } else {
        if (status == 200 && data.formEnabled && typeof window !== "undefined") {
            deleteCookie('motiv_callback_request')
        }
        yield put(checkErrors(action.type, data, status));
    }
}

export function sendCallbackForm(type, data) {
    return {
        type: "SEND_CALLBACK_FORM",
        reqType: type,
        data
    }
}

export function* fetchSendCallbackForm(action) {

    const {status, data, error} = yield call(() => {
        return fetchClientApi("/callback/"+action.reqType, {
            method: 'POST',
            body: action.data
        })
    });

    if (error) {
        console.log(error);
    } else {
        if (status === 200) {
            setCookie('motiv_callback_request', data.requestId, {
                path: '/',
                domain: cookieDomain,
                expires: 24*3600
            })
        }
        yield put(checkErrors(action.type, data, status));
    }
}

export function clearCallbackForm() {
    return {type: "CLEAR_CALLBACK_FORM"};
}