import React, { Component } from 'react';
import Icon from "../common/Icon";
import Modal from "../common/Modal";
import AuthForm from "../Forms/AuthForm";


class AuthorizationModal extends Component {

    render() {

        let {
            close,
            error = "",
            login = () => {},
            onForgetLinkClick = () => {},
            authFormSettings = {},
            qrText
        } = this.props;

        return (
            <Modal className="u-modal u-modal_active u-modal-top tariff-connection-modal" close={close}>
               <AuthForm qrText={qrText} showTitle={true} authFormSettings={authFormSettings} login={login} error={error} onForgetLinkClick={onForgetLinkClick}/>
            </Modal>
        )
    }
}

export default AuthorizationModal;