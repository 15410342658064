import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import downloadImg from "js-file-download";
import {hideLoading, showLoading} from "react-redux-loading-bar";

import React from "react";

export function mediaList(listType, mediaType, loadMore = false) {
    switch ( mediaType ) {
        case "img":
            return {
                type: !!loadMore ? 'MEDIA_IMG_LIST_MORE' : 'MEDIA_IMG_LIST',
                listType,
            };
        case "video":
            return {
                type: !!loadMore ? 'MEDIA_VIDEO_LIST_MORE' : 'MEDIA_VIDEO_LIST',
                listType,
            };
        case "all":
            return {
                type: !!loadMore ? 'MEDIA_LIST_MORE' : 'MEDIA_LIST',
                listType,
            };
        default:
            return {
                type: 'MEDIA_ALBUM_ID',
            };
    }
}

export function* fetchMediaList(action) {
    try {
        const { status, data, error, pagination } = yield call( () => {
            return fetchClientApi(
                "/albums" + (action.listType ? action.listType : ''), {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, {data, pagination}, status ));
        }

    } catch(e) {
        console.log(e);
    } finally {
        // yield put(hideLoading());
    }
}

export function download(url) {
    return {
        type: "DOWNLOAD_IMG",
        url
    }
}

export function* fetchDownload(action) {
    try {
        yield put(showLoading());
        yield call( () => {
            return fetch(action.url, {
                method: "GET",
            }).then(response => {
                response.blob().then(function(blob) {
                    downloadImg(blob, action.url.substr(action.url.lastIndexOf("/") + 1));
                });
            })
        });
        // payload processing
    } finally {
        yield put(hideLoading())
    }
}


export function mainVideo() {
    return {
        type: 'MAIN_PAGE_VIDEOS'
    }
}

export function* fetchMainVideo(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/albums/main-videos", {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status ));
        }

    } catch(e) {
        console.log(e);
    } finally {
        // yield put(hideLoading());
    }
}