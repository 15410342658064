import React, { Component } from 'react';
import {mediaUrl} from "../../tools/config";
import InfoblockLink from "../InfoBlocks/InfoblockLink";
import AdvertisingLabeling from '../AdvertisingLabeling/AdvertisingLabeling';

class RatesBigBanner extends Component {

    render() {

        let block = this.props.blocks[0];

        return (
            <InfoblockLink item={block}>
                <div className="b-internet-ipad__promo-block">
                    <AdvertisingLabeling
                        token={block && block.token}
                        advertiser={block && block.advertiser}
                    />
                    <div className="b-promo-block b-promo-block b-promo-block_theme_sale b-promo-block b-promo-block_color_black b-promo-block b-promo-block_adapt_sale">
                        <div className="b-promo-block__wrapper" style={{backgroundImage: "url(" + mediaUrl + block.imagePath + ")"}}>
                            <div className="b-promo-block__title">{block.title}</div>
                            <div className="b-promo-block__subtitle">{block.text}</div>
                        </div>
                    </div>
                </div>
            </InfoblockLink>
        )
    }
}

export default RatesBigBanner;


