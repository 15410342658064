import {
    call,
    put,
    select,
} from 'redux-saga/effects';
import { fetchClientApi, checkErrors } from '../tools/api-helper';

const isAuthSelector = (state) => state.client.isGuest;


// показываем или скрываем поле поиска вместо навигации в шапке
export function toggleSearchInputAction(data) {
    return {
        type: 'TOGGLE_SEARCH_INPUT',
        data,     
    }
}

// содержимое инпута поиска
export function stateSearchInputAction(data) {
    return {
        type: 'STATE_SEARCH_INPUT',
        data,
    }
}

// очищаем стор от результата поиска с подсказками
export function clearSearchHintAction() {
    return {
        type: 'CLEAR_SEARCH_HINT',   
    }
}

// для подсказок в поиске который появляется вместо меню
export function searchHintAction(businessLine = 'b2c', term = '', regionId = 66) {
    return {
        type: "SEARCH_HINT",
        businessLine,
        term,
        regionId,
    }
}

// для подсказок в поиске который появляется вместо меню
export function* fetchSearchHintSaga(action) {
    const isAuth = yield select(isAuthSelector);

    const {status, data, error} = yield call(() => {
        return fetchClientApi(`/search/hint/${action.businessLine}?term=${encodeURIComponent(action.term)}&region_id=${action.regionId}${!isAuth ? `&is_user=1` : ''}`, {
            method: 'GET'
        })
    });

    if (error) {
        console.log(error);
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

// для поиска на странице /search
export function searchPageAction(
    businessLine = 'b2c',
    term = '',
    regionId = 66,
    pageNum,
    entity = '',
) {
    return {
        type: "SEARCH_PAGE",
        businessLine,
        term,
        regionId,
        pageNum,
        entity,
    }
}

// для поиска на странице /search
export function* fetchSearchPageSaga(action) {
    const isAuth = yield select(isAuthSelector);
    
    const {status, data, error, pagination} = yield call(() => {
        return fetchClientApi(`/search/${action.businessLine}?term=${encodeURIComponent(action.term)}&region_id=${action.regionId}${action.pageNum ? `&page=${action.pageNum}` : ''}${action.entity ? `&entity=${action.entity}` : ''}${!isAuth ? `&is_user=1` : ''}`, {
            method: 'GET'
        })
    });

    yield put({ type: 'SEARCH_PAGE_PAGINATION', data: pagination });

    if (error) {
        console.log(error);
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

// очищаем стор от результата основного поиска
export function clearSearchPageAction() {
    return {
        type: 'CLEAR_SEARCH_PAGE',   
    }
}

// очищаем стор от состояния пагинации
export function clearSearchPagePaginationAction() {
    return {
        type: 'CLEAR_SEARCH_PAGE_PAGINATION',   
    }
}



// для популярных запросов
export function popularQueriesAction(businessLine = 'b2c', regionId = 66) {
    return {
        type: "POPULAR_QUERIES",
        businessLine,
        regionId,
    }
}

// для популярных запросов
export function* fetchPopularQueriesSaga(action) {
    const {status, data, error} = yield call(() => {
        return fetchClientApi(`/popular-queries/${action.businessLine}?region_id=${action.regionId}`, {
            method: 'GET'
        })
    });

    if (status === 200) {
        yield put({ type: 'POPULAR_QUERIES_SUCCESS', data });
    }

    if (error) {
        console.log(error);
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

// для пагинации результата поиска
export function searchResultPaginationAction() {
    return {
        type: 'SEARCH_PRESULT_PAGINATION',   
    }
}

// очищаем счетчик пагинации
export function clearSearchResultPaginationAction() {
    return {
        type: 'CLEAR_SEARCH_PRESULT_PAGINATION',   
    }
}

// содержит активный фильтр
export function currentFilterAction(data) {
    return {
        type: 'CURRENT_FILTER',
        data, 
    }
}


// для получения фильтров
export function listFiltersAction(businessLine = 'b2c', term = '', regionId = 66) {
    return {
        type: "LIST_FILTERS",
        businessLine,
        term,
        regionId,
    }
}

// для получения фильтров
export function* fetchListFiltersSaga(action) {
    const isAuth = yield select(isAuthSelector);

    const {status, data, error} = yield call(() => {
        return fetchClientApi(`/search/entity/${action.businessLine}?term=${encodeURIComponent(action.term)}&region_id=${action.regionId}${!isAuth ? `&is_user=1` : ''}`, {
            method: 'GET'
        })
    });

    if (error) {
        console.log(error);
    } else {
        yield put(checkErrors(action.type, data, status));
    }
}

// очищаем стор от списка фильтров
export function clearListFiltersAction() {
    return {
        type: 'CLEAR_LIST_FILTERS',   
    }
}


