import {
    fork,
    takeEvery, //обработает все
    takeLatest
} from 'redux-saga/effects';

import {
    fetchGetServiceCategories,
    fetchGetTariffsSupportList,
    fetchTariffArchiveList,
    fetchTariffFilterSettings,
    fetchTariffIndividualList,
    fetchTariffSelectionSettings,
    fetchTariffsList
} from "./tariffs";

import {
    fetchRegionsList,
    fetchRegionsListB2B
} from "./regions";

import {
    fetchInfoBlockImage,
    fetchInfoBlocksImagesList,
    fetchInfoBlocksList,
    fetchMnpBonuses
} from "./infoBlocks";

import {
    fetchServicesArchiveList,
    fetchServicesList,
    fetchServicesView,
    fetchTermsOfService,
} from "./services";

import {
    fetchInternetItemsList
} from "./internet";

import {
    fetchFaqList
} from "./questions";

import {
    fetchCompleteSetsList
} from "./completeSets";

import {
    fetchFeedbackFaqRequest,
    fetchFeedbackOrderRequest
} from "./feedback";

import {
    fetchCallingSearch,
    fetchCallingSearchPlaces,
    fetchRoamingMemos,
    fetchRoamingPlaceByAlias,
    fetchRoamingSearch,
    fetchRoamingSearchPlaces
} from "./roaming";

import {
    fetchfooterMenuList
} from "./footerMenu";

import {
    fetchMegaMenuList
} from "./megaMenu";

import {
    fetchActivateItem,
    fetchActivateMyTariff,
    fetchActivateTariffConstructor,
    fetchCheckAvailable,
    fetchCheckAvailableConstructor,
    fetchCheckBalanceLack,
    fetchCheckOperator,
    fetchCheckWord,
    fetchSendB2bRequest
} from "./activation";

import {
    fetchAddressAutocomplete,
    fetchComplainForm,
    fetchFormsList,
    fetchMnpFormEsiaSaga,
    fetchSubmitMnpForm,
    fetchVacancyRespond
} from "./forms";

import {
    fetchClosestShopsList,
    fetchShopsList
} from "./shops";

import {
    fetchSettingsList
} from "./settings";

import {
    calculateTariff,
    fetchAxisValues,
    fetchBanner,
    fetchGetTariffConstructor,
    fetchPdf,
    fetchPrice,
    fetchSettings,
    onPostTariffConstructor,
    setTariffParams
} from "./constructor";

import {
    fetchSms,
    fetchVerifySms
} from "./sms";

import {
    fetchComplain,
    fetchFastDecisions,
    fetchFeedbackForm,
    fetchFeedbackRequest,
    fetchFeedbackSubjects,
    fetchOnSubscription, fetchOnSubscriptionIn, fetchOnSubscriptionOut,
    fetchSupport,
    fetchSupportNotices,
    fetchUssd
} from "./support";

import {
    fetchDeferredPaymentPageSaga,
    fetchMotivPayPageSaga,
    fetchOtherPaymentMethods,
    fetchPaymentLimits,
    fetchPaymentMethodPageSaga,
    fetchPaymentMethods,
    fetchPaymentTransfersPageSaga,
    fetchPaymentTypesSaga,
    fetchPaymentsMethods,
    fetchPaymentsMethodsSpbSaga,
} from "./paymentsTransfers";

import {
    fetchPromotionsArchivePageSaga,
    fetchPromotionsCategorySaga,
    fetchPromotionsDetailArchivePageSaga,
    fetchPromotionsDetailPageSaga,
    fetchPromotionsPageSaga
} from "./promotions";

import {
    fetchGetPage,
    fetchRoutesList,
    fetchRoutesVacanciesList
} from "./routes";

import {
    fetchMainMenuList
} from "./mainMenu";

import {
    fetchCoverImagesList
} from "./coverImages";

import {
    fetchAuth,
    fetchBanServices,
    fetchChangeInfo,
    fetchChangePassword,
    fetchChangeStatus,
    fetchChangeTariff,
    fetchCheckToken,
    fetchDetails,
    fetchExchangeMins,
    fetchFormsSettings,
    fetchInfo,
    fetchLogout,
    fetchOrderDetails,
    fetchPayments,
    fetchRecovery,
    fetchRemainsHistory,
    fetchRemainsInfo,
    fetchServices,
    fetchTariffInfo,
    fetchToggleServices,
    fetchUserType,
    fetchExchangeMinsSend,
    fetchLkMenu,
    fetchLkSettings,
    fetchCostsServices,
    fetchAvailableServices,
    fetchRemainsTotal,
    fetchPromisedInfoSaga,
    fetchClientPromisedSaga,
    fetchCallLogin,
    fetchCheckLogin,
    fetchCallLogout,
    fetchServiceCashback,
    fetchGetMyAppeals, fetchGetAllMyAppeals, fetchGetFilteredMyAppeals
} from "./client";

import {
    fetchVacanciesList
} from "./vacancies";

import {
    fetchContactsList,
    fetchdataContactsSaga,
    fetchdataForSubscribersSaga,
} from "./contacts";

import {
    fetchCvForm
} from "./cvForm";

import {
    fetchNewsList, fetchNewsListShort
} from "./news";

import {
    fetchDownload, fetchMainVideo,
    fetchMediaList
} from "./media";

import {
    fetchCheckCallbackForm,
    fetchSendCallbackForm,
    fetchSlots
} from "./callback";

import { fetchDocsList } from "./docs";

import { fetchChatForm } from "./chat";

import { fetchContactsAbout, fetchDocsAbout, fetchGetCompanies, fetchGetCompanyParams } from "./company";

import {
    fetchListFiltersSaga,
    fetchPopularQueriesSaga,
    fetchSearchHintSaga,
    fetchSearchPageSaga,
} from './searchAction';

export function* rootSaga() {
    yield takeLatest("TARIFF_LIST_ARCHIVE", fetchTariffArchiveList);
    yield takeLatest("TARIFF_LIST_INDIVIDUAL", fetchTariffIndividualList);
    yield takeLatest("TARIFF_LIST", fetchTariffsList);
    yield takeLatest("SERVICE_LIST_ARCHIVE", fetchServicesArchiveList);
    yield takeLatest("SERVICE_LIST", fetchServicesList);
    yield takeLatest("SERVICE_VIEW", fetchServicesView);
    yield takeLatest("REGION_LIST", fetchRegionsList);
    yield takeLatest("REGION_LIST_B2B", fetchRegionsListB2B);
    yield takeLatest("TARIFF_FILTER_SETTINGS", fetchTariffFilterSettings);
    yield takeLatest("TARIFF_SELECTION_SETTINGS", fetchTariffSelectionSettings);
    yield takeLatest("GET_TARIFFS_SUPPORTS_LIST", fetchGetTariffsSupportList);
    yield takeLatest("GET_SERVICE_CATEGORIES", fetchGetServiceCategories);

    yield takeEvery("INFO_BLOCKS_LIST", fetchInfoBlocksList);
    yield takeEvery("INFO_BLOCKS_IMAGES_LIST", fetchInfoBlocksImagesList);
    yield takeEvery("INFO_BLOCK_IMAGE", fetchInfoBlockImage);

    yield takeLatest("MNP_BONUSES", fetchMnpBonuses);
    yield takeLatest("INTERNET_ITEMS_LIST", fetchInternetItemsList);
    yield takeLatest("FAQ_LIST", fetchFaqList);
    yield takeLatest("COMPLETE_SETS_LIST", fetchCompleteSetsList);
    yield takeLatest("FEEDBACK_ORDER_REQUEST", fetchFeedbackOrderRequest);
    yield takeLatest("FEEDBACK_FAQ_REQUEST", fetchFeedbackFaqRequest);
    yield takeLatest("ROAMING_SEARCH", fetchRoamingSearch);
    yield takeLatest("CALLING_SEARCH", fetchCallingSearch);
    yield takeLatest("FOOTER_MENU_LIST", fetchfooterMenuList);
    yield takeLatest("MEGA_MENU_ITEMS_LIST", fetchMegaMenuList);
    yield takeLatest("FORMS_LIST", fetchFormsList);
    yield takeLatest("MNP_FORM_SUBMIT", fetchSubmitMnpForm);
    yield takeLatest("MNP_FORM_ESIA", fetchMnpFormEsiaSaga);
    yield takeLatest("ROAMING_SEARCH_PLACES", fetchRoamingSearchPlaces);
    yield takeLatest("CALLING_SEARCH_PLACES", fetchCallingSearchPlaces);
    yield takeLatest("CHECK_AVAILABLE", fetchCheckAvailable);
    yield takeLatest("ACTIVATE_ITEM", fetchActivateItem);
    yield takeLatest("ADDRESS_AUTO_COMPLETE", fetchAddressAutocomplete);
    yield takeLatest("SHOPS_LIST", fetchShopsList);
    yield takeLatest("CLOSEST_SHOPS_LIST", fetchClosestShopsList);
    yield takeLatest("CHECK_OPERATOR", fetchCheckOperator);
    yield takeEvery("SETTINGS_LIST", fetchSettingsList);
    yield takeLatest("AXIS_VALUES", fetchAxisValues);
    yield takeLatest("TARIFF_PRICE", fetchPrice);
    yield takeLatest("CONSTRUCTOR_BANNER", fetchBanner);
    yield takeLatest("CONSTRUCTOR_SETTINGS", fetchSettings);
    yield takeLatest("CHECK_AVAILABLE_CONSTRUCTOR", fetchCheckAvailableConstructor);
    yield takeLatest("CHECK_BALANCE_LACK", fetchCheckBalanceLack);
    yield takeLatest("ACTIVATE_TARIFF_CONSTRUCTOR", fetchActivateTariffConstructor);
    yield takeLatest("CHECK_WORD", fetchCheckWord);
    yield takeLatest("CONSTRUCTOR_PDF", fetchPdf);
    yield takeLatest("SMS", fetchSms);
    yield takeLatest("VERIFY_SMS", fetchVerifySms);
    yield takeLatest("SUPPORT", fetchSupport);
    yield takeLatest("SUPPORT_NOTICES", fetchSupportNotices);
    yield takeLatest("SUPPORT_FAST_DECISIONS", fetchFastDecisions);
    yield takeLatest("SUPPORT_USSD", fetchUssd);
    yield takeLatest("SUPPORT_COMPLAIN", fetchComplain);
    yield takeLatest("FEEDBACK_SUBJECTS", fetchFeedbackSubjects);
    yield takeLatest("FEEDBACK_REQUEST", fetchFeedbackRequest);
    yield takeLatest("FEEDBACK_FORM", fetchFeedbackForm);
    yield takeLatest("ROUTE_LIST", fetchRoutesList);
    yield takeLatest("ROUTE_VACANCIES_LIST", fetchRoutesVacanciesList);
    yield takeLatest("MAIN_MENU_LIST", fetchMainMenuList);
    yield takeLatest("COVER_IMAGES_LIST", fetchCoverImagesList);
    yield takeLatest("ON_SUBSCRIPTION", fetchOnSubscription);
    yield takeLatest("ON_SUBSCRIPTION_IN", fetchOnSubscriptionIn);
    yield takeLatest("ON_SUBSCRIPTION_OUT", fetchOnSubscriptionOut);

    yield takeLatest("CHECK_TOKEN", fetchCheckToken);
    yield takeLatest("AUTH", fetchAuth);
    yield takeLatest("CALL_LOGIN", fetchCallLogin);
    yield takeLatest("CHECK_LOGIN", fetchCheckLogin);
    yield takeLatest("LOGOUT", fetchLogout);
    yield takeLatest("CALL_LOGOUT", fetchCallLogout);
    yield takeLatest("CLIENT_INFO", fetchInfo);
    yield takeLatest("CLIENT_REMAINS_INFO", fetchRemainsInfo);
    yield takeLatest("RECOVERY_PASSWORD", fetchRecovery);
    yield takeLatest("CHANGE_TARIFF", fetchChangeTariff);
    yield takeLatest("CHANGE_PASSWORD", fetchChangePassword);
    yield takeLatest("TARIFF_INFO", fetchTariffInfo);
    yield takeLatest("CHANGE_STATUS", fetchChangeStatus);
    yield takeLatest("MY_SERVICES", fetchServices);
    yield takeLatest("USER_TYPE", fetchUserType);
    yield takeLatest("MY_DETAILS", fetchDetails);
    yield takeLatest("MY_PAYMENTS", fetchPayments);
    yield takeLatest("TOGGLE_SERVICES", fetchToggleServices);
    yield takeLatest("EXCHANGE_MINS", fetchExchangeMins);
    yield takeLatest("ORDER_DETAILS", fetchOrderDetails);
    yield takeLatest("BAN_SERVICES", fetchBanServices);
    yield takeLatest("CHANGE_CLIENT_INFO", fetchChangeInfo);
    yield takeLatest("CLIENT_REMAINS_HISTORY", fetchRemainsHistory);
    yield takeLatest("EXCHANGE_MINS_SEND", fetchExchangeMinsSend);
    yield takeLatest("LK_FORMS_SETTINGS", fetchFormsSettings);
    yield takeLatest("LK_MENU", fetchLkMenu);
    yield takeLatest("LK_SETTINGS", fetchLkSettings);
    yield takeLatest("COSTS_SERVICES", fetchCostsServices);
    yield takeLatest("AVAILABLE_SERVICES", fetchAvailableServices);
    yield takeLatest("VACANCIES_LIST", fetchVacanciesList);
    yield takeLatest("CONTACTS_LIST", fetchContactsList);
    yield takeLatest("VACANCY_RESPOND", fetchVacancyRespond);
    yield takeLatest("CV_FORM", fetchCvForm);
    yield takeLatest("NEWS_LIST", fetchNewsList);
    yield takeLatest("DOCS_LIST", fetchDocsList);
    yield takeLatest("NEWS_LIST_SHORT", fetchNewsListShort);
    yield takeLatest("NEWS_LIST_MORE", fetchNewsList);
    yield takeLatest("MEDIA_IMG_LIST", fetchMediaList);
    yield takeLatest("MEDIA_IMG_LIST_MORE", fetchMediaList);
    yield takeLatest("MEDIA_VIDEO_LIST", fetchMediaList);
    yield takeLatest("MEDIA_VIDEO_LIST_MORE", fetchMediaList);
    yield takeLatest("MEDIA_LIST", fetchMediaList);
    yield takeLatest("MEDIA_LIST_MORE", fetchMediaList);
    yield takeLatest("MEDIA_ALBUM_ID", fetchMediaList);
    yield takeLatest("MAIN_PAGE_VIDEOS", fetchMainVideo);
    yield takeLatest("COMPLAIN_FORM", fetchComplainForm);
    yield takeLatest("CHAT_FORM", fetchChatForm);
    yield takeLatest("CHECK_CALLBACK_FORM", fetchCheckCallbackForm);
    yield takeLatest("SEND_CALLBACK_FORM", fetchSendCallbackForm);
    yield takeLatest("CHECK_SLOTS", fetchSlots);
    yield takeLatest("TERMS_OF_SERVICE", fetchTermsOfService);
    yield takeEvery("DOWNLOAD_IMG", fetchDownload);
    yield takeEvery("ACTIVATE_MY_TARIFF", fetchActivateMyTariff);
    yield takeEvery("GET_TARIFF_CONSTRUCTOR", fetchGetTariffConstructor);
    yield takeLatest("POST_TARIFF_CONSTRUCTOR", onPostTariffConstructor);
    yield takeLatest("SET_TARIFF_PARAMS", setTariffParams);
    yield fork(calculateTariff);
    yield takeLatest("CONTACTS_ABOUT", fetchContactsAbout);
    yield takeLatest("DOCS_ABOUT", fetchDocsAbout);
    yield takeLatest("COMPANY_POLICY", fetchGetCompanyParams);
    yield takeLatest("COMPANY_LICENSES", fetchGetCompanyParams);
    yield takeLatest("COMPANY_REQUISITES", fetchGetCompanyParams);
    yield takeLatest("COMPANY_ALL_LICENSES", fetchGetCompanyParams);
    yield takeLatest("CONTACTS_PARTNERS", fetchContactsList);
    yield takeLatest("CONTACTS_BUSINESS", fetchContactsList);
    yield takeLatest("ROAMING_PLACE", fetchRoamingPlaceByAlias);
    yield takeLatest("ROAMING_MEMOS", fetchRoamingMemos);
    yield takeEvery("GET_PAGE", fetchGetPage);
    yield takeLatest("COMPANIES_LIST", fetchGetCompanies);

    yield takeLatest("SEND_B2B_REQUEST", fetchSendB2bRequest);

    yield takeLatest("PAYMENTS_OTHER_PAYMENT_METHODS", fetchOtherPaymentMethods);
    yield takeLatest("PAYMENTS_METHODS", fetchPaymentsMethods);
    yield takeLatest("PAYMENTS_LIMITS", fetchPaymentLimits);
    yield takeLatest("PAYMENT_METHODS", fetchPaymentMethods);
    yield takeLatest("SEARCH_HINT", fetchSearchHintSaga);
    yield takeLatest("SEARCH_PAGE", fetchSearchPageSaga);
    yield takeLatest("POPULAR_QUERIES", fetchPopularQueriesSaga);
    yield takeLatest("LIST_FILTERS", fetchListFiltersSaga);
    yield takeLatest("MOTIV_PAY_PAGE", fetchMotivPayPageSaga);
    yield takeLatest("DEFERRED_PAYMENT_PAGE", fetchDeferredPaymentPageSaga);
    yield takeLatest("PAYMENT_TRANSFERS_PAGE", fetchPaymentTransfersPageSaga);
    yield takeLatest("PAYMENT_METHOD_PAGE", fetchPaymentMethodPageSaga);

    yield takeLatest("PROMOTIONS_PAGE", fetchPromotionsPageSaga);
    yield takeLatest("PROMOTIONS_PAGE_NEW", fetchPromotionsPageSaga);
    yield takeLatest("PROMOTIONS_CATEGORY", fetchPromotionsCategorySaga);
    yield takeLatest("PROMOTIONS_ARCHIVE_PAGE", fetchPromotionsArchivePageSaga);
    yield takeLatest("PROMOTIONS_ARCHIVE_PAGE_NEW", fetchPromotionsArchivePageSaga);
    yield takeLatest("PROMOTIONS_DETAIL_PAGE", fetchPromotionsDetailPageSaga);
    yield takeLatest("PROMOTIONS_DETAIL_ARCHIVE_PAGE", fetchPromotionsDetailArchivePageSaga);

    yield takeLatest("CLIENT_REMAINS_TOTAL", fetchRemainsTotal);
    yield takeLatest("DATA_FOR_SUBSCRIBERS", fetchdataForSubscribersSaga);
    yield takeLatest("DATA_CONTACTS", fetchdataContactsSaga);
    yield takeLatest("CLIENT_PROMISED_INFO", fetchPromisedInfoSaga);
    yield takeLatest("CLIENT_PROMISED", fetchClientPromisedSaga);
    yield takeLatest("PAYMENTS_METHODS_SPB", fetchPaymentsMethodsSpbSaga);
    yield takeLatest("SERVICE_CASHBACK", fetchServiceCashback);

    yield takeLatest("ALL_MY_APPEALS", fetchGetAllMyAppeals);
    yield takeLatest("FILTERED_MY_APPEALS", fetchGetFilteredMyAppeals);

    yield takeLatest("PAYMENT_TYPES", fetchPaymentTypesSaga);
}
