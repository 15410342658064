import React, {Component} from 'react';
import RatesServiceList from "./RatesServiceList";
import RatesAdditionalServices from "./RatesAdditionalServices";
import {mediaUrl} from '../../tools/config';

class RatesServiceCatList extends Component {

    render() {

        let cats = this.props.servicesByCats;
        let slug = this.props.slug;

        return (
            cats.map((cat, index) =>
                slug === cat.category.slug || slug === "" ?
                    <React.Fragment key={index}>
                        <div id={'services-'+cat.category.slug} className="b-services-list__services-group">
                            <div className="b-services-group b-services-group_adapt_default">
                                <div className="b-services-group__wrapper">
                                    <div className="b-services-group__header">
                                        <div className="b-counted-header b-counted-header b-counted-header_adapt_default b-counted-header b-counted-header_pl-small">
                                            <div className="b-counted-header__wrapper" id={cat.category.slug}>
                                                <img className={"b-counted-header__icon b-counted-header__icon"} src={mediaUrl + cat.category.iconPath}/>
                                                <div className="b-counted-header__title">{cat.category.title}
                                                    <div className="b-counted-header__counter">{cat.items.length}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <RatesServiceList items={cat.items} opened={slug === cat.category.slug}/>
                                </div>
                            </div>
                        </div>
                        {slug === cat.category.slug && cat.category.associatedServices.length > 0
                            ?   <RatesAdditionalServices title={"Сопутствующие услуги"} items={cat.category.associatedServices} />
                            :   ''
                        }
                    </React.Fragment>
                : ''
            )
        )
    }
}

export default RatesServiceCatList;