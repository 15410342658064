import React, { Component } from 'react';
import Icon from "../common/Icon";
import {getCookie, setUtmCookie, checkUtmParams}  from '../../tools/tools';

class ChangeRateOk extends Component {

    render() {

        let {
            onClick,
            item,
            type,
            title,
            description,
            btnText = 'ОК',
        } = this.props;

        if (!title) {
            switch (type) {
                case 'tariff':
                    title = "Тариф «" + item.name + "» успешно подключен!";

                    if (typeof getCookie("utm_start") !== "undefined") {
                        setUtmCookie(2, "utm_finish", checkUtmParams());
                    }

                    break;
                case 'service':
                    title = "Услуга «" + item.name + "» успешно подключена!";
                    break;
                case 'recovery':
                    title = "Пароль отправлен по смс";
                    break;
            }
        } else if (title.indexOf('{{name}}') !== -1) {
            title = title.replace('{{name}}', item ? item.name : '')
        }


        return (
            <div className="mnp-popup-ok swiper-container mnp-popup__wrapper">
                <div className="text-center">
                    {type === "error" ? null : <Icon name={'icon-form-ok'} className={"mnp-popup-ok__icon"}/>}
                    <div className="b-plug-auth__title">{title}</div>
                    <div>{description}</div>
                    <button type="button" className="u-btn u-btn_default u-btn_adapt_mobile-sm" onClick={onClick}>{btnText}</button>
                </div>
            </div>
        )
    }
}

export default ChangeRateOk;