import React, {Component} from 'react'
import {phoneMask} from '../../tools/mask'
import InputError from "../common/InputError"
import Select from "../common/Select";

export default class BlockNumberForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            reason: "",
            error: "",
            propError: ""
        }
    }

    static getDerivedStateFromProps(props, state){
        if (state.propError !== props.error) {
            return {...state, error: props.error, propError: props.error}
        }
        return state;
    }

    onChange = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState({
            reason: target.value
        })
    };

    onBlur = (e) => {
        var target = e.target || e.nativeEvent.target;

        this.setState({
            error: target.value ? "" : "Обязательно для заполнения"
        })
    };

    onFocus = () => {

        this.setState({error: ""})
    };

    onSubmit = (e) => {

        e.preventDefault();

        let {
            reason
        } = this.state;

        this.setState({
            error: reason ? "" : "Обязательно для заполнения"
        }, () => {
            let {
                error
            } = this.state;

            if (!error) {
                this.props.onSubmit(JSON.stringify({reason}))
            }
        });
    };

    inputProps = (name) => {

        let {
            reason
        } = this.state;

        return {
            name: name,
            value: reason,
            onChange: this.onChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus
        }
    };

    render(){

        let {
            number = "",
            formSettings = {}
        } = this.props;

        let {error} = this.state;

        let {
            block_form_description,
            block_reason_comment,
            block_description_placeholder,
        } = formSettings;

        return <div className="b-lk-block b-lk-block_adapt-default">
            <div className="b-lk-block__title">Блокировка номера <span className="no-wrap">{phoneMask(number)}</span></div>
            {block_form_description ? <div className="b-lk-block__sub-title">{block_form_description}</div> : null}
            <div className="b-lk-block__form">
                <form onSubmit={this.onSubmit}>
                    <div className="b-lk-block__select">
                        {/*<Select value={1}*/}
                                {/*options={[{value :1, label: "Кража или утеря номера"}]}*/}
                                {/*className="b-input-select"*/}
                                {/*disabled={true}*/}
                        {/*/>*/}
                        <input value="Временная блокировка номера"
                               className="b-input-text__input"
                               disabled={true}
                        />
                        {block_reason_comment ? <div className="b-lk-block__sub-text">
                            {block_reason_comment}
                        </div> : null}
                    </div>
                    <div className="b-lk-block__textarea">
                        <div className="b-input-textarea undefined">
                            <div className="b-input-textarea__label b-input-textarea__label b-input-textarea__label_upper">
                                <div className="b-input-textarea__label-text">Причина блокировки</div>
                            </div>
                            <div className="b-input-textarea__textarea-wrap">
                                <textarea className={"b-input-textarea__textarea"+(error ? " has-error" : "")}
                                          placeholder={block_description_placeholder}
                                          {...this.inputProps('reason')}
                                />
                                <InputError hasError={!!error} errorText={error}/>
                            </div>
                        </div>
                    </div>
                    <div className="b-lk-block__btn-cont">
                        <button className="b-lk-block__btn" type="submit">Заблокировать</button>
                    </div>
                </form>
            </div>
        </div>

    }
}