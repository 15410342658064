import React, {Component} from "react";

class ContactsHR extends Component {

    render() {
        let data = this.props.contactsList ? this.props.contactsList[0] : null;

        return(
            <React.Fragment>
                {data && data.title ? <div className="page__title-3 page__title-3_pp_contacts-vacancy" dangerouslySetInnerHTML={{__html: data.title}} /> : null}

                    {data ? <div className="b-contacts-str__items-list">
                        <div className="b-contacts-str__item">
                            <div className="b-contacts-str__label">Телефон:</div>
                            <div className="b-contacts-str__inner-list">
                                {data && data.phone.split(';').map((phone) =>
                                    <a className="b-contacts-str__inner-item" href={phone ? "tel:"+phone.replace(/\D/, '') : "#"}>{ phone }</a>
                                )}
                            </div>
                        </div>
                        <div className="b-contacts-str__item">
                            <div className="b-contacts-str__label">E-mail:</div>
                            {data && data.email ? <div className="b-contacts-str__inner-list">
                                <a className="b-contacts-str__inner-item b-contacts-str__inner-item_href"
                                   href={'mailto:' + data.email }>{ data.email }</a>
                            </div> : null}
                        </div>
                    </div> : null}

            </React.Fragment>
        )
    }
}

export default ContactsHR;