import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icon from "../../components/common/Icon";
import {phoneMask, onlyLetters} from "../../tools/mask";
import {emailValidator, phoneValidator, usernameValidator, emptyTextarea} from "../../tools/form-helper";
import {getCookie, setCookie} from "../../tools/tools";
import {cookieDomain, cookiePrefix} from "../../tools/config";

class AvayaChat extends Component {

    constructor(props){
        super(props);
        this.state = {
            agreement: false,
            report: false,
            checkForСheckbox: false,
            mainArea: '',
            location: '',
            fields: {
                fio: {
                    typeInput: "text",
                    label: "фио",
                    name: "fio",
                    value: "",
                    required: true,
                    mask: onlyLetters,
                    validators: [usernameValidator],
                    hasError: false,
                    placeholder: "",
                    disabled: false
                },
                phone: {
                    typeInput: "text",
                    label: "телефон",
                    name: "phone",
                    value: "",
                    required: true,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: false,
                    placeholder: "+7",
                    disabled: false
                },
                email: {
                    typeInput: "email",
                    label: "адрес электронной почты",
                    value: "",
                    name: "email",
                    required: true,
                    validators: [emailValidator],
                    hasError: false,
                    placeholder: "info@mail.ru",
                    disabled: false
                },
                textarea: {
                    typeInput: "textarea",
                    label: "Текст сообщения",
                    name: "textarea",
                    value: "",
                    options: [],
                    placeholder: "Текст сообщения",
                    required: true,
                    hasError: false,
                    validators: [emptyTextarea],
                    disabled: false
                },
                outmessage: {
                    typeInput: "textarea",
                    name: "outmessage",
                    value: "",
                    required: true,
                    hasError: false,
                },
            },
            scrolled: typeof window === 'undefined' ? false : window.scrollY > 100
        }

        this.maxMessageLength = 8000;
        this.maxFirstMessageLength = 8000;
    }

    static contextTypes = {
        fields: PropTypes.object,
        locationPath: PropTypes.string
    };

    setFieldGroup = ( fioValue = '', phoneValue = '', emailValue = '') => {
        this.setState({
            fields: {
                ...this.state.fields,
                fio: {
                    ...this.state.fields.fio,
                    value: fioValue,
                    hasError: false
                },
                phone: {
                    ...this.state.fields.phone,
                    value: phoneValue,
                    hasError: false
                },
                email: {
                    ...this.state.fields.email,
                    value: emailValue,
                    hasError: false
                }
            }
        })
    };

    componentDidMount() {
        if (typeof document !== 'undefined') {
            const textarea = document.getElementById('outmessage') || undefined;
            if (typeof textarea !== 'undefined') {
                textarea.addEventListener('input', this.resize)
            }
            if (typeof window !== "undefined") {
                window.addEventListener("beforeunload", this.onUnload);
             }
        }

        // место распределения зон ответственности
        const responsibilityAreas = this.props.items && this.props.items.responsibility_areas;
        const mainAreaProps = responsibilityAreas.find((x) => {
            if(this.props.isB2b && x.is_main){
                return x.business_line_id === "B2B"
            }
            if(!this.props.isB2b && x.is_main){
                return x.business_line_id === "B2C"
            }
            return null
        });
        
        if (responsibilityAreas && this.state.mainArea === '') {
            this.setState({
                mainArea: mainAreaProps.name
            });

            this.setArea(mainAreaProps.name);
        }
        if (this.state.location === '') {
            this.setState({
                location: this.context.locationPath
            })
        }

        let avayaCookie = ["guid", "ak", "reloadTimestamp", "users", "custDetails",
        "message", "contextId", "chatAttrCount", "totalAttributes", "custDataOpened"];
        avayaCookie.map((item) => {
            let val = getCookie(item);

            if (val) {
                sessionStorage.setItem(item, val);
                if (["custDetails"].indexOf(item) === -1) {
                    setCookie(item, val, {
                        path: '/',
                        expires: -1,
                        domain: cookieDomain
                    });
                }
            }
        });

        let fio = "", phone = "", email = "", messageAuthForm = "";
        let data = sessionStorage.getItem("custDetails");
        if (data) {
            data = JSON.parse(data);
            if (data) {
                fio = data.firstName;
                phone = data.phone.number;
                email = data.email;
            }
        }

        if (!this.props.client.isGuest && this.props.client.clientInfo.username) {
            fio = this.props.client.clientInfo.name;
            phone = this.props.client.clientInfo.username;
        }

        if (getCookie("fio")) fio = getCookie("fio");
        if (getCookie("phone")) phone = getCookie("phone");
        if (getCookie("email")) email = getCookie("email");
        if (getCookie("messageAuthForm")) messageAuthForm = getCookie("messageAuthForm");

        this.setFieldValue(false, phone, fio, email, messageAuthForm)

        document.addEventListener('keypress', this.onKeyboardSend)
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillReceiveProps(nextProps){
        if (!this.props.client.clientInfo.username && nextProps.client.clientInfo.username) {
            let fio = getCookie("fio") ? getCookie("fio") : nextProps.client.clientInfo.name;
            let phone = getCookie("phone") ? getCookie("phone") : nextProps.client.clientInfo.username;
            let email = getCookie("email") ? getCookie("email") : this.state.fields.email.value;
            let messageAuthForm = getCookie("messageAuthForm") ? getCookie("messageAuthForm") : this.state.fields.textarea.value;
            this.setFieldValue(false, phone, fio, email, messageAuthForm);
        }

        if (this.props.client.isGuest !== nextProps.client.isGuest) {
            if (typeof window !== "undefined"){
                window.chatLogon.userGuest = nextProps.client.isGuest;
            }
        }

        if (typeof window !== "undefined") {
            if (!this.props.client.isGuest && window.chatConfig) {
                if (window.chatConfig.logonHandleUser !== this.props.client.clientInfo.username) {
                    window.chatConfig.logonHandleUser = this.props.client.clientInfo.username
                }
            }
        }
    }

    componentWillUnmount(){
        if (typeof document !== 'undefined') {
            const textarea = document.getElementById('outmessage') || undefined;
            textarea.removeEventListener('outmessage', this.resize);
            if (typeof window !== "undefined") {
                window.removeEventListener("beforeunload", this.onUnload);
            }

        }
        let storageArea = localStorage.getItem('currentArea');
        if (!!storageArea) localStorage.removeItem('currentArea');
        document.removeEventListener('keypress', this.onKeyboardSend)
        window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        if (this.state.scrolled !== window.scrollY > 100) {
            this.setState({scrolled: window.scrollY > 100});
        }
    }

    setArea = (mainArea) => {
        let current_page = this.context.locationPath.replace(/\/$/, '');
        const responsibilityAreas = this.props.items.responsibility_areas;
        const pageAreaMas = [];
        let storageArea = localStorage.getItem('currentArea');

        responsibilityAreas.forEach(el => {
            if (!!el.pages) {
                const page = el.pages.filter(pages => pages.url === current_page);

                if (!page || !page.length) return;
                pageAreaMas.push({...el})
            }
        });

        if ( pageAreaMas.length > 0 ) {
            let pageAreaProps = pageAreaMas.map((item) => item.name);

            if (storageArea !== pageAreaProps) {
                if (window.chatLogon) {
                    window.chatLogon.attributes = pageAreaProps;
                } else {
                    localStorage.setItem('currentArea', JSON.stringify(pageAreaProps));
                }
            }
        } else {
            if (storageArea !== mainArea) {
                if (window.chatLogon) {
                    window.chatLogon.attributes = [mainArea];
                } else {
                    localStorage.setItem('currentArea', JSON.stringify([mainArea]));
                }
            }
        };
    };

    resize =()=> {
        const textarea = document.getElementById('outmessage') || undefined;
        textarea.style.cssText = 'height:auto;';
        textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
    };

    onUnload = e => {
        setCookie("fio", this.state.fields.fio.value, {
            path: '/',
            expires: 60,
            domain: cookieDomain
        });
        setCookie("phone", this.state.fields.phone.value, {
            path: '/',
            expires: 60,
            domain: cookieDomain
        });
        setCookie("email", this.state.fields.email.value, {
            path: '/',
            expires: 60,
            domain: cookieDomain
        });
        setCookie("messageAuthForm", this.state.fields.textarea.value, {
            path: '/',
            expires: 60,
            domain: cookieDomain
        });

        let elem = document.getElementById("liveChatLink");
        let status = elem.getAttribute("status");

        // if ( status === "open" && avayaGlobal.getSessionStorage("custDataOpened") && window.webSocket === undefined ) {
            // avayaGlobal.removeFromSessionStorage("custDataOpened")
        // }
    };

    clearFieldOutmessage =()=> {
        this.resize();

        this.setState({
            fields: {
                ...this.state.fields,
                outmessage: {
                    ...this.state.fields.outmessage,
                    value: ""
                }
            }
        });
    };

    sentFirstMessage =()=> {
        if (window.chatLogon && window.chatLogon.userGuest !== this.props.client.isGuest) {
            window.chatLogon.userGuest = this.props.client.isGuest;
        }
        if (this.state.fields.textarea.value !== '') {
            localStorage.setItem('userMessage', this.state.fields.textarea.value);
        }
        let isGuest = this.props.client.isGuest;
        let clientInfo = this.props.client.clientInfo;
        let clientInfoNotEmpty = Object.keys(clientInfo).length > 0;
        let fields = this.state.fields;
        let clientPhoneValue = clientInfo && clientInfo.username ? clientInfo.username : '';
        let clientFioValue = clientInfo && clientInfo.name ? clientInfo.name : '';

        if ( !isGuest && clientInfoNotEmpty && clientPhoneValue && fields.fio.value !== clientFioValue && fields.phone.value !== clientPhoneValue ) {
            this.setFieldValue( true );
        }
    };

    setFieldValue = ( clearFields = false, clientPhoneValue = '', clientFioValue = '', mailFromChat = '', messageAuthForm = '' ) => {
        let phoneValue = clientPhoneValue ? phoneMask(clientPhoneValue) : null;
        if ( !!clientPhoneValue ) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        value: phoneValue,
                        hasError: false
                    },
                    fio: {
                        ...this.state.fields.fio,
                        value: clientFioValue,
                        hasError: false
                    },
                    email: {
                        ...this.state.fields.email,
                        value: mailFromChat
                    },
                    textarea: {
                        ...this.state.fields.textarea,
                        value: messageAuthForm,
                        hasError: false
                    }
                }
            });
        }
        if ( clearFields ) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        value: ''
                    },
                    email: {
                        ...this.state.fields.email,
                        value: ''
                    },
                    fio: {
                        ...this.state.fields.fio,
                        value: ''
                    },
                    textarea: {
                        ...this.state.fields.textarea,
                        value: ''
                    }
                }
            });
        }
    };

    toggleCheckboxes = () => {
        const agreement = document.getElementById('agreement-with-policy');
        const report = document.getElementById('transcript-chat');

        if (agreement !== null && agreement.checked) {
            this.setState({agreement: true});
        } else {
            this.setState({agreement: false});
        }
        if (report !== null && report.checked) {
            this.setState({report: true});
        } else {
            this.setState({report: false});
        }

        if (!this.state.agreement) {
            this.setState({ checkForСheckbox: false });
        }
    };

    checkSizeForm =()=> {
        if (typeof document !== 'undefined') {
            let personalForm = document.getElementsByClassName("personalForm") || undefined;
            let heightPersonalForm = personalForm[0].style.height;
            let chatPanel = document.getElementById("chatPanel");
            let heightChatPanel = chatPanel.style.height;

            if ( heightPersonalForm !== "auto" && heightChatPanel !== "auto" ) {
                personalForm[0].style.height="auto";
                chatPanel.style.height="auto";
            }
        }
    };

    onChange = (name, value) => {
        if (name === 'textarea' && value.length > this.maxFirstMessageLength) {
            value = value.substr(0, this.maxFirstMessageLength)
        }
        if (name === 'outmessage' && value.length > this.maxMessageLength) {
            value = value.substr(0, this.maxMessageLength)
        }
        let fields = {...this.state.fields};
        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        if (!value) {
            fields[name].hasError = true;
        } else {
            fields[name].hasError = false;
        }
        this.setState({fields});
    };

    onFocus = (name) => {
        return () => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    onBlur = (name) => {
        return () => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    clickBtn =()=> {
        let { agreement, report } =this.state;
        const agreementChek = document.getElementById('agreement-with-policy');
        const reportChek = document.getElementById('transcript-chat');

        let elem = document.getElementById("liveChatLink");
        let elemChatInterface = document.getElementById("chatInterface");
        let elemCount = document.getElementById("liveChatLinkOpen--notice");
        let displayStyleCount = elemCount.style.display;
        let heightChatInterface = elemChatInterface.style.maxHeight;

        let countItem = elemCount.getAttribute("count");
        let status = elem.getAttribute("status");

        const mainArea = this.state.mainArea;

        if (typeof window !== "undefined") {
            if (typeof window.webSocket === "undefined" && status === "close") {
                ewt.requestEwt();
            }
        }

        if ( status === "close" ) {
            this.setArea(mainArea)
            if (this.state.location !== this.context.locationPath){
                this.setState({
                    location: this.context.locationPath
                });
                ewt.requestEwt();
            }
        }

        if ( agreement) {
            this.setState( {
                agreement: false
            })
        }
        if ( report) {
            this.setState( {
                report: false
            })
        }
        if (agreementChek !== null && agreementChek.checked) {
            agreementChek.checked = false
        }
        if (reportChek !== null && reportChek.checked) {
            reportChek.checked = false
        }

        if ( status === "close" ) {
            elem.setAttribute("status", "open");

            if ( displayStyleCount === "block" ) {
                elemCount.style.display="none";
            }

            elemCount.setAttribute("count", "0");
        } else {
            elem.setAttribute("status", "close");

            if ( displayStyleCount === "none" && +countItem > 0 ) {
                elemCount.style.display="block";
            }
        }

        if ( heightChatInterface === 'none' ) {
            elemChatInterface.style.maxHeight="432px";
        }

        let isGuest = this.props.client.isGuest;
        let clientInfo = this.props.client.clientInfo;
        let fields = this.state.fields;
        let fioFromChat = fields.fio.value;
        let phoneFromChat = fields.phone.value;
        let mailFromChat = fields.email.value;

        if ( status === 'close' ) {
            if (!isGuest) {
                this.setFieldValue(false, clientInfo.username, clientInfo.name, mailFromChat);
            }
            if ( fields.textarea.value !== '' ) {
                this.setState({
                    fields: {
                        ...this.state.fields,
                        textarea: {
                            ...this.state.fields.textarea,
                            value: '',
                            hasError: false
                        }
                    }
                })
            }
        }

        if (typeof window !== "undefined") {
            if (!this.props.client.isGuest) {
                if (window.chatConfig.logonHandleUser !== this.props.client.clientInfo.username) {
                    window.chatConfig.logonHandleUser = this.props.client.clientInfo.username
                }
            }
        }

        if (window.chatLogon && window.chatLogon.userGuest !== this.props.client.isGuest) {
            window.chatLogon.userGuest = this.props.client.isGuest;
        }
        let x = document.querySelector('.ui-dialog-titlebar');
        if (!!x) x.scrollIntoView();
    };

    goToChat =()=> {
        if (typeof document !== 'undefined') {
            let goToChatAttr = document.getElementById("goToChat");
            let toChatAttrBlocking = goToChatAttr.getAttribute("blocking")

            if (toChatAttrBlocking === 'false') {
                chatLogon.gatherDetails(true)
            }
        }
    };

    checkboxCheck =(agreement)=> {
        if (!agreement) {
            this.setState({checkForСheckbox: true});
        }
    };

    addAtrBtn =(agreement)=> {
        if (typeof document !== 'undefined') {
            let goToAuthAttr = document.getElementById("goToAuth");
            let goToChatAttr = document.getElementById("goToChat");
            let authAttrtusBlocking = goToAuthAttr.getAttribute("blocking")
            let toChatAttrBlocking = goToChatAttr.getAttribute("blocking")
            if ( agreement ) {
                if (authAttrtusBlocking === 'true' && toChatAttrBlocking === 'true') {
                    goToAuthAttr.setAttribute("blocking", "false");
                    goToChatAttr.setAttribute("blocking", "false");
                }
            } else {
                goToAuthAttr.setAttribute("blocking", "true");
                goToChatAttr.setAttribute("blocking", "true");
            }
        }
    };

    onKeyPress = (e, validForm) => {
        if ( e.ctrlKey && validForm ) {
            this.sentFirstMessage();
            chatLogon.gatherDetails()
        }
    };

    onKeyboardSend = (e) => {
        if ((e.keyCode == '0xA' || e.keyCode == '0xD') && e.ctrlKey) {
            setTimeout(this.clearFieldOutmessage, 200)
        }
    };

    render() {

        let { items } = this.props;
        let { agreement, report, checkForСheckbox } = this.state;
        let { fio, phone, email, textarea, outmessage } = this.state.fields;

        const mutableClass = [''];

        if ( agreement ) {
            mutableClass.push('welcome-form-btn-default--enabled')
            this.addAtrBtn(agreement);
        } else {
            this.addAtrBtn(agreement);
        }

        let validForm = false;

        if ( report ) {
            if ( !fio.hasError && fio.value !== '' &&
                !phone.hasError && phone.value !== '' &&
                !email.hasError && email.value !== '' &&
                !textarea.hasError && textarea.value !== '' ) {
                    validForm = true;
            }
        } else if ( !fio.hasError && fio.value !== '' &&
            !phone.hasError && phone.value !== '' &&
            !textarea.hasError && textarea.value !== '' ) {
                validForm = true;
        }

        // узнаем, авторизованы ли мы?
        const hideBtn = this.props.client && !this.props.client.isGuest;

        return (<div className={"avaya-chat-wrap"+(this.state.scrolled ? " scrolled" : "")}>
            <div id="chatPanel" title={ items && items.form.chat_forms_title }>
                <div id="chatWelcomeForm">
                    <div className="chat-welcome-msg-wrap">
                        <div className="chat-welcome-msg-position">
                            <div className="chat-welcome-logo" />
                            <div className="chat-welcome-dialog-wrap">
                                <Icon className="u-icon welcome-dialog-operator-icon" name="icon_operator"/>
                                <div className="chat-message-wrap">
                                    { items && items.form.chat_form_hello_string ?
                                        <span className="chat-message__operatop">{ items && items.form.chat_form_hello_string }</span>
                                        : "Добавьте текст приветствия"
                                    }
                                    <span className="msg-dbcheck">
                                        <Icon className="u-icon icon_msg-dblcheck" name="icon_msg-dblcheck"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="chat-welcome-alert">
                            <Icon className="u-icon chat-welcome-alert--icon" name="icon_operator"/>
                            <div className="chat-welcome-alert--text" dangerouslySetInnerHTML={{ __html: items && items.form.chat_form_error_message }} />
                        </div>
                    </div>

                    <div className="welcome-form-btn-wrap">
                        {items.form.chat_form_button_selecting_topic_treatment_n1 ?
                            <button
                                id="goToAuth"
                                className={mutableClass.join(' ') + ' welcome-form-btn-default welcome-form-btn welcome-form-btn__color welcome-form-btn__color-indent' + (hideBtn ? '' : ' welcome-form-btn__hide')}
                                onClick={ () => {this.clearFieldOutmessage(); this.checkboxCheck(agreement)}}
                                dangerouslySetInnerHTML={{ __html: items && items.form.chat_form_button_selecting_topic_treatment_n1 }} />
                            : null
                        }
                        { items.form.chat_form_button_selecting_topic_treatment_n2 ?
                            <button
                                id="goToChat"
                                className={mutableClass.join(' ') + ' welcome-form-btn-default welcome-form-btn welcome-form-btn__colorless welcome-form-btn__colorless-indent'}
                                onClick={ () => {this.clearFieldOutmessage(); this.checkboxCheck(agreement); this.goToChat()} }
                                dangerouslySetInnerHTML={{ __html: hideBtn ? (items && items.form.chat_form_button_selecting_topic_treatment_n2) : 'Задать вопрос' }} />
                            : null
                        }
                        { items.form.chat_form_consent_processing_personal_data ?
                            <div className="row-checkbox">
                                <label className="checkbox--welcome-form checkbox">
                                    <input id="agreement-with-policy" className="checkbox--welcome-form" type="checkbox" onChange={this.toggleCheckboxes}/>
                                    <div className={(checkForСheckbox ? "checkbox-error " : "") + "checkbox__text--welcome-form checkbox__text"}
                                        dangerouslySetInnerHTML={{ __html: items && items.form.chat_form_consent_processing_personal_data }}
                                    />
                                </label>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div id="chatForm" className="chatFormAvaya">
                    <div className="wrap-field">
                        <div className="form-label-wrap">
                            <div className="formLabel" id="nameLabel">{fio.label}</div>
                            <span className="orange chat-label-dot">*</span>
                        </div>
                        <div className="formField">
                            <input
                                type={fio.typeInput}
                                id="user-chat"
                                value={fio.value}
                                className={fio.hasError ? 'chat-form-fild--error' : ''}
                                maxLength="50"
                                placeholder={fio.placeholder}
                                onChange={(e) => {this.onChange(fio.name, e.target.value)}}
                                onBlur={this.onBlur(fio.name)}
                                onFocus={this.onFocus(fio.name)}
                            />
                        </div>
                    </div>

                    <div className="wrap-field">
                        <div className="form-label-wrap">
                            <div className="formLabel" id="phoneLabel">{phone.label}</div>
                            <span className="orange chat-label-dot">*</span>
                        </div>

                        <div className="formField">
                            <input
                                type={phone.typeInput}
                                id="phone-chat"
                                inputMode="tel"
                                value={phone.value}
                                className={
                                    phone.hasError ? 'chat-form-fild--error' :
                                    hideBtn ? ' block-field-phone ' : ''
                                }
                                placeholder={phone.placeholder}
                                maxLength="32"
                                pattern="((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}"
                                title=""
                                onChange={(e) => {this.onChange(phone.name, e.target.value)}}
                                onBlur={this.onBlur(phone.name)}
                                onFocus={this.onFocus(phone.name)}
                            />
                        </div>
                     </div>

                    <div className="wrap-field wrap-field__textarea">
                        <div className="formField form-field__textarea">
                            <input type="text" id="topic" maxLength="255" title="" pattern="([a-zA-Z0-9])+([_~\-.][a-zA-Z0-9]+)*" placeholder="" />
                            <textarea
                                type={textarea.typeInput}
                                id="message"
                                maxLength={this.maxFirstMessageLength}
                                title=""
                                pattern=""
                                placeholder={textarea.placeholder}
                                value={textarea.value}
                                className={'text-area-chat-scroll' + (textarea.hasError ? ' chat-form-fild--error' : '')}
                                onChange={(e) => {this.onChange(textarea.name, e.target.value)}}
                                onBlur={this.onBlur(textarea.name)}
                                onFocus={this.onFocus(textarea.name)}
                                onKeyPress={(e) => {this.onKeyPress(e, validForm)}}
                            />
                            <div className="open-button-wrap">
                                <button id="openbutton-chat" onClick={ () => {this.sentFirstMessage(); chatLogon.gatherDetails()} } className={"button" + (validForm ? " active" : "")}/>
                            </div>
                        </div>
                    </div>
                    {items.form.chat_form_consent_receive_transcripts ?
                        <React.Fragment>
                            <div className="row-checkbox checkbox-wrap--raport">
                                <label className="checkbox--welcome-form checkbox" htmlFor="transcript-chat">
                                    <input
                                        className="checkbox--welcome-form"
                                        type="checkbox" id="transcript-chat"
                                        value=""
                                        onChange={this.toggleCheckboxes}
                                        onClick={this.checkSizeForm}
                                    />
                                    <div className="checkbox__text--welcome-form checkbox__text"
                                    dangerouslySetInnerHTML={{ __html: items && items.form.chat_form_text_consent_receive_transcripts }} />
                                </label>
                            </div>
                            {report ?
                                <React.Fragment>
                                    <div className="formLabel" id="emailLabel">{email.label}</div>
                                    <div className="formField">
                                        <input
                                            type={email.typeInput}
                                            id="email-chat"
                                            maxLength="255"
                                            placeholder={email.placeholder}
                                            value={email.value}
                                            className={email.hasError ? 'chat-form-fild--error' : ''}
                                            onChange={(e) => {this.onChange(email.name, e.target.value)}}
                                            onBlur={this.onBlur(email.name)}
                                            onFocus={this.onFocus(email.name)}
                                        />
                                    </div>
                                </React.Fragment>
                            : null}
                        </React.Fragment>
                    : null}

                    <div className="formLabel" />
                    <div id="loginErrorsDiv" />

                </div>

                <div id="chatInterface" className="chat-interface">
                    <div id="messages" className="text-area-chat-scroll" />
                    <div id="controls">
                        <textarea
                            type="text"
                            id="outmessage"
                            value={outmessage.value}
                            onChange={(e) => {this.onChange(outmessage.name, e.target.value)}}
                            className="chatField text-area-chat-scroll"
                            placeholder="Текст сообщения"
                        />
                        <button
                            type="button"
                            id="sendbutton-chat"
                            onClick={ () => {webChat.sendChatMessage();this.clearFieldOutmessage();} }
                            className={"button" + (outmessage.value.trim().length > 0 && outmessage.value !== "" ? "" : " sendbutton-chat--lock")}
                        />
                    </div>
                </div>

            </div>

            <div className="liveChatLinkOpen" id="liveChatLink" status="close" onClick={ () => {this.clickBtn()} } >
                <div id="liveChatLinkOpen--notice" count="0" />
            </div>
        </div>)
    }
}

export default AvayaChat;
