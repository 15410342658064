import { hideLoading, showLoading } from 'react-redux-loading-bar';
import {
    call,
    put
} from 'redux-saga/effects';

import {
    checkErrors,
    fetchClientApi
} from '../tools/api-helper';


export function mainMenuList(regionId, isB2b = false) {
    return {
        type: 'MAIN_MENU_LIST',
        regionId,
        isB2b
    }
}

export function* fetchMainMenuList(action) {
    try {
        yield put(showLoading());
        const { status, data, error } = yield call(() => {
            return fetchClientApi("/main-menu/" + action.regionId + `${action.isB2b ? '?is_b2b=1' : ''}`, {
                method: 'GET',
            })
        });
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    } finally {
        yield put(hideLoading());
    }
}