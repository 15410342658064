import React, {Fragment} from "react";

import Icon from "../common/Icon";
import LinkC from "../common/LinkC";

export default function SuccessForm({title, text, icon = true, linkButton = true, close = () => {}, fixedBtnOk = false}){

    return <div className="b-success-modal b-success-modal_adapt-default">
        {icon ? <Icon className="b-success-modal__ico u-icon u-icon_modal-ok" name="icon_modal-ok"/> : null}
        <div className={`b-success-modal__title ${fixedBtnOk ? 'b-success-modal__title__fixed_width' : ''}`}>{title}</div>
        {text ? <div
          className={`b-success-modal__text`}
          dangerouslySetInnerHTML={{__html: text}}
        /> : null}
        {linkButton ? <LinkC to="/" className="b-success-modal__btn">Вернуться на главную</LinkC> :
        <button className={`u-btn b-success-modal__btn ${fixedBtnOk ? 'b-success-modal__btn__fixed_width' : ''}`} onClick={close}>OK</button>}
    </div>
}
