import Complain, {complainRequests} from "../containers/Rates/Complain";
import Support, {supportRequests} from "../containers/Rates/Support";
import FastDecisions, {fastDecisionsRequests} from "../containers/Rates/FastDecisions";
import Security, {securityRequests} from "../containers/Rates/Security";
import MotivOnMap, {motivOnMapRequests} from "../containers/Rates/MotivOnMap";
import ServiceRules, {serviceRulesRequests} from "../containers/Rates/ServiceRules";
import TariffAttachments, {tariffAttachmentsRequests} from "../containers/Rates/TariffAttachments";
import InternetTelephony, {internetRequests, telephonyRequests} from "../containers/Rates/InternetTelephony";
import PolicyAgreement, {policyAgreementRequests} from "../containers/AboutCompany/PolicyAgreement";
import Business, {BusinessHomeRequests} from "../containers/B2b/Business";
import Empty from "../containers/B2b/Empty";
import {getPathWithoutRegion} from "../tools/url-helper";
import Tariffs, {BusinessTariffsRequests} from "../containers/B2b/Tariffs";
import TariffDetail, {BusinessTariffDetailRequests} from "../containers/B2b/TariffDetail";
import ServiceDetail, {ServiceB2bRequests} from "../containers/B2b/ServiceDetail";
import Services, {ServicesB2bRequests} from "../containers/B2b/Services";
import Search, {SearchRequests} from "../containers/Search/Search";
import { matchPath } from "react-router";
import LegalInformation from "../containers/B2b/LegalInformation";
import B2bSupport, {B2bSupportRequests} from "../containers/B2b/Support";
import SmsTariffs, {smsTariffsRouteRequests} from "../containers/Rates/SmsTariffs";
import VoiceInfoServicesTariffs, {voiceInfoServicesTariffsRequests} from "../containers/Rates/VoiceInfoServicesTariffs";

const initState = {isOk:false, list: [], b2b: [], supportsRoute: [], meta: {}};

export function routes(state = initState, action) {
    switch (action.type) {
        case 'ROUTE_LIST':
            return {...state, isOk: false};

        case 'ROUTE_LIST_SUCCESS':
            return {
                ...state,
                list: action.data,
                isOk: true,
                supportsRoute: caseDynamicRoutesSupport(action.data)
            };

        case 'SET_ROUTES':
            return {
                ...state,
                [action.routeType]: action.data,
            };

        // ssr для динамических страниц ("ПОДДЕРЖКА и еще пяти страниц")
        case '@@redux/INIT':
            return {
                ...state,
                supportsRoute: caseDynamicRoutesSupport(state.list)
            };

        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                meta: getMeta(caseDynamicRoutesB2b(state.b2b), action.payload.pathname)
            }
    }
    return state;
}

// используется в качестве шаблона, в этом файле и в app.js
export function caseDynamicRoutesSupport(routes) {

    let supportsRoute = routes.map((item) => {
        let route = {
            exact: true,
            requests: [],
            headerClass: "",
            path: "/:region" + item.route
        };
        switch (item.sys_name) {
            case 'policyAgreementRoute':
                route.requests = policyAgreementRequests;
                route.component = PolicyAgreement;
                break;
            // case 'supportRoute':
            //     route.requests = supportRequests;
            //     route.component = Support;
            //     break;
            case 'fastDecisionsRoute':
                route.requests = fastDecisionsRequests;
                route.component = FastDecisions;
                break;
            case 'securityRoute':
                route.requests = securityRequests;
                route.component = Security;
                break;
            case 'motivOnMapRoute':
                route.requests = motivOnMapRequests;
                route.component = MotivOnMap;
                break;
            case 'complainRoute':
                route.requests = complainRequests;
                route.component = Complain;
                break;
            case 'supportTariffsSms':
                route.requests = smsTariffsRouteRequests;
                route.component = SmsTariffs;
                break;
            case 'supportTariffsServices':
                route.requests = voiceInfoServicesTariffsRequests;
                route.component = VoiceInfoServicesTariffs;
                break;
            case 'termsOfServiceRoute':
                route.requests = serviceRulesRequests;
                route.component = ServiceRules;
                break;
            case 'attachmentToTariffsRoute':
                route.component = TariffAttachments;
                route.requests = tariffAttachmentsRequests;
                break;
            // case 'internetRoute':
            //     route.component = InternetTelephony;
            //     route.requests = internetRequests;
            //     break;
            case 'supportInternetRoute':
                route.component = InternetTelephony;
                route.requests = internetRequests;
                break;
            case 'telephonyRoute':
                route.component = InternetTelephony;
                route.requests = telephonyRequests;
                break;
        }
        return route;
    });
    return supportsRoute
}

export function caseDynamicRoutesB2b(routes) {

    const additional = {
        mainB2BRoute: {
            component: Business,
            requests: BusinessHomeRequests,
            meta_title: 'Для корпоративных клиентов | Оператор связи МОТИВ',
            sort: 1,
            headerClass: "page__header-holder_float page__header-holder_white"
        },
        legalInformationB2BRoute: {
            component: LegalInformation,
            requests: [],
            meta_title: 'Для корпоративных клиентов | Оператор связи МОТИВ',
            sort: 2
        },
        tariffsB2BRoute: {
            component: Tariffs,
            requests: BusinessTariffsRequests,
            meta_title: 'Для корпоративных клиентов | Оператор связи МОТИВ',
            sort: 2
        },
        servicesB2BRoute: {
            component: Services,
            requests: ServicesB2bRequests,
            meta_title: 'Услуги для корпоративных клиентов | Оператор связи МОТИВ',
            sort: 2
        },
        supportB2BRoute: {
            component: B2bSupport,
            requests: B2bSupportRequests,
            meta_title: 'Обслуживание и поддержка для корпоративных клиентов | Оператор связи МОТИВ',
            sort: 2
        },
        searchB2BRoute: {
            component: Search,
            requests: SearchRequests,
            meta_title: 'Обслуживание и поддержка для корпоративных клиентов | Оператор связи МОТИВ',
            sort: 2
        },
    }

    let res = [];
    routes.forEach((item) => {
        switch (item.sys_name) {
            case 'mainB2BRoute':
                res.push({
                    exact: true,
                    requests: [],
                    headerClass: "",
                    path: "/",
                    ...additional.mainB2BRoute,
                    ...item
                });
                break
            case 'tariffsB2BRoute':
                res.push({
                    exact: true,
                    requests: BusinessTariffDetailRequests,
                    path: `/:region${item.route}/:slug`,
                    component: TariffDetail,
                    sort: 3,
                });
                break;
            case 'servicesB2BRoute':
                res.push({
                    exact: true,
                    requests: ServiceB2bRequests,
                    headerClass: "page__header-holder-indentation",
                    path: `/:region${item.route}/:slug`,
                    component: ServiceDetail,
                    sort: 3,
                });
                break;
        }

        res.push({
            exact: true,
            requests: [],
            headerClass: "",
            path: "/:region" + (item.route === '/' ? '' : item.route),
            ...additional.hasOwnProperty(item.sys_name) ? additional[item.sys_name] : {},
            ...item
        })
    })

    res.sort((a, b) => a.sort < b.sort ? -1 : 1)

    return res;
}

export function getMeta(routes, pathname) {
    let route = routes.find((item) => !!matchPath(pathname, item))
    return route ? {
        title: route.meta_title,
        description: route.meta_description,
        keywords: route.meta_keywords,
        headerClassName: route.headerClass
    } : {}
}