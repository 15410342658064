import React, { Component } from 'react';
import FaqCollapseForm from "../common/FaqCollapseForm";


class RatesCollapseFag extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.activeId
        }
    }    

    render() {
        let {
            title,
            item,
            toggleCollapse
        } = this.props;

        return (
            <div className={"b-list-collapser__item" + (this.props.activeId === item.id ? " b-list-collapser__item_collapsed" : "")} id={item.alias} name={item.alias} >
                <div className="b-list-collapser__item-header" onClick={() => toggleCollapse(item.id)}>
                    <div className="b-list-collapser__item-title">{title}</div>
                </div>
                <div className={"u-collapser" + (this.props.activeId === item.id ? " u-collapser_open" : "")}>
                    <div className="u-collapser__container">
                        <div className="b-list-collapser__collapsed-area ">
                            <div dangerouslySetInnerHTML={{__html: item.answer}} />
                            <FaqCollapseForm id={item.id} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RatesCollapseFag;