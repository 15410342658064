import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import Icon from "./Icon";
import MaskedInput from 'react-text-mask';
import ru from 'date-fns/locale/ru';
import InputIcon from "../common/InputIcon";

export default class DatePickerInput extends Component {
    constructor (props) {
        super(props);
        this.state = {
            date: null,
            dateInput: props.value ? props.value : null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.dateInput != prevState.dateInput) {
            this.inputDate.inputElement.focus();
        }
    }

    // format date to 'DD.MM.YYYY'

    formateDate = date => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();

        // day

        if (dd < 10) {
            dd = '0' + dd;
        }

        // month

        if (mm < 10) {
            mm = '0' + mm;
        }

        // result date

        return `${dd}.${mm}.${yyyy}`;
    };

    // handler change DatePicker

    handleChange(date) {
        let {
            minDate,
            maxDate
        } = this.props;

        if (date && (minDate && date < minDate || maxDate && date > maxDate)) {
            return false;
        }

        this.setState({
            date: date,
            dateInput: this.formateDate(date)
        });

        if (this.picker) this.picker.setOpen(false);

        this.props.onChange(date ? this.formateDate(date) : "");
    }

    // handler change input

    handlerInputChange = (event) => {
        event.preventDefault();

        let dateRegExp = /([0-3]{1}[0-9]{1}.[0-1]{1}[0-9]{1}.[0-9]{4})/,
            resultMatch,
            dateDelivery,
            result;

        let {
            minDate,
            maxDate
        } = this.props;

        resultMatch = event.target.value.match(dateRegExp);

        // check format date

        if (resultMatch && resultMatch.length > 0) {

            dateDelivery = new Date(resultMatch[1].replace(/([0-9]{2}).([0-9]{2}).([0-9]{4})/, '$3-$2-$1'));

            // check valid date

            if ( Object.prototype.toString.call(dateDelivery) === "[object Date]" ) {
                if ( !isNaN( dateDelivery.valueOf() ) ) {

                    // check date min, max

                    if (dateDelivery && (minDate && dateDelivery < minDate || maxDate && dateDelivery > maxDate)) {
                        result = false;
                    } else {
                        result = true;
                    }
                }
                else {
                    result = false;
                }
            } else {
                result = false;
            }
        } else {
            result = false;
        }

        if (result) {
            this.setState({
                date: dateDelivery,
                dateInput: this.formateDate(dateDelivery)
            });

            this.props.onChange(this.formateDate(dateDelivery));
        } else {
            this.setState({
                date: null,
                dateInput: event.target.value
            });

            this.props.onChange();
        }
    };

    // handler click on icon calendar

    handlerIconDateClick = () => {
        if (this.picker) this.picker.setOpen(true);
    }

    // handler flip calendar popup

    handlerFlip = (data, options) => {

        let optionArr = {...data};

        // distance to top

        const coordEl = this.inputDateBlock.getBoundingClientRect();
        const distanceToTop = coordEl.top + pageYOffset;

        // check conditions

        if (data.popper.height > distanceToTop) {
            optionArr.instance.options.placement = "bottom-end";
            //optionArr.originalPlacement = "bottom-end";
            //optionArr.placement = "bottom-end";
        } else {
            optionArr.instance.options.placement = "top-end";
            //optionArr.originalPlacement = "top-end";
            //optionArr.placement = "top-end";
        }

        return optionArr;
    }

    render() {
        let {
            minDate,
            maxDate,
            placeholder = "ДД.ММ.ГГГГ",
            allowYearDropdown = false,
            hasError = false,
            onblur = onblur,
            onfocus = onfocus,
            textError = "",
            name = "",
            readOnly
        } = this.props;

        let selectableYearProps = {
            showYearDropdown: true,
            dateFormatCalendar: "MMMM",
            scrollableYearDropdown: true,
            yearDropdownItemNumber: 100
        };

        return  <div className={`b-input-date__input-area ${readOnly ? "disabled" : ''}`}
                     ref={(inputDateArea) => {this.inputDateBlock = inputDateArea}}>

            <MaskedInput
                mask={[/[0-3]/,/[0-9]/,'.',/[0-1]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}
                onBlur={onblur}
                onFocus={onfocus}
                className={"b-input-date__input" + (hasError ? " has-error" : "")}
                placeholder={placeholder}
                type="text"
                onChange={this.handlerInputChange}
                value={this.state.dateInput}
                ref={(inputDate) => {this.inputDate = inputDate}}
                name={name}
                readOnly={readOnly}
            />

            {!readOnly
                ? (<React.Fragment>
                    <DatePicker
                        ref={(picker) => {this.picker = picker}}
                        selected={this.state.date}
                        onChange={this.handleChange}
                        minDate={minDate ? minDate : null}
                        maxDate={maxDate ? new Date(maxDate) : new Date()}
                        locale={ru}
                        isClearable={false}
                        popperPlacement='bottom-end'
                        popperModifiers={{
                            flip: {
                                enabled: true,
                                fn: this.handlerFlip
                            }
                        }}
                        {...allowYearDropdown ? selectableYearProps : {}}
                    >
                        {/*<div className={"b-calendar__calendar-footer"}>*/}
                        {/*<div className={"b-calendar__btns-section"}>*/}
                        {/*<div className={"b-calendar__btn u-btn u-btn_sm u-btn_transparent u-btn_responsive"} onClick={() => {this.picker.clear()}}>Сбросить</div>*/}
                        {/*<div className={"b-calendar__btn b-calendar__btn-apply u-btn u-btn_sm u-btn_transparent u-btn_responsive"} onClick={() => {this.handleChange()}}>Применить</div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </DatePicker>

                    <div className="b-input-date__dates-icon" onClick={this.handlerIconDateClick}>
                        <Icon name="icon_dates" className="u-icon u-icon_dates"/>
                    </div>
                </React.Fragment>)
                : <InputIcon error={hasError} />
            }
        </div>
    }
}
