import React, {Component} from "react";
import InputError from "./InputError";

export default class Select extends Component{

    constructor(props) {

        super(props);
        let selected = null;

        if (props.value === '' || props.value) {
            for (var i = 0; i < props.options.length; i++) {
                if (props.options[i].value === props.value) {
                    selected = props.options[i];
                    break;
                }
            }
        }

        this.state = {
            selected,
            opened: false,
            options: props.options || []
        }

    }

    componentWillReceiveProps(nextProps){
        if ((this.state.selected && nextProps.value != this.state.selected.value) || (!this.state.selected && nextProps.value)) {
            var selected = null;

            if (nextProps.value) {
                if (nextProps.options && nextProps.options.length) {
                    for (var i = 0; i < nextProps.options.length; i++) {
                        if (nextProps.options[i].value == nextProps.value) {
                            selected = nextProps.options[i];
                            break;
                        }
                    }
                } else {
                    selected = {
                        label: nextProps.value,
                        value: nextProps.value
                    }
                }
            }
            //if (this.filter) this.filter.value = selected ? selected.value : "";
            this.setState({selected});
        }
        if (nextProps.options != this.props.options) {
            this.setState({options: nextProps.options});
        }
    }

    onClick = (e) => {
        const fieldLocked = this.props.fieldLocked;
        if (fieldLocked === true){
            e.stopPropagation();
        } else {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            let event = document.createEvent("Event");
            event.initEvent("click", false, true);
            document.dispatchEvent(event);

            this.setState((prevState) => {
                return {
                    opened: !(prevState.opened)
                }
            });
        }
    };

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
        if (this.filter) {
            if (this.state.selected) {
                this.filter.value = this.state.selected.label;
            }
            this.filter.addEventListener('input', this.onFilter);
        }
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
        if (this.filter) this.filter.removeEventListener('input', this.onFilter);
    }

    windowClick = (event) => {
        /*if (this.filter) this.filter.value = this.state.selected ? this.state.selected.label : "";*/

        if(event.target === document && this.state.opened) {
            this.setState({
                opened: false, options: this.props.options
            });
        }
        if (event.target != document) {
            // console.log(event.target, this.state.selected)
            this.setState({
                opened: false, options: this.props.options
            });
        }

    };

    onSelect = (item) => {
        return (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            this.setState({
                selected: item,
                opened: false
            }, () => {
                if (this.filter) this.filter.value = item ? item.label : "";
                this.props.onChange && this.props.onChange(this.props.attribute, item ? item.value : '');
                this.props.saveField && this.props.saveField(this.props.attribute, item ? item.value : '');
                this.props.onToggle && this.props.onToggle(item ? item.value : '');
                this.props.selectFormName && this.props.selectFormName(this.props.attribute, item ? item.label : '');
            })
        }
    };

    onFilter = (e) => {
        this.props.onFilter && this.props.onFilter(e.target.value);
    };

    onChange = (e) => {
        this.props.onChangeValue && this.props.onChangeValue(this.props.attribute, e.target.value);
    };

    render(){

        let {
            className = "select-styler",
            placeholder = "Не выбрано",
            allowEmpty = false,
            allowSearch = false,
            trigger = true,
            disabled = false,
            hasError = false,
            onblur = onblur,
            onfocus = onfocus,
            errorText = "",
            value = "",
            fieldLocked
        } = this.props;

        let {
            opened,
            selected,
            options,
        } = this.state;

        return <div onBlur={onblur} onFocus={onfocus} tabIndex="0" className={className + (disabled ? " disabled" : "")}>
            <div className={"jq-selectbox__select"+(opened ? " opened" : "")+(hasError ? " has-error" : "")+(!!fieldLocked ? " jq-selectbox__select--locked" : "")} onClick={this.onClick} >
                {allowSearch
                    ?   <input placeholder={placeholder} ref={(input) => this.filter = input} onChange={this.onChange} value={value}/>
                    :   <div className={`jq-selectbox__select-text ${!selected ? 'jq-selectbox__select-text-placeholder' : ''}`}>{selected ? selected.label : placeholder}</div>
                }
                {trigger
                    ?   <div className="jq-selectbox__trigger">
                           <div className="jq-selectbox__trigger-arrow"/>
                        </div>
                    :   ''
                }
                <InputError hasError={hasError} errorText={errorText} className={"js-selectbox__error-text"}/>
            </div>
            {opened ?
                <div className="jq-selectbox__dropdown">
                    <ul>
                        {allowEmpty ? <li onClick={this.onSelect(null)} className={!selected ? "sel selected jq-selectbox__placeholder" : ""}>{placeholder}</li> : null}
                        {!!options ? options.map((item, key) =>
                            <li key={key} onClick={this.onSelect(item)} className={selected && selected.value == item.value ? "sel selected" : ""}>
                                {item.label}
                            </li>
                        ) : null}
                    </ul>
                </div> : null}
        </div>
    }
}