import React, {Component} from "react";
import PropTypes from "prop-types";
import LinkC from "../common/LinkC";
import Icon from "../common/Icon";
import DataPickerDay from "./DataPickerDay";
import {newsList} from "../../actions/news";
import {connect} from "react-redux";
import {DateUtils} from "react-day-picker";
import {Link} from "react-router-dom";
import Img from 'react-lazilyload-img';
import { isIE } from "react-device-detect";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';

class NewsAndEvents extends Component {
    targetElement = null;
    constructor(props) {
        super(props);

        this.state = {
            toggleBtn: false,
            clientWidth: typeof window === "undefined" ? true : window.innerWidth <= 426,
            from: null,
            to: null,
            showCalendar: false,
            showTextErr: false,
            showSearch: false,
            valueSearch: '',
            showNotFound: false,
        };

        this.refSearch = React.createRef();
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    componentDidMount() {
        this.targetElement = document.querySelector('.page');

        const motivSearchNews = JSON.parse(localStorage.getItem('MotivSearchNews'));

        if (motivSearchNews) {
            this.setState({
                showSearch: motivSearchNews.LSshowSearch,
                valueSearch: motivSearchNews.LSvalueSearch,
                showCalendar: motivSearchNews.LSshowCalendar,
            });

            // если поле поиска не пустое, то запускаем запрос поиска новостей
            if (motivSearchNews.LSvalueSearch) {
                setTimeout(() => this.showFindingNews(), 300);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        let listProps = this.props.listProps;
        if ( listProps === true ) {
            this.toggleNewsSqr();
        }

        if (!!this.state.showCalendar && this.props.widthWindow !== nextProps.widthWindow) {
            this.overlayMobileCalendar();
        }
    }

    componentWillUnmount(){
        const el = document.querySelector(".lock-news-page") || undefined;
        if (typeof el !== 'undefined'){
            el.removeEventListener("click", this.closeWindowCalendar);
        }
        clearAllBodyScrollLocks();
    };

    componentDidUpdate(prevState) {
        const el = document.querySelector(".lock-news-page") || undefined;
        if (typeof el !== 'undefined') {
            el.addEventListener("click", this.closeWindowCalendar);
        }

        if (prevState.showCalendar !== this.state.showCalendar && !!this.state.clientWidth) {
            this.overlayMobileCalendar();
        }

        if (!this.props.buttonBlockHidden) {
            this.props.getValueSearch(this.state.valueSearch);
        }

        localStorage.setItem('MotivSearchNews', JSON.stringify({
            LSshowSearch: this.state.showSearch,
            LSvalueSearch: this.state.valueSearch,
            LSshowCalendar: this.state.showCalendar,
        }));
    }

    toggleNewsList=()=>{
        let toggleBtn = this.state.toggleBtn;
        if ( !toggleBtn ){
            this.setState({
                toggleBtn: true
            })
        }
        if ( !!this.props.newsAll && !toggleBtn ) {
            this.props.clickList();
        }
    };

    toggleNewsSqr=()=>{
        let toggleBtn = this.state.toggleBtn;
        if ( !!toggleBtn ){
            this.setState({
                toggleBtn: false
            })
        }
        if ( !!this.props.newsAll && !!toggleBtn ){
            this.props.clickSqr();
        }
    };

    // открывает/закрывает поле поиска
    toggleSearch = () => {
        let showSearch = this.state.showSearch;
        this.setState({
            showSearch: !showSearch
        });
        this.refSearch.current.focus();
    };

    // получает значение поля поиска
    handleSearch = (e) => {
        this.setState({
            valueSearch: e.target.value
        });
    };

    // показывает найденные новости по нажатию на увеличилку
    showFindingNews = () => {
        let valueSearch = this.state.valueSearch;
        if (valueSearch.length >= 2) {
            this.props.getNewsList(window.innerWidth >= 426 ? "?list_type=basic" : "?list_type=mobile&page=", false, valueSearch);
        }

        if (valueSearch.length < 2) this.setState({ showNotFound: true });
        else this.setState({ showNotFound: false });
    };

    // показывает найденные новости по нажатию на ENTER
    keyDownFindingNews = (e) => {
        let valueSearch = this.state.valueSearch;
        if (e.keyCode === 13 && valueSearch.length >= 2) {
            this.props.getNewsList(window.innerWidth >= 426 ? "?list_type=basic" : "?list_type=mobile&page=", false, valueSearch);

        }
        if (e.keyCode === 13) {
            if (valueSearch.length < 2) this.setState({ showNotFound: true });
            else this.setState({ showNotFound: false });
        }
    };

    // очищает поле поиска
    clearSearch = () => {
        this.setState({
            valueSearch: '',
            showNotFound: false
        });
        this.props.getNewsList(window.innerWidth >= 426 ? "?list_type=basic" : "?list_type=mobile&page=", false, '');
    };

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    };

    setShowCalendar = () => {
        this.setState((prevState) => {
            return {
                showCalendar: !prevState.showCalendar
            }
        });
    };

    overlayMobileCalendar =()=> {
        const options: BodyScrollOptions = {
            reserveScrollBarGap: true,
        };
        if (typeof document !== 'undefined') {
            if (!!this.state.showCalendar && this.props.widthWindow < 426) {
                disableBodyScroll(this.targetElement, options);
                document.body.classList.add("overlay-mobile-calendar");

            } else {
                enableBodyScroll(this.targetElement);
                document.body.classList.remove("overlay-mobile-calendar");
            }
        }
    };

    closeWindowCalendar = (e) => {
        if (e) e.preventDefault();
        if ( !!this.state.showCalendar  ) {
            this.setState({
                showCalendar: false
            })
        }
    };

    getApply = () => {
        let showCalendar = this.state.showCalendar;
        let fromAllData = this.state.from;
        let toAllData =this.state.to;

        let fromData = new Date(fromAllData).toLocaleDateString() > "01.01.1970" ? new Date(fromAllData).toLocaleDateString() : null;
        let toData = new Date(toAllData).toLocaleDateString() > "01.01.1970" ? new Date(toAllData).toLocaleDateString() : null;
        let newFromData = "";
        let newToData = "";

        let reg =/^[.\d]*$/;

        if (fromData !== null) {
            for (let i=0; i<fromData.length; i++){
                if (reg.test(fromData[i])){
                    newFromData+=fromData[i];
                }
            }
        }
        if (toData !== null) {
            for (let i=0; i<toData.length; i++){
                if (reg.test(toData[i])){
                    newToData+=toData[i];
                }
            }
        }

        if ( !!fromData && toData === null || !!fromData && fromData === toData) {

            this.props.getNewsList("?date=" + fromData);
            this.props.getDiapazon("?date=" + fromData);
        } else if ( !!fromData && !!toData ) {

            let result ="?date_from="+newFromData+"&date_to="+ newToData;
            this.props.getNewsList(result);
            this.props.getDiapazon(result);

        } else if ( fromData === null  && toData === null ) {
            this.setShowCalendar();
        }

        if ( !!showCalendar ) {
            this.setShowCalendar();
        }
        if ( this.props.data && !!this.props.data.length ) {
            this.setState({
                showTextErr: true,
            })
        }
    };

    filterReset = () => {
        let from = this.state.from;
        let to = this.state.to;
        this.props.resetDiapazon();

        if ( from !== null || to !== null ) {
            this.setState({
                from: null,
                to: null,
            })
        };
        this.props.onResizeNews('defaultNews');
    };

    render() {
        const { 
            toggleBtn,
            showCalendar,
            showTextErr,
            from,
            to,
            showSearch,
            valueSearch,
            showNotFound,
        } = this.state;

        const selectedRegion = this.context.selectedRegion.alias;

        let {
            title,
            className = false,
            showMore = false,
            newsAll = false,
            getMore,
            linkPageNews,
            buttonBlockHidden = false,
            data,
            linkAllNewsHidden = false,
            propsPagination,
        } = this.props;

        let currentPage = propsPagination && +propsPagination.currentPage;
        let pageCount = propsPagination && +propsPagination.pageCount;
        
        return(
            <div className={ "news-and-events-block " + ( !!className ? className : "" ) } >
                <div className="news-and-events-top">                   
                    <div className="news-and-events__title">{ title }</div>
                    <div className="button-block-news">
                        {!buttonBlockHidden &&
                            <div className="news-and-events__wrap-icon-search" onClick={this.toggleSearch}>
                                <img src={`${require('../../../icons/search-black.svg')}`} alt="Поиск"/>
                            </div>
                        }

                        {!buttonBlockHidden ? <div className="news-and-events__button-calendar">
                            <div className="news-and-events__calendar" onClick={this.setShowCalendar}>
                                <Icon name={"icon_calendar"} className={"news-and-events--calendar-icon"} />
                                <span className="news-and-events--calendar-title">Календарь</span>
                            </div>
                            <div className="media-data-picker__wrap">
                                <DataPickerDay
                                    from={ from }
                                    to={ to }
                                    handleDayClick={ this.handleDayClick }
                                    showCalendar={ showCalendar }
                                    getApply={ this.getApply }
                                    filterReset={ this.filterReset }
                                />
                            </div>

                        </div> : null}
                        {!buttonBlockHidden ? <div className="news-and-events__button">
                            <div className={"news-and-events__button-line" + (!!toggleBtn ? " news-and-events__button-default" : "")} onClick={ this.toggleNewsList }>
                                <span className="button-line__dot"/>
                                <span className="button-line__line"/>
                                <span className="button-line__dot"/>
                                <span className="button-line__line"/>
                                <span className="button-line__dot"/>
                                <span className="button-line__line"/>
                            </div>
                            <div className={"news-and-events__button-square" + (!toggleBtn ? " news-and-events__button-default" : "")} onClick={ this.toggleNewsSqr }>
                                <span className="button-square__el" />
                                <span className="button-square__el" />
                                <span className="button-square__el" />
                                <span className="button-square__el" />
                            </div>
                        </div> : null }
                    </div>
                </div>

                <div className={`news-and-events__wrap-search ${showSearch ? 'active' : ''}`}>
                    <button onClick={valueSearch !== '' ? this.showFindingNews : () => {}} className="news-and-events__wrap-icon-search-input">
                        <img src={`${require('../../../icons/search-black.svg')}`} alt="Поиск" />
                    </button>
                    <input
                        className="news-and-events__input"
                        value={valueSearch}
                        placeholder={'Поиск'}
                        onChange={(e) => this.handleSearch(e)}
                        onKeyDown={this.keyDownFindingNews}
                        ref={this.refSearch}
                    />
                    <button onClick={this.clearSearch} className="news-and-events__wrap-icon-clear-input">
                        <img src={`${require('../../../icons/close-black.svg')}`} alt="Очистить" />
                    </button>
                </div>

                <div className={"news-and-events" + (!!toggleBtn ? " events--column" : "")}>
                    {data && data.length && !showNotFound ? data.map((el) =>
                        <Link key={el.id} className={"news-and-events-link" + (!!toggleBtn ? " events-link--row" : "")} to={'/'+selectedRegion + linkPageNews + '/' + el.id}>
                            {el && (el.miniature || el.photo) ?
                                <div className={ isIE === true ? "news-and-events-link-img__wrap news-and-events-link-img__wrap--IE" : "news-and-events-link-img__wrap"}>
                                    {isIE === true ?
                                        <svg role="image"
                                             preserveAspectRatio="xMidYMid slice"
                                             viewBox="0 0 1024 640" className="news-and-events-link__img--IE">
                                            <image width="100%" height="100%" xlinkHref={el.miniature}/>
                                        </svg>
                                        :
                                        <div className="img__wrapper">
                                            < Img
                                                className = "news-and-events-link__img"
                                                src={el.photo}
                                                alt=""
                                            />
                                        </div>
                                    }
                                </div>
                            : null}
                            <div className="news-and-events-link__content">
                                {el && el.date ? <div className="news-and-events-link__publicationDate" dangerouslySetInnerHTML={{ __html: el.date }} /> : null}
                                {el && el.title ? <div className="news-and-events-link__title" dangerouslySetInnerHTML={{ __html: el.title }} /> : null}
                                {el && el.short_description ? <div className="news-and-events-link__text" dangerouslySetInnerHTML={{ __html: el.short_description }} /> : null}
                            </div>
                        </Link>
                    ) : null }

                    {((data && !data.length) || showNotFound) &&
                        <div className="news-and-events__no-result">По вашему запросу ничего не найдено</div>
                    }
                </div>
                {data && data.length ? !linkAllNewsHidden ?
                    <React.Fragment>
                        {(currentPage < pageCount) && !showNotFound ?
                            <div className="news-and-events__link-to-news">
                                <div className="more-news-block" onClick={ getMore }>
                                    <Icon name={"icon_read-more-news"}/>
                                    <div className="more-news-block__title" >Показать еще</div>
                                </div>
                            </div> :
                        null}
                        {!showMore ?
                            <div className="news-and-events__link-to-news">
                                <LinkC className="link-to-news" to={ linkPageNews }>Все новости</LinkC>
                            </div> :
                        null}
                    </React.Fragment> : null : null}
            </div>
        )
    }
}

export default connect(
    state => ({
        newsList: state.news || [],
    }),
    (dispatch) => {
        return {
            getNewsList: (listType, loadMore, finding) => dispatch(newsList(listType, loadMore, finding)),
        }
    }
)(NewsAndEvents);