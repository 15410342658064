import React, { useEffect, useRef } from 'react'

// документация RuTubePlayer
// https://rutube.ru/info/embed/#embed006

const RuTubeEmbed = ({ videoId, onPlay, onPause, onEnd, containerClassName = '', opts, onReady }) => {
    const iframeRef = useRef(null)
    const videoUrl = `https://rutube.ru/play/embed/${videoId}?autoplay=1&muted=1`


    const playerMessage = (event) => {
        let message;

        if (typeof event.data === 'string') {
            try {
                message = JSON.parse(event.data)
            } catch (e) {
                // Если не удалось распарсить, выходим из функции
                return
            }
        } else {
            message = event.data
        }
        // Проверяем состояние , если Ready то передаем ref
        if (message.type === 'player:ready') {
            onReady && onReady({ target: iframeRef.current })
        }

        if (message && message.data && message.data.state) {
            switch (message.data.state) {
                case 'playing':
                    onPlay && onPlay()
                    break
                case 'paused':
                    onPause && onPause()
                    break
                case 'stopped':
                    onEnd && onEnd()
                    break

            }
        }

    };

    useEffect(() => {
        window.addEventListener('message', playerMessage)

        return () => {
            window.removeEventListener('message', playerMessage)
        }
    }, [])



    return (
        <div className={containerClassName}>
            <iframe
                ref={iframeRef}
                src={videoUrl}
                width={opts.width}
                height={opts.height}
                frameBorder="0"
                className='toolbar'
                allow="clipboard-write; autoplay"
                title="RuTube Video"
            ></iframe>
        </div>
    )
}

export default RuTubeEmbed