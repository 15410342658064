import React, {Component} from 'react'
import {datePretty} from "../../tools/mask";

export default class OrderHistory extends  Component {

    constructor(props){
        super(props);

        this.state = {
            items: [...props.items],
            sort: 'desc'
        }
    }

    sort = () => {

        this.setState((prevState) => {
            return {
                sort: prevState.sort === 'desc' ? 'asc' : 'desc',
                items: prevState.items.reverse()
            };

        });
    };

    render(){

        let {message} = this.props;
        let {items} = this.state;

        return <div>
            {message ? <div className="page__title-3 page__title-3_first-elem page__title-3_mobile-less">
                {message}
            </div> : <div className="page__table page__table_repl">
                <div className="b-table-default b-table-default_adapt_offered-docs">
                    <div className="b-table-default__wrapper">
                        <div className="b-table-default__thead">
                            <div className="b-table-default__th">
                                <div className={"b-table-default__sort-btn b-table-default__sort-btn_"+(this.state.sort === "desc" ? "down" : "up")} onClick={this.sort}>
                                    Дата заказа
                                </div>
                            </div>
                            <div className="b-table-default__th">Тип детализации</div>
                        </div>
                        <div className="b-table-default__tbody">
                            {items.map((item, key) => <div className="b-table-default__trow" key={key}>
                                <div className="b-table-default__td">
                                    <div className="b-table-default__mobile-label">
                                        <div className="b-table-default__mobile-label-text">Дата заказа</div>
                                    </div>
                                    {datePretty(item.requested_at, true)}
                                </div>
                                <div className="b-table-default__td">
                                    <div className="b-table-default__mobile-label">
                                        <div className="b-table-default__mobile-label-text">Тип детализации</div>
                                    </div>
                                    {item.type}
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>}
            {/*<div className="page__repl-way">*/}
                {/*<a className="page__repl-way-btn" href="#">Способы пополнения</a>*/}
            {/*</div>*/}
        </div>
    }
}