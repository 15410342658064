import React, {Component} from 'react';
import PropTypes from "prop-types";
import {shopOffices, mainUrl} from "../../tools/config";
import UssdLink from "../common/UssdLink";

class RatesActivationMethods extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    render() {

        let {isGuest, isB2b} = this.props;
        let item = this.props.item;
        let showModal = this.props.showModal;
        let selectedRegion = this.context.selectedRegion;
        let callbackPhoneNumber = this.props.settings.callback_form_phone_number ? this.props.settings.callback_form_phone_number : '';
        let callbackPhoneNumberB2b = this.props.settings.callback_form_phone_number_b2b ? this.props.settings.callback_form_phone_number_b2b : '';

        // отображает ЗАГОЛОВОК "Способы подключения" в авторизованной зоне и НЕ авторизованной
        const showVariationConnection = () => {
            if (!isGuest && (item.switch_opio || item.sms_activation_info || item.ussd_activation_code || item.activation_contact_center)) {
                return true;
            }
            if (isGuest && (item.switch_by_account || item.quick_activation || item.switch_opio || item.sms_activation_info || item.ussd_activation_code || item.activation_contact_center)) {
                return true;
            }
            return false;
        }

        // отображает пункт "Другие способы подключения" в авторизованной зоне и НЕ авторизованной
        const showOtherVariationConnection = () => {
            if (!isGuest && ((item.sms_activation_info || item.ussd_activation_code) || ((item.sms_activation_info || item.ussd_activation_code) && (item.switch_opio || item.activation_contact_center)))) {
                return true;
            }
            if (isGuest && (item.sms_activation_info || item.ussd_activation_code || item.quick_activation)) {
                return true;
            }
            return false;
        }

        // отображает ЗАГОЛОВОК "Другие способы управления услугами" в авторизованной зоне и НЕ авторизованной
        const showOtherVariationControl = () => {
            if (!isGuest && ((item.sms_deactivation_info || item.ussd_deactivation_code) || item.canStatus || item.deactivation_opio || item.deactivation_contact_center)) {
                return true;
            }
            if (isGuest) return true; 
            return false;
        }

        
        // отображает пункт "Способы отключения услуги" в авторизованной зоне и НЕ авторизованной
        const showVariationServiceDeactivation = () => {
            if (!isGuest && (item.sms_deactivation_info || item.ussd_deactivation_code)) {
                return true;
            }
            if (isGuest && (item.canDeactivate)) {
                return true;
            }
            return false;
        }

        return (
            <div className="b-with-aside-area__aside">
                {
                    item.is_package
                        ?
                        <div className="b-service-inner__aside-block">
                            <div className="b-plug-ways b-plug-ways_adapt_price-list b-plug-ways_price-list">
                                <div className="b-plug-ways__wrapper">
                                    <div className="b-plug-ways__title">Способы подключения</div>
                                    <div className="b-plug-ways__inner">
                                        <div className="b-plug-ways__price-list">
                                            <div
                                                className="b-price-list b-price-list_theme_dotted b-price-list_dot-color_grey">
                                                <div className="b-price-list__wrapper">
                                                    <div className="b-price-list__list">
                                                        {item.packageServices.map((item, key) =>
                                                            <div className="b-price-list__item" key={key}>
                                                                <div className="b-price-list__text">{item.name}</div>
                                                                <div className="b-price-list__dotter"/>
                                                                <UssdLink ussd={item.ussd_activation_code} className={"black-link"}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="b-service-inner__aside-block">
                            <div className="b-plug-ways b-plug-ways_adapt_default">
                                {item.status_id === 1 ?
                                    (item.canSwitch ||
                                    item.switch_opio ||
                                    item.sms_activation_info ||
                                    item.ussd_activation_code ||
                                    item.activation_contact_center ||
                                    item.switch_by_account ||
                                    item.quick_activation
                                    )
                                        ? <div className="b-plug-ways__wrapper">
                                            {showVariationConnection()
                                                && (
                                                    <div className="b-plug-ways__title">Способы подключения</div>
                                                )
                                            }
                                            <div className="b-plug-ways__inner">
                                                {item.ussd_activation_code
                                                    ? <div className="b-plug-ways__paragraph">Наберите короткую команду
                                                        <div className="b-plug-ways__command">
                                                            <UssdLink ussd={item.ussd_activation_code}
                                                                      className={"black-link"}/>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                                {item.switch_by_account && isGuest && !isB2b
                                                    ? <div className="b-plug-ways__paragraph">Через&nbsp;
                                                        <a href="#" onClick={showModal('change-rate-personal')}>личный
                                                            кабинет</a>
                                                    </div>
                                                    : ''
                                                }
                                                {
                                                    (showOtherVariationConnection())
                                                        && (
                                                            <div className="b-plug-ways__paragraph">Другие&nbsp;
                                                                <a href="#" onClick={showModal('change-rate-phone')}>
                                                                    способы подключения
                                                                </a>
                                                            </div>
                                                        )
                                                }
                                                {
                                                    item.switch_opio
                                                        ? <div className="b-plug-ways__paragraph">
                                                            Для подключения услуги обратитесь в&nbsp;
                                                            <a
                                                                target="_blank"
                                                                href={`${mainUrl + selectedRegion.alias}/motiv-on-map`}
                                                            >
                                                                офис продаж
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    item.activation_contact_center
                                                        ? <div className="b-plug-ways__paragraph">
                                                            Для подключения услуги позвоните по телефону:<br/>
                                                            <a href={`tel: ${!isB2b ? callbackPhoneNumber : callbackPhoneNumberB2b}`}>
                                                                {!isB2b ? callbackPhoneNumber : callbackPhoneNumberB2b}
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                        : ""
                                    : ""
                                }
                                {
                                    (
                                    item.canDeactivate || 
                                    item.canStatus || 
                                    item.deactivation_opio || 
                                    item.deactivation_contact_center
                                    )
                                        && <div className="b-plug-ways__wrapper">
                                            {(showOtherVariationControl())
                                                && (
                                                    <div className="b-plug-ways__title">Другие способы управления услугами</div>
                                                )}
                                            <div className="b-plug-ways__inner">
                                                {
                                                    (showVariationServiceDeactivation())
                                                        &&  (
                                                            <div className="b-plug-ways__paragraph">Способы&nbsp;
                                                                <a href="#" onClick={showModal('deactivate-rate-personal')}>
                                                                    отключения услуги
                                                                </a>
                                                                {console.log('item', item)}
                                                            </div>
                                                        )
                                                }
                                                {
                                                    item.deactivation_opio
                                                        ? <div className="b-plug-ways__paragraph">
                                                            Для отключения услуги обратитесь в&nbsp;
                                                            <a target="_blank" href={`${mainUrl + selectedRegion.alias}/motiv-on-map`}>
                                                                офис продаж
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    item.deactivation_contact_center
                                                        ? <div className="b-plug-ways__paragraph">
                                                            Для отключения услуги позвоните по телефону:<br/>
                                                            <a href={`tel: ${!isB2b ? callbackPhoneNumber : callbackPhoneNumberB2b}`}>
                                                                {!isB2b ? callbackPhoneNumber : callbackPhoneNumberB2b}
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    item.canStatus && isGuest
                                                        ? <div className="b-plug-ways__paragraph">Способы&nbsp;
                                                            <a href="#" onClick={showModal('check-rate-personal')}>
                                                                проверки статуса
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    item.status_check_opio
                                                        ? <div className="b-plug-ways__paragraph">
                                                            Для проверки статуса услуги обратитесь в&nbsp;
                                                            <a target="_blank" href={`${mainUrl + selectedRegion.alias}/motiv-on-map`}>
                                                                офис продаж
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    item.status_check_contact_center
                                                        ? <div className="b-plug-ways__paragraph">
                                                            Для проверки статуса услуги позвоните по телефону:<br/>
                                                            <a href={`tel: ${!isB2b ? callbackPhoneNumber : callbackPhoneNumberB2b}`}>
                                                                {!isB2b ? callbackPhoneNumber : callbackPhoneNumberB2b}
                                                            </a>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    }
            </div>
        )
    }
}

export default RatesActivationMethods;