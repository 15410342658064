export function flagValueAction(data) {
    return {
        type: 'FLAG_VALUE',
        data
    }
}

export function userPhoneValueAction(data) {
    return {
        type: 'USER_PHONE_VALUE',
        data
    }
}

export function tariffConnectionToggleAction(data) {
    return {
        type: 'TARIFF_CONNECTION_VALUE',
        data
    }
}

export function timerValueAction(data) {
    return {
        type: 'TIMER_VALUE',
        data
    }
}

export function tariffConectionTypeAction(data) {
    return {
        type: 'TARIFF_CONNECTION_TYPE',
        data
    }
}

// открываем или закрываем попап для рекламы на мобилке
export function advertisingLabelingMobToggleAction(data) {
    return {
        type: 'ADVERTISING_LABELING_MOB_TOGGLE',
        data
    }
}

// записываем данные в попап для рекламы на мобилке
export function advertisingLabelingMobGetDataAction(data) {
    return {
        type: 'ADVERTISING_LABELING_MOB_GET_DATA',
        data
    }
}



