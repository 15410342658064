import React, {Component} from "react";

export default class ComplainContent extends Component{

    render(){
        let { complainRoute } = this.props;

        return <div className="complain-txt">
            { complainRoute && complainRoute.title ? <h1 className="complain-txt__title">{ complainRoute.title }</h1> : null }
            { complainRoute && complainRoute.content ? <div className={"complain-txt__content"} dangerouslySetInnerHTML={{ __html: complainRoute.content }} /> : null }
        </div>
    }
}