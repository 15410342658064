import React from "react";
import {mediaUrl} from "../../tools/config";
import InfoblockLink from "./InfoblockLink";

export default function InfoBlockLinkWithIcon({blocks}){
    return blocks.map((block) =>
        <InfoblockLink key={block.alias} className={"b-icon-links__item"} item={block} >
            <div className="b-icon-links__icon-area">
                <img src={mediaUrl+block.imagePath}/>
            </div>
            <div className="b-icon-links__title">{block.title}</div>
        </InfoblockLink>);
}
