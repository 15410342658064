import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

export function contactsList(name) {
    return {
        type: name === 'partners' ? 'CONTACTS_PARTNERS' : name === 'business' ? 'CONTACTS_BUSINESS' : 'CONTACTS_LIST',
        name
    }
}

export function* fetchContactsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/contacts/"+ (action.name) , {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } 
}

// контент для страницы Абонентов
export function dataForSubscribersAction() {
    return {
        type: 'DATA_FOR_SUBSCRIBERS',   
    }
}

// контент для страницы Абонентов
export function* fetchdataForSubscribersSaga(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/page/forSubscribersRoute", {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } 
}

// контент для страницы Контакты
export function dataContactsAction() {
    return {
        type: 'DATA_CONTACTS',   
    }
}

// контент для страницы Контакты
export function* fetchdataContactsSaga(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi(
                "/page/contactsRoute", {
                    method: 'GET',
                })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    } 
}