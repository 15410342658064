import React, { Component } from 'react';
import PropTypes from "prop-types";
import TooltipQuestion from '../common/TooltipQuestion/TooltipQuestion';
import Icon from "../common/Icon";

class AbonentContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    isOpen = () => {
        this.setState({
            opened: !this.state.opened,
        });
    }

    render() {
        let {
            opened,
        } = this.state;

        let {
            price,
            title,
            newOptions,
            currency,
            tooltipText
        } = this.props;

        return (
            <div
                className="bottom_container__box"
            >
                <div className="bottom_container__content">
                    {newOptions &&
                        (<button className="bottom_container__btn" onClick={() => this.isOpen()}>
                            <Icon
                                name="icon_arrow-down_black"
                                className={`u-icon_tariffs_calculator ${opened ? ' active_img' : ''}`}
                            />
                        </button>)
                    }

                    <div className="bottom_container__wrapper">
                        <div className="bottom_container__subtitle">
                            <div className="bottom_container__subtitle_title">
                                {title}
                            </div>

                            {tooltipText
                                && (
                                    <div className='sms__wrap_tooltip'>
                                        <TooltipQuestion text={tooltipText}/>
                                    </div>
                                )}
                        </div>
                        <div className="bottom_container__price">{price}&nbsp;{currency}</div>
                    </div>
                </div>
                {newOptions &&
                    (<div className={`bottom_container__hidden ${opened ? ' bottom_container__subscriptions' : ''}`}>
                        {newOptions.map(item =>
                            <div key={item.name} className="bottom_container__subscriptions_wrapper">
                                <div>{item.name}</div>
                                <div>{item.price_discount >= 0 ? item.price_discount : item.price}&nbsp;{item.unit && item.unit.slice(0, 5)}</div>
                            </div>
                        )}
                    </div>)
                }
            </div>
        )
    }
}

export default AbonentContent;