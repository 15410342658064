import {Component} from "react";
import {onChange, RenderField} from "../Rates/PopupHelpers";
import React from "react";
import {passwordValidator, phoneAccouintValidator} from "../../tools/form-helper";
import InputError from "../common/InputError";

export class PersonalCabinet extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {
                abnum: {
                    name: "abnum",
                    type: "text",
                    label: "Номер телефона",
                    value: "",
                    validators: [phoneAccouintValidator],
                    hasError: null,
                    textError: "Укажите корректный номер телефона или номер лицевого счета"
                },
                pass: {
                    name: "pass",
                    type: "password",
                    label: "Пароль",
                    value: "",
                    className: "position-relative",
                    validators: [passwordValidator],
                    hasError: null,
                    textError: "Пароль должен содеражать не менее 8 символов"
                },
                logintype: {
                    name: "logintype",
                    type: "hidden",
                    label: "",
                    value: 1,
                    className: "d-none"
                }
            }
        };
        this.onChange = onChange.bind(this);
    }

    static getDerivedStateFromProps(props, state){
        if (state.fields.pass.textError !== props.error && props.error) {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    pass: {
                        ...state.fields.pass,
                        hasError: true,
                        textError: props.error
                    },
                    abnum: {
                        ...state.fields.abnum,
                        hasError: true,
                        textError: " "
                    }
                }
            }
        }
        return state;
    }

    openInNewWin = () => {
        return (e) => {
            e.preventDefault();
            this.props.hideModal();
            window.open('https://lisa.motivtelecom.ru/');
        }
    };

    sendPostForm = () => {
        return (e) => {
            e.preventDefault();

            let {abnum, pass} = this.state.fields;
            if (abnum.value && !abnum.hasError && pass.value && !pass.hasError && this.props.login) {
                this.props.login(JSON.stringify({
                    username: abnum.value.replace(/\D/g, ''),
                    password: pass.value
                }))
            }
            // e.target.submit();
            //this.props.hideModal();
        }
    };

    onBlur = (name) => {
        let field = this.state.fields[name];
        if (field.hasOwnProperty('validators')) {
            let valid = true;
            field.validators.forEach(function (func) {
                if (!func.call(this, field.value)) {
                    valid = false;
                }
            });
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: !valid
                    }
                }
            });
        }
    };

    render() {

        let {
            fields,
            info_text
        } = this.state;

        let forgotPasswordLink = <a className="mnp-popup__forgot-link" onClick={this.props.onLinkClick} target="_blank" href="#" rel="nofollow">Забыли пароль?</a>;

        return <div className="mnp-popup_phone_commands">
            <div>{info_text}</div>
            <form id={"lk_form"} className="form-horizontal" method="post" target="_blank" onSubmit={this.sendPostForm()}>
                <RenderField field={fields['logintype']} onblur={this.onBlur}/>
                <RenderField field={fields['abnum']} onChange={this.onChange} onblur={this.onBlur}/>
                <RenderField field={fields['pass']} onChange={this.onChange} onblur={this.onBlur} children={forgotPasswordLink}/>
                <button id={"web-enter-lk-button"} type="submit" className="u-btn u-btn_default u-btn_adapt_mobile-sm">Войти</button>
            </form>
        </div>;
    }
}