import Vacancies, {VacanciesRequests} from '../containers/AboutCompany/Vacancies'
import Career, {CareerRequests} from '../containers/AboutCompany/Career'
import Vacancy, {VacancyRequests} from '../containers/AboutCompany/Vacancy'
import PressCenter, {PressCenterRequests} from "../containers/AboutCompany/PressCenter";
import AllNews, {AllNewsRequests} from "../containers/AboutCompany/AllNews";
import FormStyle, {FormStyleRequests} from "../containers/AboutCompany/FormStyle";
import News, {NewsRequests} from "../containers/AboutCompany/News";
import MediaBank, {MediaBankRequests} from "../containers/AboutCompany/MediaBank";



const initState = {isOk:false, list: [], vacancyRoutes: []};

export function routesVacancies(state = initState, action) {
    switch (action.type) {
        case 'ROUTE_VACANCIES_LIST':
            return {...state, isOk: false};
        case 'ROUTE_VACANCIES_LIST_SUCCESS':
            return {
                ...state,
                list: action.data,
                isOk: true,
                vacancyRoutes: caseDynamicRoutes(action.data)
            };

        case 'SET_ROUTES':
            switch (action.routeType) {
                case 'vacancyRoutes':
                    return {...state, vacancyRoutes: action.data};
                case 'vacancyList':
                    return {...state, list: action.data};
                default:
                    return state;
            }
            // ssr для динамических страниц ("Работа в Мотив" и "Пресс-центр")
        case '@@redux/INIT':
            return {
                ...state,
                vacancyRoutes: caseDynamicRoutes(state.list)
            };
            
    }
    return state;
}

    // используется в качестве шаблона, в этом файле и в app.js
export function caseDynamicRoutes(routes) {
    var detailVacancyRoute = {
        exact: true,
        requests: [],
        headerClass: "",
        path: "/"
    };
    var detailNewsRoute = {
        exact: true,
        requests: [],
        headerClass: "",
        path: "/"
    };
    var vacancyRoutes = routes.map((item) => {
        var route = {
            exact: true,
            requests: [],
            headerClass: "",
            path: "/:region" + item.link
        };
        switch (item.sys_name) {
            case "vakansiiRoute":
                route.requests = VacanciesRequests;
                route.component = Vacancies;
                detailVacancyRoute = {
                    ...detailVacancyRoute,
                    path: "/:region" + item.link + "/:id?",
                    component: Vacancy,
                    requests: VacancyRequests
                };
                break;
            case "careerInMotivRoute":
                route.requests = CareerRequests;
                route.component = Career;
                break;
            case "pressCenterRoute":
                route.requests = PressCenterRequests;
                route.component = PressCenter;
                break;
            case "mediaBankRoute":
                route.requests = MediaBankRequests;
                route.component = MediaBank;
                break;
            case "newsRoute":
                route.requests = AllNewsRequests;
                route.component = AllNews;
                detailNewsRoute = {
                    ...detailNewsRoute,
                    path: "/:region" + item.link + "/:id?",
                    component: News,
                    requests: NewsRequests
                };
                break;
            case "formStyleRoute":
                route.requests = FormStyleRequests;
                route.component = FormStyle;
                break;
        }
        return route;
    });
    return [...vacancyRoutes, detailVacancyRoute, detailNewsRoute];
}