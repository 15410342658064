import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function shopsList(regionId) {
    return {
        type: 'SHOPS_LIST',
        regionId
    }
}

export function* fetchShopsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            if (action.regionId) {
                return fetchClientApi("/shop?regionId="+action.regionId, {
                    method: 'GET',
                })
            } else {
                return fetchClientApi("/shop", {
                    method: 'GET',
                })
            }
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function closestShopsList(regionId, lat, lon, region, city, street) {
    return {
        type: 'CLOSEST_SHOPS_LIST',
        regionId,
        lat,
        lon,
        region,
        city,
        street
    }
}

export function* fetchClosestShopsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/shop/closest?regionId="+action.regionId+"&lat="+action.lat+"&lon="+action.lon+"&region="+action.region+"&city="+action.city+"&street="+action.street, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}