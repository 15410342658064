import React, {Component} from "react";
import {mediaUrl} from "../../tools/config";
import YouTube from 'react-youtube';

export default class VideoFrameAbout extends Component {

    constructor(props) {

        super(props);

        this.media = React.createRef();
        this.wrapMediaRef = React.createRef();
    }

    render() {

        let data = this.props.data ? this.props.data[0] : null;
        let videoId = /https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/watch(?:\?|#\!)v=)([\w-]{11}).*/gi.exec(data.videoPath);

        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
                autoplay: 0,
                controls: 0,
                iv_load_policy: 3,
                loop: 1,
                modestbranding: 1,
                showinfo: 0
            }
        };

        return (<div className={"values-page__video" + ( videoId == null ? " values-page__video__wrapper-img" : "")}>
            {!!videoId ? <YouTube
                containerClassName="career-video-player"
                videoId={ videoId[1] }
                opts={ opts }
            /> :
            data && data.imagePath ?
                <img className="values-page__previe-video" src={ mediaUrl + data.imagePath } alt="" role="presentation" />
            : null}
        </div>)
    }
}