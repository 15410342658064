import React, { Component } from "react";
import Modal from "../../common/Modal";
import ModalNew from "../../common/ModalNew";
import success from "../../../../images/content/constructor/success.svg";
import success_orange from "../../../../images/content/constructor/success_orange.svg";
import Icon from "../../common/Icon";
import { setUtmCookie, getCookie, checkUtmParams, isEmptyObj, addBrackets } from "../../../tools/tools";

import { Social } from "../../common/TariffDescription"
import SocialLinks from "../../../components/Rates/SocialLinks";

export class InfoModal extends Component {

    render() {
        let { close } = this.props;

        return <Modal className="u-modal u-modal_active u-modal-sm modal-info modal-info-tariff" close={close}>
            <h2 className="modal-title">Это не&nbsp;подходит<br />для названия тарифа</h2>
            <div className="text"></div>
            <button className="btn btn-accent btn-chevron-right" onClick={close}>хорошо</button>
        </Modal>
    }
}

export class InfoModalUnlim extends Component {

    render() {
        let {
            close,
            agreeForUnlim,
            text
        } = this.props;

        return <Modal className="u-modal u-modal_active u-modal-sm modal-info modal-info-tariff" close={close}>
            <div className="modal_text">{text}</div>
            <div className="modal_btns">
                <button className="btn btn-accent" onClick={agreeForUnlim}>Да</button>
                <button className="btn btn-accent" onClick={close}>Нет</button>
            </div>
        </Modal>
    }
}

export class InfoModalNew extends Component {
    onSubmit = (e) => {
        e.preventDefault();

        let {
            userPhone,
            selectedRegion,
            user,
            isGuest
        } = this.props;

        let authPhone = user && user.username;
        let numberEdit = userPhone.replace(/\D/gim, '').replace(/^\d/, '');

        // if (isGuest) {
        if (numberEdit !== authPhone) {
            this.props.checkAvailableConstructor(userPhone, selectedRegion.id);
        }
        this.props.tariffConnectionToggleAction('quick');
        this.props.timerValueAction(true);
        this.props.tariffConectionTypeAction('svoi');
        this.props.onClickConnect('tariff-connection');
    };

    checkOperator = (e) => {
        e.preventDefault();

        let {
            userPhone
        } = this.props;

        let data = {
            phone: userPhone.replace(/\D/gim, ''),
        };
        this.props.checkOperator(data);
        this.props.tariffConectionTypeAction('svoi');
        this.props.tariffConnectionToggleAction('mnp');
        this.props.onClickConnect('tariff-connection');
    };

    render() {
        let {
            close,
            tariffName,
            tariffSum,
            userPhone,
            activation,
            selectedRegion,
            settings,
        } = this.props;

        let operator = activation && activation.mnp
        let operatorCode = operator && operator.operatorCode

        const indPhone = settings.switching_to_tariff_note.indexOf('{{switching_to_tariff_msisdn}}');
        const formatTextPhone = (indPhone !== -1) ? `${settings.switching_to_tariff_note.substr(0, indPhone)}<span>${addBrackets(userPhone)}</span>&ensp;${settings.switching_to_tariff_note.substr(indPhone + '{{switching_to_tariff_msisdn}}'.length)}` : settings.switching_to_tariff_note;

        return <ModalNew className="u-modal u-modal_active modal_new" close={close}>
            <div className="content">
                <h2 className="modal_title">Подключение тарифа <br />«{tariffName}»</h2>
                <div className="modal_price">за {tariffSum} ₽/месяц</div>
            </div>

            <div className="info_modal">
                {!isEmptyObj(operator) && operator.StatusCode === 3003
                    ? <div className="message">Оператор не определен</div>
                    : !isEmptyObj(operator) && operator.regionId !== selectedRegion.id
                        ? <div className="message">Выбран не верный регион</div>
                        : <form
                            onKeyDown={(event) => {
                                // по клику на Enter предотвращаем любые всплытия
                                if (event.which === 13 || event.keyCode == 13 || event.key === "Enter") {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    event.nativeEvent.stopImmediatePropagation();
                                }
                            }}
                        >
                            <div className="information">
                                <div className="information_phone">
                                    Вы указали номер телефона:
                                    <span>{addBrackets(userPhone)}</span>
                                </div>
                                <div className="information_text">
                                    {operatorCode === 'mEKATERINBURG'
                                        ? 'Указанный номер является нашим абонентом. Вы хотите изменить тариф на указанном номере Мотив?'
                                        : 'Указанный номер не является нашим абонентом. Вы хотите перейти с указанным номером в Мотив?'
                                    }
                                </div>
                            </div>

                            <div className="form_row btns">
                                <button
                                    className="btn_cancel"
                                    onClick={this.props.close}
                                >
                                    Отмена
                                </button>
                                {operatorCode === 'mEKATERINBURG'
                                    ? <button
                                        className="btn_accent"
                                        onClick={this.onSubmit}
                                        type="submit"
                                    >
                                        Изменить тариф
                                    </button>
                                    : <button
                                        className="btn_accent"
                                        onClick={this.checkOperator}
                                        type="submit"
                                    >
                                        Перейти в Мотив
                                    </button>
                                }
                            </div>
                        </form>
                }

            </div>
        </ModalNew>
    }
}

export class SuccessInfoModal extends Component {

    renderText = () => {
        let { title, btnText, close, isConstructor, gb, min, social_unlim, sms, everywhere_at_home, smsCount, motiv_voice_unlim, router, text = '', youtube_unlim, ivi, settings, apiOptionNames } = this.props;
        if (text) {
            return <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
        }

        if (settings && settings.success_description) {
            let optionsNames = {};
            if (isConstructor && apiOptionNames) {
                const { IVI, MVOICE_UNLIM, SOCIAL_UNLIM, YOUTUBE_UNLIM, ROUTER } = apiOptionNames;
                optionsNames = {
                    router: ROUTER,
                    social_network_unlim: SOCIAL_UNLIM,
                    motiv_voice_unlim: MVOICE_UNLIM,
                    youtube_unlim: YOUTUBE_UNLIM,
                    ivi: IVI
                }
            } else {
                settings.options_names.map((option) => {
                    optionsNames[option.alias] = option.value;
                })
            }
            const description = ` Гб, ${min} Мин${router ? `, ${optionsNames.router}` : ''}${social_unlim ? `, ${optionsNames.social_network_unlim}` : ''}${motiv_voice_unlim ? `, ${optionsNames.motiv_voice_unlim}` : ''}${youtube_unlim ? `, ${optionsNames.youtube_unlim}` : ''}${ivi ? `, ${optionsNames.ivi}` : ''}${sms ? `, ${smsCount} Смс` : ''}${everywhere_at_home ? `, ${optionsNames.everywhere_at_home}` : ''}`

            const ind = settings.success_description.indexOf('{{tariff_params}}');

            if (ind === -1) {
                return <div className="text">{settings.success_description}</div>
            } else {
                return <div className="text">{settings.success_description.substr(0, ind)}{gb === 0 ? <Icon name={'icon_unlimModal'} className={'unlim-modal u-icon u-icon_logo'} /> : gb}{description}{settings.success_description.substr(ind + '{{tariff_params}}'.length)}</div>
            }
        }

        return ''
    }

    render() {

        let { title, btnText, close, gb, min, social_unlim, sms, smsCount, motiv_voice_unlim, router, text = '', youtube_unlim, ivi, settings } = this.props;

        if (typeof getCookie("utm_start") !== "undefined") {
            setUtmCookie(2, "utm_finish", checkUtmParams());
        }

        return <Modal className="u-modal u-modal_active modal-fullscreen modal-success-info" close={close}>
            <div className="content">
                <img src={success} />
                <h2 className="modal-title">{title}</h2>
                {
                    this.renderText()
                }
                <button className="btn btn-accent btn-chevron-right" onClick={close}>{btnText}</button>
            </div>
        </Modal>
    }
}

export class SuccessInfoModalNew extends Component {

    renderText = () => {
        let {
            settings,
            text = '',

            constructor,
            dataForUrl,
            data_unlim,
            // sms,
            gb,
            router,
            min,
            social_unlim,
            motiv_voice_unlim,
            youtube_unlim,
            ivi,
            home_region_checked,
            apiOptionNames,
            smsCount
        } = this.props;

        if (text) {
            return <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
        }

        if (settings && settings.success_description) {
            let optionsNames = {};

            if (!isEmptyObj(apiOptionNames)) {
                const { IVI, MVOICE_UNLIM, SOCIAL_UNLIM, YOUTUBE_UNLIM, ROUTER, HOME_REGION_CHECKED } = apiOptionNames;
                optionsNames = {
                    router: ROUTER,
                    social_network_unlim: SOCIAL_UNLIM,
                    motiv_voice_unlim: MVOICE_UNLIM,
                    youtube_unlim: YOUTUBE_UNLIM,
                    ivi: IVI,
                    home_region_checked:  HOME_REGION_CHECKED
                }
            } else {
                settings.options_names.map((option) => {
                    optionsNames[option.alias] = option.value;
                })
            }

            let description = ` ГБ, ${min} минут${router ? `, ${optionsNames.router}` : ''}${social_unlim ? `, ${optionsNames.social_network_unlim}` : ''}${motiv_voice_unlim ? `, ${optionsNames.motiv_voice_unlim}` : ''}${youtube_unlim ? `, ${optionsNames.youtube_unlim}` : ''}${ivi ? `, ${optionsNames.ivi}` : ''}${home_region_checked ? `, ${optionsNames.home_region_checked}` : ''}${smsCount ? `, ${smsCount} SMS` : ''}`

            let ind = settings.success_description.indexOf('{{tariff_params}}');

            return (
                <div className="text">
                    {ind === -1
                        ? <div className="text__title">{settings.success_description}</div>
                        : <div className="text__title">
                            {settings.success_description.substr(0, ind)}
                            {gb === 0
                                ? <Icon name={'icon_unlimModal'} className={'unlim-modal u-icon u-icon_logo'} />
                                : gb
                            }
                            {description}
                            {settings.success_description.substr(ind + '{{tariff_params}}'.length)}
                        </div>
                    }

                    {!isEmptyObj(dataForUrl) &&
                        (<SocialLinks title={'Поделись тарифом'} dataForUrl={dataForUrl} />)
                    }
                </div>
            )
        }

        return ''
    }

    render() {

        let {
            title,
            btnText,
            close,
            text = '',
            constructor,
            dataForUrl,
            data_unlim,
            // sms,
            gb,
            router,
            min,
            social_unlim,
            motiv_voice_unlim,
            youtube_unlim,
            ivi,
            apiOptionNames,
            smsCount
        } = this.props;

        if (typeof getCookie("utm_start") !== "undefined") {
            setUtmCookie(2, "utm_finish", checkUtmParams());
        }

        return <ModalNew className="u-modal u-modal_active modal-fullscreen modal-success-info modal_new" close={close}>
            <div className="content">
                <img src={success_orange} />
                <h2
                    className="modal-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                {this.renderText()}
                <button className="btn btn-accent" onClick={close}>{btnText}</button>
            </div>
        </ModalNew>
    }
}