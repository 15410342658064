const initState = {
    active: {isOk: false},
    tariffsLists: [],
    archiveTariffsList: [],
    archive: {isOk: false},
    individualTariffsList: [],
    individual: {isOk: false},
    selectionSettings: [],
    filterSettings: [],
    tariffCategories: {},
    smsTariffs: [],
    loading: false,
    isError: false
};

export function tariffs(state = initState, action) {

    switch (action.type) {
        case 'TARIFF_LIST_ARCHIVE':
            return {...state, isOk: false};
        case 'TARIFF_LIST_ARCHIVE_SUCCESS':
            return {...state, archiveTariffsList: action.data, isOk: true};
        case 'TARIFF_LIST_INDIVIDUAL':
            return {...state, isOk: false};
        case 'TARIFF_LIST_INDIVIDUAL_SUCCESS':
            return {...state, individualTariffsList: action.data, isOk: true};
        case 'TARIFF_LIST':
            return {...state, isOk: false};
        case 'TARIFF_LIST_SUCCESS':
            let tariffsList = [...state.tariffsLists];
            action.data.filter((item) => {
                if (tariffsList.filter((elem) => {
                    return item.id === elem.id
                }).length === 0) {
                    tariffsList.push(item);
                }
            });
            return {...state, tariffsLists: tariffsList, isOk: true};
        case 'TARIFF_FILTER_SETTINGS_SUCCESS':
            return {...state, filterSettings: {...action.data, isOk: true}};
        case 'TARIFF_SELECTION_SETTINGS_SUCCESS':
            return {...state, selectionSettings: {...action.data, isOk: true}};
        case 'GET_TARIFFS_SUPPORTS_LIST':
            return {...state, isOk: false, loading: true, isError: false};
        case 'GET_TARIFFS_SUPPORTS_LIST_SUCCESS':
            return {...state, smsTariffs: {data: action.data.data, pagination: action.data.pagination}, isOk: true, loading: false};
        case 'GET_TARIFFS_SUPPORTS_LIST_FORBIDDEN':
        case 'GET_TARIFFS_SUPPORTS_LIST_NOT_FOUND':
        case 'GET_TARIFFS_SUPPORTS_LIST_VALIDATION_ERROR':
        case 'GET_TARIFFS_SUPPORTS_LIST_BLOCKED':
            return {...state, smsTariffs: {...action.data.data}, isOk: true, loading: false, isError: true};
        case 'GET_SERVICE_CATEGORIES':
            return {...state, isOk: false, isError: false};
        case 'GET_SERVICE_CATEGORIES_SUCCESS':
            return {...state, tariffCategories: action.data, isOk: true};
        case 'GET_SERVICE_CATEGORIES_FORBIDDEN':
        case 'GET_SERVICE_CATEGORIES_NOT_FOUND':
        case 'GET_SERVICE_CATEGORIES_VALIDATION_ERROR':
        case 'GET_SERVICE_CATEGORIES_BLOCKED':
            return {...state, tariffCategories: action.data, isOk: true, isError: true};
    }
    return state;
}