import React, { Component } from 'react';

import Slider from 'rc-slider';

export default class RangeInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputText: ''
        }
        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        let inputText = '';
        const { type, marks, unlim } = this.props;
        let vals = Object.keys(marks);
        let lastVal = vals[vals.length - 2];

        if (type === 'svoiGb' && unlim) {
            inputText = '∞'
        }
        else {
            inputText = '∞';
        }
        this.setState({ inputText })
    }

    onChange(value) {
        this.props.onChange(value);
    };

    onInputChange(e) {
        this.props.onChange(e.target.value);
    };

    render() {
        let {
            min,
            max,
            step,
            marks,
            unit,
            dots = false,
            value,
            type = false,
            stepSlider = false,
            onFocus = () => { },
            onBlur = () => { },
            onKeyDown = () => { },
            onAfterChange = () => { },
            valueSvoi = false,
            valueData,
            readOnly = false,
        } = this.props;

        const { inputText } = this.state;

        let sliderValue = 0;

        if (!!valueSvoi) {
            sliderValue = valueSvoi;
        } else {
            sliderValue = value;
        }

        if (value === inputText && type !== 'svoiMinutes' && type !== 'svoiGb') {
            sliderValue = max;
        }

        let localValue = '';

        if (type === 'svoiGb') {
            localValue = value;

            if (!!valueData) {
                localValue = inputText;
            }
            if (typeof value === "undefined") {
                localValue = '';
                sliderValue = min;

            }
        } else {
            localValue = value;
        }

        if (value === max && type !== 'svoiMinutes' && type !== 'svoiGb') {
            value = inputText;
        }

        return (
            <div className="range-input">
                <div className="b-input-text b-input-text_adapt_small">
                    <div className="b-input-text__wrapper wrapper">
                        <input
                            type="text"
                            className="b-input-text__input"
                            value={localValue}
                            onChange={this.onInputChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            readOnly={readOnly}
                        />
                        {unit ? <span className="range-input-unit">{unit}</span> : ""}
                        <div className="range-slider range_slider">
                            <Slider
                                min={min}
                                marks={marks}
                                max={max}
                                step={stepSlider ? stepSlider : null}
                                value={sliderValue}
                                onChange={this.onChange}
                                onAfterChange={onAfterChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}