import React, {Component, Fragment} from 'react';
import Modal from "../common/Modal";
import {mainUrl} from "../../tools/config";
import {connect} from "react-redux";
import {codeValidatorText, emailValidatorText} from "../../tools/form-helper";
import PdfLink from "../Constructor/PdfLink";
import {getCookie} from "../../tools/tools";
import {clearTariffPopupError} from "../../actions/activation";

class TariffLessPriseModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            changeTariffSending: false
        }
    }
    componentWillUnmount() {
        this.props.clearTariffPopupError();
    }

    componentWillReceiveProps(nextProps) {

        let available = nextProps.activation.available;

        if (available.hasOwnProperty('query_id')) {
            if (nextProps.activation.activated === true) {
                this.props.openSuccess('success-tariff-connection');
            }
        }
        if (nextProps.activation.errorBalanceLack && nextProps.activation.balanceLack && nextProps.activation.balanceLack.hasOwnProperty('message')) {
            this.setState({ errorMessage: nextProps.activation.balanceLack.message })
        }
    }
    render() {

        let {
            close,
            title,
            text,
            icon = true,
            btnText = 'Ок',
            payments = false,
            motivPay = null,

            className,
            wrapperClassName = '',
            typeWidth = '',
            region,
            acceptChangeTariff,
            clientRegionSlug,
            activation
        } = this.props;

        return (
            <Modal
                className="u-modal u-modal_active u-modal-top tariff-connection-modal"
                close={close}
                typeWidth={typeWidth}
                wrapperClassName={wrapperClassName}
                className={className}
            >
                <div className={`mnp-popup-ok swiper-container mnp-popup__wrapper ${typeWidth === 'md' ? 'mnp-popup-ok__md' : ""}`}>
                        {
                            this.state.errorMessage
                            ? <p> {this.state.errorMessage}</p>
                            : (
                                <Fragment>
                                    {text ? <div dangerouslySetInnerHTML={{__html: (this.props.activation.balanceLack && this.props.activation.balanceLack.info_text) ? this.props.activation.balanceLack.info_text : ''}} className={`b-success-modal__text ${payments ? "top-up-balance__modal" : ""}`} /> : null}

                                    {
                                        activation.is500Error ? (
                                          <span className='b-error-text'>К сожалению, мы не можем подключить интересующий вас тариф. Повторите попытку позже или позвоните по номеру 8 800 240 0000 (звонок бесплатный).</span>
                                        ) : (
                                          <div className="tariff-less-modal-btns">
                                              <button
                                                type="button"
                                                className={`u-btn u-btn_default u-btn_default-gray u-btn_adapt_mobile-sm ${this.state.changeTariffSending ? 'disabled' : ''}`}
                                                onClick={() => {this.setState({changeTariffSending: true}); acceptChangeTariff();}}
                                              >
                                                  Да, сменить тариф
                                              </button>
                                              <a
                                                href={mainUrl + region + '/payments-transfers?' + 'phone=' + this.props.userPhoneValue.replace(/\D/gim, '').slice(1) + '&payment=' + ((this.props.activation.balanceLack && this.props.activation.balanceLack.recommended_payment) ? this.props.activation.balanceLack.recommended_payment : 0)}
                                                className={`u-btn u-btn_default u-btn_adapt_mobile-sm`}
                                              >
                                                  Нет, пополнить баланс
                                              </a>
                                          </div>
                                        )
                                    }
                                </Fragment>
                            )
                        }
                </div>
            </Modal>
        )
    }
}

export default connect(
    state => ({
        activation: state.activation,
        userPhoneValue: state.common.userPhoneValue,
    }),
    (dispatch) => {
        return {
            clearTariffPopupError: () => dispatch(clearTariffPopupError()),
        }
    }
)(TariffLessPriseModal);