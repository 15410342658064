import React, {Component} from "react";
import {mediaUrl} from "../../tools/config";
import YouTube from 'react-youtube';

export default class InfoBlockCareerVideo extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isPlaying: false,
        };

        this.media = React.createRef();
        this.wrapMediaRef = React.createRef();
    }

    render() {

        let data = this.props.dataCareerVideo ? this.props.dataCareerVideo[0] : null;

        let {
            isPlaying
        } = this.state;

        let videoId = /https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/watch(?:\?|#\!)v=)([\w-]{11}).*/gi.exec(data.videoPath);

        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
                autoplay: 0,
                controls: 0,
                iv_load_policy: 3,
                loop: 1,
                modestbranding: 1,
                showinfo: 0
            }
        };

        return (<div className="page-career-video">
            {data && data.title ?
                <div className="b-intro-simple__title" dangerouslySetInnerHTML={{__html: data.title}}/> : null}
            <div className={"page-career-video__wrapper" + ( videoId == null ? " page-career-video__wrapper-img" : "")}>
                {videoId !== null ? <YouTube
                    containerClassName="career-video-player"
                    videoId={ videoId[1] }
                    opts={ opts }
                    // onReady={ this._onReady }
                /> :
                    data && data.imagePath ? <img className="b-product-plate__image b-product-plate__image_def"
                         src={ mediaUrl + data.imagePath } alt="" role="presentation" /> : null}
            </div>
        </div>)
    }
}