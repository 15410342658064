import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import SmallMenu from "../../../js/components/PaymentsTransfers/SmallMenu";
import {motivPayPageAction} from "../../actions/paymentsTransfers"
import {infoBlocksList} from "../../actions/infoBlocks";
import InfoBlock from "../../components/common/InfoBlock";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";

class MotivPay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentWillMount(){
        let regionId = this.context && this.context.selectedRegion.id;

        if (!this.props.paymentPays.length){
            this.props.motivPayPageAction();
        }

        this.props.getInfoBlocks(regionId);
        console.log('4444');
    }
    
    componentDidMount(){        
        if (!this.props.paymentPays.length){
            this.props.motivPayPageAction();
        }
    }


    render() {
        let {
            paymentPays,
            infoBlocks,
            routes,
        } = this.props

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/payments-transfers',
                title: "Платежи"
            },
            {
                url: "",
                title: 'МОТИВ.pay'
            }
        ];

        //получаем нужный нам SEO {}
        let routeSeo = routes && routes.list.find(x => x.sys_name === 'motivPayRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                </Helmet>

                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>

                        {paymentPays && paymentPays.map((item) => 
                            <React.Fragment key={item.id}>
                                <h1 className="payments-page__title">{item.title}</h1>

                                <div className="motiv-pay__block">
                                    {/* type = нужный case section = api type.alias */}
                                    <InfoBlock type={'motiv_pay_banner'} data={ infoBlocks } section={'motiv_pay_banner'} />

                                    <div className="motiv-pay__info" dangerouslySetInnerHTML={{__html: item.content}} />
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <SmallMenu 
                                // при назначении держит открытое меню
                                flag={'motiv-pay'}
                                // при назначении ищет и подчеркивает ссылку
                                flagSubMenu={'motiv-pay'}
                                // title в мобильном меню
                                customTitleMenu={'МОТИВ.pay'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        paymentPays: state.paymentsTransfers.paymentPays,
        // решает проблему с другими инфоблоками при переходе на страницу
        infoBlocks: state.infoBlocks.list.filter((item) => item.groups.map((group) => group.alias).indexOf('services_payment') !== -1),
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            motivPayPageAction: () => dispatch(motivPayPageAction()),
            getInfoBlocks : (regionId) => dispatch(infoBlocksList(regionId, 'services_payment')),
        }
    }
)(MotivPay);