import React, {Component} from "react";
import SalaryFork from "../../components/Vacancies/SalaryFork";

export default function VacancyInfo({ data }){
    const vacancyItem = data ? data : null;

    return ( <div className="page__vacancy-info">
            {vacancyItem && vacancyItem.title ? <div className="b-intro-simple__title" dangerouslySetInnerHTML={{ __html: vacancyItem.title }}/> : null}
            {vacancyItem && vacancyItem.greeting ? <div className="b-intro-simple__greeting" dangerouslySetInnerHTML={{ __html: vacancyItem.greeting }}/> : null}
            {vacancyItem && vacancyItem.salary ? <SalaryFork data={ vacancyItem.salary } /> : null}
            {vacancyItem && vacancyItem.responsibilities ?<div className="page__vacancy-info-block">
                <div className="page__vacancy-info-title">Чем предстоит заниматься:</div>
                <div className="page__vacancy-info-description" dangerouslySetInnerHTML={{ __html: vacancyItem.responsibilities }}/>
            </div> : null}
            {vacancyItem && vacancyItem.requirements ?<div className="page__vacancy-info-block">
                <div className="page__vacancy-info-title">Что мы ждем от Вас:</div>
                <div className="page__vacancy-info-description" dangerouslySetInnerHTML={{ __html: vacancyItem.requirements }}/>
                {vacancyItem && vacancyItem.educationLevel ? <div className="page__vacancy-info-description">Образование: { vacancyItem.educationLevel }</div> : null}
            </div> : null}
            {vacancyItem && vacancyItem.conditions ?<div className="page__vacancy-info-block">
                <div className="page__vacancy-info-title">Что мы предлагаем:</div>
                <div className="page__vacancy-info-description" dangerouslySetInnerHTML={{ __html: vacancyItem.conditions }}/>
                {vacancyItem && vacancyItem.experience ? <p className="page__vacancy-info-description">Опыт работы: { vacancyItem.experience }</p> : null}
                {vacancyItem && vacancyItem.schedule ? <p className="page__vacancy-info-description">График работы: { vacancyItem.schedule }</p> : null}
                {vacancyItem && vacancyItem.employment ? <p className="page__vacancy-info-description">Тип занятости: { vacancyItem.employment }</p> : null}
                {vacancyItem && vacancyItem.salary ? <p className="page__vacancy-info-description">Уровень заработной платы: { vacancyItem.salary }</p> : null}
            </div> : null}
        </div>
    )
}