import React, {Component} from "react";
import LinkC from "../common/LinkC";
import Slider from "react-slick";

import {mediaUrl} from "../../tools/config";
import MnpForm from "../Rates/MnpForm";
import Modal from "../common/Modal";
import InfoblockLink from "./InfoblockLink";

import CommonCallbackForm from "../../components/Forms/CommonCallbackForm";

export function BottomSupportInfoBlock({blocks}){
    return <div className="b-support-no-answer__list">
        {blocks.map((item, key) => <div className="b-support-no-answer__col" key={key}>
            <InfoblockLink className="b-support-no-answer__item" item={item}>
                <span className="b-support-no-answer__text">{item.title}</span>
                <span className="b-support-no-answer__icon">
                    <img className="b-support-no-answer__icon u-icon u-icon_no-answer" src={mediaUrl + item.imagePath}/>
                </span>
            </InfoblockLink>
        </div>)}
    </div>
}

export function RightSupportInfoBlock({blocks}){

    return blocks.map((item, key) => <div className={"page__aside-item page__aside-item_info"+(key === 0 ? " page__aside-item_first" : "")}>
        <InfoblockLink className="b-aside-info b-aside-info_adapt_default" item={item}>
            <div className="b-aside-info__title">{item.title}</div>
            <div className="b-aside-info__text">{item.text}</div>
            <div className="b-aside-info__icon">
                <img className="b-aside-info__img" src={mediaUrl + item.imagePath} alt="" role="presentation" />
            </div>
        </InfoblockLink>
    </div>);
}

export function RightSupportSlidesInfoBlock({blocks, fastDecisionsRoute}){

    const settings = {
        infinite: true,
        swipeToSlide: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
    };

    if (!blocks || blocks.length === 0) return <React.Fragment/>;

    return <div className="page__aside-item page__aside-item_slider">
        <div className="b-aside-slider b-aside-slider_adapt_default">
            <Slider {...settings} className="b-aside-slider__slider">
                {blocks.map((item, key) => <div className="b-aside-slider__slide" key={key}>
                    <div className="b-aside-slider__title">Быстрые решения</div>
                    { item.slug
                        ?   <div className="b-aside-slider__subtitle">
                                {
                                    item.class === 'tariffs'
                                        ?  'Перейти на тариф "' + item.name + '"'
                                        :  'Подключить услугу "' + item.name + '"'
                                }
                            </div>
                        :  ''
                    }
                    <div className="b-aside-slider__text" dangerouslySetInnerHTML={{__html: item.text}}/>
                    <div className="b-aside-slider__text">{item.desc} <b>{item.code}</b></div>
                </div>)}
            </Slider>
            <LinkC className={"b-aside-slider__link"} to={ fastDecisionsRoute } >Все решения</LinkC>
        </div>
    </div>;
}

export class RightSupportMessengerInfoBlock extends Component{

    constructor(){
        super();
        this.state = {
            modal: false
        }
    }

    toggleModal = (e) => {

        if (e) e.preventDefault();

        this.setState((prevState) => {
            return {
                modal: !prevState.modal
            }
        })
    }

    renderModal() {

        let content = "";

        if (this.state.modal) {
            content = <CommonCallbackForm closeModal={this.toggleModal}/>
        } else {
            return;
        }

        return  <Modal innerClassName="u-modal-inner no-padding" close={this.toggleModal}>
            {content}
        </Modal>
    }

    render(){
        let {
            blocks,
            subblocks
        } = this.props;

        let block = blocks.length ? blocks[0] : {};
        let textParts = block.text && block.text.split('{callLink}');

        return [
            <div className="page__aside-item page__aside-item_social" key="messenger-buttons">
                <div className="b-aside-social b-aside-social_adapt_default">
                    <div className="b-aside-social__title">{block.title}</div>
                    <div className="b-aside-social__list">
                        {subblocks.map((item, key) => <div className="b-aside-social__col" key={key}>
                            <InfoblockLink className="b-aside-social__item" item={item}>
                                <img src={mediaUrl + item.imagePath} className="b-aside-social__icon u-icon"/> {item.title} </InfoblockLink>
                        </div>)}
                    </div>
                </div>
            </div>,
            <div className="page__aside-item page__aside-item_text" key="messenger-links">
                {textParts ? <div className="b-aside-text">
                    <div className="b-aside-text__text">
                        {textParts.length > 0 ? textParts[0] : null}
                        {textParts.length > 1 ? <a href="#" className="b-aside-text__link" onClick={this.toggleModal}>{textParts[1]}</a> : null}
                        {textParts.length > 2 ? textParts[2] : null}
                    </div>
                </div> : null}
                {this.renderModal()}
            </div>
        ];
    }
}
