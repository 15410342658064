const initState = { isOk:false, footerMenuList: []};

export function footer(state = initState, action) {
    switch (action.type) {
        case 'FOOTER_MENU_LIST':
            return {...state, isOk: false};
        case 'FOOTER_MENU_LIST_SUCCESS':
            return {...state, footerMenuList: action.data, isOk: true};
    }
    return state;
}