import React, { Component } from 'react';
import PropTypes from "prop-types";
import {mediaUrl} from "../../tools/config";

class InfoBlockRoamingBigBanner extends Component {

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBefore: PropTypes.func,
    };

    render() {

        let block = this.props.blocks[0];

        return (
            <div className="b-promo-block b-promo-block b-promo-block_adapt_default">
                <div className="b-promo-block__wrapper" style={{backgroundImage: "url(" + mediaUrl + block.imagePath + ")"}}>
                    <div className="b-promo-block__title">{block.title}</div>
                    <p className="b-promo-block__text">{block.text}</p>
                </div>
            </div>
        )
    }
}

export default InfoBlockRoamingBigBanner;