import React, {Component} from "react";
import PropTypes from "prop-types";

import {phoneMask} from "../../tools/mask.js";
import {getObjectData, emailValidator, phoneValidator} from "../../tools/form-helper";
import Icon from "../common/Icon";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";
import RequiredText from "../common/form/RequiredText";

export default class InfoConfirm extends Component{

    constructor(props, context){
        super(props, context);

        this.state = {
            fields: {
                phone: {
                    label: "Номер для переноса",
                    value: phoneMask(context.fields.phone),
                    mask: phoneMask,
                    disabled: true,
                    hasError: false
                },
                region: {
                    label: "Регион",
                    value: context.fields.titleRegion,
                    disabled: true,
                    hasError: false
                },
                operator: {
                    label: "Текущий оператор",
                    value: context.fields.operator,
                    disabled: true,
                    hasError: false
                },
                callback_phone: {
                    label: "Укажите номер для обратной связи",
                    value: "",
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: null,
                    textError: "Укажите корректный номер телефона",
                    required: true,
                },
                email: {
                    label: "Ваш e–mail",
                    value: "",
                    validators: [emailValidator],
                    hasError: null,
                    textError: "Укажите корректный email",
                    required: true
                }
            },
            checkbox: {
                checkbox_1: false,
                checkbox_2: false,
                checkbox_3: false,
                checkbox_agree: false
            },
            showForm: false
        }
    }

    static contextTypes = {
        goToStep: PropTypes.func,
        step: PropTypes.number,
        fields: PropTypes.object
    };

    goBack = () => {
        this.context.goToStep(this.context.step - 1);
    };

    onCheckboxChange = (name) => {
        return () => {
            this.setState((prevState) => {
                return {
                    checkbox: {
                        ...prevState.checkbox,
                        [name]: !prevState.checkbox[name]
                    }
                }
            }, () => {
                this.setState((prevState) => {
                    return {
                        showForm: prevState.checkbox.checkbox_1 && prevState.checkbox.checkbox_2 && prevState.checkbox.checkbox_3
                    }
                })
            })
        }
    };

    onFieldChange = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        value: this.state.fields[name].mask ? this.state.fields[name].mask(e.target.value) : e.target.value
                    }
                }
            })
        }
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                })
            }
        }
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    isValid = () => {
        let {
            fields,
            checkbox,
            showForm
        } = this.state;

        if (showForm) {
            for (let key in fields) {
                if (!fields[key].value) {
                    return false;
                }
            }
            if (fields.callback_phone.value.replace(/\D/gim, '').length !== 11) {
                return false;
            }
            if (fields.email.value && !emailValidator(fields.email.value)) {
                return false;
            }
            return checkbox.checkbox_1 && checkbox.checkbox_2 && checkbox.checkbox_3 && checkbox.checkbox_agree;
        } else {
            return checkbox.checkbox_1 && checkbox.checkbox_2 && checkbox.checkbox_3;
        }
    };

    onSubmit = () => {
        if (this.state.showForm && this.isValid() && !this.props.inProgress) {
            this.props.onSubmit({...getObjectData({...this.state.fields}), form_name: "Шаг3"});
        } else {
            this.setState({showForm: true});
        }
    };

    renderField = (field) => {
        let fieldConfig = {...this.state.fields[field]};

        return <div className="b-input-text__wrapper">
            <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                <div className="b-input-text__label-text">{fieldConfig.label}{fieldConfig.required ? <span className={"orange"}>*</span> : ''}</div>
            </div>
            <div className="b-input-text__input-area">
                <InputIcon error={fieldConfig.hasError}/>
                <input className={"b-input-text__input" + (fieldConfig.hasError ? " has-error" : "")}
                       type="text"
                       value={fieldConfig.value}
                       disabled={fieldConfig.disabled}
                       onChange={this.onFieldChange(field)}
                       onBlur={this.onBlur(field)}
                       onFocus={this.onFocus(field)}
                />
                <InputError hasError={fieldConfig.hasError} errorText={fieldConfig.textError}/>
            </div>
        </div>
    };

    render(){

        let {
            conditions,
            conditions_desc,
            number_agreements_checkbox_agree_description,
            number_agreements_checkbox_agree_title,
            number_agreements_title,
            children
        } = this.props;

        let {
            showForm,
            checkbox
        } = this.state;

        return <div>
            <div className="page__step-title">{number_agreements_title}</div>
            {children}
            <RequiredText />
            <div className="page__step-form page__step-form_confirm">
                <div className="b-step-form b-step-form_theme_mnp-confirm b-step-form_adapt_mnp-confirm">
                    <div className="b-step-form__wrapper">
                        <div className="b-step-form__block">
                            {[1,2,3].map((key) => <div className="b-step-form__checkbox-item" key={"checkbox"+key}>
                                <div className="b-input-checkbox b-input-checkbox_adapt_mnp-step b-input-checkbox_subtext">
                                    <label className="b-input-checkbox__wrap" htmlFor={"checkbox_"+key}>
                                        <input id={"checkbox_"+key}
                                               name={"checkbox_"+key}
                                               className="b-input-checkbox__input"
                                               type="checkbox"
                                               onChange={this.onCheckboxChange("checkbox_"+key)}
                                        />
                                        <span className="b-input-checkbox__icon"/>
                                        <span className="b-input-checkbox__text">{this.props["number_agreements_checkbox_"+key+"_title"]}</span>
                                        <span className="b-input-checkbox__subtext">{this.props["number_agreements_checkbox_"+key+"_description"]}</span>
                                    </label>
                                </div>
                            </div>)}
                        </div>
                        {conditions ? <div className="b-step-form__block b-step-form__block_doc-download">
                            <div className="b-step-form__doc-download">
                                <div className="b-download-doc b-download-doc b-download-doc_theme_mnp-step b-download-doc b-download-doc_adapt_mnp-step">
                                    <div className="b-download-doc__wrapper">
                                        <a className="b-download-doc__item b-download-doc__item_pdf" target="_blank" href={conditions}>
                                            {conditions_desc}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        {showForm ? <div className="b-step-form__block b-step-form__block_inputs">
                            <div className="b-step-form__row">
                                <div className="b-step-form__item b-step-form__item_size_half">
                                    <div className="b-step-form__input">
                                        <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                            {this.renderField("phone")}
                                        </div>
                                    </div>
                                </div>
                                <div className="b-step-form__item b-step-form__item_size_half">
                                    <div className="b-step-form__input">
                                        <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                            {this.renderField("operator")}
                                        </div>
                                    </div>
                                </div>
                                <div className="b-step-form__item b-step-form__item_size_full">
                                    <div className="b-step-form__input">
                                        <div className="b-input-text b-input-text_adapt_mnp-approved b-input-text_approved">
                                            {this.renderField("region")}
                                        </div>
                                    </div>
                                </div>
                                <div className="b-step-form__item b-step-form__item_size_half">
                                    <div className="b-step-form__input">
                                        <div className="b-input-text b-input-text_adapt_mobile-small">
                                            {this.renderField("callback_phone")}
                                        </div>
                                    </div>
                                </div>
                                <div className="b-step-form__item b-step-form__item_size_half">
                                    <div className="b-step-form__input">
                                        <div className="b-input-text b-input-text_adapt_mobile-small">
                                            {this.renderField("email")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        {showForm ? <div className="b-step-form__block b-step-form__block_checkbox-agree">
                            <div className="b-step-form__checkbox-item">
                                <div className="b-input-checkbox b-input-checkbox_adapt_mobile-small">
                                    <label className="b-input-checkbox__wrap" htmlFor="checkbox_agree">
                                        <input className="b-input-checkbox__input"
                                               type="checkbox"
                                               id="checkbox_agree"
                                               onChange={this.onCheckboxChange("checkbox_agree")}
                                               checked={checkbox.checkbox_agree ? "checked" : false}
                                        />
                                        <span className="b-input-checkbox__icon"/>
                                        <span className="b-input-checkbox__text" dangerouslySetInnerHTML={{__html: number_agreements_checkbox_agree_title}}/>
                                        <span className="b-input-checkbox__subtext">{number_agreements_checkbox_agree_description}</span>
                                    </label>
                                </div>
                            </div>
                        </div> : null}
                        <div className="b-step-form__btns-sector">
                            <div className="b-step-form__btn-item u-btn u-btn u-btn_lg-responsive u-btn u-btn_transparent" onClick={this.goBack}>
                                Назад
                            </div>
                            <div id={"mnp-info-confirm-button"} className={"b-step-form__btn-item u-btn u-btn u-btn_lg-responsive u-btn u-btn_default"+(!this.isValid() || this.props.inProgress ? " disabled" : "")}
                                 onClick={this.onSubmit}
                            >
                                Продолжить
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
