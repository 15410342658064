import React, {Component, Fragment} from 'react';
import Icon from "../common/Icon";
import IconInst from "../common/icon_inst";

export function Social({ active = [] }) {
    let iconNames = ["icon_wh", "icon_vb", "icon_vk-ac", "icon_ok-ac", "icon_tw-ac", "icon_ma", "icon_youtube-r", "icon_rutube", "icon_ivi", "icon_share", "icon_live_jo", "icon_moim_mir"];
    let icons = [];

    iconNames.map((icon) => {
        var iconClass = "";
        if (active.indexOf(icon) != -1) {
            iconClass = icon;
            if (iconClass === "icon_inst") {
                icons[active.indexOf(icon)] = <IconInst />;
            } else {
                icons[active.indexOf(icon)] = <Icon key={icon}
                    className={"b-tariff-plate__soc-icon u-icon u-" + iconClass}
                    name={icon}
                />;
            }
        }
    });

    return icons;
}
class TariffDescription extends Component {

    getSocials = (icon) => {
        return <Fragment>
            {icon === 'icon_vk-ac' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" fill="#0077FF"/>
                  <path d="M12.6602 16.5C8.26875 16.5 5.60439 13.4944 5.5 8.5H7.72419C7.79353 12.1688 9.48564 13.7234 10.7827 14.0432V8.5H12.9142V11.6662C14.1654 11.5294 15.475 10.0892 15.9151 8.5H18.0131C17.6774 10.4543 16.2517 11.8944 15.2444 12.4885C16.2525 12.9688 17.8744 14.2261 18.5 16.5H16.194C15.7071 14.98 14.5144 13.8026 12.915 13.6427V16.5H12.6602Z" fill="white"/>
              </svg>}
            {icon === 'icon_ok-ac' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 24 24">
                  <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#F7931E"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2862 5C10.3598 5 8.79821 6.56164 8.79821 8.48796C8.79821 10.4143 10.3598 11.9761 12.2862 11.9761C14.2125 11.9761 15.7741 10.4143 15.7741 8.48796C15.7741 6.56164 14.2125 5 12.2862 5ZM12.2862 9.92991C11.4899 9.92991 10.8443 9.2843 10.8443 8.48801C10.8443 7.69172 11.4899 7.04617 12.2862 7.04617C13.0825 7.04617 13.728 7.69172 13.728 8.48801C13.728 9.2843 13.0825 9.92991 12.2862 9.92991ZM13.5175 14.7513C14.9139 14.4669 15.7506 13.8056 15.7949 13.7701C16.2035 13.4424 16.2691 12.8455 15.9415 12.4369C15.6138 12.0283 15.017 11.9626 14.6083 12.2903C14.5996 12.2973 13.7072 12.9819 12.2387 12.9829C10.7703 12.9819 9.85896 12.2973 9.85032 12.2903C9.44162 11.9626 8.84479 12.0283 8.51714 12.4369C8.18945 12.8455 8.25509 13.4424 8.66369 13.7701C8.70857 13.8061 9.57988 14.4846 11.0154 14.7624L9.01475 16.8532C8.65117 17.2303 8.6621 17.8306 9.03916 18.1942C9.22319 18.3717 9.46039 18.4599 9.69738 18.4599C9.94586 18.4599 10.1941 18.3628 10.3802 18.1698L12.2388 16.1899L14.2851 18.1826C14.6558 18.5528 15.2562 18.5522 15.6263 18.1815C15.9964 17.8109 15.9959 17.2104 15.6252 16.8403L13.5175 14.7513Z" fill="white"/>
              </svg>}
            {icon === 'icon_moim_mir' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 24 24">
                  <title/>
                  <circle cx="8.1241" cy="7.06317" r="1.66129" style={{fill: '#4c9ae8'}}/>
                  <circle cx="15.87679" cy="7.06317" r="1.66129" style={{fill: '#4c9ae8'}}/>
                  <path d="M2.98242,18.03076a1.00007,1.00007,0,0,1-.84668-1.53027L4.646,12.48584a.99986.99986,0,0,1,1.69531,1.06055L3.83105,17.561A.99861.99861,0,0,1,2.98242,18.03076Z" style={{fill: '#4c9ae8'}} />
                  <path d="M11.99121,18.72314a9.94267,9.94267,0,0,1-7.88428-3.84863l1.57617-1.23145a8.00027,8.00027,0,0,0,12.61377.00391l1.5752,1.23145A9.94309,9.94309,0,0,1,11.99121,18.72314Z" style={{fill: '#4c9ae8'}} />
                  <path d="M21.00928,18.03076a.99861.99861,0,0,1-.84863-.46973l-2.51025-4.01465a.99986.99986,0,0,1,1.69531-1.06055L21.856,16.50049a1.00007,1.00007,0,0,1-.84668,1.53027Z" style={{fill: '#4c9ae8'}} />
              </svg>}
            {icon === 'icon_live_jo' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 24 24">
                  <path d="M11.8958 1.43925C10.4168 1.43925 9.01125 1.7475 7.7355 2.29875L5.43375 0H5.4285C2.9985 1.06425 1.05075 3.02175 0 5.46L2.3055 7.76325L2.304 7.7655C1.7475 9.0435 1.416 10.4355 1.416 11.919C1.416 17.709 6.10725 22.3995 11.8958 22.3995C17.682 22.3995 22.3748 17.7075 22.3748 11.919C22.3748 6.132 17.6828 1.43925 11.8958 1.43925Z" fill="#00B0EA"/>
                  <path d="M10.8277 16.272C11.8777 13.8337 13.827 11.8762 16.2562 10.8112H16.2577L7.73696 2.30324L7.7332 2.30249C5.3017 3.36749 3.35396 5.32424 2.30396 7.76399L10.8277 16.272Z" fill="#15374C"/>
                  <path d="M16.8666 13.7175C15.4551 14.3355 14.3241 15.4748 13.7129 16.8893L17.6924 17.712L16.8666 13.7175Z" fill="#15374C"/>
                  <path d="M16.8667 13.719C16.5464 12.1913 16.2577 10.812 16.2577 10.812L16.2547 10.8128C13.8262 11.8778 11.8777 13.8345 10.8269 16.2728L13.7129 16.8893C14.3257 15.4793 15.4589 14.3355 16.8667 13.719Z" fill="white"/>
              </svg>}
            {icon === 'icon_ivi' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 40 40">
                  <g clip-path="url(#clip0_4535_34186)">
                      <g clip-path="url(#clip1_4535_34186)">
                          <path d="M39.5241 22.2382C37.6794 38.3159 33.8389 41.3676 17.7605 39.5236C1.68353 37.6788 -1.3666 33.8376 0.475893 17.7599C2.32062 1.68222 6.16189 -1.3679 22.2396 0.476829C38.3173 2.3201 41.3689 6.16137 39.5241 22.239V22.2382Z" fill="#F30745"/>
                          <path d="M26.6015 10.3242C24.9803 10.3242 23.683 10.9726 22.3864 12.8824L17.3427 20.4841L17.8108 14.5753C17.9552 12.3417 16.5501 10.3242 14.0637 10.3242C11.7577 10.3242 10.3167 11.8736 10.1724 13.8552L9.37976 24.7359C9.19955 27.4384 10.6046 28.987 12.8382 28.987C15.0717 28.987 16.4768 27.3658 17.5222 25.8882L22.3856 18.5026L21.9534 24.7351C21.7373 27.2574 23.1065 28.9862 25.592 28.9862C27.8982 28.9862 29.4109 27.6171 29.5552 25.5637L30.3478 14.5753C30.528 12.4135 29.4841 10.3242 26.6015 10.3242Z" fill="white"/>
                      </g>
                  </g>
                  <defs>
                      <clipPath id="clip0_4535_34186">
                          <rect width="40" height="40" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_4535_34186">
                          <rect width="40" height="40" fill="white"/>
                      </clipPath>
                  </defs>
              </svg>}
            {icon === 'icon_youtube-r' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" fill="#F70808"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6207 7.9578C17.1297 8.09934 17.5296 8.51489 17.6659 9.04367C17.9121 10.0011 17.9121 12 17.9121 12C17.9121 12 17.9121 13.9989 17.6649 14.9563C17.5287 15.4851 17.1287 15.9006 16.6197 16.0422C15.6981 16.299 12.001 16.299 12.001 16.299C12.001 16.299 8.30384 16.299 7.38223 16.0422C6.87326 15.9006 6.47328 15.4851 6.33703 14.9563C6.08984 13.9989 6.08984 12 6.08984 12C6.08984 12 6.08984 10.0011 6.33703 9.04367C6.47328 8.51489 6.87326 8.09934 7.38223 7.9578C8.30384 7.70099 12.001 7.70099 12.001 7.70099C12.001 7.70099 15.6981 7.70099 16.6207 7.9578ZM13.8915 11.9991L10.8192 13.8412V10.157L13.8915 11.9991Z" fill="white"/>
              </svg>}
            {icon === 'icon_rutube' &&
              <svg className={'icon ' + icon + ' b-tariff-plate__soc-icon u-icon u-'+ icon} viewBox="0 0 132 132">
                  <g clip-path="url(#clip0_519_1980)">
                      <rect width="132" height="132" fill="#100943"/>
                      <path d="M132 66.0001C168.451 66.0001 198 36.4508 198 3.05176e-05C198 -36.4508 168.451 -66 132 -66C95.5492 -66 66 -36.4508 66 3.05176e-05C66 36.4508 95.5492 66.0001 132 66.0001Z" fill="#ED143B"/>
                      <path d="M81.5361 62.9865H42.5386V47.5547H81.5361C83.814 47.5547 85.3979 47.9518 86.1928 48.6451C86.9877 49.3385 87.4801 50.6245 87.4801 52.5031V58.0441C87.4801 60.0234 86.9877 61.3094 86.1928 62.0028C85.3979 62.6961 83.814 62.9925 81.5361 62.9925V62.9865ZM84.2115 33.0059H26V99H42.5386V77.5294H73.0177L87.4801 99H106L90.0546 77.4287C95.9333 76.5575 98.573 74.756 100.75 71.7869C102.927 68.8179 104.019 64.071 104.019 57.7359V52.7876C104.019 49.0303 103.621 46.0613 102.927 43.7857C102.233 41.51 101.047 39.5307 99.362 37.7528C97.5824 36.0698 95.6011 34.8845 93.2223 34.0904C90.8435 33.3971 87.8716 33 84.2115 33V33.0059Z" fill="white"/>
                  </g>
                  <defs>
                      <clipPath id="clip0_519_1980">
                          <rect width="132" height="132" rx="66" fill="white"/>
                      </clipPath>
                  </defs>
              </svg>}
        </Fragment>
    }

    render() {

        let { tariff, slugSvoi = false } = this.props;
        let result = <div></div>, socialSection = [];

        if (slugSvoi !== -1 && tariff.svoi.social_network_unlim) {
            socialSection.push("icon_vk-ac", "icon_ok-ac", "icon_live_jo", "icon_moim_mir")
        }
        if (slugSvoi !== -1 && !!tariff.svoi.youtube_unlim) {
            socialSection.push("icon_youtube-r", "icon_rutube")
        }
        if (slugSvoi !== -1 && !!tariff.svoi.ivi) {
            socialSection.push("icon_ivi")
        }

        if (tariff.subscription_fee >= 0 && tariff.must_show_short_desc === false) {

            result =
                <div className={`b-tariffs-table__col-gb ${!this.props.title ? ' mt' : ''}`}>
                    <div className="b-tariffs-table__wrapper">
                        {
                            tariff.internet_gb_count
                                ? <div className="b-tariffs-table__gb">
                                    <div className="b-tariffs-table__gb-num">{tariff.internet_gb_count}&nbsp;</div>
                                    <div className="b-tariffs-table__gb-text">Гб</div>
                                </div>
                                : ''
                        }
                        {
                            tariff.speed_limit
                                ? <div className="b-tariffs-table__gb">
                                    <div className="b-tariffs-table__gb-text">до</div>
                                    <div className="b-tariffs-table__gb-num">&nbsp;{tariff.speed_limit}</div>
                                </div>
                                : ''
                        }
                    </div>
                    {
                        tariff.minutes_count
                            ? <div className="b-tariffs-table__gb">
                                <div className="b-tariffs-table__gb-num">{tariff.minutes_count}&nbsp;</div>
                                <div className="b-tariffs-table__gb-text">Мин</div>
                            </div>
                            : ''
                    }
                    {
                        tariff.sms_count
                            ? <div className="b-tariffs-table__gb">
                                <div className="b-tariffs-table__gb-num">{tariff.sms_count}&nbsp;</div>
                                <div className="b-tariffs-table__gb-text">Смс</div>
                            </div>
                            : ''
                    }
                    {
                        tariff.home_region_checked
                            ? <div className="b-tariffs-table__gb">
                                <div className="b-tariffs-table__gb-num">Услуга «Везде как дома»</div>
                            </div>
                            : ''
                    }
                    {socialSection.length > 0
                        ? <div className="b-tariff-plate__soc-sec">
                            <div className="b-tariff-plate__soc-icons-wrap">
                                <div className="b-tariff-plate__soc-icons social_icons">
                                    {socialSection.map((icon) => (
                                      <Fragment>
                                          {this.getSocials(icon)}
                                      </Fragment>
                                    ))}
                                    {/*<Social active={socialSection} />*/}
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>;
        }

        if ((tariff.subscription_fee >= 0 && tariff.must_show_short_desc === true) || tariff.subscription_fee === 0) {
            result = <div className="b-tariffs-table__col-gb b-tariffs-table__col-gb b-tariffs-table__col-gb_text-gb">
                <div className="b-tariffs-table__text-gb" dangerouslySetInnerHTML={{ __html: tariff.short_description }} />
            </div>;
        }

        return (
            result
        )
    }
}

export default TariffDescription;