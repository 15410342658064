import React, {Component} from "react";
import Icon from './../common/Icon';
import {
    VKShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    FacebookShareButton
} from 'react-share';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default class SocialLinks extends Component{
    constructor(props){
        super(props);
        this.state = {
            show: false
        }
    }

    componentDidUpdate(prevState){
        if (this.state.show !== prevState.show && !!this.state.show) {
            setTimeout(() => {
                this.setState({show: false});
            }, 2000);
        }
    }

    render(){
        let {title, dataForUrl} = this.props;
        let {show} = this.state;
        let shareUrl = '';

        // if (typeof window !== "undefined") {
        //     let basicUrl = window.location.origin + window.location.pathname;                        
        //     shareUrl = basicUrl + '?svoi=' + dataForUrl.min + '-' + (dataForUrl.data === 1 && dataForUrl.router === 0 ? 'u' : dataForUrl.router === 1 ? 'r' : dataForUrl.gb) + '-' + (dataForUrl.sms === 1 ? dataForUrl.sms_pack_count : 0)  + '-' + dataForUrl.social + '-' + dataForUrl.voice + '-' + dataForUrl.youtube_unlim + '-' + dataForUrl.ivi;            
        // }
        if (typeof window !== "undefined") {
            let basicUrl = window.location.origin + window.location.pathname;                        
            shareUrl = basicUrl + '?svoi=' + dataForUrl.min + '-' + (dataForUrl.data === 1 && dataForUrl.router === 0 ? 'u' : dataForUrl.router === 1 ? 'r' : dataForUrl.gb) + '-' + (dataForUrl.sms_pack_count)  + '-' + dataForUrl.social + '-' + dataForUrl.voice + '-' + dataForUrl.youtube_unlim + '-' + dataForUrl.ivi;            
        }

        return <div className="social-icons social_icons">
            <div className="social-icons-text text__share">{title}</div>
            <div className="social-icons-buttons social_icons_buttons">
                {/* <FacebookShareButton url={shareUrl} quote="Мотив. Свой тариф." /> */}
                <VKShareButton url={shareUrl} title="Мотив. Свой тариф." />
                <WhatsappShareButton url={shareUrl} title="Мотив. Свой тариф."/>
                <TelegramShareButton url={shareUrl} title="Мотив. Свой тариф."/>
                <div className="wrap-tooltip">
                    {show ? <span className="wrap-btn-copy__tooltip">Ссылка&nbsp;скопирована</span> : null}
                    <CopyToClipboard text={shareUrl} onCopy={() => this.setState({show: true})}>
                        <div className="wrap-btn-copy-url">
                            <Icon name={'icon_copy'} className={'btn-copy-url btn_copy_url'} />
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    }
}