import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import { v1 as uuidv } from 'uuid';
import Icon from "../../components/common/Icon";
import LinkC from "../../components/common/LinkC";
import {Circle} from "../../components/Rates/MyTariffReminder";
import TopBlock from "../../components/Lk/TopBlock";
import {datePretty, phoneMask} from "../../tools/mask";
import {constuctorUrl, esiaUrl, mainUrl} from "../../tools/config";
import {changeInfo, exchangeMins, services} from '../../actions/client'
import {infoBlocksList} from "../../actions/infoBlocks"
import InfoBlock from "../../components/common/InfoBlock";
import Modal from '../../components/common/Modal';

import {Helmet} from "react-helmet";
import ChangeInfoForm from "../../components/Lk/ChangeInfoForm";

class Abonent extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            modal: '',
        }

        if (!props.client.clientInfo.exchange) {
            props.getExchange(context.selectedRegion.id)
        }
        if (!props.infoBlocks.list.length && props.client.clientInfo.tariff) {
            props.getInfoBlocks(context.selectedRegion.id, "lk_abonent", props.client.clientInfo.tariff.id)
        }
        if (!props.client.services) {
            props.getMyServices()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.client.changeInfoSuccess !== this.props.client.changeInfoSuccess && this.props.client.changeInfoSuccess !== '') {
            this.openModal('')
        }
        if (prevProps.client.changeInfoError !== this.props.client.changeInfoError && this.props.client.changeInfoError !== '') {
            this.openModal('')
        }
    }

    componentDidMount() {
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'lk_abonent');
        }
    }

    componentWillReceiveProps(nextProps){
        if (!this.props.client.clientInfo.tariff && nextProps.client.clientInfo.tariff) {
            nextProps.getInfoBlocks(this.context.selectedRegion.id, "lk_abonent", nextProps.client.clientInfo.tariff.id)
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        isLk: PropTypes.bool,
    };
    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        });
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({
            modal: '',
        });
    };

    gosChangeData = () => {
        const uuidNum = uuidv();
        const urlArr = this.props.client.settings.gs_redirect_url.split('?redirect_url=');
        const redirectUrl = encodeURIComponent(`${urlArr[1]}?msisdn=${this.props.client.clientInfo.username}&state=${uuidNum}`)
        window.location.replace(`${urlArr[0]}?redirect_url=${redirectUrl}`);
    }

    renderModal() {
        let locationHref;
        if (typeof window !== 'undefined') {
            // locationHref = `${window.location.origin}${window.location.pathname === '/' ? '/sverdlovsk_oblast/' : window.location.pathname}${window.location.search !== '' ? window.location.search : ''}`;
            locationHref = `${window.location.origin}${window.location.pathname === '/' ? '/sverdlovsk_oblast/' : window.location.pathname}`;
        }

        let path = `${esiaUrl}blitz/bridge/entrance`;

        switch (this.state.modal) {
            case "choose_an_change_option":
                return <Modal className="u-modal u-modal_active choose_modal choose_modal_gos" close={this.closeModal}>
                    <div className="content">
                        {
                            this.props.client.formsSettings.update_data_form && this.props.client.formsSettings.update_data_form.update_data_form_infotext &&
                            <div className="modal_text" dangerouslySetInnerHTML={{__html: this.props.client.formsSettings.update_data_form.update_data_form_infotext}} />
                        }
                        <div className="modal_btns">
                            <button
                              onClick={this.gosChangeData}
                              className="modal_btns_links modal_btns__portal"
                            >
                                <img src={`${require('../../../images/icons/icon_portal.svg')}`} alt="" />
                                <div className="modal_btns_text">
                                    Через госуслуги
                                </div>
                            </button>
                            <button
                              className="modal_btns_links modal_btns__portal modal_btns__hands"
                              onClick={() => {this.openModal('change_data')}}
                            >
                                <img src={`${require('../../../images/icons/icon_manually.svg')}`} alt="" />
                                <div className="modal_btns_text">
                                    Отправить "Онлайн-заявку"
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal>
            case 'change_data':
                return <Modal className="u-modal u-modal_active modal-change-data" close={this.closeModal}>
                    <div className="content">
                        <ChangeInfoForm
                            title="Заявка на изменение данных"
                            description={this.props.client.formsSettings.personal_form ? (this.props.client.formsSettings.personal_form.personal_form_description || this.props.client.formsSettings.personal_form.personal_block_description) : null}
                            onSubmit={this.props.changeInfo}
                            number={this.props.client.clientInfo.username}
                            checkReq={this.props.checkReq}
                        />
                    </div>
                </Modal>
        }
    }

    renderInfo = (clientInfo) => {
        return <div className="b-lk-number__main">
            <div className="b-lk-number__row">
                <div className="b-lk-number__col-left">
                    <div className="b-lk-number__person">
                        <div className="b-lk-number__name">ФИО</div>
                        <div className="b-lk-number__val">
                            {clientInfo.name}
                        </div>
                    </div>
                    <div className="b-lk-number__person">
                        <div className="b-lk-number__name">Домашний регион</div>
                        <div className="b-lk-number__val">{clientInfo.region && clientInfo.region.title}</div>
                    </div>
                    <div className="b-lk-number__person">
                        <div className="b-lk-number__name">Номер телефона</div>
                        <div className="b-lk-number__val">
                            {clientInfo.username ? phoneMask(clientInfo.username) : null}
                            {clientInfo.status ? <span className={"b-lk-number__status"+(clientInfo.status.id == 1 ? " active" : "")}>{clientInfo.status.title}</span> : null}
                        </div>
                    </div>
                </div>
                <div className="b-lk-number__col-right">
                    <div className="b-lk-number__info">Лицевой счет: {clientInfo.personal_account}</div>
                    <div className="b-lk-number__info">№ договора: {clientInfo.contract && clientInfo.contract.id}</div>
                    <div className="b-lk-number__info">Дата заключения: {clientInfo.contract && datePretty(clientInfo.contract.date)}</div>
                    <div className="b-lk-number__info">
                        {/*<div className="b-lk-number__link-item">*/}
                            {/*<a className="b-lk-number__link" href="#">История операций</a>*/}
                        {/*</div>*/}
                        <div className="b-lk-number__link-item">
                            {clientInfo.status ? <a className="b-lk-number__link" href="#" onClick={this.props.showModal(clientInfo.status.id == 1 ? 'block-number' : 'unblock-number')}>
                                {clientInfo.status.id == 1 ? "Заблокировать" : "Разблокировать"} номер
                            </a> : null}
                        </div>
                    </div>
                    <div className="b-lk-number__change-btn">
                        <div className="b-form-simple__btns-area">
                            <div className="b-form-simple__btn-item">
                                <button
                                  type="button"
                                  className="b-form-simple__btn u-btn u-btn u-btn_default u-btn u-btn_responsive u-btn u-btn_adapt_mobile-sm"
                                  onClick={() => {this.openModal('choose_an_change_option')}}
                                >Изменить данные</button>
                            </div>
                        </div>
                    </div>
                    {/*<div className="b-lk-number__bonus">*/}
                    {/*<div className="b-lk-number__name">Бонусов на 07.02.2018</div>*/}
                    {/*<div className="b-lk-number__val b-lk-number__val_bonus">1000</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    };

    renderTariff = (clientInfo) => {

        // получаем параметры для перехода на другой сайт
        let region = this.context.selectedRegion.alias;
        let regionSlug = this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug;
        let detailUrl = (regionSlug ? "/" + regionSlug : "") + "/payments-transfers";
        let detailUrlLk = (regionSlug ? regionSlug : region) + "/payments-transfers";
        let detailDeferredPageUrl = (regionSlug ? "/" + regionSlug : "") + "/deferred-payment";
        let detailDeferredPageUrlLk = (regionSlug ? regionSlug : region) + "/deferred-payment";

        return <div className="b-lk-number__tariff">
            <div className="b-lk-number__row">
                <div className="b-lk-number__tarif-left">
                    <div className="b-lk-number__name">Текущий тариф</div>
                    {clientInfo.tariff ? <div className="b-lk-number__val" key={clientInfo.tariff.id}>«{clientInfo.tariff.title}»</div> : null}
                    <div className="b-lk-number__link-row" key="tariff-change">
                        {clientInfo.tariff && clientInfo.tariff.details ? <LinkC className="b-lk-number__link" to={mainUrl+this.context.selectedRegion.alias+"/tariffs#constructor"}>Сменить тариф</LinkC> : <LinkC className="b-lk-number__link" to={mainUrl+this.context.selectedRegion.alias+"/tariffs"}>Сменить тариф</LinkC>}
                    </div>
                    {/*<div className="b-lk-number__link-row" key="redirect">*/}
                        {/*<a className="b-lk-number__link" href="#">Переадресация</a>*/}
                    {/*</div>*/}
                </div>
                <div className="b-lk-number__tarif-right">
                    <div className="b-lk-number__name">Баланс счета на {(new Date()).toLocaleDateString('ru')}</div>
                    {clientInfo.balance ? <div className="b-lk-number__val">{clientInfo.balance.value} <span className="price-rub"> ₽</span></div> : null}
                    <div className="b-lk-number__tarif-links two-links">
                        <LinkC className="b-lk-number__link" to={this.context.isLk ? (mainUrl + detailUrlLk) : detailUrl}>Пополнить баланс</LinkC>
                        <LinkC className="b-lk-number__link" to={this.context.isLk ? (mainUrl + detailDeferredPageUrlLk) : detailDeferredPageUrl}>Активировать отложенный платеж</LinkC>
                    </div>
                </div>
            </div>
        </div>
    };

    renderServices = (clientInfo) => {
        return <InfoBlock type="advert_service_block"
                          section="advert_service_block"
                          data={this.props.infoBlocks.list}
                          additionalProps={{
                              showModal: this.props.showModal,
                              exchangeId: this.props.client.settings && this.props.client.settings.exchange_service_id,
                              canExchange: clientInfo.exchange && clientInfo.exchange.is_available && clientInfo.exchange.max_minutes && clientInfo.exchange.allowForTariff,
                              myServices: this.props.client.services ? this.props.client.services.map((item) => item.id_billing) : []
                          }}
        />
    };

    renderRemains = (clientInfo) => {
        return clientInfo.packageRemains && clientInfo.packageRemains.length ? <div className="b-lk-number__remnants">
            <div className="b-lk-remnants b-lk-remnants_adapt-default">
                <div className="b-lk-remnants__title">Остатки по пакетным услугам</div>
                <div className="b-lk-remnants__list">
                    {clientInfo.packageRemains.map((item) => <div className="b-lk-remnants__item">
                        <div className="b-lk-remnants__row">
                            <div className="b-lk-remnants__left">
                                <div className="b-lk-remnants__name">{item.service.title}</div>
                                <div className="b-lk-remnants__group-list">
                                    {/*<div className="b-lk-remnants__group">*/}
                                        {/*<div className="b-lk-remnants__group-name">Срок действия</div>*/}
                                        {/*<div className="b-lk-remnants__group-val">-</div>*/}
                                    {/*</div>*/}
                                    <div className="b-lk-remnants__group">
                                        <div className="b-lk-remnants__group-name">Дата окончания</div>
                                        <div className="b-lk-remnants__group-val">{item.date ? datePretty(item.date) : null}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="b-lk-remnants__center">
                                <div className="b-lk-remnants__line-text">
                                    <div className="b-lk-remnants__label">осталось</div>
                                    <div className="b-lk-remnants__num">{item.rest_amount}
                                        <span className="b-lk-remnants__num-val">{item.unit}</span>
                                    </div>
                                    <div className="b-lk-remnants__line">из {item.total_amount} {item.unit}</div>
                                </div>
                                <div className="b-lk-remnants__line-bar">
                                    <div className="b-lk-remnants__bar-active" style={{width: item.rest_amount*100/item.total_amount+'%'}}/>
                                </div>
                            </div>
                            <div className="b-lk-remnants__right">
                                <div className="b-circle b-circle_adapt-default">
                                    <Circle percent={Math.round(item.rest_amount*100/item.total_amount)}
                                            stroke="#f37021"
                                            radius={55}
                                            total={<div className="b-circle__inner">
                                                <div className="b-circle__text">из</div>
                                                <div className="b-circle__text">{item.total_amount} {item.unit}</div>
                                            </div>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div> : null
    };

    renderJoinedNumbers = () => {
        return <div className="b-lk-number-save b-lk-number-save_adapt-default">
            <div className="b-lk-number-save__title">Сохраненные номера</div>
            <div className="b-lk-number-save__list">
                <div className="b-lk-number-save__item">
                    <div className="b-lk-number-save__row">
                        <div className="b-lk-number-save__left">
                            <Icon name="icon_tel" className="b-lk-number-save__icon u-icon u-icon_tel"/>
                            <div className="b-lk-number-save__tel">+7 (950) 000 11 11</div>
                            <div className="b-lk-number-save__date">Зарезервирован до 30.03.2018
                            </div>
                            <div className="b-lk-number-save__link">Активировать</div>
                        </div>
                        <div className="b-lk-number-save__center">
                            <div className="b-lk-number-save__price">800
                                <sup className="b-lk-number-save__sup">
                                    <span className="u-rub">₽</span>
                                </sup>
                            </div>
                            <div className="b-lk-number-save__period">/ в месяц</div>
                        </div>
                        <div className="b-lk-number-save__right">
                            <div className="b-lk-number-save__btn">Отключить</div>
                        </div>
                    </div>
                </div>
                <div className="b-lk-number-save__item">
                    <div className="b-lk-number-save__row">
                        <div className="b-lk-number-save__left">
                            <Icon name="icon_tel" className="b-lk-number-save__icon u-icon u-icon_tel"/>
                            <div className="b-lk-number-save__tel">+7 (953) 039 12 31</div>
                            <div className="b-lk-number-save__date">Зарезервирован до 30.03.2018
                            </div>
                            <div className="b-lk-number-save__link">Активировать</div>
                        </div>
                        <div className="b-lk-number-save__center">
                            <div className="b-lk-number-save__price">45
                                <sup className="b-lk-number-save__sup">
                                    <span className="u-rub">₽</span>
                                </sup>
                            </div>
                            <div className="b-lk-number-save__period">/ в месяц</div>
                        </div>
                        <div className="b-lk-number-save__right">
                            <div className="b-lk-number-save__btn">Отключить</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };

    render() {
        let {
            isGuest,
            clientInfo
        } = this.props.client;

        if (isGuest) {
            return <Redirect to="/"/>
        }

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'infoLKRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <div className="b-lk-number b-lk-number_adapt-default">
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <div className="b-lk-number__about-title">
                <TopBlock title="Абонент и номер"/>
            </div>
            {/*<div className="b-lk-number__title">Мой основной номер</div>*/}
            {this.renderInfo(clientInfo)}
            {this.renderTariff(clientInfo)}
            {this.renderServices(clientInfo)}
            {this.renderRemains(clientInfo)}
            {this.renderModal()}
            {/*{this.renderJoinedNumbers()}*/}
        </div>
    }
}

export default connect(
    state => ({
        client: state.client,
        infoBlocks: state.infoBlocks,
        isGuest: state.client.isGuest,
        checkReq: state.client.checkReq,
        settings: state.settings,

        routes: state.routes,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
    }),
    (dispatch) => {
        return {
            getExchange: (regionId) => dispatch(exchangeMins(regionId)),
            getInfoBlocks : (regionId, group, tariff) => dispatch(infoBlocksList(regionId, group, tariff)),
            getMyServices: () => dispatch(services()),
            changeInfo: (data) => dispatch(changeInfo(data)),
        }
    }
)(Abonent);