import {
    call,
    put
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";

export function feedbackOrderRequest(name, phone, section) {
    return {
        type: 'FEEDBACK_ORDER_REQUEST',
        name,
        phone,
        section
    }
}

export function* fetchFeedbackOrderRequest(action) {
    try {
        let body = new FormData();

        body.append('phone', action.phone);
        body.append('name', action.password);
        body.append('section', action.section);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/feedback", {
                method: 'POST',
                body
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function feedbackFaqRequest(id, vote, comment) {
    return {
        type: 'FEEDBACK_FAQ_REQUEST',
        id,
        vote,
        comment
    }
}

export function* fetchFeedbackFaqRequest(action) {
    try {

        let body = new FormData();

        body.append('vote', action.vote);
        body.append('comment', action.comment);

        const { status, data, error } = yield call(() => {
            return fetchClientApi("/faq/" + action.id +  "/vote", {
                method: 'PUT',
                body: 'vote=' + (action.vote ? "1" : "0") +(action.comment ? '&comment='+action.comment : '')
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}
