import React, { Component } from 'react';

import SwitcherInput from './SwitcherInput';

export default class AdditionalServicesList extends Component {

    render() {

        return (
            <div className="additional-services-list">
                {this.props.items.map((item, index) =>
                    <div className="additional-services-list__item" key={index}>
                        <div className="additional-services-list__name">{item.name}</div>
                        <div className="additional-services-list__text">{item.text}</div>
                        <div className="additional-services-list__action-area">
                            <div className="additional-services-list__price">
                                <div className="additional-services-list__price-num">{item.price}</div>
                                <div className="additional-services-list__measure">
                                    <div className="additional-services-list__measure-cur">₽</div>
                                    <div className="additional-services-list__period"> /месяц</div>
                                </div>
                            </div>
                            <SwitcherInput id={item.id}/>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
