import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import FaqSearch from "../../components/Support/FaqSearch";
import {faqList} from "../../actions/questions";
import {formsList} from "../../actions/forms";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SecurityMessage from "../../components/Support/SecurityMessage";
import {routesList} from "../../actions/routes";
import RightNav from "../../components/Support/RightNav";
import MobileNav from "../../components/Support/MobileNav";

class Security extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        if (this.props.faq && this.props.faq.list.length === 0) {
            this.props.getFaq(this.context.selectedRegion.id, null, 'rank_security');
        }

        let form = this.props.forms ? this.props.forms.list.find(x => x.alias === 'security_form_block') : null;

        if (this.props.forms.list.length === 0 || !form) {
            this.props.getForms("security");
        }

        if ( this.props.routes.list.length === 0 ) {
            this.props.getRoutes();
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    render() {

        let selectedRegion = this.context.selectedRegion;

        let {
            faq,
            forms,
            supportErrors,
            routes,
            regions,
            location
        } = this.props;        

        let supportRoute = routes ? routes.list.find(x => x.sys_name === 'supportRoute') : null;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + supportRoute.route,
                title: "Поддержка"
            },
            {
                url: "",
                title: "Безопасность и защита от мошенничества"
            }
        ];

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'securityRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder main-media-bank" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container motiv-vacancies motiv-media-bank">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <RatesBreadcrumbs items={breadcrumbs} />
                                </div>
                                <div className="b-intro-simple__title">Безопасность и защита от&#160;мошенничества</div>
                            </div>
                            <div className="b-support-faq b-support-faq_adapt-defoult">
                                <div className="b-support__wrapper">
                                    <SecurityMessage forms={forms.list} supportErrors={supportErrors}/>
                                    <FaqSearch rankField={"rank_security"} list={faq.list} />
                                </div>
                            </div>
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                <RightNav />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        faq: state.faq,
        forms: state.forms,
        supportErrors: state.support.errors || {},
        routes: state.routes,
        regions: state.regions
    }),
    (dispatch) => {
        return {
            getFaq : (regionId, categoryId, categoryRankField) => dispatch(faqList(regionId, categoryId, categoryRankField)),
            getForms: (group) => dispatch(formsList(group)),
            getRoutes: () => dispatch(routesList()),
        }
    }
)(Security);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const securityRequests = [
    {
        func: 'getForms',
        args: ["security"],
        object: 'forms.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getFaq',
        args: [null, 'rank_security'],
        object: 'faq.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
    },
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];