import React, { Component } from 'react';

class SendResumeBlock extends Component {

    render() {
        let { openModal, dataSend } = this.props;
        let data = dataSend ? dataSend[0] : null;
        return (
            <div
                className="b-message-action b-message-action_summary b-message-action_adapt_summary">
                <div className="b-message-action__wrapper">
                    <div className="b-message-action__inner">
                        {data && data.title ? <div className="b-message-action__title" dangerouslySetInnerHTML={{__html: data.title}} /> : null}
                        {data && data.text ? <div className="b-message-action__text"  dangerouslySetInnerHTML={{__html: data.text}} /> : null}
                    </div>
                    <div className="b-message-action__action-block">
                        <div className="b-message-action__btn">
                            <div
                                className="u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default"
                                data-modal=""
                                onClick={ openModal("open") }>Отправить резюме
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SendResumeBlock;