import React, {Component, Fragment} from "react";
import Modal from "../../components/common/Modal";
import B2bTariffConnectionModal from "../../components/modals/B2bTariffConnectionModal";

export function withTariffModal(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                modal: null,
                modalData: null
            }
        }

        onClickConnection = (name, tariff) => {
            this.setState({
                modal: name,
                modalData: tariff
            })
        }

        modalClose = () => {
            this.setState({
                modal: null,
                modalData: null
            })
        }

        render() {
            const {modal, modalData} = this.state;
            return <Fragment>
                <WrappedComponent {...this.props} onClickConnection={this.onClickConnection} modalClose={this.modalClose} />
                {modal ? <Modal close={this.modalClose}>
                    {modal === 'tariff-connection' && <B2bTariffConnectionModal closeModal={this.modalClose} tariff={modalData} />}
                </Modal> : null}
            </Fragment>
        }
    };
}