const initState = { isOk:false, chatFormItems: [] };

export function chatFormFunc(state = initState, action) {
    switch (action.type) {
        case 'CHAT_FORM':
            return {...state, isOk: false};
        case 'CHAT_FORM_SUCCESS':
            return {...state, chatFormItems: action.data, isOk: true};
    }
    return state;
}