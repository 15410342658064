import React, { Component } from "react";
import QuickConnectionForm from "./QuickConnectionForm";
import MnpConnectionForm from "./MnpConnectionForm";
import CallbackConnectionForm from "./CallbackConnectionForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { sms } from "../../actions/sms";
import { flagValueAction } from "../../actions/common";

import { createBrowserHistory, createMemoryHistory } from "history";

class TariffConnection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: "quick",
            firstLoad: true,
            activeModal: "quick"
        };

        let btnParams = !!this.props.tariffParams ? { label: "Купить sim-карту", value: "buy_sim_card" } : { label: "Найти салон связи", value: "find_salon" };

        if (!this.props.isGuest && this.props.typeConnectionModal === 'svoi') {
            this.types = [
                {
                    label: "Сменить тариф",
                    value: "quick"
                },
                {
                    label: "Перейти в Мотив со своим номером",
                    value: "mnp"
                },
                btnParams
            ];
        } else if (this.props.typeConnectionModal === 'svoi') {
            this.types = [
                {
                    label: "Перейти в Мотив со своим номером",
                    value: "mnp"
                },
                {
                    label: "Сменить тариф",
                    value: "quick"
                },
                btnParams
            ];
        } else {
            this.types = [
                {
                    label: "Быстрое подключение",
                    value: "quick"
                },
                {
                    label: "Перейти в Мотив со своим номером",
                    value: "mnp"
                },
                {
                    label: "Заказать звонок",
                    value: "callback"
                }
            ];
        }
    }

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    // открытие модального окна на нужной кнопке
    componentDidMount() {
        const history = typeof document === 'undefined' ? createMemoryHistory() : createBrowserHistory();
        const queryParamsForm = new URLSearchParams(history.location.search).get('form');
        if (this.props.isGuest && queryParamsForm && queryParamsForm === 'svoi') {
            this.onChange('quick');
        }

        this.props.flagValueAction(this.state.type);
    }

    componentWillMount() {
        if (!!this.props.typeConnectionModal && this.props.isGuest && this.props.typeConnectionModal === 'svoi') {
            this.setState({
                type: "mnp",
                firstLoad: true,
                activeModal: "mnp"
            })
        }
    }

    componentDidUpdate(prevState) {
        if (this.props.typeConnectionModal === 'svoi') {
            if (this.props.isGuest) {
                if (prevState.type !== this.state.type && this.state.type === 'mnp') {
                    const el = document.querySelector('.phone_focus');
                    if (el) el.focus();
                }
                if (prevState.type !== this.state.type && this.state.type === 'quick') {
                    const el = document.querySelector('.phone_focus');
                    if (el) el.focus();
                }
            } else {
                if (prevState.type !== this.state.type && this.state.type === 'quick') {
                    const el = document.querySelector('.email_focus');
                    if (el) el.focus();
                }
                if (prevState.type !== this.state.type && this.state.type === 'mnp') {
                    const el = document.querySelector('.phone_focus');
                    if (el) el.focus();
                }
            }
        }
    }

    onChange = (type) => {
        if (type === this.state.activeModal) {

            this.setState({ activeModal: "", type: "" })
        } else {

            this.setState({ type: type, activeModal: type });
        }

        this.props.flagValueAction(type)
    };

    renderForm = () => {

        switch (this.state.type) {
            case "quick":
                return <QuickConnectionForm 
                    userTariffName={this.props.userTariffName}
                    tariffName={this.props.tariffName}
                    close={this.props.close}
                    openSuccess={this.props.openSuccess}
                    settings={this.props.settings}
                    constructor={this.props.constructor}
                    urlStr={this.props.urlStr}
                    isGuest={this.props.isGuest}
                    typeConnectionModal={this.props.typeConnectionModal}
                    tariffParams={this.props.tariffParams}
                    smsCount={this.props.smsCount}
                    linkFile={this.props.linkFile}
                />;
            case "mnp":
                return <MnpConnectionForm 
                    userTariffName={this.props.userTariffName}
                    tariffName={this.props.tariffName}
                    changeTab={this.onChange}
                    close={this.props.close}
                    settings={this.props.settings}
                    constructor={this.props.constructor}
                    urlStr={this.props.urlStr}
                    typeConnectionModal={this.props.typeConnectionModal}
                    tariffParams={this.props.tariffParams}
                    isGuest={this.props.isGuest}
                    smsCount={this.props.smsCount}
                />;
            case "callback":
                return <CallbackConnectionForm 
                    userTariffName={this.props.userTariffName}
                    tariffName={this.props.tariffName}
                    close={this.props.close}
                    openSuccess={this.props.openSuccess}
                    settings={this.props.settings}
                    constructor={this.props.constructor}
                    tariffParams={this.props.tariffParams}
                    urlStr={this.props.urlStr}
                />;
            case "find_salon":
                return <div className="text-lg find-salon_descr" dangerouslySetInnerHTML={{ __html: this.props && this.props.settings && this.props.settings.find_office_description }} />
            case "buy_sim_card":
                return <div className="text-lg find-salon_descr" dangerouslySetInnerHTML={{ __html: this.props && this.props.settings && this.props.settings.buy_sim_description }} />
            default:
                return "";
        }
    };

    render() {

        let isSm = false;
        if (window.innerWidth < 768) { isSm = true; }
        let type = this.state.type;
        let activeModal = this.state.activeModal;
        let urlSim = this.props.tariffParams.online_shop_link;

        if ((isSm === true) && (this.state.firstLoad === true)) {
            this.setState({ firstLoad: false, type: "", activeModal: "" });
        }

        return <div className={"tariff-connection" + (!this.props.isGuest && this.props.typeConnectionModal === "svoi" ? " tariff-connection-auth" : "")}>
            <div className={"tariff-connection-buttons"}>
                {this.types.map((item) => <div>
                    {item.value === 'find_salon' ?
                        <a className="tariff-connection-btn__link" onClick={() => { this.onChange(item.value) }} href={'/' + this.context.selectedRegion.alias + '/motiv-on-map'} target="_blank">
                            <button className={"tariff-connection-btn" + (item.value === type ? " active" : "")}>
                                <span className="wrap-btn-inner">{item.label}</span>
                            </button>
                        </a> : item.value === 'buy_sim_card' ?
                            <a className="tariff-connection-btn__link" onClick={() => { this.onChange(item.value) }} href={!!urlSim ? urlSim : '/'} target="_blank">
                                <button className={"tariff-connection-btn" + (item.value === type ? " active" : "")}>
                                    <span className="wrap-btn-inner">{item.label}</span>
                                </button>
                            </a> :
                            <button className={"tariff-connection-btn" + (item.value === type ? " active" : "")} onClick={() => { this.onChange(item.value) }}>
                                <span className="wrap-btn-inner">{item.label}</span>
                            </button>
                    }

                    {((item.value === type) && (type === activeModal) && (isSm === true)) ? this.renderForm() : null}</div>)}
            </div>
            {(isSm === true) ? null : this.renderForm()}
        </div>
    }
}

export default connect(
    state => ({
        sms: state.sms,
        flagValue: state.common.flagValue
    }),
    (dispatch) => {
        return {
            sendSms: (phone) => dispatch(sms(phone)),
            flagValueAction: (data) => dispatch(flagValueAction(data)),
        }
    }
)(TariffConnection);
