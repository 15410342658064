import React, {Component} from 'react';
import Tooltip from "../common/Tooltip";
import LinkC from "./LinkC";

class PriceItem extends Component {

    constructor(props) {
        super(props);
    }

    render () {

        let {
            item,
            num
        } = this.props;

        return (
            <React.Fragment>
                <div className="b-price-list__item">
                    <div className="b-price-list__text" dangerouslySetInnerHTML={{__html: item.title}}/>
                    {item.notice
                        ?   <Tooltip id={"price-item-" + num} label={<span className="img-icon icon-info"/>} openedLabel={<span className="img-icon icon-info-select"/>} className="numbers-tooltip">
                                <p dangerouslySetInnerHTML={{__html: item.notice}}/>
                            </Tooltip>
                        :   ''
                    }
                    {item.link ? <LinkC to={item.link} className="b-price-list__price" targetBlank={true} noFollow={true}>{item.value}</LinkC> : <div className="b-price-list__price" dangerouslySetInnerHTML={{__html: item.value}}/>}
                    {item.measure ? <div className="b-price-list__valute">&nbsp;<span dangerouslySetInnerHTML={{__html: item.measure}}/></div> : null}
                </div>
            </React.Fragment>
        )
    }
}

export default PriceItem;