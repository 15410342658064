import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import LinkC from "../../../js/components/common/LinkC";
import Img from 'react-lazilyload-img';
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { dataContactsAction } from '../../actions/contacts';
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import { routesList, routesVacanciesList } from "../../actions/routes";
import AsideMenu from "../../../js/components/Vacancies/AsideMenu";


class Contacts extends Component {
    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

        // для ssr
        componentWillMount() {
            this.props.dataContactsAction();
        }
    
        componentDidMount(){
            this.props.dataContactsAction();
        }

    render() {

        let selectedRegion = this.context.selectedRegion;
        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + '/about',
                title: "О компании"
            },
            {
                url: "",
                title: 'Контакты'
            }
        ];

        const {
            regions,
            location,
            stateDataContacts,
        } = this.props;
    
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'contactsRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container">
                <Helmet
                    htmlAttributes={{ "class": "pp-mod_support_spam" }}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="info-page-container__content">
                    <div className="info-page-container__content-left-part">
                        <div className="info-page-container__wrap-breadcrumbs">
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        </div>
                        <h1 className="info-page-container__title">{stateDataContacts && stateDataContacts[0] && stateDataContacts[0].title}</h1>

                        <div className="about-page__contacts-all contacts-page__wrap-contacts-all">
                            <div className="about-page__contacts-item">
                                <LinkC to={"/for-subscribers"} className={""}>
                                    <div className="about-page__contacts-item-content">
                                        <img
                                            className="about-page__contacts-item-img"
                                            src={require("../../../images/content/info-pages/subscriber.svg")}
                                            alt="Контакты"
                                        />
                                        <div className="about-page__contacts-item-title">
                                            Для абонентов
                                        </div>
                                    </div>
                                </LinkC>
                            </div>
                            <div className="about-page__contacts-item">
                                <LinkC to={"/for-press"} className={""}>
                                    <div className="about-page__contacts-item-content">
                                        <img
                                            className="about-page__contacts-item-img"
                                            src={require("../../../images/content/info-pages/press.svg")}
                                            alt="Для прессы"
                                        />
                                        <div className="about-page__contacts-item-title">
                                            Для прессы
                                        </div>
                                    </div>
                                </LinkC>
                            </div>
                            <div className="about-page__contacts-item">
                                <LinkC to={"/for-partners"} className={""}>
                                    <div className="about-page__contacts-item-content">
                                        <img
                                            className="about-page__contacts-item-img"
                                            src={require("../../../images/content/info-pages/hands.svg")}
                                            alt="Для партнеров"
                                        />
                                        <div className="about-page__contacts-item-title">
                                            Для партнеров
                                        </div>
                                    </div>
                                </LinkC>
                            </div>
                            <div className="about-page__contacts-item">
                                <LinkC to={"/for-buisness"} className={""}>
                                    <div className="about-page__contacts-item-content">
                                        <img
                                            className="about-page__contacts-item-img"
                                            src={require("../../../images/content/info-pages/portfolio.svg")}
                                            alt="Для корпоративных клиентов"
                                        />
                                        <div className="about-page__contacts-item-title">
                                            Для бизнеса
                                        </div>
                                    </div>
                                </LinkC>
                            </div>

                            <div
                                className="page__wrap_mail_to"
                                dangerouslySetInnerHTML={{__html: stateDataContacts && stateDataContacts[0] && stateDataContacts[0].content}}
                            />
                        </div>
                    </div>
                    <div className="info-page-container__content-right-part">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <AsideMenu 
                                flag={"contactsRoute"} 
                                route={this.props.routesVacancies} 
                                customTitleMenu={'Контакты'} 
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        regions: state.regions,
        stateDataContacts: state.contacts.stateDataContacts,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            dataContactsAction: () => dispatch(dataContactsAction()),
        }
    }
)(Contacts);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const ContactsRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];
