import React, {Component} from 'react';

import SidebarHeadInfo from './SidebarHeadInfo';
import Ordering, {OrderingOk} from "../Rates/Ordering";
import Modal from "./Modal";
import ChangeRate from "../Rates/ChangeRate";
import PropTypes from "prop-types";
import ButtonDropdown from "./ButtonDropdown";
import RatesBreadcrumbs from "../Rates/RatesBreadcrumps";
import ChangeRateOk from "../modals/ChangeRateOk";

class InnerPageHeader extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: null
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    showModal(type){
        return (e) => {
            e.preventDefault();
            this.setState({modal: type})
        }
    }

    hideModal(){
        this.setState({modal: null});
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    renderModal() {
        let content = "";
        switch (this.state.modal) {
            case 'ordering':
                content = <Ordering onSubmit={this.showModal('ordering-ok')}/>;
                break;
            case 'ordering-ok':
                content = <OrderingOk onClick={this.hideModal}/>;
                break;
            case 'change-rate':
                content = <ChangeRate
                    onSuccess={this.showModal('change-rate-ok')}
                    item={this.props.item}
                    hideModal={this.hideModal}
                    type={"tariff"}
                />;
                break;
            case 'change-rate-ok':
                content = <ChangeRateOk onClick={this.hideModal}/>;
                break;
            default:
                return null;
        }
        return <Modal close={this.hideModal}>{content}</Modal>
    }

    render() {

        let {
            item,
            title
        } = this.props;

        let selectedRegion = this.context.selectedRegion;

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/tariffs",
                title: 'Тарифы'
            },
            {
                url: null,
                title: this.props.isMine ? "Мой текущий тариф" : title
            }
        ];

        return (

            <div className="b-service-inner__service-header">
                <div className="b-inner-intro b-inner-intro_adapt_default">
                    <div className="b-inner-intro__wrapper">
                        <div className="b-inner-intro__inner page__container">
                            <div className="b-inner-intro__header" />
                            <div className="b-inner-intro__main-area">
                                <div className="b-inner-intro__main-area-wrapper">
                                    <RatesBreadcrumbs items={breadcrumbs}/>
                                    <div className="b-inner-intro__title">{title}»</div>
                                    <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: item.short_description}}/>
                                    <SidebarHeadInfo item={item} isMine={this.props.isMine}/>
                                    {
                                        item.status.id === 1
                                            ?   <div className="b-inner-intro__btn">
                                                    <ButtonDropdown name={"Купить тариф"} shopUrl={item.online_shop_link}/>
                                                    <a className="b-inner-intro__link" href="#" onClick={this.showModal('change-rate')}>Сменить тариф</a>
                                                    <a className="b-inner-intro__add-info ">&mdash;&nbsp; Для абонентов МОТИВ</a>
                                                </div>
                                            :   <div className="b-inner-intro__btn">
                                                    <div className="b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                        Перемещена в архив
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </div>

        )
    }
}


export default InnerPageHeader;