import React, { Component } from 'react';

export default class CheckBox extends Component {


    onChange = () => {
        this.props.onChange(this.props.name, !this.props.value-0);
    }


    render() {
        let {
            className = "",
            name = "",
            label = "",
            value = 0
        } = this.props;

        return (
            <div className={className}>
                <div className="checkbox">
                    <input className="checkbox__input" type="checkbox" id={name} checked={value ? "checked" : false} onChange={this.onChange}/>
                    <label className="checkbox__label" htmlFor={name}>
                        <div className="checkbox__description" dangerouslySetInnerHTML={{__html: label}} />
                    </label>
                </div>
            </div>
        )
    }
}
