import React, {Component} from "react";

import {phoneMask} from "../../tools/mask";
import {emptyValidator, getFormData, phoneValidator} from "../../tools/form-helper";
import InputIcon from "../common/InputIcon";
import InputError from "../common/InputError";

export default class CallbackForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            fields: {
                form_name: {
                    value: "Заказать_звонок"
                },
                username: {
                    placeholder: "Ваше имя",
                    value: "",
                    validators: [],
                    hasError: null,
                    textError: "Укажите Ваше имя"
                },
                phone: {
                    placeholder: "Номер телефона",
                    value: "",
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: null,
                    textError: ""
                }
            },
            target: props.target ? props.target : "MNP"
        };
    }

    onFieldChange = (e) => {
        var fields = {...this.state.fields};

        if (fields.hasOwnProperty(e.target.name)) {
            var field = {...fields[e.target.name]};
            field.value = field.mask ? field.mask(e.target.value) : e.target.value;
            fields[e.target.name] = field;
            this.setState({fields});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.props.inProgress) {
            let fields = Object.assign({}, this.state.fields);
            fields.phone.value = fields.phone.value.replace(/\D/gim, '');
            this.props.onSubmit(getFormData({...fields}), 'callback_form', this.state.target);
        }
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    render(){

        let {
            username,
            phone
        } = this.state.fields;

        let {
            callback_form_text,
            callback_form_phone_number,
            callback_form_raw_phone_number,
            children,
            className,
            inProgress
        } = this.props;

        let target = this.state.target;

        return  <div id="mnp-callback-form" className={"page__callback-form" + (className ? ' ' + className : '')}>
            <div className="b-callback-form b-callback-form_theme_mnp b-callback-form_adapt_mnp b-callback-form_adapt_mnp">
                <div className="b-callback-form__title">Заказать звонок</div>
                <div className="b-callback-form__body">
                    <div className="b-callback-form__text-holder">
                        <div className="b-callback-form__intro-text">{callback_form_text}</div>
                        {callback_form_phone_number ? <div className="b-callback-form__phone-holder">
                            <div className="b-callback-form__phone-text">Если у&nbsp;вас остались вопросы, задайте&nbsp;их по&nbsp;телефону:</div>
                            <div className="b-callback-form__phone">
                                <a className="b-callback-form__phone-link" href={`tel: ${callback_form_raw_phone_number}`}>{callback_form_phone_number}</a>
                            </div>
                        </div> : null}
                    </div>
                    <form onSubmit={this.onSubmit} className="b-callback-form__form-holder">
                        <div className="b-callback-form__form-item">
                            <div className="b-input-text b-input-text_adapt_mobile-small b-input-text_theme_mnp">
                                <div className="b-input-text__wrapper">
                                    <div className="b-input-text__input-area">
                                        {/*<InputIcon error={username.hasError} className={target === "MNP" ? 'b-input-text__input-icon-mpn' : ""} />*/}
                                        <input className={"b-input-text__input" + (target === "MNP" ? ' b-input-text__input-mpn' : "") + (username.hasError ? ' has-error' : "")}
                                               type="text"
                                               placeholder={username.placeholder}
                                               value={username.value}
                                               name="username"
                                               onChange={this.onFieldChange}
                                               autoComplete="off"
                                               onBlur={this.onBlur("username")}
                                               onFocus={this.onFocus("username")}
                                        />
                                        <InputError hasError={username.hasError} errorText={username.textError} className={target === "MNP" ? 'b-input-text__input-error-mpn' : ""}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-callback-form__form-item">
                            <div className="b-input-text b-input-text_adapt_mobile-small b-input-text_theme_mnp">
                                <div className="b-input-text__wrapper">
                                    <div className="b-input-text__input-area">
                                        <InputIcon error={phone.hasError} className={target === "MNP" ? 'b-input-text__input-icon-mpn' : ""} />
                                        <input className={"b-input-text__input" + (target === "MNP" ? ' b-input-text__input-mpn' : "") + (phone.hasError ? ' has-error' : "")}
                                               type="text"
                                               placeholder={phone.placeholder}
                                               value={phone.value}
                                               name="phone"
                                               onChange={this.onFieldChange}
                                               onBlur={this.onBlur("phone")}
                                               onFocus={this.onFocus("phone")}
                                        />
                                        <InputError hasError={phone.hasError} errorText={phone.textError} className={target === "MNP" ? 'b-input-text__input-error-mpn' : ""}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="b-callback-form__form-item b-callback-form__form-item_submit">
                            <button className={"b-callback-form__submit u-btn u-btn u-btn_default u-btn u-btn_adapt_mobile-sm" +
                            ((phone.value && phone.value.replace(/\D/gim, '').length === 11) && !inProgress ? "" : " disabled")}
                                    type="submit"
                            >
                                Отправить
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {children}
        </div>
    }

}