import {Component} from "react";
import PropTypes from "prop-types";
import React from "react";
import {getWord, normalizeForSelect} from "../../tools/tools";
import Scrollbars from 'react-scrollbars-custom';
import AutoComplete from "./AutoComplete";
import TabsSwitcher from "./TabsSwitcher";
import NoteCollapse from "../Support/NoteCollapse"
import {connect} from "react-redux";
import {infoBlocksList} from "../../actions/infoBlocks";

class OfficeData extends Component {

    constructor(props){
        super(props);

        this.state = {
            addressQuery: "",
            activeTab: !!props.isMotivCoverMap ? 1 : 0,
            showFilter: false,
            hoursHid: null
        };
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object
    };

    componentWillMount() {
        let popUpNote = this.props.infoBlocks ? this.props.infoBlocks.list.find(x => x.alias === 'motiv_on_map_coverage_pop_up_note') : false;

        if (this.props.infoBlocks && this.props.infoBlocks.list.length === 0  || !popUpNote ) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'motiv_on_map');
        }
    }

    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();

            this.setState(() => {
                return {
                    activeTab: val
                }
            });

            this.props.handlerChangeTab(val);
        }
    };

    toggleWorkingHours = (item) => {
        item = this.state.hoursHid === item ? null : item;

        this.setState({
            hoursHid: item
        });
    };

    handlerFilterTitle = () => {
        this.setState(prevState => {
            return {
                showFilter: !prevState.showFilter
            }
        });
    };

    render() {

        let {
            items,
            address,
            addressAutoComplete,
            chooseAddress,
            zone,
            showZonesHandler,
            showTabs,
            services,
            handlerCheckbox,
            typeMap,
            quantitySelectServices,
            handlerClearFilter,
            activeKey,
            closeCard,
            infoBlocks,
            isMnpOffices = false,
        } = this.props;

        let selectedRegion = this.context.selectedRegion;
        let popUpNoteTitle = infoBlocks && infoBlocks.list.find(x => x.alias === 'motiv_on_map_coverage_pop_up_note');

        return (
            <div className={"b-map__info-block" + ((this.state.activeTab === 1) ? " b-map__info-block-cover" : "")}>
                <div className={"b-map__map-data"}>
                    <div className={`${this.state.activeTab === 0 ? 'b-map-data' : ''} b-map-data_adapt_default`}>
                        <div className="b-map-data__wrapper">

                            {
                                (showTabs && !isMnpOffices)
                                    ? <div className="b-map-data__tabs-header">
                                        <TabsSwitcher
                                            items={[{ title: "Офисы продаж" }, { title: "Покрытие" }]}
                                            onClickFunc={ this.onToggleTab }
                                            activeTab={ this.state.activeTab }
                                            itemHasWrap={true}
                                            className={"b-tabs-header_theme_folder"}
                                            closeCard={closeCard}
                                        />
                                    </div>
                                    : null
                            }

                            {
                                (this.state.activeTab === 0 || isMnpOffices)
                                    ? <div className="b-map-data__salon-search">
                                        <div className="b-salon-search b-salon-search_adapt_default">
                                            <div className="b-salon-search__wrapper">
                                                <div className="b-salon-search__header">
                                                    <div className="b-salon-search__search-input">
                                                        <div className="b-input-text b-input-text b-input-text_adapt_default">
                                                            <div className="b-input-text__wrapper">
                                                                <div className="b-input-text__input-area">
                                                                    <AutoComplete inputOptions={{
                                                                        className: "b-input-text__input",
                                                                        placeholder: "Адрес: город, улица, метро"
                                                                    }}
                                                                    options={normalizeForSelect(address)}
                                                                    getOptions={(val) =>
                                                                        {
                                                                            if (val.length >= 3 && typeMap === 'mnp') {
                                                                                addressAutoComplete('addressFull',
                                                                                    val,
                                                                                    {
                                                                                        region: selectedRegion.name.substr(0, selectedRegion.name.indexOf(" ")),
                                                                                        regionId: selectedRegion.id
                                                                                    });
                                                                            } else if (val.length >= 3 && typeMap !== 'mnp') {
                                                                                addressAutoComplete('addressFull',
                                                                                    val,
                                                                                    {
                                                                                        regionId: selectedRegion.id,
                                                                                        allRegions: 1
                                                                                    });
                                                                            }
                                                                        }
                                                                    }
                                                                    onChange={chooseAddress}
                                                                    showClearIcon={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="b-salon-search__title js-collapser" onClick={this.handlerFilterTitle}>
                                                       {`Подобрать по типам услуг ${ quantitySelectServices !== 0 ? `(${quantitySelectServices})` : "" }`}
                                                        <svg className={`b-salon-search__title-arrow ${this.state.showFilter ? "b-salon-search__title-arrow_active" : ""} `} xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5">
                                                            <path fill="#000" fillRule="evenodd" d="M0 0l5 5 5-5z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="u-collapser u-collapser_open u-collapser_map">
                                                    <div className="u-collapser__container">
                                                        <div className="b-salon-search__body">
                                                            <div className="b-salon-search__counter-block">
                                                                <div className="b-salon-search__counter-text">Найдено:&nbsp;
                                                                    <div className="b-salon-search__counter-color">
                                                                        {items.length} {getWord(items.length, 'салон', 'салона', 'салонов')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.showFilter
                                                                ?
                                                                <div className="b-salon-search__clear-filter">
                                                                    <a className="b-salon-search__clear-filter-btn" onClick={handlerClearFilter}>Cбросить</a>
                                                                </div>
                                                                : null
                                                            }

                                                            <div className="b-salon-search__salon-list">
                                                                <div className="b-salon-search__scroll-holder">
                                                                    {
                                                                        this.state.showFilter
                                                                        ?
                                                                        <Scrollbars style={{ width:"100%", height: "100%" }}>
                                                                            {
                                                                                services.map((service, key) =>
                                                                                    <div className="b-salon-filter__checkbox" key={key}>
                                                                                        <div className={`b-input-checkbox ${!service.active ? "disabled": ""}`}>
                                                                                            <label className="b-input-checkbox__wrap">
                                                                                                <input className="b-input-checkbox__input"
                                                                                                       type="checkbox"
                                                                                                       name="services"
                                                                                                       onChange={handlerCheckbox}
                                                                                                       checked={service.checked}
                                                                                                       value={service.id}
                                                                                                       disabled={!service.active ? "disabled" : ""}
                                                                                                />
                                                                                                <span className="b-input-checkbox__icon"/>
                                                                                                <span className="b-input-checkbox__text">
                                                                                                    { service.title }
                                                                                                </span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </Scrollbars>
                                                                        :
                                                                        <Scrollbars style={{ width:"100%", height: "100%"}}>
                                                                            {items.map((point, key) =>
                                                                                <div className={"b-salon-search__salon-item" + (activeKey === key ? " b-salon-search__salon-item--active" : "")} key={key} onClick={() => this.props.onClick(point, key)}>
                                                                                    <div className="b-salon-search__address">
                                                                                        {point.address}
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="b-salon-search__work-time b-salon-search__discr">
                                                                                            <span>Режим работы:</span>
                                                                                            <span onClick={(e) => {e.stopPropagation(); this.toggleWorkingHours(point.id)}} className="b-salon-search__discr--act">{this.state.hoursHid === point.id ? "скрыть" : "подробнее"}</span>
                                                                                        </div>
                                                                                        <div className={"b-salon-search__work-time" +(this.state.hoursHid === point.id ? " b-salon-search__work-time--open" : "")} dangerouslySetInnerHTML={{__html: point.working_time}} />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Scrollbars>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                this.state.activeTab === 1
                                    ? <div className="b-map-data__cover-zone">
                                        <div className="b-cover-zone b-cover-zone_adapt_default">
                                            <div className="b-cover-zone__wrapper">
                                                <div className="b-cover-zone__search-input">
                                                    <div className="b-input-text b-input-text b-input-text_adapt_default">
                                                        <div className="b-input-text__wrapper">
                                                            <div className="b-input-text__input-area">
                                                                <AutoComplete inputOptions={{
                                                                    className: "b-input-text__input",
                                                                    placeholder: "Адрес: город, улица, метро"
                                                                }}
                                                                      options={normalizeForSelect(address)}
                                                                      getOptions={(val) =>
                                                                          {
                                                                              if (val.length >= 3 && typeMap === 'mnp') {
                                                                                  addressAutoComplete('addressFull',
                                                                                      val,
                                                                                      {
                                                                                          region: selectedRegion.name.substr(0, selectedRegion.name.indexOf(" ")),
                                                                                          regionId: selectedRegion.id
                                                                                      });
                                                                              } else if (val.length >= 3 && typeMap !== 'mnp') {
                                                                                  addressAutoComplete('addressFull',
                                                                                      val,{
                                                                                          regionId: selectedRegion.id,
                                                                                          allRegions: 1
                                                                                      });
                                                                              }
                                                                          }
                                                                      }
                                                                      onChange={chooseAddress}
                                                                      showClearIcon={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="b-cover-zone__zones-list">
                                                    <div
                                                        className={ zone.gsm ? "b-cover-zone__zone-item b-cover-zone__zone-item_gsm" : "b-cover-zone__zone-item"}
                                                        onClick={() => showZonesHandler('gsm')}
                                                    >
                                                        <div className="b-cover-zone__icon" />
                                                        <div className="b-cover-zone__inner">
                                                            <div className="b-cover-zone__zone-title">Зона GSM</div>
                                                            <div className="b-cover-zone__zone-text">Телефонная связь, интернет
                                                                для новостей и социальных сетей
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={ zone.lte ? "b-cover-zone__zone-item b-cover-zone__zone-item_4g" : "b-cover-zone__zone-item"}
                                                        onClick={() => showZonesHandler('lte')}
                                                    >
                                                        <div className="b-cover-zone__icon" />
                                                        <div className="b-cover-zone__inner">
                                                            <div className="b-cover-zone__zone-title">Зона 4G</div>
                                                            <div className="b-cover-zone__zone-text">Очень быстрый интернет,
                                                                видео в высоком качестве
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="b-cover-zone-note">
                                                    { popUpNoteTitle ? <NoteCollapse opened={false} title={"Подробнее о зонах покрытия"} description={ popUpNoteTitle.text } /> : null }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        infoBlocks: state.infoBlocks || [],
    }),
    (dispatch) => {
        return {
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
        }
    }
)(OfficeData);