import React, {Component} from "react";
import Checkbox from "./Checkbox";
import Input from "./Input";

export default class FormField extends Component{

    render(){

        let {
            type,
            className = false
        } = this.props;

        switch (type) {
            case "checkbox":
                return <Checkbox {...this.props} />;
            case "text":
                return <Input {...this.props} className={className} />;
        }
    }
}
