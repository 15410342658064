import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
// import RatesMainForm from '../../components/Rates/RatesMainForm';
import RatesList from '../../components/Rates/RatesList';
import RatesLinks from '../../components/Rates/RatesLinks';
import InfoBlock from '../../components/common/InfoBlock';
import Modal from "../../components/common/Modal";
import RatesFilter from "../../components/Rates/RatesFilter";
import {tariffsList, tariffFilterSettings, tariffSelectionSettings} from "../../actions/tariffs";
import {tariffInfo, exchangeMinsSend, exchangeMins, services, callLogin} from "../../actions/client";
import {infoBlocksList} from "../../actions/infoBlocks";
import { Helmet } from "react-helmet";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import RatesByCategories from "../../components/Rates/RatesByCategories";
import CurrentTariffBlock from "../../components/Lk/CurrentTariffBlock";
import LKModals from "../LK/LKModals";
import TariffConstructor from "../../components/TariffConstructor/TariffConstructor";
import {getNelementsArray, isEmptyObj, checkAllUrlParams, setCookie} from './../../tools/tools';
import {axisValues, settings, price, getTariffConstructor, postTariffConstructor, setTariffParams} from "../../actions/constructor";
import {InfoModal, SuccessInfoModal} from "../../components/modals/constructor/InfoModal";
import TariffConnectionModal from "../../components/modals/constructor/TariffConnectionModal";
import {checkWord, clearWord, clearActivationData} from "../../actions/activation";
import { set } from 'date-fns';
import {cookieDomain} from "../../tools/config";
import {getPathWithoutRegion} from "../../tools/url-helper";
import LinkC from "../../components/common/LinkC";

import {auth, recovery, clearState} from "../../actions/client";
import SuccessModal from "../../components/modals/SuccessModal";
import AuthorizationModal from "../../components/modals/AuthorizationModal";
import RecoveryModal from "../../components/modals/RecoveryModal";

class RatesMain extends Component {

    constructor(props, context) {
        super(props, context);

        let selectionSettings = this.props.selectionSettings;

        //tariffsRoute tariffsDlyaUmnyhVeshcheyRoute tariffsVseRoute
        this.tabUrls = [
            `/${context.selectedRegion.alias}/tariffs`, 
            `/${context.selectedRegion.alias}/tariffs/dlya-umnyh-veshchey`, 
            `/${context.selectedRegion.alias}/tariffs/vse`
        ]

        this.state = {
            activeTab: this.tabUrls.indexOf(props.location.pathname),
            modal: "",
            hasItemModal: false,
            filterData: {
                internet_traffic: selectionSettings.default_internet_traffic,
                minutes: selectionSettings.tariffMonthCallsDefault,
                sms: selectionSettings.tariffMonthSmsDefault,
                internet_social_unlimit: selectionSettings.tariffSocialUnlimitedDefault,
                internet_music_unlimit: selectionSettings.tariffMusicUnlimitedDefault,
                internet_messenger_unlimit: selectionSettings.tariffMessengerUnlimitedDefault,
                cheap_intercity: selectionSettings.tariffCheapIntercityDefault,
                onlyInternet: selectionSettings.tariffOnlyInternetDefault,
                varianceTraffic: selectionSettings.tariffMonthInternetTrafficVariance,
                varianceMinutes: selectionSettings.tariffMonthCallsVariance,
                varianceSms: selectionSettings.tariffMonthSmsVariance,
                max_traffic: selectionSettings.max_internet_traffic
            },
            selectedRegion: null,
            constructorParams: {},
            readyTariffs: [],
            name: "",
            idTariff: "",
            testWorld: false,
            smsCount: 0,
            optionNames: [],
        };

        this.priorityFieldsMain = [
            /*{
                field: 'cheap_intercity',
                priority: selectionSettings.tariffCheapIntercityOrder,
                unlimited: false
            },
            {
                field: 'internet_social_unlimit',
                priority: selectionSettings.tariffInternetSocialUnlimitOrder,
                unlimited: false
            },
            {
                field: 'internet_music_unlimit',
                priority: selectionSettings.tariffInternetMusicUnlimitOrder,
                unlimited: false
            },
            {
                field: 'internet_messenger_unlimit',
                priority: selectionSettings.tariffInternetMessengerUnlimitOrder,
                unlimited: false
            },*/
            {
                field: 'internet_gb_count',
                filter: 'byTraffic',
                priority: selectionSettings.tariffTrafficOrder,
                unlimited: true
            },
            {
                field: 'minutes_count',
                filter: 'byCalls',
                priority: selectionSettings.tariffCallsOrder,
                unlimited: true
            },
            {
                field: 'sms_count',
                filter: 'bySms',
                priority: selectionSettings.tariffSmsOrder,
                unlimited: true
            }
        ];

        this.priorityFieldsMain = this.sortSelectionPropsByPriority(this.priorityFieldsMain);

        this.toggleModal = this.toggleModal.bind(this);
        this.renderModal = this.renderModal.bind(this);

        if (props.currentTariff) {
            props.getInfoBlocksWithTariffs(context.selectedRegion.id, 'tariffs', props.currentTariff.id);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.client.exchangeMinsSuccess) {
            return {...state, modal: "exchange-success"}
        }
        if (props.client.exchangeMinsError) {
            return {...state, modal: "exchange-error"}
        }

        if (!props.isGuest && state.modal === "AuthorizationModal") {
            return {...state, modal: null}
        }

        if (props.client.fatalError && ["AuthorizationModal", "RecoveryModal"].indexOf(state.modal) > -1) {
            return {...state, modal: "ErrorModal"}
        }

        if (props.client.successMessageTitle && "RecoveryModal" === state.modal) {
            return {...state, modal: "SuccessModal"}
        }
        return state;
    }

        // для ssr
    componentWillMount() {
        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (this.props.tariffs.selectionSettings.length === 0) {
            this.props.getTariffSelectionSettings(this.context.selectedRegion.id);
        }
        if (this.props.tariffs.filterSettings.length === 0) {
            // this.props.getTariffFilterSettings();
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'tariffs');
        }
        if (this.props.currentTariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'tariffs', this.props.currentTariff.id);
        }
        if (!this.props.isGuest && (!this.props.currentTariff || Object.keys(this.props.currentTariff).length < 3)) {
            this.props.getCurrentTariff()
        }
        if (!this.props.isGuest && (!this.props.exchange || !Object.keys(this.props.exchange).length)) {
            this.props.getExchangeMins(this.context.selectedRegion.id)
        }
        if (!this.props.isGuest && !this.props.client.services) {
            this.props.getMyServices()
        }

        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }
        // if (isEmptyObj(this.props.tariffConstructor.defaultData)) {
        //     this.props.getTariffConstructor(this.context.selectedRegion.id);
        // }

        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }
    }

    componentDidMount(){
        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }

        // const defaultData = this.props.tariffConstructor.defaultData;
        // let min = defaultData && defaultData.constructor.min;
        // let data = 1;
        // let options = [];
        // options.push('SMS_PACK');
        // options.push('DATA_UNLIM');
        // const tariff = {
        //     min,
        //     data,
        //     options
        // }
        // if (!!urlParams) {
        //     // this.props.postTariffConstructor(tariff, this.context.selectedRegion.id);
        //     this.passingParams(tariff, this.context.selectedRegion.id);
        // } 
        // else {
            // if (!!defaultData && !!defaultData.options) {
            //     const checkOption = defaultData.options.find(x => x.type === "ROUTER");
            //     if (!checkOption) this.props.postTariffConstructor(tariff, this.context.selectedRegion.id);
            // }
            // // this.props.postTariffConstructor(tariff, this.context.selectedRegion.id);
            // this.passingParams(tariff, this.context.selectedRegion.id);
        // }

        if (this.props.tariffs.tariffsLists.length === 0) {
            this.props.getTariffs(this.context.selectedRegion.id);
        }
        if (this.props.tariffs.selectionSettings.length === 0) {
            this.props.getTariffSelectionSettings(this.context.selectedRegion.id);
        }
        if (this.props.tariffs.filterSettings.length === 0) {
            // this.props.getTariffFilterSettings();
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'tariffs');
        }
        if (this.props.currentTariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'tariffs', this.props.currentTariff.id);
        }
        if (!this.props.isGuest && (!this.props.currentTariff || Object.keys(this.props.currentTariff).length < 3)) {
            this.props.getCurrentTariff()
        }
        if (!this.props.isGuest && (!this.props.exchange || !Object.keys(this.props.exchange).length)) {
            this.props.getExchangeMins(this.context.selectedRegion.id)
        }
        if (!this.props.isGuest && !this.props.client.services) {
            this.props.getMyServices()
        }

        if (!this.props.settings) {
            this.props.getSettings(this.context.selectedRegion.id);
        }

        if (this.state.readyTariffs.length === 0) this.readyTariffsSet();

        if (this.props.location.hash !== '' && this.props.location.hash === '#constructor') {
            const timeout = window.setTimeout(() => {
                window.history.replaceState(null, null, window.location.pathname);
                this.scrollTo();
                window.clearTimeout(timeout)
            }, 1000)
        }

        if (isEmptyObj(this.props.tariffConstructor.defaultData)) {
            this.props.getTariffConstructor(this.context.selectedRegion.id);
        }

        if (!!this.props.tariffConstructor.defaultData && this.state.optionNames.length === 0 && this.props.tariffConstructor.defaultData && this.props.tariffConstructor.defaultData.options && this.props.tariffConstructor.defaultData.options.length > 0) {
            this.setState({optionNames: this.props.tariffConstructor.defaultData.options})
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextContext.selectedRegion != this.context.selectedRegion) {
            this.props.getTariffSelectionSettings(nextContext.selectedRegion.id);
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.tariffConstructor.recalcData.constructor !== this.props.tariffConstructor.recalcData.constructor && !!this.props.tariffConstructor.recalcData.constructor.sms_count) {
            if (this.state.smsCount !== this.props.tariffConstructor.recalcData.constructor.sms_count) {
                this.setState({smsCount: +this.props.tariffConstructor.recalcData.constructor.sms_count});
            }
        }

        if (!prevProps.currentTariff && this.props.currentTariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'tariffs', this.props.currentTariff.id);
        }
        if (prevProps.isGuest && !this.props.isGuest) {
            if (!this.props.exchange || !Object.keys(this.props.exchange).length) {
                this.props.getExchangeMins(this.context.selectedRegion.id)
            }
            if (!this.props.client.services) {
                this.props.getMyServices()
            }
        }

        let word = this.props.activation.word;

        if (word !== null && this.state.testWorld) {
            if (word.badWord) {
                this.setState({modal: 'wrong-name', name: '', testWorld: false});
            } else {
                this.setState({modal: 'tariff-connection', testWorld: false});
            }
            this.props.clearWord();
        }

        if (this.props.forms && this.props.forms.isSubmitOk != prevProps.forms.isSubmitOk && this.props.forms.isSubmitOk) {
            this.openSuccess("success")
        }

        if (prevState.modal !== this.state.modal && this.state.modal === 'tariff-connection') {
            if (typeof document !== "undefined") {
                if (this.props.isGuest) {
                    const el = document.querySelector('.phone_focus');
                    if (el) el.focus();
                } else {
                    const el = document.querySelector('.email_focus');
                    if (el) el.focus();
                }
            }
        }

        if (this.state.readyTariffs.length === 0) {
            this.readyTariffsSet();
        }

        if ( !!this.props.tariffConstructor.recalcData && !!this.props.tariffConstructor.recalcData.options) {
            if (JSON.stringify(prevProps.tariffConstructor.recalcData.options) !== JSON.stringify(this.props.tariffConstructor.recalcData.options)) {
                const arr = this.props.tariffConstructor.recalcData.options;
                const copyOptionNames = [...this.state.optionNames];
                let optionNames = [];

                if (copyOptionNames.length < 7) {
                    for (let i = 0; i < arr.length; i++) {
                        const item = copyOptionNames.find(x => x.type === arr[i].type);

                        if (!item) {
                            optionNames.push(arr[i]);
                        }

                    }

                    this.setState({optionNames: [...copyOptionNames, ...optionNames]})
                }
            }
        }
        
        let userphone = this.props.user && this.props.user.username

        // обновляем запрос после входа в лк (для отображения скидки)
        if(userphone !== prevProps.user.username && !this.props.isGuest && !isEmptyObj(this.props.tariffConstructor.recalcData)) {

            let recalcData = this.props.tariffConstructor && this.props.tariffConstructor.recalcData.constructor;
            let min = this.state.constructorParams && this.state.constructorParams.min;
            let options = recalcData.options ? recalcData.options : [];

            let {
                sms,
                gb,
                social_unlim,
                data_unlim,
                motiv_voice_unlim,
                router,
                youtube_unlim,
                ivi
            } = this.state.constructorParams

            let data = +gb === 0 ? 1 : gb;

            sms === 1 ? options.push('SMS_PACK') : null;
            social_unlim === 1 ? options.push('SOCIAL_UNLIM') : null;
            data_unlim === 1 ? options.push('DATA_UNLIM') : null;
            motiv_voice_unlim === 1 ? options.push('MVOICE_UNLIM') : null;
            router === 1 ? options.push('ROUTER') : null;
            youtube_unlim === 1 ? options.push('YOUTUBE_UNLIM') : null;
            ivi === 1 ? options.push('IVI') : null;

            const tariff = {
                min,
                data,
                options
            }
            
            this.passingParams(tariff, this.context.selectedRegion.id);
        }

        if (prevState.modal && !this.state.modal) {
            this.props.clear();
        }
    }

    static contextTypes = {
        isGuest: PropTypes.bool,
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func
    };

    static childContextTypes = {
        setHasItemModal: PropTypes.func,
        constructorParams: PropTypes.object,
    };

    readyTariffsSet =()=> {
        let tariffs = [];
        let count = 0;
        if (this.props.tariffs && this.props.tariffs.tariffsLists && this.props.tariffs.tariffsLists.length > 0 && this.state.readyTariffs.length === 0) {
            const allTariffsLists = [...this.props.tariffs.tariffsLists];
            let tarifsListSvoi = [];

            allTariffsLists.forEach( el => {
                if (el.slug.indexOf('svoi') !== -1) {
                    tarifsListSvoi.push(el);
                }
            })

            let amountElem = tarifsListSvoi && tarifsListSvoi.length >= 4 ? 4 : tarifsListSvoi.length;
            tariffs = getNelementsArray(tarifsListSvoi, amountElem);
            this.setState({
                readyTariffs: tariffs
            })
        }
    }

    getChildContext(){
        return {
            constructorParams: this.state.constructorParams,
            setHasItemModal: (val) => {
                this.setState({hasItemModal: val})
            }
        }
    }

    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();
            this.context.pushHistory(this.tabUrls[val])
            this.setState(() => {
                return {
                    activeTab: val
                }
            })
        }
    };

    toggleModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState((prevState) => {
                return {
                    modal
                }
            })
        }
    }

    renderModal() {
        let constructor = this.state.constructorParams;
        let modifiedUrl = this.props.location && this.props.location.pathname;
        let urlStr = modifiedUrl.substr(1).split('-');
        let idTariff = this.state.idTariff;
        let tariff = this.props.tariffs.tariffsLists.filter(x => x.id === idTariff);
        let tariffParams = !!idTariff && tariff && tariff[0];
        let tariffNameModal = !!idTariff ? tariffParams.name : this.state.name ? this.state.name : "Свой тариф";
        let data_unlim_info = !!idTariff ? (tariffParams.svoi.data_unlim ? 1 : 0) : this.state.constructorParams.data_unlim;
        let sms = !!idTariff ? (tariffParams.svoi.sms ? 1 : 0) : constructor.sms;
        let smsCount = !!idTariff ? (tariffParams.sms_count ? parseInt(tariffParams.sms_count) : 0) : this.state.smsCount;
        let paramGb = !!idTariff ? (tariffParams.svoi.data === "безлимит" || tariffParams.svoi.data === "Безлимит" ? 0 : parseInt(tariffParams.svoi.data)) : constructor.gb;
        let router = !!idTariff ? tariffParams.svoi.router : constructor.router;
        let paramMin = !!idTariff ? parseInt(tariffParams.svoi.min) : constructor.min;
        let paramSocialUnlim = !!idTariff ? (tariffParams.svoi.social_network_unlim ? 1 : 0) : constructor.social_unlim;
        let paramMotivVoiceUnlim = !!idTariff ? (tariffParams.svoi.motiv_voice_unlim ? 1 : 0) : constructor.motiv_voice_unlim;
        let youtube_unlim = !!idTariff ? (tariffParams.svoi.youtube_unlim ? 1 : 0) : constructor.youtube_unlim;
        let ivi = !!idTariff ? (tariffParams.svoi.ivi ? 1 : 0) : constructor.ivi;

        const { optionNames } = this.state;
        const objOptionNames = {};

        if ( this.props.tariffConstructor.recalcData.options ) {
            this.props.tariffConstructor.recalcData.options.forEach(el => {objOptionNames[el.type] = el.name})
        } else if (this.props.tariffConstructor.defaultData.options) {
            this.props.tariffConstructor.defaultData.options.forEach(el => {objOptionNames[el.type] = el.name})
        }

        let {
            authFormError,
            formsSettings,
            successMessageTitle,
            successMessage,
            recoveryFormError,
            fatalErrorTitle,
            fatalError
        } = this.props.client

        if (typeof document !== 'undefined') {
            if (this.state.modal) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        }

        switch (this.state.modal) {
            case "filter":
                let filterSettings = this.props.filterSettings;

                return <Modal close={this.toggleModal(null)} innerClassName="u-modal-inner no-padding" closeBtnClassName="mnp-popup__close mnp-popup__close--white">
                    {filterSettings && filterSettings.isOk ? <RatesFilter {...filterSettings} onTrafficChange={this.onTrafficChange} closeModal={this.toggleModal(null)}/> : null}
                </Modal>;

            case "wrong-name":
                return <InfoModal close={this.closeModal}/>;
            case "tariff-connection":
                return <TariffConnectionModal tariffName={tariffNameModal}
                                            close={this.closeModal}
                                            openSuccess={this.openSuccess}
                                            settings={this.props.settings}
                                            data_unlim_info={data_unlim_info}
                                            urlStr={urlStr}
                                            typeConnectionModal={'svoi'}
                                            tariffParams={tariffParams}
                                            smsCount={smsCount}
                                              apiOptionNames={objOptionNames}
                />;
            case "success":
                return <SuccessInfoModal title="Ваша заявка принята!"
                                        text={this.props.settings.submitting_request_mnp_description}
                                        btnText="Отлично!"
                                        close={this.closeModal}
                                         apiOptionNames={objOptionNames}
                />;
            case "success-tariff-connection":
                return <SuccessInfoModal title={'Тариф "' + tariffNameModal + '" успешно подключен'}
                                        btnText="Ок!"
                                        close={this.closeModal}
                                        router={router}
                                        gb={paramGb}
                                        min={paramMin}
                                        social_unlim={paramSocialUnlim}
                                        sms={sms}
                                        smsCount={smsCount}
                                        motiv_voice_unlim={paramMotivVoiceUnlim}
                                        youtube_unlim={youtube_unlim}
                                        ivi={ivi}
                                        apiOptionNames={objOptionNames}
                                        settings={this.props.settings}
                                        isConstructor={!idTariff}
                />;

            case "AuthorizationModal":
                return <AuthorizationModal login={this.props.auth}
                                        onForgetLinkClick={
                                            (e) => {
                                                e.preventDefault();
                                                this.openModal("RecoveryModal")
                                            }
                                        }
                                        error={authFormError}
                                        close={this.closeModal}
                                        subtitle={formsSettings && formsSettings.auth_form.auth_form_description}
                                        authFormSettings={formsSettings ? formsSettings.auth_form : {}}
                                        qrText={this.props.qrText}
                />;
            case "RecoveryModal":
                return <RecoveryModal close={this.closeModal}
                                        recover={this.props.recover}
                                        error={this.props.recoveryFormError}
                                        modal={this.state.modal}
                                        recoveryFormSettings={formsSettings ? formsSettings.recovery_form : {}}
                />;

            case "ErrorModal":
                return <SuccessModal title={fatalErrorTitle}
                                    text={fatalError}
                                    icon={false}
                                    btnText="ОК"
                                    close={this.closeModal}
                />;
            case "SuccessModal":
                return <SuccessModal title={successMessageTitle}
                                    text={successMessage}
                                    icon={true}
                                    btnText="ОК"
                                    close={this.onForgotPasswordClickHandler}
                />;
            default:
                return null;
        }
    }

    onTariffNameChange = (e) => {
        this.setState({name: e.target.value.replace(/[^А-яа-я\-_]/gim, '').substr(0, 12)}, () => {
            this.setName(this.state.name);
        });
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.props.clearActivationData();
        this.setState({modal: ''});
        this.showScroll();

        if (this.state.idTariff !== '') {
            this.setState({
                idTariff: ''
            })
        }
    };

    hideScroll = () =>{
        if (typeof document != "undefined") {
            const el = document.querySelector('.page');
            if (el) el.classList.add('no-scroll');
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.page');
            if (el) el.classList.remove('no-scroll');
        }
    };

    openSuccess = (modal) => {
        if (!this.props.isGuest && modal === 'success-tariff-connection') {
            setCookie('has_connection', new Date().getTime() + 300000, {expires: 300, path: '/', domain: cookieDomain})
        }
        this.setState({modal: modal});
    };

    openTariffConnectionModal = (modal, idTariff) => {
        if (!!idTariff && this.state.idTariff === '') {
            this.setState({
                idTariff
            })
        }

        if (modal === 'tariff-connection') {

            if (this.state.name !== "") {
                this.props.checkWord(this.state.name);
                this.setState({testWorld: true});
            }else {
                this.setState({modal});
            }
        }else {
            this.setState({modal});
        }

        this.hideScroll();
    };

    onTrafficChange = (traffic) => {
        this.setState({traffic})
    };

    setName = (name) => {
        let constructorParams = {...this.state.constructorParams};
        constructorParams.name = name;
        this.setState({constructorParams})
    };

    onMainFilterChange = (filterData) => {
        this.setState({filterData: filterData});
    };

    filterByRegion (tariffs, region_id) {
        return tariffs.filter(x => x.region_id === region_id);
    }

    filterByMainFilter = (tariffs, excludedFilters = [], includedFilters = ['byTraffic', 'byCalls', 'bySms']) => {
        let data = this.state.filterData;
        let result = tariffs;
        let selectionSettings = this.props.selectionSettings;

        if (!selectionSettings.hasOwnProperty('filters')) {
            return result;
        }

        if (excludedFilters.indexOf('byTraffic') === -1 && includedFilters.indexOf('byTraffic') !== -1) {
            result = result.filter(this.filterByTraffic);
        }

        if (!data.onlyInternet) {
            if (excludedFilters.indexOf('byCalls') === -1 && includedFilters.indexOf('byCalls') !== -1) {
                result = result.filter(this.filterByCalls);
            }
            if (excludedFilters.indexOf('bySms') === -1 && includedFilters.indexOf('bySms') !== -1) {
                result = result.filter(this.filterBySms);
            }
            if (this.needToFilter('cheap_intercity') && data.cheap_intercity) {
                result = result.filter(x => x.cheap_intercity === true);
            }
        }
        if (this.needToFilter('internet_social_unlimit') && data.internet_social_unlimit) {
            result = result.filter(x => x.internet_social_unlimit === true);
        }

        if (this.needToFilter('internet_messenger_unlimit') && data.internet_messenger_unlimit) {
            result = result.filter(x => x.internet_messenger_unlimit === true);
        }

        if (this.needToFilter('internet_music_unlimit') && data.internet_music_unlimit) {
            result = result.filter(x => x.internet_music_unlimit === true);
        }
        return result;
    };

    needToFilter = (checkBoxName) => {
        let availableFilters = this.props.selectionSettings.filters;
        return availableFilters && availableFilters.indexOf(checkBoxName) !== -1;
    };

    //filters For Main Form Filter
    filterByTraffic = (item) => {
        let sliderTraffic = this.state.filterData.internet_traffic;
        let maxTraffic = this.state.filterData.max_traffic;
        let gb = item.internet_gb_count;
        let variance = this.state.filterData.varianceTraffic*maxTraffic/100;

        if (sliderTraffic === 0) {
            if (gb === null) {
                return true;
            }
        }else {
            if (gb === null) {
                return false;
            }
        }

        if ((maxTraffic === sliderTraffic || maxTraffic < sliderTraffic) && gb === "Безлимит") {
            return true;
        }

        if (gb >= sliderTraffic && gb <= sliderTraffic + variance) {
            return true;
        }

        return false;
    };

    filterByCalls = (item) => {
        let mCheckbox = this.state.filterData.minutes;
        let separator = this.props.selectionSettings.minutes_separator;
        let minutes = item.minutes_count;
        let variance = this.state.filterData.varianceMinutes*separator/100;

        if (mCheckbox === 'often') {
            if (minutes === "Безлимит" || minutes >= separator) return true;
        }
        if (mCheckbox === "rare") {
            if (minutes === null || minutes < separator + variance) return true;
        }
        return false;
    };

    filterBySms = (item) => {
        let mCheckbox = this.state.filterData.sms;
        let separator = this.props.selectionSettings.sms_separator;
        let sms = item.sms_count;

        let variance = this.state.filterData.varianceSms*separator/100;

        if (mCheckbox === 'many') {
            if (sms === "Безлимит" || sms >= separator) return true;
        }
        if (mCheckbox === 'few') {
            if (sms === null || sms < separator + variance) return true;
        }
        return false;
    };

    sortByPriority = (tariffs, fields) => {

        let data = this.state.filterData;
        let unlim = 'Безлимит';
        let maxInt = Number.MAX_SAFE_INTEGER;

        tariffs.sort(function (t1, t2) {

            let result = 0;

            for (let i = 0, len = fields.length; i < len; i++) {

                let item = fields[i];

                if (t2[item.field] === t1[item.field]) {
                    continue;
                }
                if (data.onlyInternet && (item.field === 'minutes_count' || item.field === 'sms_count' || item.field === 'cheap_intercity')) {
                    continue;
                }

                if (item.unlimited) {
                    if (item.field === 'internet_gb_count') {
                        if (t2[item.field] === unlim) {
                            result = t1[item.field] - maxInt;
                            break;
                        }
                        if (t1[item.field] === unlim) {
                            result = maxInt - t2[item.field];
                            break;
                        }
                        result = t1[item.field] - t2[item.field];
                        break;
                    } else {
                        if (t2[item.field] === unlim) {
                            result = maxInt - t1[item.field];
                            break;
                        }
                        if (t1[item.field] === unlim) {
                            result = t2[item.field] - maxInt;
                            break;
                        }
                    }
                }
                result = t2[item.field] - t1[item.field];
                break;
            }
            return result;
        });

        return tariffs;
    };

    excludeSame (fromArr, arr) {
        let result = [];

        fromArr.filter((item) => {
            if (arr.filter((elem) => {
                return item.id === elem.id
            }).length === 0) {
                result.push(item);
            }
        });

        return result;
    };

    sortSuitable = (tariffs, fields, count) => {

        let data = this.state.filterData;
        let unlim = 'Безлимит';
        let maxInt = this.state.filterData;
        let selectionSettings = this.props.selectionSettings;

        tariffs.sort(function (t1, t2) {

            let result = 0;

            for (let i = 0, len = fields.length; i < len; i++) {

                let item = fields[i];

                if (t2[item.field] === t1[item.field]) {
                    continue;
                }
                if (data.onlyInternet && (item.field === 'minutes_count' || item.field === 'sms_count' || item.field === 'cheap_intercity')) {
                    continue;
                }

                if (item.unlimited) {
                    if (item.field === 'internet_gb_count') {

                        if (t2[item.field] === unlim) {
                            result = maxInt - t1[item.field];
                            break;
                        }
                        if (t1[item.field] === unlim) {
                            result = t2[item.field] - maxInt;
                            break;
                        }
                        result = t2[item.field] - t1[item.field];
                        break;
                    } else {
                        if (item.field === 'minutes_count') {
                            result = Math.abs(selectionSettings.minutes_separator - t2[item.field]) - Math.abs(selectionSettings.minutes_separator - t1[item.field]);
                        }
                        if (item.field === 'sms_count') {
                            result = Math.abs(selectionSettings.sms_separator - t2[item.field]) - Math.abs(selectionSettings.sms_separator - t1[item.field]);
                        }
                    }
                }
                result = t2[item.field] - t1[item.field];
                break;
            }
            return result;
        });

        return tariffs.slice(0, count);
    };

    sortSelectionPropsByPriority (props) {
        props = props.sort(function (f1, f2) {
            return f2.priority - f1.priority;
        });

        for (let i = 0, len = props.length; i < len; i++) {
            if (i+1 !== len) {
                if (props[i].priority === props[i+1].priority) {
                    props[i].sortByPrice = true;
                }
            }
        }

        return props;
    }

    sortByField = (tariffs, field) => {

        let maxInt = Number.MAX_SAFE_INTEGER;

        return tariffs.sort(function (t1, t2) {
            if (t2[field] === 'Безлимит') return maxInt - t1[field];
            if (t1[field] === 'Безлимит') return t2[field] - maxInt;

            return t2[field] - t1[field];
        });
    };

    sortBySubscribtion = (tariffs) => {
        return tariffs.sort(function (t1, t2) {
            return t1.subscription_fee - t2.subscription_fee;
        });
    };

    getPrice = (min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router = 0, youtube_unlim = 0, ivi = 0) => {
        this.setState({constructorParams: {
            min,
            gb,
            sms,
            social_unlim,
            data_unlim,
            motiv_voice_unlim,
            router,
            youtube_unlim,
            ivi
        }});

        let options = [];
        let data = +gb === 0 ? 1 : gb;

        sms === 1 ? options.push('SMS_PACK') : null;
        social_unlim === 1 ? options.push('SOCIAL_UNLIM') : null;
        data_unlim === 1 ? options.push('DATA_UNLIM') : null;
        motiv_voice_unlim === 1 ? options.push('MVOICE_UNLIM') : null;
        router === 1 ? options.push('ROUTER') : null;
        youtube_unlim === 1 ? options.push('YOUTUBE_UNLIM') : null;
        ivi === 1 ? options.push('IVI') : null;

        const tariff = {
            min,
            data,
            options
        }

        // this.props.postTariffConstructor(tariff, this.context.selectedRegion.id);
        this.passingParams(tariff, this.context.selectedRegion.id);
    };

    async passingParams(tariff, regionId) {
        await this.props.setTariffParams(tariff, regionId);
        await this.props.postTariffConstructor(tariff, regionId);
    }

    scrollTo = () => {
        this.smoothScroll('.b-internet-ipad__tarifs-list');
    }

    currentYPosition=()=> {
        if (typeof document !== "undefined") {
            if (self.pageYOffset) return self.pageYOffset;
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        }
    }

    elmYPosition=(eID)=> {
        if (typeof document !== "undefined") {
            let elm = document.querySelector(eID);
            let y = elm.offsetTop;
            let node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        }
    }

    smoothScroll=(eID)=> {
        let startY = this.currentYPosition();
        let stopY = this.elmYPosition(eID);
        let distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
            scrollTo(0, stopY); return;
        }
        let speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        let step = Math.round(distance / 25);
        let leapY = stopY > startY ? startY + step : startY - step;
        let timer = 0;
        if (stopY > startY) {
            for ( let i=startY; i<stopY; i+=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY += step; if (leapY > stopY) leapY = stopY; timer++;
            } return;
        }
        for ( let i=startY; i>stopY; i-=step ) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }
    }

    // 
    onForgotPasswordClickHandler = () => {
        this.setState(() => {
            return {
                modal: null
            }
        }, () => {
            this.openModal('AuthorizationModal');
        });
    };

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        }, () => {
            this.props.clearData ? this.props.clearData() : "";
            this.props.clearSupportData ? this.props.clearSupportData() : "";
        });
    };

    login = () => {
        this.openModal("AuthorizationModal");
    };

    render() {
        let { settings, price, sliderParams, currentTariff, tariffConstructor, regions, location } = this.props;
        const axis = this.props.tariffConstructor && this.props.tariffConstructor.axis;
        let {activeTab, filterData, constructorParams, readyTariffs, name, smsCount} = this.state;
        let selectedRegion = this.context.selectedRegion;
        let tariffs = this.filterByRegion(this.props.tariffs.tariffsLists, selectedRegion.id);
        let selectionSettings = this.props.selectionSettings;
        let filterFunc = this.filterByMainFilter;
        let excludeSame = this.excludeSame;
        let sortBySub = this.sortBySubscribtion;

        let filteredTariffs = filterFunc(tariffs);
        let priorityFieldsMain = this.priorityFieldsMain;
        let placeholder = 'Свой_тариф';
        let defaultParams = true;

        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            if (!defaultParams) defaultParams = true;
        } else {
            if (!!defaultParams) defaultParams = false;
        }

        if (defaultParams && (!!tariffConstructor.defaultData.constructor && !!tariffConstructor.defaultData.constructor.alias)) {
            placeholder = tariffConstructor.defaultData.constructor.alias;
        }
        if (!defaultParams && tariffConstructor.recalcData.constructor && tariffConstructor.recalcData.constructor.alias) {
            placeholder = tariffConstructor.recalcData.constructor.alias;
        }

        let canRecommend = false;

        if (filteredTariffs.length > 0) {
            canRecommend = true;
        }

        if (filteredTariffs.length > 4) {
            //добавить сортировку по стоимости
            filteredTariffs = sortBySub(filteredTariffs.slice(0, 4));
        } else {
            let tatiffsWithoutFiltered = excludeSame(tariffs, filteredTariffs);
            let additionalTariffs = [];

            //ни одного чекбокса
            if ((!this.needToFilter('cheap_intercity') || !filterData.cheap_intercity) &&
                (!this.needToFilter('internet_social_unlimit') || !filterData.internet_social_unlimit) &&
                (!this.needToFilter('internet_messenger_unlimit') || !filterData.internet_messenger_unlimit) &&
                (!this.needToFilter('internet_music_unlimit') || !filterData.internet_music_unlimit)
            ) {

                priorityFieldsMain.forEach(function(item, idx) {
                    if (filteredTariffs.length < 4 && idx < 2) {
                        if (idx === 0) {
                            additionalTariffs = filterFunc(tatiffsWithoutFiltered, [priorityFieldsMain[2].filter]);
                        }
                        if (idx === 1) {
                            additionalTariffs = filterFunc(tatiffsWithoutFiltered, [priorityFieldsMain[2].filter, priorityFieldsMain[1].filter]);
                        }
                        if (additionalTariffs.length > 0) {
                            filteredTariffs = filteredTariffs.concat(sortBySub(additionalTariffs));
                            tatiffsWithoutFiltered = excludeSame(tariffs, filteredTariffs);
                        }
                    }
                });
            }

            if (filteredTariffs.length < 4) {
                additionalTariffs = filterFunc(tatiffsWithoutFiltered, ['byTraffic', 'byCalls', 'bySms'], []);
                additionalTariffs = this.sortByField(additionalTariffs, priorityFieldsMain[0].field);
                filteredTariffs = filteredTariffs.concat(sortBySub(additionalTariffs)).slice(0, 4);
            }
        }

        let infoBlocks = this.props.infoBlocks.list;

        if (this.props.currentTariff && !this.state.hasItemModal) {
            tariffs = tariffs.filter((item) => item.id !== this.props.currentTariff.id)
        }

        let modifiedUrl = this.props.location && this.props.location.pathname;
        let urlStr = modifiedUrl.substr(1).split('-');
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");
        

        //получаем нужный нам SEO {}
        let routeSeo = this.state.activeTab === 0 ? this.props.routes.list.find(x => x.sys_name === 'tariffsRoute') 
                  : this.state.activeTab === 1 ? this.props.routes.list.find(x => x.sys_name === 'tariffsDlyaUmnyhVeshcheyRoute')
                  : this.state.activeTab === 2 ? this.props.routes.list.find(x => x.sys_name === 'tariffsVseRoute')
                  : null;
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder page__main-holder__tariffs" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_services-inner"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container">
                    <h1 className="page__title">Тарифы</h1>
                    {this.props.isGuest ? null : <CurrentTariffBlock {...this.props.currentTariff} regionSlug={this.context.selectedRegion.alias === this.props.clientRegionSlug ? null : this.props.clientRegionSlug} remains={this.props.remains} onClick={this.onToggleTab(1)} scrollTo={this.scrollTo}/>}
                    {this.props.isGuest ? null : <InfoBlock type="advert_service_block"
                                                            section="advert_service_block"
                                                            data={infoBlocks}
                                                            additionalProps={{
                                                                exchangeId: this.props.lkSettings && this.props.lkSettings.exchange_service_id,
                                                                showModal: this.toggleModal,
                                                                canCollapse: true,
                                                                canExchange: this.props.exchange && this.props.exchange.is_available && this.props.exchange.max_minutes && this.props.exchange.allowForTariff,
                                                                myServices: this.props.client.services ? this.props.client.services.map((item) => item.id_billing) : []
                                                            }}
                    />}
                    <div className="b-internet-ipad__tarifs-list">
                        <div className="b-internet-ipad__tabs-header">
                            <TabsSwitcher
                                items={[{ title: "Настроить тариф" }, { title: "Тарифы для умных вещей" }, (activeTab !== 2 ? { title: "Все тарифы по категориям"} : { title: "Все тарифы по категориям*"})]}
                                onClickFunc={this.onToggleTab}
                                activeTab={activeTab}
                            />
                        </div>
                    </div>

                    {this.props.isGuest && activeTab === 0 && (
                        <div className="authorization_link" onClick={this.login}>
                                Для получения индивидуального предложения,&nbsp;пожалуйста,
                                <span className="authorization_link__link">
                                    авторизуйтесь
                                    <span className="authorization_link__img">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12C24 18.6276 18.627 24 12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.627 0 24 5.3724 24 12" fill="#FC692F"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7579 20.8L10.3924 19.7474C10.3924 19.7474 10.4171 12.281 10.3924 10.9531C9.34882 10.4177 8.79996 9.31883 8.79996 8.0502C8.79996 6.25519 10.2326 4.80005 12 4.80005C13.7673 4.80005 15.2 6.25519 15.2 8.0502C15.2 9.28307 14.5997 10.3556 13.6038 10.9065C13.603 11.124 13.6022 12.259 13.6022 12.259L12 13.9591L13.6021 15.4707L13.6023 16.3406L12 17.6059L13.6033 18.7194L13.6038 19.5434L11.7579 20.8ZM12.8 6.39996H11.2V7.99996H12.8V6.39996Z" fill="white"/>
                                        </svg>
                                    </span>
                                </span>
                        </div>
                    )}

                    <ul className="tabs__content" ref="content">
                        <li className={"tabs-content__item" + ((activeTab === 0) ? " tabs-content__item--active" : "")}>
                            {!!axis ?
                                <TariffConstructor
                                    selectedRegion={selectedRegion}
                                    setName={this.setName}
                                    price={price}
                                    settings={settings}
                                    axis={axis}
                                    sliderParams={sliderParams}
                                    regions={this.props.regions}
                                    location={this.props.location}
                                    modifiedUrl={modifiedUrl}
                                    urlStr={urlStr}
                                    getPrice={this.getPrice}
                                    getSettings={this.props.getSettings}
                                    constructorParams={constructorParams}
                                    onTariffNameChange={this.onTariffNameChange}
                                    onClickConnect={this.openTariffConnectionModal}
                                    name={name}
                                    currentTariff={currentTariff}
                                    placeholder={placeholder}
                                    defaultParams={defaultParams}
                                    smsCount={smsCount}
                                /> :
                            null}

                            {readyTariffs.length > 0
                                ?  <React.Fragment>
                                        <div className="page__title-3 page__title-3_mobile-less">Выбрать готовый тариф</div>
                                        <RatesList
                                            rates={readyTariffs}
                                            byCat={false}
                                            linkToShop={true}
                                            selectedRegion={selectedRegion}
                                            onClickConnect={this.openTariffConnectionModal}
                                        />
                                    </React.Fragment>
                                : null
                            }
                        </li>
                        <li className={"tabs-content__item" + ((activeTab === 1) ? " tabs-content__item--active" : "")}>
                            <RatesByCategories showCategoryTitle={false} tariffs={tariffs} rankField={'rank_smart'} onClickConnect={this.openTariffConnectionModal} />
                        </li>
                        <li className={"tabs-content__item" + ((activeTab === 2) ? " tabs-content__item--active" : "")}>
                            <RatesByCategories tariffs={tariffs} rankField={'rank_main'} onClickConnect={this.openTariffConnectionModal} />
                        </li>
                    </ul>
                </div>
                <div className="page__container">
                    <div className="tabs-button__block">
                        <RatesLinks title={"Архив тарифов"} url={"/" + selectedRegion.alias + "/tariffs/archive"} />
                        {activeTab !== 2 ? <div className="b-tariffs-list__archive-link b-tariffs-list__category-list" onClick={this.onToggleTab(2)}>Все тарифы по категориям</div> : null}
                    </div>

                    {activeTab === 2 && (
                        <div className="tabs-button__block-footnote">
                            <p>*Все тарифы - тарифы, доступные для перехода и подключения.</p>
                            <p>Если Вы не нашли информацию о каком-либо тарифе, то для ее получения можете обратиться в&nbsp; 
                                <LinkC to={"/for-subscribers"} className="tabs-button__block-footnote-link">контакт-центр</LinkC>
                                &nbsp;или&nbsp;
                                <LinkC to={"/motiv-on-map"} className="tabs-button__block-footnote-link">офис обслуживания</LinkC>.
                            </p>
                        </div>
                    )}

                    <InfoBlock type={'banner'} data={infoBlocks} section={'main_page_banners'} />
                </div>
                <div className="page__container">
                    <div className="rates-slider">
                        <InfoBlock type={'before-slider'} data={infoBlocks} section={'infoblock_groups_promos'}/>
                        <InfoBlock type={'slider'} data={infoBlocks} section={'main_page_slider'}/>
                    </div>
                </div>
                {this.renderModal()}
                {this.props.isGuest ? null : <LKModals modal={this.state.modal} close={this.toggleModal(null)} />}
            </main>
        )
    }
}

export default connect(
    state => ({
        tariffs: state.tariffs,
        filterSettings: state.tariffs.filterSettings,
        selectionSettings: state.tariffs.selectionSettings,
        
        client: state.client,
        isGuest: state.client.isGuest,
        clientRegionSlug: state.client.clientInfo.region && state.client.clientInfo.region.alias || "",
        currentTariff: state.client.clientInfo.tariff,
        remains: state.client.clientInfo.remains,
        exchange: state.client.clientInfo.exchange,
        formsSettings: state.client.formsSettings,
        lkSettings: state.client.settings,
        user: state.client.clientInfo,
        
        infoBlocks: state.infoBlocks,
        regions: state.regions,
        // axis: state.constructor.axis,
        sliderParams: state.constructor.params,
        settings: state.constructor.settings,
        price: state.constructor.price,
        activation: state.activation,
        forms: state.forms,
        tariffConstructor: state.tariffConstructor,

        routes: state.routes,
        qrText: state.settings.qr_code_description
    }),
    (dispatch) => {
        return {
            getTariffs : (regionId) => dispatch (tariffsList(regionId)),
            getTariffFilterSettings : () => dispatch(tariffFilterSettings()),
            getTariffSelectionSettings: (regionId) => dispatch(tariffSelectionSettings(regionId)),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getInfoBlocksWithTariffs : (regionId, group, tariff) => dispatch(infoBlocksList(regionId, group, tariff)),
            getCurrentTariff: () => dispatch(tariffInfo()),
            getExchangeMins: (regionId) => dispatch(exchangeMins(regionId)),
            exchangeMinsSend: (data) => dispatch(exchangeMinsSend(data)),
            getMyServices: () => dispatch(services()),
            // getAxis: (regionId) => dispatch(axisValues(regionId)),
            getSettings: (regionId) => dispatch(settings(regionId)),
            getPrice: (regionId, min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router) => dispatch(price(regionId, min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi)),
            checkWord: (word) => dispatch(checkWord(word)),
            clearWord: () => dispatch(clearWord()),
            clearActivationData: () => dispatch(clearActivationData()),
            getTariffConstructor: (regionId) => dispatch(getTariffConstructor(regionId)),
            postTariffConstructor:(tariff, regionId) => dispatch(postTariffConstructor(tariff, regionId)),
            setTariffParams:(tariff, regionId) => dispatch(setTariffParams(tariff, regionId)),

            auth: (data, rememberMe) => dispatch(auth(data, rememberMe)),
            callLogin: () => dispatch(callLogin()),
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),
        }
    }
)(RatesMain);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */

export const RatesMainRequests = [
    {
        func: 'getTariffs',
        args: [],
        object: 'tariffs.tariffsLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['tariffs'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
    // {
    //     func: 'getTariffFilterSettings',
    //     args: [],
    //     object: 'tariffs.filterSettings',
    //     checkField: null,
    //     main: false,
    //     withRegion: false,
    //     objectReceived: false
    // },
    {
        func: 'getTariffSelectionSettings',
        args: [],
        object: 'tariffs.selectionSettings',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    }
];

export const RatesMainLkRequests = [
    {
        func: 'getCurrentTariff',
        args: [],
        object: 'client.clientInfo.tariff',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    },
];
