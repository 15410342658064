import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {otherPaymentMethods, paymentTransfersPageAction} from "../../actions/paymentsTransfers"
import {infoBlocksList} from "../../actions/infoBlocks";
import TopUpBalanceForm from "../../components/Forms/TopUpBalanceForm";
import ListOfServices from "../../components/PaymentsTransfers/ListOfServices";
import OtherPaymentMethods from "../../components/PaymentsTransfers/OtherPaymentMethods";
import SmallMenu from "../../../js/components/PaymentsTransfers/SmallMenu";
import LinkC from "../../../js/components/common/LinkC";
import InfoBlock from "../../components/common/InfoBlock";
import InfoModalQR from '../../components/modals/InfoModalQR/InfoModalQR';

class PaymentsTransfers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: '',
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
    };

    componentDidMount(){
        let regionId = this.context && this.context.selectedRegion.id;
        
        if (!this.props.paymentsTransfers.length){
            this.props.otherPaymentMethods();
        }
        // вызов title
        if (!this.props.paymentTransfers.length){
            this.props.paymentTransfersPageAction();
        }
    }

    componentWillMount(){
        let regionId = this.context && this.context.selectedRegion.id;

        this.props.getInfoBlocks(regionId);
        // вызов title
        if (!this.props.paymentTransfers.length){
            this.props.paymentTransfersPageAction();
        }
    }

    // отключаем скролл у модалки
    hideScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.pp-mod_internet');
            document.body.classList.add("modal-toggle-scroll-active");
            if (el) el.classList.add('no-scroll');
        }
    };

    // включаем скролл у модалки
    showScroll = () => {
        if (typeof document != "undefined") {
            const el = document.querySelector('.pp-mod_internet');
            document.body.classList.remove("modal-toggle-scroll-active");
            if (el) el.classList.remove('no-scroll');
        }
    };

    openModal = (modal) => {
        this.setState(() => {
            return {
                modal: modal
            }
        });
        this.hideScroll();
    };

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({ modal: '' });
        this.showScroll();
    };

    renderModal() {
        const {
            paymentsTransfers,
        } = this.props;
        
        const imageBase64 = paymentsTransfers && paymentsTransfers.dataSpb && paymentsTransfers.dataSpb.form_url;

        switch (this.state.modal) {
            case "qrcode_modal":
                return <InfoModalQR
                    imageBase64={imageBase64}
                    close={this.closeModal}
                />;

            default:
                return null;
        }
    }

    render() {
        let {
            infoBlocks,
            paymentsTransfers,
            paymentTransfers,
            routes
        }  = this.props

        let mainInfoBlock = infoBlocks && infoBlocks.find(i => i.type.alias === 'home_page_payments')
        let otherPaymentMethods = paymentsTransfers && paymentsTransfers.otherPaymentMethods;

        //получаем нужный нам SEO {}
        let routeSeo = routes && routes.list.find(x => x.sys_name === 'paymentsTransfersRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <div className="page__container info-page-container payments-page-container">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_internet"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                </Helmet>

                <div className="payments-page-container__content">
                    <div className="payments-page-container__wrapper">
                        {paymentTransfers && paymentTransfers.map((item) => 
                            <React.Fragment key={item.id}>
                                <h1 className="payments-page__title">{item.title}</h1>                                
                            </React.Fragment>
                        )}

                        <div className="payments-page__top-up-balance">
                            <TopUpBalanceForm openModal={this.openModal}/>
                        </div>

                        <div className="payments-page__banner">
                            <InfoBlock type={'payments-page_banner'} data={ infoBlocks } section={'payments_page_deferred_payment_banner'} />
                        </div>

                        {infoBlocks && mainInfoBlock && (
                            <div className="payments-page__list-of-services">
                                <ListOfServices infoBlocks={infoBlocks} />
                            </div>
                        )}

                        <div className="payments-page__more">
                            <LinkC to={"/payments-methods"} className="more_link">Узнайте</LinkC>&nbsp;
                            о других возможностях пополнения баланса
                        </div>

                        {/* <div className="payments-page__payment-methods">
                            <OtherPaymentMethods otherPaymentMethods={otherPaymentMethods} />
                        </div> */}
                    </div>

                    <div className="info-page-container__content-right-part small-menu">
                        <div className="info-page-container__wrap-aside-menu motiv-vacancies">
                            <SmallMenu 
                                // при назначении держит открытое меню
                                flag={'payments-transfers'}
                                // при назначении ищет и подчеркивает ссылку
                                flagSubMenu={'payments-transfers'}
                                // title в мобильном меню
                                customTitleMenu={'Платежи и переводы'}
                            />
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        paymentsTransfers: state.paymentsTransfers,
        paymentTransfers: state.paymentsTransfers.paymentTransfers,
        // решает проблему с другими инфоблоками при переходе на страницу
        infoBlocks: state.infoBlocks.list.filter((item) => item.groups.map((group) => group.alias).indexOf('services_payment') !== -1),
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            otherPaymentMethods: () => dispatch(otherPaymentMethods()),
            getInfoBlocks : (regionId) => dispatch(infoBlocksList(regionId, 'services_payment')),
            paymentTransfersPageAction: () => dispatch(paymentTransfersPageAction()),
        }
    }
)(PaymentsTransfers);