import React, { Component } from 'react';

export default class InputError extends Component {

    render() {
        let {
            hasError,
            errorText,
            className
        } = this.props;

        if (!hasError || !errorText) return null;

        return (
            <div className={"b-input-text__input-error" + (className ? " " + className : "")}>{errorText}</div>
        )
    }
}
