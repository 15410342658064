import React, {Component} from "react";
import PropTypes from "prop-types";

import Icon from "../common/Icon";

export default class ContinueWay extends Component{

    static contextTypes = {
        step: PropTypes.number,
        goToStep: PropTypes.func,
        showModal: PropTypes.func
    }

    render(){

        var {
            continue_by_phone_description,
            continue_by_phone_title,
            continue_online_description,
            continue_online_hidden,
            continue_online_title,
            children
        } = this.props;

        return <div className="page__b-choose-plates">
            <div className="b-choose-plates b-choose-plates_adapt-default">
                <div className="b-choose-plates__b-choose-plates">
                    <div className="b-choose-plates__row">
                        {continue_online_hidden ? null : <div className="b-choose-plates__col">
                            <div className="b-choose-plates__item">
                                <Icon className="b-choose-plates__icon b-choose-plates__icon_hand u-icon u-icon_doc" name="icon_doc"/>
                                <div className="b-choose-plates__title">{continue_online_title}</div>
                                <div className="b-choose-plates__text">{continue_online_description}</div>
                                <button id={"mnp-pickup-type-info-by-client-button"} className="b-choose-plates__btn" onClick={() => {this.context.goToStep(this.context.step + 1)}}>Выбрать</button>
                            </div>
                        </div>}
                        <div className="b-choose-plates__col">
                            <div className="b-choose-plates__item">
                                <Icon className="b-choose-plates__icon b-choose-plates__icon_planet u-icon u-icon_support2" name="icon_support2"/>
                                <div className="b-choose-plates__title">{continue_by_phone_title}</div>
                                <div className="b-choose-plates__text">{continue_by_phone_description}</div>
                                <button id={"mnp-pickup-type-info-by-call-button"} className="b-choose-plates__btn" onClick={this.context.showModal("call")}>Выбрать</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    }
}
