import {
    call,
    put, take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors,
    fetchEsiaApi
} from '../tools/api-helper';
import {getCookie} from "../tools/tools";
import {checkToken} from "./client";

export function formsList(group) {
    return {
        type: 'FORMS_LIST',
        group
    }
}

export function* fetchFormsList(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/forms/"+action.group, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function vacancyRespond(formData) {
    return {
        type: 'VACANCY_RESPOND',
        formData,
    }
}


export function clearVacancyRespondFormData() {
    return {
        type: 'CLEAR_VACANCY_RESPOND_FORM_DATA'
    }
}

export function* fetchVacancyRespond(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/vacancy-respond", {
                method: 'POST',
                body: action.formData
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, {...data, alias: action.alias}, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function complainForm (formData) {
    return {
        type: 'COMPLAIN_FORM',
        formData,
    }
}

export function clearComplainFormData() {
    return {
        type: 'CLEAR_COMPLAIN_FORM_DATA'
    }
}

export function* fetchComplainForm(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/complain", {
                method: 'POST',
                body: action.formData
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, {...data, alias: action.alias}, status));
        }
    } catch(e) {
        console.log(e);
    }
}

// mnp ESIA
export function cleareMnpFormEsiaAction() {
    return {
        type: "CLEARE_MNP_FORM_ESIA"
    }
}

export function mnpFormEsiaAction(data) {
    return {
        type: "MNP_FORM_ESIA",
        data
    }
}

export function* fetchMnpFormEsiaSaga(action) {
    try {

        let body = 'token=' + encodeURIComponent(action.data);

        const { status, data, error } = yield call( () => {
            return fetchEsiaApi("blitz/bridge/user", {
                method: 'POST',
                body,
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

// C-10
export function submitMnpForm(formData, alias, target = "MNP") {
    return {
        type: "MNP_FORM_SUBMIT",
        formData,
        alias,
        target
    }
}

export function* fetchSubmitMnpForm(action) {
    try {
        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/mnp?target=" + action.target, {
                method: 'POST',
                body: action.formData,
                token: getCookie("access_token")
            })
        });

        if(status === 409) {
            yield put({type: action.type + '_IS_REPEAT', data: {...data, alias: 'order_sent_failed'}});
            return;
        }
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, {...data, alias: action.alias}, status));
        }

    } catch(e) {
        console.log(e);
    }
}

/**
 *
 * @param type - address, metro, city, street
 * @param query
 * @param additionalParams - city for address and metro, region for city
 * @returns {{type: string, suggestionType: *, query: *}}
 */
export function addressAutoComplete(type, query, additionalParams = {}) {
    return {
        type: "ADDRESS_AUTO_COMPLETE",
        suggestionType: type,
        query,
        ...additionalParams
    }
}

export function clearAddress() {
    return {
        type: 'CLEAR_AUTO_COMPLETE_ADDRESS'
    }
}

export function clearCity() {
    return {
        type: 'CLEAR_AUTO_COMPLETE_CITY'
    }
}

export function clearStreet() {
    return {
        type: 'CLEAR_AUTO_COMPLETE_STREET'
    }
}

export function clearHouse() {
    return {
        type: 'CLEAR_AUTO_COMPLETE_HOUSE'
    }
}

export function* fetchAddressAutocomplete(action) {
    try {
        let additionalParam = "";

        if (action.city) additionalParam += "&city="+action.city;
        if (action.region) additionalParam += "&region="+action.region;
        if (action.count) additionalParam += "&count="+action.count;
        if (action.regionId) additionalParam += "&regionId="+action.regionId;
        if (action.allRegions) additionalParam += "&allRegions="+action.allRegions;

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/"+action.suggestionType+"?query="+action.query+additionalParam, {
                method: 'GET'
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, status === 200 ? {list: data, suggestionType: action.suggestionType} : data, status));
        }

    } catch(e) {
        console.log(e);
    }
}