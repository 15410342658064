import React, {Component} from "react";

import {phoneMask, onlyLetters} from "../../tools/mask";
import {emailValidator, emptyValidator, getFormData, phoneValidator, usernameValidator} from "../../tools/form-helper";
import Input from "../../components/Constructor/Input";
import Select from "../Vacancies/Select";
import Icon from "../common/Icon";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {vacancyRespond} from "../../actions/forms";
import {cvForm} from "../../actions/cvForm";
import CheckBox from "../common/CheckBox";

class VacancyForm extends Component{

    constructor(props, context){
        super(props, context);

        this.state = {
            regions: [],
            cities: [],
            selectRegionId: null,
            selectCityId:  null,
            specialization: null,
            vacancyByList: null,
            formValid: null,
            file: null,
            fieldLocked: false,
            agreementChecked: false,
            fields: {
                form_name: {
                    value: "Отправить резюме"
                },
                fio: {
                    typeInput: "text",
                    label: "фио",
                    name: "fio",
                    value: (!props.isGuest && props.clientInfo.name) ? props.clientInfo.name : "",
                    required: true,
                    mask: onlyLetters,
                    validators: [usernameValidator],
                    hasError: false,
                    errorText: "Укажите ФИО",
                    dot_orange: true
                },
                phone: {
                    typeInput: "text",
                    label: "телефон",
                    name: "phone",
                    value: (!props.isGuest && props.clientInfo.username) ? phoneMask(props.clientInfo.username) : "",
                    required: true,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: false,
                    errorText: "Укажите корректный номер телефона",
                    dot_orange: true
                },
                email: {
                    typeInput: "text",
                    label: "e–mail",
                    value: "",
                    name: "email",
                    required: true,
                    validators: [emailValidator],
                    hasError: false,
                    errorText: "Укажите корректный email",
                    dot_orange: true
                },
                city: {
                    typeInput: "select",
                    label: "Город",
                    value: "",
                    options: [],
                    name: "city",
                    hasError: false,
                    errorText: "Выберите город",
                    validators: false,
                },
                specialization: {
                    typeInput: "select",
                    label: "Специализация",
                    value: "",
                    options: [],
                    name: "specialization",
                    hasError: false,
                    errorText: "Выберите специализацию",
                },
                textarea: {
                    typeInput: "textarea",
                    label: "комментарий",
                    name: "textarea",
                    value: "",
                    options: [],
                    placeholder: "Почему вы хотите работать в нашей компании?",
                    dot_orange: true
                },
            },
        };
    }

    static contextTypes = {
        fields: PropTypes.object
    };

    componentWillMount() {
        let regionsVacancies = this.props.regions;
        this.handlerGetListRegions(regionsVacancies);

        this.handlerGetListVacancyByList(this.props.vacancyList, this.state.selectRegionId, this.state.selectCityId);

        if ( !!regionsVacancies ){
            let cities = [];
            regionsVacancies.map((el) => {
                el.cities.map((item) => {
                    cities.push({ value: item.id, label: item.name });
                });
                this.setState({ cities: cities });
            });
            this.props.getCvForm();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.vacancyList !== nextProps.vacancyList) {
            this.handlerGetListVacancyByList(nextProps.vacancyList, this.state.selectRegionId, this.state.selectCityId);
        }

        const vacancyCity_id = this.props.vacancyCity_id;
        const fields = {...this.state.fields};
        const listCities = this.state.cities;
        let cityLabel = listCities.find(x => x.value === vacancyCity_id);

        if (!!vacancyCity_id){
            this.handlerChangeCity(vacancyCity_id);
            if (!!cityLabel){
                fields.city.value = cityLabel.label;
                this.setState({fields});
            }
        }
        const specializationId = this.props.specializationId;
        const vacancyByList = this.state.vacancyByList;
        if (!!this.state.vacancyByList && !!specializationId) {
            this.handlerChangeSpecialization(specializationId);
            let specializationLabel = vacancyByList.find(x => x.value === specializationId);
            if (!!specializationLabel){
                if (!!cityLabel){
                    fields.specialization.value = specializationLabel.label;
                    this.setState({fields});
                }
                this.setState({
                    fieldLocked: true
                });
            } else {
                this.setState({
                    fieldLocked: false
                });
            }
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

        if ((this.state.selectCityId !== nextState.selectCityId) || (this.state.selectRegionId !== nextState.selectRegionId)) {
            this.handlerGetListVacancyByList(nextProps.vacancyList, nextState.selectRegionId, nextState.selectCityId);
        }
    }

    handlerGetListRegions = (list) => {
        let regions = [];

        if (list) {
            list.map((item, index) => {
                regions.push({ value: item.id, label: item.name });
            })
        }

        this.setState({ regions: regions });
    };

    handlerGetListCities = (list, value) => {
        let cities = [];

        if (list && value !== null) {
            list[value].cities.map((city, index) => {
                cities.push({ value: city.id, label: city.name });
            });
        }

        this.setState({
            cities: cities
        });
    };

    handlerGetListVacancyByList = (vacancyList, region, city) => {
        const vacanciesList = [];
        const valable_id = [];
        if ( city !== null ) {
            const city_ids = [city];
            vacancyList.forEach(vacancy => {
                const vacancies = vacancy.vacancies.filter(vacant => city_ids.includes(vacant.city_id));
                if (!vacancies || !vacancies.length) return;
                valable_id.push({...vacancy, vacancies})
            });

            this.setState({
                vacancyByList: valable_id.map((specialization)=> ({ value: specialization.id, label: specialization.title }))
            });
        }
        else if ( region !== null ) {
            this.sort( region );
        }
    };

    handlerChangeCity = (value) => {
        if (!!this.state.specialization){
            this.setState({
                specialization: null
            })
        }
        this.setState({
            selectCityId: value
        })
    };

    selectFormName = (name, value) => {
        let fields = {...this.state.fields};

        if ( name === 'city' ){
            fields[name].value = value;
            this.setState({fields});
        } else if ( name === 'specialization' ){
            fields[name].value = value;
            this.setState({fields});
        }
    };

    handlerChangeSpecialization = (value) => {
        this.setState({
            specialization: value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        const idVacancy = this.props.vacancyId ? this.props.vacancyId : null;
        let fields = Object.assign({}, this.state.fields);

        let data = new FormData();

        fields.phone.value = fields.phone.value.replace(/\D/gim, '');

        data.append('form_name', fields.form_name.value);
        {!!idVacancy ? data.append('id', idVacancy) : null}
        data.append('fio', fields.fio.value);
        data.append('email', fields.email.value);
        data.append('phone', fields.phone.value);
        data.append('city', fields.city.value);
        data.append('specialization', fields.specialization.value);
        data.append('comment', fields.textarea.value);
        data.append('file', this.state.file);

        this.props.submitForm(data, 'callback_form');
    };

    onBlur = (name) => {

        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    onBlurSelect = (name, value) => {
        return (e) => {
            if (value === ""){
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: true
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    onFieldChange = (name, value) => {
        let fields = {...this.state.fields};
        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        if (!value) {
            fields[name].hasError = true;
        }
        this.setState({fields});

        if ((!!fields["fio"].value && !!fields["phone"].value && !!fields["email"].value && !!fields["city"].value && !!fields["specialization"].value)){
                this.setState({
                    formValid: true
                });
        } else {
            this.setState({
                formValid: false
            });
        }
    };

    // позволяет загружать один и тот же файл в inputFile
    preClearFile = (e) => {
        e.target.value = null;
    }

    // добавляем файл
    onFileChange = (e) => {
        if (e.target.files.length && e.target.files[0].size < 5242880 && (e.target.files[0].type === "application/msword" ||
            e.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            || e.target.files[0].type === "application/rtf" || e.target.files[0].type === "application/pdf"
            || e.target.files[0].type === "application/vnd.oasis.opendocument.text"
            || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                this.setState({
                    file: e.target.files[0],
                });
        } else {
            this.setState({
                file: null,
            })
        }

        let fields = {...this.state.fields};
        if ((!!fields["fio"].value && !!fields["phone"].value && !!fields["email"].value && !!fields["city"].value && !!fields["specialization"].value)){
            this.setState({formValid: true});
        }
    };

    fileClean = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            file: null,
        })
    };

    onAgreementChange = (name, val) => this.setState((prev) => {
        return {
            agreementChecked: !prev.agreementChecked
        }
    })

    render(){

        let {
            form_name,
            fio,
            phone,
            email,
            textarea,
            city,
            specialization
        } = this.state.fields;

        let {
            formValid,
            file,
            fieldLocked,
            agreementChecked
        } = this.state;

        let target = this.state.target;

        let sortCities = this.state.cities.sort(function (a, b) {
            if (a.label > b.label){
                return 1;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 0;
        });

        let cvForm = this.props.cvForm ? this.props.cvForm.cvForm.find(x => x.alias === 'send_cv_form') : [];
        let modalComment = cvForm && cvForm.settings.send_cv_form_attach_comment;
        let agreement = cvForm && cvForm.settings.send_cv_form_agreement;
        return  <div>
            <span className="vacancy-modal">{ form_name.value }</span>
            <form onSubmit={ this.onSubmit } className="vacancy-modal-form">
                <div className="form-row form-vacancy-row form-vacancy-label-column">
                    <Input { ...fio }
                           onChange={this.onFieldChange}
                           name={fio.name}
                           onFocus={this.onFocus(fio.name)}
                           onBlur={this.onBlur(fio.name)}
                    />
                </div>
                <div className="form-row form-vacancy-row">
                    <div className="form-vacancy-row--item form-vacancy-label-column">
                        <Input { ...phone }
                               onChange={this.onFieldChange}
                               name={phone.name}
                               onFocus={this.onFocus(phone.name)}
                               onBlur={this.onBlur(phone.name)}
                        />
                    </div>
                    <div className="form-vacancy-row--item form-vacancy-row--email form-vacancy-label-column">
                        <Input { ...email }
                               onChange={this.onFieldChange}
                               name={email.name}
                               onFocus={this.onFocus(email.name)}
                               onBlur={this.onBlur(email.name)}
                        />
                    </div>
                </div>
                <div className="b-vacancy-search__input-carousels form-vacancy-search__input-carousels">
                    <div className="b-vacancy-search__input-carousel">
                        <div className="form-vacancy-label-block">
                            <span className="b-vacancy-search__input-carousel--desc">{ city.label }</span>
                            <span className="orange">*</span>
                        </div>
                        <Select
                            options={ sortCities }
                            onToggle={ this.handlerChangeCity }
                            selectFormName={ this.selectFormName }
                            attribute={ "city" }
                            value={ this.state.selectCityId }
                            hasError={ city.hasError }
                            errorText={ city.errorText }
                            onChange={this.onFieldChange}
                            onblur={this.onBlurSelect(city.name, city.value)}
                            onfocus={this.onFocus(city.name)}
                            fieldLocked={fieldLocked}
                        />
                    </div>
                    <div className="b-vacancy-search__input-carousel">
                        <div className="form-vacancy-label-block">
                            <span className="b-vacancy-search__input-carousel--desc">{ specialization.label }</span>
                            <span className="orange">*</span>
                        </div>
                        <Select
                            options={ this.state.vacancyByList }
                            onToggle={ this.handlerChangeSpecialization }
                            selectFormName={ this.selectFormName }
                            attribute={ "specialization" }
                            value={ this.state.specialization }
                            hasError={ specialization.hasError }
                            errorText={ specialization.errorText }
                            onChange={this.onFieldChange}
                            onblur={this.onBlurSelect(specialization.name, specialization.value)}
                            onfocus={this.onFocus(specialization.name)}
                            fieldLocked={fieldLocked}
                        />
                    </div>
                </div>

                <div className="vacancy-modal-btn-block">
                    <div className="vacancy-modal-comment">
                        <label className="b-form-spam__attach-label">
                            <span className="b-form-spam__attach-icon-area">
                                <Icon name={'icon_clip'} className={'b-form-spam__b-form-spam__attach-icon u-icon u-icon_clip'} />
                            </span>
                            {file ? <span className="close-form-cv-wrap">{file.name}<span className="close-form-cv" onClick={this.fileClean}/></span> : "Прикрепить резюме"}
                            <input className="b-form-spam__attach-input" type="file" onClick={this.preClearFile} onChange={this.onFileChange}/>
                        </label>
                        <span className={"form-vacancy-description-txt vacancy-modal-comment-label"} dangerouslySetInnerHTML={{__html: modalComment}} />
                    </div>
                    <span className={"form-vacancy-description-txt vacancy-modal-comment-label vacancy-modal-comment-label--mobile"} dangerouslySetInnerHTML={{__html: modalComment}} />
                    <div className="form-vacancy-description description--dot">
                        <span className="orange">*</span>
                        <span className="form-vacancy-description-txt">– поля обязательные для заполнения</span>
                    </div>
                </div>

                <div className="vacancy-modal-btn-block">
                    <CheckBox name={'agreement'} label={agreement} value={agreementChecked} onChange={this.onAgreementChange}/>
                    <button
                        onClick={this.onSubmit}
                        type="submit"
                        className={"form-vacancy-btn u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default "+(formValid && agreementChecked && !this.props.inProgress ? "" : " disabled")}>
                        Отправить
                    </button>
                </div>
            </form>
        </div>
    }

}

export default connect(
    state => ({
        vacancyRespond: state.forms || [],
        cvForm: state.cvForm || [],
        inProgress: state.forms.inProgress,
        clientInfo: state.client.clientInfo,
        isGuest: state.client.isGuest,
    }),
    (dispatch) => {
        return {
            submitForm: (formData) => dispatch(vacancyRespond(formData)),
            getCvForm: () => dispatch(cvForm()),
        }
    }
)(VacancyForm);