import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TopBlock from "../../components/Lk/TopBlock";
import RateServiceListItem from "../../components/Rates/RateServiceListItem";
import {connect} from "react-redux";
import {services} from '../../actions/client'
import LinkC from "../../components/common/LinkC";
import {mainUrl} from "../../tools/config";

import {Helmet} from "react-helmet";

class Services extends Component{

    static contextTypes = {
        selectedRegion: PropTypes.object
    };

    componentWillMount(){
        if (!this.props.services.length) {
            this.props.getServices();
        }
    }

    render(){

        let {
            services
        } = this.props;

         //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'servicesLKRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return <div>
            <Helmet                    
                title={currentMetaTags ? currentMetaTags.meta_title : ""}
                meta={[
                    {
                        "name": "description",
                        "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                    },
                    {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                ]}>
            </Helmet>

            <TopBlock title="Подключенные услуги"/>
            <div className="page__lk-connecteds">
                {services.map((service) => <RateServiceListItem item={service} key={service.id} clearAfter={true}/>)}
            </div>
            <div className="page__content-simple page__content-simple_text-center page__content-simple_adapt_default">
                <LinkC className="page__big-link page__big-link_centred" to={mainUrl+this.context.selectedRegion.alias+"/services/all"}>Перейти в каталог мобильных услуг</LinkC>
            </div>
        </div>
    }
}

export default connect(
    state => ({
        services: state.client.services || [],

        routes: state.routes,
    }),
    dispatch => {
        return {
            getServices: () => dispatch(services())
        }
    }
)(Services)