import React, {Component} from "react";
import PropTypes from "prop-types";
import {shopOffices} from "../../tools/config";
import UssdLink from "../common/UssdLink";

class RatesPhoneCommand extends Component {

    constructor(props) {
        super(props);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    renderOpio = (type) => {
        return  <div className={"mpn-popup__info-text"}>
                    Для {type} {this.props.type === 'tariff' ? "тарифа" : "услуги"} обратитесь в&nbsp;
                    <a target="_blank" href={shopOffices[this.context.selectedRegion.alias]}>офис продаж</a>
                </div>
    };

    renderContactCenter = (type) => {
        return  <div className={"mpn-popup__info-text"}>
                    Для {type} {this.props.type === 'tariff' ? "тарифа" : "услуги"} позвоните по телефону: <a href="tel:8-800-240-0000">8&nbsp;800&nbsp;240&nbsp;0000</a>
                </div>
    };

    render() {

        let {
            item,
            type,
            info_text
        } = this.props;

        let sms_info = 'sms_activation_info';
        let sms_desc = 'sms_activation_desc';

        let ussd_code = 'ussd_activation_code';
        let ussd_desc = 'ussd_activation_desc';
        let switch_opio = 'switch_opio';
        let activation_contact_center = 'activation_contact_center';
        let text = 'подключения';

        if (type === 'deactivate-rate-personal') {
            sms_info = 'sms_deactivation_code';
            sms_desc = 'sms_deactivation_info';
            ussd_code = 'ussd_deactivation_code';
            ussd_desc = 'ussd_deactivation_desc';
            switch_opio = 'deactivation_opio';
            activation_contact_center = 'deactivation_contact_center';
            text = 'отключения';

        } else if (type === 'check-rate-personal') {
            sms_info = 'sms_status_code';
            sms_desc = 'sms_status_info';
            ussd_code = 'ussd_status_code';
            ussd_desc = 'ussd_status_desc';
            switch_opio = 'status_check_opio';
            activation_contact_center = 'status_check_contact_center';
            text = 'проверки статуса';
        }

        return (
            <div className="mnp-popup_phone_commands">
                {(item[sms_info] && item[sms_desc]) || (item[ussd_code] && item[ussd_desc]) ? <div>{info_text}</div> : null}
                {
                    (item[sms_info] && item[sms_desc])
                        ?   <div className="mnp-popup-ok__col">
                                <b className="text_lg">1. Способ</b>
                                <div className="mnp-popup-ok__text-block text_lg">Отправить <span dangerouslySetInnerHTML={{__html: item[sms_info]}}/></div>
                                <div className="mnp-popup__bottom" dangerouslySetInnerHTML={{__html : item[sms_desc]}}/>
                            </div>
                        :   ''
                }
                {
                    (item[ussd_code] && item[ussd_desc])
                        ?   <div className="mnp-popup-ok__col">
                                <b className="text_lg">{(item[sms_info] && item[sms_desc]) ? '2' : '1' }. Способ</b>
                            <div className="mnp-popup-ok__text-block text_lg">
                                <UssdLink ussd={item[ussd_code]} className={"black-link"} >Набрать <span dangerouslySetInnerHTML={{__html: item[ussd_code]}}/></UssdLink>
                            </div>
                                <div className="mnp-popup__bottom" dangerouslySetInnerHTML={{__html: item[ussd_desc]}}/>
                            </div>
                        :   ''
                }
                {
                    item[switch_opio]
                        ?   <React.Fragment>
                                {this.renderOpio(text)}
                            </React.Fragment>
                        :   ''
                }
                {
                    item[activation_contact_center]
                        ?   <React.Fragment>
                                {this.renderContactCenter(text)}
                            </React.Fragment>
                        :   ''
                }
            </div>
        )
    }
}

export default RatesPhoneCommand;
