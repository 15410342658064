import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList} from "../../actions/routes";
import OfficeMap from "../../components/common/OfficeMap";
import { addressAutoComplete } from "../../actions/forms";
import { shopsList, closestShopsList } from "../../actions/shops";
import {coverImagesList} from "../../actions/coverImages";

class SearchOffice extends Component {

    constructor(props) {

        super(props);

        let route = props.routes.list.find(x => x.sys_name === 'motivOnMapRoute');

        this.state = {
            route: route,
            showTabs: false,
            typeMap: 'zone',
            zone: {
                lte: true,
                gsm: true
            }
        };
    }

    componentWillMount() {
        let regionId = this.context.selectedRegion.id;

        if ( this.props.shops.list.length === 0 ) {
            this.props.getShopsList(regionId);
        }

        this.props.getRoutes();
        this.props.getCoverImages();
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    showZonesHandler = (val, e) => {
        e.preventDefault();

        if (val) {
            let zone = {...this.state.zone};
            zone[val] = !zone[val];
            this.setState({zone});
        }
    };

    typeMapHandler = (val, e) => {
        e.preventDefault();

        if (val) {
            this.setState({
                typeMap: val
            })
        }
    };

    render() {

        let selectedRegion = this.context.selectedRegion;

        let {
            forms,
            shops,
            addressAutoComplete,
            getClosestShopsList,
            getShopsList
        } = this.props;

        let {
            route,
            showTabs,
            typeMap,
            zone
        } = this.state;

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={route ? route.meta_title : ""}
                    meta={[
                        {"name": "description", "content": route && route.meta_description ? route.meta_description : ""},
                        {"name": "keywords", "content": route && route.meta_keywords ? route.meta_keywords : ""}
                    ]}
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                />
                <div className="page__container">
                    <div className="page-map-options">
                        <ul className="page-map-options__list-mode list-mode">
                            <li className="list-mode__item mode-item">
                                <a className={ typeMap === "zone" ? "mode-item__link mode-item__link_selected" : "mode-item__link"} href="#" onClick={(event) => this.typeMapHandler('zone', event)}>Карта покрытия</a>
                            </li>
                            <li className="list-mode__item mode-item">
                                <a className={ typeMap === "office" ? "mode-item__link mode-item__link_selected" : "mode-item__link"} href="#" onClick={(event) => this.typeMapHandler('office', event)}>Офисы продаж</a>
                            </li>
                        </ul>

                        { typeMap === 'zone'
                            ? <ul className="page-map-options__list-zone list-zone">
                                <li className={ zone.gsm ? "list-zone__item list-zone__item_gsm zone-item" : "list-zone__item zone-item" } onClick={(event) => this.showZonesHandler('gsm', event)}>
                                    <div className="zone-item__icon"></div>
                                    <a className="zone-item__link" href="#">Зона покрытия GSM</a>
                                </li>
                                <li className={ zone.lte ? "list-zone__item list-zone__item_lte zone-item" : "list-zone__item zone-item" } onClick={(event) => this.showZonesHandler('lte', event)}>
                                    <div className="zone-item__icon"></div>
                                    <a className="zone-item__link" href="#">Зона покрытия LTE</a>
                                </li>
                            </ul>
                            : null
                        }
                    </div>
                </div>
                <div className="page__container page__container_map">

                    <OfficeMap  items={shops.list}
                                address={forms.addressFull || []}
                                addressAutoComplete={addressAutoComplete}
                                getClosestShopsList={getClosestShopsList}
                                getShopsList={getShopsList}
                                inProgress={forms.inProgress}
                                showTabs={showTabs}
                                zone={zone}
                                typeMap={typeMap}
                                coverCoords={this.props.coverImages.list}
                    />
                </div>
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        shops: state.shops,
        forms: state.forms,
        settings: state.settings,
        coverImages: state.coverImages
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            addressAutoComplete: (type, query, params) => dispatch(addressAutoComplete(type, query, params)),
            getClosestShopsList: (regionId, lat, lon) => dispatch(closestShopsList(regionId, lat, lon)),
            getShopsList: (regionId) => dispatch(shopsList(regionId)),
            getCoverImages: () => dispatch(coverImagesList())
        }
    }
)(SearchOffice);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const searchOfficeRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
];