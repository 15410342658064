import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Icon from "../common/Icon";
import AsideMenuItem from "../Vacancies/AsideMenuItem";

let routesSysName = [
    {
        alias: 'supportRoute',
        name: 'Помощь и поддержка'
    },
    {
        alias: 'fastDecisionsRoute',
        name: 'Быстрые решения'
    },
    {
        alias: 'securityRoute',
        name: 'Безопасность и защита от мошенничества'
    },
    {
        alias: 'motivOnMapRoute',
        name: 'Карта покрытия'
    },
    {
        alias: 'complainRoute',
        name: 'Добро пожаловаться'
    },
    {
        alias: 'supportTariffsSms',
        name: 'Тарифы на Смс-сообщения'
    },
    {
        alias: 'supportTariffsServices',
        name: 'Тарифы на голосовые информ. услуги'
    },
    {
        alias: 'termsOfServiceRoute',
        name: 'Правила оказания услуг',
        menu: [
            {
                alias: 'attachmentToTariffsRoute',
                name: 'Приложение к тарифам'
            },
            {
                alias: 'supportInternetRoute',
                name: 'Интернет'
            },
            {
                alias: 'telephonyRoute',
                name: 'Телефония'
            },
        ]
    },
];

function isActive (href, path) {
    if(href && path) {
        let sectionPath = path.split('/').slice(-1)[0];
        let sectionNavItem = href.split('/').slice(-1)[0];

        if (sectionPath === sectionNavItem) {
            return true;
        }
    }

    return false;
}

class RightNav extends Component {
    constructor(props, context) {
        super(props, context);

        let routes = [];
        let routesObj = props.routes ? props.routes.list : [];
        let flag, flagSubMenu = '';
        //берем локальный массив меню
        routesSysName.forEach(function (item) {
            // находим нужные нам позиции меню
            let route = routesObj.find(x => x.sys_name === item.alias);
            // если true до добавляем к меню нужные ссылки
            if (route) {
                item['link'] = route.route;
                if (isActive(route.route, context.locationPath)) {
                    flag = item.alias;
                }
                // находим нужное нам локальное подменю
                if (item.menu) {
                    let menu = [];
                    item.menu.map((menuItem) => {
                        let route = routesObj.find(x => x.sys_name === menuItem.alias);
                        if (route) {
                            menuItem.link = route.route;
                            if (isActive(route.route, context.locationPath)) {
                                flag = item.alias;
                                flagSubMenu = menuItem.alias;
                            }
                            menu.push(menuItem)
                        }
                        return menuItem;
                    })
                    item.menu = menu;
                }
                routes.push(item);
            }
        });


        this.state = {
            toggle: false,
            menu: routes,
            flag,
            flagSubMenu
        }
    }

    static contextTypes = {
        toggleOpenedClass: PropTypes.func,
        locationPath: PropTypes.string,
        megaMenuOpened: PropTypes.object,
    };

    componentDidUpdate() {
        let toggle = this.state.toggle;
        const overlayClass = document.querySelector('.u-overlay');
        const megaMenuOpened = this.context.megaMenuOpened;

        if (typeof window !== "undefined"){
            if ( toggle === true || !!overlayClass && toggle === false && megaMenuOpened === false ){
                this.context.toggleOpenedClass();
            }
        }
    }

    onToggle = () => {
        this.setState((prevState) => {
            return {
                toggle: !prevState.toggle
            }
        });
    };

    render() {
        let { toggle, menu, flag, flagSubMenu } = this.state;
        const customTitleMenu = 'Поддержка'

        return (<React.Fragment>
            <div className={(toggle ? "rotate b-aside-menu-top" : "b-aside-menu-top")} onClick={ this.onToggle }>{customTitleMenu ? customTitleMenu : 'О компании'}</div>
            <div className={`b-aside-menu b-aside-menu_adapt_default ${toggle ? "toggle " : ""}`}>
                { menu.map((item, index) =>
                    <AsideMenuItem
                        item={item}
                        key={index}
                        flag={flag}
                        flagSubMenu={flagSubMenu}
                    />)
                }
            </div>
        </React.Fragment>)
    }
}

export default connect(
    state => ({
        routes: state.routes,
    }),
    (dispatch) => {
        return {

        }
    }
)(RightNav);
